import React, { Component } from "react";
import { Grid, Paper, TextField, MenuItem } from "@material-ui/core";

import { GetAllCimetier } from "./../../Helpers/Get/Cimetier";
import { GetAllUtil } from "../../Helpers/Get/Util";

import CPCalendrier from "./CPlanning/CPCalendrier";
import { IsOracle } from "./../../Helpers/Test/IsOracle";
import { GetAllGens } from "./../../Helpers/Get/Gens";
import Opposition from "./../Global/OppositionTablette";
import { withCookies } from "react-cookie";

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
};

class CPlanning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IS_ORACLE: false,
      CIMETIER: [],
      SELECTED_CIMETIER: "",
      AGENT: [],
      SELECTED_AGENT: null,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle });
    });

    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    GetAllCimetier(1).then((cimetier) => {
      this.setState(
        {
          CIMETIER: [
            {
              id: 0,
              NUMCIME: 0,
              NOMCIME: "Indifférent",
              NUMPLAN: 0,
              IMPRIMECOLUMB: 0,
              COULEUR_EMPL: 0,
            },
            ...cimetier,
          ],
          SELECTED_CIMETIER: 0,
        },
        () => {
          if (this.state.IS_ORACLE) {
            GetAllGens(1).then((gens) => {
              this.setState({
                AGENT: [
                  {
                    id: 0,
                    IDGENS: 0,
                    NOM: "Indifférent",
                    PRENOM: "",
                    CLECIME: [0],
                    UTCLEUNIK: 0,
                    AGENT_TERRAIN: 1,
                  },
                  ...gens,
                ],
                SELECTED_AGENT: 0,
              });
            });
          } else {
            GetAllUtil(1).then((gens) => {
              this.setState({
                AGENT: [
                  {
                    id: 0,
                    UTCLEUNIK: 0,
                    LOGIN: "",
                    PASS: "",
                    ADMINISTRATEUR: 0,
                    NOM: "Indifférent",
                    PRENOM: "",
                    AGENT_TERRAIN: 1,
                  },
                  ...gens,
                ],
                SELECTED_AGENT: 0,
              });
            });
          }
        }
      );
    });
  };

  _handle_change_combo = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  render() {
    return this.state.SELECTED_AGENT !== null ? (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <TextField select fullWidth margin="normal" label="Cimetière" value={this.state.SELECTED_CIMETIER} onChange={this._handle_change_combo("SELECTED_CIMETIER")}>
              {this.state.CIMETIER.map((cimetier, index) => (
                <MenuItem key={index} value={cimetier.NUMCIME}>
                  {cimetier.NOMCIME}
                </MenuItem>
              ))}
            </TextField>

            <TextField select fullWidth margin="normal" label="Agent" value={this.state.SELECTED_AGENT} onChange={this._handle_change_combo("SELECTED_AGENT")}>
              {this.state.AGENT.map((agent, index) => (
                <MenuItem key={index} value={this.state.IS_ORACLE ? agent.IDGENS : agent.UTCLEUNIK}>
                  {`${agent.NOM} ${agent.PRENOM}`}
                </MenuItem>
              ))}
            </TextField>

            <CPCalendrier cimetier={this.state.SELECTED_CIMETIER} gens={this.state.SELECTED_AGENT} oracle={this.state.IS_ORACLE} />
          </Paper>
        </Grid>
      </Grid>
    ) : null;
  }
}

export default withCookies(CPlanning);
