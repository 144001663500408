import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { Grid, Paper, Typography, Table, TableBody, TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, TableHead, CircularProgress } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";

import { GetAllProcedureAbandon } from "./../../Helpers/Get/ProcedureAbandon";
import { GetConcessByIdProcedureAbandon } from "./../../Helpers/Get/Concess";
import { GetAllDuree } from "./../../Helpers/Get/Duree";
import { GetAllLieu } from "./../../Helpers/Get/Lieu";
import Opposition from "./../Global/OppositionTablette";
import CurrentTab from "./../Global/Object/current_tab";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  table_concess_row: {
    cursor: "pointer",
  },
};

class CProcedureAbandon extends Component {
  static propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xs"]).isRequired,
  };

  constructor(props) {
    super(props);

    this.DUREE = [];
    this.LIEU = [];

    this.state = {
      PROCEDUREABANDON: null,
      CONCESS: [],
      REDIRECT: null,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    GetAllProcedureAbandon().then((procedureAbandon) => {
      procedureAbandon = _.orderBy(procedureAbandon, ["id"], ["desc"]);

      this.setState(
        {
          PROCEDUREABANDON: procedureAbandon,
        },
        () => {
          _.forEach(procedureAbandon, (pa, i) => {
            GetConcessByIdProcedureAbandon(pa.IDPROCEDUREABANDON).then((concess) => {
              this.setState({ CONCESS: [...this.state.CONCESS, { id: pa.IDPROCEDUREABANDON, concess: concess }] });
            });
          });
        }
      );
    });

    GetAllDuree().then((duree) => {
      this.DUREE = duree;
    });

    GetAllLieu().then((lieu) => {
      this.LIEU = lieu;
    });
  };

  _cree_chaine_durre = (duree) => {
    return _.find(this.DUREE, ["NUMDURE", duree]).LIBDURE;
  };

  _cree_chaine_emplacement = (lieu) => {
    let empl = lieu;

    const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

    if (reg.test(empl)) {
      const match = empl.match(reg);

      empl = `${match[1]} ${match[3]} - ${match[2]} ${match[4]}${match[5]}`;
    }

    return empl;
  };

  render() {
    console.log("RENDER PROCEDURE-ABANDON");

    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={`/consulter/fiche-concession/${this.state.REDIRECT}/pa`} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <Typography variant="h6" gutterBottom>
              Concessions faisant l'objet d'une future reprise par la ville pour état d'abandon
            </Typography>

            {this.state.PROCEDUREABANDON === null ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

            {this.state.PROCEDUREABANDON !== null ? (
              this.state.PROCEDUREABANDON.length > 0 ? (
                <Table>
                  <TableBody>
                    {this.state.PROCEDUREABANDON.map((procedureAbandon, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`panel${index}`}>
                              {procedureAbandon.NOM_PROCEDURE_ABANDON}
                            </AccordionSummary>

                            <AccordionDetails
                              style={{
                                flexDirection: "column",
                                padding: 0,
                              }}
                            >
                              {this.state.CONCESS.length === this.state.PROCEDUREABANDON.length ? (
                                width === "xs" || width === "sm" ? (
                                  _.find(this.state.CONCESS, ["id", procedureAbandon.IDPROCEDUREABANDON]).concess.map((concess, index) => (
                                    <Accordion key={index} elevation={0}>
                                      <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography variant="body2" gutterBottom style={{ flex: 1 }}>
                                          {concess.FAMILLE}
                                        </Typography>

                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          className="HoverLink"
                                          onClick={(event) => {
                                            event.preventDefault();

                                            this.setState({
                                              REDIRECT: concess.COCLEUNIK,
                                            });
                                          }}
                                        >
                                          Voir la fiche
                                        </Typography>
                                      </AccordionSummary>

                                      <AccordionDetails
                                        onClick={() => {
                                          CurrentTab.setTab(2);

                                          this.setState({
                                            REDIRECT: concess.COCLEUNIK,
                                          });
                                        }}
                                      >
                                        <Table>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>Date d'achat</TableCell>
                                              <TableCell align="right">{`${new Date(concess.MADATE).toLocaleDateString("fr-FR")}`}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell>Durée</TableCell>
                                              <TableCell align="right">{this._cree_chaine_durre(concess.DUREE)}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                              <TableCell>Emplacement</TableCell>
                                              <TableCell align="right">{this._cree_chaine_emplacement(concess.EMPLACEMENT_LIEU)}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))
                                ) : (
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Famille</TableCell>
                                        <TableCell>Date d'achat</TableCell>
                                        <TableCell>Durée</TableCell>
                                        <TableCell>Emplacement</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {_.find(this.state.CONCESS, ["id", procedureAbandon.IDPROCEDUREABANDON]).concess.map((concess, index) => (
                                        <TableRow
                                          key={index}
                                          hover={true}
                                          style={styles.table_concess_row}
                                          onClick={() => {
                                            CurrentTab.setTab(2);

                                            this.setState({
                                              REDIRECT: concess.COCLEUNIK,
                                            });
                                          }}
                                        >
                                          <TableCell>{concess.FAMILLE}</TableCell>

                                          <TableCell>{`${new Date(concess.MADATE).toLocaleDateString("fr-FR")}`}</TableCell>

                                          <TableCell>{this._cree_chaine_durre(concess.DUREE)}</TableCell>

                                          <TableCell>{this._cree_chaine_emplacement(concess.EMPLACEMENT_LIEU)}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                )
                              ) : (
                                <Typography>Pas de concessions liées à cette procédure.</Typography>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography variant="h6" gutterBottom>
                  Pas de procédure en cours
                </Typography>
              )
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withWidth()(withCookies(CProcedureAbandon));
