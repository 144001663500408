import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import StyleLinearProgress from "./../StyleLinearProgress";

export default function DialogMajMdf(props) {
  const { state, data_loaded, table_loading, nb_total_photos_loading, nb_photos_enregistre, table_length, prestaCarto } = props;

  const loaded = (data_loaded * 100) / table_length;

  return (
    <Dialog fullWidth open={state} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Mise à jour</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {prestaCarto
            ? `Mise à jour des données de l’application ‘Gardiens de cimetières’ suite à une mise à jour de la cartographie. Il s'agit d'un téléchargement complet des données, merci de patienter`
            : `Mise à jour des données de l’application ‘Gardiens de cimetières’, merci de patienter`}
        </DialogContentText>
      </DialogContent>

      <div style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 8 }}>
        <Typography variant="body2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{table_loading}</span>
          {nb_total_photos_loading > 0 ? <span>{`Nombre de photos enregistrées : ${nb_photos_enregistre} / ${nb_total_photos_loading}`}</span> : null}
          <span>{loaded | 0}%</span>
        </Typography>
        <StyleLinearProgress variant="determinate" color="secondary" value={loaded} />
      </div>
    </Dialog>
  );
}
