import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withWidth, Paper, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Table, TableBody, TableRow, TableCell, TableHead } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import PropTypes from "prop-types";

import { GetIntervenByCocleunik } from "../../../Helpers/Get/Interven";
import { GetTemporaireForColor } from "../../../Helpers/Get/Temporaire";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: 16,
  },
  papertitle: {
    display: "flex",
    marginBottom: 20,
  },
  typographytitle: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    justifyContent: "center",
    marginBottom: 0,
    TextAlign: "center",
  },
  table_interven_row: {
    cursor: "pointer",
  },
};

class CFCTravaux extends Component {
  static propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      INTERVEN: [],
      COLOR_TEXT: [],
      REDIRECT: null,
      champ_modifie: [],
    };
  }

  componentDidMount = () => {
    let field = [];
    GetIntervenByCocleunik(this.props.concess.COCLEUNIK).then((interven) => {
      this.setState({ INTERVEN: interven });

      let sub_field = {};
      _.forEach(interven, (i) => {
        const temporaire = GetTemporaireForColor("INTERVEN", "I0CLEUNIK", i.I0CLEUNIK);

        temporaire.then((temp) => {
          if (temp.length > 0) {
            _.forEach(temp, (t) => {
              let color = "red";

              if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                color = "blue";
              }

              if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                color = "green";
              }

              if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                color = "primary";
              }

              var element_temp = t.TABLE_MODIFIE_JSON;
              element_temp = _.omit(element_temp, ["id"]);

              for (var key in element_temp) {
                sub_field[key] = color;

                if (key === "I0CLEUNIK") sub_field[key] = element_temp.I0CLEUNIK;
              }

              field.push(sub_field);
            });

            this.setState({
              champ_modifie: field,
            });
          }
        });

        const temporairePhoto = GetTemporaireForColor("PHOTOCONC", "I0CLEUNIK", i.I0CLEUNIK);
        temporairePhoto.then((temp) => {
          if (temp.length > 0) {
            _.forEach(temp, (t) => {
              let color = "red";

              if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                color = "blue";
              }

              if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                color = "green";
              }

              if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                color = "primary";
              }

              var element_temp = t.TABLE_MODIFIE_JSON;
              element_temp = _.omit(element_temp, ["id"]);

              for (var key in element_temp) {
                sub_field[key] = color;

                if (key === "I0CLEUNIK") sub_field[key] = element_temp.I0CLEUNIK;
              }

              field.push(sub_field);
            });

            this.setState({
              champ_modifie: field,
            });
          }
        });
      });
    });
  };

  _cree_chaine_suivi_travaux = (suivi) => {
    switch (suivi) {
      case 2:
        return "En attente";
      case 3:
        return "En cours";
      case 4:
        return "Suspendu";
      case 5:
        return "Terminé";
      default:
        return "Inconnu";
    }
  };

  _find_color = (modifie, i0cleunik) => {
    const temp = _.filter(modifie, (m) => {
      return m.I0CLEUNIK === i0cleunik.toString();
    });

    let color = [];

    if (temp[0] !== undefined) {
      for (var key in temp[0]) {
        if (typeof temp[0][key] === "string") {
          if (!_.includes(color, temp[0][key])) color.push(temp[0][key]);
        }
      }

      if (_.includes(color, "red")) return "red";
      if (_.includes(color, "blue")) return "blue";
      if (_.includes(color, "green")) return "green";
    }

    return "primary";
  };

  render() {
    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <Paper elevation={0} style={styles.papertitle}>
          <Typography variant="body2" gutterBottom style={styles.typographytitle}>
            Liste des travaux
          </Typography>
        </Paper>

        {width === "xs" || width === "sm" ? (
          this.state.INTERVEN.map((interven, index) => (
            <ExpansionPanel
              key={index}
              elevation={0}
              style={{
                position: "initial",
                color: this._find_color(this.state.champ_modifie, interven.I0CLEUNIK),
              }}
            >
              <ExpansionPanelSummary
                style={{
                  padding: "0 16px",
                  color: this.state.COLOR_TEXT[interven.I0CLEUNIK],
                }}
                expandIcon={<ExpandMore />}
              >
                <Typography variant="body2" gutterBottom>
                  {`${new Date(interven.DATETRAVAUX).toLocaleDateString("fr-FR")}`}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                onClick={() =>
                  this.setState({
                    REDIRECT: `/consulter/fiche-travaux/${interven.I0CLEUNIK}`,
                  })
                }
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Nature</TableCell>
                      <TableCell>{interven.EMPL_NATURE}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Suivi</TableCell>
                      <TableCell>{this._cree_chaine_suivi_travaux(interven.SUIVITRAV)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Intervenant</TableCell>
                      <TableCell>{interven.INTERVENANT_NOM}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Nature</TableCell>
                <TableCell>Suivi</TableCell>
                <TableCell>Intervenant</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.INTERVEN.map((interven, index) => (
                <TableRow
                  key={index}
                  hover
                  style={
                    (styles.table_interven_row,
                    {
                      color: this._find_color(this.state.champ_modifie, interven.I0CLEUNIK),
                    })
                  }
                  onClick={() =>
                    this.setState({
                      REDIRECT: `/consulter/fiche-travaux/${interven.I0CLEUNIK}`,
                    })
                  }
                >
                  <TableCell style={{ color: "inherit" }}>{!_.includes(interven.DATETRAVAUX, "1900-01-01") ? `${new Date(interven.DATETRAVAUX).toLocaleDateString("fr-FR")}` : ""}</TableCell>
                  <TableCell style={{ color: "inherit" }}>{interven.EMPL_NATURE}</TableCell>
                  <TableCell style={{ color: "inherit" }}>{this._cree_chaine_suivi_travaux(interven.SUIVITRAV)}</TableCell>
                  <TableCell style={{ color: "inherit" }}>{interven.INTERVENANT_NOM}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    );
  }
}

export default withWidth()(CFCTravaux);
