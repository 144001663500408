import { GetAllTemporaire } from "./../Helpers/Get/Temporaire";
import DeleteTemporaire from "./../Helpers/Delete/Temporaire";

import { GetConcaireByCocleunik, GetConcaireById } from "../Helpers/Get/Concaire";
import { GetPhotoconcByCocleunik, GetPhotoconcById } from "../Helpers/Get/Photoconc";
import { GetDefuntByCocleunik, GetDefuntById } from "../Helpers/Get/Defunt";
import { GetIntervenByCocleunik } from "../Helpers/Get/Interven";
import { GetLieuByCocleunik, GetLieuById } from "../Helpers/Get/Lieu";
import { GetConcessById } from "../Helpers/Get/Concess";

import UpdateConcaire from "./../Helpers/Update/Concaire";
import UpdatePhotoconc from "./../Helpers/Update/Photoconc";
import UpdateDefuntS from "./../Helpers/Update/DefuntS";
import UpdateDefunt from "../Helpers/Update/Defunt";
import UpdateInterven from "../Helpers/Update/Interven";
import UpdateConcess from "../Helpers/Update/Concess";
import UpdateLieu from "../Helpers/Update/Lieu";
import UpdateSvg from "../Helpers/Update/Svg";

import { DeleteConcaire } from "../Helpers/Delete/Concaire";
import { DeletePhotoconc } from "../Helpers/Delete/Photoconc";
import DeleteDefuntS from "../Helpers/Delete/DefuntS";
import { DeleteDefunt } from "../Helpers/Delete/Defunt";

import { handleError } from "../Helpers/Function";
import jsonpackage from "./../../package.json";
import { DeleteConcess } from "../Helpers/Delete/Concess";

var _ = require("lodash");

async function retourEtatInitial(temporaire) {
  switch (temporaire.TYPE_D_ENTITE) {
    case "CONCAIRE":
      console.log("L'élément temporaire est un interlocuteur.");

      return await UpdateConcaire(temporaire.ETAT_INITIAL)
        .then(() => {
          console.log("%cL'interlocuteur a été remis à son état initial.", "color: green");

          return supprimeTemporaire(temporaire);
        })
        .catch((Err) => handleError(Err));
    case "PHOTOCONC":
      console.log("L'élément temporaire est une photographie.");

      let photo = { ...temporaire.ETAT_INITIAL };

      if (photo.SIGNATURE_AVANT_AGENT !== undefined) {
        console.log("La photographie possède une SIGNATURE_AVANT_AGENT, on supprime cette rubrique.");

        delete photo.SIGNATURE_AVANT_AGENT;
      }

      if (photo.SIGNATURE_AVANT_SOCIETE !== undefined) {
        console.log("La photographie possède une SIGNATURE_AVANT_SOCIETE, on supprime cette rubrique.");

        delete photo.SIGNATURE_AVANT_SOCIETE;
      }

      return await UpdatePhotoconc(photo)
        .then(() => {
          console.log("%cLa photographie a été remise à son état initial.", "color: green");

          return supprimeTemporaire(temporaire);
        })
        .catch((Err) => handleError(Err));
    case "DEFUNT":
      console.log("L'élément temporaire est un défunt.");

      return await UpdateDefuntS(temporaire.ETAT_INITIAL)
        .then(() => {
          console.log("%cL'identité du défunt a été remise à son état initial.", "color: green");

          return UpdateDefunt(temporaire.ETAT_INITIAL)
            .then(() => {
              console.log("%cLe défunt a été remis à son état initial.", "color: green");

              return supprimeTemporaire(temporaire);
            })
            .catch((Err) => handleError(Err));
        })
        .catch((Err) => handleError(Err));
    case "INTERVEN":
      console.log("L'élément temporaire est un travaux.");

      return await UpdateInterven(temporaire.ETAT_INITIAL)
        .then(() => {
          console.log("%cL'intervention a été remis à son état initial.", "color: green");

          return supprimeTemporaire(temporaire);
        })
        .catch((Err) => handleError(Err));
    case "CONCESS":
      console.log("L'élément temporaire est une concession.");

      return await UpdateConcess(temporaire.ETAT_INITIAL)
        .then(() => {
          if (temporaire.TABLE_MODIFIE_JSON.LICLEUNIK !== undefined && temporaire.ETAT_INITIAL.LICLEUNIK !== temporaire.TABLE_MODIFIE_JSON.LICLEUNIK) {
            console.log("L'emplacement a été modifié.");

            const defuntPromise = async () => {
              console.log(`Récupération des défunt par COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);
              return await GetDefuntByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((listeDefunt) => {
                return (async function () {
                  for (let i = 0; i < listeDefunt.length; i++) {
                    const defunt = listeDefunt[i];

                    _.set(defunt, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    await UpdateDefunt(defunt)
                      .then(() => {
                        console.log(`%cL'emplacement du défunt ${defunt.D0CLEUNIK} a été modifié pour LICLEUNIK : ${temporaire.ETAT_INITIAL.LICLEUNIK}.`, "color: green");

                        if (i === listeDefunt.length - 1) {
                          return true;
                        }
                      })
                      .catch((Err) => handleError(Err));
                  }
                })();
              });
            };

            const intervenPromise = async () => {
              console.log(`Récupération des travaux par COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);
              return await GetIntervenByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((listeInterven) => {
                return (async function () {
                  for (let i = 0; i < listeInterven.length; i++) {
                    const interven = listeInterven[i];

                    _.set(interven, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    await UpdateInterven(interven)
                      .then(() => {
                        console.log(`%cL'emplacement de l'intervention ${interven.I0CLEUNIK} a été modifié pour LICLEUNIK : ${temporaire.ETAT_INITIAL.LICLEUNIK}.`, "color: green");

                        if (i === listeInterven.length - 1) {
                          return true;
                        }
                      })
                      .catch((Err) => handleError(Err));
                  }
                })();
              });
            };

            const lieuPromise = async () => {
              console.log(`Récupération de l'emplacement par LICLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);
              return await GetLieuById(temporaire.TABLE_MODIFIE_JSON.LICLEUNIK).then((lieu) => {
                if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                _.set(lieu.EMPLACEMENT, "ETAT", 1);
                _.set(lieu, "COCLEUNIK", 0);

                return UpdateLieu(lieu)
                  .then(() => {
                    console.log(`%cL'emplacement ${lieu.LICLEUNIK} a été modifié.`, "color: green");

                    return UpdateSvg(lieu)
                      .then(() => {
                        console.log(`%cLes données SVG ont été mis à jour.`, "color: green");
                      })
                      .catch((Err) => handleError(Err));
                  })
                  .catch((Err) => handleError(Err));
              });
            };

            return (async () => {
              const p1 = defuntPromise();
              const p2 = intervenPromise();
              const p3 = lieuPromise();

              await Promise.all([p1, p2, p3]).then(() => {
                console.log("%cLa concession a été remise à son état initial.", "color: green");

                return supprimeTemporaire(temporaire);
              });
            })();
          } else {
            console.log("%cLa concession a été remise à son état initial.", "color: green");

            return supprimeTemporaire(temporaire);
          }
        })
        .catch((Err) => handleError(Err));
    case "LIEU":
      console.log("L'élément temporaire est un emplacement.");

      return await UpdateLieu(temporaire.ETAT_INITIAL)
        .then(() => {
          console.log("%cL'emplacement a été remis à son état initial.", "color: green");

          return supprimeTemporaire(temporaire);
        })
        .catch((Err) => handleError(Err));
    default:
      break;
  }
}

async function supprimeObjet(temporaire, oracle) {
  switch (temporaire.TYPE_D_ENTITE) {
    case "CONCAIRE":
      console.log(`L'élément temporaire est un interlocuteur, il a pour C0CLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.C0CLEUNIK}`);

      return await GetConcaireById(temporaire.TABLE_MODIFIE_JSON.C0CLEUNIK).then((concaire) => {
        if (concaire !== undefined) {
          console.log("L'interlocuteur a été trouvé dans la tablette.");

          return DeleteConcaire(concaire.id)
            .then(() => {
              console.log("%cL'interlocuteur a été supprimé.", "color: green");

              return supprimeTemporaire(temporaire);
            })
            .catch((Err) => handleError(Err));
        } else {
          console.log("%cL'interlocuteur n'a pas été trouvé dans la tablette.", "color: red");

          return supprimeTemporaire(temporaire);
        }
      });
    case "PHOTOCONC":
      const idPhoto = oracle ? temporaire.TABLE_MODIFIE_JSON.IDEVOL_ETAT : temporaire.TABLE_MODIFIE_JSON.IDPHOTOCONC;

      console.log(`L'élément temporaire est une photographie, elle a pour IDPHOTOCONC : ${idPhoto}`);

      return await GetPhotoconcById(idPhoto).then((photoconc) => {
        if (photoconc !== undefined) {
          console.log("La photographie a été trouvé dans la tablette.");

          return DeletePhotoconc(photoconc.id)
            .then(() => {
              console.log("%cLa photographie a été supprimé.", "color: green");

              return supprimeTemporaire(temporaire);
            })
            .catch((Err) => handleError(Err));
        } else {
          console.log("%cLa photographie n'a pas été trouvé dans la tablette.", "color: red");

          return supprimeTemporaire(temporaire);
        }
      });
    case "DEFUNT":
      console.log(`L'élément temporaire est un défunt, il a pour D0CLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.D0CLEUNIK}`);

      return await GetDefuntById(temporaire.TABLE_MODIFIE_JSON.D0CLEUNIK).then((defunt) => {
        if (defunt !== undefined) {
          console.log("Le défunt a été trouvé dans la tablette.");

          return DeleteDefuntS(defunt)
            .then(() => {
              console.log("%cL'identité du défunt a été supprimé.", "color: green");

              return DeleteDefunt(defunt.id)
                .then(() => {
                  console.log("%cLe défunt a été supprimé.", "color: green");

                  return supprimeTemporaire(temporaire);
                })
                .catch((Err) => handleError(Err));
            })
            .catch((Err) => handleError(Err));
        } else {
          console.log("%cLe défunt n'a pas été trouvé dans la tablette.", "color: red");

          return supprimeTemporaire(temporaire);
        }
      });
    case "CONCESS":
      console.log(`L'élément temporaire est une concession, elle a pour COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);

      return await GetConcessById(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
        if (concess !== undefined) {
          console.log("La concession a été trouvé dans la tablette.");

          const interloPromise = async () => {
            console.log(`Récupération des interlocuteurs par COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);
            return await GetConcaireByCocleunik(concess.COCLEUNIK).then((concaire) => {
              return (async function () {
                for (let i = 0; i < concaire.length; i++) {
                  const c = concaire[i];

                  await DeleteConcaire(c.id)
                    .then(() => {
                      console.log(`%cL'interlocuteur ${c.C0CLEUNIK} a été supprimé pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: green");

                      if (i === concaire.length - 1) {
                        return true;
                      }
                    })
                    .catch((Err) => handleError(Err));
                }
              })();
            });
          };

          const photoPromise = async () => {
            console.log(`Récupération des photographies par COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);
            return await GetPhotoconcByCocleunik(concess.COCLEUNIK).then((photoconc) => {
              return (async function () {
                for (let i = 0; i < photoconc.length; i++) {
                  const p = photoconc[i];

                  await DeletePhotoconc(p.id)
                    .then(() => {
                      console.log(`%cLa photographie ${p.IDPHOTOCONC} a été supprimé pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: green");

                      if (i === photoconc.length - 1) {
                        return true;
                      }
                    })
                    .catch((Err) => handleError(Err));
                }
              })();
            });
          };

          const defuntPromise = async () => {
            console.log(`Récupération des défunts par COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);
            return await GetDefuntByCocleunik(concess.COCLEUNIK).then((defunt) => {
              return (async function () {
                for (let i = 0; i < defunt.length; i++) {
                  const d = defunt[i];

                  await DeleteDefuntS(d)
                    .then(() => {
                      console.log(`%cL'identité du défunt ${d.D0CLEUNIK} a été supprimé pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: green");

                      DeleteDefunt(d.id).then(() => {
                        console.log(`%cLe défunt ${d.D0CLEUNIK} a été supprimé pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: green");

                        if (i === defunt.length - 1) {
                          return true;
                        }
                      });
                    })
                    .catch((Err) => handleError(Err));
                }
              })();
            });
          };

          const lieuPromise = async () => {
            console.log(`Récupération de l'emplacement par COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`);
            return await GetLieuByCocleunik(concess.COCLEUNIK).then((lieu) => {
              if (lieu !== undefined) {
                console.log(`%cL'emplacement a été trouvé pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: green");

                console.log(`Modification de l'emplacement devenu non gelé.`);
                if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                _.set(lieu.EMPLACEMENT, "ETAT", 1);
                _.set(lieu, "COCLEUNIK", 0);

                return UpdateLieu(lieu)
                  .then(() => {
                    console.log(`%cL'emplacement ${lieu.LICLEUNIK} a été modifié pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: green");

                    return UpdateSvg(lieu)
                      .then(() => {
                        console.log(`%cLes données SVG ont été mis à jour pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: green");

                        return true;
                      })
                      .catch((Err) => handleError(Err));
                  })
                  .catch((Err) => handleError(Err));
              } else {
                console.log(`%cL'emplacement n'a pas été trouvé pour le COCLEUNIK : ${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}.`, "color: red");

                return true;
              }
            });
          };

          return (async () => {
            const p1 = interloPromise();
            const p2 = photoPromise();
            const p3 = defuntPromise();
            const p4 = lieuPromise();

            await Promise.all([p1, p2, p3, p4]).then(() => {
              return DeleteConcess(concess.id).then(() => {
                console.log("%cLa concession a été supprimé.", "color: green");

                return supprimeTemporaire(temporaire);
              });
            });
          })();
        } else {
          console.log("%cLa concession n'a pas été trouvé dans la tablette.", "color: red");

          return supprimeTemporaire(temporaire);
        }
      });
    default:
      break;
  }
}

async function supprimeTemporaire(temporaire) {
  return await DeleteTemporaire(temporaire.id)
    .then(() => {
      console.log("%cL'élément temporaire a été supprimé.", "color: green");
    })
    .catch((Err) => handleError(Err));
}

export default async function TemporairePurge(headers, idTablette, oracle) {
  console.log("Récupération des éléments temporaire de la tablette.");

  return await GetAllTemporaire().then((temporaire) => {
    console.log(`Il y a ${temporaire.length} éléments temporaire dans la tablette.`);

    if (temporaire.length === 0) {
      console.log("Début du traitement sans éléments temporaire.");

      return true;
    } else {
      console.log("Début du traitement avec des éléments temporaire.");

      return (async function () {
        for (let i = 0; i < temporaire.length; i++) {
          const unTemporaire = temporaire[i];

          if (unTemporaire.DATE_DE_CREATION !== null) {
            console.log("L'élément temporaire a une date de création, il a été envoyé au Gnet.");
            console.log("Récupération des éléments temporaire du Gnet.");

            const result = await fetch(`${jsonpackage.urlapi}TEMPORAIRE?start=-1&end=-1`, {
              mode: "cors",
              method: "GET",
              headers: headers,
            });

            const responseBody = result.json();
            var temporaireGnet = await responseBody;

            console.log(`Il y a ${temporaireGnet.length} éléments temporaire dans le Gnet.`);
            console.log(`On filtre les éléments temporaire du Gnet qui ont l'id tablette : ${idTablette} et qui ont l'id temporaire : ${unTemporaire.ID_TEMPORAIRE}`);

            temporaireGnet = _.filter(temporaireGnet, (tg) => {
              return tg.ID_TABLETTE === idTablette && tg.ID_TEMPORAIRE === unTemporaire.ID_TEMPORAIRE;
            });

            console.log(`Il y a ${temporaireGnet.length} éléments temporaire dans le Gnet correspondant au temporaire de la tablette.`);

            if (temporaireGnet.length === 0) {
              console.log("%cL'élément temporaire de la tablette n'a pas été trouvé dans le Gnet", "color: green");
              console.log("----------------------------------------------------------------------------------------------------------------------------------------------");

              if (unTemporaire.ETAT_INITIAL !== null) {
                console.log("L'élément temporaire a un état initial, il s'agit d'une modification");

                console.log(i, temporaire.length - 1);
                if (i === temporaire.length - 1) {
                  return await retourEtatInitial(unTemporaire).then(() => {
                    return true;
                  });
                } else {
                  await retourEtatInitial(unTemporaire);
                }
              } else {
                console.log("L'élément temporaire n'a pas d'état initial, il s'agit d'un ajout");

                console.log(i, temporaire.length - 1);
                if (i === temporaire.length - 1) {
                  return await supprimeObjet(unTemporaire, oracle).then(() => {
                    return true;
                  });
                } else {
                  await supprimeObjet(unTemporaire, oracle);
                }
              }
            } else {
              console.log("L'élément temporaire à bien été trouvé dans la base du Gnet");

              console.log(i, temporaire.length - 1);
              if (i === temporaire.length - 1) {
                return true;
              }
            }
          } else {
            console.log("L'élément temporaire n'a pas de date de création, il n'a pas été envoyé au Gnet.");

            console.log(i, temporaire.length - 1);
            if (i === temporaire.length - 1) {
              return true;
            }
          }
        }
      })();
    }
  });
}
