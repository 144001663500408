import Dexie from "dexie";
import db from "../Db";
import { GetSocieteById } from "../Get/Societe";

var DeleteSocieteBySocleunik = Dexie.async(function* (SOCLEUNIK) {
  yield db.open();

  GetSocieteById(SOCLEUNIK).then((societe) => {
    if (societe !== undefined) db.SOCIETE.delete(societe.id);
  });
});

export default DeleteSocieteBySocleunik;
