import db from "../Db";
import { GetPhotoconcById } from "../Get/Photoconc";

export function DeletePhotoconc(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.delete(ID);

    return resolve(true);
  });
}

export function DeletePhotoconcByIdPhotoconc(IDPHOTOCONC) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetPhotoconcById(IDPHOTOCONC).then((photoconc) => {
      if (photoconc !== undefined) db.PHOTOCONC.delete(photoconc.id);

      return resolve(true);
    });
  });
}
