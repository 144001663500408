import db from "../Db";

export function CheckUserRight(UTCLEUNIK, TYPE, MODE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    let NUMACTION = 0;

    switch (TYPE) {
      case "LOGIN":
        NUMACTION = 46; // A acces a la tablette
        break;
      case "CONCESS":
        NUMACTION = 47;
        break;
      case "DEFUNT":
        NUMACTION = 48;
        break;
      case "LIEU":
        NUMACTION = 49;
        break;
      case "CONCAIRE":
        NUMACTION = 50;
        break;
      case "PHOTOCONC":
        NUMACTION = 51;
        break;
      case "INTERVEN":
        NUMACTION = 52;
        break;
      default:
        break;
    }

    if (parseInt(UTCLEUNIK) === 0) {
      return resolve(true);
    } else {
      if (NUMACTION > 0) {
        db.LIGNE_ACTION.where("UTCLEUNIK")
          .equals(parseInt(UTCLEUNIK))
          .and((item) => item.NUMACTION === NUMACTION)
          .first()
          .then((result) => {
            return resolve(result[MODE]);
          });
      } else {
        return resolve(false);
      }
    }
  });
}
