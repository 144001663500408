import db from "../Db";

export function DeleteDatabase() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.delete()
      .then(() => {
        console.log("base de données supprimé");
      })
      .catch((err) => {
        return resolve(false);
      })
      .finally(() => {
        return resolve(true);
      });
  });
}
