import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AppBar, Button, Card, CardContent, Dialog, IconButton, Slide, Toolbar, Typography, withStyles } from "@material-ui/core";
import { Close, PlayArrow } from "@material-ui/icons";
import { IsOracle } from "./../Helpers/Test/IsOracle";
import { GetLieuById, GetAllLieuByCocleunik } from "../Helpers/Get/Lieu";
import { GetCendreByIdLieu } from "./../Helpers/Get/Cendre";
import { GetAllZone, GetZoneByTypezone, GetZoneById } from "./../Helpers/Get/Zone";
import { GetSvgByNumcime } from "./../Helpers/Get/Svg";

import PlanOperateurPolygon from "./PlanOperateurPolygon";
import MARKER from "../Images/marker_red.png";
import * as d3 from "d3";
import CCSousPlanEquipement from "./Consulter/CCartographie/CCSousPlanEquipement";
import { GetDefuntByLicleunik } from "../Helpers/Get/Defunt";
import { GetConcessById } from "../Helpers/Get/Concess";
import CurrentTab from "./Global/Object/current_tab";
import CalculeSituation from "./Global/Object/calculeSituation";

var _ = require("lodash");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyleTypography = withStyles({
  root: {
    textDecoration: "underline",
    "&:hover": {
      color: "#FF732F",
    },
  },
})(Typography);

const styles = {
  typography: {
    flex: 1,
  },
  card: {
    alignItems: "center",
    display: "flex",
    height: 100,
    position: "absolute",
    width: 250,
  },
};

class PlanOperateurFiche extends Component {
  constructor(props) {
    super(props);

    this.ISORACLE = false;
    this.CENDRE = "";
    this.ZONE = "";
    this.ZONE_PARENTE = "";
    this.XCenter = 0;
    this.YCenter = 0;

    this.WIDTH = 1024;
    this.HEIGHT = 768;
    this.X_ARRAY = [];
    this.Y_ARRAY = [];
    this.BOUNDS = undefined;
    this.ZOOM = undefined;
    this.SVG = undefined;
    this.ZOCLEUNIK = undefined;
    this.PREV_LICLEUNIK = 0;

    this._svg_element = React.createRef();
    this._button_bottom = React.createRef();
    this._button_left = React.createRef();
    this._button_top = React.createRef();
    this._button_right = React.createRef();

    this.state = {
      id_lieu: 0,
      cimetier: props.cimetier,
      plan: props.plan,
      lieu: "",
      plangeneral: false,
      afficheSousPlan: false,
      defunt: [],
      concess: "",
      plang: true,
      area_zoomed: false,
      bottom_arrow: false,
      left_arrow: false,
      right_arrow: false,
      top_arrow: false,
      titre_equipement: "",
      displaycard: false,
      lieu_clique: "",
      mousecoord: [],
      dialog_emplacement_gele: false,
      redirect: null,
    };
  }

  handleScroll = () => {
    if (this.state.displaycard) this.setState({ displaycard: false });
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, true);

    IsOracle().then((oracle) => {
      this.ISORACLE = oracle;

      oracle === 1 ? (this.HEIGHT = 617) : (this.HEIGHT = 768);
    });

    GetLieuById(this.props.id_lieu).then((lieu) => {
      if (lieu !== undefined) {
        this.setState({ lieu: lieu }, () => {
          if (this.state.lieu.LIEU_MULTIPLE_PRINC_VIRTUELLE) {
            GetAllLieuByCocleunik(this.state.lieu.COCLEUNIK).then((lieu) => {
              this.setState(
                {
                  lieu: _.minBy(lieu, function (l) {
                    return l.LICLEUNIK;
                  }),
                },
                () => {
                  GetCendreByIdLieu(this.state.lieu.LICLEUNIK).then((cendre) => {
                    this.CENDRE = cendre;

                    if (this.CENDRE !== undefined) {
                      GetZoneByTypezone(this.CENDRE.NUMCENDRE).then((zone) => {
                        this.ZONE = zone;

                        if (this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                          GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                            this.ZONE_PARENTE = zone_parente;

                            this._create_svg();
                          });
                        } else {
                          this._create_svg();
                        }
                      });
                    } else {
                      if (this.state.lieu.SITUATION !== 0 && (this.state.lieu.SITUATION < 250 || this.state.lieu.SITUATION > 255)) {
                        GetZoneById(this.state.lieu.SITUATION).then((zone) => {
                          this.ZONE = zone;

                          if (this.ZONE !== undefined) {
                            if (this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                              GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                                this.ZONE_PARENTE = zone_parente;

                                this._create_svg();
                              });
                            } else {
                              this._create_svg();
                            }
                          }
                        });
                      }
                    }
                  });
                }
              );
            });
          } else {
            GetCendreByIdLieu(this.state.lieu.LICLEUNIK).then((cendre) => {
              this.CENDRE = cendre;

              if (this.CENDRE !== undefined) {
                GetZoneByTypezone(this.CENDRE.NUMCENDRE).then((zone) => {
                  this.ZONE = zone;

                  if (this.ZONE !== undefined && this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                    GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                      this.ZONE_PARENTE = zone_parente;

                      this._create_svg();
                    });
                  } else {
                    this._create_svg();
                  }
                });
              } else {
                if (this.state.lieu.SITUATION !== 0 && (this.state.lieu.SITUATION < 250 || this.state.lieu.SITUATION > 255)) {
                  GetZoneById(this.state.lieu.SITUATION).then((zone) => {
                    this.ZONE = zone;

                    if (this.ZONE !== undefined) {
                      if (this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                        GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                          this.ZONE_PARENTE = zone_parente;

                          this._create_svg();
                        });
                      } else {
                        this._create_svg();
                      }
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.sousplan !== this.props.sousplan && this.props.id_lieu !== undefined) {
      if (!this.props.sousplan) {
        this.SVG.attr("height", this.ISORACLE ? 617 : 768);
        this.SVG.attr("viewBox", `0 0 1024 ${this.ISORACLE ? 617 : 768}`);

        this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity);

        this.SVG.selectAll("rect, g").remove();
        this._create_svg();
      } else {
        if (this.props.from_button) {
          this.SVG.attr("height", 768);
          this.SVG.attr("viewBox", "0 0 1024 768");

          CalculeSituation.getSituation(this.state.lieu).then((zocle) => {
            GetZoneById(zocle).then((zone) => {
              zocle = zone.ZOCLEUNIK_PARENT > 0 ? zone.ZOCLEUNIK_PARENT : zocle;

              var rect = d3.select("rect[zocleunik='" + zocle + "']");
              this.ZOCLEUNIK = zocle;

              const equipement = parseInt(rect.attr("equipement"));
              if (equipement === 0) {
                this.BOUNDS = rect.node().getBBox();

                let BX = this.BOUNDS.x;
                let BY = this.BOUNDS.y;
                let BW = this.BOUNDS.width;
                let BS = this.WIDTH / BW;
                let BT = [-BX * BS, -BY * BS];

                this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity.translate(BT[0], BT[1]).scale(BS));
              } else {
                this.setState({ afficheSousPlan: true });
              }
            });
          });
        }
      }

      this.setState({
        bottom_arrow: false,
        left_arrow: false,
        right_arrow: false,
        top_arrow: false,
        displaycard: false,
        plang: true,
      });
    }

    if (this.state.area_zoomed) {
      document.onkeydown = (event) => {
        switch (event.key) {
          case "Escape":
            event.preventDefault();

            this.setState({ displaycard: false }, () => {
              this.props.change_plang();
            });

            break;

          case "ArrowLeft":
            event.preventDefault();
            this._button_left.current.click();
            break;

          case "ArrowUp":
            event.preventDefault();
            this._button_top.current.click();
            break;

          case "ArrowRight":
            event.preventDefault();
            this._button_right.current.click();
            break;

          case "ArrowDown":
            event.preventDefault();
            this._button_bottom.current.click();
            break;

          default:
            break;
        }
      };
    } else {
      document.onkeydown = (event) => {
        event.stopPropagation();
      };
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  _create_svg = () => {
    let planAffiche = this.state.plan.IMAGEPL !== "" ? this.state.plan.IMAGEPL : this.state.plan.IMAGESVG;

    if (planAffiche !== "") {
      this.SVG = d3.select(this._svg_element.current).style("height", "auto").style("max-width", "100%");

      const g = this.SVG.append("g");
      g.append("image")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", this.WIDTH)
        .attr("height", this.HEIGHT)
        .attr("xlink:href", window.URL.createObjectURL(planAffiche))
        .style("image-rendering", "-webkit-optimize-contrast");

      let CoordX = [],
        CoordY = [];

      let ZONE = this.ZONE_PARENTE !== "" ? this.ZONE_PARENTE : this.ZONE;
      if (ZONE !== undefined) {
        if (ZONE.ZONE_DESSUS_TYPE === true || ZONE.ZONE_DESSUS_TYPE === 1) {
          CoordX.push(this.state.lieu.EMPLACEMENT.H1, this.state.lieu.EMPLACEMENT.H2, this.state.lieu.EMPLACEMENT.H3, this.state.lieu.EMPLACEMENT.H4);
          CoordY.push(this.state.lieu.EMPLACEMENT.V1, this.state.lieu.EMPLACEMENT.V2, this.state.lieu.EMPLACEMENT.V3, this.state.lieu.EMPLACEMENT.V4);
        } else {
          CoordX.push(ZONE.COORDONNEES.X1, ZONE.COORDONNEES.X2);
          CoordY.push(ZONE.COORDONNEES.Y1, ZONE.COORDONNEES.Y2);
        }

        var XMin = Math.min(...CoordX);
        var XMax = Math.max(...CoordX);
        var YMin = Math.min(...CoordY);
        var YMax = Math.max(...CoordY);

        this.XCenter = XMin + (XMax - XMin) / 2;
        this.YCenter = YMin + (YMax - YMin) / 2;

        g.append("image")
          .attr("x", this.ISORACLE ? this.XCenter * (1024 / 780) - 11 : this.XCenter * (1024 / 640) - 11)
          .attr("y", this.ISORACLE ? this.YCenter * (617 / 470) - 40 : this.YCenter * (768 / 480) - 40)
          .attr("width", 22)
          .attr("height", 40)
          .attr("xlink:href", MARKER);

        this._create_emplacement();

        GetAllZone(this.props.cimetier.NUMCIME, 1, this.state.plan.PLAN_V4).then((zone) => {
          this.ZOOM = d3
            .zoom()
            .scaleExtent([1 / 2, 5 * zone.length])
            .on("start", this._zoom_start.bind(this))
            .on("zoom", _zoomed)
            .on("end", this._zoom_end.bind(this));

          function _zoomed() {
            g.attr("transform", d3.event.transform);
          }
        });
      }
    }
  };

  _create_emplacement = () => {
    const imgWidth = this.ISORACLE ? 780 : 640;
    const imgHeight = this.ISORACLE ? 470 : 480;

    GetAllZone(this.props.cimetier.NUMCIME, 1, this.state.plan.PLAN_V4).then((zone) => {
      if (zone.length === 0) {
        this._create_emplacement_equipement(imgWidth, imgHeight);
      } else {
        _.forEach(zone, (z, i) => {
          let X = z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
          let Y = z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);
          let W = z.COORDONNEES.X2 * (this.WIDTH / imgWidth) - z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
          let H = z.COORDONNEES.Y2 * (this.HEIGHT / imgHeight) - z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);

          if (!_.includes(this.X_ARRAY, X)) {
            this.X_ARRAY.push(X);
          }

          if (!_.includes(this.Y_ARRAY, Y)) {
            this.Y_ARRAY.push(Y);
          }

          this.SVG.append("rect")
            .attr("cursor", "pointer")
            .attr("x", X)
            .attr("y", Y)
            .attr("width", W)
            .attr("height", H)
            .attr("fill", "transparent")
            .attr("zocleunik", z.ZOCLEUNIK)
            .attr("equipement", 0);
        });

        this._create_emplacement_equipement(imgWidth, imgHeight);
      }
    });
  };

  _create_emplacement_equipement = (imgWidth, imgHeight) => {
    GetAllZone(this.props.cimetier.NUMCIME, 0, this.state.plan.PLAN_V4)
      .then((zone) => {
        _.forEach(zone, (z, i) => {
          let X = z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
          let Y = z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);
          let W = z.COORDONNEES.X2 * (this.WIDTH / imgWidth) - z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
          let H = z.COORDONNEES.Y2 * (this.HEIGHT / imgHeight) - z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);

          this.SVG.append("rect")
            .attr("cursor", "pointer")
            .attr("x", X)
            .attr("y", Y)
            .attr("width", W)
            .attr("height", H)
            .attr("fill", "transparent")
            .attr("zocleunik", z.ZOCLEUNIK)
            .attr("equipement", 1);
        });
      })
      .then(() => {
        var rect = d3.selectAll("rect");
        rect.on("click", (d, i, p) => {
          d3.event.preventDefault();

          this.SVG.attr("height", 768);
          this.SVG.attr("viewBox", "0 0 1024 768");

          this.ZOCLEUNIK = d3.select(p[i]).attr("zocleunik");

          const equipement = parseInt(d3.select(p[i]).attr("equipement"));
          if (equipement === 0) {
            this.BOUNDS = p[i].getBBox();

            let BX = this.BOUNDS.x;
            let BY = this.BOUNDS.y;
            let BW = this.BOUNDS.width;
            let BS = this.WIDTH / BW;
            let BT = [-BX * BS, -BY * BS];

            this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity.translate(BT[0], BT[1]).scale(BS));
          } else {
            this.setState({ afficheSousPlan: true });
          }

          this.setState({ plang: true }, () => {
            if (equipement === 0) this.props.change_plang();
            this.props.change_from_button();
          });
        });
      });

    this.X_ARRAY = _.tail(_.sortBy(this.X_ARRAY));
    this.Y_ARRAY = _.tail(_.sortBy(this.Y_ARRAY));
  };

  _zoom_start = () => {
    if (this.SVG.selectAll("rect")._groups[0].length) {
      this.SVG.selectAll("rect").remove();
      const g = this.SVG.select("g");
      g.selectAll("image")._groups[0][1].remove();

      if (this.state.plan.IMAGESVG !== "") {
        g.select("image").attr("xlink:href", window.URL.createObjectURL(this.state.plan.IMAGESVG));
      }

      this._draw_polygons(g);
    }
  };

  _zoom_end = () => {
    if (!this.props.sousplan) {
      this.setState({ area_zoomed: false }, () => {
        this.SVG.select("g").selectAll("g").remove();

        if (this.state.plan.IMAGEPL !== "") {
          this.SVG.select("g").select("image").attr("xlink:href", window.URL.createObjectURL(this.state.plan.IMAGEPL));
        }
      });
    } else {
      this.setState({ area_zoomed: true }, () => {
        let BX = this.BOUNDS.x;
        let BY = this.BOUNDS.y;
        let BW = this.BOUNDS.width;
        let BH = this.BOUNDS.height;

        if (Math.floor(BX) === 0 || BX + BW < this.WIDTH) {
          this.setState({ right_arrow: true });
        }

        if (Math.floor(BY) === 0 || BY + BH < this.HEIGHT) {
          this.setState({ bottom_arrow: true });
        }

        if (Math.floor(BX) > 0) {
          this.setState({ left_arrow: true });
        }

        if (Math.floor(BY) > 0) {
          this.setState({ top_arrow: true });
        }
      });
    }
  };

  _draw_polygons = (g) => {
    let BX = this.BOUNDS.x;
    let BY = this.BOUNDS.y;
    let BW = this.BOUNDS.width;
    let BH = this.BOUNDS.height;

    GetSvgByNumcime(this.props.cimetier.NUMCIME).then((svg) => {
      _.forEach(svg, (s) => {
        if (
          s.POINTS.P1.X <= BX + BW &&
          s.POINTS.P2.X >= BX &&
          s.POINTS.P3.X >= BX &&
          s.POINTS.P4.X <= BX + BW &&
          s.POINTS.P1.Y <= BY + BH &&
          s.POINTS.P2.Y <= BY + BH &&
          s.POINTS.P3.Y >= BY &&
          s.POINTS.P4.Y >= BY
        ) {
          const sub_g = g.append("g").attr("cursor", "pointer").attr("licleunik", s.LICLEUNIK).style("user-select", "none");

          PlanOperateurPolygon(sub_g, s, parseInt(this.props.id_lieu));
        }
      });

      _.forEach(svg, (s) => {
        if (s.GELE === true || s.MONUMENTPRESENT === true || s.PASSE_PIED === true || (s.POSITIONSTELE >= 2 && s.POSITIONSTELE <= 6)) {
          d3.select(`g[licleunik='${s.LICLEUNIK}']`).raise();
        }
      });

      GetAllZone(this.props.cimetier.NUMCIME, 1, this.state.plan.PLAN_V4).then((zone) => {
        this.SVG.call(
          d3
            .zoom()
            .scaleExtent([1 / 2, 5 * zone.length])
            .on("zoom", this._zoomed_area.bind(this))
        );

        d3.select("g").on("click", (d, i, p) => {
          this.setState({ displaycard: false });
        });

        let LICLEUNIK = parseInt(this.props.id_lieu);

        setTimeout(() => {
          let polygon = d3
            .select("g")
            .select("g[licleunik='" + LICLEUNIK + "']")
            .node();

          if (polygon !== null) {
            polygon = polygon.getBoundingClientRect();

            let pos = [polygon.x + polygon.width / 2, polygon.y + polygon.height / 2];

            let rect = this.SVG.node().getBoundingClientRect();

            if (pos[0] > rect.right - 250) {
              pos[0] = pos[0] - 250;
            }

            if (pos[1] > rect.height - 100) {
              pos[1] = pos[1] - 100;
            }

            GetLieuById(LICLEUNIK).then((lieu) => {
              this.PREV_LICLEUNIK = LICLEUNIK;

              if (LICLEUNIK !== 0) {
                GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                  GetConcessById(lieu.COCLEUNIK).then((concess) => {
                    this.setState({
                      lieu_clique: lieu,
                      defunt: defunts,
                      concess: concess,
                      mousecoord: pos,
                      displaycard: true,
                    });
                  });
                });
              }
            });
          }
        }, 1000);

        d3.select("g")
          .selectAll("g")
          .on("click", (d, i, p) => {
            let pos = [d3.event.pageX, d3.event.pageY];
            let rect = this.SVG.node().getBoundingClientRect();

            if (pos[0] > rect.right - 250) {
              pos[0] = pos[0] - 250;
            }

            if (pos[1] > rect.height - 100) {
              pos[1] = pos[1] - 100;
            }

            let LICLEUNIK = d3.select(p[i]).attr("licleunik");
            this.PREV_LICLEUNIK = parseInt(LICLEUNIK);

            if (LICLEUNIK !== 0) {
              GetLieuById(LICLEUNIK).then((lieu) => {
                GetConcessById(lieu.COCLEUNIK).then((concess) => {
                  GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                    this.setState({
                      lieu_clique: lieu,
                      defunt: defunts,
                      concess: concess,
                      mousecoord: pos,
                      displaycard: true,
                    });
                  });
                });
              });
            }
          });
      });
    });
  };

  _zoomed_area = () => {
    if (this.SVG.selectAll("rect")._groups[0].length === 0) {
      const g = this.SVG.select("g");

      let BX = this.BOUNDS.x;
      let BY = this.BOUNDS.y;
      let BW = this.BOUNDS.width;
      let BH = this.BOUNDS.height;
      let BS = this.WIDTH / BW;
      let BT = [-BX * BS, -BY * BS];

      if (d3.event.transform.k <= BS) {
        d3.event.transform.k = BS;
        d3.event.transform.x = BT[0];
        d3.event.transform.y = BT[1];

        g.attr("transform", d3.event.transform);
      } else {
        if (d3.event.transform.x >= -(BX * d3.event.transform.k)) {
          d3.event.transform.x = -(BX * d3.event.transform.k);
        }

        if (d3.event.transform.y >= -(BY * d3.event.transform.k)) {
          d3.event.transform.y = -(BY * d3.event.transform.k);
        }

        if (d3.event.transform.x <= -(BW * d3.event.transform.k - 1024) - BX * d3.event.transform.k) {
          d3.event.transform.x = -(BW * d3.event.transform.k - 1024) - BX * d3.event.transform.k;
        }

        if (d3.event.transform.y <= -(BH * d3.event.transform.k - 768) - BY * d3.event.transform.k) {
          d3.event.transform.y = -(BH * d3.event.transform.k - 768) - BY * d3.event.transform.k;
        }

        g.attr("transform", d3.event.transform);

        if (this.state.displaycard) {
          this.setState({ displaycard: false });
        }
      }
    }
  };

  _handle_click_button_arrow = (direction) => (event) => {
    event.preventDefault();

    let BX = this.BOUNDS.x;
    let BY = this.BOUNDS.y;
    let BW = this.BOUNDS.width;
    let BH = this.BOUNDS.height;
    let BS = this.WIDTH / BW;

    switch (direction) {
      case "TOP":
        BY = this.BOUNDS.y = BY - this.Y_ARRAY[0];
        break;

      case "LEFT":
        BX = this.BOUNDS.x = BX - this.X_ARRAY[0];
        break;

      case "BOTTOM":
        BY = this.BOUNDS.y = BY + this.Y_ARRAY[0];
        break;

      case "RIGHT":
        BX = this.BOUNDS.x = BX + this.X_ARRAY[0];
        break;

      default:
        return;
    }

    let BT = [-BX * BS, -BY * BS];

    this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity.translate(BT[0], BT[1]).scale(BS));

    const g = this.SVG.select("g");
    g.selectAll("g").remove();

    this._draw_polygons(g);

    this.setState({
      bottom_arrow: Math.floor(BY) === 0 || BY + BH < this.HEIGHT,
      left_arrow: Math.floor(BX) > 0,
      right_arrow: Math.floor(BX) === 0 || BX + BW < this.WIDTH,
      top_arrow: Math.floor(BY) > 0,
      displaycard: false,
    });
  };

  _handle_close_sousplan_equipement = (event) => {
    event.preventDefault();

    this.SVG.attr("height", this.ISORACLE ? 617 : 768);
    this.SVG.attr("viewBox", `0 0 1024 ${this.ISORACLE ? 617 : 768}`);

    this.props.change_plang();

    this.setState({ afficheSousPlan: false });
  };

  _change_title = (title) => {
    this.setState({ titre_equipement: title });
  };

  _handle_click_card = (event) => {
    event.preventDefault();

    if (this.state.concess !== undefined) {
      if (!(this.props.page === "concess" && this.state.concess.LICLEUNIK === this.props.id_lieu)) {
        CurrentTab.setTab(0);

        if (this.props.reverse) {
          this.setState({
            redirect: `/consulter/fiche-concession/${this.state.concess.COCLEUNIK}/r`,
          });
        } else {
          this.setState({
            redirect: `/consulter/fiche-concession/${this.state.concess.COCLEUNIK}`,
          });
        }
      }
    }
  };

  _cree_chaine_emplacement = () => {
    var empl = "";

    if (this.state.lieu.EMPLACEMENT.CARRE.includes("Provisoire") || this.state.lieu.EMPLACEMENT.CARRE.includes("Non affect")) {
      if (this.state.concess !== undefined) {
        empl = this.state.concess.EMPL;
      }
    } else {
      empl = _.filter([
        _.filter([this.state.lieu.EMPLACEMENT.TYPECAR, this.state.lieu.EMPLACEMENT.CARRE]).join(" "),
        _.filter([this.state.lieu.EMPLACEMENT.TYPERG, this.state.lieu.EMPLACEMENT.RANG]).join(" "),
        _.filter([this.state.lieu.EMPLACEMENT.TYPEFOS, this.state.lieu.EMPLACEMENT.TOMBE]).join(" "),
      ]).join(" - ");

      const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

      if (reg.test(empl)) {
        const TYPESEC = this.state.lieu.EMPLACEMENT.TYPECAR.split("/")[0];
        const SECTION = this.state.lieu.EMPLACEMENT.CARRE.split("/")[0];
        const TYPECAR = this.state.lieu.EMPLACEMENT.TYPECAR.split("/")[1];
        const CARRE = this.state.lieu.EMPLACEMENT.CARRE.split("/")[1];

        empl = _.filter([
          _.filter([TYPESEC, SECTION]).join(" "),
          _.filter([TYPECAR, CARRE]).join(" "),
          _.filter([this.state.lieu.EMPLACEMENT.TYPERG, this.state.lieu.EMPLACEMENT.RANG]).join(" "),
          _.filter([this.state.lieu.EMPLACEMENT.TYPEFOS, this.state.lieu.EMPLACEMENT.TOMBE]).join(" "),
        ]).join(" - ");
      }
    }

    return empl;
  };

  _cree_chaine_date = (date) => {
    if (date !== "") {
      if (date.length === 4) {
        return date;
      } else {
        let c_date = date.split("T")[0].split("-");

        return new Date(c_date[0], c_date[1] - 1, c_date[2]).toLocaleDateString("fr-FR");
      }
    } else {
      return date;
    }
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <div>
        <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", minWidth: 36 }}>
            {this.state.left_arrow ? (
              <Button
                className="Button-PlanOperateurFiche Button-Left"
                variant="contained"
                color="primary"
                style={{ minWidth: 36, width: 36 }}
                ref={this._button_left}
                onClick={this._handle_click_button_arrow("LEFT")}
              >
                <PlayArrow />
              </Button>
            ) : null}
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", minHeight: 36 }}>
              {this.state.top_arrow ? (
                <Button
                  className="Button-PlanOperateurFiche Button-Up"
                  variant="contained"
                  color="primary"
                  style={{ flex: 1, borderRadius: 0 }}
                  ref={this._button_top}
                  onClick={this._handle_click_button_arrow("TOP")}
                >
                  <PlayArrow />
                </Button>
              ) : null}
            </div>

            <svg ref={this._svg_element} width={1024} height={this.ISORACLE ? 617 : 768} preserveAspectRatio="xMinYMin meet" viewBox={`0 0 1024 ${this.ISORACLE ? 617 : 768}`}></svg>

            <div style={{ display: "flex", minHeight: 36 }}>
              {this.state.bottom_arrow ? (
                <Button
                  className="Button-PlanOperateurFiche Button-Down"
                  variant="contained"
                  color="primary"
                  style={{ flex: 1, borderRadius: 0 }}
                  ref={this._button_bottom}
                  onClick={this._handle_click_button_arrow("BOTTOM")}
                >
                  <PlayArrow />
                </Button>
              ) : null}
            </div>
          </div>

          <div style={{ display: "flex", minWidth: 36 }}>
            {this.state.right_arrow ? (
              <Button
                className="Button-PlanOperateurFiche Button-Right"
                variant="contained"
                color="primary"
                style={{ minWidth: 36, width: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                ref={this._button_right}
                disabled={this.state.right_arrow ? false : true}
                onClick={this._handle_click_button_arrow("RIGHT")}
              >
                <PlayArrow />
              </Button>
            ) : null}
          </div>
        </div>

        <Dialog fullScreen open={this.state.afficheSousPlan} onClose={this._handle_close_sousplan_equipement} TransitionComponent={Transition}>
          <AppBar style={{ paddingLeft: 17 }}>
            <Toolbar disableGutters>
              <Typography variant="h6" style={styles.typography}>
                {this.state.titre_equipement !== "" ? this.state.titre_equipement : "Sous plan"}
              </Typography>

              <IconButton color="inherit" aria-label="close" onClick={this._handle_close_sousplan_equipement}>
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Toolbar />

          <CCSousPlanEquipement
            zocleunik={this.ZOCLEUNIK}
            id_lieu={this.props.id_lieu}
            changeTitle={this._change_title}
            closeDialog={() => {
              this.setState({ afficheSousPlan: false });
            }}
            reverse={false}
          />
        </Dialog>

        {this.state.lieu_clique !== "" && this.state.displaycard ? (
          <div>
            <Card
              style={{
                ...styles.card,
                ...{
                  position: "absolute",
                  left: this.state.mousecoord[0],
                  top: this.state.mousecoord[1],
                  cursor: "pointer",
                },
              }}
              onClick={this._handle_click_card}
            >
              <CardContent style={{ paddingBottom: 16 }}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {this._cree_chaine_emplacement()}
                </Typography>

                <Button
                  style={{ width: 15, height: 15, minWidth: 15, position: "absolute", top: 10, right: 10 }}
                  onClick={(event) => {
                    event.stopPropagation();

                    this.setState({ displaycard: false });
                  }}
                >
                  &times;
                </Button>

                {this.state.concess !== undefined ? (
                  this.state.concess.FAMILLE !== "" ? (
                    <Typography variant="body2" color="textSecondary" component="p">
                      {`Famille ${this.state.concess.FAMILLE}`}
                    </Typography>
                  ) : null
                ) : this.state.defunt.length > 0 ? (
                  this.state.defunt.map((defunt, index) => (
                    <StyleTypography
                      key={index}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      onClick={() =>
                        this.setState({
                          redirect: `/consulter/fiche-defunt/${defunt.D0CLEUNIK}`,
                        })
                      }
                    >
                      {`${defunt.ETAT_CIVIL.NOMDEF} ${defunt.ETAT_CIVIL.PRENOMDEF}`}
                    </StyleTypography>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary" component="p">
                    Emplacement libre
                  </Typography>
                )}

                {this.state.concess !== undefined ? (
                  !this.state.concess.DATEEXPIRA.includes("1900-01-01") ? (
                    <Typography variant="body2" color="textSecondary" component="p">
                      {`Expire le ${this._cree_chaine_date(this.state.concess.DATEEXPIRA)}`}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary" component="p">
                      Pas de date d'expiration
                    </Typography>
                  )
                ) : null}
              </CardContent>
            </Card>
          </div>
        ) : null}
      </div>
    );
  }
}

export default PlanOperateurFiche;
