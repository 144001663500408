import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  withStyles,
  TableSortLabel,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  TextField,
  Snackbar,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Delete, Exposure, ExpandMore } from "@material-ui/icons";
import jsonpackage from "../../package.json";
import { IsOracle } from "../Helpers/Test/IsOracle";

import { GetAllTemporaire } from "../Helpers/Get/Temporaire";
import { GetClient } from "../Helpers/Get/Client";
import { GetConcaireById, GetConcaireByCocleunik } from "../Helpers/Get/Concaire";
import { GetPhotoconcById, GetPhotoconcByCocleunik, GetPhotoconcByIdTablette } from "../Helpers/Get/Photoconc";
import { Cree_iso_date2, handleError } from "../Helpers/Function";
import { GetDefuntByCocleunik, GetDefuntById } from "../Helpers/Get/Defunt";
import { GetConcessById, GetConcessByRowId } from "../Helpers/Get/Concess";
import { GetLieuByCocleunik, GetLieuById } from "../Helpers/Get/Lieu";
import { GetNewDatePrestaCarto, GetNewDateSynchro } from "../Helpers/Get/GetNewDateSynchro";
import b64toBlob from "b64-to-blob";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";

import { DeleteConcaire } from "../Helpers/Delete/Concaire";
import { DeleteConcess } from "../Helpers/Delete/Concess";
import { DeleteDefunt } from "../Helpers/Delete/Defunt";
import DeleteTemporaire from "../Helpers/Delete/Temporaire";
import UpdateTemporaire from "../Helpers/Update/Temporaire";
import UpdateConcaire from "../Helpers/Update/Concaire";
import { DeletePhotoconc } from "../Helpers/Delete/Photoconc";
import UpdatePhotoconc from "../Helpers/Update/Photoconc";
import AddPhotoconc from "../Helpers/Add/Photoconc";
import UpdateDefunt from "../Helpers/Update/Defunt";
import DeleteDefuntS from "../Helpers/Delete/DefuntS";
import UpdateDefuntS from "../Helpers/Update/DefuntS";
import UpdateInterven from "../Helpers/Update/Interven";
import UpdateConcess from "../Helpers/Update/Concess";
import UpdateLieu from "../Helpers/Update/Lieu";
import { GetAllCimetier } from "../Helpers/Get/Cimetier";
import UpdateSvg from "../Helpers/Update/Svg";
import { GetIntervenByCocleunik, GetIntervenById } from "../Helpers/Get/Interven";
import CreateTable_MDF from "../Helpers/CreateTable_MDF";
import { GetLastestGescimeBuild } from "../Helpers/Get/LastestGescimeBuild";

import conf from "../assets/.conf.txt";
import { Alert } from "@material-ui/lab";
import DialogVersion from "./Global/DialogVersion";
import DialogErreur from "./Global/DialogErreur";
import DialogMajMdf from "./Global/DialogMajMdf";
import Opposition from "./Global/OppositionTablette";
import { GetUtilById } from "../Helpers/Get/Util";
import SessionTimer from "./Global/Object/SessionTimer";
import TemporaireEnErreur from "./Global/Object/TemporaireEnErreur";
import LoaderState from "./Global/Object/LoaderState";
import { DeleteDatabase } from "../Helpers/Delete/Database";
import CreateTable from "../Helpers/CreateTableSansPhotos";
import UpdateClientDateSynchro from "../Helpers/Update/Client";

var _ = require("lodash");

const ColorButton = withStyles((theme) => ({
  root: {
    "&:hover": {
      color: "#FF732F",
    },
  },
}))(IconButton);

const headCells = [
  { id: "TYPE_OPERATION", label: "Type" },
  { id: "TITRE", label: "Libellé" },
  { id: "DATE1", label: "Envoyé" },
  { id: "DATE2", label: "Importé" },
  { id: "DATE3", label: "Validé" },
  { id: "DATE4", label: "Refusé" },
];

const styles = {
  grid: {
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  TableCell: {
    fontSize: 12,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  submit_div: {
    display: "flex",
    justifyContent: "center",
    marginTop: 8,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, displayTrash, onClickTrash, checkAll, checkAllClick, tableEmpty } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span style={styles.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{ textAlign: "center" }}>
          {displayTrash === true ? (
            <ColorButton aria-label="Supprimer" onClick={onClickTrash}>
              <Delete />
            </ColorButton>
          ) : tableEmpty === false ? (
            <Checkbox checked={checkAll} onClick={checkAllClick} icon={<Exposure />} />
          ) : null}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

class Synchronisation extends Component {
  static propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  };

  constructor(props) {
    super(props);

    this.IS_ORACLE = false;
    this.ID_TABLETTE = "";

    this.state = {
      IS_ONLINE: null,
      TEMPORAIRE: [],
      REDIRECT: null,
      selected: null,
      order: "desc",
      orderBy: "DATE1",
      page: 0,
      rowsPerPage: 10,
      checked_trash: [],
      table_empty: false,
      open_trash_dialog: false,
      checked_all_trash: false,
      disabledForm: false,
      cursor: [],
      liste_tables: [
        "GNUTIL",
        "PARAM",
        "DEFUNT",
        "LIEU",
        "CENDRE",
        "PLAN",
        "ZONE",
        "CONCESS",
        "CONCAIRE",
        "TYPETB",
        "DUREE",
        "PHOTOCONC",
        "INTERVEN",
        "INTERVEN_DEFUNT",
        "NATINTERV",
        "PROCEDUREABANDON",
        "SOCIETE",
        "UTIL",
        "GENS",
        "AGENDATACHE",
        "MOUVEMENT",
        "ACCESSIBILITE",
        "ETAT_TERRAIN",
        "CONCESS_ETAT_ECHEANCE",
        "THANATOMORPHOSE",
        "MATERIAU",
        "PA",
        "LIGNE_ACTION",
        "SVG",
        "DEFUNT_S",
        "SUPPRESSION",
        "PURGEGNET",
        "PURGETABLETTE",
      ],
      loading: false,
      data_loaded: 0,
      table_loading: "",
      nb_total_photos_loading: 0,
      nb_photos_enregistre: 0,
      dialog_version: false,
      dialog_erreur: false,
      dialog_actualiser: false,
      dialog_actualiser_terminer: false,
      open_snackbar_envoye: false,
      open_snackbar_telechargement: false,
      demandeDeMiseAJours: false,
      dialogMiseAJourEnCours: false,
      prestaCarto: false,
    };

    props.changeTitle(props.title);

    fetch(`${jsonpackage.urlapi}IsOnline`, {
      mode: "cors",
    })
      .then(() => {
        this.setState({
          IS_ONLINE: true,
        });
      })
      .catch(() => {
        this.setState({
          IS_ONLINE: false,
        });
      });
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    IsOracle().then((oracle) => {
      this.IS_ORACLE = oracle;
    });

    GetAllTemporaire().then((temporaire) => {
      let table_empty = temporaire.length === 0;
      const new_temp = this._updateTemporaireTri(temporaire);

      this._getCursor(new_temp);

      this.setState({ TEMPORAIRE: new_temp, table_empty: table_empty });
    });

    this._updateTableTemporaire();
  };

  _updateTableTemporaire = () => {
    let index2 = 0;

    this.setState({ dialog_actualiser: true }, () => {
      GetClient().then((client) => {
        this.ID_TABLETTE = client.ID_TABLETTE;

        var idgens = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

        const headers = {
          _cle: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
          _idutil: parseInt(idgens),
          _idtablette: this.ID_TABLETTE,
          "Content-Type": "application/json",
        };

        GetUtilById(idgens).then((gens) => {
          if (gens !== undefined) {
            GetClient().then((clientTablette) => {
              const date = new Date();
              date.setHours(date.getHours() - date.getTimezoneOffset() / 60, date.getMinutes(), date.getSeconds());

              const data = {
                ID_TABLETTE: clientTablette.ID_TABLETTE,
                ID_CLIENT: clientTablette.IDCLIENT,
                NOM_UTIL_GESCIME: gens.LOGIN,
                DATE_DERNIERE_CONNEXION: date.toISOString().slice(0, -5),
              };

              fetch(`${jsonpackage.urlapi}EnregistrementTablette`, {
                mode: "cors",
                method: "POST",
                headers: headers,
                body: JSON.stringify(data),
              })
                .then((result) => result.text())
                .catch((err) => {
                  console.error(err);
                });
            });
          }
        });

        GetAllTemporaire().then((temp) => {
          headers._idTemporaire = temp
            .filter((temporaire) => {
              return temporaire.DATE_DE_CREATION !== null ? temporaire.ID_TEMPORAIRE : null;
            })
            .map((temporaire) => temporaire.ID_TEMPORAIRE)
            .join(",");

          if (temp.length === 0) {
            this.setState({ dialog_actualiser: false, dialog_actualiser_terminer: true });
          }

          fetch(`${jsonpackage.urlapi}TEMPORAIRE?start=-1&end=-1`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
            .then((result) => result.json())
            .then((result) => {
              _.forEach(temp, (temporaire, index) => {
                if (temporaire.DATE_DE_CREATION !== null) {
                  var t = _.find(result, ["ID_TEMPORAIRE", temporaire.ID_TEMPORAIRE]);

                  if (t === undefined) {
                    if (temporaire.ETAT_INITIAL !== null) {
                      this._updateEntiteObject(temporaire);
                    } else {
                      this._deleteEntiteObject(temporaire);
                    }

                    DeleteTemporaire(temporaire.id)
                      .then(() => {
                        let checked_trash = _.remove(this.state.checked_trash, temporaire.id);

                        GetAllTemporaire().then((listTemporaire) => {
                          let table_empty = listTemporaire.length === 0;
                          const new_temp = this._updateTemporaireTri(listTemporaire);

                          this.setState(
                            {
                              TEMPORAIRE: new_temp,
                              table_empty: table_empty,
                              checked_trash: checked_trash,
                            },
                            () => {
                              setTimeout(() => {
                                index2++;

                                if (index2 === temp.length) {
                                  this._updateTableTemporaire2(headers);
                                }
                              }, 500);
                            }
                          );
                        });
                      })
                      .catch((Err) => handleError(Err));
                  } else {
                    setTimeout(() => {
                      index2++;

                      if (index2 === temp.length) {
                        this._updateTableTemporaire2(headers);
                      }
                    }, 500);
                  }
                } else {
                  setTimeout(() => {
                    index2++;

                    if (index2 === temp.length) {
                      this._updateTableTemporaire2(headers);
                    }
                  }, 500);
                }
              });
            });
        });
      });
    });
  };

  _updateTableTemporaire2 = (headers) => {
    fetch(`${jsonpackage.urlapi}TEMPORAIRE?start=-1&end=-1`, {
      mode: "cors",
      method: "GET",
      headers: headers,
    })
      .then((result) => result.json())
      .then((result) => {
        var refused = 0;

        let old_cocleunik = 0;
        let new_cocleunik = 0;

        result = _.filter(result, (r) => {
          return r.ID_TABLETTE === this.ID_TABLETTE && r.DATE_D_IMPORT_DANS_GESCIME !== null;
        });

        if (result.length === 0) {
          this.setState({ dialog_actualiser: false, dialog_actualiser_terminer: true });
        }

        _.forEach(result, (temporaire, index) => {
          _.set(temporaire, "id", parseInt(temporaire.ID_APPLICATION_GARDIENS));

          if (temporaire.ID_GESCIME === 0) {
            _.set(temporaire, "ID_GESCIME", null);
          }

          _.set(temporaire, "TABLE_MODIFIE_JSON", JSON.parse(temporaire.TABLE_MODIFIE_JSON));

          if (_.find(this.state.TEMPORAIRE, ["id", temporaire.id]) !== undefined) {
            _.set(temporaire, "ETAT_INITIAL", _.find(this.state.TEMPORAIRE, ["id", temporaire.id]).ETAT_INITIAL);

            if (temporaire.DATE_TRAITE_DANS_GESCIME !== null && temporaire.TYPE_D_ENTITE === "CONCESS" && temporaire.TYPE_D_ACTION === 1) {
              old_cocleunik = _.find(this.state.TEMPORAIRE, ["id", temporaire.id]).TABLE_MODIFIE_JSON.COCLEUNIK;
              new_cocleunik = temporaire.TABLE_MODIFIE_JSON.COCLEUNIK;
            }

            if (old_cocleunik < 0 || temporaire.DATE_D_IMPORT_DANS_GESCIME !== null) {
              UpdateTemporaire(temporaire)
                .then(() => {
                  if (temporaire.DATE_TRAITE_DANS_GESCIME !== null && temporaire.STATUS_DE_VALIDATION === 3) {
                    if (_.find(this.state.TEMPORAIRE, ["id", temporaire.id]).DATE_TRAITE_DANS_GESCIME === null) {
                      ++refused;
                    }

                    if (temporaire.ETAT_INITIAL !== null) {
                      if (temporaire.TYPE_D_ACTION === 2) {
                        this._updateEntiteObject(temporaire);
                      } else {
                        this._addEntiteObjet(temporaire.TYPE_D_ENTITE, temporaire.ETAT_INITIAL);
                      }
                    } else {
                      this._deleteEntiteObject(temporaire);
                    }
                  } else {
                    if (temporaire.ETAT_INITIAL === null) {
                      this._updateEntiteObject2(temporaire);
                    }
                  }

                  if (index === result.length - 1) {
                    GetAllTemporaire().then((temporaire) => {
                      _.forEach(temporaire, (temp) => {
                        if (old_cocleunik < 0 && temp.TABLE_MODIFIE_JSON.COCLEUNIK === old_cocleunik) {
                          temp.TABLE_MODIFIE_JSON.COCLEUNIK = new_cocleunik;

                          if (temp.ETAT_INITIAL !== null) {
                            temp.ETAT_INITIAL.COCLEUNIK = new_cocleunik;
                          }

                          UpdateTemporaire(temp).then(() => {
                            if (temp.STATUS_DE_VALIDATION < 3) {
                              this._updateEntiteObject2(temp);
                            }

                            const new_temp = this._updateTemporaireTri(temporaire);

                            this._getCursor(new_temp);

                            if (refused > 0) {
                              this.setState({ TEMPORAIRE: new_temp });
                            } else {
                              this.setState({ TEMPORAIRE: new_temp });
                            }
                          });
                        } else {
                          if (temp.TYPE_D_ACTION === 2 && temp.STATUS_DE_VALIDATION < 3) {
                            //this._updateEntiteObject2(temp);

                            this._updadeNewObject(temp);
                          }

                          if (temp.TYPE_D_ACTION === 1 && temp.STATUS_DE_VALIDATION < 3) {
                            this._addEntiteObjet(temp.TYPE_D_ENTITE, temp.TABLE_MODIFIE_JSON);
                          }

                          if (temp.STATUS_DE_VALIDATION === 3 && temp.TYPE_D_ENTITE === "PHOTOCONC") {
                            GetAllTemporaire().then((temp2) => {
                              let filtered = _.filter(temp2, (t2) => {
                                return t2.TABLE_MODIFIE_JSON.COCLEUNIK === temp.TABLE_MODIFIE_JSON.COCLEUNIK && t2.TYPE_D_ENTITE === "PHOTOCONC" && t2.DATE_TRAITE_DANS_GESCIME !== null;
                              });

                              let ordered = _.orderBy(filtered, ["DATE_TRAITE_DANS_GESCIME"], ["desc"])[0];

                              if (ordered.STATUS_DE_VALIDATION === 3 && ordered.ETAT_INITIAL !== null) {
                                let subfiltered = _.filter(temp2, (t2) => {
                                  return t2.TABLE_MODIFIE_JSON.COCLEUNIK === ordered.TABLE_MODIFIE_JSON.COCLEUNIK && t2.TABLE_MODIFIE_JSON.IDPHOTOCONC !== ordered.TABLE_MODIFIE_JSON.IDPHOTOCONC;
                                });

                                _.forEach(subfiltered, (sf) => {
                                  if (sf.STATUS_DE_VALIDATION === 3) {
                                    sf.ETAT_INITIAL[this.IS_ORACLE ? "REFERENTE" : "PRINCIPAL"] = ordered.ETAT_INITIAL[this.IS_ORACLE ? "REFERENTE" : "PRINCIPAL"] === 1 ? 0 : 1;

                                    UpdatePhotoconc(sf.ETAT_INITIAL);
                                  } else {
                                    let PHOTOCONC = _.cloneDeep(sf.TABLE_MODIFIE_JSON);
                                    PHOTOCONC[this.IS_ORACLE ? "REFERENTE" : "PRINCIPAL"] = ordered.ETAT_INITIAL[this.IS_ORACLE ? "REFERENTE" : "PRINCIPAL"] === 1 ? 0 : 1;

                                    GetPhotoconcByIdTablette(PHOTOCONC.id).then((photo) => {
                                      PHOTOCONC.PHOTO = photo.PHOTO;
                                      PHOTOCONC.SIGNATURE_AGENT = photo.SIGNATURE_AGENT;
                                      PHOTOCONC.SIGNATURE_SOCIETE = photo.SIGNATURE_SOCIETE;

                                      if (this.IS_ORACLE) {
                                        if (PHOTOCONC.IDEVOL_ETAT !== undefined) PHOTOCONC.IDPHOTOCONC = PHOTOCONC.IDEVOL_ETAT;
                                        if (PHOTOCONC.DATE_EVOL !== undefined) PHOTOCONC.DATEAJOUT = PHOTOCONC.DATE_EVOL;
                                        if (PHOTOCONC.REFERENTE !== undefined) PHOTOCONC.PRINCIPAL = PHOTOCONC.REFERENTE;
                                        if (PHOTOCONC.ETAT_EVOL !== undefined) PHOTOCONC.NOTES = PHOTOCONC.ETAT_EVOL;
                                        delete PHOTOCONC.IDEVOL_ETAT;
                                        delete PHOTOCONC.DATE_EVOL;
                                        delete PHOTOCONC.REFERENTE;
                                        delete PHOTOCONC.ETAT_EVOL;
                                      }

                                      UpdatePhotoconc(PHOTOCONC).catch((Err) => handleError(Err));
                                    });
                                  }
                                });
                              }

                              if (ordered.STATUS_DE_VALIDATION === 2) {
                                let subfiltered;

                                if (this.IS_ORACLE) {
                                  subfiltered = _.filter(temp2, (t2) => {
                                    return t2.TABLE_MODIFIE_JSON.COCLEUNIK === ordered.TABLE_MODIFIE_JSON.COCLEUNIK && t2.TABLE_MODIFIE_JSON.IDEVOL_ETAT !== ordered.TABLE_MODIFIE_JSON.IDEVOL_ETAT;
                                  });
                                } else {
                                  subfiltered = _.filter(temp2, (t2) => {
                                    return t2.TABLE_MODIFIE_JSON.COCLEUNIK === ordered.TABLE_MODIFIE_JSON.COCLEUNIK && t2.TABLE_MODIFIE_JSON.IDPHOTOCONC !== ordered.TABLE_MODIFIE_JSON.IDPHOTOCONC;
                                  });
                                }

                                let subordered = _.orderBy(subfiltered, ["DATE_TRAITE_DANS_GESCIME"], ["desc"])[0];

                                GetPhotoconcByIdTablette(subordered.TABLE_MODIFIE_JSON.id).then((photo) => {
                                  if (photo !== undefined) {
                                    photo.PRINCIPAL = subordered.TABLE_MODIFIE_JSON[this.IS_ORACLE ? "REFERENTE" : "PRINCIPAL"];

                                    UpdatePhotoconc(photo)
                                      .then(() => {
                                        GetPhotoconcByIdTablette(ordered.TABLE_MODIFIE_JSON.id).then((photo2) => {
                                          photo2.PRINCIPAL = ordered.TABLE_MODIFIE_JSON[this.IS_ORACLE ? "REFERENTE" : "PRINCIPAL"];

                                          UpdatePhotoconc(photo2).catch((Err) => handleError(Err));
                                        });
                                      })
                                      .catch((Err) => handleError(Err));
                                  }
                                });
                              }
                            });
                          }
                        }
                      });
                    });

                    GetAllTemporaire().then((temporaire) => {
                      const new_temp = this._updateTemporaireTri(temporaire);

                      this._getCursor(new_temp);

                      this.setState({ TEMPORAIRE: new_temp }, () => {
                        this.setState({ dialog_actualiser: false, dialog_actualiser_terminer: true });
                      });
                    });
                  }
                })
                .catch((Err) => handleError(Err));
            }
          } else {
            console.log("nouvelle condition");

            setTimeout(() => {
              if (index === result.length - 1) {
                this.setState({ dialog_actualiser: false, dialog_actualiser_terminer: true });
              }
            }, 1000);
          }
        });
      });
  };

  _updadeNewObject = (temporaire) => {
    if (temporaire.DATE_TRAITE_DANS_GESCIME === null || temporaire.TYPE_D_ACTION === 2) {
      switch (temporaire.TYPE_D_ENTITE) {
        case "CONCAIRE":
          GetConcaireById(temporaire.TABLE_MODIFIE_JSON.C0CLEUNIK).then((concaire) => {
            var miseajour = _.assign({}, concaire, temporaire.TABLE_MODIFIE_JSON);

            if (miseajour.ADRMAIL !== undefined) {
              miseajour.ADRESSEMAIL = miseajour.ADRMAIL;
              delete miseajour.ADRMAIL;
            }

            if (miseajour.TYPEINTERLO !== undefined) {
              let typeinterlo = miseajour.TYPEINTERLO;

              miseajour.TYPEINTERLO.CONCESSIONNAIRE = miseajour.TYPEINTERLO.CONCESSIONNAIRE !== undefined ? miseajour.TYPEINTERLO.CONCESSIONNAIRE : parseInt(typeinterlo[0]);
              miseajour.TYPEINTERLO.COCONCESSIONNAIRE = miseajour.TYPEINTERLO.COCONCESSIONNAIRE !== undefined ? miseajour.TYPEINTERLO.COCONCESSIONNAIRE : parseInt(typeinterlo[1]);
              miseajour.TYPEINTERLO.AYANTDROIT = miseajour.TYPEINTERLO.AYANTDROIT !== undefined ? miseajour.TYPEINTERLO.AYANTDROIT : parseInt(typeinterlo[2]);
              miseajour.TYPEINTERLO.INHUMABLE = miseajour.TYPEINTERLO.INHUMABLE !== undefined ? miseajour.TYPEINTERLO.INHUMABLE : parseInt(typeinterlo[3]);
              miseajour.TYPEINTERLO.AUTRE = miseajour.TYPEINTERLO.AUTRE !== undefined ? miseajour.TYPEINTERLO.AUTRE : parseInt(typeinterlo[4]);

              if (this.IS_ORACLE) {
                miseajour.TYPEINTERLO.DONATAIRE_LEGATAIRE = miseajour.TYPEINTERLO.DONATAIRE_LEGATAIRE !== undefined ? miseajour.TYPEINTERLO.DONATAIRE_LEGATAIRE : parseInt(typeinterlo[5]);
                miseajour.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE = miseajour.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE !== undefined ? miseajour.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE : parseInt(typeinterlo[6]);
                miseajour.TYPEINTERLO.INDESIRABLE = 0;
              } else {
                miseajour.TYPEINTERLO.DONATAIRE_LEGATAIRE = 0;
                miseajour.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE = 0;
                miseajour.TYPEINTERLO.INDESIRABLE = parseInt(typeinterlo[5]);

                if (miseajour.ADR_NUMERO === undefined) miseajour.ADR_NUMERO = "";
                if (miseajour.ADR_BISTER === undefined) miseajour.ADR_BISTER = "";
                if (miseajour.ADR_COMP === undefined) miseajour.ADR_COMP = "";
              }
            }

            UpdateConcaire(miseajour).catch((Err) => handleError(Err));
          });

          break;
        case "PHOTOCONC":
          var miseajour = temporaire.TABLE_MODIFIE_JSON;

          GetPhotoconcByIdTablette(miseajour.id).then((photo) => {
            if (photo !== undefined) {
              if (miseajour.AGENT !== undefined) {
                photo.AGENT = miseajour.AGENT;
              }

              if (miseajour.MONUMENT_DERRIERE !== undefined) {
                photo.MONUMENT_DERRIERE = miseajour.MONUMENT_DERRIERE;
              }

              if (miseajour.MONUMENT_DEVANT !== undefined) {
                photo.MONUMENT_DEVANT = miseajour.MONUMENT_DEVANT;
              }

              if (miseajour.MONUMENT_DROITE !== undefined) {
                photo.MONUMENT_DROITE = miseajour.MONUMENT_DROITE;
              }

              if (miseajour.MONUMENT_ETAT !== undefined) {
                photo.MONUMENT_ETAT = miseajour.MONUMENT_ETAT;
              }

              if (miseajour.MONUMENT_GAUCHE !== undefined) {
                photo.MONUMENT_GAUCHE = miseajour.MONUMENT_GAUCHE;
              }

              if (miseajour.NOTES !== undefined) {
                photo.NOTES = miseajour.NOTES;
              }

              if (miseajour.PHOTO !== undefined && miseajour.PHOTO !== null) {
                if (miseajour.PHOTO !== "") {
                  const image = miseajour.PHOTO.replace(/data:image.*base64,/, "");
                  photo.PHOTO = b64toBlob(image, "image/png");
                }
              }

              if (miseajour.PRINCIPAL !== undefined) {
                photo.PRINCIPAL = miseajour.PRINCIPAL;
              }

              if (miseajour.SEMELLE_ETAT !== undefined) {
                photo.SEMELLE_ETAT = miseajour.SEMELLE_ETAT;
              }

              if (miseajour.SIGNATURE_AGENT !== undefined && miseajour.SIGNATURE_AGENT !== null) {
                if (miseajour.SIGNATURE_AGENT !== "") {
                  const image = miseajour.SIGNATURE_AGENT.replace(/data:image.*base64,/, "");
                  photo.SIGNATURE_AGENT = b64toBlob(image, "image/png");
                }
              }

              if (miseajour.SIGNATURE_SOCIETE !== undefined && miseajour.SIGNATURE_SOCIETE !== null) {
                if (miseajour.SIGNATURE_SOCIETE !== "") {
                  const image = miseajour.SIGNATURE_SOCIETE.replace(/data:image.*base64,/, "");
                  photo.SIGNATURE_SOCIETE = b64toBlob(image, "image/png");
                }
              }

              if (miseajour.SOCIETE !== undefined) {
                photo.SOCIETE = miseajour.SOCIETE;
              }

              if (this.IS_ORACLE) {
                if (miseajour.IDEVOL_ETAT !== undefined) photo.IDPHOTOCONC = miseajour.IDEVOL_ETAT;
                if (miseajour.DATE_EVOL !== undefined) photo.DATEAJOUT = miseajour.DATE_EVOL;
                if (miseajour.REFERENTE !== undefined) photo.PRINCIPAL = miseajour.REFERENTE;
                if (miseajour.ETAT_EVOL !== undefined) photo.NOTES = miseajour.ETAT_EVOL;
              }

              UpdatePhotoconc(photo).catch((Err) => handleError(Err));
            }
          });
          break;
        case "DEFUNT":
          GetDefuntById(temporaire.TABLE_MODIFIE_JSON.D0CLEUNIK).then((defunt) => {
            var miseajour = _.assign({}, defunt, temporaire.TABLE_MODIFIE_JSON);

            if (miseajour.NOMDEF !== undefined) {
              miseajour.ETAT_CIVIL.NOMDEF = miseajour.NOMDEF;
              delete miseajour.NOMDEF;
            }

            if (miseajour.NOMJEUNE !== undefined) {
              miseajour.ETAT_CIVIL.NOMJEUNE = miseajour.NOMJEUNE;
              delete miseajour.NOMJEUNE;
            }

            if (miseajour.PRENOMDEF !== undefined) {
              miseajour.ETAT_CIVIL.PRENOMDEF = miseajour.PRENOMDEF;
              delete miseajour.PRENOMDEF;
            }

            if (miseajour.DATENAISS !== undefined) {
              miseajour.ETAT_CIVIL.DATENAISS = miseajour.DATENAISS;
              delete miseajour.DATENAISS;
            }

            if (miseajour.DATEDECES !== undefined) {
              miseajour.ETAT_CIVIL.DATEDECES = miseajour.DATEDECES;
              delete miseajour.DATEDECES;
            }

            if (miseajour.DATEINHUM !== undefined) {
              miseajour.ETAT_CIVIL.DATEINHUM = `${miseajour.DATEINHUM !== "" ? miseajour.DATEINHUM : "1900-01-01"}T00:00:01`;
              delete miseajour.DATEINHUM;
            }

            if (miseajour.HEUREMOUV !== undefined && miseajour.HEUREMOUV !== "") {
              let dateinhu = miseajour.ETAT_CIVIL.DATEINHUM !== "" ? miseajour.ETAT_CIVIL.DATEINHUM : "1900-01-01";

              dateinhu = `${dateinhu.split("T")[0]}T${miseajour.HEUREMOUV}:01`;
              miseajour.ETAT_CIVIL.DATEINHUM = dateinhu;
            }

            if (miseajour.AGE !== undefined) {
              miseajour.ETAT_CIVIL.AGE = miseajour.AGE;
              delete miseajour.AGE;
            }

            if (this.IS_ORACLE) {
              if (miseajour.CIVDEF !== undefined) {
                miseajour.ETAT_CIVIL.SEXE = miseajour.CIVDEF;
                delete miseajour.CIVDEF;
              }

              if (miseajour.ANNEEDECES !== undefined) {
                miseajour.ETAT_CIVIL.ANNEEDECES = miseajour.ANNEEDECES;
                delete miseajour.ANNEEDECES;
              }
            } else {
              if (miseajour.SEXE !== undefined) {
                miseajour.ETAT_CIVIL.SEXE = miseajour.SEXE;
                delete miseajour.SEXE;

                miseajour.ETAT_CIVIL.ANNEEDECES = 0;
              }
            }

            if (miseajour.INFOPLUS !== undefined) {
              miseajour.ENVELOPPE.URNE = parseInt(miseajour.INFOPLUS[0]);
              miseajour.ENVELOPPE.RELIQUAIRE = parseInt(miseajour.INFOPLUS[1]);

              if (this.IS_ORACLE) {
                miseajour.ENVELOPPE.ENFANT = 0;
                miseajour.ENVELOPPE.DISPERSION = parseInt(miseajour.INFOPLUS[2]);
                miseajour.ENVELOPPE.URNE_SCELLE = parseInt(miseajour.INFOPLUS[3]);
              } else {
                miseajour.ENVELOPPE.ENFANT = parseInt(miseajour.INFOPLUS[2]);
                miseajour.ENVELOPPE.DISPERSION = parseInt(miseajour.INFOPLUS[3]);
                miseajour.ENVELOPPE.URNE_SCELLE = parseInt(miseajour.INFOPLUS[4]);
              }

              delete miseajour.INFOPLUS;
            }

            if (miseajour.BIERRE !== undefined) {
              const bierre = miseajour.BIERRE;

              miseajour.BIERRE.MEBI_MC = miseajour.BIERRE.MEBI_MC !== undefined ? miseajour.BIERRE.MEBI_MC : parseInt(bierre[0]);
              miseajour.BIERRE.SOINS_CONSERVATIONS = miseajour.BIERRE.SOINS_CONSERVATIONS !== undefined ? miseajour.BIERRE.SOINS_CONSERVATIONS : parseInt(bierre[1]);
              miseajour.BIERRE.HERMETIQUE = miseajour.BIERRE.HERMETIQUE !== undefined ? miseajour.BIERRE.HERMETIQUE : parseInt(bierre[2]);
              miseajour.BIERRE.ZINC_HN = miseajour.BIERRE.ZINC_HN !== undefined ? miseajour.BIERRE.ZINC_HN : parseInt(bierre[3]);
            }

            const enfantdc = miseajour.ENFANTDC;
            if (this.IS_ORACLE) {
              miseajour.ENFANTDC.AUCUN = miseajour.ENFANTDC.AUCUN !== undefined ? miseajour.ENFANTDC.AUCUN : enfantdc === 1 ? 1 : 0;
              miseajour.ENFANTDC.ENFANT = miseajour.ENFANTDC.ENFANT !== undefined ? miseajour.ENFANTDC.ENFANT : enfantdc === 2 ? 1 : 0;
              miseajour.ENFANTDC.NOUVEAU_NE = miseajour.ENFANTDC.NOUVEAU_NE !== undefined ? miseajour.ENFANTDC.NOUVEAU_NE : enfantdc === 3 ? 1 : 0;
              miseajour.ENFANTDC.MORT_NE = miseajour.ENFANTDC.MORT_NE !== undefined ? miseajour.ENFANTDC.MORT_NE : enfantdc === 4 ? 1 : 0;
              miseajour.ENFANTDC.FOETUS = miseajour.ENFANTDC.FOETUS !== undefined ? miseajour.ENFANTDC.FOETUS : enfantdc === 5 ? 1 : 0;
            } else {
              miseajour.ENFANTDC.AUCUN = miseajour.ENFANTDC.AUCUN !== undefined ? miseajour.ENFANTDC.AUCUN : 0;
              miseajour.ENFANTDC.ENFANT = miseajour.ENFANTDC.ENFANT !== undefined ? miseajour.ENFANTDC.ENFANT : 0;
              miseajour.ENFANTDC.NOUVEAU_NE = miseajour.ENFANTDC.NOUVEAU_NE !== undefined ? miseajour.ENFANTDC.NOUVEAU_NE : 0;
              miseajour.ENFANTDC.MORT_NE = miseajour.ENFANTDC.MORT_NE !== undefined ? miseajour.ENFANTDC.MORT_NE : 0;
              miseajour.ENFANTDC.FOETUS = miseajour.ENFANTDC.FOETUS !== undefined ? miseajour.ENFANTDC.FOETUS : 0;
            }

            UpdateDefuntS(miseajour)
              .then(() => {
                UpdateDefunt(miseajour).catch((Err) => handleError(Err));
              })
              .catch((Err) => handleError(Err));
          });

          break;
        case "CONCESS":
          GetConcessById(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
            var miseajour = _.assign({}, concess, temporaire.TABLE_MODIFIE_JSON);

            if (miseajour.A_REPRENDRE === undefined) miseajour.A_REPRENDRE = 0;
            if (miseajour.POSE_PLAQUE === undefined) miseajour.POSE_PLAQUE = 0;

            if (this.IS_ORACLE) {
              if (miseajour.PANNEAU !== undefined) miseajour.EMPL = miseajour.PANNEAU;
              if (miseajour.C0CLEUNIK !== undefined) miseajour.INTERLOCUTEUR_C0CLEUNIK = miseajour.C0CLEUNIK;
              delete miseajour.PANNEAU;
              delete miseajour.C0CLEUNIK;
            }

            UpdateConcess(miseajour)
              .then(() => {
                GetLieuById(miseajour.LICLEUNIK).then((lieu) => {
                  if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                  _.set(lieu, "TOMBEL", miseajour.DETAIL);

                  UpdateLieu(lieu)
                    .then(() => {
                      UpdateSvg(lieu).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                });
              })
              .catch((Err) => handleError(Err));
          });

          break;
        case "INTERVEN":
          GetIntervenById(temporaire.TABLE_MODIFIE_JSON.I0CLEUNIK).then((interven) => {
            var miseajour = _.assign({}, interven, temporaire.TABLE_MODIFIE_JSON);

            if (miseajour.HEUREDEBUT !== undefined) {
              let datetravaux = new Date(miseajour.DATETRAVAUX);
              const heuretravaux = miseajour.HEUREDEBUT;

              datetravaux.setHours(heuretravaux.split(":")[0]);
              datetravaux.setMinutes(heuretravaux.split(":")[1]);
              miseajour.DATETRAVAUX = Cree_iso_date2(datetravaux);

              delete miseajour.HEUREDEBUT;
            }

            if (miseajour.HEUREDEPART !== undefined) {
              let datetravauxfin = new Date(miseajour.DATETRAVAUX_FIN);
              const heuretravauxfin = miseajour.HEUREDEPART;

              datetravauxfin.setHours(heuretravauxfin.split(":")[0]);
              datetravauxfin.setMinutes(heuretravauxfin.split(":")[1]);
              miseajour.DATETRAVAUX_FIN = Cree_iso_date2(datetravauxfin);

              delete miseajour.HEUREDEPART;
            }

            UpdateInterven(miseajour).catch((Err) => handleError(Err));
          });

          break;
        case "LIEU":
          GetLieuById(temporaire.TABLE_MODIFIE_JSON.LICLEUNIK).then((lieu) => {
            var miseajour = _.assign({}, lieu, temporaire.TABLE_MODIFIE_JSON);
            let empl = lieu.EMPLACEMENT;
            var miseajourempl = _.assign({}, empl, temporaire.TABLE_MODIFIE_JSON.EMPLACEMENT);
            miseajour.EMPLACEMENT = miseajourempl;

            UpdateLieu(miseajour).catch((Err) => handleError(Err));
          });

          break;
        default:
          break;
      }
    }
  };

  _getCursor = (temporaire) => {
    _.forEach(temporaire, (nt) => {
      switch (nt.TYPE_D_ENTITE) {
        case "CONCAIRE":
          GetConcaireById(nt.TABLE_MODIFIE_JSON.C0CLEUNIK).then((concaire) => {
            if (concaire !== undefined) {
              this.setState({
                cursor: [...this.state.cursor, { id: nt.id, type: "pointer" }],
              });
            } else {
              this.setState({
                cursor: [...this.state.cursor, { id: nt.id, type: "default" }],
              });
            }
          });

          break;
        case "PHOTOCONC":
          this.setState({
            cursor: [...this.state.cursor, { id: nt.id, type: "pointer" }],
          });

          break;
        case "DEFUNT":
          GetDefuntById(nt.TABLE_MODIFIE_JSON.D0CLEUNIK).then((defunt) => {
            if (defunt !== undefined) {
              this.setState({
                cursor: [...this.state.cursor, { id: nt.id, type: "pointer" }],
              });
            } else {
              this.setState({
                cursor: [...this.state.cursor, { id: nt.id, type: "default" }],
              });
            }
          });
          break;
        case "CONCESS":
          GetConcessById(nt.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
            if (concess !== undefined) {
              this.setState({
                cursor: [...this.state.cursor, { id: nt.id, type: "pointer" }],
              });
            } else {
              GetConcessByRowId(nt.TABLE_MODIFIE_JSON.id).then((concess) => {
                if (concess !== undefined) {
                  this.setState({
                    cursor: [...this.state.cursor, { id: nt.id, type: "pointer" }],
                  });
                } else {
                  this.setState({
                    cursor: [...this.state.cursor, { id: nt.id, type: "default" }],
                  });
                }
              });
            }
          });
          break;
        case "INTERVEN":
          GetIntervenById(nt.TABLE_MODIFIE_JSON.I0CLEUNIK).then((interven) => {
            if (interven !== undefined) {
              this.setState({
                cursor: [...this.state.cursor, { id: nt.id, type: "pointer" }],
              });
            } else {
              this.setState({
                cursor: [...this.state.cursor, { id: nt.id, type: "default" }],
              });
            }
          });
          break;
        default:
          break;
      }
    });
  };

  _cree_chaine_type = (type) => {
    switch (type) {
      case 1:
        return "Ajout";
      case 2:
        return "Modification";
      case 3:
        return "Suppression";
      default:
        return "";
    }
  };

  _change_loader_state = () => {
    this.setState({
      data_loaded: LoaderState.getIndex(),
      table_loading: LoaderState.getTable(),
      nb_total_photos_loading: LoaderState.getNombreTotalPhotos(),
      nb_photos_enregistre: LoaderState.getNombrePhotos(),
    });
  };

  _handle_click_button_synchroniser = (event) => {
    event.preventDefault();

    SessionTimer.setTimer(0);
    this.setState({ demandeDeMiseAJours: true });
  };

  _handle_click_button_synchroniser_oui = (event) => {
    event.preventDefault();

    let timer = 1800000;
    // if (process.env.REACT_APP_ENVIRONMENT !== undefined && process.env.REACT_APP_ENVIRONMENT === "dev") {
    //   timer = 10000;
    // }

    TemporaireEnErreur.resetTemporaireEnErreur();

    if (this.state.disabledForm) return;

    this.setState({ disabledForm: true, demandeDeMiseAJours: false, dialogMiseAJourEnCours: true }, () => {
      fetch(`${jsonpackage.urlapi}IsOnline`, {
        mode: "cors",
      })
        .then(() => {
          this.setState(
            {
              IS_ONLINE: true,
            },
            () => {
              var idgens = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

              const headers = {
                _cle: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
                _idutil: parseInt(idgens),
                _idTablette: this.ID_TABLETTE,
                "Content-Type": "application/json",
              };

              let index2 = 0;

              console.log("nombre d'éléments a transmettre");
              console.log(this.state.TEMPORAIRE.length);

              if (this.state.TEMPORAIRE.length === 0) {
                SessionTimer.setTimer(timer);
                this.setState({ open_snackbar_envoye: true });
              }

              const tempLength = this.state.TEMPORAIRE.length;
              _.forEach(this.state.TEMPORAIRE, (temporaire, index) => {
                if (temporaire.DATE_DE_CREATION === null && !TemporaireEnErreur.getTemporaireEnErreur().includes(temporaire.id)) {
                  const date = new Date();
                  date.setHours(date.getHours() - date.getTimezoneOffset() / 60, date.getMinutes(), date.getSeconds());

                  let TEMPORAIRE = { ...temporaire };
                  _.set(TEMPORAIRE, "ID_TEMPORAIRE", 0);
                  _.set(TEMPORAIRE, "ID_TYPE_TEMPORAIRE", 0);
                  _.set(TEMPORAIRE, "DATE_DE_CREATION", date.toISOString().slice(0, -5));
                  _.set(TEMPORAIRE, "ID_GESCIME", 0);
                  _.set(TEMPORAIRE, "ETAT_INITIAL", JSON.stringify(TEMPORAIRE.ETAT_INITIAL));
                  _.set(TEMPORAIRE, "TABLE_MODIFIE_JSON", JSON.stringify(TEMPORAIRE.TABLE_MODIFIE_JSON));

                  if (temporaire.TYPE_D_ENTITE === "CONCESS" && temporaire.TABLE_MODIFIE_JSON.COCLEUNIK < 0) {
                    fetch(`${jsonpackage.urlapi}TEMPORAIRE`, {
                      mode: "cors",
                      method: "POST",
                      headers: headers,
                      body: JSON.stringify(TEMPORAIRE),
                    })
                      .then((result) => result.text())
                      .then((result) => {
                        if (result !== undefined && result !== null) {
                          const new_temp = JSON.parse(result);

                          if (new_temp.StackTrace !== undefined) {
                            TemporaireEnErreur.setTemporaireEnErreur(TEMPORAIRE.id);

                            index2++;
                            if (index2 === tempLength) {
                              SessionTimer.setTimer(timer);

                              this.setState({ open_snackbar_envoye: true });
                            }
                          } else {
                            _.set(new_temp, "id", parseInt(TEMPORAIRE.id));
                            _.set(new_temp, "ID_APPLICATION_GARDIENS", parseInt(new_temp.ID_APPLICATION_GARDIENS));

                            if (new_temp.ID_GESCIME === 0) {
                              _.set(new_temp, "ID_GESCIME", null);
                            }

                            _.set(new_temp, "TABLE_MODIFIE_JSON", JSON.parse(new_temp.TABLE_MODIFIE_JSON));

                            _.set(new_temp, "TYPE_D_ACTION", parseInt(new_temp.TYPE_D_ACTION));

                            _.set(new_temp, "ETAT_INITIAL", _.find(this.state.TEMPORAIRE, ["id", new_temp.id]).ETAT_INITIAL);

                            UpdateTemporaire(new_temp)
                              .then(() => {
                                GetAllTemporaire().then((temporaire) => {
                                  const new_temp = this._updateTemporaireTri(temporaire);

                                  this.setState({ TEMPORAIRE: new_temp }, () => {
                                    setTimeout(() => {
                                      index2++;
                                      if (index2 === tempLength) {
                                        SessionTimer.setTimer(timer);

                                        this.setState({ open_snackbar_envoye: true });
                                      }
                                    }, 1500);
                                  });
                                });
                              })
                              .catch((Err) => handleError(Err));
                          }
                        }
                      });
                  } else {
                    setTimeout(() => {
                      fetch(`${jsonpackage.urlapi}TEMPORAIRE`, {
                        mode: "cors",
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(TEMPORAIRE),
                      })
                        .then((result) => result.text())
                        .then((result) => {
                          if (result !== undefined && result !== null) {
                            const new_temp = JSON.parse(result);

                            if (new_temp.StackTrace !== undefined) {
                              TemporaireEnErreur.setTemporaireEnErreur(TEMPORAIRE.id);

                              index2++;
                              if (index2 === tempLength) {
                                SessionTimer.setTimer(timer);

                                this.setState({ open_snackbar_envoye: true });
                              }
                            } else {
                              _.set(new_temp, "id", parseInt(TEMPORAIRE.id));
                              _.set(new_temp, "ID_APPLICATION_GARDIENS", parseInt(new_temp.ID_APPLICATION_GARDIENS));

                              if (new_temp.ID_GESCIME === 0) {
                                _.set(new_temp, "ID_GESCIME", null);
                              }

                              _.set(new_temp, "TABLE_MODIFIE_JSON", JSON.parse(new_temp.TABLE_MODIFIE_JSON));

                              _.set(new_temp, "TYPE_D_ACTION", parseInt(new_temp.TYPE_D_ACTION));

                              _.set(new_temp, "ETAT_INITIAL", _.find(this.state.TEMPORAIRE, ["id", new_temp.id]).ETAT_INITIAL);

                              UpdateTemporaire(new_temp)
                                .then(() => {
                                  GetAllTemporaire().then((temporaire) => {
                                    const new_temp = this._updateTemporaireTri(temporaire);

                                    this.setState({ TEMPORAIRE: new_temp }, () => {
                                      index2++;
                                      if (index2 === tempLength) {
                                        SessionTimer.setTimer(timer);

                                        this.setState({ open_snackbar_envoye: true });
                                      }
                                    });
                                  });
                                })
                                .catch((Err) => handleError(Err));
                            }
                          }
                        });
                    }, 1500);
                  }
                } else {
                  headers._idTemporaire = temporaire.ID_TEMPORAIRE;

                  fetch(`${jsonpackage.urlapi}TEMPORAIRE?start=-1&end=-1`, {
                    mode: "cors",
                    method: "GET",
                    headers: headers,
                  })
                    .then((result) => result.json())
                    .then((result) => {
                      if (result.length === 0) {
                        if (temporaire.ETAT_INITIAL !== null) {
                          this._updateEntiteObject(temporaire);
                        } else {
                          this._deleteEntiteObject(temporaire);
                        }

                        DeleteTemporaire(temporaire.id)
                          .then(() => {
                            let checked_trash = _.remove(this.state.checked_trash, temporaire.id);

                            GetAllTemporaire().then((listTemporaire) => {
                              let table_empty = listTemporaire.length === 0;
                              const new_temp = this._updateTemporaireTri(listTemporaire);

                              this.setState(
                                {
                                  TEMPORAIRE: new_temp,
                                  table_empty: table_empty,
                                  checked_trash: checked_trash,
                                },
                                () => {
                                  setTimeout(() => {
                                    index2++;

                                    if (index2 === tempLength) {
                                      SessionTimer.setTimer(timer);

                                      this.setState({ open_snackbar_envoye: true });
                                    }
                                  }, 1500);
                                }
                              );
                            });
                          })
                          .catch((Err) => handleError(Err));
                      } else if (result[0].DATE_TRAITE_DANS_GESCIME !== null) {
                        DeleteTemporaire(temporaire.id)
                          .then(() => {
                            let checked_trash = _.remove(this.state.checked_trash, temporaire.id);

                            GetAllTemporaire().then((listTemporaire) => {
                              let table_empty = listTemporaire.length === 0;
                              const new_temp = this._updateTemporaireTri(listTemporaire);

                              this.setState(
                                {
                                  TEMPORAIRE: new_temp,
                                  table_empty: table_empty,
                                  checked_trash: checked_trash,
                                },
                                () => {
                                  setTimeout(() => {
                                    index2++;

                                    if (index2 === tempLength) {
                                      SessionTimer.setTimer(timer);

                                      this.setState({ open_snackbar_envoye: true });
                                    }
                                  }, 1500);
                                }
                              );
                            });
                          })
                          .catch((Err) => handleError(Err));
                      } else {
                        index2++;

                        if (index2 === tempLength) {
                          SessionTimer.setTimer(timer);

                          this.setState({ open_snackbar_envoye: true });
                        }
                      }
                    });
                }
              });
            }
          );
        })
        .catch(() => {
          this.setState({
            IS_ONLINE: false,
          });
        });
    });
  };

  _updateTemporaireTri = (temporaire) => {
    let new_temp = [];
    _.forEach(temporaire, (temp) => {
      temp.DATE1 = temp.DATE_DE_CREATION !== null ? temp.DATE_DE_CREATION : "0001-01-01T00:00:00";
      temp.DATE2 = temp.DATE_D_IMPORT_DANS_GESCIME !== null ? temp.DATE_D_IMPORT_DANS_GESCIME : "0001-01-01T00:00:00";
      temp.DATE3 = temp.STATUS_DE_VALIDATION === 2 ? temp.DATE_TRAITE_DANS_GESCIME : "0001-01-01T00:00:00";
      temp.DATE4 = temp.STATUS_DE_VALIDATION === 3 ? temp.DATE_TRAITE_DANS_GESCIME : "0001-01-01T00:00:00";

      new_temp.push(temp);
    });

    return new_temp;
  };

  _cree_chaine_date = (date) => {
    if (date === null) {
      return "";
    }

    if (date !== "") {
      if (date.includes("1900-01-01") || date.includes("0001-01-01")) {
        return "";
      } else {
        const datetime = new Date(date);
        const datetimeFormat = new Intl.DateTimeFormat("fr", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        const [{ value: day }, , { value: month }, , { value: year }, , { value: hour }, , { value: minute }] = datetimeFormat.formatToParts(datetime);

        return `${day}/${month}/${year} à ${hour}h${minute}`;
      }
    } else {
      return date;
    }
  };

  handleRequestSort = (_, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";

    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  handleChangePage = (_, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  _deleteEntiteObject = (temporaire) => {
    switch (temporaire.TYPE_D_ENTITE) {
      case "CONCAIRE":
        if (temporaire.ETAT_INITIAL === null) {
          GetConcaireById(temporaire.TABLE_MODIFIE_JSON.C0CLEUNIK).then((concaire) => {
            if (concaire !== undefined) {
              DeleteConcaire(concaire.id).catch((Err) => handleError(Err));
            }
          });
        } else {
          UpdateConcaire(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        }

        break;
      case "PHOTOCONC":
        if (temporaire.ETAT_INITIAL === null) {
          GetPhotoconcById(this.IS_ORACLE ? temporaire.TABLE_MODIFIE_JSON.IDEVOL_ETAT : temporaire.TABLE_MODIFIE_JSON.IDPHOTOCONC).then((photoconc) => {
            if (photoconc !== undefined) DeletePhotoconc(photoconc.id).catch((Err) => handleError(Err));
          });
        } else {
          UpdatePhotoconc(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        }

        break;
      case "DEFUNT":
        if (temporaire.ETAT_INITIAL === null) {
          GetDefuntById(temporaire.TABLE_MODIFIE_JSON.D0CLEUNIK).then((defunt) => {
            if (defunt !== undefined)
              DeleteDefuntS(defunt)
                .then(() => {
                  DeleteDefunt(defunt.id).catch((Err) => handleError(Err));
                })
                .catch((Err) => handleError(Err));
          });
        } else {
          UpdateDefuntS(temporaire.ETAT_INITIAL)
            .then(() => {
              UpdateDefunt(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
            })
            .catch((Err) => handleError(Err));
        }

        break;
      case "INTERVEN":
        UpdateInterven(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      case "CONCESS":
        if (temporaire.ETAT_INITIAL === null) {
          GetConcessById(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
            if (concess !== undefined) {
              GetConcaireByCocleunik(concess.COCLEUNIK).then((concaire) => {
                _.forEach(concaire, (c) => {
                  DeleteConcaire(c.id).catch((Err) => handleError(Err));
                });
              });

              GetPhotoconcByCocleunik(concess.COCLEUNIK).then((photoconc) => {
                _.forEach(photoconc, (p) => {
                  DeletePhotoconc(p.id).catch((Err) => handleError(Err));
                });
              });

              GetDefuntByCocleunik(concess.COCLEUNIK).then((defunt) => {
                _.forEach(defunt, (d) => {
                  DeleteDefuntS(d)
                    .then(() => {
                      DeleteDefunt(d.id).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                });
              });

              GetLieuByCocleunik(concess.COCLEUNIK).then((lieu) => {
                if (lieu !== undefined) {
                  if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                  _.set(lieu.EMPLACEMENT, "ETAT", 1);
                  _.set(lieu, "COCLEUNIK", 0);

                  UpdateLieu(lieu)
                    .then(() => {
                      UpdateSvg(lieu).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                }
              });

              DeleteConcess(concess.id).catch((Err) => handleError(Err));
            }
          });
        } else {
          UpdateConcess(temporaire.ETAT_INITIAL).then(() => {
            if (temporaire.TABLE_MODIFIE_JSON.LICLEUNIK !== undefined) {
              if (temporaire.ETAT_INITIAL.LICLEUNIK !== temporaire.TABLE_MODIFIE_JSON.LICLEUNIK) {
                GetPhotoconcByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((photoconc) => {
                  _.forEach(photoconc, (p) => {
                    _.set(p, "COCLEUNIK", temporaire.ETAT_INITIAL.COCLEUNIK);

                    UpdatePhotoconc(p).catch((Err) => handleError(Err));
                  });
                });

                GetDefuntByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((defunt) => {
                  _.forEach(defunt, (d) => {
                    _.set(d, "COCLEUNIK", temporaire.ETAT_INITIAL.COCLEUNIK);
                    _.set(d, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    UpdateDefunt(d).catch((Err) => handleError(Err));
                  });
                });

                GetLieuById(temporaire.TABLE_MODIFIE_JSON.LICLEUNIK).then((lieu) => {
                  if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                  _.set(lieu.EMPLACEMENT, "ETAT", 1);
                  _.set(lieu, "COCLEUNIK", 0);

                  UpdateLieu(lieu)
                    .then(() => {
                      UpdateSvg(lieu).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                });
              }
            }
          });
        }

        break;
      default:
        break;
    }
  };

  _updateEntiteObject = (temporaire) => {
    switch (temporaire.TYPE_D_ENTITE) {
      case "CONCAIRE":
        UpdateConcaire(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      case "PHOTOCONC":
        let photo = temporaire.ETAT_INITIAL;

        if (photo.SIGNATURE_AVANT_AGENT !== undefined) {
          delete photo.SIGNATURE_AVANT_AGENT;
        }

        if (photo.SIGNATURE_AVANT_SOCIETE !== undefined) {
          delete photo.SIGNATURE_AVANT_SOCIETE;
        }

        UpdatePhotoconc(photo).catch((Err) => handleError(Err));
        break;
      case "DEFUNT":
        UpdateDefuntS(temporaire.ETAT_INITIAL)
          .then(() => {
            UpdateDefunt(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
          })
          .catch((Err) => handleError(Err));
        break;
      case "INTERVEN":
        UpdateInterven(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      case "CONCESS":
        UpdateConcess(temporaire.ETAT_INITIAL)
          .then(() => {
            if (temporaire.TABLE_MODIFIE_JSON.LICLEUNIK !== undefined) {
              if (temporaire.ETAT_INITIAL.LICLEUNIK !== temporaire.TABLE_MODIFIE_JSON.LICLEUNIK) {
                GetDefuntByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((defunts) => {
                  _.forEach(defunts, (defunt) => {
                    _.set(defunt, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    UpdateDefunt(defunt).catch((Err) => handleError(Err));
                  });
                });

                GetIntervenByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((intervens) => {
                  _.forEach(intervens, (interven) => {
                    _.set(interven, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    UpdateInterven(interven).catch((Err) => handleError(Err));
                  });
                });

                GetLieuById(temporaire.TABLE_MODIFIE_JSON.LICLEUNIK).then((lieu) => {
                  if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                  _.set(lieu.EMPLACEMENT, "ETAT", 1);
                  _.set(lieu, "COCLEUNIK", 0);

                  UpdateLieu(lieu)
                    .then(() => {
                      UpdateSvg(lieu).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                });
              }
            }
          })
          .catch((Err) => handleError(Err));
        break;

      case "LIEU":
        UpdateLieu(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      default:
        break;
    }
  };

  _updateEntiteObject2 = (temporaire) => {
    switch (temporaire.TYPE_D_ENTITE) {
      case "CONCAIRE":
        let CONCAIRE = temporaire.TABLE_MODIFIE_JSON;

        if (CONCAIRE.ADRMAIL !== undefined) {
          CONCAIRE.ADRESSEMAIL = CONCAIRE.ADRMAIL;
          delete CONCAIRE.ADRMAIL;
        }

        let typeinterlo = CONCAIRE.TYPEINTERLO;
        CONCAIRE.TYPEINTERLO = {};
        CONCAIRE.TYPEINTERLO.CONCESSIONNAIRE = parseInt(typeinterlo[0]);
        CONCAIRE.TYPEINTERLO.COCONCESSIONNAIRE = parseInt(typeinterlo[1]);
        CONCAIRE.TYPEINTERLO.AYANTDROIT = parseInt(typeinterlo[2]);
        CONCAIRE.TYPEINTERLO.INHUMABLE = parseInt(typeinterlo[3]);
        CONCAIRE.TYPEINTERLO.AUTRE = parseInt(typeinterlo[4]);

        if (this.IS_ORACLE) {
          CONCAIRE.TYPEINTERLO.DONATAIRE_LEGATAIRE = parseInt(typeinterlo[5]);
          CONCAIRE.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE = parseInt(typeinterlo[6]);
          CONCAIRE.TYPEINTERLO.INDESIRABLE = 0;
        } else {
          CONCAIRE.TYPEINTERLO.DONATAIRE_LEGATAIRE = 0;
          CONCAIRE.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE = 0;
          CONCAIRE.TYPEINTERLO.INDESIRABLE = parseInt(typeinterlo[5]);

          if (CONCAIRE.ADR_NUMERO === undefined) CONCAIRE.ADR_NUMERO = "";
          if (CONCAIRE.ADR_BISTER === undefined) CONCAIRE.ADR_BISTER = "";
          if (CONCAIRE.ADR_COMP === undefined) CONCAIRE.ADR_COMP = "";
        }

        UpdateConcaire(CONCAIRE).catch((Err) => handleError(Err));
        break;
      case "PHOTOCONC":
        let PHOTOCONC = temporaire.TABLE_MODIFIE_JSON;

        GetPhotoconcByIdTablette(PHOTOCONC.id).then((photo) => {
          if (photo !== undefined) {
            PHOTOCONC.AGENT = photo.AGENT;
            PHOTOCONC.MONUMENT_DERRIERE = photo.MONUMENT_DERRIERE;
            PHOTOCONC.MONUMENT_DEVANT = photo.MONUMENT_DEVANT;
            PHOTOCONC.MONUMENT_DROITE = photo.MONUMENT_DROITE;
            PHOTOCONC.MONUMENT_ETAT = photo.MONUMENT_ETAT;
            PHOTOCONC.MONUMENT_GAUCHE = photo.MONUMENT_GAUCHE;
            PHOTOCONC.MONUMENT_GAUCHE = photo.MONUMENT_GAUCHE;
            PHOTOCONC.NOTES = photo.NOTES;
            PHOTOCONC.PHOTO = photo.PHOTO;
            PHOTOCONC.PRINCIPAL = photo.PRINCIPAL;
            PHOTOCONC.SEMELLE_ETAT = photo.SEMELLE_ETAT;
            PHOTOCONC.SIGNATURE_AGENT = photo.SIGNATURE_AGENT;
            PHOTOCONC.SIGNATURE_SOCIETE = photo.SIGNATURE_SOCIETE;
            PHOTOCONC.SOCIETE = photo.SOCIETE;

            if (this.IS_ORACLE) {
              if (PHOTOCONC.IDEVOL_ETAT !== undefined) PHOTOCONC.IDPHOTOCONC = PHOTOCONC.IDEVOL_ETAT;
              if (PHOTOCONC.DATE_EVOL !== undefined) PHOTOCONC.DATEAJOUT = PHOTOCONC.DATE_EVOL;
              if (PHOTOCONC.REFERENTE !== undefined) PHOTOCONC.PRINCIPAL = PHOTOCONC.REFERENTE;
              if (PHOTOCONC.ETAT_EVOL !== undefined) PHOTOCONC.NOTES = PHOTOCONC.ETAT_EVOL;
              delete PHOTOCONC.IDEVOL_ETAT;
              delete PHOTOCONC.DATE_EVOL;
              delete PHOTOCONC.REFERENTE;
              delete PHOTOCONC.ETAT_EVOL;
            }

            UpdatePhotoconc(PHOTOCONC).catch((Err) => handleError(Err));
          }
        });

        break;
      case "DEFUNT":
        let DEFUNT = temporaire.TABLE_MODIFIE_JSON;

        DEFUNT.ETAT_CIVIL = {};
        DEFUNT.ETAT_CIVIL.NOMDEF = DEFUNT.NOMDEF;
        DEFUNT.ETAT_CIVIL.NOMJEUNE = DEFUNT.NOMJEUNE;
        DEFUNT.ETAT_CIVIL.PRENOMDEF = DEFUNT.PRENOMDEF;
        DEFUNT.ETAT_CIVIL.DATENAISS = DEFUNT.DATENAISS;
        DEFUNT.ETAT_CIVIL.DATEDECES = DEFUNT.DATEDECES;
        DEFUNT.ETAT_CIVIL.DATEINHUM = `${DEFUNT.DATEINHUM !== "" ? DEFUNT.DATEINHUM : "1900-01-01"}T00:00:01`;

        if (DEFUNT.HEUREMOUV !== undefined && DEFUNT.HEUREMOUV !== "") {
          let dateinhu = DEFUNT.ETAT_CIVIL.DATEINHUM !== "" ? DEFUNT.ETAT_CIVIL.DATEINHUM : "1900-01-01";

          dateinhu = `${dateinhu.split("T")[0]}T${DEFUNT.HEUREMOUV}:01`;
          DEFUNT.ETAT_CIVIL.DATEINHUM = dateinhu;
        }

        DEFUNT.ETAT_CIVIL.AGE = DEFUNT.AGE;

        if (this.IS_ORACLE) {
          DEFUNT.ETAT_CIVIL.SEXE = DEFUNT.CIVDEF;
          DEFUNT.ETAT_CIVIL.ANNEEDECES = DEFUNT.ANNEEDECES;

          delete DEFUNT.CIVDEF;
          delete DEFUNT.ANNEEDECES;
        } else {
          DEFUNT.ETAT_CIVIL.SEXE = DEFUNT.SEXE;
          DEFUNT.ETAT_CIVIL.ANNEEDECES = 0;

          delete DEFUNT.SEXE;
        }

        delete DEFUNT.NOMDEF;
        delete DEFUNT.NOMJEUNE;
        delete DEFUNT.PRENOMDEF;
        delete DEFUNT.DATENAISS;
        delete DEFUNT.DATEDECES;
        delete DEFUNT.DATEINHUM;
        delete DEFUNT.HEUREMOUV;
        delete DEFUNT.AGE;

        DEFUNT.ENVELOPPE = {};
        DEFUNT.ENVELOPPE.URNE = parseInt(DEFUNT.INFOPLUS[0]);
        DEFUNT.ENVELOPPE.RELIQUAIRE = parseInt(DEFUNT.INFOPLUS[1]);

        if (this.IS_ORACLE) {
          DEFUNT.ENVELOPPE.ENFANT = 0;
          DEFUNT.ENVELOPPE.DISPERSION = parseInt(DEFUNT.INFOPLUS[2]);
          DEFUNT.ENVELOPPE.URNE_SCELLE = parseInt(DEFUNT.INFOPLUS[3]);
        } else {
          DEFUNT.ENVELOPPE.ENFANT = parseInt(DEFUNT.INFOPLUS[2]);
          DEFUNT.ENVELOPPE.DISPERSION = parseInt(DEFUNT.INFOPLUS[3]);
          DEFUNT.ENVELOPPE.URNE_SCELLE = parseInt(DEFUNT.INFOPLUS[4]);
        }

        delete DEFUNT.INFOPLUS;

        const bierre = DEFUNT.BIERRE;
        DEFUNT.BIERRE = {};
        DEFUNT.BIERRE.MEBI_MC = parseInt(bierre[0]);
        DEFUNT.BIERRE.SOINS_CONSERVATIONS = parseInt(bierre[1]);
        DEFUNT.BIERRE.HERMETIQUE = parseInt(bierre[2]);
        DEFUNT.BIERRE.ZINC_HN = parseInt(bierre[3]);

        const enfantdc = DEFUNT.ENFANTDC;
        DEFUNT.ENFANTDC = {};
        if (this.IS_ORACLE) {
          DEFUNT.ENFANTDC.AUCUN = enfantdc === 1 ? 1 : 0;
          DEFUNT.ENFANTDC.ENFANT = enfantdc === 2 ? 1 : 0;
          DEFUNT.ENFANTDC.NOUVEAU_NE = enfantdc === 3 ? 1 : 0;
          DEFUNT.ENFANTDC.MORT_NE = enfantdc === 4 ? 1 : 0;
          DEFUNT.ENFANTDC.FOETUS = enfantdc === 5 ? 1 : 0;
        } else {
          DEFUNT.ENFANTDC.AUCUN = 0;
          DEFUNT.ENFANTDC.ENFANT = 0;
          DEFUNT.ENFANTDC.NOUVEAU_NE = 0;
          DEFUNT.ENFANTDC.MORT_NE = 0;
          DEFUNT.ENFANTDC.FOETUS = 0;
        }

        UpdateDefuntS(DEFUNT)
          .then(() => {
            UpdateDefunt(DEFUNT).catch((Err) => handleError(Err));
          })
          .catch((Err) => handleError(Err));
        break;
      case "CONCESS":
        let CONCESS = temporaire.TABLE_MODIFIE_JSON;

        if (CONCESS.A_REPRENDRE === undefined) CONCESS.A_REPRENDRE = 0;
        if (CONCESS.POSE_PLAQUE === undefined) CONCESS.POSE_PLAQUE = 0;

        if (this.IS_ORACLE) {
          if (CONCESS.PANNEAU !== undefined) CONCESS.EMPL = CONCESS.PANNEAU;
          if (CONCESS.C0CLEUNIK !== undefined) CONCESS.INTERLOCUTEUR_C0CLEUNIK = CONCESS.C0CLEUNIK;
          delete CONCESS.PANNEAU;
          delete CONCESS.C0CLEUNIK;
        }

        UpdateConcess(CONCESS).catch((Err) => handleError(Err));
        break;
      default:
        break;
    }
  };

  _addEntiteObjet = (type_entite, etat) => {
    switch (type_entite) {
      case "PHOTOCONC":
        if (this.IS_ORACLE) {
          for (var key in etat) {
            if (key === "IDEVOL_ETAT") {
              etat.IDPHOTOCONC = etat[key];
              delete etat[key];
            }

            if (key === "DATE_EVOL") {
              etat.DATEAJOUT = etat[key];
              delete etat[key];
            }

            if (key === "REFERENTE") {
              etat.PRINCIPAL = etat[key];
              delete etat[key];
            }

            if (key === "ETAT_EVOL") {
              etat.NOTES = etat[key];
              delete etat[key];
            }
          }
        }

        const photo = etat;

        if (photo.PHOTO !== "" && photo.PHOTO !== undefined) {
          const image = photo.PHOTO.replace(/data:image.*base64,/, "");
          var blob = b64toBlob(image, "image/png");

          _.set(photo, "PHOTO", blob);
        }

        setTimeout(() => {
          GetPhotoconcById(photo.IDPHOTOCONC).then((photoconc) => {
            if (photoconc === undefined) {
              AddPhotoconc(photo).catch((Err) => handleError(Err));
            }
          });
        }, 500);

        break;
      default:
        break;
    }
  };

  _deleteRow = () => {
    _.forEach(this.state.checked_trash, (id) => {
      const temp = _.find(this.state.TEMPORAIRE, ["id", id]);

      if (temp.ETAT_INITIAL !== null) {
        if (temp.TYPE_D_ACTION === 2) {
          this._updateEntiteObject(temp);

          if (temp.ETAT_INITIAL.DETAIL !== undefined) {
            GetLieuById(temp.ETAT_INITIAL.LICLEUNIK).then((lieu) => {
              if (temp.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temp.TABLE_MODIFIE_JSON.DEVENU_DEGELE);
              if (temp.ETAT_INITIAL.DETAIL !== lieu.TOMBEL) _.set(lieu, "TOMBEL", temp.ETAT_INITIAL.DETAIL);

              UpdateLieu(lieu)
                .then(() => {
                  UpdateSvg(lieu).catch((Err) => handleError(Err));
                })
                .catch((Err) => handleError(Err));
            });
          }
        } else {
          this._addEntiteObjet(temp);
        }
      } else {
        this._deleteEntiteObject(temp);
      }

      if (temp.TYPE_D_ENTITE === "CONCESS" && temp.TYPE_D_ACTION === 1) {
        var clone = _.cloneDeep(this.state.TEMPORAIRE);
        clone = _.filter(clone, function (n) {
          return !_.isEqual(n, temp);
        });

        _.forEach(clone, (c) => {
          if (_.has(c.TABLE_MODIFIE_JSON, ["COCLEUNIK"])) {
            if (c.TABLE_MODIFIE_JSON.COCLEUNIK === temp.TABLE_MODIFIE_JSON.COCLEUNIK) {
              DeleteTemporaire(c.id)
                .then(() => {
                  let checked_trash = _.remove(this.state.checked_trash, c.id);

                  GetAllTemporaire().then((temporaire) => {
                    let table_empty = temporaire.length === 0;
                    const new_temp = this._updateTemporaireTri(temporaire);

                    this.setState({
                      TEMPORAIRE: new_temp,
                      table_empty: table_empty,
                      checked_trash: checked_trash,
                    });
                  });
                })
                .catch((Err) => handleError(Err));
            }
          }
        });
      }

      DeleteTemporaire(id)
        .then(() => {
          let checked_trash = _.remove(this.state.checked_trash, id);

          GetAllTemporaire().then((temporaire) => {
            let table_empty = temporaire.length === 0;

            const new_temp = this._updateTemporaireTri(temporaire);

            this.setState({
              TEMPORAIRE: new_temp,
              table_empty: table_empty,
              checked_trash: checked_trash,
            });
          });
        })
        .catch((Err) => handleError(Err));
    });
  };

  _handle_checked_trash = (convoi) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    let checked_trash = [];

    if (!_.includes(this.state.checked_trash, convoi.id)) {
      checked_trash = [...this.state.checked_trash, convoi.id];
    } else {
      checked_trash = _.filter(this.state.checked_trash, (trash) => {
        return trash !== convoi.id;
      });
    }

    this.setState({
      checked_trash: checked_trash,
      disabledForm: checked_trash.length > 0,
    });
  };

  _handle_click_trash = (event) => {
    event.preventDefault();

    this.setState({ open_trash_dialog: true });
  };

  _handle_click_all_trash = (event) => {
    GetAllTemporaire().then((temporaire) => {
      let checked_trash = [];

      _.forEach(temporaire, (temp) => {
        if (temp.DATE_DE_CREATION === null) {
          checked_trash.push(temp.id);
        }
      });

      this.setState({
        checked_trash: checked_trash,
        disabledForm: checked_trash.length > 0,
      });
    });
  };

  _handle_close_trash_dialog = () => {
    this.setState({ open_trash_dialog: false });
  };

  _handle_close_trash_dialog_with_delete = () => {
    this._deleteRow();

    this.setState({ open_trash_dialog: false });
  };

  _handle_click_row = (row) => (event) => {
    event.preventDefault();

    switch (row.TYPE_D_ENTITE) {
      case "DEFUNT":
        GetDefuntById(row.TABLE_MODIFIE_JSON.D0CLEUNIK).then((defunt) => {
          if (defunt !== undefined) {
            this.setState({
              REDIRECT: `/consulter/fiche-defunt/${row.TABLE_MODIFIE_JSON.D0CLEUNIK}`,
            });
          }
        });

        break;
      case "CONCAIRE":
      case "CONCESS":
      case "LIEU":
        GetConcessById(row.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
          if (concess !== undefined) {
            this.setState({
              REDIRECT: `/consulter/fiche-concession/${row.TABLE_MODIFIE_JSON.COCLEUNIK}`,
            });
          }
          // else {
          //   // GetConcessByRowId(row.TABLE_MODIFIE_JSON.id).then((concess) => {
          //   //   if (concess !== undefined) {
          //   //     this.setState({
          //   //       REDIRECT: `/consulter/fiche-concession/${concess.COCLEUNIK}`,
          //   //     });
          //   //   }
          //   // });
          // }
        });

        break;
      case "PHOTOCONC":
        if (row.TABLE_MODIFIE_JSON.CONSTAT_TRAVAUX > 0) {
          if (row.TABLE_MODIFIE_JSON.I0CLEUNIK !== undefined) {
            this.setState({
              REDIRECT: `/consulter/fiche-travaux/${row.TABLE_MODIFIE_JSON.I0CLEUNIK}`,
            });
          } else {
            GetConcessById(row.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
              if (concess !== undefined) {
                this.setState({
                  REDIRECT: `/modifier/photographies/${row.TABLE_MODIFIE_JSON.COCLEUNIK}/s`,
                });
              }
            });
          }
        } else if (row.TABLE_MODIFIE_JSON.ABANDON_1 === true || row.TABLE_MODIFIE_JSON.ABANDON_2 === true) {
          this.setState({
            REDIRECT: `/consulter/fiche-concession/${row.TABLE_MODIFIE_JSON.COCLEUNIK}/pa`,
          });
        } else {
          GetConcessById(row.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
            if (concess !== undefined) {
              this.setState({
                REDIRECT: `/modifier/photographies/${row.TABLE_MODIFIE_JSON.COCLEUNIK}/s`,
              });
            }
            //  else {
            //   GetPhotoconcByRowId(row.TABLE_MODIFIE_JSON.id).then((photo) => {
            //     if (photo !== undefined) {
            //       this.setState({
            //         REDIRECT: `/modifier/photographies/${photo.COCLEUNIK}`,
            //       });
            //     }
            //   });
            // }
          });
        }

        break;
      case "INTERVEN":
        GetIntervenById(row.TABLE_MODIFIE_JSON.I0CLEUNIK).then((interven) => {
          if (interven !== undefined) {
            this.setState({
              REDIRECT: `/consulter/fiche-travaux/${row.TABLE_MODIFIE_JSON.I0CLEUNIK}`,
            });
          }
        });

        break;
      default:
        break;
    }
  };

  _handle_click_button_actualiser = (event) => {
    event.preventDefault();

    fetch(`${jsonpackage.urlapi}IsOnline`, {
      mode: "cors",
    })
      .then(() => {
        this.setState(
          {
            IS_ONLINE: true,
          },
          () => {
            this._updateTableTemporaire();
          }
        );
      })
      .catch(() => {
        this.setState({
          IS_ONLINE: false,
        });
      });
  };

  _handle_change_expanded = (index) => (event) => {
    event.preventDefault();

    if (this.state.EXPANDED === index) {
      this.setState({ EXPANDED: null });
    } else {
      this.setState({ EXPANDED: index });
    }
  };

  _handle_close_dialog_miseAJourEnCours = (event) => {
    event.preventDefault();

    this.setState({ dialogMiseAJourEnCours: false, open_snackbar_envoye: false }, () => {
      var idgens = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

      const headers2 = {
        _cle: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
        _idutil: parseInt(idgens),
        "Content-Type": "application/json",
      };

      // verification de l'existance d'une nouvelle base mdf
      GetClient().then((client) => {
        const DateSynchro = GetNewDateSynchro(headers2._cle, client.DATE_SYNCHRO.replace(/"/g, ""));

        DateSynchro.then((date) => {
          //client.DATE_SYNCHRO = "2021-07-09";
          //date = "2021-10-21";

          // verification de l'existance d'une presta carto
          const DatePrestaCarto = GetNewDatePrestaCarto(headers2._cle, client.DATE_SYNCHRO, this.ID_TABLETTE);

          DatePrestaCarto.then((datePc) => {
            datePc = datePc.replaceAll('"', "");
            //datePc = "2021-10-19";

            console.log(`Date de synchro tablette : ${client.DATE_SYNCHRO}`);
            console.log(`Date de synchro mdf : ${date}`);
            console.log(`Date de prestation carto : ${datePc}`);

            if (datePc !== "") {
              // si on a une date retourne par le robot restore
              if (date !== "") {
                this.setState({ prestaCarto: true }, () => {
                  // si la date retourne par le robot restore est plus recente que la date de la tablette
                  if (new Date(client.DATE_SYNCHRO.replace(/"/g, "")) < new Date(datePc) && new Date(date) > new Date(datePc)) {
                    this.setState({ loading: true, open_snackbar_telechargement: true }, () => {
                      GetAllCimetier(1).then((cimetiere) => {
                        let cimetiere_ids = _.map(cimetiere, "NUMCIME");

                        // creation du header pour les requete
                        const headers = {
                          APIKEY: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
                          ALIAS: atob(this.props.cookies.get(encodeURIComponent(btoa("ALIAS")))),
                          IDENTIFIANT: atob(this.props.cookies.get(encodeURIComponent(btoa("IDENTIFIANT")))),
                          MOT_DE_PASSE: atob(this.props.cookies.get(encodeURIComponent(btoa("MOT_DE_PASSE")))),
                          CIMETIERE: `0,${cimetiere_ids}`,
                          DATE_SYNCHRO: client.DATE_SYNCHRO.replace(/"/g, ""),
                        };

                        const lastestGescimeBuild = GetLastestGescimeBuild(headers);
                        lastestGescimeBuild.then((version) => {
                          fetch(conf)
                            .then((r) => r.text())
                            .then((text) => {
                              let json = {};

                              const keyValuePair = (kvStr) => {
                                const kvPair = kvStr.split("=").map((val) => val.trim());
                                var jsonKey = kvPair[0];

                                json[jsonKey] = kvPair[1];
                              };

                              _.forEach(text.split("\n"), (t) => {
                                keyValuePair(t);
                              });

                              if (version < (this.IS_ORACLE ? json.VERSION_GESCIME_ORACLE : json.VERSION_GESCIME_HF)) {
                                this.setState({ dialog_version: true, disabledForm: false, loading: false });
                              } else {
                                GetAllCimetier(0).then((liste_cimetiere) => {
                                  const deleteDb = DeleteDatabase();

                                  deleteDb.then((res) => {
                                    headers.DATE_SYNCHRO = "";

                                    const updateTable = CreateTable(liste_cimetiere, headers, this._change_loader_state, client.ID_TABLETTE);

                                    const dateToSend = new Date();
                                    dateToSend.setHours(dateToSend.getHours() - dateToSend.getTimezoneOffset() / 60, dateToSend.getMinutes(), dateToSend.getSeconds());

                                    updateTable
                                      .then(() => {
                                        fetch(`${jsonpackage.urlapi}PrestaCarto`, {
                                          mode: "cors",
                                          method: "POST",
                                          headers: { ...headers2, ...{ _idtablette: client.ID_TABLETTE } },
                                          body: JSON.stringify(dateToSend.toISOString().slice(0, -5)),
                                        })
                                          .then((result) => result.text())
                                          .then((result) => {
                                            UpdateClientDateSynchro(date).then(() => {
                                              LoaderState.setIndex(0);
                                              LoaderState.setTable("");

                                              this.setState({ loading: false, prestaCarto: false });
                                            });
                                          });
                                      })
                                      .catch((err) => {
                                        console.error(err);

                                        this.setState({ dialog_erreur: true, disabledForm: false, loading: false, prestaCarto: false });
                                      });
                                  });
                                });
                              }
                            });
                        });
                      });
                    });
                  }
                });
              }
            } else {
              // si on a une date retourne par le robot restore
              if (date !== "") {
                // si la date retourne par le robot restore est plus recente que la date de la tablette
                if (new Date(date) > new Date(client.DATE_SYNCHRO.replace(/"/g, ""))) {
                  // on appel les methode de l'API pour mettre a jour la tablette
                  // pour mettre a jours avec les meme cimetiere, on recupere les cimetieres present dans la tablette

                  this.setState({ loading: true, open_snackbar_telechargement: true }, () => {
                    GetAllCimetier(1).then((cimetiere) => {
                      let cimetiere_ids = _.map(cimetiere, "NUMCIME");

                      // creation du header pour les requete
                      const headers = {
                        APIKEY: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
                        ALIAS: atob(this.props.cookies.get(encodeURIComponent(btoa("ALIAS")))),
                        IDENTIFIANT: atob(this.props.cookies.get(encodeURIComponent(btoa("IDENTIFIANT")))),
                        MOT_DE_PASSE: atob(this.props.cookies.get(encodeURIComponent(btoa("MOT_DE_PASSE")))),
                        CIMETIERE: `0,${cimetiere_ids}`,
                        DATE_SYNCHRO: client.DATE_SYNCHRO.replace(/"/g, ""),
                      };

                      const idgens = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

                      const lastestGescimeBuild = GetLastestGescimeBuild(headers);
                      lastestGescimeBuild.then((version) => {
                        fetch(conf)
                          .then((r) => r.text())
                          .then((text) => {
                            let json = {};

                            const keyValuePair = (kvStr) => {
                              const kvPair = kvStr.split("=").map((val) => val.trim());
                              var jsonKey = kvPair[0];

                              json[jsonKey] = kvPair[1];
                            };

                            _.forEach(text.split("\n"), (t) => {
                              keyValuePair(t);
                            });

                            if (version < (this.IS_ORACLE ? json.VERSION_GESCIME_ORACLE : json.VERSION_GESCIME_HF)) {
                              this.setState({ dialog_version: true, disabledForm: false, loading: false });
                            } else {
                              const updateTable = CreateTable_MDF(headers, idgens, this.ID_TABLETTE, date, this._change_loader_state, client.ID_TABLETTE);

                              updateTable
                                .then(() => {
                                  LoaderState.setIndex(0);
                                  LoaderState.setTable("");

                                  this.setState({ loading: false });
                                })
                                .catch((err) => {
                                  console.error(err);
                                  this.setState({ dialog_erreur: true, disabledForm: false, loading: false });
                                });
                            }
                          });
                      });
                    });
                  });
                }
              }
            }
          });
        });
      });
    });
  };

  render() {
    console.log("RENDER SYNCHRONISATION");

    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={{ ...styles.grid, alignItems: "stretch" }}>
        <Grid item xs={10} style={styles.grid_item}>
          <Paper
            style={{
              ...styles.paper,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="secondary" style={{ marginBottom: 16 }} onClick={this._handle_click_button_actualiser}>
                Actualiser
              </Button>
            </div>

            {this.state.IS_ONLINE !== null ? (
              <Typography variant="body1" style={this.state.IS_ONLINE ? { color: "green" } : { color: "red" }}>
                {this.state.IS_ONLINE ? "Vous êtes connecté au service Gescime" : 'Vous n\'êtes pas connecté au service Gescime, cliquez sur "Actualiser" pour rafraichir la page'}
              </Typography>
            ) : null}

            {width === "xs" || width === "sm" ? (
              <div style={{ marginTop: 24 }}>
                <Paper elevation={0} style={{ display: "flex", padding: "0 16px" }}>
                  <TextField
                    select
                    label="Tri par :"
                    value={this.state.orderBy}
                    style={{ width: "25ch", marginRight: 16, marginBottom: 16 }}
                    onChange={(e) => {
                      this.setState({
                        orderBy: e.target.value,
                        EXPANDED: null,
                      });
                    }}
                  >
                    <MenuItem value="TYPE_OPERATION">Type</MenuItem>
                    <MenuItem value="TITRE">Libellé</MenuItem>
                    <MenuItem value="DATE1">Envoyé</MenuItem>
                    <MenuItem value="DATE2">Importé</MenuItem>
                    <MenuItem value="DATE3">Validé</MenuItem>
                    <MenuItem value="DATE4">Refusé</MenuItem>
                  </TextField>

                  <TextField
                    select
                    label="Ordre :"
                    value={this.state.order}
                    style={{ width: "25ch" }}
                    onChange={(e) => {
                      this.setState({ order: e.target.value, EXPANDED: null });
                    }}
                  >
                    <MenuItem value="asc">Croissant</MenuItem>
                    <MenuItem value="desc">Décroissant</MenuItem>
                  </TextField>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignSelf: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {this.state.checked_trash.length > 0 ? (
                      <ColorButton aria-label="Supprimer" onClick={this._handle_click_trash}>
                        <Delete />
                      </ColorButton>
                    ) : this.state.table_empty === false ? (
                      <Checkbox checked={this.state.checked_all_trash} onClick={this._handle_click_all_trash} icon={<Exposure />} />
                    ) : null}
                  </div>
                </Paper>

                <TableContainer component={Paper}>
                  {stableSort(this.state.TEMPORAIRE, getComparator(this.state.order, this.state.orderBy))
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      return (
                        <Accordion key={index} elevation={0} expanded={this.state.EXPANDED === index ? true : false} onChange={this._handle_change_expanded(index)}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            style={{ alignItems: "center", backgroundColor: TemporaireEnErreur.getTemporaireEnErreur().includes(row.id) ? "red" : "tranparent" }}
                          >
                            <Typography variant="body2" style={{ flex: 1 }}>
                              {row.TITRE}
                            </Typography>

                            {row.DATE_DE_CREATION === null ? (
                              <Checkbox style={{ padding: 0 }} checked={_.includes(this.state.checked_trash, row.id)} onClick={this._handle_checked_trash(row)} />
                            ) : null}
                          </AccordionSummary>

                          <AccordionDetails>
                            <Table size="small" style={{ cursor: "pointer" }} onClick={this._handle_click_row(row)}>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Type</TableCell>
                                  <TableCell>{this._cree_chaine_type(row.TYPE_D_ACTION)}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Envoyé</TableCell>
                                  <TableCell>{this._cree_chaine_date(row.DATE1)}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Importé</TableCell>
                                  <TableCell>{this._cree_chaine_date(row.DATE2)}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Validé</TableCell>
                                  <TableCell>{row.STATUS_DE_VALIDATION === 2 ? this._cree_chaine_date(row.DATE3) : null}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Refusé</TableCell>
                                  <TableCell>{row.STATUS_DE_VALIDATION === 3 ? this._cree_chaine_date(row.DATE4) : null}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </TableContainer>
              </div>
            ) : (
              <TableContainer>
                <Table size="small">
                  <EnhancedTableHead
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onRequestSort={this.handleRequestSort}
                    displayTrash={this.state.checked_trash.length > 0}
                    onClickTrash={this._handle_click_trash}
                    checkAll={this.state.checked_all_trash}
                    checkAllClick={this._handle_click_all_trash}
                    tableEmpty={this.state.table_empty}
                  />

                  <TableBody>
                    {stableSort(this.state.TEMPORAIRE, getComparator(this.state.order, this.state.orderBy))
                      .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow
                            style={{
                              cursor: _.find(this.state.cursor, ["id", row.id]) !== undefined ? _.find(this.state.cursor, ["id", row.id]).type : "default",
                              backgroundColor: TemporaireEnErreur.getTemporaireEnErreur().includes(row.id) ? "red" : "tranparent",
                            }}
                            key={index}
                          >
                            <TableCell style={styles.TableCell} onClick={this._handle_click_row(row)}>
                              {this._cree_chaine_type(row.TYPE_D_ACTION)}
                            </TableCell>
                            <TableCell style={styles.TableCell} onClick={this._handle_click_row(row)}>
                              {row.TITRE}
                            </TableCell>
                            <TableCell style={styles.TableCell} onClick={this._handle_click_row(row)}>
                              {this._cree_chaine_date(row.DATE1)}
                            </TableCell>
                            <TableCell style={styles.TableCell} onClick={this._handle_click_row(row)}>
                              {this._cree_chaine_date(row.DATE2)}
                            </TableCell>
                            <TableCell style={styles.TableCell} onClick={this._handle_click_row(row)}>
                              {row.STATUS_DE_VALIDATION === 2 ? this._cree_chaine_date(row.DATE3) : null}
                            </TableCell>
                            <TableCell style={styles.TableCell} onClick={this._handle_click_row(row)}>
                              {row.STATUS_DE_VALIDATION === 3 ? this._cree_chaine_date(row.DATE4) : null}
                            </TableCell>
                            <TableCell style={(styles.TableCell, { textAlign: "center" })}>
                              {row.DATE_DE_CREATION === null ? <Checkbox checked={_.includes(this.state.checked_trash, row.id)} onClick={this._handle_checked_trash(row)} /> : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <TablePagination
              colSpan={3}
              count={this.state.TEMPORAIRE.length}
              rowsPerPage={this.state.rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              page={this.state.page}
              labelRowsPerPage="Nombre de lignes"
              labelDisplayedRows={({ from, to, count }) => (count > 0 ? `De ${from} à ${to === -1 ? count : to} sur ${count !== -1 ? count : to} résultats` : `Aucun résultat`)}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />

            <div style={styles.submit_div}>
              <Button variant="contained" color="primary" onClick={this._handle_click_button_synchroniser} disabled={!this.state.IS_ONLINE || this.state.disabledForm}>
                Synchroniser
              </Button>
            </div>
          </Paper>

          <Dialog fullWidth open={this.state.open_trash_dialog} onClose={this._handle_close_trash_dialog} aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">Annuler l'envoi ?</DialogTitle>
            <DialogActions>
              <Button onClick={this._handle_close_trash_dialog} variant="outlined" color="primary">
                Non
              </Button>
              <Button onClick={this._handle_close_trash_dialog_with_delete} variant="outlined" color="primary" autoFocus>
                Oui
              </Button>
            </DialogActions>
          </Dialog>

          <DialogMajMdf
            state={this.state.loading}
            data_loaded={this.state.data_loaded}
            table_loading={this.state.table_loading}
            nb_total_photos_loading={this.state.nb_total_photos_loading}
            nb_photos_enregistre={this.state.nb_photos_enregistre}
            table_length={this.state.liste_tables.length}
            prestaCarto={this.state.prestaCarto}
          />
          <DialogVersion state={this.state.dialog_version} onChange={(value) => this.setState({ dialog_version: value })} />
          <DialogErreur state={this.state.dialog_erreur} onChange={(value) => this.setState({ dialog_erreur: value })} />

          <Dialog open={this.state.demandeDeMiseAJours} onClose={() => this.setState({ demandeDeMiseAJours: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Synchronisation des données"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Confirmez-vous la synchronisation des données avec Gescime ?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ demandeDeMiseAJours: false })}>Non</Button>
              <Button onClick={this._handle_click_button_synchroniser_oui} autoFocus>
                Oui
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.dialogMiseAJourEnCours} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Synchronisation des données"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.open_snackbar_envoye ? (
                  `Envoi des données effectué`
                ) : (
                  <span>
                    Synchronisation des données en cours.
                    <br />
                    Merci de ne pas fermer cette fenêtre le temps du transfert
                  </span>
                )}
              </DialogContentText>
            </DialogContent>
            {this.state.open_snackbar_envoye ? (
              <DialogActions>
                <Button onClick={this._handle_close_dialog_miseAJourEnCours}>ok</Button>
              </DialogActions>
            ) : null}
          </Dialog>

          <Dialog open={this.state.dialog_actualiser} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Actualisation des données"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Traitement en cours. Veuillez patienter…</DialogContentText>
            </DialogContent>
          </Dialog>

          <Dialog open={this.state.dialog_actualiser_terminer} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Actualisation des données"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{`Traitement terminé`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ dialog_actualiser_terminer: false })}>ok</Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={this.state.open_snackbar_telechargement}
            onClose={() => this.setState({ open_snackbar_telechargement: false })}
          >
            <Alert severity="info" onClose={() => this.setState({ open_snackbar_telechargement: false })}>
              Téléchargement de la base de données en cours
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    );
  }
}

export default withWidth()(withCookies(Synchronisation));
