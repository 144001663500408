import { GetAllCimetier } from "./Get/Cimetier";
import { CreateCimetier } from "./Create/Cimetier";
import { CreateClient } from "./Create/Client";
import { CreateGNUtil } from "./Create/GNUtil";
import { CreateParam } from "./Create/Param";
import { CreateDefunt } from "./Create/Defunt";
import { CreateLieu } from "./Create/Lieu";
import { CreateCendre } from "./Create/Cendre";
import { CreatePlan } from "./Create/Plan";
import { CreateZone } from "./Create/Zone";
import { CreateConcess } from "./Create/Concess";
import { CreateConcaire } from "./Create/Concaire";
import { CreateTypetb } from "./Create/Typetb";
import { CreateDuree } from "./Create/Duree";
import { CreatePhotoconc } from "./Create/Photoconc";
import { CreateInterven } from "./Create/Interven";
import { CreateIntervenDefunt } from "./Create/IntervenDefunt";
import { CreateNatinterv } from "./Create/Natinterv";
import { CreateProcedureAbandon } from "./Create/ProcedureAbandon";
import { CreateSociete } from "./Create/Societe";
import { CreateUtil } from "./Create/Util";
import { CreateGens } from "./Create/Gens";
import { CreateAgendaTache } from "./Create/AgendaTache";
import { CreateMouvement } from "./Create/Mouvement";
import { CreateAccessibilite } from "./Create/Accessibilite";
import { CreateThanatomorphose } from "./Create/Thanatomorphose";
import { CreateEtatTerrain } from "./Create/EtatTerrain";
import { CreateConcessEtatEcheance } from "./Create/ConcessEtatEcheance";
import { CreateMateriau } from "./Create/Materiau";
import { CreatePa } from "./Create/Pa";
import { CreateLigneAction } from "./Create/LigneAction";
import { CreateSvg } from "./Create/Svg";
import { CreateDefuntS } from "./Create/DefuntS";
import LoaderState from "./../Components/Global/Object/LoaderState";

var _ = require("lodash");

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function CreateTable(liste_cimetiere, headers, _change_loader_state, idTablette) {
  return new Promise((resolve, reject) => {
    const createCimetiere = CreateCimetier(liste_cimetiere, headers);

    createCimetiere
      .then((created) => {
        if (created === undefined || created !== "") return reject(created);

        const createClient = CreateClient(headers, idTablette);

        createClient
          .then((created) => {
            if (created === undefined || created !== "") return reject(created);

            GetAllCimetier(1).then((cimetiere) => {
              let cimetiere_ids = _.map(cimetiere, "NUMCIME");
              headers.CIMETIERE = cimetiere_ids;

              LoaderState.setTable("GN_UTIL");
              _change_loader_state();

              const createGNUtil = CreateGNUtil(headers);

              createGNUtil
                .then((created) => {
                  if (created === undefined || created !== "") return reject(created);

                  LoaderState.setIndex(LoaderState.getIndex() + 1);
                  LoaderState.setTable("PARAM");
                  _change_loader_state();

                  const createParam = CreateParam(headers);

                  createParam
                    .then((created) => {
                      if (created === undefined || created !== "") return reject(created);

                      LoaderState.setIndex(LoaderState.getIndex() + 1);
                      LoaderState.setTable("DEFUNT");
                      _change_loader_state();

                      const createDefunt = CreateDefunt(headers);

                      createDefunt
                        .then((created) => {
                          if (created === undefined || created !== "") return reject(created);

                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                          LoaderState.setTable("LIEU");
                          _change_loader_state();

                          timeout(1000).then(() => {
                            const createLieu = CreateLieu(headers);

                            createLieu
                              .then((created) => {
                                if (created === undefined || created !== "") return reject(created);

                                LoaderState.setIndex(LoaderState.getIndex() + 1);
                                LoaderState.setTable("CENDRE");
                                _change_loader_state();

                                timeout(1000).then(() => {
                                  const createCendre = CreateCendre(headers);

                                  createCendre
                                    .then((created) => {
                                      if (created === undefined || created !== "") return reject(created);

                                      LoaderState.setIndex(LoaderState.getIndex() + 1);
                                      LoaderState.setTable("PLAN");
                                      _change_loader_state();

                                      const createPlan = CreatePlan(headers);

                                      createPlan
                                        .then((created) => {
                                          if (created === undefined || created !== "") return reject(created);

                                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                                          LoaderState.setTable("ZONE");
                                          _change_loader_state();

                                          const createZone = CreateZone(headers);

                                          createZone
                                            .then((created) => {
                                              if (created === undefined || created !== "") return reject(created);

                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                              LoaderState.setTable("CONCESS");
                                              _change_loader_state();

                                              timeout(1000).then(() => {
                                                const createConcess = CreateConcess(headers);

                                                createConcess
                                                  .then((created) => {
                                                    if (created === undefined || created !== "") return reject(created);

                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                    LoaderState.setTable("CONCAIRE");
                                                    _change_loader_state();

                                                    timeout(1000).then(() => {
                                                      const createConcaire = CreateConcaire(headers);

                                                      createConcaire
                                                        .then((created) => {
                                                          if (created === undefined || created !== "") return reject(created);

                                                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                          LoaderState.setTable("TYPETB");
                                                          _change_loader_state();

                                                          timeout(1000).then(() => {
                                                            const createTypetb = CreateTypetb(headers);

                                                            createTypetb
                                                              .then((created) => {
                                                                if (created === undefined || created !== "") return reject(created);

                                                                LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                LoaderState.setTable("DUREE");
                                                                _change_loader_state();

                                                                const createDuree = CreateDuree(headers);

                                                                createDuree
                                                                  .then((created) => {
                                                                    if (created === undefined || created !== "") return reject(created);

                                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                    LoaderState.setTable("INTERVEN");
                                                                    _change_loader_state();

                                                                    const createInterven = CreateInterven(headers);

                                                                    createInterven
                                                                      .then((created) => {
                                                                        if (created === undefined || created !== "") return reject(created);

                                                                        LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                        LoaderState.setTable("INTERVEN_DEFUNT");
                                                                        _change_loader_state();

                                                                        timeout(1000).then(() => {
                                                                          const createIntervenDefunt = CreateIntervenDefunt(headers);

                                                                          createIntervenDefunt
                                                                            .then((created) => {
                                                                              if (created === undefined || created !== "") return reject(created);

                                                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                              LoaderState.setTable("NATINTERV");
                                                                              _change_loader_state();

                                                                              timeout(1000).then(() => {
                                                                                const createNatinterv = CreateNatinterv(headers);

                                                                                createNatinterv
                                                                                  .then((created) => {
                                                                                    if (created === undefined || created !== "") return reject(created);

                                                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                    LoaderState.setTable("PROCEDUREABANDON");
                                                                                    _change_loader_state();

                                                                                    timeout(1000).then(() => {
                                                                                      const createProcedureAbandon = CreateProcedureAbandon(headers);

                                                                                      createProcedureAbandon
                                                                                        .then((created) => {
                                                                                          if (created === undefined || created !== "") return reject(created);

                                                                                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                          LoaderState.setTable("SOCIETE");
                                                                                          _change_loader_state();

                                                                                          const createSociete = CreateSociete(headers);

                                                                                          createSociete
                                                                                            .then((created) => {
                                                                                              if (created === undefined || created !== "") return reject(created);

                                                                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                              LoaderState.setTable("UTIL");
                                                                                              _change_loader_state();

                                                                                              timeout(1000).then(() => {
                                                                                                const createUtil = CreateUtil(headers);

                                                                                                createUtil
                                                                                                  .then((created) => {
                                                                                                    if (created === undefined || created !== "") return reject(created);

                                                                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                    LoaderState.setTable("GENS");
                                                                                                    _change_loader_state();

                                                                                                    const createGens = CreateGens(headers);

                                                                                                    createGens
                                                                                                      .then((created) => {
                                                                                                        if (created === undefined || created !== "") return reject(created);

                                                                                                        LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                        LoaderState.setTable("AGENDATACHE");
                                                                                                        _change_loader_state();

                                                                                                        const createAgendaTache = CreateAgendaTache(headers);

                                                                                                        createAgendaTache
                                                                                                          .then((created) => {
                                                                                                            if (created === undefined || created !== "") return reject(created);

                                                                                                            LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                            LoaderState.setTable("MOUVEMENT");
                                                                                                            _change_loader_state();

                                                                                                            timeout(1000).then(() => {
                                                                                                              const createMouvement = CreateMouvement(headers);

                                                                                                              createMouvement
                                                                                                                .then((created) => {
                                                                                                                  if (created === undefined || created !== "") return reject(created);

                                                                                                                  LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                  LoaderState.setTable("ACCESSIBILITE");
                                                                                                                  _change_loader_state();

                                                                                                                  timeout(1000).then(() => {
                                                                                                                    const createAccessibilite = CreateAccessibilite(headers);

                                                                                                                    createAccessibilite
                                                                                                                      .then((created) => {
                                                                                                                        if (created === undefined || created !== "") return reject(created);

                                                                                                                        LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                        LoaderState.setTable("THANATOMORPHOSE");
                                                                                                                        _change_loader_state();

                                                                                                                        const createThanatomorphose = CreateThanatomorphose(headers);

                                                                                                                        createThanatomorphose
                                                                                                                          .then((created) => {
                                                                                                                            if (created === undefined || created !== "") return reject(created);

                                                                                                                            LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                            LoaderState.setTable("ETAT_TERRAIN");
                                                                                                                            _change_loader_state();

                                                                                                                            const createEtatTerrain = CreateEtatTerrain(headers);

                                                                                                                            createEtatTerrain
                                                                                                                              .then((created) => {
                                                                                                                                if (created === undefined || created !== "") return reject(created);

                                                                                                                                LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                LoaderState.setTable("CONCESS_ETAT_ECHEANCE");
                                                                                                                                _change_loader_state();

                                                                                                                                const createConcessEtatEcheance = CreateConcessEtatEcheance(headers);

                                                                                                                                createConcessEtatEcheance
                                                                                                                                  .then((created) => {
                                                                                                                                    if (created === undefined || created !== "") return reject(created);

                                                                                                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                    LoaderState.setTable("MATERIAU");
                                                                                                                                    _change_loader_state();

                                                                                                                                    const createMateriau = CreateMateriau(headers);

                                                                                                                                    createMateriau
                                                                                                                                      .then((created) => {
                                                                                                                                        if (created === undefined || created !== "")
                                                                                                                                          return reject(created);

                                                                                                                                        LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                        LoaderState.setTable("PA");
                                                                                                                                        _change_loader_state();

                                                                                                                                        const createPa = CreatePa(headers);

                                                                                                                                        createPa
                                                                                                                                          .then((created) => {
                                                                                                                                            if (created === undefined || created !== "")
                                                                                                                                              return reject(created);

                                                                                                                                            LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                            LoaderState.setTable("LIGNE_ACTION");
                                                                                                                                            _change_loader_state();

                                                                                                                                            const createLigneAction = CreateLigneAction(headers);

                                                                                                                                            createLigneAction
                                                                                                                                              .then((created) => {
                                                                                                                                                if (created === undefined || created !== "")
                                                                                                                                                  return reject(created);

                                                                                                                                                LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                                LoaderState.setTable("SVG");
                                                                                                                                                _change_loader_state();

                                                                                                                                                timeout(1000).then(() => {
                                                                                                                                                  const createSvg = CreateSvg(false);

                                                                                                                                                  createSvg
                                                                                                                                                    .then((created) => {
                                                                                                                                                      if (created === undefined || created !== "")
                                                                                                                                                        return;

                                                                                                                                                      LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                                      LoaderState.setTable("PHOTOCONC");
                                                                                                                                                      _change_loader_state();

                                                                                                                                                      timeout(1000).then(() => {
                                                                                                                                                        const createPhotoconc = CreatePhotoconc(
                                                                                                                                                          headers,
                                                                                                                                                          _change_loader_state
                                                                                                                                                        );

                                                                                                                                                        createPhotoconc
                                                                                                                                                          .then((created) => {
                                                                                                                                                            if (created === undefined || created !== "")
                                                                                                                                                              return reject(created);

                                                                                                                                                            LoaderState.setIndex(
                                                                                                                                                              LoaderState.getIndex() + 1
                                                                                                                                                            );
                                                                                                                                                            LoaderState.setTable("DEFUNT_S");
                                                                                                                                                            LoaderState.setNombreTotalPhotos(0);
                                                                                                                                                            LoaderState.setNombrePhotos(0);
                                                                                                                                                            _change_loader_state();

                                                                                                                                                            timeout(1000).then(() => {
                                                                                                                                                              const createDefuntS =
                                                                                                                                                                CreateDefuntS(headers);

                                                                                                                                                              createDefuntS
                                                                                                                                                                .then((created) => {
                                                                                                                                                                  if (
                                                                                                                                                                    created === undefined ||
                                                                                                                                                                    created !== ""
                                                                                                                                                                  )
                                                                                                                                                                    return;

                                                                                                                                                                  LoaderState.setIndex(
                                                                                                                                                                    LoaderState.getIndex() + 1
                                                                                                                                                                  );
                                                                                                                                                                  _change_loader_state();

                                                                                                                                                                  timeout(1000).then(() => {
                                                                                                                                                                    return resolve("");
                                                                                                                                                                  });
                                                                                                                                                                })
                                                                                                                                                                .catch((err) => {
                                                                                                                                                                  return reject(err);
                                                                                                                                                                });
                                                                                                                                                            });
                                                                                                                                                          })
                                                                                                                                                          .catch((err) => {
                                                                                                                                                            return reject(err);
                                                                                                                                                          });
                                                                                                                                                      });
                                                                                                                                                    })
                                                                                                                                                    .catch((err) => {
                                                                                                                                                      return reject(err);
                                                                                                                                                    });
                                                                                                                                                });
                                                                                                                                              })
                                                                                                                                              .catch((err) => {
                                                                                                                                                return reject(err);
                                                                                                                                              });
                                                                                                                                          })
                                                                                                                                          .catch((err) => {
                                                                                                                                            return reject(err);
                                                                                                                                          });
                                                                                                                                      })
                                                                                                                                      .catch((err) => {
                                                                                                                                        return reject(err);
                                                                                                                                      });
                                                                                                                                  })
                                                                                                                                  .catch((err) => {
                                                                                                                                    return reject(err);
                                                                                                                                  });
                                                                                                                              })
                                                                                                                              .catch((err) => {
                                                                                                                                return reject(err);
                                                                                                                              });
                                                                                                                          })
                                                                                                                          .catch((err) => {
                                                                                                                            return reject(err);
                                                                                                                          });
                                                                                                                      })
                                                                                                                      .catch((err) => {
                                                                                                                        return reject(err);
                                                                                                                      });
                                                                                                                  });
                                                                                                                })
                                                                                                                .catch((err) => {
                                                                                                                  return reject(err);
                                                                                                                });
                                                                                                            });
                                                                                                          })
                                                                                                          .catch((err) => {
                                                                                                            return reject(err);
                                                                                                          });
                                                                                                      })
                                                                                                      .catch((err) => {
                                                                                                        return reject(err);
                                                                                                      });
                                                                                                  })
                                                                                                  .catch((err) => {
                                                                                                    return reject(err);
                                                                                                  });
                                                                                              });
                                                                                            })
                                                                                            .catch((err) => {
                                                                                              return reject(err);
                                                                                            });
                                                                                        })
                                                                                        .catch((err) => {
                                                                                          return reject(err);
                                                                                        });
                                                                                    });
                                                                                  })
                                                                                  .catch((err) => {
                                                                                    return reject(err);
                                                                                  });
                                                                              });
                                                                            })
                                                                            .catch((err) => {
                                                                              return reject(err);
                                                                            });
                                                                        });
                                                                      })
                                                                      .catch((err) => {
                                                                        return reject(err);
                                                                      });
                                                                  })
                                                                  .catch((err) => {
                                                                    return reject(err);
                                                                  });
                                                              })
                                                              .catch((err) => {
                                                                return reject(err);
                                                              });
                                                          });
                                                        })
                                                        .catch((err) => {
                                                          return reject(err);
                                                        });
                                                    });
                                                  })
                                                  .catch((err) => {
                                                    return reject(err);
                                                  });
                                              });
                                            })
                                            .catch((err) => {
                                              return reject(err);
                                            });
                                        })
                                        .catch((err) => {
                                          return reject(err);
                                        });
                                    })
                                    .catch((err) => {
                                      return reject(err);
                                    });
                                });
                              })
                              .catch((err) => {
                                return reject(err);
                              });
                          });
                        })
                        .catch((err) => {
                          return reject(err);
                        });
                    })
                    .catch((err) => {
                      return reject(err);
                    });
                })
                .catch((err) => {
                  return reject(err);
                });
            });
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
