import React, { Component } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { IsOracle } from "./../Helpers/Test/IsOracle";

const styles = {
  grid: {
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
};

class Aide extends Component {
  constructor(props) {
    super(props);

    props.changeTitle(props.title);

    this.state = {
      IS_ORACLE: false,
    };
  }

  componentDidMount = () => {
    IsOracle().then((ora) => {
      this.setState({ IS_ORACLE: ora === 1 });
    });
  };

  render() {
    console.log("RENDER AIDE");

    return (
      <Grid container alignItems="baseline" justify="center" style={{ ...styles.grid, alignItems: "stretch" }}>
        <Grid item xs={10} style={styles.grid_item}>
          <Paper
            style={{
              ...styles.paper,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="body2">
              <br />
              <br />
              <span>
                Bienvenue dans l’application <b>‘Gardiens de cimetières’</b>. Cette application peut être utilisée offline, en mode avion. Il faudra penser à synchroniser manuellement les données avec
                Gescime, à la fréquence souhaitée, via une connexion Wi-fi ou clé 4G.
              </span>
              <br />
              <br />
              <span>Cette application vous permettra de modifier ou d’ajouter des données métier dans Gescime, tout en étant dans le cimetière.</span>
              <br />
              <br />
              <br />
              <br />
              <span>
                <b>Ainsi vous retrouverez les thèmes suivants : </b>
              </span>
              <br />
              <ul style={{ listStyleType: "' - '" }}>
                <li>
                  <u>PLANNING</u>
                  <ul>
                    <li>{`Ce menu vous permettra de consulter le planning du cimetière (inhumations, exhumations, travaux) et de localiser les emplacements concernés`}</li>
                  </ul>
                </li>
                <br />

                <li>
                  <u>DEFUNTS</u>
                  <ul>
                    <li>Ce menu vous permettra de rechercher un défunt et de le localiser sur la carte</li>
                  </ul>
                </li>
                <br />

                <li>
                  <u>CARTOGRAPHIE</u>
                  <ul>
                    <li>Ce menu vous permettra d’obtenir des informations sur un emplacement et d’accéder à la concession rattachée</li>
                  </ul>
                </li>
                <br />

                <li>
                  <u>CONCESSIONS</u>
                  <ul>
                    <li>Ce menu vous permettra de rechercher une concession et de la localiser sur la carte</li>
                  </ul>
                </li>
                <br />

                <li>
                  <u>PROCEDURES</u>
                  <ul>
                    <li>
                      Ce menu vous permettra de visualiser les concessions :
                      <ul>
                        <li>{`Rattachées à une procédure de reprise pour état d’abandon et réaliser le constat (photographies, descriptif, etc.)`}</li>
                        <li>{`Expirées et y déposer les plaquettes ou modifier leur statut (à reprendre, en cours de reprise, etc.)`}</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <br />

                <li>
                  <u>PHOTOS</u>
                  <ul>
                    <li>Ce menu vous permettra de réaliser des photographies des sépultures</li>
                    <li>Attention, afin d'optimiser l'espace de stockage, les photographies réalisées sont redimensionnées en 2048 avec un taux de compression de 50%</li>
                  </ul>
                </li>
                <br />

                <li>
                  <u>TRAVAUX</u>
                  <ul>
                    <li>{`Ce menu vous permettra de suivre les travaux dans le cimetière et réaliser le constat (photographies, état des monuments adjacents, signatures, etc.)`}</li>
                  </ul>
                </li>
              </ul>
              <br />
              <br />
              <span>
                <b>Chaque concession possède 6 onglets : </b>
              </span>
              <br />
              <ul style={{ listStyleType: "' - '" }}>
                <li>
                  <u>CONTRAT</u>
                  {this.state.IS_ORACLE
                    ? ` : contient les informations relatives au contrat (date de prise, d’expiration, durée, etc.). Informations modifiables : nom de famille, genre, superficie, état d’échéance`
                    : ` : contient les informations relatives au contrat (date de prise, d’expiration, durée, etc.). Informations modifiables : nom de famille, genre, superficie, à reprendre, pose plaquette`}
                </li>
                <br />

                <li>
                  <u>INTERLOCUTEURS</u>
                  {` : contient les informations relatives aux interlocuteurs (coordonnées, qualité, bénéficiaire, etc.). Possibilité d’ajouter un interlocuteur. Informations modifiables :
                  coordonnées et lien de parenté avec le concessionnaire.`}
                </li>
                <br />

                <li>
                  <u>ETAT</u>
                  {this.state.IS_ORACLE
                    ? ` : contient les informations relatives au contrat (date de prise, d’expiration, durée, etc.). Informations modifiables : nom de famille, genre, superficie, état d’échéance`
                    : ` : contient les informations relatives au contrat (date de prise, d’expiration, durée, etc.). Possibilité d’ajouter des photographies. Informations modifiables : nom de famille, genre, superficie, à reprendre, pose plaquette`}
                </li>
                <br />

                <li>
                  <u>LIEU</u>
                  {this.state.IS_ORACLE
                    ? ` : contient les informations relatives à l’emplacement (thanatomorphose, nature du sol, accessibilité, entrée caveau, emplacement gelé, monument restant, passe-pieds). Informations modifiables : tout.`
                    : ` : contient les informations relatives à l’emplacement (entrée caveau, monument restant). Informations modifiables : tout.`}
                </li>
                <br />

                <li>
                  <u>DEFUNTS</u>
                  {` : contient les informations relatives au défunt (état civil, dates de naissance et de décès, âge, date et heure d’inhumation, enveloppe, etc.). Possibilité d’ajouter un
                  défunt. Informations modifiables : tout sauf l’enveloppe (à part en ajout).`}
                </li>
                <br />

                <li>
                  <u>TRAVAUX</u>
                  {` : contient les informations relatives aux travaux réalisés (intervenant, dates, nature de l’opération, etc.). Possibilité d’ajouter un constat de travaux. Informations
                  modifiables : dates et heures de début et de fin, suivi.`}
                </li>
              </ul>
              <br />
              <br />
              <span>
                La <b>SYNCHRONISATION DES DONNEES</b>
                {` est manuelle et se fait depuis le menu ‘Synchronisation’ > METTRE A JOUR (envoi des données).`}
              </span>
              <br />
              <span>{`Cette liste se videra dès le téléchargement d'une nouvelle base de données.`}</span>
              <br />
              <br />
              <span>
                Un <b>code couleur</b> sur les enregistrements modifiés vous permet de connaître l’état de votre modification :{" "}
              </span>
              <br />
              <ul style={{ listStyleType: "' - '" }}>
                <li>
                  <span style={{ color: "red" }}>Rouge</span> : la modification n’a pas encore été validée dans Gescime
                </li>
                <li>
                  <span style={{ color: "green" }}>Vert</span> : la modification a été transmise à Gescime
                </li>
                <li>
                  <span style={{ color: "black" }}>Noir</span> : la modification est validée ou refusée dans Gescime
                </li>
                <li>
                  <span style={{ backgroundColor: "red" }}>Ligne rouge</span> : l'enregistrement n'a pas pu être transmis et sera à supprimer de la liste
                </li>
              </ul>
              <br />
              <br />
              <span>
                Une <b>DECONNEXION AUTOMATIQUE</b>
                {` est programmée au bout de 30 minutes d'inactivité. Il faudra se reconnecter à son compte pour utiliser l'application.`}
              </span>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default Aide;
