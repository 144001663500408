import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, Dialog, Slide, AppBar, Toolbar, IconButton, Grid, List, ListItem, ListItemText, Button } from "@material-ui/core";
import { PermIdentity, Close } from "@material-ui/icons";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";

import { GetConcaireByCocleunik } from "./../../../Helpers/Get/Concaire";
import { IsOracle } from "./../../../Helpers/Test/IsOracle";
import { GetTemporaire, GetTemporaireForColor } from "../../../Helpers/Get/Temporaire";
import { withCookies } from "react-cookie";
import { CheckUserRight } from "./../../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "./../../Global/DialogAccessRight";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: 16,
  },
  papertitle: {
    display: "flex",
    marginBottom: 20,
  },
  typographytitle: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    justifyContent: "center",
    marginBottom: 0,
    paddingRight: 95,
  },
  table_concaire_row: {
    cursor: "pointer",
  },
  typography: {
    flex: 1,
  },
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  dialogpaper: {
    display: "flex",
    padding: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CFCInterlocuteur extends Component {
  static propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xs"]).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      CONCAIRE: [],
      FICHE_CONCAIRE: null,
      DIALOG_OPEN: false,
      REDIRECT: null,
      concaire_modifie: [],
      champ_modifie: [],
      en_cours_de_traitement: false,
      droit_util_ajout_interlo: false,
      droit_util_modif_interlo: false,
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    let field = [];
    GetConcaireByCocleunik(this.props.concess.COCLEUNIK).then((concaire) => {
      this.setState({
        CONCAIRE: concaire,
      });

      _.forEach(concaire, (c) => {
        const temporaire = GetTemporaireForColor("CONCAIRE", "C0CLEUNIK", c.C0CLEUNIK);

        temporaire.then((temp) => {
          if (temp.length > 0) {
            let sub_field = {};

            _.forEach(temp, (t) => {
              let color = "red";

              if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                color = "blue";
              }

              if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                color = "green";
              }

              if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                color = "primary";
              }

              var element_temp = t.TABLE_MODIFIE_JSON;
              element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

              for (var key in element_temp) {
                sub_field[key] = color;

                if (key === "C0CLEUNIK") sub_field[key] = element_temp.C0CLEUNIK;
              }

              field.push(sub_field);
            });

            this.setState({
              champ_modifie: field,
            });
          }
        });
      });
    });
  };

  _cree_chaine_civilite = (civilite) => {
    switch (civilite) {
      case 1:
        return "Monsieur";
      case 2:
        return "Madame";
      case 3:
        return "Mademoiselle";
      case 4:
        return this.state.IS_ORACLE ? "Héritier(s)" : "Enfant";
      case 5:
        return "Autre";
      default:
        return "";
    }
  };

  _cree_chaine_situation = (concaire) => {
    let typ = [];

    let typeinterlo = { ...concaire.TYPEINTERLO };
    typeinterlo = _.omit(typeinterlo, ["INHUMABLE", "INDESIRABLE", "DONATAIRE_LEGATAIRE", "EXECUTEUR_TESTAMENTAIRE"]);

    let cle = _.findKey(typeinterlo, (item) => item === 1);
    switch (cle) {
      case "CONCESSIONNAIRE":
        typ.push("Concessionnaire");
        break;
      case "COCONCESSIONNAIRE":
        typ.push("Co-concessionnaire");
        break;
      case "AYANTDROIT":
        typ.push("Ayant-droit");
        break;
      case "AUTRE":
        typ.push(this.state.IS_ORACLE ? "Tiers" : "Autre");
        break;
      default:
        break;
    }

    if (concaire.TYPEINTERLO.INHUMABLE === 1) {
      typ.push("Inhumable");
    }

    if (concaire.TYPEINTERLO.INDESIRABLE === 1 && !this.state.IS_ORACLE) {
      typ.push("Indésirable");
    }

    if (concaire.TYPEINTERLO.DONATAIRE_LEGATAIRE === 1 && this.state.IS_ORACLE) {
      typ.push("Donataire légataire");
    }

    if (concaire.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE === 1 && this.state.IS_ORACLE) {
      typ.push("Exécuteur testamentaire");
    }

    if (this.props.concess.INTERLOCUTEUR_C0CLEUNIK === concaire.C0CLEUNIK) {
      typ.push("Référent");
    }

    if (concaire.DCD === 1) {
      typ.push(`Décédé${concaire.CIVILITE > 1 && concaire.CIVILITE < 4 ? "e" : ""}`);
    }

    return _.join(typ, ", ");
  };

  _get_color = (modifie, champ) => {
    return _.has(modifie, champ) ? _.get(modifie, champ) : "primary";
  };

  _find_color = (modifie, c0cleunik) => {
    const temp = _.find(modifie, ["C0CLEUNIK", c0cleunik]);

    let color = [];
    for (var key in temp) {
      if (typeof temp[key] === "string") {
        if (!_.includes(color, temp[key])) color.push(temp[key]);
      }
    }

    if (_.includes(color, "red")) return "red";
    if (_.includes(color, "blue")) return "blue";
    if (_.includes(color, "green")) return "green";

    return "primary";
  };

  _handle_click_table_row = (index) => (event) => {
    event.preventDefault();

    GetTemporaire("CONCAIRE", "C0CLEUNIK", this.state.CONCAIRE[index].C0CLEUNIK).then((temporaire) => {
      this.setState({
        en_cours_de_traitement: false,
      });

      if (temporaire !== undefined) {
        if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
          this.setState({
            en_cours_de_traitement: true,
          });
        }
      }
    });

    this.setState({
      FICHE_CONCAIRE: index,
      DIALOG_OPEN: true,
    });
  };

  render() {
    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <Paper elevation={0} style={styles.papertitle}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              CheckUserRight(this.state.ID_UTIL, "CONCAIRE", "DROITAJOUT").then((asRight) => {
                if (asRight) {
                  this.setState({
                    REDIRECT: `/modifier/interlocuteur/${this.props.concess.COCLEUNIK}`,
                  });
                } else {
                  this.setState({ droit_util_ajout_interlo: true });
                }
              });
            }}
          >
            Ajouter
          </Button>

          <Typography variant="body2" gutterBottom style={styles.typographytitle} className="TypographyTitle-FicheConcession">
            Liste des interlocuteurs
          </Typography>
        </Paper>

        {width === "xs" || width === "sm" ? (
          <List component="nav">
            {this.state.CONCAIRE.map((concaire, index) => (
              <div key={index}>
                <ListItem button style={{ color: this._find_color(this.state.champ_modifie, concaire.C0CLEUNIK) }} onClick={this._handle_click_table_row(index)}>
                  <ListItemText
                    primary={`${this._cree_chaine_civilite(concaire.CIVILITE)} ${concaire.NOM}${concaire.NOMJEUNE.trim() !== "" ? ` ${concaire.NOMJEUNE}` : ""}${
                      concaire.PRENOM.trim() !== "" ? ` ${concaire.PRENOM}` : ""
                    }`}
                  />
                </ListItem>
              </div>
            ))}
          </List>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Civilité</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Nom de naissance</TableCell>
                <TableCell>Prénom</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.CONCAIRE.map((concaire, index) => (
                <TableRow
                  key={index}
                  hover
                  style={
                    (styles.table_concaire_row,
                    {
                      color: this._find_color(this.state.champ_modifie, concaire.C0CLEUNIK),
                    })
                  }
                  onClick={this._handle_click_table_row(index)}
                >
                  <TableCell style={{ color: "inherit" }}>
                    {this.props.concess.INTERLOCUTEUR_C0CLEUNIK === concaire.C0CLEUNIK ? (
                      <PermIdentity
                        style={{
                          width: 18,
                          height: 18,
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>

                  <TableCell
                    style={{
                      color: "inherit",
                    }}
                  >
                    {this._cree_chaine_civilite(concaire.CIVILITE)}
                  </TableCell>

                  <TableCell
                    style={{
                      color: "inherit",
                    }}
                  >
                    {concaire.NOM}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "inherit",
                    }}
                  >
                    {concaire.NOMJEUNE}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "inherit",
                    }}
                  >
                    {concaire.PRENOM}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {this.state.FICHE_CONCAIRE !== null ? (
          <Dialog fullScreen open={this.state.DIALOG_OPEN} onClose={() => this.setState({ DIALOG_OPEN: false })} TransitionComponent={Transition}>
            <AppBar style={{ paddingLeft: 17 }}>
              <Toolbar disableGutters>
                <Typography variant="h6" style={styles.typography}>
                  {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].NOM}
                </Typography>

                <IconButton color="inherit" aria-label="close" onClick={() => this.setState({ DIALOG_OPEN: false })}>
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Toolbar />

            <Grid container alignItems="baseline" justify="center" style={styles.grid}>
              <Grid item xs={11} md={8} style={styles.grid_item}>
                <Paper style={styles.paper}>
                  <Paper elevation={0} style={styles.dialogpaper}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.en_cours_de_traitement}
                      onClick={() => {
                        CheckUserRight(this.state.ID_UTIL, "CONCAIRE", "DROITMODIF").then((asRight) => {
                          if (asRight) {
                            this.setState({
                              REDIRECT: `/modifier/interlocuteur/${this.props.concess.COCLEUNIK}/${this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK}`,
                            });
                          } else {
                            this.setState({ droit_util_modif_interlo: true });
                          }
                        });
                      }}
                    >
                      Modifier
                    </Button>

                    {this.state.en_cours_de_traitement ? (
                      <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                        Modification impossible : Interlocuteur non traité dans Gescime
                      </Typography>
                    ) : null}
                  </Paper>

                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CIVILITE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CIVILITE")
                              : "primary",
                          }}
                        >
                          Civilité
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CIVILITE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CIVILITE")
                              : "primary",
                          }}
                        >
                          {this._cree_chaine_civilite(this.state.CONCAIRE[this.state.FICHE_CONCAIRE].CIVILITE)}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOM")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOM")
                              : "primary",
                          }}
                        >
                          Nom
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOM")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOM")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].NOM}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOMJEUNE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOMJEUNE")
                              : "primary",
                          }}
                        >
                          Nom de naissance
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOMJEUNE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "NOMJEUNE")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].NOMJEUNE}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PRENOM")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PRENOM")
                              : "primary",
                          }}
                        >
                          Prénom
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PRENOM")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PRENOM")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].PRENOM}
                        </TableCell>
                      </TableRow>

                      {this.state.IS_ORACLE ? (
                        <TableRow>
                          <TableCell
                            style={{
                              color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_NUMERO")
                                ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_NUMERO")
                                : "primary",
                            }}
                          >
                            Numéro de voie
                          </TableCell>
                          <TableCell
                            style={{
                              color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_NUMERO")
                                ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_NUMERO")
                                : "primary",
                            }}
                          >
                            {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].ADR_NUMERO}
                          </TableCell>
                        </TableRow>
                      ) : null}

                      {this.state.IS_ORACLE ? (
                        <TableRow>
                          <TableCell
                            style={{
                              color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_BISTER")
                                ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_BISTER")
                                : "primary",
                            }}
                          >
                            Bis / Ter
                          </TableCell>
                          <TableCell
                            style={{
                              color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_BISTER")
                                ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_BISTER")
                                : "primary",
                            }}
                          >
                            {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].ADR_BISTER}
                          </TableCell>
                        </TableRow>
                      ) : null}

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADRESSE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADRESSE")
                              : "primary",
                          }}
                        >
                          Adresse
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADRESSE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADRESSE")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].ADRESSE}
                        </TableCell>
                      </TableRow>

                      {this.state.IS_ORACLE ? (
                        <TableRow>
                          <TableCell
                            style={{
                              color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_COMP")
                                ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_COMP")
                                : "primary",
                            }}
                          >
                            Complément d'adresse
                          </TableCell>
                          <TableCell
                            style={{
                              color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_COMP")
                                ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "ADR_COMP")
                                : "primary",
                            }}
                          >
                            {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].ADR_COMP}
                          </TableCell>
                        </TableRow>
                      ) : null}

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CODEPO")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CODEPO")
                              : "primary",
                          }}
                        >
                          Code postal
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CODEPO")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "CODEPO")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].CODEPO}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "VILLE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "VILLE")
                              : "primary",
                          }}
                        >
                          Ville
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "VILLE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "VILLE")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].VILLE}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TEL")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TEL")
                              : "primary",
                          }}
                        >
                          Téléphone fixe
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TEL")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TEL")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].TEL}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PORTABLE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PORTABLE")
                              : "primary",
                          }}
                        >
                          Téléphone portable
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PORTABLE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PORTABLE")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].PORTABLE}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), this.state.IS_ORACLE ? "ADRMAIL" : "ADRESSEMAIL")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), this.state.IS_ORACLE ? "ADRMAIL" : "ADRESSEMAIL")
                              : "primary",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), this.state.IS_ORACLE ? "ADRMAIL" : "ADRESSEMAIL")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), this.state.IS_ORACLE ? "ADRMAIL" : "ADRESSEMAIL")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].ADRESSEMAIL}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PARENTE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PARENTE")
                              : "primary",
                          }}
                        >
                          Parenté concessionnaire
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PARENTE")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "PARENTE")
                              : "primary",
                          }}
                        >
                          {this.state.CONCAIRE[this.state.FICHE_CONCAIRE].PARENTE}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TYPEINTERLO")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TYPEINTERLO")
                              : "primary",
                          }}
                        >
                          Qualité
                        </TableCell>
                        <TableCell
                          style={{
                            color: _.has(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TYPEINTERLO")
                              ? _.get(_.find(this.state.champ_modifie, ["C0CLEUNIK", this.state.CONCAIRE[this.state.FICHE_CONCAIRE].C0CLEUNIK]), "TYPEINTERLO")
                              : "primary",
                          }}
                        >
                          {this._cree_chaine_situation(this.state.CONCAIRE[this.state.FICHE_CONCAIRE])}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </Dialog>
        ) : null}

        <DialogAccessRight state={this.state.droit_util_ajout_interlo} onChange={(value) => this.setState({ droit_util_ajout_interlo: value })} />
        <DialogAccessRight state={this.state.droit_util_modif_interlo} onChange={(value) => this.setState({ droit_util_modif_interlo: value })} />
      </Paper>
    );
  }
}

export default withCookies(withWidth()(CFCInterlocuteur));
