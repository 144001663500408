import db from "../Db";

export function GetAllIntervenDefunt() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN_DEFUNT.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetIntervenDefuntById(IDINTERVEN_DEFUNT) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN_DEFUNT.where("IDINTERVEN_DEFUNT")
      .equals(IDINTERVEN_DEFUNT)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetIntervenDefuntByIdInterven(I0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN_DEFUNT.where("I0CLEUNIK")
      .equals(I0CLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetIntervenDefuntByD0cleunik(D0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN_DEFUNT.where("D0CLEUNIK")
      .equals(D0CLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
