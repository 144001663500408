import db from "../Db";
import { handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteEtatTerrain from "../Delete/EtatTerrain_MDF";

var _ = require("lodash");

export async function UpdateEtatTerrain_MDF(headers) {
  const _etat_terrain = await GetApiData("ETAT_TERRAIN", headers);

  if (_etat_terrain.Message !== undefined) {
    return _etat_terrain;
  }

  return await DeleteEtatTerrain().then(() => {
    var drops = [];
    _.forEach(_etat_terrain, (etat_terrain) => {
      drops.push({
        IDETAT_TERRAIN: etat_terrain.IDETAT_TERRAIN,
        LIBELLE: etat_terrain.LIBELLE.trim(),
        BL_ARCHIVE: etat_terrain.BL_ARCHIVE === 1,
        ORDRE: etat_terrain.ORDRE,
        COULEUR: etat_terrain.COULEUR,
      });
    });

    return db
      .open()
      .then(() => {
        return db.ETAT_TERRAIN.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
