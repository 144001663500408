import db from "../Db";
import { GetAllAgendaTache } from "./AgendaTache";

var _ = require("lodash");

export function GetAllMouvement() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.MOUVEMENT.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetAllMouvementInTask() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetAllAgendaTache().then((tache) => {
      let D0CLEUNIK = [];

      _.forEach(tache, (t) => {
        if (t.D0CLEUNIK > 0) {
          D0CLEUNIK.push(t.D0CLEUNIK);
        }
      });

      db.MOUVEMENT.where("D0CLEUNIK")
        .anyOf(D0CLEUNIK)
        .toArray()
        .then((result) => {
          return resolve(result);
        });
    });
  });
}

export function GetMouvementById(IDMOUVEMENT) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.MOUVEMENT.where("IDMOUVEMENT")
      .equals(IDMOUVEMENT)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetMouvementByD0cleunik(D0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.MOUVEMENT.where("D0CLEUNIK")
      .equals(D0CLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
