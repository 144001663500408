import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  TablePagination,
  TextField,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableSortLabel,
  CircularProgress,
} from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { ExpandMore, FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";

import { IsOracle } from "./../../Helpers/Test/IsOracle";
import { GetClient } from "./../../Helpers/Get/Client";
import { GetAllConcess } from "./../../Helpers/Get/Concess";
import { GetAllDuree } from "./../../Helpers/Get/Duree";
import { GetAllCimetier } from "../../Helpers/Get/Cimetier";
import { GetAllLieu } from "./../../Helpers/Get/Lieu";
import { GetAllCendre } from "./../../Helpers/Get/Cendre";
import { differenceObject } from "./../../Helpers/Test/Difference";
import { Cree_iso_date, handleError } from "../../Helpers/Function";
import UpdateTemporaire from "./../../Helpers/Update/Temporaire";
import AddTemporaire from "./../../Helpers/Add/Temporaire";
import StyleToggleButton from "./../StyleToggleButton";
import { miseAJourConcessAssociation } from "./../Global/Concess";
import { CheckUserRight } from "./../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "../Global/DialogAccessRight";
import CurrentTab from "./../Global/Object/current_tab";

var _ = require("lodash");

const headCells = [
  { id: "FAMILLE", label: "Famille", sortable: true },
  { id: "MADATE", label: "Date d'achat", sortable: false },
  { id: "DUREE", label: "Durée", sortable: false },
  { id: "DATEEXPIRA", label: "Date d'expiration", sortable: false },
  { id: "TYPECIME", label: "Cimetière", sortable: false },
  { id: "EMPLACEMENT_LIEU", label: "Emplacement", sortable: true },
];

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id && headCell.sortable ? order : "asc"}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : null}
              hideSortIcon={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id && headCell.sortable ? <span style={styles.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>

      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage />
      </IconButton>
    </div>
  );
}

class CListeConcessions extends Component {
  static propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xs"]).isRequired,
  };

  constructor(props) {
    super(props);

    this.IDCLIENT = 0;
    this.ID_TABLETTE = "";
    this.CIMETIER = [];
    this.LIEU = [];
    this.CENDRE = [];
    this.ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      CIMETIER: [],
      CONCESS: [],
      CONCESS_AFFICHE: [],
      loader: true,
      LETTER: "",
      DUREE: [],
      EXPANDED: null,
      REDIRECT: null,
      DIALOG_AFFECTER_CONCESSION: false,
      SELECTED_CONCESSION: "",
      SELECTED_LIEU: "",
      currentPage: 0,
      rowsPerPage: 10,
      orderBy: "FAMILLE",
      order: "asc",
      droit_util_modif_concession: false,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    GetClient().then((client) => {
      this.IDCLIENT = client.IDCLIENT;
      this.ID_TABLETTE = client.ID_TABLETTE;
    });

    GetAllConcess().then((concess) => {
      let CONCESS = _.orderBy(concess, "FAMILLE", "asc");

      GetAllDuree().then((duree) => {
        let DUREE = duree;

        GetAllCimetier(1).then((cimetier) => {
          this.CIMETIER = cimetier;

          GetAllLieu().then((lieu) => {
            this.LIEU = lieu;

            GetAllCendre().then((cendre) => {
              this.CENDRE = cendre;

              if (this.props.id_lieu !== undefined) {
                const lieu = _.find(this.LIEU, ["LICLEUNIK", parseInt(this.props.id_lieu)]);

                CONCESS = _.filter(CONCESS, (c) => {
                  return c.TYPECONC === 2 && c.TYPECIME === lieu.CIMEL && c.NOMBRE === lieu.NOMBREL;
                });

                let hc = _.find(headCells, ["id", "EMPLACEMENT_LIEU"]);
                hc.label = "Emplacement supposé";
              } else {
                let hc = _.find(headCells, ["id", "EMPLACEMENT_LIEU"]);
                hc.label = "Emplacement";
              }

              this.setState({
                CONCESS: CONCESS,
                CONCESS_AFFICHE: CONCESS,
                DUREE: DUREE,
                SELECTED_LIEU: lieu,
                loader: false,
              });
            });
          });
        });
      });
    });
  };

  _cree_chaine_duree = (duree) => {
    let dur = _.find(this.state.DUREE, ["NUMDURE", duree]);

    if (dur !== undefined) {
      return dur.LIBDURE;
    }
  };

  _cree_chaine_cimetier = (typecime) => {
    let cim = _.find(this.CIMETIER, ["NUMCIME", typecime]);

    if (cim !== undefined) {
      return cim.NOMCIME;
    }
  };

  _handle_change_alphabet = (alphabet) => (event) => {
    event.preventDefault();

    if (this.state.LETTER === alphabet) {
      this.setState({
        LETTER: null,
        CONCESS_AFFICHE: this.state.CONCESS,
        currentPage: 0,
        EXPANDED: -1,
        loader: false,
      });
    } else {
      let concess = [...this.state.CONCESS];

      let caL = _.filter(concess, function (value, key) {
        return _.startsWith(value.FAMILLE, alphabet);
      });

      this.setState({
        LETTER: alphabet,
        CONCESS_AFFICHE: caL,
        currentPage: 0,
        EXPANDED: -1,
        loader: false,
      });
    }
  };

  _handle_change_tablepage = (event, newPage) => {
    this.setState({ currentPage: newPage, EXPANDED: -1 });
  };

  _handle_change_tablerowperpage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, currentPage: 0, EXPANDED: -1 });
  };

  _handle_click_concess_in_list = (concess) => (event) => {
    event.preventDefault();

    if (this.props.id_lieu !== undefined) {
      CheckUserRight(this.state.ID_UTIL, "CONCESS", "DROITMODIF").then((asRight) => {
        if (asRight) {
          this.setState({
            DIALOG_AFFECTER_CONCESSION: true,
            SELECTED_CONCESSION: concess,
            SELECTED_LIEU: _.find(this.LIEU, ["LICLEUNIK", parseInt(this.props.id_lieu)]),
          });
        } else {
          this.setState({ droit_util_modif_concession: true });
        }
      });
    } else {
      CurrentTab.setTab(0);

      this.setState({
        REDIRECT: `/consulter/fiche-concession/${concess.COCLEUNIK}`,
      });
    }
  };

  _UpdateConcessData = (CONCESS) => {
    if (CONCESS.DATEEXPIRA !== "") {
      CONCESS.DATEEXPIRA = Cree_iso_date(CONCESS.DATEEXPIRA);
    }

    if (CONCESS.MADATE !== "") {
      CONCESS.MADATE = Cree_iso_date(CONCESS.MADATE);
    }

    if (CONCESS.DETAIL === "") {
      CONCESS.DETAIL = 0;
    }

    if (CONCESS.DETAIL === "") {
      CONCESS.DETAIL = 0;
    }

    if (CONCESS.SUPERFICIE !== "") {
      CONCESS.SUPERFICIE = parseFloat(CONCESS.SUPERFICIE);
    }

    return CONCESS;
  };

  _MiseAJourTemporaire = (temporaire) => {
    UpdateTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _AjoutTemporaire = (temporaire) => {
    AddTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _handle_click_affecter_concession = (event) => {
    event.preventDefault();

    let CONCESS = { ...this.state.SELECTED_CONCESSION };
    _.set(CONCESS, "LICLEUNIK", this.state.SELECTED_LIEU.LICLEUNIK);
    _.set(CONCESS, "TYPECONC", 1);
    _.set(CONCESS, "EMPLACEMENT_LIEU", this._cree_chaine_emplacement());

    CONCESS = this._UpdateConcessData(CONCESS);

    let diff = differenceObject(CONCESS, this._UpdateConcessData(this.state.SELECTED_CONCESSION));
    diff = _.assign(
      {
        id: CONCESS.id,
        COCLEUNIK: CONCESS.COCLEUNIK,
      },
      diff
    );

    miseAJourConcessAssociation(CONCESS, { ...this.state.SELECTED_LIEU }, this.state.IS_ORACLE, diff, this, this.state.SELECTED_CONCESSION);
  };

  handleRequestSort = (_, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";

    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  _cree_chaine_emplacement = () => {
    let empl = _.filter([
      _.filter([this.state.SELECTED_LIEU.EMPLACEMENT.TYPECAR, this.state.SELECTED_LIEU.EMPLACEMENT.CARRE]).join(" "),
      _.filter([this.state.SELECTED_LIEU.EMPLACEMENT.TYPERG, this.state.SELECTED_LIEU.EMPLACEMENT.RANG]).join(" "),
      _.filter([this.state.SELECTED_LIEU.EMPLACEMENT.TYPEFOS, this.state.SELECTED_LIEU.EMPLACEMENT.TOMBE]).join(" "),
    ]).join(" - ");

    const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

    if (reg.test(empl)) {
      const TYPESEC = this.state.LIEU.EMPLACEMENT.TYPECAR.split("/")[0];
      const SECTION = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[0];
      const TYPECAR = this.state.LIEU.EMPLACEMENT.TYPECAR.split("/")[1];
      const CARRE = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[1];

      empl = _.filter([
        _.filter([TYPESEC, SECTION]).join(" "),
        _.filter([TYPECAR, CARRE]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
      ]).join(" - ");
    }

    return empl;
  };

  _emplacement_lieu = (lieu) => {
    let empl = lieu;

    const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

    if (reg.test(empl)) {
      const match = empl.match(reg);

      empl = `${match[1]} ${match[3]} - ${match[2]} ${match[4]}${match[5]}`;
    }

    return empl;
  };

  render() {
    console.log("RENDER LISTE CONCESSIONS");

    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 24 }}>
              {`Liste des concessions${this.props.id_lieu !== undefined ? " non localisées" : ""}`}
            </Typography>

            <Paper elevation={0} style={{ textAlign: "center" }}>
              {this.ALPHABET.map((alphabet, index) => (
                <ToggleButton
                  key={index}
                  style={{ margin: 6, width: 48 }}
                  value={this.state.LETTER}
                  selected={this.state.LETTER === alphabet ? true : false}
                  onChange={this._handle_change_alphabet(alphabet)}
                >
                  {alphabet}
                </ToggleButton>
              ))}

              <StyleToggleButton style={{ margin: 6 }} value="" selected={this.state.LETTER === ""} onChange={this._handle_change_alphabet("")}>
                Afficher tout
              </StyleToggleButton>
            </Paper>

            {this.state.loader ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

            {this.state.DUREE.length > 0 ? (
              width === "xs" || width === "sm" ? (
                <div style={{ marginTop: 24 }}>
                  <Paper elevation={0} style={{ display: "flex", padding: "0 16px" }}>
                    <TextField
                      select
                      label="Tri par :"
                      value={this.state.orderBy}
                      style={{ width: "25ch", marginRight: 16, marginBottom: 16 }}
                      onChange={(e) => {
                        this.setState({
                          orderBy: e.target.value,
                          EXPANDED: null,
                        });
                      }}
                    >
                      <MenuItem value="FAMILLE">Famille</MenuItem>
                      <MenuItem value="EMPLACEMENT_LIEU">{`Emplacement${this.props.id_lieu !== undefined ? " supposé" : ""}`}</MenuItem>
                    </TextField>

                    <TextField
                      select
                      label="Ordre :"
                      value={this.state.order}
                      style={{ width: "25ch" }}
                      onChange={(e) => {
                        this.setState({ order: e.target.value, EXPANDED: null });
                      }}
                    >
                      <MenuItem value="asc">Croissant</MenuItem>
                      <MenuItem value="desc">Décroissant</MenuItem>
                    </TextField>
                  </Paper>

                  <TableContainer component={Paper}>
                    {stableSort(this.state.CONCESS_AFFICHE, getComparator(this.state.order, this.state.orderBy))
                      .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map((row, index) => {
                        return (
                          <Accordion key={index} elevation={0} expanded={this.state.EXPANDED === index ? true : false} onChange={() => this.setState({ EXPANDED: index })}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <Typography variant="body2" gutterBottom>
                                {row.FAMILLE}
                              </Typography>
                            </AccordionSummary>

                            <AccordionDetails onClick={this._handle_click_concess_in_list(row)}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>Date d'achat</TableCell>
                                    <TableCell>{`${new Date(row.MADATE).toLocaleDateString("fr-FR")}`}</TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>Durée</TableCell>
                                    <TableCell>{this._cree_chaine_duree(row.DUREE)}</TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>Date d'expiration</TableCell>
                                    <TableCell>{`${new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") !== `01/01/1900` ? new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") : ``}`}</TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>Cimetière</TableCell>
                                    <TableCell>{this._cree_chaine_cimetier(row.TYPECIME)}</TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>{`Emplacement${this.props.id_lieu !== undefined ? " supposé" : ""}`}</TableCell>
                                    <TableCell>{this._emplacement_lieu(row.EMPLACEMENT_LIEU)}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </TableContainer>
                </div>
              ) : (
                <TableContainer component={Paper} style={{ marginTop: 24 }}>
                  <Table>
                    <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} onRequestSort={this.handleRequestSort} />

                    <TableBody>
                      {stableSort(this.state.CONCESS_AFFICHE, getComparator(this.state.order, this.state.orderBy))
                        .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow key={index} hover={true} style={{ cursor: "pointer" }} onClick={this._handle_click_concess_in_list(row)}>
                              <TableCell>{row.FAMILLE}</TableCell>
                              <TableCell>{`${new Date(row.MADATE).toLocaleDateString("fr-FR")}`}</TableCell>
                              <TableCell>{this._cree_chaine_duree(row.DUREE)}</TableCell>
                              <TableCell>{`${new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") !== `01/01/1900` ? new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") : ``}`}</TableCell>
                              <TableCell>{this._cree_chaine_cimetier(row.TYPECIME)}</TableCell>
                              <TableCell>{this._emplacement_lieu(row.EMPLACEMENT_LIEU)}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            ) : null}

            {this.state.DUREE.length > 0 ? (
              <Table>
                <TableBody>
                  <TableRow>
                    <TablePagination
                      style={{ display: "flex", flexDirection: "column" }}
                      colSpan={3}
                      count={this.state.CONCESS_AFFICHE.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.currentPage}
                      labelRowsPerPage="Nombre de lignes"
                      labelDisplayedRows={({ from, to, count }) =>
                        width !== "xs" && width !== "sm" ? (count > 0 ? `De ${from} à ${to === -1 ? count : to} sur ${count !== -1 ? count : to} resultats` : `Aucun résultat`) : null
                      }
                      onChangePage={this._handle_change_tablepage}
                      onChangeRowsPerPage={this._handle_change_tablerowperpage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            ) : null}

            {this.LIEU !== undefined && this.LIEU !== "" && this.state.SELECTED_CONCESSION !== "" ? (
              <Dialog open={this.state.DIALOG_AFFECTER_CONCESSION} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">{`Confirmez-vous l'association de la concession ${
                    this.state.SELECTED_CONCESSION.FAMILLE
                  } à l'emplacement ${this._cree_chaine_emplacement()} ?`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={() => this.setState({ DIALOG_AFFECTER_CONCESSION: false })}>
                    Non
                  </Button>
                  <Button color="primary" onClick={this._handle_click_affecter_concession}>
                    Oui
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}

            <DialogAccessRight state={this.state.droit_util_modif_concession} onChange={(value) => this.setState({ droit_util_modif_concession: value })} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(withWidth()(CListeConcessions));
