import db from "../Db";
import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";
import DeleteGnUtil from "../Delete/GNUtil_MDF";

var _ = require("lodash");

export async function UpdateGNUtil_MDF(headers) {
  const _util = await GetApiData("GN_UTIL", headers);

  if (_util.Message !== undefined) {
    return _util;
  }

  return await DeleteGnUtil().then(() => {
    var drops = [];
    _.forEach(_util, (util) => {
      drops.push({
        IDUTIL: util.IDUTIL,
        NOM: util.NOM,
        PASS: util.PASS,
      });
    });

    return db
      .open()
      .then(() => {
        return db.GNUTIL.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
