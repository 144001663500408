import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  withWidth,
} from "@material-ui/core";
import { ExpandMore, FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { GetAllExpiredConcess } from "./../../../Helpers/Get/Concess";
import { IsOracle } from "./../../../Helpers/Test/IsOracle";
import { GetAllDuree } from "./../../../Helpers/Get/Duree";
import { GetAllCimetier } from "./../../../Helpers/Get/Cimetier";
import { GetAllConcessEtatEcheance } from "./../../../Helpers/Get/ConcessEtatEcheance";
import { GetAllConcaire } from "./../../../Helpers/Get/Concaire";

var _ = require("lodash");

const headCells = [
  { id: "FAMILLE", label: "Famille" },
  { id: "MADATE", label: "Date d'achat", sortable: false },
  { id: "DUREE", label: "Durée", sortable: false },
  { id: "DATEEXPIRA", label: "Date d'expiration", sortable: false },
  { id: "TYPECIME", label: "Cimetière", sortable: false },
  { id: "EMPLACEMENT_LIEU", label: "Emplacement" },
];

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span style={styles.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage />
      </IconButton>
    </div>
  );
}

class CPSepultureExpirees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      oracle: false,
      concess: [],
      concessAffiche: [],
      circularLoader: true,
      duree: [],
      cimetier: [],
      selectedCimetier: 0,
      etatecheance: [],
      order: "asc",
      orderBy: "FAMILLE",
      currentPage: 0,
      rowsPerPage: 10,
      recherche: "",
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ oracle: oracle === 1 ? true : false }, () => {
        if (this.state.oracle) {
          if (_.find(headCells, ["id", "IDCONCESS_ETAT_ECHEANCE"]) === undefined) headCells.push({ id: "IDCONCESS_ETAT_ECHEANCE", label: "Etat d'échéance" });
        } else {
          if (_.find(headCells, ["id", "A_REPRENDRE"]) === undefined) headCells.push({ id: "A_REPRENDRE", label: "A reprendre" });
          if (_.find(headCells, ["id", "POSE_PLAQUE"]) === undefined) headCells.push({ id: "POSE_PLAQUE", label: "Pose plaquette" });
        }

        GetAllExpiredConcess(this.state.oracle).then((concess) => {
          GetAllConcaire().then((concaire) => {
            concaire = _.filter(concaire, (c) => {
              return c.TYPEINTERLO.CONCESSIONNAIRE === 1;
            });

            const concaireCocle = _.map(concaire, "COCLEUNIK");

            concess = _.filter(concess, (c) => {
              return _.includes(concaireCocle, c.COCLEUNIK);
            });

            GetAllDuree().then((duree) => {
              duree = _.filter(duree, (d) => {
                return d.VALDURE !== 0;
              });

              const dureeNumdure = _.map(duree, "NUMDURE");

              concess = _.filter(concess, (c) => {
                return _.includes(dureeNumdure, c.DUREE);
              });

              GetAllCimetier(1).then((cimetier) => {
                GetAllConcessEtatEcheance().then((etatecheance) => {
                  this.setState({ concess: concess, concessAffiche: concess, circularLoader: false, duree: duree, cimetier: cimetier, etatecheance: etatecheance });
                });
              });
            });
          });
        });
      });
    });
  };

  handleRequestSort = (_, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";

    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  _handle_click_concess_in_list = (concess) => (event) => {
    event.preventDefault();

    this.setState({
      redirect: `/consulter/fiche-concession/${concess.COCLEUNIK}/e`,
    });
  };

  _cree_chaine_duree = (duree) => {
    let dur = _.find(this.state.duree, ["NUMDURE", duree]);

    if (dur !== undefined) {
      return dur.LIBDURE;
    }
  };

  _cree_chaine_cimetier = (typecime) => {
    let cim = _.find(this.state.cimetier, ["NUMCIME", typecime]);

    if (cim !== undefined) {
      return cim.NOMCIME;
    }
  };

  _emplacement_lieu = (lieu) => {
    let empl = lieu;

    const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

    if (reg.test(empl)) {
      const match = empl.match(reg);

      empl = `${match[1]} ${match[3]} - ${match[2]} ${match[4]}${match[5]}`;
    }

    return empl;
  };

  _cree_chaine_etat_echeance = (id_etatecheance) => {
    let etatech = _.find(this.state.etatecheance, ["IDCONCESS_ETAT_ECHEANCE", id_etatecheance]);

    let chaineretour = "";
    if (etatech !== undefined) chaineretour = etatech.LIBELLE;

    return chaineretour;
  };

  _handle_change_tablepage = (event, newPage) => {
    this.setState({ currentPage: newPage });
  };

  _handle_change_tablerowperpage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, currentPage: 0 });
  };

  _handle_change_recherche = (event) => {
    event.preventDefault();

    let concess = [...this.state.concess];

    let sc = this.state.selectedCimetier;

    let caCime = _.filter(concess, function (value, key) {
      if (sc !== 0) return value.TYPECIME === sc;

      return value;
    });

    let caRech = _.filter(caCime, function (value, key) {
      if (event.target.value !== "")
        return (
          _.startsWith(
            value.FAMILLE.normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
            event.target.value.toLowerCase()
          ) || _.startsWith(value.FAMILLE.toLowerCase(), event.target.value.toLowerCase())
        );

      return value;
    });

    this.setState({ recherche: event.target.value, concessAffiche: caRech, circularLoader: false, rowsPerPage: 10, currentPage: 0 });
  };

  _handle_change_combo_cimetier = (event) => {
    let cimetier = event.target.value;

    let concess = [...this.state.concess];

    let caCime = _.filter(concess, function (value, key) {
      if (cimetier !== 0) return value.TYPECIME === cimetier;

      return value;
    });

    let rech = this.state.recherche;

    let caRech = _.filter(caCime, function (value, key) {
      if (rech !== "")
        return (
          _.startsWith(
            value.FAMILLE.normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
            rech.toLowerCase()
          ) || _.startsWith(value.FAMILLE.toLowerCase(), rech.toLowerCase())
        );

      return value;
    });

    this.setState({ selectedCimetier: cimetier, concessAffiche: caRech, circularLoader: false, rowsPerPage: 10, currentPage: 0 });
  };

  render() {
    console.log("RENDER LISTE CONCESSION EXPIRE");

    const { width } = this.props;

    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 24 }}>
              Concessions expirées, pouvant faire l'objet d'une future reprise par la ville
            </Typography>

            {width === "xs" || width === "sm" || width === "md" ? (
              <div style={{ marginTop: 24 }}>
                <Paper elevation={0} style={{ padding: "0 16px", margin: "16px 0 0 0" }}>
                  <TextField style={{ width: "25ch" }} label="Nom de famille" value={this.state.recherche} onChange={this._handle_change_recherche} />

                  <TextField select label="Cimetière" value={this.state.selectedCimetier} style={{ width: "25ch", marginLeft: 16 }} onChange={this._handle_change_combo_cimetier}>
                    <MenuItem value={0}>Tous les cimetières</MenuItem>
                    {this.state.cimetier.map((cimetier, index) => (
                      <MenuItem key={index} value={cimetier.NUMCIME}>
                        {cimetier.NOMCIME}
                      </MenuItem>
                    ))}
                  </TextField>
                </Paper>

                <Paper elevation={0} style={{ padding: "0 16px", margin: "0 0 16px 0" }}>
                  <TextField
                    select
                    label="Tri par :"
                    value={this.state.orderBy}
                    style={{ margin: "16px 16px 0 0", width: "25ch" }}
                    onChange={(e) => {
                      this.setState({
                        orderBy: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="FAMILLE">Famille</MenuItem>
                    <MenuItem value="EMPLACEMENT_LIEU">Emplacement</MenuItem>
                    {!this.state.oracle ? <MenuItem value="A_REPRENDRE">A reprendre</MenuItem> : null}
                    {!this.state.oracle ? <MenuItem value="POSE_PLAQUE">Pose plaquette</MenuItem> : null}
                    {this.state.oracle ? <MenuItem value="IDCONCESS_ETAT_ECHEANCE">Etat d'échéance</MenuItem> : null}
                  </TextField>

                  <TextField
                    select
                    label="Ordre :"
                    value={this.state.order}
                    style={{ margin: "16px 16px 0 0", width: "25ch" }}
                    onChange={(e) => {
                      this.setState({ order: e.target.value });
                    }}
                  >
                    <MenuItem value="asc">Croissant</MenuItem>
                    <MenuItem value="desc">Décroissant</MenuItem>
                  </TextField>
                </Paper>

                {this.state.circularLoader ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

                <TableContainer component={Paper}>
                  {stableSort(this.state.concessAffiche, getComparator(this.state.order, this.state.orderBy))
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      return (
                        <Accordion key={index} elevation={0}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="body2" gutterBottom style={{ flex: 1 }}>
                              <DeviceOrientation lockOrientation={"landscape"}>
                                {/* Will only be in DOM in landscape */}
                                <Orientation orientation="landscape" alwaysRender={false}>
                                  {`${row.FAMILLE} - ${this._cree_chaine_cimetier(row.TYPECIME)} - ${this._emplacement_lieu(row.EMPLACEMENT_LIEU)}`}
                                </Orientation>
                                {/* Will stay in DOM, but is only visible in portrait */}
                                <Orientation orientation="portrait" alwaysRender={false}>
                                  {row.FAMILLE}
                                </Orientation>
                              </DeviceOrientation>
                            </Typography>

                            <Typography variant="body2" gutterBottom className="HoverLink" onClick={this._handle_click_concess_in_list(row)}>
                              Voir la fiche
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails onClick={this._handle_click_concess_in_list(row)}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Date d'achat</TableCell>
                                  <TableCell>{`${new Date(row.MADATE).toLocaleDateString("fr-FR")}`}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Durée</TableCell>
                                  <TableCell>{this._cree_chaine_duree(row.DUREE)}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Date d'expiration</TableCell>
                                  <TableCell>{`${new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") !== `01/01/1900` ? new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") : ``}`}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Cimetière</TableCell>
                                  <TableCell>{this._cree_chaine_cimetier(row.TYPECIME)}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Emplacement</TableCell>
                                  <TableCell>{this._emplacement_lieu(row.EMPLACEMENT_LIEU)}</TableCell>
                                </TableRow>

                                {!this.state.oracle ? (
                                  <TableRow>
                                    <TableCell>A reprendre</TableCell>
                                    <TableCell>{row.A_REPRENDRE === 1 ? `Oui` : `Non`}</TableCell>
                                  </TableRow>
                                ) : null}

                                {!this.state.oracle ? (
                                  <TableRow>
                                    <TableCell>Pose plaquette</TableCell>
                                    <TableCell>{row.POSE_PLAQUE === 1 ? `Oui` : `Non`}</TableCell>
                                  </TableRow>
                                ) : null}

                                {this.state.oracle ? (
                                  <TableRow>
                                    <TableCell>Etat d'échéance</TableCell>
                                    <TableCell>{this._cree_chaine_etat_echeance(row.IDCONCESS_ETAT_ECHEANCE)}</TableCell>
                                  </TableRow>
                                ) : null}
                              </TableBody>
                            </Table>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </TableContainer>
              </div>
            ) : (
              <div>
                <Paper elevation={0} style={{ padding: "0 16px", margin: "16px 0 0 0" }}>
                  <TextField style={{ width: "25ch" }} label="Nom de famille" value={this.state.recherche} onChange={this._handle_change_recherche} />

                  <TextField select label="Cimetière" value={this.state.selectedCimetier} style={{ width: "25ch", marginLeft: 16 }} onChange={this._handle_change_combo_cimetier}>
                    <MenuItem value={0}>Tous les cimetières</MenuItem>
                    {this.state.cimetier.map((cimetier, index) => (
                      <MenuItem key={index} value={cimetier.NUMCIME}>
                        {cimetier.NOMCIME}
                      </MenuItem>
                    ))}
                  </TextField>
                </Paper>

                <TableContainer component={Paper} style={{ marginTop: 24 }}>
                  <Table>
                    <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} onRequestSort={this.handleRequestSort} />

                    {this.state.concessAffiche.length === 0 ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

                    <TableBody>
                      {stableSort(this.state.concessAffiche, getComparator(this.state.order, this.state.orderBy))
                        .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow key={index} hover={true} style={{ cursor: "pointer" }} onClick={this._handle_click_concess_in_list(row)}>
                              <TableCell>{row.FAMILLE}</TableCell>
                              <TableCell>{`${new Date(row.MADATE).toLocaleDateString("fr-FR")}`}</TableCell>
                              <TableCell>{this._cree_chaine_duree(row.DUREE)}</TableCell>
                              <TableCell>{`${new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") !== `01/01/1900` ? new Date(row.DATEEXPIRA).toLocaleDateString("fr-FR") : ``}`}</TableCell>
                              <TableCell>{this._cree_chaine_cimetier(row.TYPECIME)}</TableCell>
                              <TableCell>{this._emplacement_lieu(row.EMPLACEMENT_LIEU)}</TableCell>
                              {!this.state.oracle ? <TableCell>{row.A_REPRENDRE === 1 ? `Oui` : `Non`}</TableCell> : null}
                              {!this.state.oracle ? <TableCell>{row.POSE_PLAQUE === 1 ? `Oui` : `Non`}</TableCell> : null}
                              {this.state.oracle ? <TableCell>{this._cree_chaine_etat_echeance(row.IDCONCESS_ETAT_ECHEANCE)}</TableCell> : null}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}

            {this.state.concessAffiche.length > 0 ? (
              <Table>
                <TableBody>
                  <TableRow>
                    <TablePagination
                      style={{ display: "flex", flexDirection: "column" }}
                      colSpan={3}
                      count={this.state.concessAffiche.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.currentPage}
                      labelRowsPerPage="Nombre de lignes"
                      labelDisplayedRows={({ from, to, count }) =>
                        width !== "xs" && width !== "sm" ? (count > 0 ? `De ${from} à ${to === -1 ? count : to} sur ${count !== -1 ? count : to} resultats` : `Aucun résultat`) : null
                      }
                      onChangePage={this._handle_change_tablepage}
                      onChangeRowsPerPage={this._handle_change_tablerowperpage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withWidth()(CPSepultureExpirees);
