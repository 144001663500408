import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

var _ = require("lodash");

export async function CreateThanatomorphose(headers) {
  const _thanato = await GetApiData("THANATOMORPHOSE", headers);

  if (_thanato.Message !== undefined) {
    return _thanato;
  }

  var drops = [];
  _.forEach(_thanato, (thanato) => {
    drops.push({
      IDTHANATOMORPHOSE: thanato.IDTHANATOMORPHOSE,
      LIBELLE: thanato.LIBELLE.trim(),
      ARCHIVER: thanato.ARCHIVER,
      ORDRE: thanato.ORDRE,
    });
  });

  return await db
    .open()
    .then(() => {
      return db.THANATOMORPHOSE.bulkAdd(drops)
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
