import React, { Component } from "react";
import { Grid, Paper, FormLabel, List, ListItem, Typography } from "@material-ui/core";
import { ListItemText, ListItemIcon, Divider } from "@material-ui/core";
import { Checkbox, Button } from "@material-ui/core";
import StyleLinearProgress from "./../StyleLinearProgress";

var _ = require("lodash");

const styles = {
  grid_container: {
    flex: 1,
  },
  paper: {
    padding: 16,
  },
  submit_div: {
    display: "flex",
    marginTop: 16,
    justifyContent: "space-between",
  },
};

class ParamCimetiere extends Component {
  render() {
    const {
      submit_param_cimetiere,
      click_param_cimetiere_selection,
      click_param_cimetiere_retour,
      liste_cimetiere,
      cimetiere,
      loading,
      data_loaded,
      table_loading,
      nb_total_photos_loading,
      nb_photos_enregistre,
      disabledForm,
    } = this.props;
    let arrcim = cimetiere !== "" ? cimetiere.split(",").map(Number) : [];

    return (
      <Grid container justify="center" alignItems="center" style={styles.grid_container}>
        <Grid item xs={11} sm={11} md={8}>
          <Paper style={styles.paper}>
            <form onSubmit={submit_param_cimetiere}>
              <FormLabel>
                Sélectionner une option d'affichage des cimetières.
                <br />
                <i style={{ fontSize: "0.75rem" }}>Le choix de n'afficher qu'un seul cimetière sur la tablette permettra d'optimiser l'espace de stockage dans le cache</i>
              </FormLabel>

              <List component="nav">
                <ListItem button onClick={click_param_cimetiere_selection(0)}>
                  <ListItemText primary="Tous les cimetières" />
                  <ListItemIcon>
                    <Checkbox edge="end" checked={_.includes(arrcim, 0)} />
                  </ListItemIcon>
                </ListItem>

                <Divider />

                {liste_cimetiere.map((cime, index) => (
                  <div key={index}>
                    <ListItem button onClick={click_param_cimetiere_selection(cime.NUMCIME)}>
                      <ListItemText primary={cime.NOMCIME} />
                      <ListItemIcon>
                        <Checkbox edge="end" checked={_.includes(arrcim, cime.NUMCIME)} />
                      </ListItemIcon>
                    </ListItem>

                    <Divider />
                  </div>
                ))}
              </List>

              <div style={styles.submit_div}>
                <Button variant="contained" color="primary" disabled={disabledForm} onClick={click_param_cimetiere_retour}>
                  Retour
                </Button>

                <Button variant="contained" color="primary" type="submit" disabled={disabledForm}>
                  Valider la configuration
                </Button>
              </div>
            </form>
          </Paper>

          {loading === true ? (
            <div>
              <StyleLinearProgress variant="determinate" color="secondary" value={data_loaded} />
              <Typography variant="body2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{table_loading}</span>
                {nb_total_photos_loading > 0 ? <span>{`Nombre de photos enregistrées : ${nb_photos_enregistre} / ${nb_total_photos_loading}`}</span> : null}
                <span>{data_loaded | 0}%</span>
              </Typography>
            </div>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

export default ParamCimetiere;
