import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Grid, Paper, TextField, MenuItem, FormGroup, FormControlLabel, Switch, FormControl, FormLabel, Checkbox, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { withCookies } from "react-cookie";

import { IsOracle } from "./../../Helpers/Test/IsOracle";

import { GetClient } from "./../../Helpers/Get/Client";
import { GetDefuntById } from "../../Helpers/Get/Defunt";
import { GetConcessById } from "../../Helpers/Get/Concess";
import { GetAllCimetier } from "./../../Helpers/Get/Cimetier";
import { GetCendreById, GetCendreByNumcime, GetCendreByIdLieu } from "./../../Helpers/Get/Cendre";
import { GetLieuById, GetLieuByCimel } from "../../Helpers/Get/Lieu";
import { GetTemporaireNonEnvoye } from "../../Helpers/Get/Temporaire";

import AddDefunt from "./../../Helpers/Add/Defunt";
import AddDefuntS from "../../Helpers/Add/DefuntS";
import AddTemporaire from "./../../Helpers/Add/Temporaire";
import UpdateDefunt from "../../Helpers/Update/Defunt";
import UpdateDefuntS from "../../Helpers/Update/DefuntS";
import UpdateTemporaire from "../../Helpers/Update/Temporaire";

import { GetSquare, GetRank, GetTomb, GetLieuFromEmpl, handleError, CreeTemporaire, Cree_iso_date } from "./../../Helpers/Function";
import { differenceObject } from "./../../Helpers/Test/Difference";
import { GetParam } from "./../../Helpers/Get/Param";
import { GetAllMateriau } from "./../../Helpers/Get/Materiau";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  formcontrollabel: {
    justifyContent: "center",
  },
  menuitem: {
    minHeight: 36,
  },
  formcontrol: {
    marginTop: 16,
  },
  radiogroup: {
    flexDirection: "initial",
    justifyContent: "space-between",
  },
  submit_div: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },
};

class MDefunt extends Component {
  constructor(props) {
    super(props);

    this.IDCLIENT = 0;
    this.LIEU_VIRTUEL = false;
    this.ID_TABLETTE = "";
    this.ID_UTIL = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

    this.nom_usage_ref = React.createRef();
    this.nom_naissance_ref = React.createRef();

    this.state = {
      IS_ORACLE: false,
      DEFUNT_SAUVEGARDE: {
        id: 0,
        D0CLEUNIK: 0,
        ETAT_CIVIL: {
          NOMDEF: "",
          SEXE: "",
          NOMJEUNE: "",
          PRENOMDEF: "",
          DATENAISS: "",
          DATEDECES: "",
          DATEINHUM: "",
          HEUREMOUV: "",
          ANNEEDECES: "",
          AGE: "",
        },
        ENVELOPPE: {
          URNE: 0,
          RELIQUAIRE: 0,
          ENFANT: 0,
          DISPERSION: 0,
          URNE_SCELLE: 0,
        },
        BIERRE: {
          MEBI_MC: 0,
          SOINS_CONSERVATIONS: 0,
          HERMETIQUE: 0,
          ZINC_HN: 0,
        },
        ENFANTDC: {
          AUCUN: 0,
          ENFANT: 0,
          NOUVEAU_NE: 0,
          MORT_NE: 0,
          FOETUS: 0,
        },
        LICLEUNIK: 0,
        COCLEUNIK: 0,
        TYPECIME: "",
        NUMCENDRE: "",
      },
      DEFUNT: {
        id: 0,
        D0CLEUNIK: 0,
        ETAT_CIVIL: {
          NOMDEF: "",
          SEXE: "",
          NOMJEUNE: "",
          PRENOMDEF: "",
          DATENAISS: "",
          DATEDECES: "",
          DATEINHUM: "",
          HEUREMOUV: "",
          ANNEEDECES: "",
          AGE: "",
        },
        ENVELOPPE: {
          URNE: 0,
          RELIQUAIRE: 0,
          ENFANT: 0,
          DISPERSION: 0,
          URNE_SCELLE: 0,
        },
        BIERRE: {
          MEBI_MC: 0,
          SOINS_CONSERVATIONS: 0,
          HERMETIQUE: 0,
          ZINC_HN: 0,
        },
        ENFANTDC: {
          AUCUN: 0,
          ENFANT: 0,
          NOUVEAU_NE: 0,
          MORT_NE: 0,
          FOETUS: 0,
        },
        LICLEUNIK: 0,
        COCLEUNIK: 0,
        TYPECIME: "",
        NUMCENDRE: "",
      },
      CONCESS: "",
      CIMETIER: [],
      IS_CENDRE: false,
      CENDRE: [],
      MATERIAU: [],
      SQUARE_LIBELLE: "Carré",
      SELECTED_SQUARE: "",
      SQUARE: [],
      RANK_LIBELLE: "Rang",
      SELECTED_RANK: "",
      RANK: [],
      TOMB_LIBELLE: "Tombe",
      SELECTED_TOMB: "",
      TOMB: [],
      SELECTED_ENVELOPPE: "",
      REDIRECT: null,
      ERROR: {
        DATENAISS: null,
        DATEDECES: null,
        DATEINHUM: null,
        ANNEEDECES: null,
      },
      TemporaireNonEnvoye: null,
      estTemporaireNonEnvoye: false,
      anneeDeces: true,
      dateDeces: true,
      disabledForm: false,
      param: "",
    };

    if (props.id_defunt !== undefined && props.id_defunt !== "c") {
      GetDefuntById(parseInt(props.id_defunt)).then((defunt) => {
        GetConcessById(defunt.COCLEUNIK).then((concess) => {
          if (defunt.ETAT_CIVIL.DATENAISS !== "") {
            defunt.ETAT_CIVIL.DATENAISS = this._cree_chaine_date(defunt.ETAT_CIVIL.DATENAISS);
          }

          if (defunt.ETAT_CIVIL.DATEDECES !== "") {
            defunt.ETAT_CIVIL.DATEDECES = this._cree_chaine_date(defunt.ETAT_CIVIL.DATEDECES);

            this.setState({ anneeDeces: false });
          }

          if (defunt.ETAT_CIVIL.DATEINHUM !== "") {
            defunt.ETAT_CIVIL.HEUREMOUV = defunt.ETAT_CIVIL.DATEINHUM;
            defunt.ETAT_CIVIL.DATEINHUM = this._cree_chaine_date(defunt.ETAT_CIVIL.DATEINHUM);
          } else {
            defunt.ETAT_CIVIL.HEUREMOUV = "";
          }

          props.changeTitle(defunt.ETAT_CIVIL.NOMDEF + " " + defunt.ETAT_CIVIL.PRENOMDEF);

          this.setState({
            DEFUNT_SAUVEGARDE: defunt,
            CONCESS: concess,
          });
        });
      });
    } else if (props.id_defunt === "c" && props.id_concess !== undefined) {
      GetConcessById(props.id_concess).then((concess) => {
        let DEFUNT = this.state.DEFUNT;
        DEFUNT.TYPECIME = concess.TYPECIME;
        DEFUNT.COCLEUNIK = concess.COCLEUNIK;

        props.changeTitle("Défunt");

        if (DEFUNT.ETAT_CIVIL.DATEINHUM !== "") {
          DEFUNT.ETAT_CIVIL.HEUREMOUV = DEFUNT.ETAT_CIVIL.DATEINHUM;
          DEFUNT.ETAT_CIVIL.DATEINHUM = this._cree_chaine_date(DEFUNT.ETAT_CIVIL.DATEINHUM);
        } else {
          DEFUNT.ETAT_CIVIL.HEUREMOUV = "";
        }

        this.setState({
          DEFUNT_SAUVEGARDE: DEFUNT,
          CONCESS: concess,
        });
      });
    } else {
      props.changeTitle("Défunt");
    }
  }

  componentDidMount = () => {
    GetClient().then((client) => {
      this.IDCLIENT = client.IDCLIENT;
      this.ID_TABLETTE = client.ID_TABLETTE;
    });

    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    GetParam().then((param) => {
      this.setState({ param: param });
    });

    GetAllCimetier(1).then((cimetier) => {
      this.setState({ CIMETIER: cimetier });
    });

    GetAllMateriau().then((materiau) => {
      materiau = _.orderBy(materiau, "ORDRE", "asc");

      this.setState({ MATERIAU: materiau });
    });

    if (this.props.id_defunt !== undefined && this.props.id_defunt !== "c") {
      GetDefuntById(parseInt(this.props.id_defunt)).then((defunt) => {
        GetConcessById(defunt.COCLEUNIK).then((concess) => {
          if (defunt.ETAT_CIVIL.DATENAISS !== "") {
            defunt.ETAT_CIVIL.DATENAISS = this._cree_chaine_date(defunt.ETAT_CIVIL.DATENAISS);
          }

          if (defunt.ETAT_CIVIL.DATEDECES !== "") {
            defunt.ETAT_CIVIL.DATEDECES = this._cree_chaine_date(defunt.ETAT_CIVIL.DATEDECES);

            this.setState({ anneeDeces: false });
          }

          if (defunt.ETAT_CIVIL.DATEINHUM !== "") {
            defunt.ETAT_CIVIL.HEUREMOUV = defunt.ETAT_CIVIL.DATEINHUM;
            defunt.ETAT_CIVIL.DATEINHUM = this._cree_chaine_date(defunt.ETAT_CIVIL.DATEINHUM);
          } else {
            defunt.ETAT_CIVIL.HEUREMOUV = "";
          }

          GetTemporaireNonEnvoye("DEFUNT", "D0CLEUNIK", defunt.D0CLEUNIK).then((temp) => {
            if (temp !== undefined)
              this.setState({
                TemporaireNonEnvoye: temp.TYPE_D_ACTION,
                estTemporaireNonEnvoye: true,
              });
          });

          this.props.changeTitle(defunt.ETAT_CIVIL.NOMDEF + " " + defunt.ETAT_CIVIL.PRENOMDEF);

          if (defunt.NUMCENDRE !== "" && defunt.NUMCENDRE !== 0) {
            GetCendreByNumcime(defunt.TYPECIME).then((allcendre) => {
              this.setState({
                IS_CENDRE: true,
                CENDRE: allcendre,
              });
            });
          } else {
            GetLieuByCimel(defunt.TYPECIME, false).then((alllieu) => {
              GetLieuById(defunt.LICLEUNIK).then((lieu) => {
                this.LIEU_VIRTUEL = lieu.LIEU_MULTIPLE_PRINC_VIRTUELLE;

                let square = GetSquare(alllieu);
                let rank = GetRank(alllieu, lieu.EMPLACEMENT.CARRE);
                let tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE, lieu.EMPLACEMENT.RANG);

                if (this.LIEU_VIRTUEL) {
                  tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE, lieu.EMPLACEMENT.RANG, lieu.COCLEUNIK);
                }

                const defAssign = {
                  ...defunt.ENVELOPPE,
                  ...defunt.BIERRE,
                };

                let cerceuil = _.every(_.values(defAssign), (value) => value === 0);

                const ageDefunt = this._calcule_age_defunt(defunt);
                if (ageDefunt !== undefined) _.set(defunt.ETAT_CIVIL, "AGE", this._calcule_age_defunt(defunt));

                this.setState({
                  DEFUNT: defunt,
                  SQUARE_LIBELLE: lieu.EMPLACEMENT.TYPECAR,
                  SELECTED_SQUARE: lieu.EMPLACEMENT.CARRE,
                  SQUARE: square,
                  RANK_LIBELLE: lieu.EMPLACEMENT.TYPERG,
                  SELECTED_RANK: lieu.EMPLACEMENT.RANG,
                  RANK: rank,
                  TOMB_LIBELLE: lieu.EMPLACEMENT.TYPEFOS,
                  SELECTED_TOMB: lieu.EMPLACEMENT.TOMBE,
                  TOMB: tomb,
                  SELECTED_ENVELOPPE: cerceuil ? "CERCUEIL" : _.findKey(defAssign, (item) => item === 1),
                });
              });
            });
          }

          const ageDefunt = this._calcule_age_defunt(defunt);
          if (ageDefunt !== undefined) _.set(defunt.ETAT_CIVIL, "AGE", this._calcule_age_defunt(defunt));

          this.setState({
            DEFUNT: defunt,
            CONCESS: concess,
          });
        });
      });
    } else if (this.props.id_defunt === "c" && this.props.id_concess !== undefined) {
      GetConcessById(this.props.id_concess).then((concess) => {
        let DEFUNT = this.state.DEFUNT;
        DEFUNT.TYPECIME = concess.TYPECIME;
        DEFUNT.COCLEUNIK = concess.COCLEUNIK;

        this.props.changeTitle("Défunt");

        GetLieuByCimel(concess.TYPECIME, false).then((alllieu) => {
          GetLieuById(concess.LICLEUNIK).then((lieu) => {
            this.LIEU_VIRTUEL = lieu.LIEU_MULTIPLE_PRINC_VIRTUELLE;

            let square = GetSquare(alllieu);
            let rank = GetRank(alllieu, lieu.EMPLACEMENT.CARRE);
            let tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE, lieu.EMPLACEMENT.RANG);

            if (this.LIEU_VIRTUEL) {
              tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE, lieu.EMPLACEMENT.RANG, lieu.COCLEUNIK);
            }

            this.setState({
              SQUARE_LIBELLE: lieu.EMPLACEMENT.TYPECAR,
              SELECTED_SQUARE: lieu.EMPLACEMENT.CARRE,
              SQUARE: square,
              RANK_LIBELLE: lieu.EMPLACEMENT.TYPERG,
              SELECTED_RANK: lieu.EMPLACEMENT.RANG,
              RANK: rank,
              TOMB_LIBELLE: lieu.EMPLACEMENT.TYPEFOS,
              SELECTED_TOMB: lieu.EMPLACEMENT.TOMBE,
              TOMB: tomb,
            });
          });
        });

        const ageDefunt = this._calcule_age_defunt(DEFUNT);
        if (ageDefunt !== undefined) _.set(DEFUNT.ETAT_CIVIL, "AGE", this._calcule_age_defunt(DEFUNT));

        this.setState({
          DEFUNT: DEFUNT,
          CONCESS: concess,
        });
      });
    } else {
      this.props.changeTitle("Défunt");
    }
  };

  _calcule_age_defunt = (defunt) => {
    if (
      defunt.ETAT_CIVIL.DATENAISS !== "" &&
      (defunt.ETAT_CIVIL.DATEDECES !== "" || (defunt.ETAT_CIVIL.ANNEEDECES !== "" && defunt.ETAT_CIVIL.ANNEEDECES !== 0 && defunt.ETAT_CIVIL.ANNEEDECES.toString().length === 4))
    ) {
      var bds;
      var dds;

      if (defunt.ETAT_CIVIL.DATENAISS !== "") {
        if (defunt.ETAT_CIVIL.DATENAISS.includes("/")) {
          bds = defunt.ETAT_CIVIL.DATENAISS.split("/");
        } else {
          let c_date = defunt.ETAT_CIVIL.DATENAISS.split("T")[0].split("-");
          bds = _.reverse(c_date);
        }
      }

      if (defunt.ETAT_CIVIL.DATEDECES !== "") {
        if (defunt.ETAT_CIVIL.DATEDECES.includes("/")) {
          dds = defunt.ETAT_CIVIL.DATEDECES.split("/");
        } else {
          let c_date = defunt.ETAT_CIVIL.DATEDECES.split("T")[0].split("-");
          dds = _.reverse(c_date);
        }
      } else {
        dds = `31/12/${defunt.ETAT_CIVIL.ANNEEDECES}`.split("/");
      }

      var birthDate;
      if (bds.length === 1) {
        birthDate = new Date(bds[0], 0, 1);
      } else {
        birthDate = new Date(bds[2], bds[1] - 1, bds[0]);
      }

      var deathDate = new Date(dds[2], dds[1] - 1, dds[0]);

      var years = deathDate.getFullYear() - birthDate.getFullYear();

      if ((deathDate.getMonth() < birthDate.getMonth() || deathDate.getMonth() === birthDate.getMonth()) && deathDate.getDate() < birthDate.getDate()) {
        years--;
      }

      return Math.abs(years);
    }
  };

  _cree_chaine_date = (date) => {
    if (date !== null && date !== "") {
      if (date.length < 5) {
        return date;
      } else {
        if (date.includes("1900-01-01")) {
          return "";
        } else {
          if ((date.includes("/") || !Number.isInteger(date)) && !date.includes("T")) {
            return date;
          } else {
            const datetime = new Date(date);
            const datetimeFormat = new Intl.DateTimeFormat("fr", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            const [{ value: day }, , { value: month }, , { value: year }] = datetimeFormat.formatToParts(datetime);

            return `${day}/${month}/${year}`;
          }
        }
      }
    } else {
      return "";
    }
  };

  _UpdateDefuntData = (DEFUNT) => {
    if (DEFUNT.ETAT_CIVIL.DATENAISS !== "") {
      DEFUNT.ETAT_CIVIL.DATENAISS = Cree_iso_date(DEFUNT.ETAT_CIVIL.DATENAISS);
    }

    if (DEFUNT.ETAT_CIVIL.DATEDECES !== "") {
      DEFUNT.ETAT_CIVIL.DATEDECES = Cree_iso_date(DEFUNT.ETAT_CIVIL.DATEDECES);
    }

    if (DEFUNT.ETAT_CIVIL.HEUREMOUV !== undefined && DEFUNT.ETAT_CIVIL.DATEINHUM !== "") {
      DEFUNT.ETAT_CIVIL.DATEINHUM = `${Cree_iso_date(DEFUNT.ETAT_CIVIL.DATEINHUM).slice(0, -1)}1`;
    }

    if (DEFUNT.ETAT_CIVIL.HEUREMOUV !== undefined && DEFUNT.ETAT_CIVIL.HEUREMOUV !== "") {
      DEFUNT.ETAT_CIVIL.HEUREMOUV = Cree_iso_date(DEFUNT.ETAT_CIVIL.DATEINHUM, DEFUNT.ETAT_CIVIL.HEUREMOUV);
    }

    if (DEFUNT.NUMCENDRE === "") {
      DEFUNT.NUMCENDRE = 0;
    }

    return DEFUNT;
  };

  _AjoutTemporaire = (temporaire) => {
    AddTemporaire(temporaire)
      .then(() => {
        if (temporaire.TYPE_D_ENTITE !== "DEFUNT" || this.props.id_defunt === "c") {
          this.setState({ REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}/DEFUNT` });
        } else {
          this.setState({ REDIRECT: `/consulter/fiche-defunt/${temporaire.TABLE_MODIFIE_JSON.D0CLEUNIK}` });
        }
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourTemporaire = (temporaire) => {
    UpdateTemporaire(temporaire)
      .then(() => {
        if (temporaire.TYPE_D_ENTITE !== "DEFUNT" || this.props.id_defunt === "c") {
          this.setState({ REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}/DEFUNT` });
        } else {
          this.setState({ REDIRECT: `/consulter/fiche-defunt/${temporaire.TABLE_MODIFIE_JSON.D0CLEUNIK}` });
        }
      })
      .catch((Err) => handleError(Err));
  };

  _AjoutDefunt = (DEFUNT) => {
    DEFUNT = _.omit(DEFUNT, ["id"]);

    let defAdd = { ...DEFUNT };

    if (defAdd.ETAT_CIVIL.HEUREMOUV !== "") {
      defAdd.ETAT_CIVIL.DATEINHUM = Cree_iso_date(DEFUNT.ETAT_CIVIL.DATEINHUM, DEFUNT.ETAT_CIVIL.HEUREMOUV);
    }

    delete defAdd.ETAT_CIVIL.HEUREMOUV;

    if (this.state.IS_ORACLE && defAdd.IDMATERIAU === undefined) DEFUNT.IDMATERIAU = 0;

    const ajout_defunt = AddDefunt(defAdd);
    ajout_defunt
      .then((_id) => {
        _.set(defAdd, "id", _id);
        _.set(defAdd, "D0CLEUNIK", -_id);
        const modif_d0cleunik = UpdateDefunt(defAdd);
        modif_d0cleunik
          .then(() => {
            const ajout_identite = AddDefuntS(defAdd);
            ajout_identite
              .then(() => {
                _.set(DEFUNT, "id", _id);
                _.set(DEFUNT, "D0CLEUNIK", -_id);

                let identite = `${DEFUNT.ETAT_CIVIL.NOMDEF} ${DEFUNT.ETAT_CIVIL.PRENOMDEF}`;

                DEFUNT.NOMDEF = DEFUNT.ETAT_CIVIL.NOMDEF;
                DEFUNT.NOMJEUNE = DEFUNT.ETAT_CIVIL.NOMJEUNE;
                DEFUNT.PRENOMDEF = DEFUNT.ETAT_CIVIL.PRENOMDEF;
                DEFUNT.DATENAISS = DEFUNT.ETAT_CIVIL.DATENAISS;
                DEFUNT.DATEDECES = DEFUNT.ETAT_CIVIL.DATEDECES;

                const dateInhum = DEFUNT.ETAT_CIVIL.DATEINHUM;
                if (dateInhum !== "") {
                  let date = dateInhum.split("T")[0];
                  let heure = dateInhum.split("T")[1].slice(0, -3);

                  DEFUNT.DATEINHUM = date !== "1900-01-01" ? date : "";
                  DEFUNT.HEUREMOUV = heure !== "00:00" ? heure : "";
                } else {
                  DEFUNT.DATEINHUM = "";
                  DEFUNT.HEUREMOUV = "";
                }

                DEFUNT.AGE = DEFUNT.ETAT_CIVIL.AGE;

                if (this.state.IS_ORACLE) {
                  DEFUNT.CIVDEF = DEFUNT.ETAT_CIVIL.SEXE;
                  DEFUNT.ANNEEDECES = DEFUNT.ETAT_CIVIL.ANNEEDECES;
                } else {
                  DEFUNT.SEXE = DEFUNT.ETAT_CIVIL.SEXE;
                }

                delete DEFUNT.ETAT_CIVIL;

                if (this.state.IS_ORACLE) {
                  DEFUNT.INFOPLUS = `${DEFUNT.ENVELOPPE.URNE}${DEFUNT.ENVELOPPE.RELIQUAIRE}${DEFUNT.ENVELOPPE.DISPERSION}${DEFUNT.ENVELOPPE.URNE_SCELLE}`;
                } else {
                  DEFUNT.INFOPLUS = `${DEFUNT.ENVELOPPE.URNE}${DEFUNT.ENVELOPPE.RELIQUAIRE}${DEFUNT.ENVELOPPE.ENFANT}${DEFUNT.ENVELOPPE.DISPERSION}${DEFUNT.ENVELOPPE.URNE_SCELLE}`;
                }

                delete DEFUNT.ENVELOPPE;

                DEFUNT.BIERRE = `${DEFUNT.BIERRE.MEBI_MC}${DEFUNT.BIERRE.SOINS_CONSERVATIONS}${DEFUNT.BIERRE.HERMETIQUE}${DEFUNT.BIERRE.ZINC_HN}`;

                if (this.state.IS_ORACLE) {
                  DEFUNT.ENFANTDC =
                    DEFUNT.ENFANTDC.AUCUN === 1
                      ? 1
                      : DEFUNT.ENFANTDC.ENFANT === 1
                      ? 2
                      : DEFUNT.ENFANTDC.NOUVEAU_NE === 1
                      ? 3
                      : DEFUNT.ENFANTDC.MORT_NE === 1
                      ? 4
                      : DEFUNT.ENFANTDC.FOETUS === 1
                      ? 5
                      : 0;
                } else {
                  delete DEFUNT.ENFANTDC;
                }

                const temporaire = CreeTemporaire(this.IDCLIENT, this.ID_TABLETTE, this.ID_UTIL, `Ajout du défunt ${identite}`, "DEFUNT", 1, DEFUNT, null);
                this._AjoutTemporaire(temporaire);
              })
              .catch((Err) => handleError(Err));
          })
          .catch((Err) => handleError(Err));
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourDefunt = (DEFUNT, DEFUNT_TEMPORAIRE) => {
    let defUpdate = { ...DEFUNT };

    if (defUpdate.ETAT_CIVIL.HEUREMOUV !== "") {
      defUpdate.ETAT_CIVIL.DATEINHUM = Cree_iso_date(DEFUNT.ETAT_CIVIL.DATEINHUM, DEFUNT.ETAT_CIVIL.HEUREMOUV);
    }

    delete defUpdate.ETAT_CIVIL.HEUREMOUV;

    const modifie_defunt = UpdateDefunt(defUpdate);
    modifie_defunt
      .then(() => {
        const modifie_identite = UpdateDefuntS(defUpdate);
        modifie_identite
          .then(() => {
            let identite = `${DEFUNT.ETAT_CIVIL.NOMDEF} ${DEFUNT.ETAT_CIVIL.PRENOMDEF}`;

            GetTemporaireNonEnvoye("DEFUNT", "D0CLEUNIK", DEFUNT.D0CLEUNIK).then((temporaire) => {
              if (DEFUNT_TEMPORAIRE.ETAT_CIVIL !== undefined) {
                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.NOMDEF !== undefined) {
                  DEFUNT_TEMPORAIRE.NOMDEF = DEFUNT_TEMPORAIRE.ETAT_CIVIL.NOMDEF;
                }

                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.NOMJEUNE !== undefined) {
                  DEFUNT_TEMPORAIRE.NOMJEUNE = DEFUNT_TEMPORAIRE.ETAT_CIVIL.NOMJEUNE;
                }

                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.PRENOMDEF !== undefined) {
                  DEFUNT_TEMPORAIRE.PRENOMDEF = DEFUNT_TEMPORAIRE.ETAT_CIVIL.PRENOMDEF;
                }

                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.DATENAISS !== undefined) {
                  DEFUNT_TEMPORAIRE.DATENAISS = DEFUNT_TEMPORAIRE.ETAT_CIVIL.DATENAISS;
                }

                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.DATEDECES !== undefined) {
                  DEFUNT_TEMPORAIRE.DATEDECES = DEFUNT_TEMPORAIRE.ETAT_CIVIL.DATEDECES;
                }

                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.DATEINHUM !== undefined) {
                  DEFUNT_TEMPORAIRE.DATEINHUM = DEFUNT_TEMPORAIRE.ETAT_CIVIL.DATEINHUM.split("T")[0];
                }

                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.HEUREMOUV !== undefined && DEFUNT_TEMPORAIRE.ETAT_CIVIL.HEUREMOUV !== "") {
                  DEFUNT_TEMPORAIRE.HEUREMOUV = DEFUNT_TEMPORAIRE.ETAT_CIVIL.HEUREMOUV.split("T")[1].slice(0, -3);
                }

                if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.AGE !== undefined) {
                  DEFUNT_TEMPORAIRE.AGE = DEFUNT_TEMPORAIRE.ETAT_CIVIL.AGE;
                }

                if (this.state.IS_ORACLE) {
                  if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.ANNEEDECES !== undefined) {
                    DEFUNT_TEMPORAIRE.ANNEEDECES = DEFUNT_TEMPORAIRE.ETAT_CIVIL.ANNEEDECES;
                  }

                  if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.SEXE !== undefined) {
                    DEFUNT_TEMPORAIRE.CIVDEF = DEFUNT_TEMPORAIRE.ETAT_CIVIL.SEXE;
                  }
                } else {
                  if (DEFUNT_TEMPORAIRE.ETAT_CIVIL.SEXE !== undefined) {
                    DEFUNT_TEMPORAIRE.SEXE = DEFUNT_TEMPORAIRE.ETAT_CIVIL.SEXE;
                  }
                }

                delete DEFUNT_TEMPORAIRE.ETAT_CIVIL;
              }

              if (DEFUNT_TEMPORAIRE.ENVELOPPE !== undefined) {
                if (this.state.IS_ORACLE) {
                  DEFUNT_TEMPORAIRE.INFOPLUS = `${DEFUNT_TEMPORAIRE.ENVELOPPE.URNE !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.URNE : DEFUNT.ENVELOPPE.URNE}`;
                  DEFUNT_TEMPORAIRE.INFOPLUS += `${DEFUNT_TEMPORAIRE.ENVELOPPE.RELIQUAIRE !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.RELIQUAIRE : DEFUNT.ENVELOPPE.RELIQUAIRE}`;
                  DEFUNT_TEMPORAIRE.INFOPLUS += `${DEFUNT_TEMPORAIRE.ENVELOPPE.DISPERSION !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.DISPERSION : DEFUNT.ENVELOPPE.DISPERSION}`;
                  DEFUNT_TEMPORAIRE.INFOPLUS += `${DEFUNT_TEMPORAIRE.ENVELOPPE.URNE_SCELLE !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.URNE_SCELLE : DEFUNT.ENVELOPPE.URNE_SCELLE}`;
                } else {
                  DEFUNT_TEMPORAIRE.INFOPLUS = `${DEFUNT_TEMPORAIRE.ENVELOPPE.URNE !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.URNE : DEFUNT.ENVELOPPE.URNE}`;
                  DEFUNT_TEMPORAIRE.INFOPLUS += `${DEFUNT_TEMPORAIRE.ENVELOPPE.RELIQUAIRE !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.RELIQUAIRE : DEFUNT.ENVELOPPE.RELIQUAIRE}`;
                  DEFUNT_TEMPORAIRE.INFOPLUS += `${DEFUNT_TEMPORAIRE.ENVELOPPE.ENFANT !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.ENFANT : DEFUNT.ENVELOPPE.ENFANT}`;
                  DEFUNT_TEMPORAIRE.INFOPLUS += `${DEFUNT_TEMPORAIRE.ENVELOPPE.DISPERSION !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.DISPERSION : DEFUNT.ENVELOPPE.DISPERSION}`;
                  DEFUNT_TEMPORAIRE.INFOPLUS += `${DEFUNT_TEMPORAIRE.ENVELOPPE.URNE_SCELLE !== undefined ? DEFUNT_TEMPORAIRE.ENVELOPPE.URNE_SCELLE : DEFUNT.ENVELOPPE.URNE_SCELLE}`;
                }
                delete DEFUNT_TEMPORAIRE.ENVELOPPE;
              }

              if (DEFUNT_TEMPORAIRE.BIERRE !== undefined) {
                DEFUNT_TEMPORAIRE.BIERRE = `${DEFUNT_TEMPORAIRE.BIERRE.MEBI_MC !== undefined ? DEFUNT_TEMPORAIRE.BIERRE.MEBI_MC : DEFUNT.BIERRE.MEBI_MC}`;
                DEFUNT_TEMPORAIRE.BIERRE += `${DEFUNT_TEMPORAIRE.BIERRE.SOINS_CONSERVATIONS !== undefined ? DEFUNT_TEMPORAIRE.BIERRE.SOINS_CONSERVATIONS : DEFUNT.BIERRE.SOINS_CONSERVATIONS}`;
                DEFUNT_TEMPORAIRE.BIERRE += `${DEFUNT_TEMPORAIRE.BIERRE.HERMETIQUE !== undefined ? DEFUNT_TEMPORAIRE.BIERRE.HERMETIQUE : DEFUNT.BIERRE.HERMETIQUE}`;
                DEFUNT_TEMPORAIRE.BIERRE += `${DEFUNT_TEMPORAIRE.BIERRE.ZINC_HN !== undefined ? DEFUNT_TEMPORAIRE.BIERRE.ZINC_HN : DEFUNT.BIERRE.ZINC_HN}`;
              }

              if (this.state.IS_ORACLE) {
                if (DEFUNT_TEMPORAIRE.ENFANTDC !== undefined) {
                  DEFUNT_TEMPORAIRE.ENFANTDC =
                    DEFUNT_TEMPORAIRE.ENFANTDC.AUCUN === 1
                      ? 1
                      : DEFUNT_TEMPORAIRE.ENFANTDC.ENFANT === 1
                      ? 2
                      : DEFUNT_TEMPORAIRE.ENFANTDC.NOUVEAU_NE === 1
                      ? 3
                      : DEFUNT_TEMPORAIRE.ENFANTDC.MORT_NE === 1
                      ? 4
                      : DEFUNT_TEMPORAIRE.ENFANTDC.FOETUS === 1
                      ? 5
                      : 0;
                }
              }

              if (temporaire !== undefined) {
                var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, DEFUNT_TEMPORAIRE);

                temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;
                temporaire.TITRE = temporaire.TITRE.replace(/(.*défunt )(.*)/, `$1${identite}`);

                this._MiseAJourTemporaire(temporaire);
              } else {
                const temporaire = CreeTemporaire(this.IDCLIENT, this.ID_TABLETTE, this.ID_UTIL, `Modification du défunt ${identite}`, "DEFUNT", 2, DEFUNT_TEMPORAIRE, this.state.DEFUNT_SAUVEGARDE);

                this._AjoutTemporaire(temporaire);
              }
            });
          })
          .catch((Err) => handleError(Err));
      })
      .catch((Err) => handleError(Err));
  };

  _handle_submit_form_defunt = (event) => {
    event.preventDefault();

    if (this.state.disabledForm) return;

    this.setState({ disabledForm: true }, () => {
      let DEFUNT = this.state.DEFUNT;
      DEFUNT = this._UpdateDefuntData(DEFUNT);

      if (this.props.id_defunt !== undefined && this.props.id_defunt !== "c") {
        let changed = !_.isEqual(DEFUNT, this._UpdateDefuntData(this.state.DEFUNT_SAUVEGARDE));

        if (changed === true) {
          let diff = differenceObject(DEFUNT, this._UpdateDefuntData(this.state.DEFUNT_SAUVEGARDE));
          diff = _.assign(
            {
              id: DEFUNT.id,
              D0CLEUNIK: DEFUNT.D0CLEUNIK,
              COCLEUNIK: DEFUNT.COCLEUNIK,
            },
            diff
          );

          this._MiseAJourDefunt(DEFUNT, diff);
        }
      } else {
        if (this.props.id_defunt === "c") {
          GetLieuByCimel(DEFUNT.TYPECIME, false).then((alllieu) => {
            let lieu = GetLieuFromEmpl(alllieu, this.state.SELECTED_SQUARE.trim(), this.state.SELECTED_RANK.trim(), this.state.SELECTED_TOMB.trim());

            if (lieu.length > 1) {
              lieu = _.filter(lieu, (l) => {
                return l.COCLEUNIK === DEFUNT.COCLEUNIK;
              });
            }

            _.set(DEFUNT, "LICLEUNIK", lieu[0].LICLEUNIK);

            GetCendreByIdLieu(DEFUNT.LICLEUNIK).then((cendre) => {
              if (cendre !== undefined) {
                _.set(DEFUNT, "NUMCENDRE", cendre.NUMCENDRE);
              }

              this._AjoutDefunt(DEFUNT);
            });
          });
        } else {
          this._AjoutDefunt(DEFUNT);
        }
      }
    });
  };

  _cree_chaine_modif = (diff) => {
    let champs = [];

    _.forOwn(diff, function (value, key) {
      if (key === "ETAT_CIVIL") {
        _.forOwn(value, function (subvalue, subkey) {
          champs.push(subkey);
        });
      } else {
        champs.push(key);
      }
    });

    return champs.join(",");
  };

  _cree_iso_time = (date) => {
    var match = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/g.exec(date);

    if (parseInt(match[1]) <= 1911 && parseInt(match[2]) <= 2 && parseInt(match[3]) <= 11) {
      var minutes = parseInt(match[5]);
      minutes = minutes < 10 ? `0${minutes}` : minutes;

      var newdate = `${match[1]}-${match[2]}-${match[3]}T${match[4]}:${minutes}:${match[6]}`;

      if (`${match[4]}:${minutes}:${match[6]}` === "00:00:00") {
        return null;
      }

      return newdate;
    }

    if (`${match[4]}:${match[5]}:${match[6]}` === "00:00:00") {
      return null;
    }

    return date;
  };

  _handle_change_defunt = (champ, refInput) => (event) => {
    if (!event instanceof Date && isNaN(event)) {
      event.preventDefault();
    }

    let cursor;
    if (event !== null && event.target !== undefined) cursor = event.target.selectionStart;

    let DEFUNT = _.cloneDeep(this.state.DEFUNT);
    const ageDefunt = DEFUNT.ETAT_CIVIL.AGE;

    let ERROR = this.state.ERROR;
    switch (champ) {
      case "AGE":
        if (isNaN(event.target.value)) {
          DEFUNT.ETAT_CIVIL[champ] = 0;
          ERROR[champ] = "Valeur incorrecte.";
        } else {
          DEFUNT.ETAT_CIVIL[champ] = event.target.value;
        }

        break;
      case "NOMDEF":
      case "NOMJEUNE":
        DEFUNT.ETAT_CIVIL[champ] = event.target.value.toUpperCase();
        break;
      case "PRENOMDEF":
        DEFUNT.ETAT_CIVIL[champ] = event.target.value.replace(/(?:^|-|\s)\S/g, (l) => l.toUpperCase());
        break;
      case "DATENAISS":
        if (!Number.isInteger(parseInt(event.target.value))) {
          DEFUNT.ETAT_CIVIL[champ] = event.target.value;

          if (event.target.value !== "") {
            ERROR[champ] = "Valeur incorrecte.";
          }
        } else {
          if (event.target.value.length === 8 && !event.target.value.includes("/")) {
            var match = /^(\d{2})(\d{2})(\d{4})$/g.exec(event.target.value);

            DEFUNT.ETAT_CIVIL[champ] = `${match[1]}/${match[2]}/${match[3]}`;
            ERROR[champ] = null;
          } else if (event.target.value.length === 10 && event.target.value.includes("/")) {
            DEFUNT.ETAT_CIVIL[champ] = event.target.value;
            ERROR[champ] = null;
          } else {
            match = /^([1|2]{1}[8|9|0]{1}\d{2})$/g.exec(event.target.value);

            if (match !== null) {
              DEFUNT.ETAT_CIVIL[champ] = match[1];
              ERROR[champ] = null;
            } else {
              if (new Date(event.target.value.slice(0, 10)).toString() === "Invalid Date") {
                DEFUNT.ETAT_CIVIL[champ] = event.target.value.slice(0, 10);
                ERROR[champ] = "Valeur incorrecte.";
              } else {
                DEFUNT.ETAT_CIVIL[champ] = event.target.value.slice(0, 10);
                ERROR[champ] = null;
              }
            }
          }
        }

        DEFUNT.ETAT_CIVIL.AGE = this._calcule_age_defunt(DEFUNT) < 150 ? this._calcule_age_defunt(DEFUNT) : ageDefunt;

        var dds, bds;
        if (DEFUNT.ETAT_CIVIL.DATEDECES !== "" && DEFUNT.ETAT_CIVIL[champ] !== "") {
          if (DEFUNT.ETAT_CIVIL.DATEDECES.includes("/")) {
            dds = DEFUNT.ETAT_CIVIL.DATEDECES.split("/");
          } else {
            let c_date = DEFUNT.ETAT_CIVIL.DATEDECES.split("T")[0].split("-");
            dds = _.reverse(c_date);
          }

          if (DEFUNT.ETAT_CIVIL[champ].includes("/")) {
            bds = DEFUNT.ETAT_CIVIL[champ].split("/");
          } else {
            let c_date = DEFUNT.ETAT_CIVIL[champ].split("T")[0].split("-");
            bds = _.reverse(c_date);
          }

          var birthDate;
          if (bds.length === 1) {
            birthDate = new Date(bds[0], 0, 1);
          } else {
            birthDate = new Date(bds[2], bds[1] - 1, bds[0]);
          }

          var deathDate = new Date(dds[2], dds[1] - 1, dds[0]);

          if (deathDate < birthDate) {
            ERROR[champ] = "La date de naissance ne peut être supérieure à la date de décès";
          } else {
            ERROR[champ] = null;
          }
        } else {
          if (event.target.value === "") {
            ERROR[champ] = null;
            ERROR.DATEDECES = null;
          }
        }

        break;
      case "DATEDECES":
        if (event instanceof Date && !isNaN(event)) {
          let newdate = new Date(event);

          if (newdate <= new Date(1911, 3, 11)) {
            newdate.setMinutes(9);
            newdate.setSeconds(21);
          }

          var userTimezoneOffset = newdate.getTimezoneOffset() * 60000;
          newdate = new Date(newdate.getTime() - userTimezoneOffset);

          DEFUNT.ETAT_CIVIL[champ] = newdate.toISOString().slice(0, -5);
        }

        DEFUNT.ETAT_CIVIL.AGE = this._calcule_age_defunt(DEFUNT) < 150 ? this._calcule_age_defunt(DEFUNT) : ageDefunt;

        var dds2, bds2, bdi2;
        if (DEFUNT.ETAT_CIVIL[champ] !== "" && (DEFUNT.ETAT_CIVIL.DATENAISS !== "" || DEFUNT.ETAT_CIVIL.DATEINHUM !== "")) {
          if (DEFUNT.ETAT_CIVIL[champ].includes("/")) {
            dds2 = DEFUNT.ETAT_CIVIL[champ].split("/");
          } else {
            let c_date = DEFUNT.ETAT_CIVIL[champ].split("T")[0].split("-");
            dds2 = _.reverse(c_date);
          }

          if (DEFUNT.ETAT_CIVIL.DATENAISS.includes("/")) {
            bds2 = DEFUNT.ETAT_CIVIL.DATENAISS.split("/");
          } else {
            let c_date = DEFUNT.ETAT_CIVIL.DATENAISS.split("T")[0].split("-");
            bds2 = _.reverse(c_date);
          }

          var birthDate2;

          if (bds2.length === 1) {
            birthDate2 = new Date(bds2[0], 0, 1);
          } else {
            birthDate2 = new Date(bds2[2], bds2[1] - 1, bds2[0]);
          }

          if (DEFUNT.ETAT_CIVIL.DATEINHUM.includes("/")) {
            bdi2 = DEFUNT.ETAT_CIVIL.DATEINHUM.split("/");
          } else {
            let c_date = DEFUNT.ETAT_CIVIL.DATEINHUM.split("T")[0].split("-");
            bdi2 = _.reverse(c_date);
          }

          var inhumDate2;

          if (bdi2.length === 1) {
            inhumDate2 = new Date(bdi2[0], 0, 1);
          } else {
            inhumDate2 = new Date(bdi2[2], bdi2[1] - 1, bdi2[0]);
          }

          var deathDate2 = new Date(dds2[2], dds2[1] - 1, dds2[0]);

          if (event === null) {
            ERROR[champ] = null;
            ERROR.DATENAISS = null;
            ERROR.DATEINHUM = null;
          } else {
            if (birthDate2 > deathDate2) {
              ERROR[champ] = "La date de décès ne peut être inférieure à la date de naissance";
              ERROR.DATENAISS = null;
            } else {
              ERROR[champ] = null;
              ERROR.DATENAISS = null;
            }

            if (ERROR[champ] === null) {
              if (deathDate2 > inhumDate2) {
                ERROR[champ] = "La date de décès ne peut être supérieure à la date d'inhumation";
                ERROR.DATEINHUM = null;
              } else {
                ERROR[champ] = null;
                ERROR.DATEINHUM = null;
              }
            }
          }
        }

        if (event !== null) {
          this.setState({ anneeDeces: false });
        } else {
          this.setState({ anneeDeces: true }, () => {
            DEFUNT.ETAT_CIVIL.DATEDECES = "";
            DEFUNT.ETAT_CIVIL.AGE = this._calcule_age_defunt(DEFUNT) < 150 ? this._calcule_age_defunt(DEFUNT) : ageDefunt;

            this.setState({
              DEFUNT: DEFUNT,
            });
          });
        }

        break;
      case "ANNEEDECES":
        DEFUNT.ETAT_CIVIL[champ] = event.target.value;

        DEFUNT.ETAT_CIVIL.AGE = this._calcule_age_defunt(DEFUNT) < 150 ? this._calcule_age_defunt(DEFUNT) : ageDefunt;

        var dds4, bds3;
        if (DEFUNT.ETAT_CIVIL[champ] !== "" && DEFUNT.ETAT_CIVIL.DATENAISS !== "") {
          let c_date = `${DEFUNT.ETAT_CIVIL[champ]}-01-01T00:00:00`.split("T")[0].split("-");
          dds4 = _.reverse(c_date);

          if (DEFUNT.ETAT_CIVIL.DATENAISS.includes("/")) {
            bds3 = DEFUNT.ETAT_CIVIL.DATENAISS.split("/");
          } else {
            let c_date = DEFUNT.ETAT_CIVIL.DATENAISS.split("T")[0].split("-");
            bds3 = _.reverse(c_date);
          }

          var birthDate3;

          if (bds3.length === 1) {
            birthDate3 = new Date(bds3[0], 0, 1);
          } else {
            birthDate3 = new Date(bds3[2], bds3[1] - 1, bds3[0]);
          }

          var deathDate4 = new Date(dds4[2], dds4[1] - 1, dds4[0]);

          if (birthDate3 > deathDate4) {
            ERROR[champ] = "L'année de décès ne peut être inférieure à la date de naissance";
          } else {
            ERROR[champ] = null;
          }
        }

        if (event.target.value !== "") {
          this.setState({ dateDeces: false });
        } else {
          this.setState({ dateDeces: true });
        }

        break;
      case "DATEINHUM":
      case "HEUREMOUV":
        if (event instanceof Date && !isNaN(event)) {
          let newdate = new Date(event);

          if (newdate <= new Date(1911, 3, 11)) {
            newdate.setMinutes(9);
            newdate.setSeconds(21);
          }

          var userTimezoneOffset2 = newdate.getTimezoneOffset() * 60000;
          newdate = new Date(newdate.getTime() - userTimezoneOffset2);

          DEFUNT.ETAT_CIVIL[champ] = newdate.toISOString().slice(0, -5);

          let c_date = DEFUNT.ETAT_CIVIL[champ].split("T")[0].split("-");
          var dis = _.reverse(c_date);
          var dds3;

          if (DEFUNT.ETAT_CIVIL.DATEDECES !== "") {
            if (DEFUNT.ETAT_CIVIL.DATEDECES.includes("/")) {
              dds3 = DEFUNT.ETAT_CIVIL.DATEDECES.split("/");
            } else {
              let c_date = DEFUNT.ETAT_CIVIL.DATEDECES.split("T")[0].split("-");
              dds3 = _.reverse(c_date);
            }
          }

          if (DEFUNT.ETAT_CIVIL.ANNEEDECES !== "" && DEFUNT.ETAT_CIVIL.ANNEEDECES > 0) {
            let c_date = `${DEFUNT.ETAT_CIVIL.ANNEEDECES}-01-01T00:00:00`.split("T")[0].split("-");
            dds3 = _.reverse(c_date);
          }

          if (dds3 !== undefined) {
            var deathDate3 = new Date(dds3[2], dds3[1] - 1, dds3[0]);
            var inhumDate = new Date(dis[2], dis[1] - 1, dis[0]);

            if (deathDate3 > inhumDate) {
              ERROR[champ] = "La date d'inhumation ne peut être inférieure à la date de décès";
              ERROR.DATEDECES = null;
            } else {
              ERROR[champ] = null;
              ERROR.DATEDECES = null;
            }
          }
        }

        break;
      case "IDMATERIAU":
        DEFUNT[champ] = parseInt(event.target.value);

        break;
      default:
        DEFUNT.ETAT_CIVIL[champ] = event.target.value;
        break;
    }

    this.setState({ DEFUNT: DEFUNT, ERROR: ERROR, disabledForm: false }, () => {
      if (refInput && refInput.current != null) refInput.current.selectionEnd = cursor;
    });
  };

  _handle_change_combo_cimetier = (event) => {
    GetLieuByCimel(parseInt(event.target.value), false).then((lieu) => {
      GetCendreByNumcime(parseInt(event.target.value)).then((cendre) => {
        let square = GetSquare(lieu);

        let DEFUNT = this.state.DEFUNT;
        DEFUNT.TYPECIME = parseInt(event.target.value);
        DEFUNT.NUMCENDRE = "";

        this.setState({
          DEFUNT: DEFUNT,
          CENDRE: cendre,
          SELECTED_SQUARE: "",
          SQUARE: square,
          SELECTED_RANK: "",
          RANK: [],
          SELECTED_TOMB: "",
          TOMB: [],
          disabledForm: false,
        });
      });
    });
  };

  _handle_change_switch_cendre = () => {
    let DEFUNT = this.state.DEFUNT;
    DEFUNT.NUMCENDRE = "";

    this.setState({
      DEFUNT: DEFUNT,
      IS_CENDRE: !this.state.IS_CENDRE,
      SELECTED_SQUARE: "",
      RANK: [],
      SELECTED_RANK: "",
      TOMB: [],
      SELECTED_TOMB: "",
      disabledForm: false,
    });
  };

  _handle_change_combo_cendre = (event) => {
    GetCendreById(event.target.value).then((cendre) => {
      let DEFUNT = this.state.DEFUNT;
      DEFUNT.NUMCENDRE = event.target.value;
      DEFUNT.LICLEUNIK = cendre.LICLEUNIK;
      DEFUNT.COCLEUNIK = 0;

      this.setState({ DEFUNT: DEFUNT, disabledForm: false });
    });
  };

  _handle_change_combo_square = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.DEFUNT.TYPECIME, false).then((alllieu) => {
      let rank = GetRank(alllieu, event.target.value.trim());

      this.setState({
        SELECTED_SQUARE: event.target.value,
        RANK: rank,
        SELECTED_RANK: "",
        TOMB: [],
        SELECTED_TOMB: "",
        disabledForm: false,
      });
    });
  };

  _handle_change_combo_rank = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.DEFUNT.TYPECIME, false).then((alllieu) => {
      let tomb = GetTomb(alllieu, this.state.SELECTED_SQUARE.trim(), event.target.value.trim());

      this.setState({
        SELECTED_RANK: event.target.value,
        TOMB: tomb,
        SELECTED_TOMB: "",
        disabledForm: false,
      });
    });
  };

  _handle_change_combo_tomb = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.DEFUNT.TYPECIME, false).then((alllieu) => {
      let lieu = GetLieuFromEmpl(alllieu, this.state.SELECTED_SQUARE.trim(), this.state.SELECTED_RANK.trim(), event.target.value.trim());

      let DEFUNT = this.state.DEFUNT;
      DEFUNT.LICLEUNIK = lieu.LICLEUNIK;
      DEFUNT.COCLEUNIK = lieu.COCLEUNIK;

      this.setState({
        DEFUNT: DEFUNT,
        SELECTED_TOMB: event.target.value,
        disabledForm: false,
      });
    });
  };

  _handle_change_radio_cercueil = (event) => {
    let DEFUNT = this.state.DEFUNT;
    DEFUNT.ENVELOPPE = _.mapValues(DEFUNT.ENVELOPPE, () => 0);
    DEFUNT.BIERRE = _.mapValues(DEFUNT.BIERRE, () => 0);

    switch (event.target.value) {
      case "HERMETIQUE":
      case "ZINC_HN":
        DEFUNT.BIERRE[event.target.value] = 1;
        break;
      default:
        if (event.target.value !== "CERCUEIL") {
          DEFUNT.ENVELOPPE[event.target.value] = 1;
        }
        break;
    }

    this.setState({ DEFUNT: DEFUNT, SELECTED_ENVELOPPE: event.target.value, disabledForm: false });
  };

  _handle_change_radio_cercueil_oracle = (field) => (event) => {
    let DEFUNT = this.state.DEFUNT;

    switch (field) {
      case "URNE":
        if (event.target.value === "false") {
          DEFUNT.ENVELOPPE[field] = 1;
          DEFUNT.ENVELOPPE.URNE_SCELLE = 0;
        } else {
          DEFUNT.ENVELOPPE[field] = 0;
        }
        break;
      case "URNE_SCELLE":
        if (event.target.value === "false") {
          DEFUNT.ENVELOPPE[field] = 1;
          DEFUNT.ENVELOPPE.URNE = 0;
        } else {
          DEFUNT.ENVELOPPE[field] = 0;
        }
        break;
      case "HERMETIQUE":
      case "ZINC_HN":
        DEFUNT.BIERRE[field] = event.target.value === "false" ? 1 : 0;
        break;
      default:
        DEFUNT.ENVELOPPE[field] = event.target.value === "false" ? 1 : 0;
        break;
    }

    this.setState({ DEFUNT: DEFUNT, disabledForm: false });
  };

  _handle_change_combo_enfant = (event) => {
    let DEFUNT = this.state.DEFUNT;

    DEFUNT.ENFANTDC = _.mapValues(DEFUNT.ENFANTDC, () => 0);
    DEFUNT.ENFANTDC[event.target.value] = 1;

    this.setState({ DEFUNT: DEFUNT, disabledForm: false });
  };

  render() {
    console.log("RENDER M_DEFUNT");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <form onSubmit={this._handle_submit_form_defunt}>
              <TextField select fullWidth margin="normal" label="Civilité" value={this.state.DEFUNT.ETAT_CIVIL.SEXE} onChange={this._handle_change_defunt("SEXE")}>
                <MenuItem value={1}>Monsieur</MenuItem>
                <MenuItem value={2}>Madame</MenuItem>
                {this.props.id_defunt !== undefined && this.props.id_defunt !== "c" ? <MenuItem value={3}>Mademoiselle</MenuItem> : null}
              </TextField>

              <TextField
                inputRef={this.nom_usage_ref}
                required
                fullWidth
                margin="normal"
                autoCapitalize="off"
                autoCorrect="off"
                label="Nom d'usage"
                value={this.state.DEFUNT.ETAT_CIVIL.NOMDEF}
                onChange={this._handle_change_defunt("NOMDEF", this.nom_usage_ref)}
              />

              {this.state.DEFUNT.ETAT_CIVIL.SEXE === 2 || this.state.DEFUNT.ETAT_CIVIL.SEXE === "" ? (
                <TextField
                  inputRef={this.nom_naissance_ref}
                  fullWidth
                  margin="normal"
                  autoCapitalize="off"
                  autoCorrect="off"
                  label="Nom de naissance"
                  value={this.state.DEFUNT.ETAT_CIVIL.NOMJEUNE}
                  onChange={this._handle_change_defunt("NOMJEUNE", this.nom_naissance_ref)}
                />
              ) : null}

              <TextField
                fullWidth
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Prénom"
                value={this.state.DEFUNT.ETAT_CIVIL.PRENOMDEF}
                onChange={this._handle_change_defunt("PRENOMDEF")}
              />

              <TextField
                error={this.state.ERROR.DATENAISS !== null}
                fullWidth
                margin="normal"
                autoCapitalize="off"
                autoCorrect="off"
                label="Date de naissance (JJ/MM/AAAA ou AAAA)"
                inputProps={{
                  pattern: "(^(?:((?:0[1-9]|1[0-9]|2[0-9])/(?:0[1-9]|1[0-2])|(?:30)/(?!02)(?:0[1-9]|1[0-2])|31/(?:0[13578]|1[02]))/(?:18|19|20)[0-9]{2}|(?:18|19|20)[0-9]{2})$)",
                }}
                value={this._cree_chaine_date(this.state.DEFUNT.ETAT_CIVIL.DATENAISS)}
                onChange={this._handle_change_defunt("DATENAISS")}
                helperText={this.state.ERROR.DATENAISS}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardDatePicker
                  disabled={!this.state.dateDeces}
                  error={this.state.ERROR.DATEDECES !== null}
                  fullWidth
                  margin="normal"
                  minDate="01.01.1800"
                  minDateMessage="La date ne peut être inférieure au 1er Janvier 1800"
                  maxDateMessage="La date n'est pas valide"
                  invalidDateMessage="Format de date invalide"
                  label="Date de décès (JJ/MM/AAAA)"
                  format="dd/MM/yyyy"
                  inputProps={{
                    pattern: "(^(?:((?:0[1-9]|1[0-9]|2[0-9])/(?:0[1-9]|1[0-2])|(?:30)/(?!02)(?:0[1-9]|1[0-2])|31/(?:0[13578]|1[02]))/(?:18|19|20)[0-9]{2})$)",
                  }}
                  value={this.state.DEFUNT.ETAT_CIVIL.DATEDECES !== "" ? Cree_iso_date(this.state.DEFUNT.ETAT_CIVIL.DATEDECES) : null}
                  onChange={this._handle_change_defunt("DATEDECES")}
                  animateYearScrolling
                  cancelLabel="annuler"
                  helperText={this.state.ERROR.DATEDECES}
                />
              </MuiPickersUtilsProvider>

              {this.state.IS_ORACLE ? (
                <TextField
                  disabled={!this.state.anneeDeces}
                  error={this.state.ERROR.ANNEEDECES !== null}
                  fullWidth
                  margin="normal"
                  autoCapitalize="on"
                  autoCorrect="off"
                  label="Année de décès"
                  value={this.state.DEFUNT.ETAT_CIVIL.ANNEEDECES !== 0 ? this.state.DEFUNT.ETAT_CIVIL.ANNEEDECES : ""}
                  onChange={this._handle_change_defunt("ANNEEDECES")}
                  helperText={this.state.ERROR.ANNEEDECES}
                />
              ) : null}

              <TextField
                fullWidth
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Age"
                value={this.state.DEFUNT.ETAT_CIVIL.AGE !== 0 ? this.state.DEFUNT.ETAT_CIVIL.AGE : ""}
                onChange={this._handle_change_defunt("AGE")}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardDatePicker
                  fullWidth
                  error={this.state.ERROR.DATEINHUM !== null}
                  margin="normal"
                  minDate="01.01.1800"
                  minDateMessage="La date ne peut être inférieure au 1er Janvier 1800"
                  maxDateMessage="La date n'est pas valide"
                  invalidDateMessage="Format de date invalide"
                  label="Date de d'inhumation (JJ/MM/AAAA)"
                  format="dd/MM/yyyy"
                  inputProps={{
                    pattern: "(^(?:((?:0[1-9]|1[0-9]|2[0-9])/(?:0[1-9]|1[0-2])|(?:30)/(?!02)(?:0[1-9]|1[0-2])|31/(?:0[13578]|1[02]))/(?:18|19|20)[0-9]{2})$)",
                  }}
                  value={this.state.DEFUNT.ETAT_CIVIL.DATEINHUM !== "" ? Cree_iso_date(this.state.DEFUNT.ETAT_CIVIL.DATEINHUM) : null}
                  onChange={this._handle_change_defunt("DATEINHUM")}
                  animateYearScrolling
                  cancelLabel="annuler"
                  helperText={this.state.ERROR.DATEINHUM}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardTimePicker
                  fullWidth
                  margin="normal"
                  ampm={false}
                  label="Heure d'inhumation (HH:MM)"
                  format="HH:mm"
                  inputProps={{
                    pattern: "(^(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])$)",
                  }}
                  mask="__:__"
                  value={this.state.DEFUNT.ETAT_CIVIL.HEUREMOUV !== "" && !this.state.DEFUNT.ETAT_CIVIL.HEUREMOUV.includes("00:00:01") ? this.state.DEFUNT.ETAT_CIVIL.HEUREMOUV : null}
                  onChange={this._handle_change_defunt("HEUREMOUV")}
                  cancelLabel="annuler"
                />
              </MuiPickersUtilsProvider>

              <TextField
                select
                required
                fullWidth
                disabled={(this.props.id_defunt !== undefined && this.props.id_defunt !== "c") || (this.props.id_defunt === "c" && this.props.id_concess !== undefined)}
                margin="normal"
                label="Cimetière"
                value={this.state.DEFUNT.TYPECIME}
                onChange={this._handle_change_combo_cimetier}
              >
                {this.state.CIMETIER.map((cimetier, index) => (
                  <MenuItem key={index} value={cimetier.NUMCIME.toString()}>
                    {cimetier.NOMCIME}
                  </MenuItem>
                ))}
              </TextField>

              <FormGroup>
                <FormControlLabel
                  disabled={(this.props.id_defunt !== undefined && this.props.id_defunt !== "c") || (this.props.id_defunt === "c" && this.props.id_concess !== undefined)}
                  label="Equipement"
                  style={styles.formcontrollabel}
                  control={<Switch color="primary" checked={this.state.IS_CENDRE} onChange={this._handle_change_switch_cendre} />}
                />
              </FormGroup>

              {this.state.DEFUNT.TYPECIME !== "" ? (
                this.state.IS_CENDRE ? (
                  <TextField
                    select
                    required={this.state.IS_CENDRE}
                    fullWidth
                    disabled={(this.props.id_defunt !== undefined && this.props.id_defunt !== "c") || (this.props.id_defunt === "c" && this.props.id_concess !== undefined)}
                    margin="normal"
                    label="Equipement"
                    value={this.state.DEFUNT.NUMCENDRE}
                    onChange={this._handle_change_combo_cendre}
                  >
                    {this.state.CENDRE.map((cendre, index) => (
                      <MenuItem key={index} value={cendre.NUMCENDRE}>
                        {cendre.NOMCENDRE}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : this.state.SELECTED_SQUARE.includes("Provisoire") || this.state.SELECTED_SQUARE.includes("Non affect") ? (
                  <TextField
                    fullWidth
                    disabled
                    margin="normal"
                    label={this.state.param.LIBELLEANCIENNUMPLAN !== "" ? this.state.param.LIBELLEANCIENNUMPLAN : "Emplacement"}
                    value={this.state.CONCESS.EMPL}
                  />
                ) : (
                  <div>
                    <TextField
                      select
                      required={!this.state.IS_CENDRE}
                      fullWidth
                      disabled={(this.props.id_defunt !== undefined && this.props.id_defunt !== "c") || (this.props.id_defunt === "c" && this.props.id_concess !== undefined)}
                      margin="normal"
                      label={this.state.SQUARE_LIBELLE}
                      value={this.state.SELECTED_SQUARE !== "" ? this.state.SELECTED_SQUARE : " "}
                      onChange={this._handle_change_combo_square}
                    >
                      {this.state.SQUARE.map((square, index) => (
                        <MenuItem key={index} value={square} style={styles.menuitem}>
                          {square}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required={!this.state.IS_CENDRE}
                      fullWidth
                      disabled={(this.props.id_defunt !== undefined && this.props.id_defunt !== "c") || (this.props.id_defunt === "c" && this.props.id_concess !== undefined)}
                      margin="normal"
                      label={this.state.RANK_LIBELLE}
                      value={this.state.SELECTED_RANK !== "" ? this.state.SELECTED_RANK : " "}
                      onChange={this._handle_change_combo_rank}
                    >
                      {this.state.RANK.map((rank, index) => (
                        <MenuItem key={index} value={rank} style={styles.menuitem}>
                          {rank}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required={!this.state.IS_CENDRE}
                      fullWidth
                      disabled={(this.props.id_defunt !== undefined && this.props.id_defunt !== "c") || (this.props.id_defunt === "c" && this.props.id_concess !== undefined && !this.LIEU_VIRTUEL)}
                      margin="normal"
                      label={this.state.TOMB_LIBELLE}
                      value={this.state.SELECTED_TOMB !== "" ? this.state.SELECTED_TOMB : " "}
                      onChange={this._handle_change_combo_tomb}
                    >
                      {this.state.TOMB.map((tomb, index) => (
                        <MenuItem key={index} value={tomb} style={styles.menuitem}>
                          {tomb}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )
              ) : null}

              <FormControl component="fieldset" style={styles.formcontrol}>
                <FormLabel component="legend">Enveloppe</FormLabel>

                {this.state.IS_ORACLE ? (
                  <div>
                    <FormGroup row style={styles.radiogroup}>
                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={
                          <Checkbox
                            checked={_.get(this.state.DEFUNT.ENVELOPPE, "URNE") === 1}
                            onChange={this._handle_change_radio_cercueil_oracle("URNE")}
                            value={_.get(this.state.DEFUNT.ENVELOPPE, "URNE") === 1}
                          />
                        }
                        label="Urne"
                      />

                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={
                          <Checkbox
                            checked={_.get(this.state.DEFUNT.ENVELOPPE, "RELIQUAIRE") === 1}
                            onChange={this._handle_change_radio_cercueil_oracle("RELIQUAIRE")}
                            value={_.get(this.state.DEFUNT.ENVELOPPE, "RELIQUAIRE") === 1}
                          />
                        }
                        label="Boite à ossements"
                      />

                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={
                          <Checkbox
                            checked={_.get(this.state.DEFUNT.ENVELOPPE, "DISPERSION") === 1}
                            onChange={this._handle_change_radio_cercueil_oracle("DISPERSION")}
                            value={_.get(this.state.DEFUNT.ENVELOPPE, "DISPERSION") === 1}
                          />
                        }
                        label="Dispersion"
                      />

                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={
                          <Checkbox
                            checked={_.get(this.state.DEFUNT.ENVELOPPE, "URNE_SCELLE") === 1}
                            onChange={this._handle_change_radio_cercueil_oracle("URNE_SCELLE")}
                            value={_.get(this.state.DEFUNT.ENVELOPPE, "URNE_SCELLE") === 1}
                          />
                        }
                        label="Urne scellée"
                      />

                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={
                          <Checkbox
                            checked={_.get(this.state.DEFUNT.BIERRE, "HERMETIQUE") === 1}
                            onChange={this._handle_change_radio_cercueil_oracle("HERMETIQUE")}
                            value={_.get(this.state.DEFUNT.BIERRE, "HERMETIQUE") === 1}
                          />
                        }
                        label="Cercueil hermétique"
                      />

                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={
                          <Checkbox
                            checked={_.get(this.state.DEFUNT.BIERRE, "ZINC_HN") === 1}
                            onChange={this._handle_change_radio_cercueil_oracle("ZINC_HN")}
                            value={_.get(this.state.DEFUNT.BIERRE, "ZINC_HN") === 1}
                          />
                        }
                        label="Cercueil hors-normes"
                      />
                    </FormGroup>
                  </div>
                ) : (
                  <FormGroup row style={styles.radiogroup}>
                    <FormControlLabel
                      disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                      control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "CERCUEIL"} onChange={this._handle_change_radio_cercueil} value="CERCUEIL" />}
                      label="Cercueil"
                    />

                    <FormControlLabel
                      disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                      control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "HERMETIQUE"} onChange={this._handle_change_radio_cercueil} value="HERMETIQUE" />}
                      label="Cercueil hermétique"
                    />

                    {!this.state.IS_ORACLE ? (
                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "ZINC_HN"} onChange={this._handle_change_radio_cercueil} value="ZINC_HN" />}
                        label="Cercueil zingué"
                      />
                    ) : null}

                    {!this.state.IS_ORACLE ? (
                      <FormControlLabel
                        disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                        control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "ENFANT"} onChange={this._handle_change_radio_cercueil} value="ENFANT" />}
                        label="Cercueil enfant"
                      />
                    ) : null}

                    <FormControlLabel
                      disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                      control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "RELIQUAIRE"} onChange={this._handle_change_radio_cercueil} value="RELIQUAIRE" />}
                      label="Reliquaire"
                    />

                    <FormControlLabel
                      disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                      control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "URNE"} onChange={this._handle_change_radio_cercueil} value="URNE" />}
                      label="Urne"
                    />

                    <FormControlLabel
                      disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                      control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "URNE_SCELLE"} onChange={this._handle_change_radio_cercueil} value="URNE_SCELLE" />}
                      label="Urne scellée"
                    />

                    <FormControlLabel
                      disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                      control={<Checkbox checked={this.state.SELECTED_ENVELOPPE === "DISPERSION"} onChange={this._handle_change_radio_cercueil} value="DISPERSION" />}
                      label="Dispersion"
                    />
                  </FormGroup>
                )}
              </FormControl>

              {this.state.IS_ORACLE ? (
                <div>
                  <TextField
                    select
                    fullWidth
                    disabled={this.props.id_defunt !== undefined && this.props.id_defunt !== "c" && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                    margin="normal"
                    label="Enfant"
                    value={_.findKey(this.state.DEFUNT.ENFANTDC, (item) => item === 1) !== undefined ? _.findKey(this.state.DEFUNT.ENFANTDC, (item) => item === 1) : "AUCUN"}
                    onChange={this._handle_change_combo_enfant}
                  >
                    <MenuItem value="AUCUN" style={{ minHeight: 36 }}></MenuItem>
                    <MenuItem value="ENFANT">Enfant</MenuItem>
                    <MenuItem value="NOUVEAU_NE">Nouveau né</MenuItem>
                    <MenuItem value="MORT_NE">Enfant sans vie</MenuItem>
                    <MenuItem value="FOETUS">fœtus</MenuItem>
                  </TextField>

                  <TextField select fullWidth margin="normal" label="Matériau" value={this.state.DEFUNT.IDMATERIAU || ""} onChange={this._handle_change_defunt("IDMATERIAU")}>
                    <MenuItem value="0" style={{ minHeight: 36 }} />
                    {this.state.MATERIAU.map((materiau, index) => {
                      if (materiau.ARCHIVER === false || materiau.IDMATERIAU === this.state.DEFUNT.IDMATERIAU) {
                        return (
                          <MenuItem key={index} value={materiau.IDMATERIAU}>
                            {materiau.LIBELLE}
                          </MenuItem>
                        );
                      }

                      return null;
                    })}
                  </TextField>
                </div>
              ) : null}

              <div style={styles.submit_div}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Annuler
                </Button>

                <Button variant="contained" color="primary" type="submit" disabled={this.state.disabledForm}>
                  Enregistrer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(withRouter(MDefunt));
