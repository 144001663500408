import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@material-ui/core";

import { Close } from "@material-ui/icons";

const styles = {
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

export default function DialogErreur(props) {
  const { state, onChange } = props;

  return (
    <Dialog open={state} onClose={() => onChange(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle onClose={() => onChange(false)} id="alert-dialog-title">
        <Typography variant="h6" component="p">
          Erreur
        </Typography>

        <IconButton aria-label="close" style={styles.closeButton} onClick={() => onChange(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Une erreur a été détectée lors de la mise à jour des données de l’application ‘Gardiens de cimetières’
          <br />
          Veuillez réessayer ultérieurement
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
