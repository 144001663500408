import db from "../Db";

import { GetAllDefuntS } from "../Get/DefuntS";

var _ = require("lodash");

var AddDefuntS = async function (DEFUNT) {
  return await GetAllDefuntS().then((result) => {
    let identite = `${DEFUNT.ETAT_CIVIL.NOMDEF} ${DEFUNT.ETAT_CIVIL.PRENOMDEF}`;
    let firstChar = identite.charAt(0);

    var n = _.deburr(identite).toLowerCase().charCodeAt(0) - 97;

    result[n][firstChar].push({
      id: DEFUNT.id,
      D0CLEUNIK: DEFUNT.D0CLEUNIK,
      IDENTITE: identite,
    });
    let ordered = _.orderBy(result[n][firstChar], "IDENTITE", "asc");

    return db
      .open()
      .then(() => {
        return db.DEFUNT_S.put({
          id: n + 1,
          [firstChar.toUpperCase()]: ordered,
        })
          .then(() => {
            return "";
          })
          .catch((err) => {
            console.error(err.stack || err);

            return "Erreur : Ajout des données";
          });
      })
      .catch((err) => {
        console.error(err.stack || err);
        return "Erreur : Ouverture de la base";
      });
  });
};

export default AddDefuntS;
