import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateAgendaTache(headers) {
  const _agendatacheCount = await GetApiData("AGENDATACHECount", headers);

  if (_agendatacheCount.Message !== undefined) {
    return _agendatacheCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("AGENDATACHE", _agendatacheCount, 2500, headers, (getProgress, getAgendatache) => {
      if (getAgendatache.Message !== undefined) {
        return res(getAgendatache);
      }

      var drops = [];
      _.forEach(getAgendatache, (agendatache) => {
        let couleur = agendatache.COULEUR > 0 ? agendatache.COULEUR : null;
        if (couleur !== null) {
          var b = Math.floor(couleur / 65536);
          var v = Math.floor((couleur - b * 65536) / 256);
          var r = Math.floor(couleur - b * 65536 - v * 256);

          couleur = [r, v, b];
        }

        drops.push({
          IDAGENDA: agendatache.IDAGENDA,
          TACHE: {
            DATECREATION: agendatache.DATECREATION,
            NOM_CREER_PAR: agendatache.NOM_CREER_PAR,
            LIBELLE: agendatache.LIBELLE.trim(),
            DESCRIPTION: agendatache.TITRETACHE,
            COULEUR: couleur,
            IDGENS: agendatache.GENS !== null ? agendatache.GENS.split(",") : [],
            DATETACHE: agendatache.DATETACHE,
            DATETACHE_FIN: agendatache.DATETACHE_FIN,
          },
          TYPECIME: agendatache.TYPECIME,
          LICLEUNIK: agendatache.LICLEUNIK,
          D0CLEUNIK: agendatache.D0CLEUNIK,
          IDSOCIETE: agendatache.IDSOCIETE,
          IDINTERVEN: agendatache.IDINTERVEN,
        });
      });

      db.open()
        .then(() => {
          db.AGENDATACHE.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _agendatacheCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_agendatacheCount} taches d'agenda`);

    return val;
  });
}
