export default function dessineEmplacement(sub_g, s, id_lieu) {
  sub_g
    .append("polygon")
    .attr("stroke", "#A0A0A0")
    .attr("stroke-width", s.POINTS.BORDER)
    .attr("fill", s.LICLEUNIK === id_lieu ? "red" : s.COULEUR)
    .attr("points", `${s.POINTS.P1.X},${s.POINTS.P1.Y} ${s.POINTS.P2.X},${s.POINTS.P2.Y} ${s.POINTS.P3.X},${s.POINTS.P3.Y} ${s.POINTS.P4.X},${s.POINTS.P4.Y}`);

  if (s.GELE === true) {
    sub_g
      .append("line")
      .attr("x1", s.POINTS.P1.X)
      .attr("y1", s.POINTS.P1.Y)
      .attr("x2", s.POINTS.P3.X)
      .attr("y2", s.POINTS.P3.Y)
      .attr("stroke", "red")
      .attr("stroke-width", s.POINTS.BORDER / 2);

    sub_g
      .append("line")
      .attr("x1", s.POINTS.P2.X)
      .attr("y1", s.POINTS.P2.Y)
      .attr("x2", s.POINTS.P4.X)
      .attr("y2", s.POINTS.P4.Y)
      .attr("stroke", "red")
      .attr("stroke-width", s.POINTS.BORDER / 2);
  }

  if (s.MONUMENTPRESENT === true) {
    var angleDeg = (Math.atan2(parseFloat(s.POINTS.P2.Y) - parseFloat(s.POINTS.P1.Y), parseFloat(s.POINTS.P2.X) - parseFloat(s.POINTS.P1.X)) * 180) / Math.PI;

    sub_g
      .append("polygon")
      .attr("fill", "red")
      .attr(
        "points",
        `${parseFloat(s.POINTS.P1.X)},${parseFloat(s.POINTS.P1.Y)} ${parseFloat(s.POINTS.P1.X) + 1},${parseFloat(s.POINTS.P1.Y)} ${parseFloat(s.POINTS.P1.X)},${parseFloat(s.POINTS.P1.Y) + 1}`
      )
      .attr("transform", `rotate(${parseInt(angleDeg)} ${s.POINTS.P1.X} ${s.POINTS.P1.Y})`);
  }

  if (s.PASSE_PIED === true) {
    sub_g
      .append("polygon")
      .attr("stroke", "red")
      .attr("stroke-width", s.POINTS.BORDER)
      .attr("fill", "transparent")
      .attr("points", `${s.POINTS.P1.X},${s.POINTS.P1.Y} ${s.POINTS.P2.X},${s.POINTS.P2.Y} ${s.POINTS.P3.X},${s.POINTS.P3.Y} ${s.POINTS.P4.X},${s.POINTS.P4.Y}`);
  }

  if (s.POSITIONSTELE === 2) {
    sub_g
      .append("line")
      .attr("x1", parseFloat(s.POINTS.P1.X) + 0.2)
      .attr("y1", parseFloat(s.POINTS.P1.Y) + 0.2)
      .attr("x2", parseFloat(s.POINTS.P2.X) - 0.2)
      .attr("y2", parseFloat(s.POINTS.P2.Y) + 0.2)
      .attr("stroke", "black")
      .attr("stroke-width", s.POINTS.BORDER);
  }

  if (s.POSITIONSTELE === 3) {
    sub_g
      .append("line")
      .attr("x1", parseFloat(s.POINTS.P3.X) - 0.2)
      .attr("y1", parseFloat(s.POINTS.P3.Y) - 0.2)
      .attr("x2", parseFloat(s.POINTS.P4.X) + 0.2)
      .attr("y2", parseFloat(s.POINTS.P4.Y) - 0.2)
      .attr("stroke", "black")
      .attr("stroke-width", s.POINTS.BORDER);
  }

  if (s.POSITIONSTELE === 4) {
    sub_g
      .append("line")
      .attr("x1", parseFloat(s.POINTS.P1.X) + 0.2)
      .attr("y1", parseFloat(s.POINTS.P1.Y) + 0.2)
      .attr("x2", parseFloat(s.POINTS.P4.X) + 0.2)
      .attr("y2", parseFloat(s.POINTS.P4.Y) - 0.2)
      .attr("stroke", "black")
      .attr("stroke-width", s.POINTS.BORDER);
  }

  if (s.POSITIONSTELE === 5) {
    sub_g
      .append("line")
      .attr("x1", parseFloat(s.POINTS.P2.X) - 0.2)
      .attr("y1", parseFloat(s.POINTS.P2.Y) + 0.2)
      .attr("x2", parseFloat(s.POINTS.P3.X) - 0.2)
      .attr("y2", parseFloat(s.POINTS.P3.Y) - 0.2)
      .attr("stroke", "black")
      .attr("stroke-width", s.POINTS.BORDER);
  }

  if (s.POSITIONSTELE === 6) {
    sub_g
      .append("polygon")
      .attr("stroke", "black")
      .attr("stroke-width", s.POINTS.BORDER)
      .attr("fill", "transparent")
      .attr(
        "points",
        `${parseFloat(s.POINTS.P1.X) + 0.2},${parseFloat(s.POINTS.P1.Y) + 0.2} ${parseFloat(s.POINTS.P2.X) - 0.2},${parseFloat(s.POINTS.P2.Y) + 0.2} ${parseFloat(s.POINTS.P3.X) - 0.2},${
          parseFloat(s.POINTS.P3.Y) - 0.2
        } ${parseFloat(s.POINTS.P4.X) + 0.2},${parseFloat(s.POINTS.P4.Y) - 0.2}`
      );
  }

  if (s.TEXT.X !== undefined) {
    if (s.TEXT.TRANSFORM !== undefined) {
      sub_g
        .append("text")
        .attr("x", parseFloat(s.TEXT.X))
        .attr("y", parseFloat(s.TEXT.Y))
        .attr("text-anchor", "middle")
        .attr("font-family", "Verdana")
        .attr("font-weight", "bold")
        .attr("lengthAdjust", "spacing")
        .attr("font-size", s.TEXT.SIZE)
        .attr("transform", "rotate(90 " + parseFloat(s.TEXT.X) + " " + parseFloat(s.TEXT.TRANSFORM.Y) + ")")
        .text(s.TEXT.TOMBE);
    } else {
      if (s.TEXT.TSPAN !== undefined) {
        var text = sub_g
          .append("text")
          .attr("x", parseFloat(s.TEXT.X))
          .attr("y", parseFloat(s.TEXT.Y))
          .attr("text-anchor", "middle")
          .attr("font-family", "Verdana")
          .attr("font-weight", "bold")
          .attr("lengthAdjust", "spacing")
          .attr("font-size", s.TEXT.SIZE);

        for (var i = 0; i < s.TEXT.TSPAN.Y.length; i++) {
          text.append("tspan").attr("x", parseFloat(s.TEXT.X)).attr("y", parseFloat(s.TEXT.TSPAN.Y[i])).text(s.TEXT.TSPAN.TEXT[i]);
        }
      } else {
        sub_g
          .append("text")
          .attr("x", parseFloat(s.TEXT.X))
          .attr("y", parseFloat(s.TEXT.Y))
          .attr("text-anchor", "middle")
          .attr("font-family", "Verdana")
          .attr("font-weight", "bold")
          .attr("lengthAdjust", "spacing")
          .attr("font-size", s.TEXT.SIZE)
          .text(s.TEXT.TOMBE);
      }
    }
  }
}
