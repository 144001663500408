import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

import InputRechercheConcession from "./../InputRechercheConcession";
import ListeConcessions from "./../ListeConcessions";
import { GetLieuById } from "../../Helpers/Get/Lieu";
import { CheckUserRight } from "./../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "../Global/DialogAccessRight";
import Opposition from "./../Global/OppositionTablette";

var _ = require("lodash");

const styles = {
  grid: {
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    paddingTop: 10,
  },
  paper: {
    padding: 16,
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 16,
  },
};

class CConcessions extends Component {
  constructor(props) {
    super(props);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.state = {
      REDIRECT: null,
      emplacement: "",
      EMPLACEMENT_OCCUPE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      droit_util_ajout_concession: false,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    if (this.props.id_lieu !== undefined) {
      GetLieuById(this.props.id_lieu).then((lieu) => {
        if (lieu.COCLEUNIK > 0) this.setState({ EMPLACEMENT_OCCUPE: true });

        this.setState({ emplacement: this._cree_chaine_emplacement(lieu) });
      });
    }
  };

  _cree_chaine_emplacement = (lieu) => {
    return _.filter([
      _.filter([lieu.EMPLACEMENT.TYPECAR, lieu.EMPLACEMENT.CARRE]).join(" "),
      _.filter([lieu.EMPLACEMENT.TYPERG, lieu.EMPLACEMENT.RANG]).join(" "),
      _.filter([lieu.EMPLACEMENT.TYPEFOS, lieu.EMPLACEMENT.TOMBE]).join(" "),
    ]).join(" - ");
  };

  _handle_click_liste_concessions = (event) => {
    event.preventDefault();

    if (this.props.id_lieu !== undefined) {
      this.setState({ REDIRECT: `/consulter/liste-concessions/${this.props.id_lieu}` });
    } else {
      this.setState({ REDIRECT: "/consulter/liste-concessions" });
    }
  };

  render() {
    console.log("RENDER CONCESSIONS");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return this.props.input_concession !== undefined && this.props.input_concession !== "ajout" ? (
      <ListeConcessions input_concession={this.props.input_concession} />
    ) : (
      <Grid container alignItems={this.props.id_lieu !== undefined ? "baseline" : "center"} justify="center" style={styles.grid}>
        {this.props.id_lieu !== undefined ? (
          <div>
            <Typography variant="h5" gutterBottom style={{ margin: 20 }}>
              Avant de créer une nouvelle concession, veuillez bien vérifier que celle-ci n'existe pas déjà, mais pas encore associée au plan
            </Typography>

            <Typography variant="h6" gutterBottom style={{ margin: 20, textAlign: "center" }}>
              {this.state.emplacement}
            </Typography>
          </div>
        ) : null}

        <Grid item xs={11} style={styles.grid_item}>
          <Paper style={styles.paper} elevation={0}>
            <Typography variant="h6" gutterBottom>
              {`Rechercher une concession${this.props.input_concession === "ajout" ? " non localisée" : ""}`}
            </Typography>

            <InputRechercheConcession input_concession={this.props.input_concession} id_lieu={this.props.id_lieu} />

            {this.props.input_concession !== undefined && this.state.EMPLACEMENT_OCCUPE === false ? (
              <div style={styles.div}>
                <Typography variant="body2">
                  <i>Vous pouvez également taper le début du nom et cliquer sur le bouton RECHERCHER</i>
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    CheckUserRight(this.state.ID_UTIL, "CONCESS", "DROITAJOUT").then((asRight) => {
                      if (asRight) {
                        this.props.input_concession === "ajout"
                          ? this.setState({
                              REDIRECT: `/modifier/ajout-concession/${this.props.id_lieu}`,
                            })
                          : this.setState({ REDIRECT: "/modifier/concession" });
                      } else {
                        this.setState({ droit_util_ajout_concession: true });
                      }
                    });
                  }}
                >
                  Ajouter une concession
                </Button>
              </div>
            ) : null}

            {this.state.EMPLACEMENT_OCCUPE === false ? (
              <div style={styles.div}>
                {this.props.input_concession === undefined ? (
                  <Typography variant="body2">
                    <i>Vous pouvez également taper le début du nom et cliquer sur le bouton RECHERCHER (double cliquer)</i>
                  </Typography>
                ) : (
                  <Typography variant="body2"></Typography>
                )}

                <Button variant="contained" color="primary" onClick={this._handle_click_liste_concessions}>
                  {`Afficher toutes les concessions${this.props.input_concession === "ajout" ? " non localisées" : ""}`}
                </Button>
              </div>
            ) : null}

            <DialogAccessRight state={this.state.droit_util_ajout_concession} onChange={(value) => this.setState({ droit_util_ajout_concession: value })} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(CConcessions);
