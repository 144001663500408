import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetIntervenDefuntById } from "../Get/IntervenDefunt";

var _ = require("lodash");

export async function UpdateIntervenDefunt_MDF(headers) {
  const _intervenDefuntCount = await GetApiData("INTERVEN_DEFUNTCount", headers);

  if (_intervenDefuntCount.Message !== undefined) {
    return _intervenDefuntCount;
  }

  const promise = new Promise((res, rej) => {
    if (_intervenDefuntCount === 0) res("");

    addTableWithProgress("INTERVEN_DEFUNT", _intervenDefuntCount, 2500, headers, (getProgress, getIntervenDefunt) => {
      if (getIntervenDefunt.Message !== undefined) {
        return res(getIntervenDefunt);
      }

      _.forEach(getIntervenDefunt, (interven_defunt) => {
        const intervenDefunt_a_mettre_a_jour = {
          IDINTERVEN_DEFUNT: interven_defunt.IDINTERVEN_DEFUNT,
          D0CLEUNIK: interven_defunt.D0CLEUNIK,
          I0CLEUNIK: interven_defunt.I0CLEUNIK,
          IDMOUVEMENT: interven_defunt.IDMOUVEMENT,
        };

        GetIntervenDefuntById(intervenDefunt_a_mettre_a_jour.IDINTERVEN_DEFUNT).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.INTERVEN_DEFUNT.add(intervenDefunt_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(intervenDefunt_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.INTERVEN_DEFUNT.put(intervenDefunt_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _intervenDefuntCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_intervenDefuntCount} interventions lié à un défunt`);

    return val;
  });
}
