import Dexie from "dexie";
import db from "../Db";
import { GetUtilById } from "./../Get/Util";

var DeleteUtilByUtcleunik = Dexie.async(function* (UTCLEUNIK) {
  yield db.open();

  GetUtilById(UTCLEUNIK).then((gens) => {
    if (gens !== undefined) db.UTIL.delete(gens.id);
  });
});

export default DeleteUtilByUtcleunik;
