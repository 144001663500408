import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Home } from "@material-ui/icons";

class HomeButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      REDIRECT: null,
    };
  }

  componentDidMount = () => {
    document.addEventListener("keydown", (event) => {
      if (event.shiftKey && event.code === "Digit0") {
        this.setState({ REDIRECT: "/" });
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.REDIRECT !== null) {
      this.setState({ REDIRECT: null });
    }
  };

  render() {
    return (
      <div>
        {this.state.REDIRECT !== null ? <Redirect to={this.state.REDIRECT} push /> : null}

        {this.props.location.pathname !== "/" ? (
          <IconButton aria-label="Accueil" onClick={() => this.setState({ REDIRECT: "/" })}>
            <Home />
          </IconButton>
        ) : null}
      </div>
    );
  }
}

export default withRouter(HomeButton);
