import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Paper, Typography, TextField, MenuItem, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { GetAllCimetier } from "./../../Helpers/Get/Cimetier";
import { GetPlanById } from "../../Helpers/Get/Plan";
import { GetLieuByCimel, GetLieuByCocleunik } from "./../../Helpers/Get/Lieu";
import { GetSquare, GetRank, GetTomb, GetLieuFromEmpl } from "../../Helpers/Function";
import { GetConcessById } from "../../Helpers/Get/Concess";
import { Close } from "@material-ui/icons";
import { GetParam } from "./../../Helpers/Get/Param";
import Opposition from "./../Global/OppositionTablette";
import { withCookies } from "react-cookie";

var _ = require("lodash");

const styles = {
  grid: {
    flex: 1,
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    padding: 16,
    textAlign: "center",
  },
  menuitem: {
    minHeight: 36,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

class MPhotos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cimetier: [],
      selected_cimetier: "",
      lieu: {
        id: 0,
        LICLEUNIK: 0,
        EMPLACEMENT: {
          TYPECAR: "Carré",
          CARRE: "",
          TYPERG: "Rang",
          RANG: "",
          TYPEFOS: "Tombe",
          TOMBE: "",
          ETAT: 0,
          GELE: false,
          H1: 0,
          V1: 0,
          H2: 0,
          V2: 0,
          H3: 0,
          V3: 0,
          H4: 0,
          V4: 0,
        },
        COCLEUNIK: 0,
        CIMEL: 0,
        TOMBEL: 0,
        NOMBREL: 0,
        DUREEL: "",
        POSNUM: 0,
        SITUATION: 0,
        SITUATION_ALL_1: 0,
        SITUATION_ALL_2: 0,
        SITUATION_ALL_3: 0,
        SITUATION_ALL_4: 0,
        TAILLEPOLICEDULIEU: 0,
        AFFMULTILIGNE: 0,
      },
      carre: [],
      carreDesactive: false,
      rang: [],
      rangDesactive: false,
      tombe: [],
      img_plan: null,
      redirect: null,
      emplacement_libre_dialog: false,
      emplacement_multiple_dialog: false,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    if (this.props.id_concession !== undefined) {
      GetLieuByCocleunik(parseInt(this.props.id_concession)).then((lieu) => {
        GetLieuByCimel(lieu.CIMEL).then((alllieu) => {
          alllieu = _.filter(alllieu, (l) => {
            return l.EMPLACEMENT.ETAT !== 1;
          });

          let square = GetSquare(alllieu);
          let rank = GetRank(alllieu, lieu.EMPLACEMENT.CARRE.trim());
          let tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE.trim(), lieu.EMPLACEMENT.RANG.trim());

          GetAllCimetier(1).then((cimetier) => {
            let thiscimetier = _.filter(cimetier, (c) => {
              return c.NUMCIME === lieu.CIMEL;
            });

            GetPlanById(thiscimetier[0].NUMPLAN).then((plan) => {
              this.setState({ cimetier: cimetier, img_plan: plan.IMAGEPL });
            });
          });

          this.setState({ lieu: lieu, carre: square, rang: rank, tombe: tomb, selected_cimetier: lieu.CIMEL });
        });
      });
    } else {
      GetAllCimetier(1).then((cimetier) => {
        GetPlanById(cimetier[0].NUMPLAN).then((plan) => {
          this.setState({ cimetier: cimetier, img_plan: plan.IMAGEPL });
        });
      });
    }

    GetParam().then((param) => {
      let LIEU = this.state.lieu;
      if (param.CARREDEFLT !== "") LIEU.EMPLACEMENT.TYPECAR = param.CARREDEFLT;
      if (param.RANGDEFLT !== "") LIEU.EMPLACEMENT.TYPERG = param.RANGDEFLT;
      if (param.TOMBEDEFLT !== "") LIEU.EMPLACEMENT.TYPEFOS = param.TOMBEDEFLT;

      this.setState({ lieu: LIEU });
    });
  };

  _handle_change_combo_cimetier = (event) => {
    GetLieuByCimel(event.target.value).then((lieu) => {
      lieu = _.filter(lieu, (l) => {
        return l.EMPLACEMENT.ETAT !== 1;
      });

      let square = GetSquare(lieu);

      GetParam().then((param) => {
        let LIEU = this.state.lieu;
        if (param.CARREDEFLT !== "") LIEU.EMPLACEMENT.TYPECAR = param.CARREDEFLT;
        if (param.RANGDEFLT !== "") LIEU.EMPLACEMENT.TYPERG = param.RANGDEFLT;
        if (param.TOMBEDEFLT !== "") LIEU.EMPLACEMENT.TYPEFOS = param.TOMBEDEFLT;
        LIEU.EMPLACEMENT.CARRE = "";
        LIEU.EMPLACEMENT.RANG = "";
        LIEU.EMPLACEMENT.TOMBE = "";
        LIEU.CIMEL = event.target.value;

        GetAllCimetier(1).then((cimetier) => {
          cimetier = _.filter(cimetier, (c) => {
            return c.NUMCIME === LIEU.CIMEL;
          });

          GetPlanById(cimetier[0].NUMPLAN).then((plan) => {
            this.setState({ img_plan: plan.IMAGEPL });
          });
        });

        this.setState(
          {
            selected_cimetier: event.target.value,
            lieu: LIEU,
            carre: square,
            carreDesactive: false,
            rang: [],
            tombe: [],
          },
          () => {
            if (square.length === 1 && square[0] === " ") {
              GetLieuByCimel(this.state.lieu.CIMEL, false).then((alllieu) => {
                let rank = GetRank(alllieu, square[0].trim());

                let LIEU = this.state.lieu;
                LIEU.EMPLACEMENT.CARRE = square[0];
                LIEU.EMPLACEMENT.RANG = "";
                LIEU.EMPLACEMENT.TOMBE = "";

                this.setState(
                  {
                    LIEU: LIEU,
                    carreDesactive: true,
                    rangDesactive: false,
                    rang: rank,
                    tombe: [],
                  },
                  () => {
                    if (rank.length === 1 && rank[0] === " ") {
                      let tomb = GetTomb(alllieu, this.state.lieu.EMPLACEMENT.CARRE.trim(), rank[0].trim());

                      let LIEU = this.state.lieu;
                      LIEU.EMPLACEMENT.RANG = rank[0];
                      LIEU.EMPLACEMENT.TOMBE = "";

                      this.setState({
                        LIEU: LIEU,
                        rangDesactive: true,
                        tombe: tomb,
                      });
                    }
                  }
                );
              });
            }
          }
        );
      });
    });
  };

  _handle_change_combo_square = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.lieu.CIMEL).then((alllieu) => {
      alllieu = _.filter(alllieu, (l) => {
        return l.EMPLACEMENT.ETAT !== 1;
      });

      let rank = GetRank(alllieu, event.target.value.trim());

      let LIEU = this.state.lieu;
      LIEU.EMPLACEMENT.CARRE = event.target.value;
      LIEU.EMPLACEMENT.RANG = "";
      LIEU.EMPLACEMENT.TOMBE = "";

      this.setState(
        {
          lieu: LIEU,
          rang: rank,
          rangDesactive: false,
          tombe: [],
        },
        () => {
          if (rank.length === 1 && rank[0] === " ") {
            let tomb = GetTomb(alllieu, this.state.lieu.EMPLACEMENT.CARRE.trim(), rank[0].trim());

            let LIEU = this.state.lieu;
            LIEU.EMPLACEMENT.RANG = rank[0];
            LIEU.EMPLACEMENT.TOMBE = "";

            this.setState({
              lieu: LIEU,
              rangDesactive: true,
              tombe: tomb,
            });
          }
        }
      );
    });
  };

  _handle_change_combo_rank = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.lieu.CIMEL).then((alllieu) => {
      alllieu = _.filter(alllieu, (l) => {
        return l.EMPLACEMENT.ETAT !== 1;
      });

      let tomb = GetTomb(alllieu, this.state.lieu.EMPLACEMENT.CARRE.trim(), event.target.value.trim());

      let LIEU = this.state.lieu;
      LIEU.EMPLACEMENT.RANG = event.target.value;
      LIEU.EMPLACEMENT.TOMBE = "";

      this.setState({
        lieu: LIEU,
        tombe: tomb,
      });
    });
  };

  _handle_change_combo_tomb = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.lieu.CIMEL).then((alllieu) => {
      alllieu = _.filter(alllieu, (l) => {
        return l.EMPLACEMENT.ETAT !== 1;
      });

      let LIEU = GetLieuFromEmpl(alllieu, this.state.lieu.EMPLACEMENT.CARRE.trim(), this.state.lieu.EMPLACEMENT.RANG.trim(), event.target.value.trim());

      if (LIEU.length > 1) {
        this.setState({ emplacement_multiple_dialog: true });
      } else {
        if (LIEU[0].EMPLACEMENT.CARRE === "") {
          LIEU[0].EMPLACEMENT.CARRE = " ";
        }

        if (LIEU[0].EMPLACEMENT.RANG === "") {
          LIEU[0].EMPLACEMENT.RANG = " ";
        }

        if (LIEU[0].EMPLACEMENT.TOMBE === "") {
          LIEU[0].EMPLACEMENT.TOMBE = " ";
        }

        this.setState({
          lieu: LIEU[0],
        });
      }
    });
  };

  _handle_submit_selection_concess = (event) => {
    event.preventDefault();

    if (this.state.lieu.COCLEUNIK !== 0) {
      GetConcessById(this.state.lieu.COCLEUNIK).then((concession) => {
        if (concession !== undefined) {
          this.setState({
            redirect: `/modifier/photographies/${concession.COCLEUNIK}`,
          });
        } else {
          this.setState({ emplacement_libre_dialog: true });
        }
      });
    }
  };

  _handle_click_selection_plan_concess = (event) => {
    event.preventDefault();

    let redirect = "/consulter/cartographie/photos";
    if (this.state.lieu.CIMEL !== 0) {
      GetAllCimetier(1).then((cimetier) => {
        let cime = _.filter(cimetier, (c) => {
          return c.NUMCIME === this.state.lieu.CIMEL;
        })[0];

        let filtered = _.filter(cimetier, (c) => {
          return c.NUMPLAN === cime.NUMPLAN && c.IMPRIMECOLUMB === 0;
        })[0];

        redirect += `/${filtered.NUMCIME}`;

        if (this.state.lieu.LICLEUNIK !== 0) {
          redirect += `/${this.state.lieu.LICLEUNIK}`;
        }

        this.setState({
          redirect: redirect,
        });
      });
    } else {
      this.setState({
        redirect: redirect,
      });
    }
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Grid container alignItems="center" justify="center" style={styles.grid}>
        <Grid item xs={11} sm={11} md={8}>
          <Paper style={styles.paper}>
            <Typography>Sélectionnez l'emplacement sur lequel vous souhaitez ajouter une photographie</Typography>

            <br />
            <br />
            <br />

            {this.state.img_plan !== null && this.state.img_plan !== "" ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography color="primary" style={{ marginBottom: 8 }}>
                    Sélection par la carte
                  </Typography>

                  <img
                    alt="Sélection par le plan"
                    src={window.URL.createObjectURL(this.state.img_plan)}
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={this._handle_click_selection_plan_concess}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography color="primary" style={{ marginBottom: 8 }}>
                    Sélection par la liste
                  </Typography>

                  <form onSubmit={this._handle_submit_selection_concess}>
                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      label="Cimetière"
                      value={this.state.selected_cimetier}
                      onChange={this._handle_change_combo_cimetier}
                    >
                      {this.state.cimetier.map((cimetier, index) => (
                        <MenuItem key={index} value={cimetier.NUMCIME}>
                          {cimetier.NOMCIME}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      disabled={this.state.carreDesactive}
                      label={this.state.lieu.EMPLACEMENT.TYPECAR}
                      value={this.state.lieu.EMPLACEMENT.CARRE}
                      onChange={this._handle_change_combo_square}
                    >
                      {this.state.carre.map((square, index) => (
                        <MenuItem key={index} value={square} style={styles.menuitem}>
                          {square}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      disabled={this.state.rangDesactive}
                      label={this.state.lieu.EMPLACEMENT.TYPERG}
                      value={this.state.lieu.EMPLACEMENT.RANG}
                      onChange={this._handle_change_combo_rank}
                    >
                      {this.state.rang.map((rank, index) => (
                        <MenuItem key={index} value={rank} style={styles.menuitem}>
                          {rank}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      label={this.state.lieu.EMPLACEMENT.TYPEFOS}
                      value={this.state.lieu.EMPLACEMENT.TOMBE}
                      onChange={this._handle_change_combo_tomb}
                    >
                      {this.state.tombe.map((tomb, index) => (
                        <MenuItem key={index} value={tomb} style={styles.menuitem}>
                          {tomb}
                        </MenuItem>
                      ))}
                    </TextField>

                    <Button fullWidth variant="contained" color="primary" type="submit" style={{ marginTop: 16 }}>
                      Ajouter des photographies
                    </Button>
                  </form>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
                <Grid item xs={12} sm={6}>
                  <Typography color="primary" style={{ marginBottom: 8 }}>
                    Sélection par la liste
                  </Typography>

                  <form onSubmit={this._handle_submit_selection_concess}>
                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      label="Cimetière"
                      value={this.state.selected_cimetier}
                      onChange={this._handle_change_combo_cimetier}
                    >
                      {this.state.cimetier.map((cimetier, index) => (
                        <MenuItem key={index} value={cimetier.NUMCIME}>
                          {cimetier.NOMCIME}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      label={this.state.lieu.EMPLACEMENT.TYPECAR}
                      value={this.state.lieu.EMPLACEMENT.CARRE}
                      onChange={this._handle_change_combo_square}
                    >
                      {this.state.carre.map((square, index) => (
                        <MenuItem key={index} value={square} style={styles.menuitem}>
                          {square}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      label={this.state.lieu.EMPLACEMENT.TYPERG}
                      value={this.state.lieu.EMPLACEMENT.RANG}
                      onChange={this._handle_change_combo_rank}
                    >
                      {this.state.rang.map((rank, index) => (
                        <MenuItem key={index} value={rank} style={styles.menuitem}>
                          {rank}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      style={{ textAlign: "initial", marginTop: 4 }}
                      label={this.state.lieu.EMPLACEMENT.TYPEFOS}
                      value={this.state.lieu.EMPLACEMENT.TOMBE}
                      onChange={this._handle_change_combo_tomb}
                    >
                      {this.state.tombe.map((tomb, index) => (
                        <MenuItem key={index} value={tomb} style={styles.menuitem}>
                          {tomb}
                        </MenuItem>
                      ))}
                    </TextField>

                    <Button fullWidth variant="contained" color="primary" type="submit" style={{ marginTop: 16 }}>
                      Ajouter des photographies
                    </Button>
                  </form>
                </Grid>
              </Grid>
            )}
          </Paper>

          <Dialog onClose={() => this.setState({ emplacement_libre_dialog: false })} open={this.state.emplacement_libre_dialog}>
            <DialogTitle onClose={() => this.setState({ emplacement_libre_dialog: false })}>
              <Typography variant="h6" component="p">
                Attention
              </Typography>

              <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ emplacement_libre_dialog: false })}>
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Typography gutterBottom>Impossible d'ajouter des photographies sur un emplacement libre.</Typography>
            </DialogContent>
          </Dialog>

          <Dialog onClose={() => this.setState({ emplacement_multiple_dialog: false })} open={this.state.emplacement_multiple_dialog}>
            <DialogTitle onClose={() => this.setState({ emplacement_multiple_dialog: false })}>
              <Typography variant="h6" component="p">
                Attention
              </Typography>

              <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ emplacement_multiple_dialog: false })}>
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Typography gutterBottom>Cette numérotation correspond à plusieurs emplacements. Veuillez sélectionner l'emplacement par la cartographie</Typography>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(MPhotos);
