import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetConcaireById } from "../Get/Concaire";

var _ = require("lodash");

export async function UpdateConcaire_MDF(headers) {
  const _concaireCount = await GetApiData("CONCAIRECount", headers);

  if (_concaireCount.Message !== undefined) {
    return _concaireCount;
  }

  const promise = new Promise((res, rej) => {
    if (_concaireCount === 0) res("");

    addTableWithProgress("CONCAIRE", _concaireCount, 10000, headers, (getProgress, getConcaire) => {
      if (getConcaire.Message !== undefined) {
        return res(getConcaire);
      }

      _.forEach(getConcaire, (concaire) => {
        const concaire_a_mettre_a_jour = {
          C0CLEUNIK: concaire.C0CLEUNIK,
          NOM: concaire.NOM.trim(),
          PRENOM: concaire.PRENOM.trim(),
          NOMJEUNE: concaire.NOMJEUNE.trim(),
          CIVILITE: concaire.CIVILITE,
          ADR_NUMERO: concaire.ADR_NUMERO,
          ADR_BISTER: concaire.ADR_BISTER,
          ADRESSE: concaire.ADRESSE,
          ADR_COMP: concaire.ADR_COMP,
          CODEPO: concaire.CODEPO,
          VILLE: concaire.VILLE,
          TEL: concaire.TEL,
          PORTABLE: concaire.PORTABLE,
          ADRESSEMAIL: concaire.ADRESSEMAIL,
          PARENTE: concaire.PARENTE,
          COCLEUNIK: concaire.COCLEUNIK,
          DCD: concaire.DCD,
          TYPEINTERLO: {
            CONCESSIONNAIRE: concaire.CONCESSIONNAIRE,
            COCONCESSIONNAIRE: concaire.COCONCESSIONNAIRE,
            AYANTDROIT: concaire.AYANTDROIT,
            INHUMABLE: concaire.INHUMABLE,
            AUTRE: concaire.AUTRE,
            INDESIRABLE: concaire.INDESIRABLE,
            DONATAIRE_LEGATAIRE: concaire.DONATAIRE_LEGATAIRE,
            EXECUTEUR_TESTAMENTAIRE: concaire.EXECUTEUR_TESTAMENTAIRE,
          },
        };

        GetConcaireById(concaire_a_mettre_a_jour.C0CLEUNIK).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.CONCAIRE.add(concaire_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(concaire_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.CONCAIRE.put(concaire_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _concaireCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_concaireCount} interlocuteurs`);

    return val;
  });
}
