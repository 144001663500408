import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Grid, Paper, TextField, MenuItem, FormControl, FormGroup, FormControlLabel, Checkbox, Button } from "@material-ui/core";
import { withCookies } from "react-cookie";

import { GetClient } from "../../../Helpers/Get/Client";
import { GetConcaireById } from "../../../Helpers/Get/Concaire";
import { GetConcessById } from "../../../Helpers/Get/Concess";
import { GetTemporaireNonEnvoye } from "./../../../Helpers/Get/Temporaire";

import { IsOracle } from "../../../Helpers/Test/IsOracle";
import { differenceObject } from "../../../Helpers/Test/Difference";

import AddConcaire from "../../../Helpers/Add/Concaire";
import UpdateConcaire from "../../../Helpers/Update/Concaire";
import AddTemporaire from "./../../../Helpers/Add/Temporaire";
import UpdateTemporaire from "./../../../Helpers/Update/Temporaire";

import { handleError, CreeTemporaire } from "./../../../Helpers/Function";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  submit_div: {
    alignItems: "flex-end",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    marginTop: 8,
  },
};

const initialState = {
  CONCAIRE: {
    id: 0,
    C0CLEUNIK: 0,
    NOM: "",
    PRENOM: "",
    NOMJEUNE: "",
    CIVILITE: "",
    ADR_NUMERO: "",
    ADR_BISTER: "",
    ADRESSE: "",
    ADR_COMP: "",
    CODEPO: "",
    VILLE: "",
    TEL: "",
    PORTABLE: "",
    ADRESSEMAIL: "",
    PARENTE: "",
    COCLEUNIK: 0,
    DCD: 0,
    TYPEINTERLO: {
      CONCESSIONNAIRE: 0,
      COCONCESSIONNAIRE: 0,
      AYANTDROIT: 0,
      INHUMABLE: 0,
      AUTRE: 0,
      INDESIRABLE: 0,
      DONATAIRE_LEGATAIRE: 0,
      EXECUTEUR_TESTAMENTAIRE: 0,
    },
  },
};

class MCInterlocuteur extends Component {
  constructor(props) {
    super(props);

    this.IDCLIENT = 0;
    this.ID_TABLETTE = "";
    this.ID_UTIL = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

    this.famille_ref = React.createRef();
    this.ville_ref = React.createRef();

    this.state = {
      IS_ORACLE: false,
      CONCESS: "",
      CONCAIRE_SAUVEGARDE: { ...initialState.CONCAIRE },
      CONCAIRE: { ...initialState.CONCAIRE },
      REFERENT: false,
      ETAIT_REFERENT: false,
      REDIRECT: null,
      TemporaireNonEnvoye: null,
      estTemporaireNonEnvoye: false,
      disabledForm: false,
      TYPEINTERLO_COMBO: {},
    };

    if (this.props.id_interlocuteur !== undefined) {
      GetConcaireById(this.props.id_interlocuteur).then((concaire) => {
        let typeinterlo = { ...concaire.TYPEINTERLO };
        typeinterlo = _.omit(typeinterlo, ["INHUMABLE", "INDESIRABLE", "DONATAIRE_LEGATAIRE", "EXECUTEUR_TESTAMENTAIRE"]);

        this.setState({
          CONCAIRE_SAUVEGARDE: concaire,
          TYPEINTERLO_COMBO: typeinterlo,
        });
      });
    }
  }

  componentDidMount = () => {
    let CONCAIRE = this.state.CONCAIRE;
    CONCAIRE.COCLEUNIK = parseInt(this.props.id_concession);

    let CONCAIRE_SAUVEGARDE = this.state.CONCAIRE_SAUVEGARDE;
    CONCAIRE_SAUVEGARDE.COCLEUNIK = parseInt(this.props.id_concession);

    this.setState({
      CONCAIRE,
      CONCAIRE_SAUVEGARDE,
    });

    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    GetClient().then((client) => {
      this.IDCLIENT = client.IDCLIENT;
      this.ID_TABLETTE = client.ID_TABLETTE;
    });

    GetConcessById(this.props.id_concession).then((concess) => {
      this.setState({ CONCESS: concess });
    });

    if (this.props.id_interlocuteur !== undefined) {
      GetConcaireById(this.props.id_interlocuteur).then((concaire) => {
        let typeinterlo = { ...concaire.TYPEINTERLO };
        typeinterlo = _.omit(typeinterlo, ["INHUMABLE", "INDESIRABLE", "DONATAIRE_LEGATAIRE", "EXECUTEUR_TESTAMENTAIRE"]);

        let REFERENT = false;
        if (this.state.CONCESS.INTERLOCUTEUR_C0CLEUNIK === concaire.C0CLEUNIK) {
          REFERENT = true;
        }

        GetTemporaireNonEnvoye("CONCAIRE", "C0CLEUNIK", concaire.C0CLEUNIK).then((temp) => {
          if (temp !== undefined)
            this.setState({
              TemporaireNonEnvoye: temp.TYPE_D_ACTION,
              estTemporaireNonEnvoye: true,
            });
        });

        this.setState({
          CONCAIRE: concaire,
          TYPEINTERLO_COMBO: typeinterlo,
          REFERENT: REFERENT,
          ETAIT_REFERENT: REFERENT,
        });
      });
    }
  };

  _AjoutTemporaire = (temporaire) => {
    AddTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}/${temporaire.TYPE_D_ENTITE}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourTemporaire = (temporaire) => {
    UpdateTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}/${temporaire.TYPE_D_ENTITE}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _AjoutConcaire = (CONCAIRE) => {
    CONCAIRE = _.omit(CONCAIRE, ["id"]);

    const ajout_concaire = AddConcaire(CONCAIRE);
    ajout_concaire
      .then((_id) => {
        _.set(CONCAIRE, "id", _id);
        _.set(CONCAIRE, "C0CLEUNIK", -_id);

        const modif_c0cleunik = UpdateConcaire(CONCAIRE);
        modif_c0cleunik
          .then(() => {
            let identite = `${CONCAIRE.NOM} ${CONCAIRE.PRENOM}`.trim();

            if (this.state.IS_ORACLE) {
              for (var key in CONCAIRE) {
                if (key === "ADRESSEMAIL") {
                  CONCAIRE.ADRMAIL = CONCAIRE[key];
                  delete CONCAIRE[key];
                }
              }

              CONCAIRE.TYPEINTERLO = `${CONCAIRE.TYPEINTERLO.CONCESSIONNAIRE}${CONCAIRE.TYPEINTERLO.COCONCESSIONNAIRE}${CONCAIRE.TYPEINTERLO.AYANTDROIT}${CONCAIRE.TYPEINTERLO.INHUMABLE}${CONCAIRE.TYPEINTERLO.AUTRE}${CONCAIRE.TYPEINTERLO.DONATAIRE_LEGATAIRE}${CONCAIRE.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE}`;
            } else {
              CONCAIRE = _.omit(CONCAIRE, ["ADR_NUMERO", "ADR_BISTER", "ADR_COMP"]);

              CONCAIRE.TYPEINTERLO = `${CONCAIRE.TYPEINTERLO.CONCESSIONNAIRE}${CONCAIRE.TYPEINTERLO.COCONCESSIONNAIRE}${CONCAIRE.TYPEINTERLO.AYANTDROIT}${CONCAIRE.TYPEINTERLO.INHUMABLE}${CONCAIRE.TYPEINTERLO.AUTRE}${CONCAIRE.TYPEINTERLO.INDESIRABLE}`;
            }

            const temporaire = CreeTemporaire(this.IDCLIENT, this.ID_TABLETTE, this.ID_UTIL, `Ajout de l'interlocuteur ${identite}`, "CONCAIRE", 1, CONCAIRE, null);

            this._AjoutTemporaire(temporaire);
          })
          .catch((Err) => handleError(Err));
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourConcaire = (CONCAIRE, CONCAIRE_TEMPORAIRE) => {
    const modifie_concaire = UpdateConcaire(CONCAIRE);
    modifie_concaire
      .then(() => {
        GetTemporaireNonEnvoye("CONCAIRE", "C0CLEUNIK", CONCAIRE.C0CLEUNIK).then((temporaire) => {
          if (this.state.IS_ORACLE) {
            for (var key in CONCAIRE_TEMPORAIRE) {
              if (key === "ADRESSEMAIL") {
                CONCAIRE_TEMPORAIRE.ADRMAIL = CONCAIRE_TEMPORAIRE[key];
                delete CONCAIRE_TEMPORAIRE[key];
              }
            }
          }

          let identite = `${CONCAIRE.NOM} ${CONCAIRE.PRENOM}`.trim();

          if (temporaire !== undefined) {
            var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, CONCAIRE_TEMPORAIRE);

            temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;
            temporaire.TITRE = `Modification de l'interlocuteur ${identite}`;

            this._MiseAJourTemporaire(temporaire);
          } else {
            const temporaire = CreeTemporaire(
              this.IDCLIENT,
              this.ID_TABLETTE,
              this.ID_UTIL,
              `Modification de l'interlocuteur ${identite}`,
              "CONCAIRE",
              2,
              CONCAIRE_TEMPORAIRE,
              this.state.CONCAIRE_SAUVEGARDE
            );

            this._AjoutTemporaire(temporaire);
          }
        });
      })
      .catch((Err) => handleError(Err));
  };

  _handle_submit_form_concaire = (event) => {
    event.preventDefault();

    if (this.state.disabledForm) return;

    this.setState({ disabledForm: true }, () => {
      let CONCAIRE = this.state.CONCAIRE;

      if (this.props.id_interlocuteur !== undefined) {
        let changed = !_.isEqual(CONCAIRE, this.state.CONCAIRE_SAUVEGARDE);

        if (changed === true) {
          let diff = differenceObject(CONCAIRE, this.state.CONCAIRE_SAUVEGARDE);

          diff = _.assign(
            {
              id: CONCAIRE.id,
              C0CLEUNIK: CONCAIRE.C0CLEUNIK,
              COCLEUNIK: CONCAIRE.COCLEUNIK,
            },
            diff
          );

          this._MiseAJourConcaire(CONCAIRE, diff);
        }
      } else {
        this._AjoutConcaire(CONCAIRE);
      }
    });
  };

  // _cree_chaine_modif = (diff) => {
  //   let champs = [];

  //   _.forOwn(diff, function (value, key) {
  //     champs.push(key);
  //   });

  //   return champs.join(",");
  // };

  _handle_change_concaire = (champ, refInput) => (event) => {
    let CONCAIRE = this.state.CONCAIRE;

    let cursor;
    if (event.target !== undefined) cursor = event.target.selectionStart;

    switch (champ) {
      case "NOM":
      case "NOMJEUNE":
      case "VILLE":
        CONCAIRE[champ] = event.target.value.toUpperCase();
        break;
      case "PRENOM":
        CONCAIRE[champ] = event.target.value.replace(/(?:^|-|\s)\S/g, (l) => l.toUpperCase());
        break;
      case "TEL":
      case "PORTABLE":
        if (event.target.value.length === 10 && !event.target.value.includes(" ")) {
          var match = /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/g.exec(event.target.value);

          CONCAIRE[champ] = `${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
        } else {
          CONCAIRE[champ] = event.target.value.slice(0, 14);
        }
        break;
      case "TYPEINTERLO":
        CONCAIRE[champ] = _.mapValues(CONCAIRE[champ], () => 0);
        CONCAIRE[champ][event.target.value] = 1;
        break;
      default:
        CONCAIRE[champ] = event.target.value;
        break;
    }

    this.setState({ CONCAIRE: CONCAIRE, disabledForm: false }, () => {
      if (refInput && refInput.current != null) refInput.current.selectionEnd = cursor;
    });
  };

  render() {
    console.log("RENDER M_INTERLOCUTEUR");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <form onSubmit={this._handle_submit_form_concaire} style={styles.form}>
              <TextField
                select
                fullWidth
                disabled={this.props.id_interlocuteur !== undefined && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                margin="normal"
                label="Civilité"
                value={this.state.CONCAIRE.CIVILITE}
                onChange={this._handle_change_concaire("CIVILITE")}
              >
                <MenuItem value={1}>Monsieur</MenuItem>
                <MenuItem value={2}>Madame</MenuItem>
                {this.props.id_interlocuteur !== undefined ? <MenuItem value={3}>Mademoiselle</MenuItem> : null}
                <MenuItem value={4}>{this.state.IS_ORACLE ? `Héritier(s)` : "Enfant"}</MenuItem>
                <MenuItem value={5}>Autre</MenuItem>
              </TextField>

              <TextField
                inputRef={this.famille_ref}
                required
                fullWidth
                disabled={this.props.id_interlocuteur !== undefined && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                margin="normal"
                autoCapitalize="off"
                autoCorrect="off"
                label="Nom"
                value={this.state.CONCAIRE.NOM}
                onChange={this._handle_change_concaire("NOM", this.famille_ref)}
              />

              {this.state.CONCAIRE.CIVILITE === 2 || this.state.CONCAIRE.CIVILITE === 3 ? (
                <TextField
                  fullWidth
                  disabled={this.props.id_interlocuteur !== undefined && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                  margin="normal"
                  autoCapitalize="off"
                  autoCorrect="off"
                  label="Nom de naissance"
                  value={this.state.CONCAIRE.NOMJEUNE}
                  onChange={this._handle_change_concaire("NOMJEUNE")}
                />
              ) : null}

              <TextField
                fullWidth
                disabled={this.props.id_interlocuteur !== undefined && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Prénom"
                value={this.state.CONCAIRE.PRENOM}
                onChange={this._handle_change_concaire("PRENOM")}
              />

              {this.state.IS_ORACLE ? (
                <TextField
                  fullWidth
                  margin="normal"
                  autoCapitalize="on"
                  autoCorrect="off"
                  label="Numéro de voie"
                  value={this.state.CONCAIRE.ADR_NUMERO}
                  onChange={this._handle_change_concaire("ADR_NUMERO")}
                />
              ) : null}

              {this.state.IS_ORACLE ? (
                <TextField
                  fullWidth
                  margin="normal"
                  autoCapitalize="on"
                  autoCorrect="off"
                  label="Bis / Ter"
                  value={this.state.CONCAIRE.ADR_BISTER}
                  onChange={this._handle_change_concaire("ADR_BISTER")}
                />
              ) : null}

              <TextField fullWidth margin="normal" autoCapitalize="on" autoCorrect="off" label="Adresse" value={this.state.CONCAIRE.ADRESSE} onChange={this._handle_change_concaire("ADRESSE")} />

              {this.state.IS_ORACLE ? (
                <TextField
                  fullWidth
                  margin="normal"
                  autoCapitalize="on"
                  autoCorrect="off"
                  label="Complément d'adresse"
                  value={this.state.CONCAIRE.ADR_COMP}
                  onChange={this._handle_change_concaire("ADR_COMP")}
                />
              ) : null}

              <TextField
                fullWidth
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Code postal"
                inputProps={{
                  maxLength: 5,
                }}
                value={this.state.CONCAIRE.CODEPO}
                onChange={this._handle_change_concaire("CODEPO")}
              />

              <TextField
                inputRef={this.ville_ref}
                fullWidth
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Ville"
                value={this.state.CONCAIRE.VILLE}
                onChange={this._handle_change_concaire("VILLE", this.ville_ref)}
              />

              <TextField
                fullWidth
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Téléphone fixe"
                inputProps={{
                  maxLength: 14,
                  pattern: "(^[0-9 ]{14}$)",
                }}
                value={this.state.CONCAIRE.TEL}
                onChange={this._handle_change_concaire("TEL")}
              />

              <TextField
                fullWidth
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Téléphone portable"
                inputProps={{
                  maxLength: 14,
                  pattern: "(^[0-9 ]{14}$)",
                }}
                value={this.state.CONCAIRE.PORTABLE}
                onChange={this._handle_change_concaire("PORTABLE")}
              />

              <TextField
                fullWidth
                margin="normal"
                autoCapitalize="on"
                autoCorrect="off"
                label="Email"
                type="email"
                value={this.state.CONCAIRE.ADRESSEMAIL}
                onChange={this._handle_change_concaire("ADRESSEMAIL")}
              />

              <TextField select fullWidth margin="normal" label="Parenté concessionnaire" value={this.state.CONCAIRE.PARENTE} onChange={this._handle_change_concaire("PARENTE")}>
                <MenuItem value="Ami">Ami</MenuItem>
                <MenuItem value="Amie">Amie</MenuItem>
                <MenuItem value="Arrière petit-fils">Arrière petit-fils</MenuItem>
                <MenuItem value="Arrière petite-fille">Arrière petite-fille</MenuItem>
                <MenuItem value="Beau-Frère">Beau-Frère</MenuItem>
                <MenuItem value="Beau-fils">Beau-fils</MenuItem>
                <MenuItem value="Beau-père">Beau-père</MenuItem>
                <MenuItem value="Belle-fille">Belle-fille</MenuItem>
                <MenuItem value="Belle-mère">Belle-mère</MenuItem>
                <MenuItem value="Belle-soeur">Belle-soeur</MenuItem>
                <MenuItem value="Concessionnaire">Concessionnaire</MenuItem>
                <MenuItem value="Concubin">Concubin</MenuItem>
                <MenuItem value="Concubine">Concubine</MenuItem>
                <MenuItem value="Cousin">Cousin</MenuItem>
                <MenuItem value="Cousine">Cousine</MenuItem>
                <MenuItem value="Divorcé">Divorcé</MenuItem>
                <MenuItem value="Divorcée">Divorcée</MenuItem>
                <MenuItem value="Epouse">Epouse</MenuItem>
                <MenuItem value="Epoux">Epoux</MenuItem>
                <MenuItem value="Fille">Fille</MenuItem>
                <MenuItem value="Fils">Fils</MenuItem>
                <MenuItem value="Frère">Frère</MenuItem>
                <MenuItem value="Gendre">Gendre</MenuItem>
                <MenuItem value="Grand-mère">Grand-mère</MenuItem>
                <MenuItem value="Grand-père">Grand-père</MenuItem>
                <MenuItem value="Mère">Mère</MenuItem>
                <MenuItem value="Neveu">Neveu</MenuItem>
                <MenuItem value="Nièce">Nièce</MenuItem>
                <MenuItem value="Notaire">Notaire</MenuItem>
                <MenuItem value="Oncle">Oncle</MenuItem>
                <MenuItem value="Petit-cousin">Petit-cousin</MenuItem>
                <MenuItem value="Petit-fils">Petit-fils</MenuItem>
                <MenuItem value="Petite-fille">Petite-fille</MenuItem>
                <MenuItem value="Petite-nièce">Petite-nièce</MenuItem>
                <MenuItem value="Père">Père</MenuItem>
                <MenuItem value="Soeur">Soeur</MenuItem>
                <MenuItem value="Tante">Tante</MenuItem>
                <MenuItem value="Tuteur">Tuteur</MenuItem>
              </TextField>

              <TextField
                select
                fullWidth
                disabled={this.props.id_interlocuteur !== undefined && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye)}
                margin="normal"
                label="Qualité"
                value={_.findKey(this.state.CONCAIRE.TYPEINTERLO, (item) => item === 1) !== undefined ? _.findKey(this.state.CONCAIRE.TYPEINTERLO, (item) => item === 1) : ""}
                onChange={this._handle_change_concaire("TYPEINTERLO")}
              >
                <MenuItem value="CONCESSIONNAIRE">Concessionnaire</MenuItem>
                <MenuItem value="COCONCESSIONNAIRE">Co-concessionnaire</MenuItem>
                <MenuItem value="AYANTDROIT">Ayant droit</MenuItem>
                <MenuItem value="AUTRE">{this.state.IS_ORACLE ? "Tiers" : "Autre"}</MenuItem>
              </TextField>

              {this.props.id_interlocuteur !== undefined ? (
                <div>
                  <FormControl component="fieldset" margin="normal" disabled>
                    <FormGroup>
                      <FormControlLabel label="Inhumable" control={<Checkbox checked={this.state.CONCAIRE.TYPEINTERLO.INHUMABLE === 1} value={this.state.CONCAIRE.TYPEINTERLO.INHUMABLE === 1} />} />
                    </FormGroup>
                  </FormControl>

                  {!this.state.IS_ORACLE ? (
                    <FormControl component="fieldset" margin="normal" disabled>
                      <FormGroup>
                        <FormControlLabel
                          label="Indésirable"
                          control={<Checkbox checked={this.state.CONCAIRE.TYPEINTERLO.INDESIRABLE === 1} value={this.state.CONCAIRE.TYPEINTERLO.INDESIRABLE === 1} />}
                        />
                      </FormGroup>
                    </FormControl>
                  ) : null}

                  {this.state.IS_ORACLE ? (
                    <FormControl component="fieldset" margin="normal" disabled>
                      <FormGroup>
                        <FormControlLabel
                          label="Donataire légataire"
                          control={<Checkbox checked={this.state.CONCAIRE.TYPEINTERLO.DONATAIRE_LEGATAIRE === 1} value={this.state.CONCAIRE.TYPEINTERLO.DONATAIRE_LEGATAIRE === 1} />}
                        />
                      </FormGroup>
                    </FormControl>
                  ) : null}

                  {this.state.IS_ORACLE ? (
                    <FormControl component="fieldset" margin="normal" disabled>
                      <FormGroup>
                        <FormControlLabel
                          label="Exécuteur testamentaire"
                          control={<Checkbox checked={this.state.CONCAIRE.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE === 1} value={this.state.CONCAIRE.TYPEINTERLO.EXECUTEUR_TESTAMENTAIRE === 1} />}
                        />
                      </FormGroup>
                    </FormControl>
                  ) : null}

                  <FormControl component="fieldset" margin="normal" disabled>
                    <FormGroup>
                      <FormControlLabel label="Référent" control={<Checkbox checked={this.state.REFERENT} value={this.state.REFERENT} />} />
                    </FormGroup>
                  </FormControl>

                  <FormControl component="fieldset" margin="normal" disabled>
                    <FormGroup>
                      <FormControlLabel
                        label={`Décédé${this.state.CONCAIRE.CIVILITE > 1 && this.state.CONCAIRE.CIVILITE < 4 ? "e" : ""}`}
                        control={<Checkbox checked={this.state.CONCAIRE.DCD === 1} value={this.state.CONCAIRE.DCD === 1} />}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              ) : null}

              <div style={styles.submit_div}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Annuler
                </Button>

                <Button variant="contained" color="primary" type="submit" disabled={this.state.disabledForm}>
                  Enregistrer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(withRouter(MCInterlocuteur));
