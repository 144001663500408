import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import {
  Grid,
  Badge,
  Box,
  IconButton,
  withStyles,
  Typography,
  Paper,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardMedia,
  CardContent,
  Snackbar,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faUser, faFileAlt, faHourglass } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkedAlt, faTruckPickup, faCamera } from "@fortawesome/free-solid-svg-icons";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import jsonpackage from "../../package.json";

import { IsOracle } from "./../Helpers/Test/IsOracle";
import { GetSquare, GetRank, GetTomb, GetLieuFromEmpl, handleError } from "../Helpers/Function";
import { GetAllCimetier } from "./../Helpers/Get/Cimetier";
import { GetLieuByCimel, GetLieuByCocleunik, GetLieuById } from "./../Helpers/Get/Lieu";
import { GetClient } from "./../Helpers/Get/Client";
import { GetParam } from "./../Helpers/Get/Param";
import { GetConcessById } from "../Helpers/Get/Concess";
import { GetNewDatePrestaCarto, GetNewDateSynchro } from "../Helpers/Get/GetNewDateSynchro";
import { GetLastestGescimeBuild } from "../Helpers/Get/LastestGescimeBuild";
import CreateTable_MDF from "./../Helpers/CreateTable_MDF";
import AccueilButton from "./AccueilButton";

import banniere from "../Images/banniere.png";
import conf from "../assets/.conf.txt";
import DialogVersion from "./Global/DialogVersion";
import DialogErreur from "./Global/DialogErreur";
import DialogMajMdf from "./Global/DialogMajMdf";

import UpdateConcaire from "./../Helpers/Update/Concaire";
import UpdatePhotoconc from "./../Helpers/Update/Photoconc";
import UpdateDefuntS from "./../Helpers/Update/DefuntS";
import UpdateDefunt from "../Helpers/Update/Defunt";
import UpdateInterven from "../Helpers/Update/Interven";
import UpdateConcess from "../Helpers/Update/Concess";
import { GetDefuntByCocleunik, GetDefuntById } from "../Helpers/Get/Defunt";
import { GetIntervenByCocleunik } from "../Helpers/Get/Interven";
import UpdateLieu from "../Helpers/Update/Lieu";
import UpdateSvg from "../Helpers/Update/Svg";
import { GetPhotoconcByCocleunik, GetPhotoconcById } from "../Helpers/Get/Photoconc";
import AddPhotoconc from "../Helpers/Add/Photoconc";
import b64toBlob from "b64-to-blob";
import { GetConcaireByCocleunik, GetConcaireById } from "../Helpers/Get/Concaire";
import { DeleteConcaire } from "../Helpers/Delete/Concaire";
import { DeletePhotoconc } from "../Helpers/Delete/Photoconc";
import DeleteDefuntS from "../Helpers/Delete/DefuntS_MDF";
import { DeleteDefunt } from "../Helpers/Delete/Defunt";
import { DeleteConcess } from "../Helpers/Delete/Concess";
import TemporairePurge from "./TemporairePurges";
import LoaderState from "./Global/Object/LoaderState";
import SessionTimer from "./Global/Object/SessionTimer";
import { DeleteDatabase } from "./../Helpers/Delete/Database";
import CreateTable from "../Helpers/CreateTable";
import { GetAllTemporaire } from "./../Helpers/Get/Temporaire";
import TemporaireEnErreur from "./Global/Object/TemporaireEnErreur";
import UpdateClientDateSynchro from "./../Helpers/Update/Client";

var _ = require("lodash");

const styles = {
  grid: {
    display: "flex",
    flex: 1,
  },
  calendarIcon: {
    fontSize: "330px",
    "&:hover": { color: "#FF732F" },
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  menuitem: {
    minHeight: 36,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

const CallendarButton = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent !important",
    "&:hover": {
      color: "#FF732F",
    },
    "& span": {
      flexDirection: "column",
    },
  },
}))(IconButton);

class Accueil extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.IS_ORACLE = false;
    this.ID_TABLETTE = "";
    this.ComboSqureRef = React.createRef();

    this.state = {
      REDIRECT: null,
      CIMETIER: [],
      SELECTED_CIMETIER: "",
      SQUARE_DISABLED: false,
      RANK_DISABLED: false,
      LIEU: {
        id: 0,
        EMPLACEMENT: {
          TYPECAR: "Carré",
          CARRE: "",
          TYPERG: "Rang",
          RANG: "",
          TYPEFOS: "Tombe",
          TOMBE: "",
          H1: 0,
          V1: 0,
          H2: 0,
          V2: 0,
          H3: 0,
          V3: 0,
          H4: 0,
          V4: 0,
        },
        COCLEUNIK: 0,
        CIMEL: 0,
        TOMBEL: 0,
        NOMBREL: 0,
        DUREEL: "",
        POSNUM: 0,
        SITUATION: 0,
        TAILLEPOLICEDULIEU: 0,
        AFFMULTILIGNE: 0,
      },
      SQUARE: [],
      RANK: [],
      TOMB: [],
      emplacement_libre_dialog: false,
      emplacement_multiple_dialog: false,
      date_synchro: "",
      nouvelledatesynchro: "",
      nouvellebasemdf: false,
      prestaCarto: false,
      liste_tables: [
        "GNUTIL",
        "PARAM",
        "DEFUNT",
        "LIEU",
        "CENDRE",
        "PLAN",
        "ZONE",
        "CONCESS",
        "CONCAIRE",
        "TYPETB",
        "DUREE",
        "PHOTOCONC",
        "INTERVEN",
        "INTERVEN_DEFUNT",
        "NATINTERV",
        "PROCEDUREABANDON",
        "SOCIETE",
        "UTIL",
        "GENS",
        "AGENDATACHE",
        "MOUVEMENT",
        "ACCESSIBILITE",
        "ETAT_TERRAIN",
        "CONCESS_ETAT_ECHEANCE",
        "THANATOMORPHOSE",
        "MATERIAU",
        "PA",
        "LIGNE_ACTION",
        "SVG",
        "DEFUNT_S",
        "SUPPRESSION",
        "PURGEGNET",
        "PURGETABLETTE",
      ],
      loading: false,
      dialog_version: false,
      dialog_erreur: false,
      dialog_synchro: false,
      data_loaded: 0,
      table_loading: "",
      nb_total_photos_loading: 0,
      nb_photos_enregistre: 0,
      nouvellebasemdfSnackbarClick: false,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    var idgens = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

    const headers = {
      _cle: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
      _idutil: parseInt(idgens),
      "Content-Type": "application/json",
    };

    IsOracle().then((oracle) => {
      this.IS_ORACLE = oracle;
    });

    GetParam().then((param) => {
      let LIEU = this.state.LIEU;
      if (param.CARREDEFLT !== "") LIEU.EMPLACEMENT.TYPECAR = param.CARREDEFLT;
      if (param.RANGDEFLT !== "") LIEU.EMPLACEMENT.TYPERG = param.RANGDEFLT;
      if (param.TOMBEDEFLT !== "") LIEU.EMPLACEMENT.TYPEFOS = param.TOMBEDEFLT;

      this.setState({ LIEU: LIEU });
    });

    GetAllCimetier(1).then((cimetier) => {
      this.setState({ CIMETIER: cimetier });
    });

    GetClient().then((client) => {
      this.ID_TABLETTE = client.ID_TABLETTE;

      //TODO modification de la date synchro pour test
      //client.DATE_SYNCHRO = "2022-02-15";

      this.setState({ date_synchro: client.DATE_SYNCHRO.replace(/"/g, "") }, () => {
        fetch(`${jsonpackage.urlapi}IsOnline`, {
          mode: "cors",
        }).then(() => {
          // verification de l'existance d'une nouvelle base mdf
          const DateSynchro = GetNewDateSynchro(headers._cle, client.DATE_SYNCHRO.replace(/"/g, ""));

          DateSynchro.then((date) => {
            date = date.replaceAll('"', "");
            //date = "2022-02-21";

            // verification de l'existance d'une presta carto
            const DatePrestaCarto = GetNewDatePrestaCarto(headers._cle, client.DATE_SYNCHRO.replace(/"/g, ""), this.ID_TABLETTE);

            DatePrestaCarto.then((datePc) => {
              datePc = datePc.replaceAll('"', "");
              //datePc = "";

              console.log(`Date de synchro tablette : ${client.DATE_SYNCHRO.replace(/"/g, "")}`);
              console.log(`Date de synchro mdf : ${date}`);
              console.log(`Date de prestation carto : ${datePc}`);

              if (datePc !== "") {
                // si on a une date retourne par le robot restore
                if (date !== "") {
                  // si la date retourne par le robot restore est plus recente que la date de la tablette
                  if (new Date(client.DATE_SYNCHRO.replace(/"/g, "")) <= new Date(datePc) && new Date(date) > new Date(datePc)) {
                    // on affiche un message pour indiquer qu'une nouvelle base mdf est disponible
                    this.setState({ nouvellebasemdf: true, nouvelledatesynchro: date, prestaCarto: true });
                  } else {
                    // si la date retourne par le robot restore est plus recente que la date de la tablette
                    if (new Date(date) > new Date(client.DATE_SYNCHRO.replace(/"/g, ""))) {
                      // on affiche un message pour indiquer qu'une nouvelle base mdf est disponible
                      this.setState({ nouvellebasemdf: true, nouvelledatesynchro: date, prestaCarto: false });
                    }
                  }
                }
              } else {
                // si on a une date retourne par le robot restore
                if (date !== "") {
                  // si la date retourne par le robot restore est plus recente que la date de la tablette
                  if (new Date(date) > new Date(client.DATE_SYNCHRO.replace(/"/g, ""))) {
                    // on affiche un message pour indiquer qu'une nouvelle base mdf est disponible
                    this.setState({ nouvellebasemdf: true, nouvelledatesynchro: date, prestaCarto: false });
                  }
                }
              }
            });
          });
        });
      });
    });

    document.addEventListener("keydown", (event) => {
      if (event.shiftKey && event.code === "Digit1") {
        this.setState({ REDIRECT: "/consulter/defunts" });
      }

      if (event.shiftKey && event.code === "Digit2") {
        this.setState({ REDIRECT: "/consulter/cartographie" });
      }

      if (event.shiftKey && event.code === "Digit3") {
        this.setState({ REDIRECT: "/consulter/concessions" });
      }

      if (event.shiftKey && event.code === "Digit4") {
        this.setState({ REDIRECT: "/consulter/procedure-abandon" });
      }

      if (event.shiftKey && event.code === "Digit5") {
        this.setState({ REDIRECT: "/modifier/type_photographies" });
      }

      if (event.shiftKey && event.code === "Digit6") {
        this.setState({ REDIRECT: "/consulter/travaux" });
      }

      if (event.shiftKey && event.code === "Digit7") {
        this.setState({ REDIRECT: "/consulter/planning" });
      }

      if (event.shiftKey && event.code === "Digit0") {
        this.setState({ REDIRECT: "/" });
      }
    });
  };

  _change_loader_state = () => {
    this.setState({
      data_loaded: LoaderState.getIndex(),
      table_loading: LoaderState.getTable(),
      nb_total_photos_loading: LoaderState.getNombreTotalPhotos(),
      nb_photos_enregistre: LoaderState.getNombrePhotos(),
    });
  };

  _handle_change_combo_cimetier = (event) => {
    GetLieuByCimel(event.target.value, false).then((lieu) => {
      let square = GetSquare(lieu);

      GetParam().then((param) => {
        let LIEU = this.state.LIEU;
        if (param.CARREDEFLT !== "") LIEU.EMPLACEMENT.TYPECAR = param.CARREDEFLT;
        if (param.RANGDEFLT !== "") LIEU.EMPLACEMENT.TYPERG = param.RANGDEFLT;
        if (param.TOMBEDEFLT !== "") LIEU.EMPLACEMENT.TYPEFOS = param.TOMBEDEFLT;
        LIEU.EMPLACEMENT.CARRE = "";
        LIEU.EMPLACEMENT.RANG = "";
        LIEU.EMPLACEMENT.TOMBE = "";
        LIEU.CIMEL = event.target.value;

        this.setState(
          {
            SELECTED_CIMETIER: event.target.value,
            LIEU: LIEU,
            SQUARE: square,
            SQUARE_DISABLED: false,
            RANK: [],
            TOMB: [],
          },
          () => {
            if (square.length === 1 && square[0] === " ") {
              GetLieuByCimel(this.state.LIEU.CIMEL, false).then((alllieu) => {
                let rank = GetRank(alllieu, square[0].trim());

                let LIEU = this.state.LIEU;
                LIEU.EMPLACEMENT.CARRE = square[0];
                LIEU.EMPLACEMENT.RANG = "";
                LIEU.EMPLACEMENT.TOMBE = "";

                this.setState(
                  {
                    LIEU: LIEU,
                    SQUARE_DISABLED: true,
                    RANK_DISABLED: false,
                    RANK: rank,
                    TOMB: [],
                  },
                  () => {
                    if (rank.length === 1 && rank[0] === " ") {
                      let tomb = GetTomb(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), rank[0].trim());

                      let LIEU = this.state.LIEU;
                      LIEU.EMPLACEMENT.RANG = rank[0];
                      LIEU.EMPLACEMENT.TOMBE = "";

                      this.setState({
                        LIEU: LIEU,
                        RANK_DISABLED: true,
                        TOMB: tomb,
                      });
                    }
                  }
                );
              });
            }
          }
        );
      });
    });
  };

  _handle_change_combo_square = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.LIEU.CIMEL, false).then((alllieu) => {
      let rank = GetRank(alllieu, event.target.value.trim());

      let LIEU = this.state.LIEU;
      LIEU.EMPLACEMENT.CARRE = event.target.value;
      LIEU.EMPLACEMENT.RANG = "";
      LIEU.EMPLACEMENT.TOMBE = "";

      this.setState(
        {
          LIEU: LIEU,
          RANK: rank,
          RANK_DISABLED: false,
          TOMB: [],
        },
        () => {
          if (rank.length === 1 && rank[0] === " ") {
            let tomb = GetTomb(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), rank[0].trim());

            let LIEU = this.state.LIEU;
            LIEU.EMPLACEMENT.RANG = rank[0];
            LIEU.EMPLACEMENT.TOMBE = "";

            this.setState({
              LIEU: LIEU,
              RANK_DISABLED: true,
              TOMB: tomb,
            });
          }
        }
      );
    });
  };

  _handle_change_combo_rank = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.LIEU.CIMEL, false).then((alllieu) => {
      let tomb = GetTomb(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), event.target.value.trim());

      let LIEU = this.state.LIEU;
      LIEU.EMPLACEMENT.RANG = event.target.value;
      LIEU.EMPLACEMENT.TOMBE = "";

      this.setState({
        LIEU: LIEU,
        TOMB: tomb,
      });
    });
  };

  _handle_change_combo_tomb = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.LIEU.CIMEL, false).then((alllieu) => {
      let LIEU = GetLieuFromEmpl(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), this.state.LIEU.EMPLACEMENT.RANG.trim(), event.target.value.trim());

      if (LIEU[0].EMPLACEMENT.CARRE === "") {
        LIEU[0].EMPLACEMENT.CARRE = " ";
      }

      if (LIEU[0].EMPLACEMENT.RANG === "") {
        LIEU[0].EMPLACEMENT.RANG = " ";
      }

      if (LIEU[0].EMPLACEMENT.TOMBE === "") {
        LIEU[0].EMPLACEMENT.TOMBE = " ";
      }

      if (LIEU.length > 1) {
        this.setState({ emplacement_multiple_dialog: true });
      } else {
        this.setState({
          LIEU: LIEU[0],
        });
      }
    });
  };

  _handle_submit_concession = (event) => {
    event.preventDefault();

    if (this.state.LIEU.COCLEUNIK !== 0) {
      GetConcessById(this.state.LIEU.COCLEUNIK).then((concession) => {
        if (concession !== undefined) {
          this.setState({
            REDIRECT: "/consulter/fiche-concession/" + concession.COCLEUNIK,
          });
        } else {
          this.setState({ emplacement_libre_dialog: true });
        }
      });
    }

    if (this.state.LIEU.EMPLACEMENT.ETAT === 1) {
      this.setState({ emplacement_libre_dialog: true });
    }
  };

  _handle_click_accueil_button = (url) => (event) => {
    event.preventDefault();

    this.setState({ REDIRECT: url });
  };

  _updatedatabase = () => {
    GetAllTemporaire().then((temp) => {
      const nonEnvoye = _.filter(temp, ["DATE_DE_CREATION", null]);

      if (nonEnvoye.length > 0) {
        this.setState({
          REDIRECT: "/synchronisation",
        });
      } else {
        this.setState({ nouvellebasemdf: false, loading: true }, () => {
          GetAllCimetier(1).then((cimetiere) => {
            let cimetiere_ids = _.map(cimetiere, "NUMCIME");

            const headers = {
              APIKEY: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
              ALIAS: atob(this.props.cookies.get(encodeURIComponent(btoa("ALIAS")))),
              IDENTIFIANT: atob(this.props.cookies.get(encodeURIComponent(btoa("IDENTIFIANT")))),
              MOT_DE_PASSE: atob(this.props.cookies.get(encodeURIComponent(btoa("MOT_DE_PASSE")))),
              CIMETIERE: `0,${cimetiere_ids}`,
              DATE_SYNCHRO: this.state.date_synchro,
            };

            const idgens = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

            const lastestGescimeBuild = GetLastestGescimeBuild(headers);
            lastestGescimeBuild.then((version) => {
              fetch(conf)
                .then((r) => r.text())
                .then((text) => {
                  let json = {};

                  const keyValuePair = (kvStr) => {
                    const kvPair = kvStr.split("=").map((val) => val.trim());
                    var jsonKey = kvPair[0];

                    json[jsonKey] = kvPair[1];
                  };

                  _.forEach(text.split("\n"), (t) => {
                    keyValuePair(t);
                  });

                  if (version < (this.IS_ORACLE ? json.VERSION_GESCIME_ORACLE : json.VERSION_GESCIME_HF)) {
                    this.setState({ dialog_version: true, loading: false });
                  } else {
                    const headers2 = {
                      _cle: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
                      _idutil: parseInt(idgens),
                      "Content-Type": "application/json",
                    };

                    SessionTimer.setTimer(0);

                    let timer = 1800000;
                    // if (process.env.REACT_APP_ENVIRONMENT !== undefined && process.env.REACT_APP_ENVIRONMENT === "dev") {
                    //   timer = 1000;
                    // }

                    TemporairePurge(headers2, this.ID_TABLETTE, this.IS_ORACLE).then((temporairePurge) => {
                      console.log(temporairePurge ? "Les éléments temporaire ont été traités" : "Erreur lors de la modification des éléments temporaire.");

                      if (temporairePurge) {
                        let updateTable;
                        if (this.state.prestaCarto) {
                          GetAllTemporaire().then((temporaires) => {
                            let index = 0;

                            if (temporaires.length === 0) {
                              GetAllCimetier(0).then((liste_cimetiere) => {
                                const deleteDb = DeleteDatabase();

                                deleteDb.then((res) => {
                                  headers.DATE_SYNCHRO = "";

                                  const idTablette = this.ID_TABLETTE;

                                  updateTable = CreateTable(liste_cimetiere, headers, this._change_loader_state, idTablette);

                                  updateTable
                                    .then(() => {
                                      const dateToSend = new Date();
                                      dateToSend.setHours(dateToSend.getHours() - dateToSend.getTimezoneOffset() / 60, dateToSend.getMinutes(), dateToSend.getSeconds());

                                      fetch(`${jsonpackage.urlapi}PrestaCarto`, {
                                        mode: "cors",
                                        method: "POST",
                                        headers: { ...headers2, ...{ _idtablette: idTablette } },
                                        body: JSON.stringify(dateToSend.toISOString().slice(0, -5)),
                                      })
                                        .then((result) => result.text())
                                        .then((result) => {
                                          UpdateClientDateSynchro(this.state.nouvelledatesynchro).then(() => {
                                            LoaderState.setIndex(0);
                                            LoaderState.setTable("");

                                            SessionTimer.setTimer(timer);

                                            this.setState({ loading: false, date_synchro: this.state.nouvelledatesynchro });
                                          });
                                        });
                                    })
                                    .catch((err) => {
                                      console.error(err);

                                      SessionTimer.setTimer(timer);

                                      this.setState({ dialog_erreur: true, loading: false });
                                    });
                                });
                              });
                            }

                            _.forEach(temporaires, (temporaire) => {
                              if (temporaire.DATE_DE_CREATION === null && !TemporaireEnErreur.getTemporaireEnErreur().includes(temporaire.id)) {
                                const date = new Date();
                                date.setHours(date.getHours() - date.getTimezoneOffset() / 60, date.getMinutes(), date.getSeconds());

                                let TEMPORAIRE = { ...temporaire };
                                _.set(TEMPORAIRE, "ID_TEMPORAIRE", 0);
                                _.set(TEMPORAIRE, "ID_TYPE_TEMPORAIRE", 0);
                                _.set(TEMPORAIRE, "DATE_DE_CREATION", date.toISOString().slice(0, -5));
                                _.set(TEMPORAIRE, "ID_GESCIME", 0);
                                _.set(TEMPORAIRE, "ETAT_INITIAL", JSON.stringify(TEMPORAIRE.ETAT_INITIAL));
                                _.set(TEMPORAIRE, "TABLE_MODIFIE_JSON", JSON.stringify(TEMPORAIRE.TABLE_MODIFIE_JSON));

                                if (temporaire.TYPE_D_ENTITE === "CONCESS" && temporaire.TABLE_MODIFIE_JSON.COCLEUNIK < 0) {
                                  fetch(`${jsonpackage.urlapi}TEMPORAIRE`, {
                                    mode: "cors",
                                    method: "POST",
                                    headers: headers2,
                                    body: JSON.stringify(TEMPORAIRE),
                                  })
                                    .then((result) => result.text())
                                    .then((result) => {
                                      index++;

                                      if (index === temporaires.length) {
                                        GetAllCimetier(0).then((liste_cimetiere) => {
                                          const deleteDb = DeleteDatabase();

                                          deleteDb.then((res) => {
                                            headers.DATE_SYNCHRO = "";

                                            const idTablette = this.ID_TABLETTE;

                                            updateTable = CreateTable(liste_cimetiere, headers, this._change_loader_state, idTablette);

                                            updateTable
                                              .then(() => {
                                                const dateToSend = new Date();
                                                dateToSend.setHours(dateToSend.getHours() - dateToSend.getTimezoneOffset() / 60, dateToSend.getMinutes(), dateToSend.getSeconds());

                                                fetch(`${jsonpackage.urlapi}PrestaCarto`, {
                                                  mode: "cors",
                                                  method: "POST",
                                                  headers: { ...headers2, ...{ _idtablette: idTablette } },
                                                  body: JSON.stringify(dateToSend.toISOString().slice(0, -5)),
                                                })
                                                  .then((result) => result.text())
                                                  .then((result) => {
                                                    UpdateClientDateSynchro(this.state.nouvelledatesynchro).then(() => {
                                                      LoaderState.setIndex(0);
                                                      LoaderState.setTable("");

                                                      SessionTimer.setTimer(timer);

                                                      this.setState({ loading: false, date_synchro: this.state.nouvelledatesynchro });
                                                    });
                                                  });
                                              })
                                              .catch((err) => {
                                                console.error(err);

                                                SessionTimer.setTimer(timer);

                                                this.setState({ dialog_erreur: true, loading: false });
                                              });
                                          });
                                        });
                                      }
                                    });
                                } else {
                                  setTimeout(() => {
                                    fetch(`${jsonpackage.urlapi}TEMPORAIRE`, {
                                      mode: "cors",
                                      method: "POST",
                                      headers: headers2,
                                      body: JSON.stringify(TEMPORAIRE),
                                    })
                                      .then((result) => result.text())
                                      .then((result) => {
                                        index++;

                                        if (index === temporaires.length) {
                                          GetAllCimetier(0).then((liste_cimetiere) => {
                                            const deleteDb = DeleteDatabase();

                                            deleteDb.then((res) => {
                                              headers.DATE_SYNCHRO = "";

                                              const idTablette = this.ID_TABLETTE;

                                              updateTable = CreateTable(liste_cimetiere, headers, this._change_loader_state, idTablette);

                                              updateTable
                                                .then(() => {
                                                  const dateToSend = new Date();
                                                  dateToSend.setHours(dateToSend.getHours() - dateToSend.getTimezoneOffset() / 60, dateToSend.getMinutes(), dateToSend.getSeconds());

                                                  fetch(`${jsonpackage.urlapi}PrestaCarto`, {
                                                    mode: "cors",
                                                    method: "POST",
                                                    headers: { ...headers2, ...{ _idtablette: idTablette } },
                                                    body: JSON.stringify(dateToSend.toISOString().slice(0, -5)),
                                                  })
                                                    .then((result) => result.text())
                                                    .then((result) => {
                                                      UpdateClientDateSynchro(this.state.nouvelledatesynchro).then(() => {
                                                        LoaderState.setIndex(0);
                                                        LoaderState.setTable("");

                                                        SessionTimer.setTimer(timer);

                                                        this.setState({ loading: false, date_synchro: this.state.nouvelledatesynchro });
                                                      });
                                                    });
                                                })
                                                .catch((err) => {
                                                  console.error(err);

                                                  SessionTimer.setTimer(timer);

                                                  this.setState({ dialog_erreur: true, loading: false });
                                                });
                                            });
                                          });
                                        }
                                      });
                                  }, 1500);
                                }
                              }
                            });
                          });
                        } else {
                          updateTable = CreateTable_MDF(headers, idgens, this.ID_TABLETTE, this.state.nouvelledatesynchro, this._change_loader_state);

                          updateTable
                            .then(() => {
                              LoaderState.setIndex(0);
                              LoaderState.setTable("");

                              SessionTimer.setTimer(timer);

                              this.setState({ loading: false, date_synchro: this.state.nouvelledatesynchro });
                            })
                            .catch((err) => {
                              console.error(err);

                              SessionTimer.setTimer(timer);

                              this.setState({ dialog_erreur: true, loading: false });
                            });
                        }
                      }
                    });
                  }
                });
            });
          });
        });
      }
    });
  };

  _updateEntiteObject = (temporaire) => {
    switch (temporaire.TYPE_D_ENTITE) {
      case "CONCAIRE":
        UpdateConcaire(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      case "PHOTOCONC":
        let photo = temporaire.ETAT_INITIAL;

        if (photo.SIGNATURE_AVANT_AGENT !== undefined) {
          delete photo.SIGNATURE_AVANT_AGENT;
        }

        if (photo.SIGNATURE_AVANT_SOCIETE !== undefined) {
          delete photo.SIGNATURE_AVANT_SOCIETE;
        }

        UpdatePhotoconc(photo).catch((Err) => handleError(Err));
        break;
      case "DEFUNT":
        UpdateDefuntS(temporaire.ETAT_INITIAL)
          .then(() => {
            UpdateDefunt(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
          })
          .catch((Err) => handleError(Err));
        break;
      case "INTERVEN":
        UpdateInterven(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      case "CONCESS":
        UpdateConcess(temporaire.ETAT_INITIAL)
          .then(() => {
            if (temporaire.TABLE_MODIFIE_JSON.LICLEUNIK !== undefined) {
              if (temporaire.ETAT_INITIAL.LICLEUNIK !== temporaire.TABLE_MODIFIE_JSON.LICLEUNIK) {
                GetDefuntByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((defunts) => {
                  _.forEach(defunts, (defunt) => {
                    _.set(defunt, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    UpdateDefunt(defunt).catch((Err) => handleError(Err));
                  });
                });

                GetIntervenByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((intervens) => {
                  _.forEach(intervens, (interven) => {
                    _.set(interven, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    UpdateInterven(interven).catch((Err) => handleError(Err));
                  });
                });

                GetLieuById(temporaire.TABLE_MODIFIE_JSON.LICLEUNIK).then((lieu) => {
                  if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                  _.set(lieu.EMPLACEMENT, "ETAT", 1);
                  _.set(lieu, "COCLEUNIK", 0);

                  UpdateLieu(lieu)
                    .then(() => {
                      UpdateSvg(lieu).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                });
              }
            }
          })
          .catch((Err) => handleError(Err));
        break;

      case "LIEU":
        UpdateLieu(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      default:
        break;
    }
  };

  _addEntiteObjet = (type_entite, etat) => {
    switch (type_entite) {
      case "PHOTOCONC":
        if (this.IS_ORACLE) {
          for (var key in etat) {
            if (key === "IDEVOL_ETAT") {
              etat.IDPHOTOCONC = etat[key];
              delete etat[key];
            }

            if (key === "DATE_EVOL") {
              etat.DATEAJOUT = etat[key];
              delete etat[key];
            }

            if (key === "REFERENTE") {
              etat.PRINCIPAL = etat[key];
              delete etat[key];
            }

            if (key === "ETAT_EVOL") {
              etat.NOTES = etat[key];
              delete etat[key];
            }
          }
        }

        const photo = _.omit(etat, ["id"]);

        if (photo.PHOTO !== "" && photo.PHOTO !== undefined) {
          const image = photo.PHOTO.replace(/data:image.*base64,/, "");
          var blob = b64toBlob(image, "image/png");

          _.set(photo, "PHOTO", blob);
        }

        setTimeout(() => {
          GetPhotoconcById(photo.IDPHOTOCONC).then((photoconc) => {
            if (photoconc === undefined) {
              AddPhotoconc(photo).catch((Err) => handleError(Err));
            }
          });
        }, 500);

        break;
      default:
        break;
    }
  };

  _deleteEntiteObject = (temporaire) => {
    switch (temporaire.TYPE_D_ENTITE) {
      case "CONCAIRE":
        if (temporaire.ETAT_INITIAL === null) {
          GetConcaireById(temporaire.TABLE_MODIFIE_JSON.C0CLEUNIK).then((concaire) => {
            if (concaire !== undefined) {
              DeleteConcaire(concaire.id)
                .then(() => console.log("%cL'interlocuteur a été supprimé", "color: green"))
                .catch((Err) => handleError(Err));
            }
          });
        } else {
          UpdateConcaire(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        }

        break;
      case "PHOTOCONC":
        if (temporaire.ETAT_INITIAL === null) {
          GetPhotoconcById(this.IS_ORACLE ? temporaire.TABLE_MODIFIE_JSON.IDEVOL_ETAT : temporaire.TABLE_MODIFIE_JSON.IDPHOTOCONC).then((photoconc) => {
            if (photoconc !== undefined) DeletePhotoconc(photoconc.id).catch((Err) => handleError(Err));
          });
        } else {
          UpdatePhotoconc(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        }

        break;
      case "DEFUNT":
        if (temporaire.ETAT_INITIAL === null) {
          GetDefuntById(temporaire.TABLE_MODIFIE_JSON.D0CLEUNIK).then((defunt) => {
            if (defunt !== undefined)
              DeleteDefuntS(defunt)
                .then(() => {
                  DeleteDefunt(defunt.id).catch((Err) => handleError(Err));
                })
                .catch((Err) => handleError(Err));
          });
        } else {
          UpdateDefuntS(temporaire.ETAT_INITIAL)
            .then(() => {
              UpdateDefunt(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
            })
            .catch((Err) => handleError(Err));
        }

        break;
      case "INTERVEN":
        UpdateInterven(temporaire.ETAT_INITIAL).catch((Err) => handleError(Err));
        break;
      case "CONCESS":
        if (temporaire.ETAT_INITIAL === null) {
          GetConcessById(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((concess) => {
            if (concess !== undefined) {
              GetConcaireByCocleunik(concess.COCLEUNIK).then((concaire) => {
                _.forEach(concaire, (c) => {
                  DeleteConcaire(c.id).catch((Err) => handleError(Err));
                });
              });

              GetPhotoconcByCocleunik(concess.COCLEUNIK).then((photoconc) => {
                _.forEach(photoconc, (p) => {
                  DeletePhotoconc(p.id).catch((Err) => handleError(Err));
                });
              });

              GetDefuntByCocleunik(concess.COCLEUNIK).then((defunt) => {
                _.forEach(defunt, (d) => {
                  DeleteDefuntS(d)
                    .then(() => {
                      DeleteDefunt(d.id).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                });
              });

              GetLieuByCocleunik(concess.COCLEUNIK).then((lieu) => {
                if (lieu !== undefined) {
                  if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                  _.set(lieu.EMPLACEMENT, "ETAT", 1);
                  _.set(lieu, "COCLEUNIK", 0);

                  UpdateLieu(lieu)
                    .then(() => {
                      UpdateSvg(lieu).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                }
              });

              DeleteConcess(concess.id).catch((Err) => handleError(Err));
            }
          });
        } else {
          UpdateConcess(temporaire.ETAT_INITIAL).then(() => {
            if (temporaire.TABLE_MODIFIE_JSON.LICLEUNIK !== undefined) {
              if (temporaire.ETAT_INITIAL.LICLEUNIK !== temporaire.TABLE_MODIFIE_JSON.LICLEUNIK) {
                GetPhotoconcByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((photoconc) => {
                  _.forEach(photoconc, (p) => {
                    _.set(p, "COCLEUNIK", temporaire.ETAT_INITIAL.COCLEUNIK);

                    UpdatePhotoconc(p).catch((Err) => handleError(Err));
                  });
                });

                GetDefuntByCocleunik(temporaire.TABLE_MODIFIE_JSON.COCLEUNIK).then((defunt) => {
                  _.forEach(defunt, (d) => {
                    _.set(d, "COCLEUNIK", temporaire.ETAT_INITIAL.COCLEUNIK);
                    _.set(d, "LICLEUNIK", temporaire.ETAT_INITIAL.LICLEUNIK);

                    UpdateDefunt(d).catch((Err) => handleError(Err));
                  });
                });

                GetLieuById(temporaire.TABLE_MODIFIE_JSON.LICLEUNIK).then((lieu) => {
                  if (temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE !== undefined) _.set(lieu.EMPLACEMENT, "GELE", temporaire.TABLE_MODIFIE_JSON.DEVENU_DEGELE);

                  _.set(lieu.EMPLACEMENT, "ETAT", 1);
                  _.set(lieu, "COCLEUNIK", 0);

                  UpdateLieu(lieu)
                    .then(() => {
                      UpdateSvg(lieu).catch((Err) => handleError(Err));
                    })
                    .catch((Err) => handleError(Err));
                });
              }
            }
          });
        }

        break;
      default:
        break;
    }
  };

  render() {
    console.log("RENDER ACCUEIL");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container direction="column" alignItems="flex-start" justify="flex-start" style={styles.grid}>
        <Card style={{ width: "100%", overflow: "inherit" }} elevation={0}>
          <div>
            <CardContent style={{ flex: 1, padding: 0 }}>
              <div style={{ position: "relative", display: "flex" }}>
                <CardMedia component="img" image={banniere} title="bannière" />
                <Typography style={{ position: "absolute", right: 20, marginTop: 20, padding: "6px 12px", backgroundColor: "white", borderRadius: 5, userSelect: "none" }}>
                  {this.state.date_synchro !== "" ? `Dernière mise à jour le ${new Date(this.state.date_synchro).toLocaleDateString("fr-FR")}` : `Aucune mise à jours `}
                </Typography>
                <Typography
                  style={{ position: "absolute", right: 20, marginTop: 60, padding: "6px 12px", backgroundColor: "white", borderRadius: 5, userSelect: "none" }}
                >{`Tablette : ${this.ID_TABLETTE}`}</Typography>
              </div>
            </CardContent>
          </div>
        </Card>

        <Grid item xs style={{ display: "flex", flex: "1", width: "100%" }}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            style={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              flex: "1",
            }}
          >
            <Grid
              item
              xs
              style={{
                alignItems: "center",
                display: "flex",
                flex: "1",
                justifyContent: "center",
                height: "100%",
                maxHeight: "500px",
              }}
            >
              <CallendarButton onClick={() => this.setState({ REDIRECT: "/consulter/planning" })}>
                <FontAwesomeIcon icon={faCalendarAlt} style={styles.calendarIcon} />
                <Typography variant="h6" color="secondary">
                  PLANNING
                </Typography>
              </CallendarButton>
            </Grid>

            <Grid
              item
              xs
              style={{
                display: "flex",
                flex: "1",
                height: "100%",
                maxHeight: 400,
                minWidth: 350,
              }}
            >
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="flex-start"
                style={{
                  display: "flex",
                  flex: "1",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box flexGrow={1} style={{ maxWidth: "130px" }}>
                  <AccueilButton text="DEFUNTS" url="/consulter/defunts" icon={faUser} onClick={this._handle_click_accueil_button} />
                  <AccueilButton text="PROCEDURES" url="/consulter/procedures" icon={faHourglass} onClick={this._handle_click_accueil_button} />
                </Box>

                <Box flexGrow={1} style={{ maxWidth: "130px" }}>
                  <AccueilButton text="CARTOGRAPHIE" url="/consulter/cartographie" icon={faMapMarkedAlt} onClick={this._handle_click_accueil_button} />
                  <AccueilButton text="PHOTOS" url="/modifier/type_photographies" icon={faCamera} onClick={this._handle_click_accueil_button} />
                </Box>

                <Box flexGrow={1} style={{ maxWidth: "130px" }}>
                  <AccueilButton text="CONCESSIONS" url="/consulter/concessions" icon={faFileAlt} onClick={this._handle_click_accueil_button} />
                  <AccueilButton text="TRAVAUX" url="/consulter/travaux" icon={faTruckPickup} onClick={this._handle_click_accueil_button} />
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              xs
              style={{
                alignItems: "center",
                display: "flex",
                flex: "1",
                height: "100%",
                maxHeight: 400,
                minWidth: 350,
              }}
            >
              <Paper elevation={0} style={styles.paper}>
                <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                  <form onSubmit={this._handle_submit_concession}>
                    <TextField select required fullWidth margin="normal" label="Cimetière" value={this.state.SELECTED_CIMETIER} onChange={this._handle_change_combo_cimetier}>
                      {this.state.CIMETIER.map((cimetier, index) => (
                        <MenuItem key={index} value={cimetier.NUMCIME}>
                          {cimetier.NOMCIME}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      ref={this.ComboSqureRef}
                      disabled={this.state.SQUARE_DISABLED}
                      label={this.state.LIEU.EMPLACEMENT.TYPECAR}
                      value={this.state.LIEU.EMPLACEMENT.CARRE}
                      onChange={this._handle_change_combo_square}
                    >
                      {this.state.SQUARE.map((square, index) => (
                        <MenuItem key={index} value={square} style={styles.menuitem}>
                          {square}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      disabled={this.state.RANK_DISABLED}
                      label={this.state.LIEU.EMPLACEMENT.TYPERG}
                      value={this.state.LIEU.EMPLACEMENT.RANG}
                      onChange={this._handle_change_combo_rank}
                    >
                      {this.state.RANK.map((rank, index) => (
                        <MenuItem key={index} value={rank} style={styles.menuitem}>
                          {rank}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      label={this.state.LIEU.EMPLACEMENT.TYPEFOS}
                      value={this.state.LIEU.EMPLACEMENT.TOMBE}
                      onChange={this._handle_change_combo_tomb}
                    >
                      {this.state.TOMB.map((tomb, index) => (
                        <MenuItem key={index} value={tomb} style={styles.menuitem}>
                          {tomb}
                        </MenuItem>
                      ))}
                    </TextField>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 16,
                      }}
                    >
                      <Button variant="contained" color="primary" type="submit">
                        Rechercher
                      </Button>
                    </div>
                  </form>
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Dialog onClose={() => this.setState({ emplacement_libre_dialog: false })} open={this.state.emplacement_libre_dialog}>
            <DialogTitle onClose={() => this.setState({ emplacement_libre_dialog: false })}>
              <Typography variant="h6" component="p">
                Attention
              </Typography>

              <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ emplacement_libre_dialog: false })}>
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Typography gutterBottom>Impossible d'afficher la fiche de concession sur un emplacement libre.</Typography>
            </DialogContent>
          </Dialog>

          <Dialog onClose={() => this.setState({ emplacement_multiple_dialog: false })} open={this.state.emplacement_multiple_dialog}>
            <DialogTitle onClose={() => this.setState({ emplacement_multiple_dialog: false })}>
              <Typography variant="h6" component="p">
                Attention
              </Typography>

              <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ emplacement_multiple_dialog: false })}>
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Typography gutterBottom>Cette numérotation correspond à plusieurs emplacements. Veuillez sélectionner l'emplacement par la cartographie</Typography>
            </DialogContent>
          </Dialog>

          <DialogMajMdf
            state={this.state.loading}
            data_loaded={this.state.data_loaded}
            table_loading={this.state.table_loading}
            nb_total_photos_loading={this.state.nb_total_photos_loading}
            nb_photos_enregistre={this.state.nb_photos_enregistre}
            table_length={this.state.liste_tables.length}
            prestaCarto={this.state.prestaCarto}
          />
          <DialogVersion state={this.state.dialog_version} onChange={(value) => this.setState({ dialog_version: value })} />
          <DialogErreur state={this.state.dialog_erreur} onChange={(value) => this.setState({ dialog_erreur: value })} />

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            style={({ right: 24, cursor: "pointer" }, this.state.nouvellebasemdfSnackbarClick ? { display: "block" } : { display: "flex" })}
            open={this.state.nouvellebasemdf}
            onClick={() => this.setState({ nouvellebasemdfSnackbarClick: !this.state.nouvellebasemdfSnackbarClick })}
          >
            {!this.state.nouvellebasemdfSnackbarClick ? (
              <Badge badgeContent="!" color="primary">
                <Alert severity="info" color="warning" className="AlertSmall"></Alert>
              </Badge>
            ) : (
              <Alert
                severity="info"
                color="warning"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this._updatedatabase();
                    }}
                  >
                    ok
                  </Button>
                }
              >
                Une mise à jour de la base de données est disponible. Cliquez sur OK pour la télécharger
              </Alert>
            )}
          </Snackbar>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(Accueil);
