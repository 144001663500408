import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { Paper, TextField, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Downshift from "downshift";

import { IsOracle } from "./../Helpers/Test/IsOracle";
import { GetClient } from "./../Helpers/Get/Client";
import { GetAllConcess } from "./../Helpers/Get/Concess";
import { GetLieuById } from "./../Helpers/Get/Lieu";
import { Cree_iso_date, handleError } from "../Helpers/Function";
import { differenceObject } from "./../Helpers/Test/Difference";
import UpdateTemporaire from "./../Helpers/Update/Temporaire";
import AddTemporaire from "./../Helpers/Add/Temporaire";
import { miseAJourConcessAssociation } from "./Global/Concess";
import { DialogAccessRight } from "./Global/DialogAccessRight";
import { CheckUserRight } from "./../Helpers/Get/LigneAction";
import CurrentTab from "./Global/Object/current_tab";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
  },
  form: {
    display: "flex",
    alignItems: "baseline",
  },
  downshift: {
    flex: 1,
    marginRight: 20,
  },
};

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return <TextField InputProps={{ inputRef: ref, ...InputProps }} {...other} />;
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem {...itemProps} key={index} selected={isHighlighted} component="div" style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
      {suggestion.FAMILLE}
    </MenuItem>
  );
}

function renderSuggestionEmpl({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem {...itemProps} key={index} selected={isHighlighted} component="div" style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
      {suggestion.EMPL}
    </MenuItem>
  );
}

function renderSuggestionOrdre({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem {...itemProps} key={index} selected={isHighlighted} component="div" style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
      {suggestion.ANCNUM}
    </MenuItem>
  );
}

class InputRechercheConcession extends Component {
  constructor(props) {
    super(props);

    this.LIST_CONCESSIONS = [];
    this.IDCLIENT = 0;
    this.ID_TABLETTE = "";
    this.ID_UTIL = atob(props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      SELECTED_CONCESSION: "",
      SELECTED_CONCESSION_EMPL: "",
      SELECTED_CONCESSION_ORDRE: "",
      SELECTED_CONCESSION_ID: [],
      LIEU: "",
      REDIRECT: null,
      RESULT_LIST: [],
      ERROR: false,
      DIALOG_AFFECTER_CONCESSION: false,
      DIALOG_EMPLACEMENT_OCCUPE: false,
      droit_util_modif_concession: false,
    };
  }

  componentDidMount = () => {
    document.onkeydown = (event) => {
      event.stopPropagation();
    };

    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    GetClient().then((client) => {
      this.IDCLIENT = client.IDCLIENT;
      this.ID_TABLETTE = client.ID_TABLETTE;
    });

    if (this.props.id_lieu !== undefined) {
      GetLieuById(this.props.id_lieu).then((lieu) => {
        if (lieu.COCLEUNIK > 0) {
          this.setState({ DIALOG_EMPLACEMENT_OCCUPE: true });
        } else {
          this.setState({ LIEU: lieu }, () => {
            this._get_all_concessions();
          });
        }
      });
    } else {
      this._get_all_concessions();
    }
  };

  _get_all_concessions = () => {
    GetAllConcess().then((concessions) => {
      if (this.props.input_concession === "ajout" && this.props.id_lieu !== undefined) {
        this.LIST_CONCESSIONS = _.filter(concessions, (c) => {
          return c.TYPECONC === 2 && c.TYPECIME === this.state.LIEU.CIMEL && c.NOMBRE === this.state.LIEU.NOMBREL;
        });
      } else {
        this.LIST_CONCESSIONS = concessions;
      }
    });
  };

  _get_suggestions = (inputValue) => {
    let count = 0;
    return _.orderBy(this.LIST_CONCESSIONS, ["FAMILLE"], ["asc"]).filter((suggestion) => {
      const keep =
        (!inputValue ||
          suggestion.FAMILLE.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .startsWith(
              inputValue
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            )) &&
        count < 5;
      if (keep) {
        count++;
      }
      return keep;
    });
  };

  _get_suggestions_empl = (inputValue) => {
    let count = 0;

    return _.orderBy(this.LIST_CONCESSIONS, ["EMPL"], ["asc"]).filter((suggestion) => {
      const keep = (!inputValue || _.includes(suggestion.EMPL.toLowerCase(), inputValue.toLowerCase())) && count < 5;

      if (keep) {
        count++;
      }

      return keep;
    });
  };

  _get_suggestions_ordre = (inputValue) => {
    let count = 0;

    return _.orderBy(this.LIST_CONCESSIONS, ["ANCNUM"], ["asc"]).filter((suggestion) => {
      const keep = (!inputValue || _.startsWith(suggestion.ANCNUM.toLowerCase(), inputValue.toLowerCase())) && count < 5;

      if (keep) {
        count++;
      }

      return keep;
    });
  };

  _get_all_suggestions = (inputValue) => {
    if (this.state.SELECTED_CONCESSION_ID.length) {
      return this.state.SELECTED_CONCESSION_ID;
    } else {
      return _.orderBy(this.LIST_CONCESSIONS, ["FAMILLE"], ["asc"]).filter((suggestion) => {
        const keep =
          !inputValue ||
          suggestion.FAMILLE.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .startsWith(
              inputValue
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            );

        return keep;
      });
    }
  };

  _handle_submit_form_rechercheconcession = (event) => {
    event.preventDefault();

    console.log("click_rechercheconcession");

    if (this.props.input_concession === "ajout") {
      CheckUserRight(this.state.ID_UTIL, "CONCESS", "DROITMODIF").then((asRight) => {
        if (asRight) {
          this.setState({
            DIALOG_AFFECTER_CONCESSION: true,
          });
        } else {
          this.setState({ droit_util_modif_concession: true });
        }
      });
    } else {
      if (this.state.SELECTED_CONCESSION !== "") {
        CurrentTab.setTab(0);

        this.setState({
          RESULT_LIST: this._get_all_suggestions(this.state.SELECTED_CONCESSION),
        });
      }
    }
  };

  _UpdateConcessData = (CONCESS) => {
    if (CONCESS.DATEEXPIRA !== "") {
      CONCESS.DATEEXPIRA = Cree_iso_date(CONCESS.DATEEXPIRA);
    }

    if (CONCESS.MADATE !== "") {
      CONCESS.MADATE = Cree_iso_date(CONCESS.MADATE);
    }

    if (CONCESS.DETAIL === "") {
      CONCESS.DETAIL = 0;
    }

    if (CONCESS.SUPERFICIE !== "") {
      CONCESS.SUPERFICIE = parseFloat(CONCESS.SUPERFICIE);
    }

    return CONCESS;
  };

  _MiseAJourTemporaire = (temporaire) => {
    UpdateTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _AjoutTemporaire = (temporaire) => {
    AddTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _cree_chaine_emplacement = () => {
    return _.filter([
      _.filter([this.state.LIEU.EMPLACEMENT.TYPECAR, this.state.LIEU.EMPLACEMENT.CARRE]).join(" "),
      _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
      _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
    ]).join(" - ");
  };

  _handle_click_affecter_concession = (event) => {
    event.preventDefault();

    // VS : Si l'emplacement est affectée : ouverture popup
    if (this.state.LIEU.COCLEUNIK > 0) {
      this.setState({ DIALOGUE_EMPLACEMENT_OCCUPE: true });
      return;
    }

    let CONCESS = { ...this.state.SELECTED_CONCESSION_ID[0] };
    _.set(CONCESS, "LICLEUNIK", this.state.LIEU.LICLEUNIK);
    _.set(CONCESS, "EMPLACEMENT_LIEU", this._cree_chaine_emplacement());
    _.set(CONCESS, "TYPECONC", 1);

    CONCESS = this._UpdateConcessData(CONCESS);

    let diff = differenceObject(CONCESS, this._UpdateConcessData(this.state.SELECTED_CONCESSION_ID[0]));
    diff = _.assign(
      {
        id: CONCESS.id,
        COCLEUNIK: CONCESS.COCLEUNIK,
      },
      diff
    );

    miseAJourConcessAssociation(CONCESS, { ...this.state.LIEU }, this.state.IS_ORACLE, diff, this, this.state.SELECTED_CONCESSION_ID[0]);
  };

  render() {
    if (this.state.RESULT_LIST.length > 0) {
      if (this.state.RESULT_LIST.length === 1) {
        return <Redirect to={`/consulter/fiche-concession/${this.state.RESULT_LIST[0].COCLEUNIK}`} push />;
      } else {
        return <Redirect to={`/consulter/concessions/${this.state.SELECTED_CONCESSION}`} push />;
      }
    }

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <form style={styles.form} onSubmit={this._handle_submit_form_rechercheconcession}>
          <Downshift
            inputValue={this.state.SELECTED_CONCESSION}
            onInputValueChange={(inputValue) => {
              this.setState({
                SELECTED_CONCESSION_EMPL: "",
                SELECTED_CONCESSION_ORDRE: "",
                SELECTED_CONCESSION_ID: [],
              });
            }}
            onChange={(item) => {
              if (item !== null && item !== undefined) {
                this.setState({ SELECTED_CONCESSION: item.FAMILLE });
              }
            }}
            onSelect={(item) => {
              if (item !== null && item !== undefined) {
                this.setState({ SELECTED_CONCESSION_ID: [item], SELECTED_CONCESSION_EMPL: item.EMPL, SELECTED_CONCESSION_ORDRE: item.ANCNUM });
              }
            }}
            itemToString={(item) => (item ? item.FAMILLE : "")}
          >
            {({ getInputProps, getItemProps, isOpen, inputValue, highlightedIndex, clearSelection }) => (
              <div style={styles.downshift}>
                {renderInput({
                  fullWidth: true,
                  error: this.state.ERROR,
                  helperText: this.state.ERROR ? "Selectionner une concession" : null,
                  InputProps: getInputProps({
                    placeholder: "Nom de famille",
                    onChange: (event) => {
                      this.setState({
                        SELECTED_CONCESSION: event.target.value,
                        ERROR: false,
                      });
                    },
                    onKeyDown: (event) => {
                      if (event.key === "Enter" && isOpen) {
                        clearSelection();
                      }
                    },
                  }),
                })}
                {isOpen ? (
                  <Paper square>
                    {this._get_suggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion,
                        }),
                        highlightedIndex,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            )}
          </Downshift>

          {this.props.input_concession === "ajout" ? (
            <Downshift
              inputValue={this.state.SELECTED_CONCESSION_EMPL}
              onInputValueChange={(inputValue) => {
                this.setState({
                  SELECTED_CONCESSION: "",
                  SELECTED_CONCESSION_ORDRE: "",
                  SELECTED_CONCESSION_ID: [],
                });
              }}
              onChange={(item) => {
                if (item !== null && item !== undefined) {
                  this.setState({ SELECTED_CONCESSION_EMPL: item.EMPL });
                }
              }}
              onSelect={(item) => {
                if (item !== null && item !== undefined) {
                  this.setState({ SELECTED_CONCESSION_ID: [item], SELECTED_CONCESSION: item.FAMILLE, SELECTED_CONCESSION_ORDRE: item.ANCNUM });
                }
              }}
              itemToString={(item) => (item ? item.EMPL : "")}
            >
              {({ getInputProps, getItemProps, isOpen, inputValue, highlightedIndex, clearSelection }) => (
                <div style={styles.downshift}>
                  {renderInput({
                    fullWidth: true,
                    error: this.state.ERROR,
                    helperText: this.state.ERROR ? "Selectionner une concession" : null,
                    InputProps: getInputProps({
                      placeholder: "Ancien numéro",
                      onChange: (event) => {
                        this.setState({
                          SELECTED_CONCESSION_EMPL: event.target.value,
                          ERROR: false,
                        });
                      },
                      onKeyDown: (event) => {
                        if (event.key === "Enter" && isOpen) {
                          clearSelection();
                        }
                      },
                    }),
                  })}
                  {isOpen ? (
                    <Paper square>
                      {this._get_suggestions_empl(inputValue).map((suggestion, index) =>
                        renderSuggestionEmpl({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion,
                          }),
                          highlightedIndex,
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              )}
            </Downshift>
          ) : (
            <Downshift
              inputValue={this.state.SELECTED_CONCESSION_ORDRE}
              onInputValueChange={(inputValue) => {
                this.setState({
                  SELECTED_CONCESSION: "",
                  SELECTED_CONCESSION_EMPL: "",
                  SELECTED_CONCESSION_ID: [],
                });
              }}
              onChange={(item) => {
                if (item !== null && item !== undefined) {
                  this.setState({ SELECTED_CONCESSION_ORDRE: item.ANCNUM });
                }
              }}
              onSelect={(item) => {
                if (item !== null && item !== undefined) {
                  this.setState({ SELECTED_CONCESSION_ID: [item], SELECTED_CONCESSION: item.FAMILLE });
                }
              }}
              itemToString={(item) => (item ? item.ANCNUM : "")}
            >
              {({ getInputProps, getItemProps, isOpen, inputValue, highlightedIndex, clearSelection }) => (
                <div style={styles.downshift}>
                  {renderInput({
                    fullWidth: true,
                    error: this.state.ERROR,
                    helperText: this.state.ERROR ? "Selectionner une concession" : null,
                    InputProps: getInputProps({
                      placeholder: "N° d'ordre",
                      onChange: (event) => {
                        this.setState({
                          SELECTED_CONCESSION_ORDRE: event.target.value,
                          ERROR: false,
                        });
                      },
                      onKeyDown: (event) => {
                        if (event.key === "Enter" && isOpen) {
                          clearSelection();
                        }
                      },
                    }),
                  })}
                  {isOpen ? (
                    <Paper square>
                      {this._get_suggestions_ordre(inputValue).map((suggestion, index) =>
                        renderSuggestionOrdre({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion,
                          }),
                          highlightedIndex,
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              )}
            </Downshift>
          )}

          <Button variant="contained" color="secondary" type="submit">
            {this.props.input_concession === "ajout" ? "Associer" : "Rechercher"}
          </Button>
        </form>

        {this.state.LIEU !== undefined && this.state.LIEU !== "" && this.state.SELECTED_CONCESSION_ID.length === 1 ? (
          <Dialog open={this.state.DIALOG_AFFECTER_CONCESSION} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{`Confirmez-vous l'association de la concession ${
                this.state.SELECTED_CONCESSION_ID[0].FAMILLE
              } à l'emplacement ${this._cree_chaine_emplacement()} ?`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({ DIALOG_AFFECTER_CONCESSION: false })}>
                Non
              </Button>
              <Button color="primary" onClick={this._handle_click_affecter_concession}>
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        <Dialog open={this.state.DIALOG_EMPLACEMENT_OCCUPE} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Opération impossible. L'emplacement est déjà occupé.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ DIALOG_EMPLACEMENT_OCCUPE: false })}>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>

        {this.props.input_concession === "ajout" ? (
          <DialogAccessRight state={this.state.droit_util_modif_concession} onChange={(value) => this.setState({ droit_util_modif_concession: value })} />
        ) : null}
      </Paper>
    );
  }
}

export default withCookies(withRouter(InputRechercheConcession));
