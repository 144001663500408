import Dexie from "dexie";

var _ = require("lodash");

var version = 1;
var db = new Dexie("GARDIEN");

var schema = {
  CLIENT: "++id,IDCLIENT,ORACLE",
  GNUTIL: "++id,IDUTIL,[NOM+PASS]",
  PARAM: "++id",
  DEFUNT: "++id,D0CLEUNIK,LICLEUNIK,COCLEUNIK,TYPECIME,NUMCENDRE",
  DEFUNT_S: "++id",
  LIEU: "++id,LICLEUNIK,COCLEUNIK,CIMEL,TOMBEL,DUREEL,POSNUM,SITUATION,SITUATION_ALL_1,SITUATION_ALL_2,SITUATION_ALL_3,SITUATION_ALL_4,LIEU_MULTIPLE_PRINC_VIRTUELLE",
  CENDRE: "++id,NUMCENDRE,TYPECIME,LICLEUNIK",
  CIMETIER: "++id,NUMCIME,NUMPLAN,IMPRIMECOLUMB",
  PLAN: "++id,NUMPLAN",
  ZONE: "++id,ZOCLEUNIK,TYPEZONE,CIM,ZOCLEUNIK_PARENT,[CIM+ZONE_DESSUS_TYPE]",
  CONCESS: "++id,COCLEUNIK,LICLEUNIK,DUREE,DETAIL,TYPECIME,TYPECONC,IDPROCEDUREABANDON,INTERLOCUTEUR_C0CLEUNIK",
  CONCAIRE: "++id,C0CLEUNIK,COCLEUNIK",
  TYPETB: "++id,NUMTB",
  PHOTOCONC: "++id,IDPHOTOCONC,COCLEUNIK,*I0CLEUNIK",
  INTERVEN: "++id,I0CLEUNIK,IDMOUVEMENT,SOCLEUNIK,LICLEUNIK,COCLEUNIK,TYPECIME",
  INTERVEN_DEFUNT: "++id,IDINTERVEN_DEFUNT,D0CLEUNIK,I0CLEUNIK,IDMOUVEMENT",
  NATINTERV: "++id,IDNATINTERV,I0CLEUNIK",
  PROCEDUREABANDON: "++id,IDPROCEDUREABANDON",
  DUREE: "++id,NUMDURE",
  SOCIETE: "++id,SOCLEUNIK",
  UTIL: "++id,UTCLEUNIK,AGENT_TERRAIN,[LOGIN+PASS]",
  GENS: "++id,IDGENS,AGENT_TERRAIN,UTCLEUNIK",
  AGENDATACHE: "++id,IDAGENDA,TYPECIME,LICLEUNIK,D0CLEUNIK,IDSOCIETE,IDINTERVEN",
  MOUVEMENT: "++id,IDMOUVEMENT,D0CLEUNIK",
  TEMPORAIRE: "++id,ID_TEMPORAIRE,ID_CLIENT,ID_TYPE_TEMPORAIRE,ID_APPLICATION_GARDIENS,ID_GESCIME",
  SVG: "++id,NUMCIME,LICLEUNIK",
  THANATOMORPHOSE: "++id,IDTHANATOMORPHOSE",
  ACCESSIBILITE: "++id,IDACCESSIBILITE",
  ETAT_TERRAIN: "++id,IDETAT_TERRAIN",
  CONCESS_ETAT_ECHEANCE: "++id,IDCONCESS_ETAT_ECHEANCE",
  MATERIAU: "++id,IDMATERIAU",
  PA: "++id,CODECIME,CODETYPE,CODEDURE,CODENOMBRE",
  LIGNE_ACTION: "++id,IDACTION_UTIL,DROITAJOUT,DROITLECTURE,DROITMODIF,UTCLEUNIK,NUMACTION",
};

db.version(version).stores(schema);
db = modifySchema("CONCESS", "++id,COCLEUNIK,LICLEUNIK,DUREE,DETAIL,TYPECIME,TYPECONC,IDPROCEDUREABANDON,INTERLOCUTEUR_C0CLEUNIK,DATEEXPIRA");

function modifySchema(tableName, tableSchema) {
  db.close();

  // Now use statically opening to add table:
  var upgraderDB = new Dexie(db.name);

  const currentSchema = db.tables.reduce((result, { name, schema }) => {
    result[name] = [schema.primKey.src, ...schema.indexes.map((idx) => idx.src)].join(",");
    return result;
  }, {});

  upgraderDB.version(db.verno).stores(currentSchema);

  var schemaChanges = _.cloneDeep(currentSchema);
  schemaChanges[tableName] = tableSchema;

  upgraderDB.version(db.verno + 1).stores(schemaChanges);

  return upgraderDB;
}

// console.log("Version", db.verno);
// console.log(
//   "Tables",
//   db.tables.map(({ name, schema }) => ({
//     name,
//     schema,
//   }))
// );

export default db;
