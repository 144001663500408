import db from "../Db";
import { handleError } from "../Function";
import { GetCouleurEmpl } from "../Get/Cimetier";
import { GetSvgByIdLieu } from "./../Get/Svg";

var UpdateSvg = (LIEU) => {
  return GetCouleurEmpl(LIEU).then((couleur) => {
    return GetSvgByIdLieu(LIEU.LICLEUNIK).then((svg) => {
      if (svg === undefined) return;

      svg.COULEUR = couleur;
      svg.GELE = LIEU.EMPLACEMENT.GELE;
      svg.MONUMENTPRESENT = LIEU.EMPLACEMENT.MONUMENTPRESENT;
      svg.PASSE_PIED = LIEU.EMPLACEMENT.PASSE_PIED;
      svg.POSITIONSTELE = LIEU.EMPLACEMENT.POSITIONSTELE;

      return db
        .open()
        .then(() => {
          return db.SVG.put(svg).catch((err) => {
            return handleError(err);
          });
        })
        .catch((err) => {
          return handleError(err);
        });
    });
  });
};

export default UpdateSvg;
