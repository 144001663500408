import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

var _ = require("lodash");

export async function CreateConcessEtatEcheance(headers) {
  const _concess_etat_echeance = await GetApiData("CONCESS_ETAT_ECHEANCE", headers);

  if (_concess_etat_echeance.Message !== undefined) {
    return _concess_etat_echeance;
  }

  var drops = [];
  _.forEach(_concess_etat_echeance, (concess_etat_echeance) => {
    drops.push({
      IDCONCESS_ETAT_ECHEANCE: concess_etat_echeance.IDCONCESS_ETAT_ECHEANCE,
      LIBELLE: concess_etat_echeance.LIBELLE.trim(),
      ARCHIVER: concess_etat_echeance.ARCHIVER,
      ORDRE: concess_etat_echeance.ORDRE,
    });
  });

  return await db
    .open()
    .then(() => {
      return db.CONCESS_ETAT_ECHEANCE.bulkAdd(drops)
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
