import db from "../Db";
import { handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteTypetb from "../Delete/Typetb_MDF";

var _ = require("lodash");

export async function UpdateTypetb_MDF(headers) {
  const _typtb = await GetApiData("TYPETB", headers);

  if (_typtb.Message !== undefined) {
    return _typtb;
  }

  return await DeleteTypetb().then(() => {
    var drops = [];
    _.forEach(_typtb, (typetb) => {
      drops.push({
        NUMTB: typetb.NUMTB,
        NOMTOMBE: typetb.NOMTOMBE,
      });
    });

    return db
      .open()
      .then(() => {
        return db.TYPETB.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
