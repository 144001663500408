import React, { Component } from "react";
import { withWidth, Table, TableHead, TableRow, TableCell, TableBody, Card, CardActions, Typography, CardMedia, Dialog, Slide, AppBar, Toolbar, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { GetPhotoconcByI0cleunik } from "../../../../Helpers/Get/Photoconc";
import { IsOracle } from "./../../../../Helpers/Test/IsOracle";
import { GetIntervenById } from "../../../../Helpers/Get/Interven";
import { GetTemporaireForColor } from "../../../../Helpers/Get/Temporaire";
import { Cree_date_chaine } from "../../../../Helpers/Function";

import b64toBlob from "b64-to-blob";
import no_photo from "../../../../Images/no_photo.json";
import { GetParam } from "./../../../../Helpers/Get/Param";

var _ = require("lodash");

const styles = {
  table: {
    marginTop: 24,
    tableLayout: "fixed",
  },
  tablecells: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  photoconc_card: {
    border: "1px solid grey",
    cursor: "pointer",
    flex: 1,
    margin: "auto",
    marginBottom: 10,
    maxWidth: 160,
    minWidth: 160,
    textAlign: "center",
    width: 100,
  },
  photoconc_card_dialog: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    margin: "120px 50px 50px 50px",
  },
  photoconc_cardaction: {
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 3,
  },
  photoconc_cardmedia: {
    backgroundColor: "whitesmoke",
    backgroundSize: "contain",
    borderTop: "1px solid lightgrey",
    height: 0,
    paddingTop: "100%",
  },
  photoconc_cardmedia_dialog: {
    backgroundSize: "contain",
    height: 0,
    paddingTop: "60%",
  },
  appBar: {
    position: "relative",
  },
  typography: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CTFTPhotographieConstat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_oracle: false,
      photoconc: [],
      interven: "",
      champ_modifie: [],
      param: "",
      photoDialogOpen: false,
      photoDialogPhoto: null,
    };
  }

  componentDidMount = () => {
    const new_photo = {
      ABANDON_1: false,
      ABANDON_2: false,
      COCLEUNIK: this.props.id_concess,
      CONSTAT_TRAVAUX: 0,
      DATEAJOUT: null,
      I0CLEUNIK: this.props.id_intervention.toString(),
      MONUMENT_ETAT: "",
      NOTES: "",
      PHOTO: b64toBlob(no_photo.image, "image/png"),
      PRINCIPAL: 0,
      SEMELLE_ETAT: "",
      MONUMENT_GAUCHE: "",
      MONUMENT_DROITE: "",
      MONUMENT_DEVANT: "",
      MONUMENT_DERRIERE: "",
      NO_PHOTO: true,
    };

    IsOracle().then((oracle) => {
      this.setState({ is_oracle: oracle });

      GetParam().then((param) => {
        this.setState({ param: param });
      });

      GetPhotoconcByI0cleunik(this.props.id_intervention.toString()).then((photo) => {
        _.forEach(photo, (p) => {
          if (p.PHOTO === "") {
            p.PHOTO = b64toBlob(no_photo.image, "image/png");
          }
        });

        if (_.find(photo, ["CONSTAT_TRAVAUX", 1]) === undefined) {
          let np = { ...new_photo };
          np.CONSTAT_TRAVAUX = 1;
          photo.splice(0, 0, np);
        }

        if (_.find(photo, ["CONSTAT_TRAVAUX", 2]) === undefined) {
          let np = { ...new_photo };
          np.CONSTAT_TRAVAUX = 2;
          photo.splice(1, 0, np);
        }

        if (photo[0].CONSTAT_TRAVAUX === 2) {
          _.reverse(photo);
        }

        let pc = _.last(
          _.filter(photo, (pa) => {
            return pa.CONSTAT_TRAVAUX === 1;
          })
        );

        let sc = _.last(
          _.filter(photo, (pa) => {
            return pa.CONSTAT_TRAVAUX === 2;
          })
        );

        this.setState({ photoconc: [pc, sc] }, () => {
          GetIntervenById(this.props.id_intervention).then((interven) => {
            this.setState({ interven: interven });

            setTimeout(() => {
              let field = [];
              _.forEach(this.state.photoconc, (p) => {
                const temporaire = GetTemporaireForColor("PHOTOCONC", oracle ? "IDEVOL_ETAT" : "IDPHOTOCONC", p.IDPHOTOCONC);

                temporaire.then((temp) => {
                  if (temp.length > 0) {
                    _.forEach(temp, (t) => {
                      let color = "red";

                      if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                        color = "blue";
                      }

                      if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                        color = "green";
                      }

                      if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                        color = "primary";
                      }

                      var element_temp = t.TABLE_MODIFIE_JSON;
                      element_temp = _.omit(element_temp, ["id"]);

                      let sub_field = {};
                      for (var key in element_temp) {
                        sub_field[key] = color;

                        if (key === (oracle ? "IDEVOL_ETAT" : "IDPHOTOCONC")) sub_field[key] = oracle ? element_temp.IDEVOL_ETAT : element_temp.IDPHOTOCONC;
                      }

                      field.push(sub_field);
                    });

                    this.setState({ champ_modifie: field });
                  }
                });
              });
            }, 500);
          });
        });
      });
    });
  };

  _get_color_temporaire = (photoconc, index, champ) => {
    if (photoconc[index] !== undefined && photoconc[index].IDPHOTOCONC !== undefined) {
      let filter = _.filter(this.state.champ_modifie, (field) => {
        return field.IDPHOTOCONC === photoconc[index].IDPHOTOCONC || field.IDEVOL_ETAT === photoconc[index].IDPHOTOCONC;
      });

      let color = "primary";

      for (let i = 0; i < filter.length; i++) {
        const element = filter[i];

        if (_.has(_.find([element], ["IDPHOTOCONC", photoconc[index].IDPHOTOCONC]) || _.find([element], ["IDEVOL_ETAT", photoconc[index].IDPHOTOCONC]), champ)) {
          color = _.get(_.find([element], ["IDPHOTOCONC", photoconc[index].IDPHOTOCONC]) || _.find([element], ["IDEVOL_ETAT", photoconc[index].IDPHOTOCONC]), champ);
        }
      }

      return color;
    } else {
      return "primary";
    }
  };

  zoomOnImage = (photo) => (event) => {
    event.preventDefault();

    if (photo.NO_PHOTO === true) {
      this.props.MODIFIER_ref.current.click();
    } else {
      this.setState({ photoDialogOpen: true, photoDialogPhoto: photo.PHOTO });
    }
  };

  render() {
    const { width } = this.props;

    return width === "xs" || width === "sm" ? (
      <div>
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Constat avant travaux</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell style={styles.tablecells}>
                {_.filter(this.state.photoconc, (photo) => {
                  return photo.CONSTAT_TRAVAUX === 1;
                }).map((photo, index) =>
                  photo.PHOTO !== "" ? (
                    <Card
                      key={index}
                      style={{
                        ...styles.photoconc_card,
                        border: `1px solid ${this._get_color_temporaire(this.state.photoconc, 0, "PHOTO") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 0, "PHOTO")}`,
                      }}
                    >
                      <CardActions disableSpacing style={styles.photoconc_cardaction}>
                        <Typography variant="body2">{photo.DATEAJOUT !== null && !_.includes(photo.DATEAJOUT, "1900-01-01") ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>

                        <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                          {photo.PRINCIPAL ? "Référente" : null}
                        </Typography>
                      </CardActions>

                      <CardMedia onClick={this.zoomOnImage(photo)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                    </Card>
                  ) : null
                )}
              </TableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat des lieux</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  ...styles.tablecells,
                  whiteSpace: "pre-line",
                  color: this._get_color_temporaire(this.state.photoconc, 0, this.state.is_oracle ? "ETAT_EVOL" : "NOTES"),
                }}
              >
                {this.state.photoconc[0] !== undefined ? this.state.photoconc[0].NOTES : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat du monument</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_ETAT") }}>
                {this.state.photoconc[0] !== undefined ? this.state.photoconc[0].MONUMENT_ETAT : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>{this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 0, "SEMELLE_ETAT") }}>
                {this.state.photoconc[0] !== undefined ? this.state.photoconc[0].SEMELLE_ETAT : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à gauche : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_GAUCHE") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_GAUCHE === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_GAUCHE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à droite : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_DROITE") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_DROITE === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_DROITE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de devant : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_DEVANT") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_DEVANT === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_DEVANT) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de derrière : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_DERRIERE") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_DERRIERE === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_DERRIERE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            {this.state.photoconc[0] !== undefined && this.state.photoconc[0].AGENT ? (
              <TableRow>
                <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Agent : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "AGENT") }}>{this.state.photoconc[0].AGENT}</div>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[0] !== undefined && this.state.photoconc[0].SIGNATURE_AGENT ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_AGENT") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_AGENT")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'agent" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[0].SIGNATURE_AGENT)} />
                  </div>
                ) : (
                  <div>Constat non signé par l'agent</div>
                )}
              </TableCell>
            </TableRow>

            {this.state.photoconc[0] !== undefined && this.state.photoconc[0].SOCIETE ? (
              <TableRow>
                <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Opérateur : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "SOCIETE") }}>{this.state.photoconc[0].SOCIETE}</div>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[0] !== undefined && this.state.photoconc[0].SIGNATURE_SOCIETE ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_SOCIETE") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_SOCIETE")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'opérateur" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[0].SIGNATURE_SOCIETE)} />
                  </div>
                ) : (
                  <div>Constat non signé par l'opérateur</div>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Constat après travaux</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell style={styles.tablecells}>
                {_.filter(this.state.photoconc, (photo) => {
                  return photo.CONSTAT_TRAVAUX === 2;
                }).map((photo, index) =>
                  photo.PHOTO !== "" ? (
                    <Card
                      key={index}
                      style={{
                        ...styles.photoconc_card,
                        border: `1px solid ${this._get_color_temporaire(this.state.photoconc, 1, "PHOTO") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 1, "PHOTO")}`,
                      }}
                    >
                      <CardActions disableSpacing style={styles.photoconc_cardaction}>
                        <Typography variant="body2">{photo.DATEAJOUT !== null && !_.includes(photo.DATEAJOUT, "1900-01-01") ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>

                        <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                          {photo.PRINCIPAL ? "Référente" : null}
                        </Typography>
                      </CardActions>

                      <CardMedia onClick={this.zoomOnImage(photo)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                    </Card>
                  ) : null
                )}
              </TableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat des lieux</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  ...styles.tablecells,
                  whiteSpace: "pre-line",
                  color: this._get_color_temporaire(this.state.photoconc, 1, this.state.is_oracle ? "ETAT_EVOL" : "NOTES"),
                }}
              >
                {this.state.photoconc[1] !== undefined ? this.state.photoconc[1].NOTES : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat du monument</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_ETAT") }}>
                {this.state.photoconc[1] !== undefined ? this.state.photoconc[1].MONUMENT_ETAT : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>{this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 1, "SEMELLE_ETAT") }}>
                {this.state.photoconc[1] !== undefined ? this.state.photoconc[1].SEMELLE_ETAT : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à gauche : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_GAUCHE") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_GAUCHE === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_GAUCHE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à droite : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_DROITE") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_DROITE === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_DROITE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de devant : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_DEVANT") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_DEVANT === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_DEVANT) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de derrière : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_DERRIERE") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_DERRIERE === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_DERRIERE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            {this.state.photoconc[1] !== undefined && this.state.photoconc[1].AGENT ? (
              <TableRow>
                <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Agent : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "AGENT") }}>{this.state.photoconc[1].AGENT}</div>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[1] !== undefined && this.state.photoconc[1].SIGNATURE_AGENT ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_AGENT") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_AGENT")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'agent" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[1].SIGNATURE_AGENT)} />
                  </div>
                ) : (
                  <div>Constat non signé par l'agent</div>
                )}
              </TableCell>
            </TableRow>

            {this.state.photoconc[1] !== undefined && this.state.photoconc[1].SOCIETE ? (
              <TableRow>
                <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Opérateur : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "SOCIETE") }}>{this.state.photoconc[1].SOCIETE}</div>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[1] !== undefined && this.state.photoconc[1].SIGNATURE_SOCIETE ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_SOCIETE") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_SOCIETE")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'opérateur" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[1].SIGNATURE_SOCIETE)} />
                  </div>
                ) : (
                  <div>Constat non signé par l'opérateur</div>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {this.state.photoDialogPhoto !== null ? (
          <Dialog
            fullScreen
            open={this.state.photoDialogOpen}
            onClose={() => {
              this.setState({ photoDialogOpen: false });
            }}
            TransitionComponent={Transition}
          >
            <AppBar className={styles.appBar}>
              <Toolbar>
                <Typography variant="h6" style={styles.typography}></Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({ photoDialogOpen: false });
                  }}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Card style={styles.photoconc_card_dialog} elevation={0}>
              <CardMedia style={styles.photoconc_cardmedia_dialog} image={window.URL.createObjectURL(this.state.photoDialogPhoto)} />
            </Card>
          </Dialog>
        ) : null}
      </div>
    ) : (
      <div>
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Constat avant travaux</TableCell>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Constat après travaux</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell style={styles.tablecells}>
                {_.filter(this.state.photoconc, (photo) => {
                  return photo.CONSTAT_TRAVAUX === 1;
                }).map((photo, index) =>
                  photo.PHOTO !== "" ? (
                    <Card
                      key={index}
                      style={{
                        ...styles.photoconc_card,
                        border: `1px solid ${this._get_color_temporaire(this.state.photoconc, 0, "PHOTO") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 0, "PHOTO")}`,
                      }}
                    >
                      <CardActions disableSpacing style={styles.photoconc_cardaction}>
                        <Typography variant="body2">{photo.DATEAJOUT !== null && !_.includes(photo.DATEAJOUT, "1900-01-01") ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>

                        <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                          {photo.PRINCIPAL ? "Référente" : null}
                        </Typography>
                      </CardActions>

                      <CardMedia onClick={this.zoomOnImage(photo)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                    </Card>
                  ) : null
                )}
              </TableCell>

              <TableCell style={styles.tablecells}>
                {_.filter(this.state.photoconc, (photo) => {
                  return photo.CONSTAT_TRAVAUX === 2;
                }).map((photo, index) =>
                  photo.PHOTO !== "" ? (
                    <Card
                      key={index}
                      style={{
                        ...styles.photoconc_card,
                        border: `1px solid ${this._get_color_temporaire(this.state.photoconc, 1, "PHOTO") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 1, "PHOTO")}`,
                      }}
                    >
                      <CardActions disableSpacing style={styles.photoconc_cardaction}>
                        <Typography variant="body2">{photo.DATEAJOUT !== null && !_.includes(photo.DATEAJOUT, "1900-01-01") ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>

                        <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                          {photo.PRINCIPAL ? "Référente" : null}
                        </Typography>
                      </CardActions>

                      <CardMedia onClick={this.zoomOnImage(photo)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                    </Card>
                  ) : null
                )}
              </TableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat des lieux</TableCell>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat des lieux</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  ...styles.tablecells,
                  whiteSpace: "pre-line",
                  color: this._get_color_temporaire(this.state.photoconc, 0, this.state.is_oracle ? "ETAT_EVOL" : "NOTES"),
                }}
              >
                {this.state.photoconc[0] !== undefined ? this.state.photoconc[0].NOTES : ""}
              </TableCell>

              <TableCell
                style={{
                  ...styles.tablecells,
                  whiteSpace: "pre-line",
                  color: this._get_color_temporaire(this.state.photoconc, 1, this.state.is_oracle ? "ETAT_EVOL" : "NOTES"),
                }}
              >
                {this.state.photoconc[1] !== undefined ? this.state.photoconc[1].NOTES : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat du monument</TableCell>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>Etat du monument</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_ETAT") }}>
                {this.state.photoconc[0] !== undefined ? this.state.photoconc[0].MONUMENT_ETAT : ""}
              </TableCell>

              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_ETAT") }}>
                {this.state.photoconc[1] !== undefined ? this.state.photoconc[1].MONUMENT_ETAT : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>{this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}</TableCell>
              <TableCell style={{ ...styles.tablecells, textDecoration: "underline" }}>{this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 0, "SEMELLE_ETAT") }}>
                {this.state.photoconc[0] !== undefined ? this.state.photoconc[0].SEMELLE_ETAT : ""}
              </TableCell>

              <TableCell style={{ ...styles.tablecells, color: this._get_color_temporaire(this.state.photoconc, 1, "SEMELLE_ETAT") }}>
                {this.state.photoconc[1] !== undefined ? this.state.photoconc[1].SEMELLE_ETAT : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à gauche : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_GAUCHE") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_GAUCHE === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_GAUCHE) : ""}
                  </div>
                </div>
              </TableCell>

              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à gauche : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_GAUCHE") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_GAUCHE === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_GAUCHE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à droite : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_DROITE") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_DROITE === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_DROITE) : ""}
                  </div>
                </div>
              </TableCell>

              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument à droite : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_DROITE") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_DROITE === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_DROITE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de devant : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_DEVANT") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_DEVANT === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_DEVANT) : ""}
                  </div>
                </div>
              </TableCell>

              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de devant : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_DEVANT") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_DEVANT === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_DEVANT) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de derrière : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "MONUMENT_DERRIERE") }}>
                    {this.state.photoconc[0] !== undefined ? (this.state.photoconc[0].MONUMENT_DERRIERE === "PB" ? "Problème" : this.state.photoconc[0].MONUMENT_DERRIERE) : ""}
                  </div>
                </div>
              </TableCell>

              <TableCell style={styles.tablecells}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, textDecoration: "underline" }}>Monument de derrière : </div>
                  <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "MONUMENT_DERRIERE") }}>
                    {this.state.photoconc[1] !== undefined ? (this.state.photoconc[1].MONUMENT_DERRIERE === "PB" ? "Problème" : this.state.photoconc[1].MONUMENT_DERRIERE) : ""}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                {this.state.photoconc[0] !== undefined && this.state.photoconc[0].AGENT ? (
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Agent : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "AGENT") }}>{this.state.photoconc[0].AGENT}</div>
                  </div>
                ) : (
                  <div>Constat non signé par l'agent</div>
                )}
              </TableCell>

              <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                {this.state.photoconc[1] !== undefined && this.state.photoconc[1].AGENT ? (
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Agent : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "AGENT") }}>{this.state.photoconc[1].AGENT}</div>
                  </div>
                ) : (
                  <div>Constat non signé par l'agent</div>
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[0] !== undefined && this.state.photoconc[0].SIGNATURE_AGENT ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_AGENT") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_AGENT")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'agent" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[0].SIGNATURE_AGENT)} />
                  </div>
                ) : null}
              </TableCell>

              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[1] !== undefined && this.state.photoconc[1].SIGNATURE_AGENT ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_AGENT") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_AGENT")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'agent" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[1].SIGNATURE_AGENT)} />
                  </div>
                ) : null}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                {this.state.photoconc[0] !== undefined && this.state.photoconc[0].SOCIETE ? (
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Opérateur : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 0, "SOCIETE") }}>{this.state.photoconc[0].SOCIETE}</div>
                  </div>
                ) : (
                  <div>Constat non signé par l'opérateur</div>
                )}
              </TableCell>

              <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                {this.state.photoconc[1] !== undefined && this.state.photoconc[1].SOCIETE ? (
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, textDecoration: "underline" }}>Opérateur : </div>
                    <div style={{ color: this._get_color_temporaire(this.state.photoconc, 1, "SOCIETE") }}>{this.state.photoconc[1].SOCIETE}</div>
                  </div>
                ) : (
                  <div>Constat non signé par l'opérateur</div>
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[0] !== undefined && this.state.photoconc[0].SIGNATURE_SOCIETE ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_SOCIETE") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 0, "SIGNATURE_SOCIETE")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'opérateur" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[0].SIGNATURE_SOCIETE)} />
                  </div>
                ) : null}
              </TableCell>

              <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                {this.state.photoconc[1] !== undefined && this.state.photoconc[1].SIGNATURE_SOCIETE ? (
                  <div
                    style={{
                      border: `1px solid ${
                        this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_SOCIETE") === "primary" ? "grey" : this._get_color_temporaire(this.state.photoconc, 1, "SIGNATURE_SOCIETE")
                      }`,
                      borderRadius: 10,
                      width: 260,
                      height: 200,
                      margin: "0 auto",
                    }}
                  >
                    <img alt="signature de l'opérateur" style={{ width: 260, height: 200 }} src={window.URL.createObjectURL(this.state.photoconc[1].SIGNATURE_SOCIETE)} />
                  </div>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {this.state.photoDialogPhoto !== null ? (
          <Dialog
            fullScreen
            open={this.state.photoDialogOpen}
            onClose={() => {
              this.setState({ photoDialogOpen: false });
            }}
            TransitionComponent={Transition}
          >
            <AppBar className={styles.appBar}>
              <Toolbar>
                <Typography variant="h6" style={styles.typography}></Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({ photoDialogOpen: false });
                  }}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Card style={styles.photoconc_card_dialog} elevation={0}>
              <CardMedia style={styles.photoconc_cardmedia_dialog} image={window.URL.createObjectURL(this.state.photoDialogPhoto)} />
            </Card>
          </Dialog>
        ) : null}
      </div>
    );
  }
}

export default withWidth()(CTFTPhotographieConstat);
