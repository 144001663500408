import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";

import { GetAllCendre } from "../../Helpers/Get/Cendre";

const styles = {
  grid: {
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 16,
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
    paddingTop: 16,
  },
  empty_div: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: 16,
  },
};

class CListeEquipement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cendre: [],
      redirect: null,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    GetAllCendre().then((cendre) => {
      this.setState({
        cendre: cendre,
      });
    });
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Grid container justify="center" style={styles.grid}>
        <Grid item xs={11} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <Typography variant="h6" style={{ marginBottom: 16 }}>
              Liste des équipements
            </Typography>

            {this.state.cendre.length > 0 ? (
              <List component="nav">
                {this.state.cendre.map((cendre, index) => (
                  <div key={index}>
                    <ListItem
                      button
                      onClick={() =>
                        this.setState({
                          redirect: `/consulter/list-equipement_cendre/${cendre.NUMCENDRE}`,
                        })
                      }
                    >
                      <ListItemText primary={`${cendre.NOMCENDRE}`} />
                    </ListItem>

                    <Divider />
                  </div>
                ))}
              </List>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default CListeEquipement;
