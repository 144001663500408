import db from "../Db";

export function GetAllTemporaire() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TEMPORAIRE.toArray().then((temporaire) => {
      return resolve(temporaire);
    });
  });
}

export function GetTemporaire(TABLE, CLE, ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TEMPORAIRE.count((count) => {
      if (count === 0) {
        return resolve(undefined);
      }

      var i = 0;
      var tempTable = [];
      db.TEMPORAIRE.each((item) => {
        if (item.TYPE_D_ENTITE === TABLE && item.TABLE_MODIFIE_JSON[CLE] === parseInt(ID)) {
          tempTable.push(item);
        }

        i++;

        if (i === count) {
          return resolve(tempTable.pop());
        }
      });
    });
  });
}

export function GetTemporairePhoto(TABLE, CLE, ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TEMPORAIRE.count((count) => {
      if (count === 0) {
        return resolve(undefined);
      }

      var i = 0;
      var tempTable = [];
      db.TEMPORAIRE.each((item) => {
        if (item.TYPE_D_ENTITE === TABLE && item.TABLE_MODIFIE_JSON[CLE] === parseInt(ID)) {
          tempTable.push(item);
        }

        i++;

        if (i === count) {
          return resolve(tempTable);
        }
      });
    });
  });
}

export function GetTemporaireNonEnvoye(TABLE, CLE, ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TEMPORAIRE.count((count) => {
      if (count === 0) {
        return resolve(undefined);
      }

      var i = 0;
      db.TEMPORAIRE.each((item) => {
        if (item.TYPE_D_ENTITE === TABLE && item.TABLE_MODIFIE_JSON[CLE] === parseInt(ID) && item.DATE_DE_CREATION === null) {
          return resolve(item);
        }

        i++;

        if (i === count) {
          return resolve(undefined);
        }
      });
    });
  });
}

export function GetTemporaireById(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TEMPORAIRE.get(parseInt(ID)).then((temporaire) => {
      return resolve(temporaire);
    });
  });
}

export function GetTemporaireForColor(TABLE, CLE, ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TEMPORAIRE.count((count) => {
      if (count === 0) {
        return resolve([]);
      }

      var i = 0;
      var field = [];
      db.TEMPORAIRE.each((item) => {
        if (item.TYPE_D_ENTITE === TABLE && parseInt(item.TABLE_MODIFIE_JSON[CLE]) === parseInt(ID) && item.DATE_TRAITE_DANS_GESCIME === null) {
          field.push(item);
        }

        i++;

        if (i === count) {
          return resolve(field);
        }
      });
    });
  });
}

// export function GetGNTemporaireByTable(TABLE, ID) {
//   return new Promise(function (resolve, reject) {
//     if (!db.isOpen()) {
//       db.open();
//     }

//     db.TEMPORAIRE.each((item) => {
//       if (
//         item.TYPE_D_ENTITE === TABLE &&
//         item.TABLE_MODIFIE_JSON.D0CLEUNIK === parseInt(ID)
//       ) {
//         return resolve(item);
//       }
//     });
//   });
// }
