import db from "../Db";

export function GetAllAccessibilite() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.ACCESSIBILITE.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetAccessibiliteById(IDACCESSIBILITE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.ACCESSIBILITE.where("IDACCESSIBILITE")
      .equals(parseInt(IDACCESSIBILITE))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
