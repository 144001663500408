import db from "../Db";
import b64toBlob from "b64-to-blob";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateZone(headers) {
  const _zoneCount = await GetApiData("ZONECount", headers);

  if (_zoneCount.Message !== undefined) {
    return _zoneCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("ZONE", _zoneCount, 50, headers, (getProgress, getZone) => {
      if (getZone.Message !== undefined) {
        return res(getZone);
      }

      var drops = [];
      _.forEach(getZone, (zone) => {
        var blob = "";
        if (zone.IMAGESP !== "") blob = b64toBlob(zone.IMAGESP, "image/png");

        drops.push({
          ZOCLEUNIK: zone.ZOCLEUNIK,
          COORDONNEES: {
            X1: zone.X1,
            Y1: zone.Y1,
            X2: zone.X2,
            Y2: zone.Y2,
          },
          TYPEZONE: zone.TYPEZONE,
          CIM: zone.CIM,
          IMAGESP: blob,
          ZOCLEUNIK_PARENT: zone.ZOCLEUNIK_PARENT !== undefined ? zone.ZOCLEUNIK_PARENT : 0,
          ZONE_DESSUS_TYPE: zone.ZONE_DESSUS_TYPE,
        });
      });

      db.open()
        .then(() => {
          db.ZONE.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _zoneCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_zoneCount} zones`);

    return val;
  });
}
