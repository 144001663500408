var TemporaireEnErreur = (function () {
  var temporaire = [];

  var getTemporaireEnErreur = function () {
    return temporaire;
  };

  var setTemporaireEnErreur = function (currentTemporaire) {
    temporaire.push(currentTemporaire);
  };

  var resetTemporaireEnErreur = function () {
    temporaire = [];
  };

  return {
    getTemporaireEnErreur: getTemporaireEnErreur,
    setTemporaireEnErreur: setTemporaireEnErreur,
    resetTemporaireEnErreur: resetTemporaireEnErreur,
  };
})();

export default TemporaireEnErreur;
