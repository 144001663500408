import jsonpackage from "../../../package.json";
import { handleError } from "../Function";

export async function GetNewDateSynchro(cleApi, dateSynchro) {
  const result = await fetch(`${jsonpackage.urlapi}Restore`, {
    mode: "cors",
    headers: {
      _cle: cleApi,
      _date_synchro: dateSynchro,
      "Content-Type": "application/json",
    },
  }).catch((err) => {
    return handleError(err);
  });

  const result_1 = await result.text();
  return result_1;
}

export async function GetNewDatePrestaCarto(cleApi, dateSynchro, idtablette) {
  const result = await fetch(`${jsonpackage.urlapi}PrestaCarto`, {
    mode: "cors",
    headers: {
      _cle: cleApi,
      _date_synchro: dateSynchro,
      _idtablette: idtablette,
      "Content-Type": "application/json",
    },
  }).catch((err) => {
    return handleError(err);
  });

  const result_1 = await result.text();
  return result_1;
}
