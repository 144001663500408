import db from "../Db";
import { handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteDuree from "../Delete/Duree_MDF";

var _ = require("lodash");

export async function UpdateDuree_MDF(headers) {
  const _duree = await GetApiData("DUREE", headers);

  if (_duree.Message !== undefined) {
    return _duree;
  }

  return await DeleteDuree().then(() => {
    var drops = [];
    _.forEach(_duree, (duree) => {
      drops.push({
        NUMDURE: duree.NUMDURE,
        LIBDURE: duree.LIBDURE.trim(),
        VALDURE: duree.VALDURE,
      });
    });

    return db
      .open()
      .then(() => {
        return db.DUREE.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
