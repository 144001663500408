import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { Button, Card, CardContent, Typography, Dialog, IconButton, DialogActions, DialogTitle, DialogContent, DialogContentText, Slide, AppBar, Toolbar, withStyles } from "@material-ui/core";
import { Close, PlayArrow } from "@material-ui/icons";
import * as d3 from "d3";

import { IsOracle } from "./../Helpers/Test/IsOracle";
import { GetCimetierById, GetCouleurEmpl } from "./../Helpers/Get/Cimetier";
import { GetPlanById } from "./../Helpers/Get/Plan";
import { GetAllZone } from "./../Helpers/Get/Zone";
import { GetSvgByNumcime } from "./../Helpers/Get/Svg";
import { GetLieuById } from "./../Helpers/Get/Lieu";
import { GetConcessById } from "../Helpers/Get/Concess";
import CCSousPlanEquipement from "./Consulter/CCartographie/CCSousPlanEquipement";
import { GetDefuntByLicleunik } from "../Helpers/Get/Defunt";
import CurrentTab from "./Global/Object/current_tab";

import PlanOperateurPolygon from "./PlanOperateurPolygon";
import CalculeSituation from "./Global/Object/calculeSituation";

var _ = require("lodash");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyleTypography = withStyles({
  root: {
    textDecoration: "underline",
    "&:hover": {
      color: "#FF732F",
    },
  },
})(Typography);

const styles = {
  zoom_area_paper: {
    display: "flex",
    marginBottom: 5,
  },
  zoom_area_paper_reset: {
    flex: 1,
  },
  button_arrow_top: {
    marginRight: 69,
  },
  zoom_area_paper_button: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  button_arrow_bottom: {
    marginLeft: 5,
    marginRight: 5,
  },
  card: {
    alignItems: "center",
    display: "flex",
    height: 100,
    position: "absolute",
    width: 250,
  },
  labbelpg: {
    textAlign: "center",
    marginTop: 20,
    color: "darkorange",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  typography: {
    flex: 1,
  },
};

let initialState = {
  ISORACLE: false,
  LIEU: "",
  DEFUNT: [],
  CONCESS: "",
  AREA_ZOOMED: false,
  bottom_arrow: false,
  left_arrow: false,
  right_arrow: false,
  top_arrow: false,
  MOUSECOORD: [],
  DISPLAYCARD: false,
  REDIRECT: null,
  emplacement_libre_dialog: false,
  afficheSousPlan: false,
  titre_equipement: "",
  dialog_emplacement_gele: false,
  PLANG: false,
};

class PlanOperateur extends Component {
  constructor(props) {
    super(props);

    this.SVG = undefined;
    this.PREV_LICLEUNIK = 0;
    this.WIDTH = 1024;
    this.HEIGHT = 768;
    this.X_ARRAY = [];
    this.Y_ARRAY = [];
    this.BOUNDS = undefined;
    this.ZOOM = undefined;
    this.PLAN = undefined;
    this.ZOCLEUNIK = undefined;

    this.state = initialState;

    this._svg_element = React.createRef();
    this._button_escape = React.createRef();
    this._button_bottom = React.createRef();
    this._button_left = React.createRef();
    this._button_top = React.createRef();
    this._button_right = React.createRef();
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      oracle === 1 ? (this.HEIGHT = 617) : (this.HEIGHT = 768);

      this.setState({ ...initialState, ISORACLE: oracle }, () => {
        this._create_svg();
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.state.AREA_ZOOMED) {
      document.onkeydown = (event) => {
        switch (event.key) {
          case "Escape":
            event.preventDefault();
            this._button_escape.current.click();
            break;

          case "ArrowLeft":
            event.preventDefault();
            this._button_left.current.click();
            break;

          case "ArrowUp":
            event.preventDefault();
            this._button_top.current.click();
            break;

          case "ArrowRight":
            event.preventDefault();
            this._button_right.current.click();
            break;

          case "ArrowDown":
            event.preventDefault();
            this._button_bottom.current.click();
            break;

          default:
            break;
        }
      };
    } else {
      document.onkeydown = (event) => {
        event.stopPropagation();
      };
    }

    if (prevProps.numcime !== this.props.numcime) {
      IsOracle().then((oracle) => {
        oracle === 1 ? (this.HEIGHT = 617) : (this.HEIGHT = 768);

        this.setState({ ...initialState, ISORACLE: oracle }, () => {
          this.SVG.selectAll("rect, g").remove();

          this._create_svg();
        });
      });
    }
  };

  _create_svg = () => {
    GetCimetierById(this.props.numcime).then((cimetier) => {
      GetPlanById(cimetier.NUMPLAN).then((plan) => {
        this.PLAN = plan;
        let planAffiche = this.PLAN.IMAGEPL !== "" ? this.PLAN.IMAGEPL : this.PLAN.IMAGESVG;

        if (planAffiche !== "") {
          this.SVG = d3.select(this._svg_element.current).style("width", "100%").style("height", "auto").style("max-width", "1024px");

          const g = this.SVG.append("g");

          g.append("image")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", this.WIDTH)
            .attr("height", this.HEIGHT)
            .attr("xlink:href", window.URL.createObjectURL(planAffiche))
            .style("image-rendering", "-webkit-optimize-contrast");

          this._create_emplacement();

          GetAllZone(this.props.numcime, 1, this.PLAN.PLAN_V4).then((zone) => {
            this.ZOOM = d3
              .zoom()
              .scaleExtent([1 / 2, 5 * zone.length])
              .on("start", this._zoom_start.bind(this))
              .on("zoom", _zoomed)
              .on("end", this._zoom_end.bind(this));

            function _zoomed() {
              g.attr("transform", d3.event.transform);
            }
          });
        }
      });
    });
  };

  _create_emplacement = () => {
    GetAllZone(this.props.numcime, 1, this.PLAN.PLAN_V4).then((zone) => {
      if (zone.length === 0) {
        GetAllZone(this.props.numcime, 0, this.PLAN.PLAN_V4)
          .then((zone) => {
            const imgWidth = this.state.ISORACLE ? 780 : 640;
            const imgHeight = this.state.ISORACLE ? 470 : 480;

            _.forEach(zone, (z, i) => {
              let X = z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
              let Y = z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);
              let W = z.COORDONNEES.X2 * (this.WIDTH / imgWidth) - z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
              let H = z.COORDONNEES.Y2 * (this.HEIGHT / imgHeight) - z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);

              this.SVG.append("rect")
                .attr("cursor", "pointer")
                .attr("x", X)
                .attr("y", Y)
                .attr("width", W)
                .attr("height", H)
                .attr("fill", "transparent")
                .attr("zocleunik", z.ZOCLEUNIK)
                .attr("equipement", 1);
            });
          })
          .then(() => {
            var rect = d3.selectAll("rect");
            rect.on("click", (d, i, p) => {
              d3.event.preventDefault();

              this.SVG.attr("height", 768);
              this.SVG.attr("viewBox", "0 0 1024 768");

              this.ZOCLEUNIK = d3.select(p[i]).attr("zocleunik");

              const equipement = parseInt(d3.select(p[i]).attr("equipement"));
              if (equipement === 0) {
                this.BOUNDS = p[i].getBBox();

                let BX = this.BOUNDS.x;
                let BY = this.BOUNDS.y;
                let BW = this.BOUNDS.width;
                let BS = this.WIDTH / BW;
                let BT = [-BX * BS, -BY * BS];

                this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity.translate(BT[0], BT[1]).scale(BS));
              } else {
                this.setState({ afficheSousPlan: true });
              }
            });
          });
      }

      const imgWidth = this.state.ISORACLE ? 780 : 640;
      const imgHeight = this.state.ISORACLE ? 470 : 480;

      _.forEach(zone, (z, i) => {
        let X = z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
        let Y = z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);
        let W = z.COORDONNEES.X2 * (this.WIDTH / imgWidth) - z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
        let H = z.COORDONNEES.Y2 * (this.HEIGHT / imgHeight) - z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);

        if (!_.includes(this.X_ARRAY, X)) {
          this.X_ARRAY.push(X);
        }

        if (!_.includes(this.Y_ARRAY, Y)) {
          this.Y_ARRAY.push(Y);
        }

        this.SVG.append("rect").attr("cursor", "pointer").attr("x", X).attr("y", Y).attr("width", W).attr("height", H).attr("fill", "transparent").attr("zocleunik", z.ZOCLEUNIK).attr("equipement", 0);
      });

      GetAllZone(this.props.numcime, 0, this.PLAN.PLAN_V4)
        .then((zone) => {
          const imgWidth = this.state.ISORACLE ? 780 : 640;
          const imgHeight = this.state.ISORACLE ? 470 : 480;

          _.forEach(zone, (z, i) => {
            let X = z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
            let Y = z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);
            let W = z.COORDONNEES.X2 * (this.WIDTH / imgWidth) - z.COORDONNEES.X1 * (this.WIDTH / imgWidth);
            let H = z.COORDONNEES.Y2 * (this.HEIGHT / imgHeight) - z.COORDONNEES.Y1 * (this.HEIGHT / imgHeight);

            this.SVG.append("rect")
              .attr("cursor", "pointer")
              .attr("x", X)
              .attr("y", Y)
              .attr("width", W)
              .attr("height", H)
              .attr("fill", "transparent")
              .attr("zocleunik", z.ZOCLEUNIK)
              .attr("equipement", 1);
          });

          let id_lieu = this.props.id_lieu !== undefined ? this.props.id_lieu : !isNaN(parseInt(this.props.type)) ? this.props.type : undefined;

          if (id_lieu !== undefined && this.state.PLANG === false) {
            this.setState({ PLANG: true }, () => {
              GetLieuById(id_lieu).then((lieu) => {
                if (this.props.vrainumcime === lieu.CIMEL) {
                  CalculeSituation.getSituation(lieu).then((zocle) => {
                    var rect = d3.select("rect[zocleunik='" + zocle + "']");
                    this.ZOCLEUNIK = zocle;

                    const equipement = parseInt(rect.attr("equipement"));

                    if (equipement === 0) {
                      this.BOUNDS = rect.node().getBBox();

                      let BX = this.BOUNDS.x;
                      let BY = this.BOUNDS.y;
                      let BW = this.BOUNDS.width;
                      let BS = this.WIDTH / BW;
                      let BT = [-BX * BS, -BY * BS];

                      this.SVG.attr("height", 768);
                      this.SVG.attr("viewBox", "0 0 1024 768");

                      this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity.translate(BT[0], BT[1]).scale(BS));
                    } else {
                      this.setState({ afficheSousPlan: true });
                    }
                  });
                }
              });
            });
          }
        })
        .then(() => {
          var rect = d3.selectAll("rect");
          rect.on("click", (d, i, p) => {
            d3.event.preventDefault();

            this.SVG.attr("height", 768);
            this.SVG.attr("viewBox", "0 0 1024 768");

            this.ZOCLEUNIK = d3.select(p[i]).attr("zocleunik");

            const equipement = parseInt(d3.select(p[i]).attr("equipement"));
            if (equipement === 0) {
              this.BOUNDS = p[i].getBBox();

              let BX = this.BOUNDS.x;
              let BY = this.BOUNDS.y;
              let BW = this.BOUNDS.width;
              let BS = this.WIDTH / BW;
              let BT = [-BX * BS, -BY * BS];

              this.SVG.attr("height", 768);
              this.SVG.attr("viewBox", "0 0 1024 768");

              this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity.translate(BT[0], BT[1]).scale(BS));
            } else {
              this.setState({ afficheSousPlan: true });
            }
          });
        });

      this.X_ARRAY = _.tail(_.sortBy(this.X_ARRAY));
      this.Y_ARRAY = _.tail(_.sortBy(this.Y_ARRAY));
    });
  };

  _zoom_start = () => {
    if (this.SVG.selectAll("rect")._groups[0].length) {
      this.SVG.selectAll("rect").remove();
      const g = this.SVG.select("g");

      if (this.PLAN.IMAGESVG !== "") {
        this.SVG.select("g").select("image").attr("xlink:href", window.URL.createObjectURL(this.PLAN.IMAGESVG));
      }
      this._draw_polygons(g);
    }
  };

  _draw_polygons = (g) => {
    let BX = this.BOUNDS.x;
    let BY = this.BOUNDS.y;
    let BW = this.BOUNDS.width;
    let BH = this.BOUNDS.height;

    let id_lieu = this.props.id_lieu !== undefined ? this.props.id_lieu : !isNaN(parseInt(this.props.type)) ? this.props.type : undefined;

    GetSvgByNumcime(this.props.numcime).then((svg) => {
      _.forEach(svg, (s) => {
        if (
          s.POINTS.P1.X <= BX + BW &&
          s.POINTS.P2.X >= BX &&
          s.POINTS.P3.X >= BX &&
          s.POINTS.P4.X <= BX + BW &&
          s.POINTS.P1.Y <= BY + BH &&
          s.POINTS.P2.Y <= BY + BH &&
          s.POINTS.P3.Y >= BY &&
          s.POINTS.P4.Y >= BY
        ) {
          const sub_g = g.append("g").attr("cursor", "pointer").attr("licleunik", s.LICLEUNIK).style("user-select", "none");

          PlanOperateurPolygon(sub_g, s, parseInt(id_lieu));
        }
      });

      _.forEach(svg, (s) => {
        if (s.GELE === true || s.MONUMENTPRESENT === true || s.PASSE_PIED === true || (s.POSITIONSTELE >= 2 && s.POSITIONSTELE <= 6)) {
          d3.select(`g[licleunik='${s.LICLEUNIK}']`).raise();
        }
      });

      GetAllZone(this.props.numcime, 1, this.PLAN.PLAN_V4).then((zone) => {
        this.SVG.call(
          d3
            .zoom()
            .scaleExtent([1 / 2, 5 * zone.length])
            .on("zoom", this._zoomed_area.bind(this))
        );

        d3.select("g").on("click", (d, i, p) => {
          this.setState({ DISPLAYCARD: false });
        });

        let id_lieu = this.props.id_lieu !== undefined ? this.props.id_lieu : !isNaN(parseInt(this.props.type)) ? this.props.type : undefined;

        if (id_lieu !== undefined) {
          let LICLEUNIK = parseInt(id_lieu);

          setTimeout(() => {
            let polygon = d3
              .select("g")
              .select("g[licleunik='" + LICLEUNIK + "']")
              .node();

            if (polygon !== null) {
              polygon = polygon.getBoundingClientRect();

              let pos = [polygon.x + polygon.width / 2, polygon.y + polygon.height / 2];

              let rect = this.SVG.node().getBoundingClientRect();

              if (pos[0] > rect.right - 250) {
                pos[0] = pos[0] - 250;
              }

              if (pos[1] > rect.height - 100) {
                pos[1] = pos[1] - 100;
              }

              GetLieuById(LICLEUNIK).then((lieu) => {
                this.PREV_LICLEUNIK = LICLEUNIK;

                if (LICLEUNIK !== 0) {
                  GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                    GetConcessById(lieu.COCLEUNIK).then((concess) => {
                      this.setState({
                        LIEU: lieu,
                        DEFUNT: defunts,
                        CONCESS: concess,
                        MOUSECOORD: pos,
                        DISPLAYCARD: true,
                      });
                    });
                  });
                }
              });
            }
          }, 1000);
        }

        d3.select("g")
          .selectAll("g")
          .on("click", (d, i, p) => {
            let pos = [d3.event.pageX, d3.event.pageY];
            let rect = this.SVG.node().getBoundingClientRect();

            if (pos[0] > rect.right - 250) {
              pos[0] = pos[0] - 250;
            }

            if (pos[1] > rect.height - 100) {
              pos[1] = pos[1] - 100;
            }

            if (this.PREV_LICLEUNIK !== 0) {
              GetLieuById(this.PREV_LICLEUNIK).then((lieu) => {
                GetCouleurEmpl(lieu).then((couleur) => {
                  this.SVG.select("g").select(`g[licleunik='${this.PREV_LICLEUNIK}']`).select("polygon").style("fill", couleur);

                  d3.select(p[i]).select("polygon").style("fill", "red");

                  let LICLEUNIK = d3.select(p[i]).attr("licleunik");
                  this.PREV_LICLEUNIK = parseInt(LICLEUNIK);

                  if (LICLEUNIK !== 0) {
                    GetLieuById(LICLEUNIK).then((lieu) => {
                      GetConcessById(lieu.COCLEUNIK).then((concess) => {
                        GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                          this.setState({
                            LIEU: lieu,
                            DEFUNT: defunts,
                            CONCESS: concess,
                            MOUSECOORD: pos,
                            DISPLAYCARD: true,
                          });
                        });
                      });
                    });
                  }
                });
              });
            } else {
              let LICLEUNIK = d3.select(p[i]).attr("licleunik");
              GetLieuById(LICLEUNIK).then((lieu) => {
                this.PREV_LICLEUNIK = parseInt(LICLEUNIK);

                d3.select(p[i]).select("polygon").style("fill", "red");

                if (LICLEUNIK !== 0) {
                  GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                    GetConcessById(lieu.COCLEUNIK).then((concess) => {
                      this.setState({
                        LIEU: lieu,
                        DEFUNT: defunts,
                        CONCESS: concess,
                        MOUSECOORD: pos,
                        DISPLAYCARD: true,
                      });
                    });
                  });
                }
              });
            }
          });
      });
    });
  };

  _zoomed_area = () => {
    if (this.SVG.selectAll("rect")._groups[0].length === 0) {
      const g = this.SVG.select("g");

      let BX = this.BOUNDS.x;
      let BY = this.BOUNDS.y;
      let BW = this.BOUNDS.width;
      let BH = this.BOUNDS.height;
      let BS = this.WIDTH / BW;
      let BT = [-BX * BS, -BY * BS];

      if (d3.event.transform.k <= BS) {
        d3.event.transform.k = BS;
        d3.event.transform.x = BT[0];
        d3.event.transform.y = BT[1];

        g.attr("transform", d3.event.transform);
      } else {
        if (d3.event.transform.x >= -(BX * d3.event.transform.k)) {
          d3.event.transform.x = -(BX * d3.event.transform.k);
        }

        if (d3.event.transform.y >= -(BY * d3.event.transform.k)) {
          d3.event.transform.y = -(BY * d3.event.transform.k);
        }

        if (d3.event.transform.x <= -(BW * d3.event.transform.k - 1024) - BX * d3.event.transform.k) {
          d3.event.transform.x = -(BW * d3.event.transform.k - 1024) - BX * d3.event.transform.k;
        }

        if (d3.event.transform.y <= -(BH * d3.event.transform.k - 768) - BY * d3.event.transform.k) {
          d3.event.transform.y = -(BH * d3.event.transform.k - 768) - BY * d3.event.transform.k;
        }

        g.attr("transform", d3.event.transform);

        if (this.state.DISPLAYCARD) {
          this.setState({ DISPLAYCARD: false });
        }
      }
    }
  };

  _zoom_end = () => {
    if (this.SVG.selectAll("rect")._groups[0].length === 0 && d3.event.transform.k === 1) {
      this.setState({ AREA_ZOOMED: false }, () => {
        this.SVG.select("g").selectAll("g").remove();

        if (this.PLAN.IMAGEPL !== "") {
          this.SVG.select("g").select("image").attr("xlink:href", window.URL.createObjectURL(this.PLAN.IMAGEPL));
        }

        this._create_emplacement(this.SVG);
      });
    } else {
      this.setState({ AREA_ZOOMED: true }, () => {
        let BX = this.BOUNDS.x;
        let BY = this.BOUNDS.y;
        let BW = this.BOUNDS.width;
        let BH = this.BOUNDS.height;

        if (Math.floor(BX) === 0 || BX + BW < this.WIDTH) {
          this.setState({ right_arrow: true });
        }

        if (Math.floor(BY) === 0 || BY + BH < this.HEIGHT) {
          this.setState({ bottom_arrow: true });
        }

        if (Math.floor(BX) > 0) {
          this.setState({ left_arrow: true });
        }

        if (Math.floor(BY) > 0) {
          this.setState({ top_arrow: true });
        }
      });
    }
  };

  _handle_click_button_reset = (event) => {
    event.preventDefault();

    this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity);

    this.SVG.attr("height", this.state.ISORACLE === 1 ? 617 : 768);
    this.SVG.attr("viewBox", `0 0 1024 ${this.state.ISORACLE === 1 ? 617 : 768}`);

    this.setState({
      bottom_arrow: false,
      left_arrow: false,
      right_arrow: false,
      top_arrow: false,
      MOUSECOORD: [],
      DISPLAYCARD: false,
      PLANG: true,
    });
  };

  _handle_click_button_arrow = (direction) => (event) => {
    event.preventDefault();

    let BX = this.BOUNDS.x;
    let BY = this.BOUNDS.y;
    let BW = this.BOUNDS.width;
    let BH = this.BOUNDS.height;
    let BS = this.WIDTH / BW;

    switch (direction) {
      case "TOP":
        BY = this.BOUNDS.y = BY - this.Y_ARRAY[0];
        break;

      case "LEFT":
        BX = this.BOUNDS.x = BX - this.X_ARRAY[0];
        break;

      case "BOTTOM":
        BY = this.BOUNDS.y = BY + this.Y_ARRAY[0];
        break;

      case "RIGHT":
        BX = this.BOUNDS.x = BX + this.X_ARRAY[0];
        break;

      default:
        return;
    }

    let BT = [-BX * BS, -BY * BS];

    this.SVG.transition().duration(750).call(this.ZOOM.transform, d3.zoomIdentity.translate(BT[0], BT[1]).scale(BS));

    const g = this.SVG.select("g");
    g.selectAll("g").remove();

    this._draw_polygons(g);

    this.setState({
      bottom_arrow: Math.floor(BY) === 0 || BY + BH < this.HEIGHT,
      left_arrow: Math.floor(BX) > 0,
      right_arrow: Math.floor(BX) === 0 || BX + BW < this.WIDTH,
      top_arrow: Math.floor(BY) > 0,
      MOUSECOORD: [],
      DISPLAYCARD: false,
    });
  };

  _cree_chaine_emplacement = () => {
    var empl = "";

    if (this.state.LIEU.EMPLACEMENT.CARRE.includes("Provisoire") || this.state.LIEU.EMPLACEMENT.CARRE.includes("Non affect")) {
      if (this.state.CONCESS !== undefined) {
        empl = this.state.CONCESS.EMPL;
      }
    } else {
      empl = _.filter([
        _.filter([this.state.LIEU.EMPLACEMENT.TYPECAR, this.state.LIEU.EMPLACEMENT.CARRE]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
      ]).join(" - ");

      const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

      if (reg.test(empl)) {
        const TYPESEC = this.state.LIEU.EMPLACEMENT.TYPECAR.split("/")[0];
        const SECTION = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[0];
        const TYPECAR = this.state.LIEU.EMPLACEMENT.TYPECAR.split("/")[1];
        const CARRE = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[1];

        empl = _.filter([
          _.filter([TYPESEC, SECTION]).join(" "),
          _.filter([TYPECAR, CARRE]).join(" "),
          _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
          _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
        ]).join(" - ");
      }
    }

    return empl;
  };

  _cree_chaine_date = (date) => {
    if (date !== "") {
      if (date.length === 4) {
        return date;
      } else {
        let c_date = date.split("T")[0].split("-");

        return new Date(c_date[0], c_date[1] - 1, c_date[2]).toLocaleDateString("fr-FR");
      }
    } else {
      return date;
    }
  };

  _handle_click_card = (event) => {
    event.preventDefault();

    if (this.state.DEFUNT.length === 0 || this.state.LIEU.COCLEUNIK !== 0) {
      if (this.props.type === "photos") {
        if (this.state.CONCESS !== undefined) {
          this.setState({
            REDIRECT: `/modifier/photographies/${this.state.CONCESS.COCLEUNIK}`,
          });
        } else {
          this.setState({ emplacement_libre_dialog: true });
        }
      } else {
        if (this.state.CONCESS !== undefined) {
          CurrentTab.setTab(0);

          this.setState({
            REDIRECT: `/consulter/fiche-concession/${this.state.CONCESS.COCLEUNIK}/r`,
          });
        } else {
          if (this.state.LIEU.EMPLACEMENT.GELE === true) {
            this.setState({
              dialog_emplacement_gele: true,
            });
          } else {
            this.setState({
              REDIRECT: `/consulter/concessions/ajout/${this.state.LIEU.LICLEUNIK}`,
            });
          }
        }
      }
    }
  };

  _handle_close_sousplan_equipement = (event) => {
    event.preventDefault();

    this.SVG.attr("height", this.state.ISORACLE === 1 ? 617 : 768);
    this.SVG.attr("viewBox", `0 0 1024 ${this.state.ISORACLE === 1 ? 617 : 768}`);

    this.setState({ afficheSousPlan: false });
  };

  _change_title = (title) => {
    this.setState({ titre_equipement: title });
  };

  _degele_emplacement = () => {
    this.setState({ dialog_emplacement_gele: false, REDIRECT: `/consulter/concessions/ajout/${this.state.LIEU.LICLEUNIK}` });
  };

  render() {
    console.log("RENDER PLAN OPERATEUR");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <div>
        <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", minWidth: 36 }}>
            {this.state.left_arrow ? (
              <Button
                className="Button-PlanOperateurFiche Button-Left"
                variant="contained"
                color="primary"
                style={{ minWidth: 36, width: 36 }}
                ref={this._button_left}
                onClick={this._handle_click_button_arrow("LEFT")}
              >
                <PlayArrow />
              </Button>
            ) : null}
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", minHeight: 36 }}>
              {this.state.top_arrow ? (
                <Button
                  className="Button-PlanOperateurFiche Button-Up"
                  variant="contained"
                  color="primary"
                  style={{ flex: 1, borderRadius: 0 }}
                  ref={this._button_top}
                  onClick={this._handle_click_button_arrow("TOP")}
                >
                  <PlayArrow />
                </Button>
              ) : null}
            </div>

            <svg ref={this._svg_element} width={1024} height={this.state.ISORACLE ? 617 : 768} preserveAspectRatio="xMinYMin meet" viewBox={`0 0 1024 ${this.state.ISORACLE ? 617 : 768}`}></svg>

            <div style={{ display: "flex", minHeight: 36 }}>
              {this.state.bottom_arrow ? (
                <Button
                  className="Button-PlanOperateurFiche Button-Down"
                  variant="contained"
                  color="primary"
                  style={{ flex: 1, borderRadius: 0 }}
                  ref={this._button_bottom}
                  onClick={this._handle_click_button_arrow("BOTTOM")}
                >
                  <PlayArrow />
                </Button>
              ) : null}
            </div>
          </div>

          <div style={{ display: "flex", minWidth: 36 }}>
            {this.state.right_arrow ? (
              <Button
                className="Button-PlanOperateurFiche Button-Right"
                variant="contained"
                color="primary"
                style={{ minWidth: 36, width: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                ref={this._button_right}
                onClick={this._handle_click_button_arrow("RIGHT")}
              >
                <PlayArrow />
              </Button>
            ) : null}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" color="primary" onClick={() => this.setState({ REDIRECT: "/consulter/liste-equipement" })}>
            Equipements
          </Button>

          {this.state.AREA_ZOOMED ? (
            <Button variant="contained" color="primary" ref={this._button_escape} onClick={this._handle_click_button_reset}>
              Plan général
            </Button>
          ) : (
            <Typography variant="body2" color="textSecondary" component="p" style={styles.labbelpg}>
              Cliquez sur le plan pour avoir une vue détaillée
            </Typography>
          )}
        </div>

        {this.state.LIEU !== "" && this.state.DISPLAYCARD ? (
          <div>
            <Card
              style={{
                ...styles.card,
                ...{
                  position: "absolute",
                  left: this.state.MOUSECOORD[0],
                  top: this.state.MOUSECOORD[1],
                  cursor: "pointer",
                },
              }}
              onClick={this._handle_click_card}
            >
              <CardContent style={{ paddingBottom: 16 }}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {this._cree_chaine_emplacement()}
                </Typography>

                <Button
                  style={{ width: 15, height: 15, minWidth: 15, position: "absolute", top: 10, right: 10 }}
                  onClick={(event) => {
                    event.stopPropagation();

                    this.setState({ DISPLAYCARD: false });
                  }}
                >
                  &times;
                </Button>

                {this.state.CONCESS !== undefined ? (
                  this.state.CONCESS.FAMILLE !== "" ? (
                    <Typography variant="body2" color="textSecondary" component="p">
                      {`Famille ${this.state.CONCESS.FAMILLE}`}
                    </Typography>
                  ) : null
                ) : this.state.DEFUNT.length > 0 ? (
                  this.state.DEFUNT.map((defunt, index) => (
                    <StyleTypography
                      key={index}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      onClick={() =>
                        this.setState({
                          REDIRECT: `/consulter/fiche-defunt/${defunt.D0CLEUNIK}`,
                        })
                      }
                    >
                      {`${defunt.ETAT_CIVIL.NOMDEF} ${defunt.ETAT_CIVIL.PRENOMDEF}`}
                    </StyleTypography>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary" component="p">
                    Emplacement libre
                  </Typography>
                )}

                {this.state.CONCESS !== undefined ? (
                  !this.state.CONCESS.DATEEXPIRA.includes("1900-01-01") ? (
                    <Typography variant="body2" color="textSecondary" component="p">
                      {`Expire le ${this._cree_chaine_date(this.state.CONCESS.DATEEXPIRA)}`}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary" component="p">
                      Pas de date d'expiration
                    </Typography>
                  )
                ) : null}
              </CardContent>
            </Card>
          </div>
        ) : null}

        <Dialog onClose={() => this.setState({ emplacement_libre_dialog: false })} open={this.state.emplacement_libre_dialog}>
          <DialogTitle onClose={() => this.setState({ emplacement_libre_dialog: false })}>
            <Typography variant="h6" component="p">
              Attention
            </Typography>

            <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ emplacement_libre_dialog: false })}>
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Typography gutterBottom>Impossible d'ajouter des photographies sur un emplacement libre.</Typography>
          </DialogContent>
        </Dialog>

        <Dialog fullScreen open={this.state.afficheSousPlan} onClose={this._handle_close_sousplan_equipement} TransitionComponent={Transition}>
          <AppBar style={{ paddingLeft: 17 }}>
            <Toolbar disableGutters>
              <Typography variant="h6" style={styles.typography}>
                {this.state.titre_equipement !== "" ? this.state.titre_equipement : "Sous plan"}
              </Typography>

              <IconButton color="inherit" aria-label="close" onClick={this._handle_close_sousplan_equipement}>
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Toolbar />

          <CCSousPlanEquipement zocleunik={this.ZOCLEUNIK} type={this.props.type} id_lieu={this.props.id_lieu} changeTitle={this._change_title} reverse={true} />
        </Dialog>

        <Dialog open={this.state.dialog_emplacement_gele} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Attention vous allez créer une concession sur un emplacement gelé. Confirmez-vous ?</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ dialog_emplacement_gele: false })}>
              Non
            </Button>
            <Button color="primary" onClick={this._degele_emplacement}>
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withCookies(PlanOperateur);
