import { withStyles } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";

const StyleToggleButton = withStyles({
  root: {
    color: "rgba(255, 115, 47, 0.53);",
    border: "1px solid rgba(255, 115, 47, 0.27)",
    "&:hover": {
      "background-color": "rgba(255, 115, 47, 0.20)",
    },
  },
  selected: {
    color: "rgba(255, 115, 47, 0.69) !important",
    "background-color": "rgba(255, 115, 47, 0.27) !important",
  },
})(ToggleButton);

export default StyleToggleButton;
