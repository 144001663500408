import db from "../Db";
import { handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteConcessEtatEcheance from "../Delete/ConcessEtatEcheance";

var _ = require("lodash");

export async function UpdateConcessEtatEcheance_MDF(headers) {
  const _concess_etat_echeance = await GetApiData("CONCESS_ETAT_ECHEANCE", headers);

  if (_concess_etat_echeance.Message !== undefined) {
    return _concess_etat_echeance;
  }

  return await DeleteConcessEtatEcheance().then(() => {
    var drops = [];
    _.forEach(_concess_etat_echeance, (concess_etat_echeance) => {
      drops.push({
        IDCONCESS_ETAT_ECHEANCE: concess_etat_echeance.IDCONCESS_ETAT_ECHEANCE,
        LIBELLE: concess_etat_echeance.LIBELLE.trim(),
        ARCHIVER: concess_etat_echeance.ARCHIVER,
        ORDRE: concess_etat_echeance.ORDRE,
      });
    });

    return db
      .open()
      .then(() => {
        return db.CONCESS_ETAT_ECHEANCE.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
