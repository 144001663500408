import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";
import { faClock, faHourglass } from "@fortawesome/free-regular-svg-icons";
import AccueilButton from "./../AccueilButton";

const styles = {
  grid: {
    display: "flex",
    flex: 1,
  },
  paper: {
    flex: 1,
    textAlign: "center",
    justifyContent: "center",
  },
  button: {
    width: 320,
    justifyContent: "start",
  },
};

class CProcedures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };

    props.changeTitle(props.title);
  }

  _handle_click_accueil_button = (url) => (event) => {
    event.preventDefault();

    this.setState({ redirect: url });
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Grid container direction="column" alignItems="flex-start" justify="flex-start" style={styles.grid}>
        <Grid item xs style={{ display: "flex", width: "100%" }}>
          <Grid container direction="row" alignItems="center">
            <Box flexGrow={1} style={{ display: "flex" }}>
              <AccueilButton text="Procédures de reprise pour état d'abandon" url="/consulter/procedure-abandon" icon={faClock} onClick={this._handle_click_accueil_button} />
              <AccueilButton text="Liste des concessions expirées" url="/consulter/sepultures-expirees" icon={faHourglass} onClick={this._handle_click_accueil_button} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default CProcedures;
