import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withWidth, Paper, Table, TableHead, TableRow, TableCell, TableBody, ExpansionPanel, Typography, ExpansionPanelSummary, ExpansionPanelDetails, Button } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import PropTypes from "prop-types";

import { GetDefuntByCocleunik } from "../../../Helpers/Get/Defunt";
import { GetTemporaireForColor } from "../../../Helpers/Get/Temporaire";
import { CheckUserRight } from "./../../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "../../Global/DialogAccessRight";
import { withCookies } from "react-cookie";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: 16,
  },
  papertitle: {
    display: "flex",
    marginBottom: 20,
  },
  typographytitle: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    justifyContent: "center",
    marginBottom: 0,
    paddingRight: 95,
  },
  table_defunt_row: {
    cursor: "pointer",
  },
};

class CFCDefunts extends Component {
  static propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      DEFUNTS: [],
      COLOR_TEXT: [],
      REDIRECT: null,
      champ_modifie: [],
      droit_util_ajout_defunt: false,
    };
  }

  componentDidMount = () => {
    let field = [];
    GetDefuntByCocleunik(this.props.concess.COCLEUNIK).then((defunt) => {
      this.setState({ DEFUNTS: defunt });

      let sub_field = {};
      _.forEach(defunt, (d) => {
        const temporaire = GetTemporaireForColor("DEFUNT", "D0CLEUNIK", d.D0CLEUNIK);

        temporaire.then((temp) => {
          if (temp.length > 0) {
            _.forEach(temp, (t) => {
              let color = "red";

              if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                color = "blue";
              }

              if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                color = "green";
              }

              if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                color = "primary";
              }

              var element_temp = t.TABLE_MODIFIE_JSON;
              element_temp = _.omit(element_temp, ["id"]);

              for (var key in element_temp) {
                sub_field[key] = color;

                if (key === "D0CLEUNIK") sub_field[key] = element_temp.D0CLEUNIK;
              }

              field.push(sub_field);
            });

            this.setState({
              champ_modifie: field,
            });
          }
        });
      });
    });
  };

  _cree_chaine_civilite = (civilite) => {
    switch (civilite) {
      case 1:
        return "Monsieur";
      case 2:
        return "Madame";
      case 3:
        return "Mademoiselle";
      default:
        return "";
    }
  };

  _cree_chaine_date = (date) => {
    if (date !== "") {
      if (date.length === 4) {
        return date;
      } else {
        // HF
        if (date.includes("1900-01-01")) {
          return "";
        } else {
          let c_date = date.split("T")[0].split("-");

          return new Date(c_date[0], c_date[1] - 1, c_date[2]).toLocaleDateString("fr-FR");
        }
      }
    } else {
      return date;
    }
  };

  _cree_chaine_enveloppe = (defunt) => {
    let env = "";

    let cerceuil = _.every(_.values(defunt.ENVELOPPE), (value) => value === 0);

    if (cerceuil) {
      env = "Cercueil";
    } else {
      let cle = _.findKey(defunt.ENVELOPPE, (item) => item === 1);

      switch (cle) {
        case "URNE":
          env = "Urne";
          break;
        case "RELIQUAIRE":
          env = "Reliquaire";
          break;
        case "ENFANT":
          env = "Cercueil enfant";
          break;
        case "DISPERSION":
          env = "Dispersion";
          break;
        case "URNE_SCELLE":
          env = "Urne scellée";
          break;
        case "HERMETIQUE":
          env = "Cercueil hermétique";
          break;
        case "ZINC":
          env = "Cercueil zingué";
          break;
        default:
          break;
      }
    }

    return env;
  };

  _find_color = (modifie, d0cleunik) => {
    const temp = _.find(modifie, ["D0CLEUNIK", d0cleunik]);

    let color = [];
    for (var key in temp) {
      if (typeof temp[key] === "string") {
        if (!_.includes(color, temp[key])) color.push(temp[key]);
      }
    }

    if (_.includes(color, "red")) return "red";
    if (_.includes(color, "blue")) return "blue";
    if (_.includes(color, "green")) return "green";

    return "primary";
  };

  render() {
    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <Paper elevation={0} style={styles.papertitle}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              CheckUserRight(this.state.ID_UTIL, "DEFUNT", "DROITAJOUT").then((asRight) => {
                if (asRight) {
                  this.setState({
                    REDIRECT: `/modifier/defunt/c/${this.props.concess.COCLEUNIK}/`,
                  });
                } else {
                  this.setState({ droit_util_ajout_defunt: true });
                }
              });
            }}
          >
            Ajouter
          </Button>

          <Typography variant="body2" gutterBottom style={styles.typographytitle}>
            Liste des défunts
          </Typography>
        </Paper>

        {width === "xs" || width === "sm" ? (
          this.state.DEFUNTS.map((defunt, index) => (
            <ExpansionPanel
              key={index}
              elevation={0}
              style={{
                position: "initial",
                color: this._find_color(this.state.champ_modifie, defunt.D0CLEUNIK),
              }}
            >
              <ExpansionPanelSummary
                style={{
                  padding: "0 16px",
                  color: this.state.COLOR_TEXT[defunt.D0CLEUNIK],
                }}
                expandIcon={<ExpandMore />}
              >
                <Typography variant="body2" gutterBottom>{`${this._cree_chaine_civilite(defunt.ETAT_CIVIL.SEXE)} ${defunt.ETAT_CIVIL.NOMDEF}`}</Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                onClick={() =>
                  this.setState({
                    REDIRECT: `/consulter/fiche-defunt/${defunt.D0CLEUNIK}`,
                  })
                }
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Civilité</TableCell>
                      <TableCell align="right">{this._cree_chaine_civilite(defunt.ETAT_CIVIL.SEXE)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell align="right">{defunt.ETAT_CIVIL.NOMDEF}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Nom de naissance</TableCell>
                      <TableCell align="right">{defunt.ETAT_CIVIL.NOMJEUNE}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Prénom</TableCell>
                      <TableCell align="right">{defunt.ETAT_CIVIL.PRENOMDEF}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Date de décès</TableCell>
                      <TableCell align="right">
                        {this._cree_chaine_date(defunt.ETAT_CIVIL.DATEDECES) !== ""
                          ? this._cree_chaine_date(defunt.ETAT_CIVIL.DATEDECES)
                          : defunt.ETAT_CIVIL.ANNEEDECES !== 0
                          ? defunt.ETAT_CIVIL.ANNEEDECES
                          : ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Enveloppe</TableCell>
                      <TableCell align="right">{this._cree_chaine_enveloppe(defunt)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Civilité</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Nom de naissance</TableCell>
                <TableCell>Prénom</TableCell>
                <TableCell>Date de décès</TableCell>
                <TableCell>Enveloppe</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.DEFUNTS.map((defunt, index) => (
                <TableRow
                  key={index}
                  hover
                  style={
                    (styles.table_defunt_row,
                    {
                      color: this._find_color(this.state.champ_modifie, defunt.D0CLEUNIK),
                    })
                  }
                  onClick={() =>
                    this.setState({
                      REDIRECT: `/consulter/fiche-defunt/${defunt.D0CLEUNIK}`,
                    })
                  }
                >
                  <TableCell style={{ color: "inherit" }}>{this._cree_chaine_civilite(defunt.ETAT_CIVIL.SEXE)}</TableCell>

                  <TableCell style={{ color: "inherit" }}>{defunt.ETAT_CIVIL.NOMDEF}</TableCell>
                  <TableCell style={{ color: "inherit" }}>{defunt.ETAT_CIVIL.NOMJEUNE}</TableCell>
                  <TableCell style={{ color: "inherit" }}>{defunt.ETAT_CIVIL.PRENOMDEF}</TableCell>
                  <TableCell style={{ color: "inherit" }}>
                    {this._cree_chaine_date(defunt.ETAT_CIVIL.DATEDECES) !== ""
                      ? this._cree_chaine_date(defunt.ETAT_CIVIL.DATEDECES)
                      : defunt.ETAT_CIVIL.ANNEEDECES !== 0
                      ? defunt.ETAT_CIVIL.ANNEEDECES
                      : ""}
                  </TableCell>
                  <TableCell style={{ color: "inherit" }}>{this._cree_chaine_enveloppe(defunt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        <DialogAccessRight state={this.state.droit_util_ajout_defunt} onChange={(value) => this.setState({ droit_util_ajout_defunt: value })} />
      </Paper>
    );
  }
}

export default withCookies(withWidth()(CFCDefunts));
