import jsonpackage from "../../../package.json";

export async function GetApiKey(alias, identifiant, motdepasse) {
  const result = await fetch(`${jsonpackage.urlapi}GLOBAL`, {
    mode: "cors",
    headers: {
      _alias: alias,
      _identifiant: identifiant,
      _motdepasse: motdepasse,
      "Content-Type": "application/json",
    },
  });

  const result_1 = await result;
  if (result_1.status !== 200) {
    return undefined;
  }

  const result_2 = result_1.json();
  return result_2;
}
