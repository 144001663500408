import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { withStyles, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Chip } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { format, endOfWeek, startOfWeek } from "date-fns";
import frLocale from "date-fns/locale/fr";

import { IsOracle } from "../../../Helpers/Test/IsOracle";
import { GetAllDefuntInTask } from "./../../../Helpers/Get/Defunt";
import { GetAllLieuInTask } from "./../../../Helpers/Get/Lieu";
import { GetAllCendre } from "./../../../Helpers/Get/Cendre";
import { GetAllConcessInTask } from "./../../../Helpers/Get/Concess";
import { GetAllCimetier } from "./../../../Helpers/Get/Cimetier";
import { GetAllSocieteInTask } from "./../../../Helpers/Get/Societe";
import { GetAllMouvementInTask } from "./../../../Helpers/Get/Mouvement";
import { GetAllInterven, GetAllIntervenInTask } from "./../../../Helpers/Get/Interven";
import { GetAllNatintervInTask } from "./../../../Helpers/Get/Natinterv";

import * as d3 from "d3";
import barreImg from "../../../Images/barre.svg";

var _ = require("lodash");

const StyleChip = withStyles({
  root: {
    height: 20,
    marginRight: 5,
    width: 20,
  },
  label: {
    padding: 0,
  },
})(Chip);

const StyleAccordionSummary = withStyles({
  content: {
    justifyContent: "space-between",
  },
})(AccordionSummary);

class CPCalendrierBulle extends Component {
  constructor(props) {
    super(props);

    this.cardRef = React.createRef();

    this.state = {
      allInterven: [],
      tache: [],
      defunt: [],
      defuntAffiche: [],
      profession: [],
      professionAffiche: [],
      lieu: [],
      lieuAffiche: [],
      cendre: [],
      cendreAffiche: [],
      concess: [],
      concessAffiche: [],
      cimetier: [],
      cimetierAffiche: [],
      societe: [],
      societeAffiche: [],
      mouvement: [],
      mouvementAffiche: [],
      interven: [],
      intervenAffiche: [],
      natinterv: [],
      natintervAffiche: [],
      datetache: [],
      week: [],
      redirect: null,
      ready: false,
      expanded: format(Date.now(), "dd/MM/yyyy", {
        locale: frLocale,
      }).toUpperCase(),
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ oracle: oracle === 1 ? true : false });
    });

    GetAllInterven().then((allInterven) => {
      this.setState({ allInterven: allInterven });
    });

    this.setState({ tache: this.props.tache, week: this.props.week }, () => {
      GetAllDefuntInTask().then((defunt) => {
        GetAllLieuInTask().then((lieu) => {
          GetAllCendre().then((cendre) => {
            GetAllConcessInTask().then((concess) => {
              GetAllCimetier(1).then((cimetier) => {
                GetAllSocieteInTask().then((societe) => {
                  GetAllMouvementInTask().then((mouvement) => {
                    GetAllIntervenInTask().then((interven) => {
                      let I0CLEUNIK = [];
                      _.forEach(interven, (i) => {
                        if (i.I0CLEUNIK > 0) {
                          I0CLEUNIK.push(i.I0CLEUNIK);
                        }
                      });

                      GetAllNatintervInTask(I0CLEUNIK).then((natinterv) => {
                        this.setState(
                          {
                            defunt: defunt,
                            lieu: lieu,
                            cendre: cendre,
                            concess: concess,
                            cimetier: cimetier,
                            societe: societe,
                            mouvement: mouvement,
                            interven: interven,
                            natinterv: natinterv,
                          },
                          () => {
                            this.Initialize();
                          }
                        );
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps.tache, this.props.tache)) {
      this.setState({ tache: this.props.tache, week: this.props.week }, () => {
        this.Initialize();
      });
    }

    if (!_.isEqual(prevProps.week, this.props.week)) {
      this.setState({ tache: this.props.tache, week: this.props.week }, () => {
        this.Initialize();
      });
    }

    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        expanded: format(this.props.expanded, "dd/MM/yyyy", {
          locale: frLocale,
        }).toUpperCase(),
      });
    }
  };

  Initialize = () => {
    let prof = [];
    let def = [];
    let lie = [];
    let cen = [];
    let con = [];
    let cim = [];
    let soc = [];
    let int = [];
    let natint = [];
    let datet = [];

    let _this = this;

    if (this.state.tache.length > 0) {
      _.forEach(this.state.tache, function (value) {
        if (value.D0CLEUNIK !== 0) {
          let currentDef = _.find(_this.state.defunt, (defunt) => {
            return defunt.D0CLEUNIK === value.D0CLEUNIK;
          });

          def = { ...def, [value.IDAGENDA]: currentDef };

          var profession = "";

          if (currentDef !== "" && currentDef !== undefined) {
            profession = value.TACHE.LIBELLE === "Inhumation" && currentDef.ENVELOPPE.DISPERSION === 1 ? "Dispersion" : value.TACHE.LIBELLE;
          } else {
            profession = value.TACHE.LIBELLE;
          }

          prof = { ...prof, [value.IDAGENDA]: profession };
        } else {
          prof = { ...prof, [value.IDAGENDA]: value.TACHE.LIBELLE };
        }

        if (value.LICLEUNIK !== 0) {
          let currentLie = _.find(_this.state.lieu, (lieu) => {
            return lieu.LICLEUNIK === value.LICLEUNIK;
          });

          lie = { ...lie, [value.IDAGENDA]: currentLie };

          let currentCen = _.find(_this.state.cendre, (cendre) => {
            return cendre.LICLEUNIK === value.LICLEUNIK;
          });

          cen = { ...cen, [value.IDAGENDA]: currentCen };

          let currentCon = _.find(_this.state.concess, (concess) => {
            return concess.LICLEUNIK === value.LICLEUNIK;
          });

          con = { ...con, [value.IDAGENDA]: currentCon };
        }

        if (value.TYPECIME !== 0) {
          let currentCim = _.find(_this.state.cimetier, (cimetier) => {
            return cimetier.NUMCIME === value.TYPECIME;
          });

          cim = { ...cim, [value.IDAGENDA]: currentCim };
        }

        if (value.IDSOCIETE !== null && value.IDSOCIETE !== 0) {
          let currentSoc = _.find(_this.state.societe, (societe) => {
            return societe.SOCLEUNIK === value.IDSOCIETE;
          });

          soc = { ...soc, [value.IDAGENDA]: currentSoc };
        }

        if (_this.state.oracle) {
          // A tester
          let currentMou = _.filter(_this.state.mouvement, (mouvement) => {
            return mouvement.D0CLEUNIK === value.D0CLEUNIK;
          });

          _.forEach(currentMou, (mouvement) => {
            let currentInt = _.filter(_this.state.interven, (interven) => {
              return interven.IDMOUVEMENT === mouvement.IDMOUVEMENT;
            });

            currentInt = _.filter(currentInt, (intevenr) => {
              let dateDebut = startOfWeek(_this.state.week, {
                weekStartsOn: 1,
              }).setDate(
                startOfWeek(_this.state.week, {
                  weekStartsOn: 1,
                }).getDate() - 7
              );

              let dateFin = endOfWeek(_this.state.week, {
                weekStartsOn: 1,
              }).setDate(
                endOfWeek(_this.state.week, {
                  weekStartsOn: 1,
                }).getDate() + 7
              );

              return new Date(intevenr.DATETRAVAUX) >= dateDebut && new Date(intevenr.DATETRAVAUX) <= dateFin;
            });

            _.forEach(currentInt, (interven) => {
              let currentNat = _.filter(_this.state.natinterv, (natinterv) => {
                return natinterv.I0CLEUNIK === interven.I0CLEUNIK;
              });

              let nat = [];

              _.forEach(currentNat, (natinterv) => {
                nat = [...nat, natinterv.LIBINTERVENTION];
              });

              int = { ...int, [value.IDAGENDA]: currentInt };
              natint = { ...natint, [value.IDAGENDA]: nat };
            });
          });
        } else {
          let currentInt = _.find(_this.state.interven, (interven) => {
            return interven.I0CLEUNIK === value.IDINTERVEN;
          });

          if (currentInt !== undefined) {
            let currentNat = _.filter(_this.state.natinterv, (natinterv) => {
              return natinterv.I0CLEUNIK === currentInt.I0CLEUNIK;
            });

            let nat = [];

            _.forEach(currentNat, (natinterv) => {
              nat = [...nat, natinterv.LIBINTERVENTION];
            });

            int = { ...int, [value.IDAGENDA]: currentInt };
            natint = { ...natint, [value.IDAGENDA]: nat };
          }
        }

        let dateDebut = format(new Date(value.TACHE.DATETACHE), "dd/MM/yyyy", {
          locale: frLocale,
        });

        let dateFin = format(new Date(value.TACHE.DATETACHE_FIN), "dd/MM/yyyy", {
          locale: frLocale,
        });

        if (dateDebut !== dateFin) {
          datet = {
            ...datet,
            [value.IDAGENDA]: `Date: du ${dateDebut} au ${dateFin}`,
          };
        } else {
          datet = { ...datet, [value.IDAGENDA]: `Date: ${dateDebut}` };
        }

        _this.setState({
          defuntAffiche: def,
          professionAffiche: prof,
          lieuAffiche: lie,
          cendreAffiche: cen,
          concessAffiche: con,
          cimetierAffiche: cim,
          societeAffiche: soc,
          intervenAffiche: int,
          natintervAffiche: natint,
          datetache: datet,
          ready: true,
        });
      });
    } else {
      this.setState({
        ready: true,
      });
    }
  };

  _handle_click_tache = (tache) => (event) => {
    event.preventDefault();

    if (this.state.professionAffiche[tache.IDAGENDA] === "Travaux") {
      if (tache.IDINTERVEN !== 0) {
        this.setState({
          redirect: `/consulter/fiche-travaux/${tache.IDINTERVEN}`,
        });
      }
    } else {
      if (tache.D0CLEUNIK !== 0) {
        if (
          this.state.professionAffiche[tache.IDAGENDA] === "Inhumation" ||
          this.state.professionAffiche[tache.IDAGENDA] === "Exhumation" ||
          this.state.professionAffiche[tache.IDAGENDA] === "Dispersion"
        ) {
          var intProfession;
          if (this.state.professionAffiche[tache.IDAGENDA] === "Inhumation") {
            intProfession = 1;
          }

          if (this.state.professionAffiche[tache.IDAGENDA] === "Exhumation") {
            intProfession = 2;
          }

          if (this.state.professionAffiche[tache.IDAGENDA] === "Dispersion") {
            intProfession = 3;
          }

          this.setState({
            redirect: `/consulter/fiche-defunt/${tache.D0CLEUNIK}/${intProfession}`,
          });
        } else {
          this.setState({
            redirect: `/consulter/fiche-defunt/${tache.D0CLEUNIK}`,
          });
        }
      }
    }
  };

  _cree_chaine_emplacement = (idtache) => {
    var empl = "";

    if (this.state.cendreAffiche[idtache] !== undefined && this.state.cendreAffiche[idtache] !== "") {
      empl = this.state.cendreAffiche[idtache].NOMCENDRE;
    } else {
      if (this.state.lieuAffiche[idtache] !== undefined && this.state.lieuAffiche[idtache] !== "") {
        if (this.state.lieuAffiche[idtache].EMPLACEMENT.CARRE.includes("Provisoire") || this.state.lieuAffiche[idtache].EMPLACEMENT.CARRE.includes("Non affect")) {
          if (this.state.concessAffiche[idtache] !== undefined && this.state.concessAffiche[idtache] !== "") {
            empl = this.state.concessAffiche[idtache].EMPL;
          }
        } else {
          empl = _.filter([
            _.filter([this.state.lieuAffiche[idtache].EMPLACEMENT.TYPECAR, this.state.lieuAffiche[idtache].EMPLACEMENT.CARRE]).join(" "),
            _.filter([this.state.lieuAffiche[idtache].EMPLACEMENT.TYPERG, this.state.lieuAffiche[idtache].EMPLACEMENT.RANG]).join(" "),
            _.filter([this.state.lieuAffiche[idtache].EMPLACEMENT.TYPEFOS, this.state.lieuAffiche[idtache].EMPLACEMENT.TOMBE]).join(" "),
          ]).join(" - ");

          const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

          if (reg.test(empl)) {
            const TYPESEC = this.state.lieuAffiche[idtache].EMPLACEMENT.TYPECAR.split("/")[0];
            const SECTION = this.state.lieuAffiche[idtache].EMPLACEMENT.CARRE.split("/")[0];
            const TYPECAR = this.state.lieuAffiche[idtache].EMPLACEMENT.TYPECAR.split("/")[1];
            const CARRE = this.state.lieuAffiche[idtache].EMPLACEMENT.CARRE.split("/")[1];

            empl = _.filter([
              _.filter([TYPESEC, SECTION]).join(" "),
              _.filter([TYPECAR, CARRE]).join(" "),
              _.filter([this.state.lieuAffiche[idtache].EMPLACEMENT.TYPERG, this.state.lieuAffiche[idtache].EMPLACEMENT.RANG]).join(" "),
              _.filter([this.state.lieuAffiche[idtache].EMPLACEMENT.TYPEFOS, this.state.lieuAffiche[idtache].EMPLACEMENT.TOMBE]).join(" "),
            ]).join(" - ");
          }
        }
      }
    }

    if (empl !== "") {
      return `Emplacement: ${empl}`;
    }
  };

  _handle_change_expanded = (date) => (event) => {
    event.preventDefault();

    if (this.state.expanded !== date) {
      this.setState({ expanded: date });
    } else {
      this.setState({ expanded: null });
    }
  };

  _cree_couleur = (tache) => {
    if (this.state.professionAffiche[tache.IDAGENDA] !== undefined && this.state.professionAffiche[tache.IDAGENDA].toUpperCase() === "DISPERSION") {
      const couleur = tache.TACHE.COULEUR;
      const prct = -25;

      let R = couleur[0];
      let V = couleur[1];
      let B = couleur[2];

      R === 0 ? (R = 255 * prct) : (R = R * (1 + prct / 100));
      V === 0 ? (V = 255 * prct) : (V = V * (1 + prct / 100));
      B === 0 ? (B = 255 * prct) : (B = B * (1 + prct / 100));

      return `rgba(${R.toString()},${V.toString()},${B.toString()}, ${(this.state.oracle ? 190 : 160) / 255})`;
    } else {
      return `rgba(${tache.TACHE.COULEUR.toString()}, ${(this.state.oracle ? 190 : 160) / 255})`;
    }
  };

  _cree_chaine_time_tache = (tache) => {
    let time = "";
    let time_start = format(new Date(tache.DATETACHE), "HH:mm", {
      locale: frLocale,
    });

    let time_end = format(new Date(tache.DATETACHE_FIN), "HH:mm", {
      locale: frLocale,
    });

    if (time_start !== "00:00" && time_end !== "00:00") {
      time = `${time_start} - ${time_end}`;
    }

    return time;
  };

  _tache_ended = (tache, index, jndex) => {
    if (this.state.professionAffiche[tache.IDAGENDA] !== undefined) {
      const now = Date.now();

      if (this.state.professionAffiche[tache.IDAGENDA] !== "Divers") {
        setTimeout(() => {
          const card = d3.select("div.paperplanning")._groups[0][0].childNodes[index + 1].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[jndex];

          if (
            this.state.professionAffiche[tache.IDAGENDA] === "Dispersion" ||
            this.state.professionAffiche[tache.IDAGENDA] === "Exhumation" ||
            this.state.professionAffiche[tache.IDAGENDA] === "Inhumation"
          ) {
            const tachefin = new Date(tache.TACHE.DATETACHE_FIN);

            if (now > tachefin) {
              if (card !== undefined) {
                card.style.backgroundImage = `url(${barreImg})`;
                card.style.backgroundSize = "100% 100%";
              }

              return true;
            } else {
              if (card !== undefined) {
                card.style.backgroundImage = null;
                card.style.backgroundSize = null;
              }

              return false;
            }
          }

          if (this.state.professionAffiche[tache.IDAGENDA] === "Travaux") {
            let interven = _.find(this.state.allInterven, ["I0CLEUNIK", tache.IDINTERVEN]);

            if (interven !== undefined && interven.SUIVITRAV === 5) {
              if (card !== undefined) {
                card.style.backgroundImage = `url(${barreImg})`;
                card.style.backgroundSize = "100% 100%";
              }

              return true;
            } else {
              if (card !== undefined) {
                card.style.backgroundImage = null;
                card.style.backgroundSize = null;
              }

              return false;
            }
          }

          if (this.state.professionAffiche[tache.IDAGENDA] === undefined) {
            if (card !== undefined) {
              card.style.backgroundImage = null;
              card.style.backgroundSize = null;
            }

            return false;
          }
        }, 500);
      }
    }
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return this.state.ready
      ? this.state.week.map((d, i) => (
          <Accordion
            key={i}
            expanded={
              this.state.expanded ===
              format(d, "dd/MM/yyyy", {
                locale: frLocale,
              }).toUpperCase()
            }
            onChange={this._handle_change_expanded(
              format(d, "dd/MM/yyyy", {
                locale: frLocale,
              }).toUpperCase()
            )}
          >
            <StyleAccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                style={
                  format(d, "dd/MM/yyyy", {
                    locale: frLocale,
                  }).toUpperCase() ===
                  format(Date.now(), "dd/MM/yyyy", {
                    locale: frLocale,
                  }).toUpperCase()
                    ? { color: "red" }
                    : null
                }
              >
                {format(d, "eeee d", { locale: frLocale }).toUpperCase()}
              </Typography>

              <Typography component="div">
                {_.filter(this.state.tache, function (value) {
                  var dateTache = new Date(value.TACHE.DATETACHE);
                  dateTache.setHours(0);
                  dateTache.setMinutes(0);
                  var dateTacheFin = new Date(value.TACHE.DATETACHE_FIN);
                  dateTacheFin.setHours(0);
                  dateTacheFin.setMinutes(0);
                  var dateJour = d;

                  return dateJour >= dateTache && dateJour <= dateTacheFin;
                }).map((t, j) => (
                  <StyleChip
                    key={j}
                    className={this._tache_ended(t, i, j) === true ? "tacheEnded" : ""}
                    style={{
                      backgroundColor: this._cree_couleur(t),
                    }}
                    label={this.state.professionAffiche[t.IDAGENDA] !== undefined ? this.state.professionAffiche[t.IDAGENDA].toUpperCase().charAt(0) : ""}
                  />
                ))}
              </Typography>
            </StyleAccordionSummary>

            <AccordionDetails style={{ flexDirection: "column" }}>
              {_.filter(this.state.tache, function (value) {
                var dateTache = new Date(value.TACHE.DATETACHE);
                dateTache.setHours(0);
                dateTache.setMinutes(0);
                var dateTacheFin = new Date(value.TACHE.DATETACHE_FIN);
                dateTacheFin.setHours(0);
                dateTacheFin.setMinutes(0);
                var dateJour = d;

                return dateJour >= dateTache && dateJour <= dateTacheFin;
              }).map((t, i) => (
                <Card
                  key={i}
                  style={{
                    margin: 10,
                    backgroundColor: this._cree_couleur(t),
                    userSelect: "none",
                    cursor: `${t.D0CLEUNIK === 0 && t.IDINTERVEN === 0 ? "default" : "pointer"}`,
                  }}
                  onClick={this._handle_click_tache(t)}
                >
                  <CardContent style={{ padding: 16 }}>
                    <Typography color="textSecondary" gutterBottom>
                      {`${this._cree_chaine_time_tache(t.TACHE)} ${this.state.professionAffiche[t.IDAGENDA] !== undefined ? this.state.professionAffiche[t.IDAGENDA].toUpperCase() : ""}`}
                    </Typography>

                    {this.state.defuntAffiche[t.IDAGENDA] !== undefined && this.state.defuntAffiche[t.IDAGENDA] !== "" ? (
                      <Typography color="textSecondary" gutterBottom>
                        {`Défunt: ${this.state.defuntAffiche[t.IDAGENDA].ETAT_CIVIL.NOMDEF} ${this.state.defuntAffiche[t.IDAGENDA].ETAT_CIVIL.PRENOMDEF}`}
                      </Typography>
                    ) : null}

                    {this.state.lieuAffiche[t.IDAGENDA] !== undefined && this.state.lieuAffiche[t.IDAGENDA] !== "" ? (
                      <Typography color="textSecondary" gutterBottom>
                        {this._cree_chaine_emplacement(t.IDAGENDA)}
                      </Typography>
                    ) : null}

                    {this.state.cimetierAffiche[t.IDAGENDA] !== undefined && this.state.cimetierAffiche[t.IDAGENDA] !== "" ? (
                      <Typography color="textSecondary" gutterBottom>
                        {`Cimetière : ${this.state.cimetierAffiche[t.IDAGENDA].NOMCIME}`}
                      </Typography>
                    ) : null}

                    {this.state.societeAffiche[t.IDAGENDA] !== undefined && this.state.societeAffiche[t.IDAGENDA] !== "" ? (
                      <Typography color="textSecondary" gutterBottom>
                        {`Société: ${this.state.societeAffiche[t.IDAGENDA].NOMSOCIETE}`}
                      </Typography>
                    ) : this.state.intervenAffiche[t.IDAGENDA] !== undefined && this.state.intervenAffiche[t.IDAGENDA] !== "" ? (
                      <Typography color="textSecondary" gutterBottom>
                        {`Société: ${this.state.intervenAffiche[t.IDAGENDA].INTERVENANT_NOM}`}
                      </Typography>
                    ) : null}

                    {this.state.intervenAffiche[t.IDAGENDA] !== undefined && this.state.intervenAffiche[t.IDAGENDA] !== "" ? (
                      <Typography color="textSecondary" gutterBottom>
                        {`Concession: ${this.state.intervenAffiche[t.IDAGENDA].EMPL_DEF_OU_CONCESSION.trim()}`}
                      </Typography>
                    ) : null}

                    <Typography color="textSecondary" gutterBottom>
                      {this.state.datetache[t.IDAGENDA]}
                    </Typography>

                    {t.TACHE.DESCRIPTION !== "" ? (
                      <Typography color="textSecondary" gutterBottom>
                        {this._cree_chaine_emplacement(t.IDAGENDA) !== undefined
                          ? t.TACHE.DESCRIPTION.replace(this._cree_chaine_emplacement(t.IDAGENDA).replace("Emplacement:", "").trim().replace(/ - /g, " "), "").trim() +
                            `${
                              this.state.natintervAffiche[t.IDAGENDA] !== undefined && this.state.natintervAffiche[t.IDAGENDA].length > 0
                                ? " - " + this.state.natintervAffiche[t.IDAGENDA].join("\n")
                                : ""
                            }`
                          : t.TACHE.DESCRIPTION}
                      </Typography>
                    ) : null}
                  </CardContent>
                </Card>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      : null;
  }
}

export default withCookies(CPCalendrierBulle);
