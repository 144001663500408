import Dexie from "dexie";
import db from "../Db";
import b64toBlob from "b64-to-blob";

var _ = require("lodash");

var UpdateTemporaire = Dexie.async(function* (TEMPORAIRE) {
  yield db.open();
  yield db.TEMPORAIRE.put(cleanTemp(_.cloneDeep(TEMPORAIRE)));
});

export default UpdateTemporaire;

function cleanTemp(temp) {
  const tableModifie = temp.TABLE_MODIFIE_JSON;

  if (tableModifie.SIGNATURE_AVANT_AGENT !== undefined && typeof tableModifie.SIGNATURE_AVANT_AGENT !== "string") {
    if (tableModifie.SIGNATURE_AGENT !== "") {
      const image = tableModifie.SIGNATURE_AGENT.replace(/data:image.*base64,/, "");
      tableModifie.SIGNATURE_AGENT = b64toBlob(image, "image/png");
    }
  }

  if (tableModifie.SIGNATURE_AVANT_SOCIETE !== undefined && typeof tableModifie.SIGNATURE_AVANT_SOCIETE !== "string") {
    if (tableModifie.SIGNATURE_AVANT_SOCIETE !== "") {
      const image = tableModifie.SIGNATURE_AVANT_SOCIETE.replace(/data:image.*base64,/, "");
      tableModifie.SIGNATURE_AVANT_SOCIETE = b64toBlob(image, "image/png");
    }
  }

  if (tableModifie.SIGNATURE_AGENT !== undefined && typeof tableModifie.SIGNATURE_AGENT !== "string") {
    if (tableModifie.SIGNATURE_AGENT !== "") {
      const image = tableModifie.SIGNATURE_AGENT.replace(/data:image.*base64,/, "");
      tableModifie.SIGNATURE_AGENT = b64toBlob(image, "image/png");
    }
  }

  if (tableModifie.SIGNATURE_SOCIETE !== undefined && typeof tableModifie.SIGNATURE_SOCIETE !== "string") {
    if (tableModifie.SIGNATURE_SOCIETE !== "") {
      const image = tableModifie.SIGNATURE_SOCIETE.replace(/data:image.*base64,/, "");
      tableModifie.SIGNATURE_SOCIETE = b64toBlob(image, "image/png");
    }
  }

  temp.TABLE_MODIFIE_JSON = tableModifie;

  return temp;
}
