import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { GetLieuByCocleunik2, GetLieuById } from "../Helpers/Get/Lieu";
import { GetAllCimetier } from "./../Helpers/Get/Cimetier";

var _ = require("lodash");

class BackButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  _handle_click_history = () => {
    if (_.includes(this.props.location.pathname, "/consulter/fiche-concession/")) {
      let reg = /^.*\/(\w?\d+)$|^.*\/(-?\d+)\/?([r])?$/;
      let match = reg.exec(this.props.location.pathname);

      if (match !== null) {
        if (match[2] !== undefined && !isNaN(parseInt(match[2]))) {
          if (parseInt(match[2]) < 0) {
            this.props.history.go(-3);
          } else {
            GetLieuByCocleunik2(parseInt(match[2])).then((lieu) => {
              if (lieu !== undefined) {
                GetAllCimetier(1).then((cimetier) => {
                  let cime = _.filter(cimetier, (c) => {
                    return c.NUMCIME === lieu.CIMEL;
                  })[0];

                  let filtered = _.filter(cimetier, (c) => {
                    return c.NUMPLAN === cime.NUMPLAN && c.IMPRIMECOLUMB === 0;
                  })[0];

                  this.setState({ redirect: `/consulter/cartographie/${lieu.LICLEUNIK}/${filtered.NUMCIME}` }, () => {
                    this.setState({ redirect: null });
                  });
                });
              } else {
                this.props.history.goBack();
              }
            });
          }
        } else {
          this.props.history.goBack();
        }
      } else {
        let reg = /^.*\/(\w?\d+)$|^.*\/(-?\d+)\/PHOTOCONC$/;
        let match = reg.exec(this.props.location.pathname);

        if (match !== null) {
          this.setState({ redirect: `/modifier/type_photographies/${match[2]}` }, () => {
            this.setState({ redirect: null });
          });
        } else {
          let reg = /^.*\/(\w?\d+)$|^.*\/(-?\d+)\/e$/;
          let match = reg.exec(this.props.location.pathname);

          if (match !== null) {
            this.setState({ redirect: `/consulter/sepultures-expirees` }, () => {
              this.setState({ redirect: null });
            });
          } else {
            let reg = /^.*\/(\w?\d+)$|^.*\/(-?\d+)\/PHOTOCONC\/pa$/;
            let match = reg.exec(this.props.location.pathname);

            if (match !== null) {
              this.setState({ redirect: `/consulter/procedure-abandon` }, () => {
                this.setState({ redirect: null });
              });
            } else {
              this.props.history.goBack();
            }
          }
        }
      }
    } else if (_.includes(this.props.location.pathname, "/consulter/concessions/ajout/")) {
      let reg = /^.*\/(\w?\d+)$|^.*\/(-?\d+)\/?([r])?$/;
      let match = reg.exec(this.props.location.pathname);

      if (match !== null) {
        if (match[1] !== undefined && !isNaN(parseInt(match[1]))) {
          if (parseInt(match[1]) < 0) {
            this.props.history.go(-2);
          } else {
            GetLieuById(parseInt(match[1])).then((lieu) => {
              if (lieu !== undefined) {
                GetAllCimetier(1).then((cimetier) => {
                  let cime = _.filter(cimetier, (c) => {
                    return c.NUMCIME === lieu.CIMEL;
                  })[0];

                  let filtered = _.filter(cimetier, (c) => {
                    return c.NUMPLAN === cime.NUMPLAN && c.IMPRIMECOLUMB === 0;
                  })[0];

                  this.setState({ redirect: `/consulter/cartographie/${lieu.LICLEUNIK}/${filtered.NUMCIME}` }, () => {
                    this.setState({ redirect: null });
                  });
                });
              } else {
                this.props.history.goBack();
              }
            });
          }
        } else {
          this.props.history.goBack();
        }
      } else {
        this.props.history.goBack();
      }
    } else if (_.includes(this.props.location.pathname, "/modifier/photographies/")) {
      let reg = /^.*\/(\w?\d+)$|^.*\/(-?\d+)\/?([r])?$/;
      let match = reg.exec(this.props.location.pathname);

      if (match !== null) {
        this.setState({ redirect: `/modifier/type_photographies/${match[1]}` }, () => {
          this.setState({ redirect: null });
        });
      } else {
        this.props.history.goBack();
      }
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return this.props.location.pathname !== "/" ? (
      <IconButton aria-label="Accueil" onClick={this._handle_click_history}>
        <ArrowBack />
      </IconButton>
    ) : null;
  }
}

export default withRouter(BackButton);
