import Dexie from "dexie";
import db from "../Db";
import { GetAgendaTacheById } from "./../Get/AgendaTache";

var DeleteAgendaTacheByIdAgenda = Dexie.async(function* (IDAGENDA) {
  yield db.open();

  GetAgendaTacheById(IDAGENDA).then((tache) => {
    if (tache !== undefined) db.AGENDATACHE.delete(tache.id);
  });
});

export default DeleteAgendaTacheByIdAgenda;
