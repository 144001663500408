import React, { Component } from "react";

import { IsOracle } from "./../Helpers/Test/IsOracle";
import { GetLieuById } from "../Helpers/Get/Lieu";
import { GetCendreByIdLieu } from "./../Helpers/Get/Cendre";
import { GetZoneByTypezone, GetZoneById } from "../Helpers/Get/Zone";
import { GetCimetierById } from "../Helpers/Get/Cimetier";
import { GetPlanById } from "./../Helpers/Get/Plan";

import MARKER from "../Images/marker_red.png";
import { GetAllLieuByCocleunik } from "./../Helpers/Get/Lieu";

var _ = require("lodash");

class PlanGeneral extends Component {
  constructor(props) {
    super(props);

    this.ISORACLE = false;
    this.LIEU = "";
    this.CENDRE = "";
    this.ZONE = "";
    this.ZONE_PARENTE = "";
    this.XCenter = 0;
    this.YCenter = 0;

    this.state = {
      PLAN: "",
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.ISORACLE = oracle;
    });

    GetLieuById(this.props.id_lieu).then((lieu) => {
      this.LIEU = lieu;

      if (this.LIEU !== undefined) {
        if (this.LIEU.LIEU_MULTIPLE_PRINC_VIRTUELLE) {
          GetAllLieuByCocleunik(this.LIEU.COCLEUNIK).then((lieu) => {
            this.LIEU = _.minBy(lieu, function (l) {
              return l.LICLEUNIK;
            });

            GetCendreByIdLieu(this.LIEU.LICLEUNIK).then((cendre) => {
              this.CENDRE = cendre;

              if (this.CENDRE !== undefined) {
                GetZoneByTypezone(this.CENDRE.NUMCENDRE).then((zone) => {
                  this.ZONE = zone;

                  if (this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                    GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                      this.ZONE_PARENTE = zone_parente;
                    });
                  }
                });

                GetCimetierById(this.CENDRE.TYPECIME).then((cimetier) => {
                  this.CIMETIER = cimetier;

                  GetPlanById(this.CIMETIER.NUMPLAN).then((plan) => {
                    this.setState({ PLAN: plan });
                  });
                });
              } else {
                if (this.LIEU.SITUATION !== 0 && (this.LIEU.SITUATION < 250 || this.LIEU.SITUATION > 255)) {
                  GetZoneById(this.LIEU.SITUATION).then((zone) => {
                    this.ZONE = zone;

                    if (this.ZONE !== undefined) {
                      if (this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                        GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                          this.ZONE_PARENTE = zone_parente;
                        });
                      }
                    }
                  });
                }

                GetCimetierById(this.LIEU.CIMEL).then((cimetier) => {
                  this.CIMETIER = cimetier;

                  GetPlanById(this.CIMETIER.NUMPLAN).then((plan) => {
                    this.setState({ PLAN: plan });
                  });
                });
              }
            });
          });
        } else {
          GetCendreByIdLieu(this.LIEU.LICLEUNIK).then((cendre) => {
            this.CENDRE = cendre;

            if (this.CENDRE !== undefined) {
              GetZoneByTypezone(this.CENDRE.NUMCENDRE).then((zone) => {
                this.ZONE = zone;

                if (this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                  GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                    this.ZONE_PARENTE = zone_parente;
                  });
                }
              });

              GetCimetierById(this.CENDRE.TYPECIME).then((cimetier) => {
                this.CIMETIER = cimetier;

                GetPlanById(this.CIMETIER.NUMPLAN).then((plan) => {
                  this.setState({ PLAN: plan });
                });
              });
            } else {
              if (this.LIEU.SITUATION !== 0 && (this.LIEU.SITUATION < 250 || this.LIEU.SITUATION > 255)) {
                GetZoneById(this.LIEU.SITUATION).then((zone) => {
                  this.ZONE = zone;

                  if (this.ZONE !== undefined) {
                    if (this.ZONE.ZOCLEUNIK_PARENT !== 0 && this.ZONE.ZOCLEUNIK_PARENT !== null) {
                      GetZoneById(this.ZONE.ZOCLEUNIK_PARENT).then((zone_parente) => {
                        this.ZONE_PARENTE = zone_parente;
                      });
                    }
                  }
                });
              }

              GetCimetierById(this.LIEU.CIMEL).then((cimetier) => {
                this.CIMETIER = cimetier;

                GetPlanById(this.CIMETIER.NUMPLAN).then((plan) => {
                  this.setState({ PLAN: plan });
                });
              });
            }
          });
        }
      }
    });
  };

  _create_plan_general = () => {
    if (this.state.PLAN.IMAGEPL.size > 0) {
      let CoordX = [],
        CoordY = [];

      let ZONE = this.ZONE_PARENTE !== "" ? this.ZONE_PARENTE : this.ZONE;

      if (ZONE.ZONE_DESSUS_TYPE === true || ZONE.ZONE_DESSUS_TYPE === 1) {
        CoordX.push(this.LIEU.EMPLACEMENT.H1, this.LIEU.EMPLACEMENT.H2, this.LIEU.EMPLACEMENT.H3, this.LIEU.EMPLACEMENT.H4);
        CoordY.push(this.LIEU.EMPLACEMENT.V1, this.LIEU.EMPLACEMENT.V2, this.LIEU.EMPLACEMENT.V3, this.LIEU.EMPLACEMENT.V4);
      } else {
        CoordX.push(ZONE.COORDONNEES.X1, ZONE.COORDONNEES.X2);
        CoordY.push(ZONE.COORDONNEES.Y1, ZONE.COORDONNEES.Y2);
      }

      var XMin = Math.min(...CoordX);
      var XMax = Math.max(...CoordX);
      var YMin = Math.min(...CoordY);
      var YMax = Math.max(...CoordY);

      this.XCenter = XMin + (XMax - XMin) / 2;
      this.YCenter = YMin + (YMax - YMin) / 2;

      return (
        <g>
          <title>Position du défunt sur le plan général</title>
          <image xlinkHref={window.URL.createObjectURL(this.state.PLAN.IMAGEPL)} x={0} y={0} width={1024} height={this.ISORACLE ? 617 : 768} />
          <image
            xlinkHref={MARKER}
            x={this.ISORACLE ? this.XCenter * (1024 / 780) - 11 : this.XCenter * (1024 / 640) - 11}
            y={this.ISORACLE ? this.YCenter * (617 / 470) - 40 : this.YCenter * (768 / 480) - 40}
            width={22}
            height={40}
            style={{ cursor: "pointer" }}
            onClick={this.props.afficheSousPlan}
          />
        </g>
      );
    } else {
      return null;
    }
  };

  render() {
    return this.state.PLAN !== "" && this.ZONE !== undefined ? this._create_plan_general() : null;
  }
}

export default PlanGeneral;
