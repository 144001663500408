import { GetPlanById } from "./../Get/Plan";

export function CalculateCoordinatesGMap(LIEU, ZONE, NUMPLAN, ORACLE) {
  return new Promise(function (resolve, reject) {
    let CX = [],
      CY = [];

    if (ZONE.ZONE_DESSUS_TYPE === 1) {
      CX.push(LIEU.EMPLACEMENT.H1, LIEU.EMPLACEMENT.H2, LIEU.EMPLACEMENT.H3, LIEU.EMPLACEMENT.H4);
      CY.push(LIEU.EMPLACEMENT.V1, LIEU.EMPLACEMENT.V2, LIEU.EMPLACEMENT.V3, LIEU.EMPLACEMENT.V4);
    } else {
      CX.push(ZONE.COORDONNEES.X1, ZONE.COORDONNEES.X2);
      CY.push(ZONE.COORDONNEES.Y1, ZONE.COORDONNEES.Y2);
    }

    let XMin = Math.min(...CX);
    let XMax = Math.max(...CX);
    let YMin = Math.min(...CY);
    let YMax = Math.max(...CY);

    let XCenter = (XMax - XMin) / 2 + XMin;
    let YCenter = (YMax - YMin) / 2 + YMin;

    let BDD_Width, BDD_Height;
    if (ORACLE) {
      BDD_Width = 780;
      BDD_Height = 470;
    } else {
      BDD_Width = 640;
      BDD_Height = 480;
    }

    GetPlanById(NUMPLAN).then((plan) => {
      if (plan.COORDONNEES.GNET_COREL_CADRE_PLANG_LARGEUR !== 0 && plan.COORDONNEES.GNET_COREL_CADRE_PLANG_HAUTEUR !== 0) {
        let GX = plan.COORDONNEES.GNET_COREL_CADRE_PLANG_LARGEUR / BDD_Width;
        let GY = plan.COORDONNEES.GNET_COREL_CADRE_PLANG_HAUTEUR / BDD_Height;

        let GLNG = plan.COORDONNEES.GNET_COREL_GMAP_LARGEUR / (plan.COORDONNEES.GNET_NE_LONGITUDE - plan.COORDONNEES.GNET_SW_LONGITUDE);
        let GLAT = plan.COORDONNEES.GNET_COREL_GMAP_HAUTEUR / (plan.COORDONNEES.GNET_NE_LATITUDE - plan.COORDONNEES.GNET_SW_LATITUDE);

        let PX0 = XCenter * GX - plan.COORDONNEES.GNET_COREL_CADRE_PLANG_LARGEUR / 2 + plan.COORDONNEES.GNET_COREL_CADRE_PLANG_X;
        let PY0 = (BDD_Height - YCenter) * GY - plan.COORDONNEES.GNET_COREL_CADRE_PLANG_HAUTEUR / 2 + plan.COORDONNEES.GNET_COREL_CADRE_PLANG_Y;

        let RAD = (plan.COORDONNEES.GNET_COREL_GMAP_ANGLE * Math.PI) / 180;

        let PX1 = (PX0 - plan.COORDONNEES.GNET_COREL_GMAP_X) * Math.cos(RAD) + (PY0 - plan.COORDONNEES.GNET_COREL_GMAP_Y) * Math.sin(RAD);
        let PY1 = -(PX0 - plan.COORDONNEES.GNET_COREL_GMAP_X) * Math.sin(RAD) + (PY0 - plan.COORDONNEES.GNET_COREL_GMAP_Y) * Math.cos(RAD);

        let LATCenter = (plan.COORDONNEES.GNET_NE_LATITUDE - plan.COORDONNEES.GNET_SW_LATITUDE) / 2 + plan.COORDONNEES.GNET_SW_LATITUDE;
        let LNGCenter = (plan.COORDONNEES.GNET_NE_LONGITUDE - plan.COORDONNEES.GNET_SW_LONGITUDE) / 2 + plan.COORDONNEES.GNET_SW_LONGITUDE;

        let LAT = LATCenter + PY1 / GLAT;
        let LNG = LNGCenter + PX1 / GLNG;

        return resolve(LAT + "," + LNG);
      } else {
        return resolve(null);
      }
    });
  });
}
