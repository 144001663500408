import db from "../Db";

export function GetAllAgendaTache() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.AGENDATACHE.toArray().then((agendaTache) => {
      return resolve(agendaTache);
    });
  });
}

export function GetAgendaTacheById(IDAGENDA) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.AGENDATACHE.where("IDAGENDA")
      .equals(IDAGENDA)
      .first()
      .then((agendaTache) => {
        return resolve(agendaTache);
      });
  });
}

export function GetAgendaTacheByD0cleunik(D0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.AGENDATACHE.where("D0CLEUNIK")
      .equals(D0CLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
