import Dexie from "dexie";
import db from "../Db";
import { GetIntervenById } from "../Get/Interven";

var DeleteIntervenByI0cleunik = Dexie.async(function* (I0CLEUNIK) {
  yield db.open();

  GetIntervenById(I0CLEUNIK).then((interven) => {
    if (interven !== undefined) db.INTERVEN.delete(interven.id);
  });
});

export default DeleteIntervenByI0cleunik;
