import db from "../Db";
import { GetAllAgendaTache } from "./AgendaTache";

var _ = require("lodash");

export function GetAllSociete() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.SOCIETE.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetAllSocieteInTask() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetAllAgendaTache().then((tache) => {
      let IDSOCIETE = [];

      _.forEach(tache, (t) => {
        if (t.IDSOCIETE > 0) {
          IDSOCIETE.push(t.IDSOCIETE);
        }
      });

      db.SOCIETE.where("SOCLEUNIK")
        .anyOf(IDSOCIETE)
        .toArray()
        .then((result) => {
          return resolve(result);
        });
    });
  });
}

export function GetSocieteById(SOCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.SOCIETE.where("SOCLEUNIK")
      .equals(parseInt(SOCLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
