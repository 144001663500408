import db from "../Db";
import b64toBlob from "b64-to-blob";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgressForPhotoconc, handleError } from "../Function";
import LoaderState from "./../../Components/Global/Object/LoaderState";

var _ = require("lodash");

export async function CreatePhotoconc(headers, _change_loader_state) {
  const _photoconcCount = await GetApiData("PHOTOCONCCount", headers);

  if (_photoconcCount.Message !== undefined) {
    return _photoconcCount;
  }

  LoaderState.setNombreTotalPhotos(_photoconcCount);
  _change_loader_state();

  const promise = new Promise((res, rej) => {
    addTableWithProgressForPhotoconc("PHOTOCONC", _photoconcCount, 50, headers, (getProgress, getPhotoconc) => {
      if (getPhotoconc.Message !== undefined) {
        return res(getPhotoconc);
      }

      var drops = [];
      _.forEach(getPhotoconc, (photoconc) => {
        var blob = "";
        if (photoconc.PHOTO !== "") blob = b64toBlob(photoconc.PHOTO, "image/png");

        drops.push({
          IDPHOTOCONC: photoconc.IDPHOTOCONC,
          PHOTO: blob,
          DATEAJOUT: photoconc.DATEAJOUT,
          PRINCIPAL: photoconc.PRINCIPAL ? 1 : 0,
          COCLEUNIK: photoconc.COCLEUNIK,
          NOTES: photoconc.NOTES,
          I0CLEUNIK: photoconc.I0CLEUNIK,
          SEMELLE_ETAT: photoconc.SEMELLE_ETAT,
          MONUMENT_ETAT: photoconc.MONUMENT_ETAT,
          CONSTAT_TRAVAUX: photoconc.CONSTAT_TRAVAUX,
          ABANDON_1: photoconc.ABANDON_1,
          ABANDON_2: photoconc.ABANDON_2,
        });
      });

      db.open()
        .then(() => {
          db.PHOTOCONC.bulkAdd(drops)
            .then(() => {
              LoaderState.setNombrePhotos(getProgress < _photoconcCount ? getProgress : _photoconcCount);
              _change_loader_state();

              if (getProgress >= _photoconcCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_photoconcCount} photos de concession`);

    return val;
  });
}
