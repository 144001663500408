import db from "../Db";

export function GetAllPa() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PA.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetPaByCTDN(CODECIME, CODETYPE, CODEDURE, CODENOMBRE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PA.where("CODECIME")
      .equals(CODECIME)
      .and((item) => item.CODETYPE === CODETYPE)
      .and((item) => item.CODEDURE === CODEDURE)
      .and((item) => item.CODENOMBRE === CODENOMBRE)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetPaByCDN(CODECIME, CODEDURE, CODENOMBRE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PA.where("CODECIME")
      .equals(CODECIME)
      .and((item) => item.CODEDURE === CODEDURE)
      .and((item) => item.CODENOMBRE === CODENOMBRE)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetPaByCN(CODECIME, CODENOMBRE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PA.where("CODECIME")
      .equals(CODECIME)
      .and((item) => item.CODENOMBRE === CODENOMBRE)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
