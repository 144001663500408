import db from "../Db";

export function GetAllUtil(AGENT = null) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    if (AGENT === null) {
      db.UTIL.toArray().then((gens) => {
        return resolve(gens);
      });
    } else {
      db.UTIL.where("AGENT_TERRAIN")
        .equals(AGENT)
        .toArray()
        .then((gens) => {
          return resolve(gens);
        });
    }
  });
}

export function GetUtilById(UTCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.UTIL.where("UTCLEUNIK")
      .equals(parseInt(UTCLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetUtilByIdentifiant(IDENTIFIANT, MOT_DE_PASSE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.UTIL.where(["LOGIN", "PASS"])
      .equals([IDENTIFIANT, MOT_DE_PASSE])
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
