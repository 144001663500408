import db from "../Db";
import { GetApiData } from "../Get/ApiData";
import DeletePlan from "../Delete/Plan_MDF";
import b64toBlob from "b64-to-blob";
import { handleError } from "../Function";

var _ = require("lodash");

export async function UpdatePlan_MDF(headers) {
  const _plan = await GetApiData("PLAN", headers);

  if (_plan.Message !== undefined) {
    return _plan;
  }

  return await DeletePlan().then(() => {
    var drops = [];
    _.forEach(_plan, (plan) => {
      var blob1 = "";
      if (plan.IMAGESVG !== "") blob1 = b64toBlob(Buffer.from(plan.IMAGESVG, "utf8").toString("base64"), "image/svg+xml");

      var blob2 = "";
      if (plan.IMAGEPL !== "") blob2 = b64toBlob(plan.IMAGEPL, "image/png");

      drops.push({
        NUMPLAN: plan.NUMPLAN,
        COORDONNEES: {
          GNET_COREL_CADRE_PLANG_HAUTEUR: plan.GNET_COREL_CADRE_PLANG_HAUTEUR,
          GNET_COREL_CADRE_PLANG_LARGEUR: plan.GNET_COREL_CADRE_PLANG_LARGEUR,
          GNET_COREL_CADRE_PLANG_X: plan.GNET_COREL_CADRE_PLANG_X,
          GNET_COREL_CADRE_PLANG_Y: plan.GNET_COREL_CADRE_PLANG_Y,
          GNET_COREL_GMAP_ANGLE: plan.GNET_COREL_GMAP_ANGLE,
          GNET_COREL_GMAP_HAUTEUR: plan.GNET_COREL_GMAP_HAUTEUR,
          GNET_COREL_GMAP_LARGEUR: plan.GNET_COREL_GMAP_LARGEUR,
          GNET_COREL_GMAP_X: plan.GNET_COREL_GMAP_X,
          GNET_COREL_GMAP_Y: plan.GNET_COREL_GMAP_Y,
          GNET_NE_LATITUDE: plan.GNET_NE_LATITUDE,
          GNET_NE_LONGITUDE: plan.GNET_NE_LONGITUDE,
          GNET_SW_LATITUDE: plan.GNET_SW_LATITUDE,
          GNET_SW_LONGITUDE: plan.GNET_SW_LONGITUDE,
        },
        IMAGESVG: blob1,
        IMAGEPL: blob2,
        PLAN_V4: plan.PLAN_V4,
      });
    });

    return db
      .open()
      .then(() => {
        return db.PLAN.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
