import db from "../Db";

import { GetCouleurEmpl } from "../Get/Cimetier";

var _ = require("lodash");

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

export function CalculateCoordinatesSousPlan(ZONE, LIEU, LICLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.LIEU.where("SITUATION")
      .equals(ZONE.ZOCLEUNIK)
      .or("SITUATION_ALL_1")
      .equals(ZONE.ZOCLEUNIK)
      .or("SITUATION_ALL_2")
      .equals(ZONE.ZOCLEUNIK)
      .or("SITUATION_ALL_3")
      .equals(ZONE.ZOCLEUNIK)
      .or("SITUATION_ALL_4")
      .equals(ZONE.ZOCLEUNIK)
      .toArray()
      .then((lieux) => {
        if (lieux.length === 0) {
          db.LIEU.where("SITUATION")
            .equals(ZONE.ZOCLEUNIK)
            .toArray()
            .then((lieux) => {
              CoordinateSousPlan(ZONE, lieux, LICLEUNIK).then((drops) => {
                return resolve(drops);
              });
            });
        } else {
          CoordinateSousPlan(ZONE, lieux, LICLEUNIK).then((drops) => {
            return resolve(drops);
          });
        }
      });
  });
}

function CoordinateSousPlan(ZONE, LIEUX, LICLEUNIK) {
  var drops = [];

  return new Promise(function (resolve, reject) {
    for (var current = 0; current < LIEUX.length; current++) {
      let i = current;
      let lieu = LIEUX[i];

      GetCouleurEmpl(lieu).then((couleur) => {
        if (_.includes(LICLEUNIK, lieu.LICLEUNIK)) {
          couleur = "#ff0000";
        }

        var TOMBE = lieu.EMPLACEMENT.TOMBE;

        if (!Number.isNaN(parseInt(TOMBE))) {
          TOMBE = parseInt(TOMBE);
        }

        var X1, X2, X3, X4, Y1, Y2, Y3, Y4;
        var x = [],
          y = [];

        let GX = 640 / (ZONE.COORDONNEES.X2 - ZONE.COORDONNEES.X1);
        let GY = 480 / (ZONE.COORDONNEES.Y2 - ZONE.COORDONNEES.Y1);

        if (ZONE.ZONE_DESSUS_TYPE === 1) {
          X1 = (lieu.EMPLACEMENT.H1 * (1024 / 640) - ZONE.COORDONNEES.X1 * (1024 / 640)) * GX;

          X2 = (lieu.EMPLACEMENT.H2 * (1024 / 640) - ZONE.COORDONNEES.X1 * (1024 / 640)) * GX;

          X3 = (lieu.EMPLACEMENT.H3 * (1024 / 640) - ZONE.COORDONNEES.X1 * (1024 / 640)) * GX;

          X4 = (lieu.EMPLACEMENT.H4 * (1024 / 640) - ZONE.COORDONNEES.X1 * (1024 / 640)) * GX;

          Y1 = (lieu.EMPLACEMENT.V1 * (768 / 480) - ZONE.COORDONNEES.Y1 * (768 / 480)) * GY;

          Y2 = (lieu.EMPLACEMENT.V2 * (768 / 480) - ZONE.COORDONNEES.Y1 * (768 / 480)) * GY;

          Y3 = (lieu.EMPLACEMENT.V3 * (768 / 480) - ZONE.COORDONNEES.Y1 * (768 / 480)) * GY;

          Y4 = (lieu.EMPLACEMENT.V4 * (768 / 480) - ZONE.COORDONNEES.Y1 * (768 / 480)) * GY;
        } else {
          X1 = lieu.EMPLACEMENT.H1 * (1024 / 640);
          X2 = lieu.EMPLACEMENT.H2 * (1024 / 640);
          X3 = lieu.EMPLACEMENT.H3 * (1024 / 640);
          X4 = lieu.EMPLACEMENT.H4 * (1024 / 640);

          Y1 = lieu.EMPLACEMENT.V1 * (768 / 480);
          Y2 = lieu.EMPLACEMENT.V2 * (768 / 480);
          Y3 = lieu.EMPLACEMENT.V3 * (768 / 480);
          Y4 = lieu.EMPLACEMENT.V4 * (768 / 480);
        }

        let P1 = new Point(X1, Y1);
        let P2 = new Point(X2, Y2);
        let P3 = new Point(X3, Y3);
        let P4 = new Point(X4, Y4);

        let points = { P1, P2, P3, P4 };
        for (var index in points) {
          let point = points[index];

          x.push(point.x);
          y.push(point.y);
        }

        let XMin = Math.min.apply(null, x);
        let XMax = Math.max.apply(null, x);
        let YMin = Math.min.apply(null, y);
        let YMax = Math.max.apply(null, y);

        let Width = (XMax - XMin) / 2 + XMin;
        let Height = (YMax - YMin) / 2 + YMin;

        drops.push({
          NUMCIME: ZONE.CIM,
          POLYGON: `${P1.x.toFixed(6)},${P1.y.toFixed(6)} ${P2.x.toFixed(6)},${P2.y.toFixed(6)} ${P3.x.toFixed(6)},${P3.y.toFixed(6)} ${P4.x.toFixed(6)},${P4.y.toFixed(6)}`,
          LICLEUNIK: lieu.LICLEUNIK,
          COULEUR: couleur,
          TEXT: {
            X: parseFloat(Width.toFixed(6)),
            Y: parseFloat(Height.toFixed(6)),
            TOMBE: TOMBE,
          },
        });

        if (i === LIEUX.length - 1) {
          return resolve(drops);
        }
      });
    }
  });
}
