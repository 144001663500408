import db from "../Db";

export function GetAllEtatTerrain() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.ETAT_TERRAIN.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetEtatTerrainById(IDETAT_TERRAIN) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.ETAT_TERRAIN.where("IDETAT_TERRAIN")
      .equals(parseInt(IDETAT_TERRAIN))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
