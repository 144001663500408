import Dexie from "dexie";
import db from "../Db";
import { GetMouvementById } from "../Get/Mouvement";

var DeleteMouvementByIdMouvement = Dexie.async(function* (IDMOUVEMENT) {
  yield db.open();

  GetMouvementById(IDMOUVEMENT).then((mouvement) => {
    if (mouvement !== undefined) db.MOUVEMENT.delete(mouvement.id);
  });
});

export default DeleteMouvementByIdMouvement;
