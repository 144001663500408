var LieuHistoAcces = (function () {
  var lieu = [];
  var lieuASupprimer = [];

  var getLieu = function () {
    return lieu;
  };

  var setLieu = function (currentLieu) {
    lieu.push(currentLieu);
  };

  var resetLieu = function () {
    lieu = [];
  };

  var getLieuASupprimer = function () {
    return lieuASupprimer;
  };

  var setLieuASupprimer = function (currentLieu) {
    lieuASupprimer.push(currentLieu);
  };

  var resetLieuASupprimer = function () {
    lieuASupprimer = [];
  };

  return {
    getLieu: getLieu,
    setLieu: setLieu,
    resetLieu: resetLieu,
    getLieuASupprimer: getLieuASupprimer,
    setLieuASupprimer: setLieuASupprimer,
    resetLieuASupprimer: resetLieuASupprimer,
  };
})();

export default LieuHistoAcces;
