import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import { Button, Paper, Table, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";

import { GetTypetbById } from "../../../Helpers/Get/Typetb";
import { GetDureeById } from "../../../Helpers/Get/Duree";
import { IsOracle } from "./../../../Helpers/Test/IsOracle";
import { CheckUserRight } from "./../../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "./../../Global/DialogAccessRight";
import CurrentTab from "./../../Global/Object/current_tab";
import { GetTemporaireForColor } from "../../../Helpers/Get/Temporaire";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: 16,
  },
  papertitle: {
    display: "flex",
    marginBottom: 20,
  },
  typographytitle: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    justifyContent: "center",
    marginBottom: 0,
    paddingRight: 95,
  },
};

class CFCContrat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      TYPETB: "",
      DUREE: "",
      droit_util_modif_concession: false,
      redirect: null,
      champ_modifie: [],
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle === 1 });
    });

    GetTypetbById(this.props.concess.DETAIL).then((typetb) => {
      GetDureeById(this.props.concess.DUREE).then((duree) => {
        this.setState({ TYPETB: typetb, DUREE: duree });
      });
    });

    let field = {};
    const temporaire = GetTemporaireForColor("CONCESS", "COCLEUNIK", this.props.concess.COCLEUNIK);
    temporaire.then((temp) => {
      _.forEach(temp, (t) => {
        let color = "red";

        if (t.DATE_D_ENVOI_A_GESCIME !== null) {
          color = "blue";
        }

        if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
          color = "green";
        }

        if (t.DATE_TRAITE_DANS_GESCIME !== null) {
          color = "primary";
        }

        var element_temp = t.TABLE_MODIFIE_JSON;
        element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

        for (var key in element_temp) {
          field[key] = color;
        }
      });

      this.setState({
        champ_modifie: field,
      });
    });
  };

  _cree_chaine_date = (date) => {
    if (date !== "" && date !== null) {
      if (date.length === 4) {
        return date;
      } else {
        // HF
        if (date.includes("1900-01-01")) {
          return "";
        } else {
          let c_date = date.split("T")[0].split("-");

          return new Date(c_date[0], c_date[1] - 1, c_date[2]).toLocaleDateString("fr-FR");
        }
      }
    } else {
      return date;
    }
  };

  _cree_chaine_nature = () => {
    switch (this.props.concess.CONCESSIONTYPE) {
      case 1:
        return "Concession de terrain";
      case 2:
        return "Concession columbarium";
      case 3:
        return "Terrain commun";
      case 4:
        return "Militaire";
      case 5:
        return "Victime civile";
      default:
        return "";
    }
  };

  render() {
    const champ_modifie = this.state.champ_modifie;

    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <Paper elevation={0} style={styles.papertitle}>
          <Button
            variant="contained"
            color="primary"
            disabled={this.props.en_cours_de_traitement}
            onClick={() => {
              CheckUserRight(this.state.ID_UTIL, "CONCESS", "DROITMODIF").then((asRight) => {
                if (asRight) {
                  this.props.pa ? CurrentTab.setTab(2) : CurrentTab.setTab(0);

                  this.setState({
                    redirect: this.props.pa ? `/modifier/concession/${this.props.concess.COCLEUNIK}/pa` : `/modifier/concession/${this.props.concess.COCLEUNIK}`,
                  });
                } else {
                  this.setState({ droit_util_modif_concession: true });
                }
              });
            }}
          >
            Modifier
          </Button>

          {this.props.en_cours_de_traitement ? (
            <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
              Modification impossible : Concession non traitée dans Gescime
            </Typography>
          ) : null}

          <Typography variant="body2" gutterBottom style={styles.typographytitle}>
            Contrat
          </Typography>
        </Paper>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "FAMILLE") ? _.get(champ_modifie, "FAMILLE") : "primary",
                }}
              >
                Famille
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "FAMILLE") ? _.get(champ_modifie, "FAMILLE") : "primary",
                }}
              >
                {this.props.concess.FAMILLE}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "ANCNUM") ? _.get(champ_modifie, "ANCNUM") : "primary",
                }}
              >
                N° d'ordre
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "ANCNUM") ? _.get(champ_modifie, "ANCNUM") : "primary",
                }}
              >
                {this.props.concess.ANCNUM !== 0 ? this.props.concess.ANCNUM : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "TYPECIME") ? _.get(champ_modifie, "TYPECIME") : "primary",
                }}
              >
                Cimetière
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "TYPECIME") ? _.get(champ_modifie, "TYPECIME") : "primary",
                }}
              >
                {this.props.cimetier.NOMCIME}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "LICLEUNIK") ? _.get(champ_modifie, "LICLEUNIK") : "primary",
                }}
              >
                Emplacement
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "LICLEUNIK") ? _.get(champ_modifie, "LICLEUNIK") : "primary",
                }}
              >
                {this.props.concess.EMPLACEMENT_LIEU}
              </TableCell>
            </TableRow>

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(champ_modifie, "CONCESSIONTYPE") ? _.get(champ_modifie, "CONCESSIONTYPE") : "primary",
                  }}
                >
                  Nature
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(champ_modifie, "CONCESSIONTYPE") ? _.get(champ_modifie, "CONCESSIONTYPE") : "primary",
                  }}
                >
                  {this._cree_chaine_nature()}
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "MADATE") ? _.get(champ_modifie, "MADATE") : "primary",
                }}
              >
                {this.state.IS_ORACLE && this.props.concess.CONCESSIONTYPE === 3 ? "Date d'inhumation" : "Date de prise"}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "MADATE") ? _.get(champ_modifie, "MADATE") : "primary",
                }}
              >
                {this._cree_chaine_date(this.props.concess.MADATE)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "DATEEXPIRA") ? _.get(champ_modifie, "DATEEXPIRA") : "primary",
                }}
              >
                Date d'expiration
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "DATEEXPIRA") ? _.get(champ_modifie, "DATEEXPIRA") : "primary",
                }}
              >
                {this._cree_chaine_date(this.props.concess.DATEEXPIRA)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "DETAIL") ? _.get(champ_modifie, "DETAIL") : "primary",
                }}
              >
                Type
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "DETAIL") ? _.get(champ_modifie, "DETAIL") : "primary",
                }}
              >
                {this.state.TYPETB !== undefined ? this.state.TYPETB.NOMTOMBE : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "DUREE") ? _.get(champ_modifie, "DUREE") : "primary",
                }}
              >
                Durée
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "DUREE") ? _.get(champ_modifie, "DUREE") : "primary",
                }}
              >
                {this.state.DUREE !== undefined ? this.state.DUREE.LIBDURE : null}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, "SUPERFICIE") ? _.get(champ_modifie, "SUPERFICIE") : "primary",
                }}
              >
                Superficie
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, "SUPERFICIE") ? _.get(champ_modifie, "SUPERFICIE") : "primary",
                }}
              >
                {this.props.concess.SUPERFICIE > 0 ? `${this.props.concess.SUPERFICIE} m²` : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <DialogAccessRight state={this.state.droit_util_modif_concession} onChange={(value) => this.setState({ droit_util_modif_concession: value })} />
      </Paper>
    );
  }
}

export default withCookies(CFCContrat);
