import db from "../Db";

export function GetAllDefuntS() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT_S.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetDefuntSExist() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT_S.count().then((result) => {
      return resolve(result > 0);
    });
  });
}
