import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Paper, Typography, IconButton, TextField, TableContainer, Table, TableBody, TableRow, TableCell, TablePagination } from "@material-ui/core";
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import { GetDefuntByNumcendre } from "./../../Helpers/Get/Defunt";
import { GetCendreById } from "../../Helpers/Get/Cendre";
import { stableSort, getComparator } from "./../Global/Gfunction";

var _ = require("lodash");

const styles = {
  grid: {
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 16,
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
    paddingTop: 16,
  },
  empty_div: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: 16,
  },
};

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage />
      </IconButton>
    </div>
  );
}

class CListeEquipementCendre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cendre: "",
      defunt: [],
      defunt_sans_filtre: [],
      redirect: null,
      recherche: "",
      currentPage: 0,
      rowsPerPage: 10,
      orderBy: "FAMILLE",
      order: "asc",
    };
  }

  componentDidMount = () => {
    GetCendreById(this.props.id_cendre).then((cendre) => {
      this.props.changeTitle(cendre.NOMCENDRE);

      GetDefuntByNumcendre(cendre.NUMCENDRE).then((defunt) => {
        defunt = _.orderBy(
          defunt,
          (d) => {
            if (d.ETAT_CIVIL.NOMDEF === "") return d.ETAT_CIVIL.NOMJEUNE;
            return d.ETAT_CIVIL.NOMDEF;
          },
          ["asc"]
        );

        this.setState({ cendre: cendre, defunt: defunt, defunt_sans_filtre: defunt });
      });
    });
  };

  _cree_prefix = (pluriel, recherche) => {
    if (this.state.cendre !== "") {
      if (/^[aeiou]$/i.test(this.state.cendre.NOMCENDRE.charAt(0)) === true) {
        if (this.state.cendre.DISPERSION === 1) {
          return `dispersé${pluriel ? "s" : ""} à l'${this.state.cendre.NOMCENDRE}${recherche === true ? " ne correspond à la recherche" : ""}`;
        } else {
          return `inhumé${pluriel ? "s" : ""} à l'${this.state.cendre.NOMCENDRE}${recherche === true ? " ne correspond à la recherche" : ""}`;
        }
      } else {
        if (this.state.cendre.DISPERSION === 1) {
          return `dispersé${pluriel ? "s" : ""} au ${this.state.cendre.NOMCENDRE}${recherche === true ? " ne correspond à la recherche" : ""}`;
        } else {
          return `inhumé${pluriel ? "s" : ""} au ${this.state.cendre.NOMCENDRE}${recherche === true ? " ne correspond à la recherche" : ""}`;
        }
      }
    }
  };

  _handle_change_recherche = (event) => {
    event.preventDefault();

    let DEFUNT = [...this.state.defunt_sans_filtre];
    DEFUNT = _.filter(DEFUNT, (d) => {
      let identite = `${d.ETAT_CIVIL.NOMDEF} ${d.ETAT_CIVIL.PRENOMDEF}`;
      if (d.ETAT_CIVIL.NOMDEF === "") identite = `${d.ETAT_CIVIL.NOMJEUNE} ${d.ETAT_CIVIL.PRENOMDEF}`;

      return _.startsWith(identite.toLowerCase(), event.target.value.toLowerCase());
    });

    this.setState({ recherche: event.target.value, defunt: DEFUNT, rowsPerPage: 10, currentPage: 0 });
  };

  _cree_chaine_identite = (defunt) => {
    let identite = "";

    if (defunt.ETAT_CIVIL.NOMDEF.trim() !== "") {
      if (defunt.ETAT_CIVIL.PRENOMDEF.trim() !== "") {
        identite = `${defunt.ETAT_CIVIL.NOMDEF.trim()} ${defunt.ETAT_CIVIL.PRENOMDEF.trim()}`;
      } else {
        identite = `${defunt.ETAT_CIVIL.NOMDEF.trim()}`;
      }
    } else {
      if (defunt.ETAT_CIVIL.SEXE > 1 && defunt.ETAT_CIVIL.NOMJEUNE.trim() !== "") {
        identite = `${defunt.ETAT_CIVIL.NOMJEUNE.trim()} ${defunt.ETAT_CIVIL.PRENOMDEF.trim()}`;
      } else {
        identite = `${defunt.ETAT_CIVIL.PRENOMDEF.trim()}`;
      }
    }

    return identite;
  };

  _handle_change_tablepage = (event, newPage) => {
    this.setState({ currentPage: newPage });
  };

  _handle_change_tablerowperpage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, currentPage: 0 });
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Grid container justify="center" style={styles.grid}>
        <Grid item xs={11} style={styles.grid_item}>
          {this.state.cendre !== "" ? (
            <Paper style={styles.paper}>
              <Typography variant="h6" style={{ marginBottom: 16 }}>
                {`Liste des défunts ${this._cree_prefix(true)}`}
              </Typography>

              {this.state.defunt.length > 0 || this.state.recherche !== "" ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <TextField style={{ width: "15rem" }} margin="normal" label="Rechercher" value={this.state.recherche} onChange={this._handle_change_recherche} />
                </div>
              ) : null}

              {this.state.defunt.length > 0 ? (
                <div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {stableSort(this.state.defunt, getComparator(this.state.order, this.state.orderBy))
                          .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                          .map((row, index) => {
                            return (
                              <TableRow
                                key={index}
                                hover={true}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (this.props.closeDialog !== undefined) this.props.closeDialog();

                                  this.setState({
                                    redirect: `/consulter/fiche-defunt/${row.D0CLEUNIK}`,
                                  });
                                }}
                              >
                                <TableCell>{this._cree_chaine_identite(row)}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Table>
                    <TableBody>
                      <TableRow>
                        <TablePagination
                          style={{ display: "flex", flexDirection: "column" }}
                          colSpan={3}
                          count={this.state.defunt.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.currentPage}
                          labelRowsPerPage="Nombre de lignes"
                          labelDisplayedRows={({ from, to, count }) => (count > 0 ? `De ${from} à ${to === -1 ? count : to} sur ${count !== -1 ? count : to} resultats` : `Aucun résultat`)}
                          onChangePage={this._handle_change_tablepage}
                          onChangeRowsPerPage={this._handle_change_tablerowperpage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <Typography variant="body1">{`Aucun défunt ${this._cree_prefix(false, this.state.recherche !== "" ? true : false)}`}</Typography>
              )}
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

export default CListeEquipementCendre;
