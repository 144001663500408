import db from "../Db";
import { handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteProcedureAbandon from "../Delete/ProcedureAbandon_MDF";

var _ = require("lodash");

export async function UpdateProcedureAbandon_MDF(headers) {
  const _procedure_abandon = await GetApiData("PROCEDUREABANDON", headers);

  if (_procedure_abandon.Message !== undefined) {
    return _procedure_abandon;
  }

  return await DeleteProcedureAbandon().then(() => {
    var drops = [];
    _.forEach(_procedure_abandon, (procedure_abandon) => {
      drops.push({
        IDPROCEDUREABANDON: procedure_abandon.IDPROCEDUREABANDON,
        NOM_PROCEDURE_ABANDON: procedure_abandon.NOM_PROCEDURE_ABANDON,
      });
    });

    return db
      .open()
      .then(() => {
        return db.PROCEDUREABANDON.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
