import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Paper, List, ListItem, ListItemText, Divider, CircularProgress, withWidth } from "@material-ui/core";

import { GetAllConcess } from "./../Helpers/Get/Concess";
import { GetAllCimetier } from "./../Helpers/Get/Cimetier";
import { GetAllLieu } from "./../Helpers/Get/Lieu";
import { GetAllCendre } from "./../Helpers/Get/Cendre";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    margin: 16,
  },
};

class ListeConcessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LISTE_CONCESSION: [],
      LIEU: [],
      CENDRE: [],
      CIMETIERE: [],
      REDIRECT: null,
    };
  }

  componentDidMount = () => {
    GetAllLieu().then((lieu) => {
      GetAllCendre().then((cendre) => {
        GetAllCimetier(1).then((cimetier) => {
          GetAllConcess().then((concessions) => {
            this.setState({
              LISTE_CONCESSION: this._get_all_suggestions(concessions, this.props.input_concession),
              LIEU: lieu,
              CENDRE: cendre,
              CIMETIERE: cimetier,
            });
          });
        });
      });
    });
  };

  _get_all_suggestions = (concessions, input_concession) => {
    return _.orderBy(concessions, ["FAMILLE"], ["asc"]).filter((suggestion) => {
      const keep =
        !input_concession ||
        suggestion.FAMILLE.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .startsWith(
            input_concession
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          );

      return keep;
    });
  };

  render() {
    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={`/consulter/fiche-concession/${this.state.REDIRECT}`} push />;
    }

    return (
      <Paper style={styles.paper}>
        {this.state.LISTE_CONCESSION.length === 0 ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

        <List component="nav">
          {this.state.LISTE_CONCESSION.map((concession, index) => {
            var lie = _.find(this.state.LIEU, ["LICLEUNIK", concession.LICLEUNIK]);
            var cen = _.find(this.state.CENDRE, ["LICLEUNIK", concession.LICLEUNIK]);

            return (
              <div key={index}>
                <ListItem button onClick={() => this.setState({ REDIRECT: concession.COCLEUNIK })} style={{ display: width === "xs" ? "grid" : "flex" }}>
                  <ListItemText primary={concession.FAMILLE} />
                  <ListItemText
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    primary={`${
                      _.find(this.state.CIMETIERE, ["NUMCIME", cen !== undefined ? cen.TYPECIME : lie.CIMEL]) !== undefined
                        ? _.find(this.state.CIMETIERE, ["NUMCIME", cen !== undefined ? cen.TYPECIME : lie.CIMEL]).NOMCIME
                        : ""
                    } - ${concession.EMPLACEMENT_LIEU}`}
                  />
                </ListItem>

                <Divider />
              </div>
            );
          })}
        </List>
      </Paper>
    );
  }
}

export default withWidth()(ListeConcessions);
