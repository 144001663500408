import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateConcaire(headers) {
  const _concaireCount = await GetApiData("CONCAIRECount", headers);

  if (_concaireCount.Message !== undefined) {
    return _concaireCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("CONCAIRE", _concaireCount, 10000, headers, (getProgress, getConcaire) => {
      if (getConcaire.Message !== undefined) {
        return res(getConcaire);
      }

      var drops = [];
      _.forEach(getConcaire, (concaire) => {
        drops.push({
          C0CLEUNIK: concaire.C0CLEUNIK,
          NOM: concaire.NOM.trim(),
          PRENOM: concaire.PRENOM.trim(),
          NOMJEUNE: concaire.NOMJEUNE.trim(),
          CIVILITE: concaire.CIVILITE,
          ADR_NUMERO: concaire.ADR_NUMERO,
          ADR_BISTER: concaire.ADR_BISTER,
          ADRESSE: concaire.ADRESSE,
          ADR_COMP: concaire.ADR_COMP,
          CODEPO: concaire.CODEPO,
          VILLE: concaire.VILLE,
          TEL: concaire.TEL,
          PORTABLE: concaire.PORTABLE,
          ADRESSEMAIL: concaire.ADRESSEMAIL,
          PARENTE: concaire.PARENTE,
          COCLEUNIK: concaire.COCLEUNIK,
          DCD: concaire.DCD,
          TYPEINTERLO: {
            CONCESSIONNAIRE: concaire.CONCESSIONNAIRE,
            COCONCESSIONNAIRE: concaire.COCONCESSIONNAIRE,
            AYANTDROIT: concaire.AYANTDROIT,
            INHUMABLE: concaire.INHUMABLE,
            AUTRE: concaire.AUTRE,
            INDESIRABLE: concaire.INDESIRABLE,
            DONATAIRE_LEGATAIRE: concaire.DONATAIRE_LEGATAIRE,
            EXECUTEUR_TESTAMENTAIRE: concaire.EXECUTEUR_TESTAMENTAIRE,
          },
        });
      });

      db.open()
        .then(() => {
          db.CONCAIRE.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _concaireCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_concaireCount} interlocuteurs`);

    return val;
  });
}
