import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { ToggleButton } from "@material-ui/lab";
import StyleToggleButton from "./../StyleToggleButton";
import { GetAllDefunt } from "./../../Helpers/Get/Defunt";
import { GetAllCimetier } from "./../../Helpers/Get/Cimetier";
import { GetAllConcess } from "./../../Helpers/Get/Concess";
import { ExpandMore, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";

var _ = require("lodash");

const headCells = [
  { id: "ETAT_CIVIL.NOMDEF", label: "Nom", sortable: true },
  { id: "ETAT_CIVIL.DATENAISS", label: "Date de naissance", sortable: false },
  { id: "ETAT_CIVIL.DATEDECES", label: "Date de décès", sortable: false },
  { id: "ETAT_CIVIL.AGE", label: "Age", sortable: false },
  { id: "TYPECIME", label: "Cimetière", sortable: false },
  { id: "EMPLACEMENT_LIEU", label: "Emplacement", sortable: true },
];

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id && headCell.sortable ? order : "asc"}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : null}
              hideSortIcon={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id && headCell.sortable ? <span style={styles.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes(".")) {
    b = b[orderBy.split(".")[0]][orderBy.split(".")[1]];
    a = a[orderBy.split(".")[0]][orderBy.split(".")[1]];
  } else {
    b = b[orderBy];
    a = a[orderBy];
  }

  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>

      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage />
      </IconButton>
    </div>
  );
}

class CListeDefunts extends Component {
  constructor(props) {
    super(props);

    this.ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    this.CIMETIER = [];
    this.CONCESS = [];

    this.state = {
      REDIRECT: null,
      LETTER: "",
      DEFUNT: [],
      DEFUNT_AFFICHE: [],
      currentPage: 0,
      EXPANDED: null,
      loader: true,
      order: "asc",
      orderBy: "ETAT_CIVIL.NOMDEF",
      rowsPerPage: 10,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    GetAllDefunt().then((defunts) => {
      let DEFUNT = _.orderBy(defunts, "NOMDEF", "asc");

      GetAllCimetier(1).then((cimetier) => {
        this.CIMETIER = cimetier;

        GetAllConcess().then((concess) => {
          this.CONCESS = concess;

          this.setState({ DEFUNT: DEFUNT, DEFUNT_AFFICHE: DEFUNT, loader: false });
        });
      });
    });
  };

  _handle_change_alphabet = (alphabet) => (event) => {
    event.preventDefault();

    if (this.state.LETTER === alphabet) {
      this.setState({
        LETTER: null,
        DEFUNT_AFFICHE: this.state.DEFUNT,
        currentPage: 0,
        EXPANDED: -1,
        loader: false,
      });
    } else {
      let defunt = [...this.state.DEFUNT];

      let caL = _.filter(defunt, function (value, key) {
        return _.startsWith(value.ETAT_CIVIL.NOMDEF, alphabet);
      });

      this.setState({
        LETTER: alphabet,
        DEFUNT_AFFICHE: caL,
        currentPage: 0,
        EXPANDED: -1,
        loader: false,
      });
    }
  };

  handleRequestSort = (_, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";

    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  _handle_click_defunt_in_list = (defunt) => (event) => {
    event.preventDefault();

    this.setState({
      REDIRECT: `/consulter/fiche-defunt/${defunt.D0CLEUNIK}`,
    });
  };

  _cree_chaine_cimetier = (typecime) => {
    let cim = _.find(this.CIMETIER, "NUMCIME", typecime);

    if (cim !== undefined) {
      return cim.NOMCIME;
    }
  };

  _emplacement_lieu = (lieu) => {
    let empl = lieu;

    const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

    if (reg.test(empl)) {
      const match = empl.match(reg);

      empl = `${match[1]} ${match[3]} - ${match[2]} ${match[4]}${match[5]}`;
    }

    return empl;
  };

  _handle_change_tablepage = (event, newPage) => {
    this.setState({ currentPage: newPage, EXPANDED: -1 });
  };

  _handle_change_tablerowperpage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, currentPage: 0, EXPANDED: -1 });
  };

  render() {
    console.log("RENDER LISTE DEFUNTS");

    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 24 }}>
              {`Liste des défunts`}
            </Typography>

            <Paper elevation={0} style={{ textAlign: "center" }}>
              {this.ALPHABET.map((alphabet, index) => (
                <ToggleButton
                  key={index}
                  style={{ margin: 6, width: 48 }}
                  value={this.state.LETTER}
                  selected={this.state.LETTER === alphabet ? true : false}
                  onChange={this._handle_change_alphabet(alphabet)}
                >
                  {alphabet}
                </ToggleButton>
              ))}

              <StyleToggleButton style={{ margin: 6 }} value="" selected={this.state.LETTER === ""} onChange={this._handle_change_alphabet("")}>
                Afficher tout
              </StyleToggleButton>
            </Paper>

            {this.state.loader ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

            {width === "xs" || width === "sm" ? (
              <div style={{ marginTop: 24 }}>
                <Paper elevation={0} style={{ display: "flex", padding: "0 16px" }}>
                  <TextField
                    select
                    label="Tri par :"
                    value={this.state.orderBy}
                    style={{ width: "25ch", marginRight: 16, marginBottom: 16 }}
                    onChange={(e) => {
                      this.setState({
                        orderBy: e.target.value,
                        EXPANDED: null,
                      });
                    }}
                  >
                    <MenuItem value="ETAT_CIVIL.NOMDEF">Nom</MenuItem>
                    <MenuItem value="EMPLACEMENT_LIEU">Emplacement</MenuItem>
                  </TextField>

                  <TextField
                    select
                    label="Ordre :"
                    value={this.state.order}
                    style={{ width: "25ch" }}
                    onChange={(e) => {
                      this.setState({ order: e.target.value, EXPANDED: null });
                    }}
                  >
                    <MenuItem value="asc">Croissant</MenuItem>
                    <MenuItem value="desc">Décroissant</MenuItem>
                  </TextField>
                </Paper>

                <TableContainer component={Paper}>
                  {stableSort(this.state.DEFUNT_AFFICHE, getComparator(this.state.order, this.state.orderBy))
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      return (
                        <Accordion key={index} elevation={0} expanded={this.state.EXPANDED === index ? true : false} onChange={() => this.setState({ EXPANDED: index })}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="body2" gutterBottom>
                              {row.ETAT_CIVIL.NOMDEF}
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails onClick={this._handle_click_defunt_in_list(row)}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Date de naissance</TableCell>
                                  <TableCell>{row.ETAT_CIVIL.DATENAISS !== "" ? `${new Date(row.ETAT_CIVIL.DATENAISS).toLocaleDateString("fr-FR")}` : null}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Date de décès</TableCell>
                                  <TableCell>{row.ETAT_CIVIL.DATEDECES !== "" ? `${new Date(row.ETAT_CIVIL.DATEDECES).toLocaleDateString("fr-FR")}` : null}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Age</TableCell>
                                  <TableCell>{row.ETAT_CIVIL.AGE > 0 ? row.ETAT_CIVIL.AGE : null}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Cimetière</TableCell>
                                  <TableCell>{this._cree_chaine_cimetier(row.TYPECIME)}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Emplacement</TableCell>
                                  <TableCell>{this._emplacement_lieu(row.EMPLACEMENT_LIEU)}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </TableContainer>
              </div>
            ) : (
              <TableContainer component={Paper} style={{ marginTop: 24 }}>
                <Table>
                  <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} onRequestSort={this.handleRequestSort} />

                  <TableBody>
                    {stableSort(this.state.DEFUNT_AFFICHE, getComparator(this.state.order, this.state.orderBy))
                      .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow key={index} hover={true} style={{ cursor: "pointer" }} onClick={this._handle_click_defunt_in_list(row)}>
                            <TableCell>{row.ETAT_CIVIL.NOMDEF}</TableCell>
                            <TableCell>{row.ETAT_CIVIL.DATENAISS !== "" ? `${new Date(row.ETAT_CIVIL.DATENAISS).toLocaleDateString("fr-FR")}` : null}</TableCell>
                            <TableCell>{row.ETAT_CIVIL.DATEDECES !== "" ? `${new Date(row.ETAT_CIVIL.DATEDECES).toLocaleDateString("fr-FR")}` : null}</TableCell>
                            <TableCell>{row.ETAT_CIVIL.AGE > 0 ? row.ETAT_CIVIL.AGE : null}</TableCell>
                            <TableCell>{this._cree_chaine_cimetier(row.TYPECIME)}</TableCell>
                            <TableCell>{this._emplacement_lieu(row.EMPLACEMENT_LIEU)}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Table>
              <TableBody>
                <TableRow>
                  <TablePagination
                    style={{ display: "flex", flexDirection: "column" }}
                    colSpan={3}
                    count={this.state.DEFUNT_AFFICHE.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.currentPage}
                    labelRowsPerPage="Nombre de lignes"
                    labelDisplayedRows={({ from, to, count }) =>
                      width !== "xs" && width !== "sm" ? (count > 0 ? `De ${from} à ${to === -1 ? count : to} sur ${count !== -1 ? count : to} resultats` : `Aucun résultat`) : null
                    }
                    onChangePage={this._handle_change_tablepage}
                    onChangeRowsPerPage={this._handle_change_tablerowperpage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(withWidth()(CListeDefunts));
