import Dexie from "dexie";
import db from "../Db";
import { GetLieuById } from "../Get/Lieu";

var DeleteLieuByLicleunik = Dexie.async(function* (LICLEUNIK) {
  yield db.open();

  GetLieuById(LICLEUNIK).then((lieu) => {
    if (lieu !== undefined) db.LIEU.delete(lieu.id);
  });
});

export default DeleteLieuByLicleunik;
