import DeleteTemporaire from "../Delete/Temporaire";
import jsonpackage from "../../../package.json";
import { GetAllTemporaire } from "./../Get/Temporaire";

var _ = require("lodash");

export async function Purge_Gnet(headers) {
  const result = await fetch(`${jsonpackage.urlapi}Purge`, {
    mode: "cors",
    headers: {
      _cle: headers.APIKEY,
      _idutil: headers.ID_GENS,
      _idtablette: headers.ID_TABLETTE,
    },
  });

  const result_1 = await result.text();
  return result_1;
}

export async function Purge_Tablette() {
  const temporaire = await GetAllTemporaire();

  if (temporaire.length === 0) return "";

  _.forEach(temporaire, (temp, index) => {
    if (temp.DATE_TRAITE_DANS_GESCIME !== null) DeleteTemporaire(temp.id);

    if (index === temporaire.length - 1) return "";
  });
}
