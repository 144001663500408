import jsonpackage from "../../../package.json";

export async function GetApiData(entite, headers) {
  if (navigator.onLine) {
    try {
      const result = await fetch(`${jsonpackage.urlapi}${entite}`, {
        mode: "cors",
        headers: {
          _cle: headers.APIKEY,
          _identifiant: headers.IDENTIFIANT,
          _motdepasse: headers.MOT_DE_PASSE,
          _cimetiere: headers.CIMETIERE,
          _date_synchro: headers.DATE_SYNCHRO,
        },
      });

      const result_1 = await result.text();
      return result_1 ? JSON.parse(result_1) : {};
    } catch (error) {
      return { Message: error.message };
    }
  } else {
    return { Message: "Erreur inconnu" };
  }
}
