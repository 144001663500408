import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetPaByCTDN } from "./../Get/Pa";

var _ = require("lodash");

export async function UpdatePa_MDF(headers) {
  const _paCount = await GetApiData("PACount", headers);

  if (_paCount.Message !== undefined) {
    return _paCount;
  }

  const promise = new Promise((res, rej) => {
    if (_paCount === 0) res("");

    addTableWithProgress("PA", _paCount, 2500, headers, (getProgress, getPa) => {
      if (getPa.Message !== undefined) {
        return res(getPa);
      }

      _.forEach(getPa, (pa) => {
        const pa_a_mettre_a_jour = {
          CONCESSIONTYPE: pa.CONCESSIONTYPE,
          CODECIME: pa.CODECIME,
          CODETYPE: pa.CODETYPE,
          CODEDURE: pa.CODEDURE,
          CODENOMBRE: pa.CODENOMBRE,
          PA1_VIGUEUR: pa.PA1_VIGUEUR,
          PA1_MESSAGE: pa.PA1_MESSAGE,
          PA2_VIGUEUR: pa.PA2_VIGUEUR,
          PA2_MESSAGE: pa.PA2_MESSAGE,
          PA3_VIGUEUR: pa.PA3_VIGUEUR,
          PA3_MESSAGE: pa.PA3_MESSAGE,
        };

        GetPaByCTDN(pa_a_mettre_a_jour.CODECIME, pa_a_mettre_a_jour.CODETYPE, pa_a_mettre_a_jour.CODEDURE, pa_a_mettre_a_jour.CODENOMBRE).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.PA.add(pa_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(pa_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.PA.put(pa_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _paCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_paCount} pa`);

    return val;
  });
}
