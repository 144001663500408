import db from "../Db";
import { GetConcessById } from "./Concess";
import { GetDefuntByLicleunik } from "./Defunt";

function decimalToRgb(decimal) {
  return {
    red: (decimal >> 16) & 0xff,
    green: (decimal >> 8) & 0xff,
    blue: decimal & 0xff,
  };
}

function rgbTodecimal(r, g, b) {
  return (r << 16) + (g << 8) + b;
}

export function GetAllCimetier(WITH_COLUMB = 1) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    if (WITH_COLUMB === 1) {
      db.CIMETIER.toArray().then((cimetier) => {
        return resolve(cimetier);
      });
    } else {
      db.CIMETIER.where("IMPRIMECOLUMB")
        .equals(0)
        .toArray()
        .then((cimetier) => {
          return resolve(cimetier);
        });
    }
  });
}

export function GetCouleurEmpl(LIEU) {
  var couleur = 0;

  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CIMETIER.where("NUMCIME")
      .equals(parseInt(LIEU.CIMEL))
      .first()
      .then(async (cimetier) => {
        const concess = await GetConcessById(LIEU.COCLEUNIK);
        const defunt = await GetDefuntByLicleunik(LIEU.LICLEUNIK);

        if (concess !== undefined || defunt.length > 0) {
          couleur = cimetier.COULEUR_EMPL;

          const rgb = decimalToRgb(couleur);
          couleur = rgbTodecimal(rgb.blue, rgb.green, rgb.red);

          if (couleur !== 0 && couleur !== -1) {
            couleur = "#" + couleur.toString(16);
          } else {
            couleur = "#E5E5FE";
          }
        } else {
          couleur = "#9ACD32";
        }

        return resolve(couleur);
      });
  });
}

export function GetCimetierById(NUMCIME) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CIMETIER.where("NUMCIME")
      .equals(parseInt(NUMCIME))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
