import db from "../Db";
import { GetAllAgendaTache } from "./AgendaTache";

var _ = require("lodash");

export function GetAllDefunt() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.toArray().then((result) => {
      return resolve(_.orderBy(result, ["ETAT_CIVIL.NOMDEF", "ETAT_CIVIL.PRENOMDEF"], "asc"));
    });
  });
}

export function GetAllDefuntInTask() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetAllAgendaTache().then((tache) => {
      let D0CLEUNIK = [];

      _.forEach(tache, (t) => {
        if (t.D0CLEUNIK > 0) {
          D0CLEUNIK.push(t.D0CLEUNIK);
        }
      });

      db.DEFUNT.where("D0CLEUNIK")
        .anyOf(D0CLEUNIK)
        .toArray()
        .then((result) => {
          return resolve(_.orderBy(result, ["ETAT_CIVIL.NOMDEF", "ETAT_CIVIL.PRENOMDEF"], "asc"));
        });
    });
  });
}

export function GetDefuntById(D0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.where("D0CLEUNIK")
      .equals(parseInt(D0CLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetDefuntByLicleunik(LICLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.where("LICLEUNIK")
      .equals(parseInt(LICLEUNIK))
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetDefuntByIdTablette(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.get(parseInt(ID)).then((result) => {
      return resolve(result);
    });
  });
}

export function GetDefuntByCocleunik(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.where("COCLEUNIK")
      .equals(COCLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetDefuntInCendre() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.where("NUMCENDRE")
      .notEqual(0)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetDefuntByNumcendre(NUMCENDRE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.where("NUMCENDRE")
      .equals(parseInt(NUMCENDRE))
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
