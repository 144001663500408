import db from "../Db";
import { handleError } from "../Function";
import { GetDefuntById } from "../Get/Defunt";
import DeleteDefuntS from "./DefuntS";

export function DeleteDefunt(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DEFUNT.delete(ID);

    return resolve(true);
  });
}

export function DeleteDefuntByD0cleunik(D0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetDefuntById(D0CLEUNIK).then((defunt) => {
      if (defunt !== undefined) {
        DeleteDefuntS(defunt)
          .then((result) => {
            if (result !== "") return;

            db.DEFUNT.delete(defunt.id);

            return resolve(true);
          })
          .catch((err) => {
            handleError(err);
          });
      }
    });
  });
}
