import React, { Component } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff, Close } from "@material-ui/icons";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { sha256 } from "js-sha256";
import base64 from "base64-arraybuffer";
import IdleTimer from "react-idle-timer";

import Home from "./Home";

import { GetUtilByIdentifiant } from "./../Helpers/Get/Util";
import { CheckUserRight } from "./../Helpers/Get/LigneAction";
import { Alert } from "@material-ui/lab";
import { GetClient } from "./../Helpers/Get/Client";

import jsonpackage from "../../package.json";
import SessionTimer from "./Global/Object/SessionTimer";
import { GetDefuntSExist } from "../Helpers/Get/DefuntS";

const styles = {
  grid_container: {
    flex: 1,
  },
  grid_item: {
    maxWidth: 345,
  },
  card: {
    padding: "16px",
  },
  card_media: {
    backgroundSize: "contain",
    paddingTop: "35%",
  },
  card_content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  submit_div: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

class LogIn extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.IDENTIFIANT = React.createRef();
    this.MOT_DE_PASSE = React.createRef();

    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);

    this.state = {
      LOGGED: this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))) !== undefined,
      PASS_VISIBILITY: false,
      login_error: false,
      droit_util_login: false,
      idle_disconnect: false,
      erreurTelechargement: false,
    };
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);

    // timeout = 1800000 = 30 minutes (prod) - timeout = 10000 = 10 secondes (test)
    let timer = 1800000;
    // if (process.env.REACT_APP_ENVIRONMENT !== undefined && process.env.REACT_APP_ENVIRONMENT === "dev") {
    //   timer = 10000;
    // }

    SessionTimer.setTimer(timer);

    const defuntsExist = GetDefuntSExist();
    defuntsExist.then((res) => {
      if (!res) {
        this.setState({ erreurTelechargement: true });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onUnload = () => {
    this.props.cookies.remove(encodeURIComponent(btoa("ID_UTIL")), {
      path: "/",
    });

    var historyObj = window.history;
    historyObj.pushState(null, null, "/");
  };

  _handle_submit_form_login = (event) => {
    event.preventDefault();

    let ALIAS = atob(this.props.cookies.get(encodeURIComponent(btoa("ALIAS"))));
    let IDENTIFIANT = this.IDENTIFIANT.current.value;
    let MOT_DE_PASSE = this.MOT_DE_PASSE.current.value;

    let buffer;
    if (IDENTIFIANT === "gescime" && MOT_DE_PASSE === "copernic") {
      buffer = sha256.arrayBuffer(MOT_DE_PASSE);
      MOT_DE_PASSE = base64.encode(buffer);

      this.setCookies(0);

      this.setState({ LOGGED: true });
    } else {
      buffer = sha256.arrayBuffer(MOT_DE_PASSE + ALIAS);
      MOT_DE_PASSE = base64.encode(buffer);

      GetUtilByIdentifiant(IDENTIFIANT, MOT_DE_PASSE).then((gens) => {
        if (gens !== undefined) {
          CheckUserRight(gens.UTCLEUNIK, "LOGIN", "DROITLECTURE").then((asRight) => {
            if (asRight) {
              this.setCookies(gens.UTCLEUNIK);

              const headers = {
                _cle: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
                "Content-Type": "application/json",
              };

              GetClient().then((clientTablette) => {
                const date = new Date();
                date.setHours(date.getHours() - date.getTimezoneOffset() / 60, date.getMinutes(), date.getSeconds());

                const data = {
                  ID_TABLETTE: clientTablette.ID_TABLETTE,
                  ID_CLIENT: clientTablette.IDCLIENT,
                  NOM_UTIL_GESCIME: gens.LOGIN,
                  DATE_DERNIERE_CONNEXION: date.toISOString().slice(0, -5),
                };

                fetch(`${jsonpackage.urlapi}EnregistrementTablette`, {
                  mode: "cors",
                  method: "POST",
                  headers: headers,
                  body: JSON.stringify(data),
                })
                  .then((result) => result.text())
                  .then(() => {
                    this.setState({ LOGGED: true, idle_disconnect: false });
                  })
                  .catch(() => {
                    this.setState({ LOGGED: true, idle_disconnect: false });
                  });
              });
            } else {
              this.setState({ droit_util_login: true });
            }
          });
        } else {
          this.setState({ login_error: true });
        }
      });
    }
  };

  setCookies = (ID_UTIL) => {
    const expire = new Date();
    expire.setFullYear(expire.getFullYear() + 10);

    this.props.cookies.set(encodeURIComponent(btoa("ID_UTIL")), btoa(ID_UTIL), {
      path: "/",
      expires: expire,
    });
  };

  handleOnIdle = () => {
    this.props.cookies.remove(encodeURIComponent(btoa("ID_UTIL")), {
      path: "/",
    });

    if (SessionTimer.getTimer() > 0) {
      var historyObj = window.history;
      historyObj.pushState(null, null, "/");

      this.setState({ LOGGED: false, idle_disconnect: true });
    }
  };

  render() {
    console.log("RENDER LOGIN");

    return this.state.LOGGED ? (
      <div style={{ display: "flex", flex: 1 }}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={SessionTimer.getTimer()}
          onIdle={this.handleOnIdle}
          debounce={250}
        />

        <Home newVersionAvailable={this.props.newVersionAvailable} setNewVersionAvailable={this.props.setNewVersionAvailable} />
      </div>
    ) : (
      <Grid container alignItems="center" justify="center" style={styles.grid_container}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Card style={styles.card}>
            <CardMedia image={process.env.PUBLIC_URL + "logoGescime.svg"} title="Logo Gescime" alt="Logo Gescime" style={styles.card_media} />

            <form onSubmit={this._handle_submit_form_login}>
              <CardContent style={styles.card_content}>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  autoComplete="username"
                  autoCorrect="off"
                  label="Identifiant"
                  inputRef={this.IDENTIFIANT}
                  onChange={(event) => {
                    this.IDENTIFIANT.current.value = event.target.value;
                  }}
                />

                <FormControl required fullWidth margin="normal">
                  <InputLabel htmlFor="MOT_DE_PASSE">Mot de passe</InputLabel>

                  <Input
                    id="MOT_DE_PASSE"
                    autoComplete="current-password"
                    autoCorrect="off"
                    type={this.state.PASS_VISIBILITY ? "text" : "password"}
                    inputRef={this.MOT_DE_PASSE}
                    endAdornment={
                      <InputAdornment>
                        <IconButton
                          aria-label="Visualiser le mot de passe"
                          onClick={() =>
                            this.setState({
                              PASS_VISIBILITY: !this.state.PASS_VISIBILITY,
                            })
                          }
                        >
                          {this.state.PASS_VISIBILITY ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </CardContent>

              <div style={styles.submit_div}>
                <Button variant="contained" color="primary" type="submit">
                  Connexion
                </Button>
              </div>
            </form>
          </Card>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={this.state.login_error}
            autoHideDuration={4000}
            onClose={() => this.setState({ login_error: false })}
          >
            <Alert severity="error" onClose={() => this.setState({ login_error: false })}>
              Identifiant et/ou mot de passe incorrect
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={this.state.idle_disconnect}
            onClose={() => this.setState({ idle_disconnect: false })}
          >
            <Alert severity="warning" onClose={() => this.setState({ idle_disconnect: false })}>
              Session expirée. Veuillez vous reconnecter
            </Alert>
          </Snackbar>

          <Dialog onClose={() => this.setState({ droit_util_login: false })} open={this.state.droit_util_login}>
            <DialogTitle onClose={() => this.setState({ droit_util_login: false })}>
              <Typography variant="h6" component="p">
                Attention
              </Typography>

              <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ droit_util_login: false })}>
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Typography gutterBottom>Vous n’avez pas les droits vous permettant d’utiliser l’application « Gardiens de cimetières »</Typography>
            </DialogContent>
          </Dialog>

          <Dialog onClose={() => this.setState({ erreurTelechargement: false })} open={this.state.erreurTelechargement}>
            <DialogTitle onClose={() => this.setState({ erreurTelechargement: false })}>
              <Typography variant="h6" component="p">
                ATTENTION
              </Typography>

              <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ erreurTelechargement: false })}>
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Typography gutterBottom>
                {`L'installation de l'application ‘Gardiens de Cimetières’ a échoué (il peut s'agir d'une déconnexion d'internet ou d'un problème matériel).`}
                <br />
                <br />
                Que souhaitez-vous faire ?
              </Typography>
            </DialogContent>

            <DialogActions style={{ justifyContent: "space-between" }}>
              <Button onClick={() => this.setState({ erreurTelechargement: false })} color="primary">
                Annuler
              </Button>

              <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                <Button onClick={this.props.handle_click_recommencer(false)} color="primary">
                  Recommencer
                </Button>

                <Button onClick={this.props.handle_click_recommencer(true)} color="primary">
                  Recommencer sans les photographies
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(LogIn);
