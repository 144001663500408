import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreatePa(headers) {
  const _paCount = await GetApiData("PACount", headers);

  if (_paCount.Message !== undefined) {
    return _paCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("PA", _paCount, 2500, headers, (getProgress, getPa) => {
      if (getPa.Message !== undefined) {
        return res(getPa);
      }

      var drops = [];
      _.forEach(getPa, (pa) => {
        drops.push({
          CONCESSIONTYPE: pa.CONCESSIONTYPE,
          CODECIME: pa.CODECIME,
          CODETYPE: pa.CODETYPE,
          CODEDURE: pa.CODEDURE,
          CODENOMBRE: pa.CODENOMBRE,
          PA1_VIGUEUR: pa.PA1_VIGUEUR,
          PA1_MESSAGE: pa.PA1_MESSAGE,
          PA2_VIGUEUR: pa.PA2_VIGUEUR,
          PA2_MESSAGE: pa.PA2_MESSAGE,
          PA3_VIGUEUR: pa.PA3_VIGUEUR,
          PA3_MESSAGE: pa.PA3_MESSAGE,
        });
      });

      db.open()
        .then(() => {
          db.PA.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _paCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_paCount} pa`);

    return val;
  });
}
