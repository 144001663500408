var CurrentTab = (function () {
  var tab = 0;

  var getTab = function () {
    return tab;
  };

  var setTab = function (currentTab) {
    tab = currentTab;
  };

  return {
    getTab: getTab,
    setTab: setTab,
  };
})();

export default CurrentTab;
