import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

var _ = require("lodash");

export async function CreateLigneAction(headers) {
  const _ligneAction = await GetApiData("LIGNE_ACTION", headers);

  if (_ligneAction.Message !== undefined) {
    return _ligneAction;
  }

  var drops = [];
  _.forEach(_ligneAction, (ligneAction) => {
    drops.push({
      IDACTION_UTIL: ligneAction.IDACTION_UTIL,
      DROITAJOUT: ligneAction.DROITAJOUT,
      DROITLECTURE: ligneAction.DROITLECTURE,
      DROITMODIF: ligneAction.DROITMODIF,
      UTCLEUNIK: ligneAction.UTCLEUNIK,
      NUMACTION: ligneAction.NUMACTION,
    });
  });

  return await db
    .open()
    .then(() => {
      return db.LIGNE_ACTION.bulkAdd(drops)
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
