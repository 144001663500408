import { CreateDefuntS } from "../Create/DefuntS";
import { handleError } from "../Function";
import DeleteDefuntS from "../Delete/DefuntS_MDF";

export async function UpdateDefuntS_MDF() {
  return await DeleteDefuntS().then(async () => {
    try {
      const result = await CreateDefuntS();
      return result;
    } catch (err) {
      handleError(err);
    }
  });
}
