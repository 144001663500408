import db from "../Db";

export function GetClient() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CLIENT.get(1).then((result) => {
      return resolve(result);
    });
  });
}
