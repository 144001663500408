var SessionTimer = (function () {
  var time = 0;

  var getTimer = function () {
    return time;
  };

  var setTimer = function (currentTime) {
    time = currentTime;
  };

  return {
    getTimer: getTimer,
    setTimer: setTimer,
  };
})();

export default SessionTimer;
