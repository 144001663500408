import { UpdateGNUtil_MDF } from "./Update/GnUtil_MDF";
import { UpdateParam_MDF } from "./Update/Param_MDF";
import { UpdateDefunt_MDF } from "./Update/Defunt_MDF";
import { UpdateLieu_MDF } from "./Update/Lieu_MDF";
import { UpdateCendre_MDF } from "./Update/Cendre_MDF";
import { UpdatePlan_MDF } from "./Update/Plan_MDF";
import { UpdateZone_MDF } from "./Update/Zone_MDF";
import { UpdateConcess_MDF } from "./Update/Concess_MDF";
import { UpdateConcaire_MDF } from "./Update/Concaire_MDF";
import { UpdateTypetb_MDF } from "./Update/Typetb_MDF";
import { UpdateDuree_MDF } from "./Update/Duree_MDF";
import { UpdatePhotoconc_MDF } from "./Update/Photoconc_MDF";
import { UpdateInterven_MDF } from "./Update/Interven_MDF";
import { UpdateIntervenDefunt_MDF } from "./Update/intervenDefunt_MDF";
import { UpdateNatinterv_MDF } from "./Update/Natinterv_MDF";
import { UpdateProcedureAbandon_MDF } from "./Update/ProcedureAbandon_MDF";
import { UpdateSociete_MDF } from "./Update/Societe_MDF";
import { UpdateUtil_MDF } from "./Update/Util_MDF";
import { UpdateGens_MDF } from "./Update/Gens_MDF";
import { UpdateAgendaTache_MDF } from "./Update/AgendaTache_MDF";
import { UpdateMouvement_MDF } from "./Update/Mouvement_MDF";
import { UpdateAccessibilite_MDF } from "./Update/Accessibilite_MDF";
import { UpdateEtatTerrain_MDF } from "./Update/EtatTerrain_MDF";
import { UpdateConcessEtatEcheance_MDF } from "./Update/ConcessEtatEcheance_MDF";
import { UpdateThanatomorphose_MDF } from "./Update/Thanatomorphose_MDF";
import { UpdateMateriau_MDF } from "./Update/Materiau_MDF";
import { UpdatePa_MDF } from "./Update/Pa_MDF";
import { UpdateLigneAction_MDF } from "./Update/LigneAction_MDF";
import { UpdateSvg_MDF } from "./Update/Svg_MDF";
import { UpdateDefuntS_MDF } from "./Update/DefuntS_MDF";
import { Suppression_MDF } from "./Update/Suppression_MDF";
import { Purge_Gnet, Purge_Tablette } from "./Update/Purge";
import { DeletePhotoForInterven } from "./Delete/Photoconc_MDF";
import UpdateClientDateSynchro from "./Update/Client";
import LoaderState from "./../Components/Global/Object/LoaderState";
import LieuHistoAcces from "./../Components/Global/Object/LieuHistoAcces";

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function CreateTable_MDF(headers, idgens, idtablette, date, _change_loader_state) {
  return new Promise((resolve, reject) => {
    headers.ID_GENS = idgens;
    headers.ID_TABLETTE = idtablette;

    LoaderState.setTable("PURGEGNET");
    _change_loader_state();

    const purge_Gnet = Purge_Gnet(headers);

    purge_Gnet
      .then(() => {
        LoaderState.setIndex(LoaderState.getIndex() + 1);
        LoaderState.setTable("GN_UTIL");
        _change_loader_state();

        const updateGNUtil_MDF = UpdateGNUtil_MDF(headers);

        updateGNUtil_MDF
          .then((updated) => {
            if (updated === undefined || updated !== "") return reject(updated);

            LoaderState.setIndex(LoaderState.getIndex() + 1);
            LoaderState.setTable("PARAM");
            _change_loader_state();

            const updateParam_MDF = UpdateParam_MDF(headers);

            updateParam_MDF
              .then((updated) => {
                if (updated === undefined || updated !== "") return reject(updated);

                LoaderState.setIndex(LoaderState.getIndex() + 1);
                LoaderState.setTable("DEFUNT");
                _change_loader_state();

                const updateDefunt_MDF = UpdateDefunt_MDF(headers);

                updateDefunt_MDF
                  .then((updated) => {
                    if (updated === undefined || updated !== "") return reject(updated);

                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                    LoaderState.setTable("LIEU");
                    _change_loader_state();

                    timeout(1000).then(() => {
                      const updateLieu_MDF = UpdateLieu_MDF(headers);

                      updateLieu_MDF
                        .then((updated) => {
                          if (updated === undefined || updated !== "") return reject(updated);

                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                          LoaderState.setTable("CENDRE");
                          _change_loader_state();

                          timeout(1000).then(() => {
                            const updateCendre_MDF = UpdateCendre_MDF(headers);

                            updateCendre_MDF
                              .then((updated) => {
                                if (updated === undefined || updated !== "") return reject(updated);

                                LoaderState.setIndex(LoaderState.getIndex() + 1);
                                LoaderState.setTable("PLAN");
                                _change_loader_state();

                                const updatePlan_MDF = UpdatePlan_MDF(headers);

                                updatePlan_MDF
                                  .then((updated) => {
                                    if (updated === undefined || updated !== "") return reject(updated);

                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                    LoaderState.setTable("ZONE");
                                    _change_loader_state();

                                    const updateZone_MDF = UpdateZone_MDF(headers);

                                    updateZone_MDF
                                      .then((updated) => {
                                        if (updated === undefined || updated !== "") return reject(updated);

                                        LoaderState.setIndex(LoaderState.getIndex() + 1);
                                        LoaderState.setTable("CONCESS");
                                        _change_loader_state();

                                        timeout(1000).then(() => {
                                          const updateConcess_MDF = UpdateConcess_MDF(headers);

                                          updateConcess_MDF
                                            .then((updated) => {
                                              if (updated === undefined || updated !== "") return reject(updated);

                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                              LoaderState.setTable("CONCAIRE");
                                              _change_loader_state();

                                              timeout(1000).then(() => {
                                                const updateConcaire_MDF = UpdateConcaire_MDF(headers);

                                                updateConcaire_MDF
                                                  .then((updated) => {
                                                    if (updated === undefined || updated !== "") return reject(updated);

                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                    LoaderState.setTable("TYPETB");
                                                    _change_loader_state();

                                                    timeout(1000).then(() => {
                                                      const updateTypetb_MDF = UpdateTypetb_MDF(headers);

                                                      updateTypetb_MDF
                                                        .then((updated) => {
                                                          if (updated === undefined || updated !== "") return reject(updated);

                                                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                          LoaderState.setTable("DUREE");
                                                          _change_loader_state();

                                                          const updateDuree_MDF = UpdateDuree_MDF(headers);

                                                          updateDuree_MDF
                                                            .then((updated) => {
                                                              if (updated === undefined || updated !== "") return reject(updated);

                                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                              LoaderState.setTable("INTERVEN");
                                                              _change_loader_state();

                                                              const updateInterven_MDF = UpdateInterven_MDF(headers);

                                                              updateInterven_MDF
                                                                .then((updated) => {
                                                                  if (updated === undefined || updated !== "") return reject(updated);

                                                                  LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                  LoaderState.setTable("INTERVEN_DEFUNT");
                                                                  _change_loader_state();

                                                                  timeout(1000).then(() => {
                                                                    const updateIntervenDefunt_MDF = UpdateIntervenDefunt_MDF(headers);

                                                                    updateIntervenDefunt_MDF
                                                                      .then((updated) => {
                                                                        if (updated === undefined || updated !== "") return reject(updated);

                                                                        LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                        LoaderState.setTable("NATINTERV");
                                                                        _change_loader_state();

                                                                        timeout(1000).then(() => {
                                                                          const updateNatinterv_MDF = UpdateNatinterv_MDF(headers);

                                                                          updateNatinterv_MDF
                                                                            .then((updated) => {
                                                                              if (updated === undefined || updated !== "") return reject(updated);

                                                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                              LoaderState.setTable("PROCEDUREABANDON");
                                                                              _change_loader_state();

                                                                              timeout(1000).then(() => {
                                                                                const updateProcedureAbandon_MDF = UpdateProcedureAbandon_MDF(headers);

                                                                                updateProcedureAbandon_MDF
                                                                                  .then((updated) => {
                                                                                    if (updated === undefined || updated !== "") return reject(updated);

                                                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                    LoaderState.setTable("SOCIETE");
                                                                                    _change_loader_state();

                                                                                    const updateSociete_MDF = UpdateSociete_MDF(headers);

                                                                                    updateSociete_MDF
                                                                                      .then((updated) => {
                                                                                        if (updated === undefined || updated !== "") return reject(updated);

                                                                                        LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                        LoaderState.setTable("UTIL");
                                                                                        _change_loader_state();

                                                                                        timeout(1000).then(() => {
                                                                                          const updateUtil_MDF = UpdateUtil_MDF(headers);

                                                                                          updateUtil_MDF
                                                                                            .then((updated) => {
                                                                                              if (updated === undefined || updated !== "") return reject(updated);

                                                                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                              LoaderState.setTable("GENS");
                                                                                              _change_loader_state();

                                                                                              const updateGens_MDF = UpdateGens_MDF(headers);

                                                                                              updateGens_MDF
                                                                                                .then((updated) => {
                                                                                                  if (updated === undefined || updated !== "") return reject(updated);

                                                                                                  LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                  LoaderState.setTable("AGENDATACHE");
                                                                                                  _change_loader_state();

                                                                                                  const updateAgendaTache_MDF = UpdateAgendaTache_MDF(headers);

                                                                                                  updateAgendaTache_MDF
                                                                                                    .then((updated) => {
                                                                                                      if (updated === undefined || updated !== "") return reject(updated);

                                                                                                      LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                      LoaderState.setTable("MOUVEMENT");
                                                                                                      _change_loader_state();

                                                                                                      timeout(1000).then(() => {
                                                                                                        const updateMouvement_MDF = UpdateMouvement_MDF(headers);

                                                                                                        updateMouvement_MDF
                                                                                                          .then((updated) => {
                                                                                                            if (updated === undefined || updated !== "") return reject(updated);

                                                                                                            LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                            LoaderState.setTable("ACCESSIBILITE");
                                                                                                            _change_loader_state();

                                                                                                            timeout(1000).then(() => {
                                                                                                              const updateAccessibilite_MDF = UpdateAccessibilite_MDF(headers);

                                                                                                              updateAccessibilite_MDF
                                                                                                                .then((updated) => {
                                                                                                                  if (updated === undefined || updated !== "") return reject(updated);

                                                                                                                  LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                  LoaderState.setTable("ETAT_TERRAIN");
                                                                                                                  _change_loader_state();

                                                                                                                  const updateEtatTerrain_MDF = UpdateEtatTerrain_MDF(headers);

                                                                                                                  updateEtatTerrain_MDF
                                                                                                                    .then((updated) => {
                                                                                                                      if (updated === undefined || updated !== "") return reject(updated);

                                                                                                                      LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                      LoaderState.setTable("CONCESS_ETAT_ECHEANCE");
                                                                                                                      _change_loader_state();

                                                                                                                      const updateConcessEtatEcheance_MDF = UpdateConcessEtatEcheance_MDF(headers);

                                                                                                                      updateConcessEtatEcheance_MDF
                                                                                                                        .then((updated) => {
                                                                                                                          if (updated === undefined || updated !== "") return reject(updated);

                                                                                                                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                          LoaderState.setTable("THANATOMORPHOSE");
                                                                                                                          _change_loader_state();

                                                                                                                          const updateThanatomorphose_MDF = UpdateThanatomorphose_MDF(headers);

                                                                                                                          updateThanatomorphose_MDF
                                                                                                                            .then((updated) => {
                                                                                                                              if (updated === undefined || updated !== "") return reject(updated);

                                                                                                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                              LoaderState.setTable("MATERIAU");
                                                                                                                              _change_loader_state();

                                                                                                                              const updateMateriau_MDF = UpdateMateriau_MDF(headers);

                                                                                                                              updateMateriau_MDF
                                                                                                                                .then((updated) => {
                                                                                                                                  if (updated === undefined || updated !== "") return reject(updated);

                                                                                                                                  LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                  LoaderState.setTable("PA");
                                                                                                                                  _change_loader_state();

                                                                                                                                  const updatePa_MDF = UpdatePa_MDF(headers);

                                                                                                                                  updatePa_MDF
                                                                                                                                    .then((updated) => {
                                                                                                                                      if (updated === undefined || updated !== "")
                                                                                                                                        return reject(updated);

                                                                                                                                      LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                      LoaderState.setTable("LIGNE_ACTION");
                                                                                                                                      _change_loader_state();

                                                                                                                                      const updateLigneAction_MDF = UpdateLigneAction_MDF(headers);

                                                                                                                                      updateLigneAction_MDF
                                                                                                                                        .then((updated) => {
                                                                                                                                          if (updated === undefined || updated !== "")
                                                                                                                                            return reject(updated);

                                                                                                                                          LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                          LoaderState.setTable("SVG");
                                                                                                                                          _change_loader_state();

                                                                                                                                          timeout(1000).then(() => {
                                                                                                                                            if (LieuHistoAcces.getLieu().length > 0) {
                                                                                                                                              const updateSvg_MDF = UpdateSvg_MDF();

                                                                                                                                              updateSvg_MDF
                                                                                                                                                .then((updated) => {
                                                                                                                                                  if (updated === undefined || updated !== "")
                                                                                                                                                    return reject(updated);

                                                                                                                                                  LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                                  LoaderState.setTable("PHOTOCONC");
                                                                                                                                                  _change_loader_state();

                                                                                                                                                  timeout(1000).then(() => {
                                                                                                                                                    const updatePhotoconc_MDF = UpdatePhotoconc_MDF(
                                                                                                                                                      headers,
                                                                                                                                                      _change_loader_state
                                                                                                                                                    );

                                                                                                                                                    updatePhotoconc_MDF
                                                                                                                                                      .then((updated) => {
                                                                                                                                                        if (updated === undefined || updated !== "")
                                                                                                                                                          return reject(updated);

                                                                                                                                                        LoaderState.setIndex(
                                                                                                                                                          LoaderState.getIndex() + 1
                                                                                                                                                        );
                                                                                                                                                        LoaderState.setTable("DEFUNT_S");
                                                                                                                                                        LoaderState.setNombreTotalPhotos(0);
                                                                                                                                                        LoaderState.setNombrePhotos(0);
                                                                                                                                                        _change_loader_state();

                                                                                                                                                        timeout(1000).then(() => {
                                                                                                                                                          const updateDefuntS_MDF = UpdateDefuntS_MDF();

                                                                                                                                                          updateDefuntS_MDF
                                                                                                                                                            .then((updated) => {
                                                                                                                                                              if (
                                                                                                                                                                updated === undefined ||
                                                                                                                                                                updated !== ""
                                                                                                                                                              )
                                                                                                                                                                return reject(updated);

                                                                                                                                                              LoaderState.setIndex(
                                                                                                                                                                LoaderState.getIndex() + 1
                                                                                                                                                              );
                                                                                                                                                              LoaderState.setTable("SUPPRESSION");
                                                                                                                                                              _change_loader_state();

                                                                                                                                                              timeout(1000).then(() => {
                                                                                                                                                                const suppression_MDF =
                                                                                                                                                                  Suppression_MDF(headers);

                                                                                                                                                                suppression_MDF
                                                                                                                                                                  .then((updated) => {
                                                                                                                                                                    if (
                                                                                                                                                                      updated === undefined ||
                                                                                                                                                                      updated !== ""
                                                                                                                                                                    )
                                                                                                                                                                      return reject(updated);

                                                                                                                                                                    LoaderState.setIndex(
                                                                                                                                                                      LoaderState.getIndex() + 1
                                                                                                                                                                    );
                                                                                                                                                                    LoaderState.setTable(
                                                                                                                                                                      "PURGETABLETTE"
                                                                                                                                                                    );
                                                                                                                                                                    _change_loader_state();

                                                                                                                                                                    timeout(1000).then(() => {
                                                                                                                                                                      const purge_Tablette =
                                                                                                                                                                        Purge_Tablette();

                                                                                                                                                                      purge_Tablette
                                                                                                                                                                        .then(() => {
                                                                                                                                                                          LoaderState.setTable(
                                                                                                                                                                            "PHOTOCONC_INTERVEN"
                                                                                                                                                                          );

                                                                                                                                                                          const deletePhotoForInterven =
                                                                                                                                                                            DeletePhotoForInterven(
                                                                                                                                                                              _change_loader_state
                                                                                                                                                                            );

                                                                                                                                                                          deletePhotoForInterven
                                                                                                                                                                            .then(() => {
                                                                                                                                                                              LoaderState.setTable(
                                                                                                                                                                                "DATE_SYNCHRO"
                                                                                                                                                                              );
                                                                                                                                                                              LoaderState.setNombreTotalPhotos(
                                                                                                                                                                                0
                                                                                                                                                                              );
                                                                                                                                                                              LoaderState.setNombrePhotos(
                                                                                                                                                                                0
                                                                                                                                                                              );
                                                                                                                                                                              _change_loader_state();

                                                                                                                                                                              const updateClientDateSynchro =
                                                                                                                                                                                UpdateClientDateSynchro(
                                                                                                                                                                                  date
                                                                                                                                                                                );

                                                                                                                                                                              updateClientDateSynchro
                                                                                                                                                                                .then(() => {
                                                                                                                                                                                  LoaderState.setIndex(
                                                                                                                                                                                    LoaderState.getIndex() +
                                                                                                                                                                                      1
                                                                                                                                                                                  );
                                                                                                                                                                                  _change_loader_state();

                                                                                                                                                                                  return resolve("");
                                                                                                                                                                                })
                                                                                                                                                                                .catch((err) => {
                                                                                                                                                                                  return reject(err);
                                                                                                                                                                                });
                                                                                                                                                                            })
                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                              return reject(err);
                                                                                                                                                                            });
                                                                                                                                                                        })
                                                                                                                                                                        .catch((err) => {
                                                                                                                                                                          return reject(err);
                                                                                                                                                                        });
                                                                                                                                                                    });
                                                                                                                                                                  })
                                                                                                                                                                  .catch((err) => {
                                                                                                                                                                    return reject(err);
                                                                                                                                                                  });
                                                                                                                                                              });
                                                                                                                                                            })
                                                                                                                                                            .catch((err) => {
                                                                                                                                                              return reject(err);
                                                                                                                                                            });
                                                                                                                                                        });
                                                                                                                                                      })
                                                                                                                                                      .catch((err) => {
                                                                                                                                                        return reject(err);
                                                                                                                                                      });
                                                                                                                                                  });
                                                                                                                                                })
                                                                                                                                                .catch((err) => {
                                                                                                                                                  return reject(err);
                                                                                                                                                });
                                                                                                                                            } else {
                                                                                                                                              LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                              LoaderState.setTable("PHOTOCONC");
                                                                                                                                              _change_loader_state();

                                                                                                                                              timeout(1000).then(() => {
                                                                                                                                                const updatePhotoconc_MDF = UpdatePhotoconc_MDF(
                                                                                                                                                  headers,
                                                                                                                                                  _change_loader_state
                                                                                                                                                );

                                                                                                                                                updatePhotoconc_MDF
                                                                                                                                                  .then((updated) => {
                                                                                                                                                    if (updated === undefined || updated !== "")
                                                                                                                                                      return reject(updated);

                                                                                                                                                    LoaderState.setIndex(LoaderState.getIndex() + 1);
                                                                                                                                                    LoaderState.setTable("DEFUNT_S");
                                                                                                                                                    LoaderState.setNombreTotalPhotos(0);
                                                                                                                                                    LoaderState.setNombrePhotos(0);
                                                                                                                                                    _change_loader_state();

                                                                                                                                                    timeout(1000).then(() => {
                                                                                                                                                      const updateDefuntS_MDF = UpdateDefuntS_MDF();

                                                                                                                                                      updateDefuntS_MDF
                                                                                                                                                        .then((updated) => {
                                                                                                                                                          if (updated === undefined || updated !== "")
                                                                                                                                                            return reject(updated);

                                                                                                                                                          LoaderState.setIndex(
                                                                                                                                                            LoaderState.getIndex() + 1
                                                                                                                                                          );
                                                                                                                                                          LoaderState.setTable("SUPPRESSION");
                                                                                                                                                          _change_loader_state();

                                                                                                                                                          timeout(1000).then(() => {
                                                                                                                                                            const suppression_MDF =
                                                                                                                                                              Suppression_MDF(headers);

                                                                                                                                                            suppression_MDF
                                                                                                                                                              .then((updated) => {
                                                                                                                                                                if (
                                                                                                                                                                  updated === undefined ||
                                                                                                                                                                  updated !== ""
                                                                                                                                                                )
                                                                                                                                                                  return reject(updated);

                                                                                                                                                                LoaderState.setIndex(
                                                                                                                                                                  LoaderState.getIndex() + 1
                                                                                                                                                                );
                                                                                                                                                                LoaderState.setTable("PURGETABLETTE");
                                                                                                                                                                _change_loader_state();

                                                                                                                                                                timeout(1000).then(() => {
                                                                                                                                                                  const purge_Tablette =
                                                                                                                                                                    Purge_Tablette();

                                                                                                                                                                  purge_Tablette
                                                                                                                                                                    .then(() => {
                                                                                                                                                                      const deletePhotoForInterven =
                                                                                                                                                                        DeletePhotoForInterven();

                                                                                                                                                                      deletePhotoForInterven
                                                                                                                                                                        .then(() => {
                                                                                                                                                                          const updateClientDateSynchro =
                                                                                                                                                                            UpdateClientDateSynchro(
                                                                                                                                                                              date
                                                                                                                                                                            );

                                                                                                                                                                          updateClientDateSynchro
                                                                                                                                                                            .then(() => {
                                                                                                                                                                              LoaderState.setIndex(
                                                                                                                                                                                LoaderState.getIndex() +
                                                                                                                                                                                  1
                                                                                                                                                                              );
                                                                                                                                                                              _change_loader_state();

                                                                                                                                                                              return resolve("");
                                                                                                                                                                            })
                                                                                                                                                                            .catch((err) => {
                                                                                                                                                                              return reject(err);
                                                                                                                                                                            });
                                                                                                                                                                        })
                                                                                                                                                                        .catch((err) => {
                                                                                                                                                                          return reject(err);
                                                                                                                                                                        });
                                                                                                                                                                    })
                                                                                                                                                                    .catch((err) => {
                                                                                                                                                                      return reject(err);
                                                                                                                                                                    });
                                                                                                                                                                });
                                                                                                                                                              })
                                                                                                                                                              .catch((err) => {
                                                                                                                                                                return reject(err);
                                                                                                                                                              });
                                                                                                                                                          });
                                                                                                                                                        })
                                                                                                                                                        .catch((err) => {
                                                                                                                                                          return reject(err);
                                                                                                                                                        });
                                                                                                                                                    });
                                                                                                                                                  })
                                                                                                                                                  .catch((err) => {
                                                                                                                                                    return reject(err);
                                                                                                                                                  });
                                                                                                                                              });
                                                                                                                                            }
                                                                                                                                          });
                                                                                                                                        })
                                                                                                                                        .catch((err) => {
                                                                                                                                          return reject(err);
                                                                                                                                        });
                                                                                                                                    })
                                                                                                                                    .catch((err) => {
                                                                                                                                      return reject(err);
                                                                                                                                    });
                                                                                                                                })
                                                                                                                                .catch((err) => {
                                                                                                                                  return reject(err);
                                                                                                                                });
                                                                                                                            })
                                                                                                                            .catch((err) => {
                                                                                                                              return reject(err);
                                                                                                                            });
                                                                                                                        })
                                                                                                                        .catch((err) => {
                                                                                                                          return reject(err);
                                                                                                                        });
                                                                                                                    })
                                                                                                                    .catch((err) => {
                                                                                                                      return reject(err);
                                                                                                                    });
                                                                                                                })
                                                                                                                .catch((err) => {
                                                                                                                  return reject(err);
                                                                                                                });
                                                                                                            });
                                                                                                          })
                                                                                                          .catch((err) => {
                                                                                                            return reject(err);
                                                                                                          });
                                                                                                      });
                                                                                                    })
                                                                                                    .catch((err) => {
                                                                                                      return reject(err);
                                                                                                    });
                                                                                                })
                                                                                                .catch((err) => {
                                                                                                  return reject(err);
                                                                                                });
                                                                                            })
                                                                                            .catch((err) => {
                                                                                              return reject(err);
                                                                                            });
                                                                                        });
                                                                                      })
                                                                                      .catch((err) => {
                                                                                        return reject(err);
                                                                                      });
                                                                                  })
                                                                                  .catch((err) => {
                                                                                    return reject(err);
                                                                                  });
                                                                              });
                                                                            })
                                                                            .catch((err) => {
                                                                              return reject(err);
                                                                            });
                                                                        });
                                                                      })
                                                                      .catch((err) => {
                                                                        return reject(err);
                                                                      });
                                                                  });
                                                                })
                                                                .catch((err) => {
                                                                  return reject(err);
                                                                });
                                                            })
                                                            .catch((err) => {
                                                              return reject(err);
                                                            });
                                                        })
                                                        .catch((err) => {
                                                          return reject(err);
                                                        });
                                                    });
                                                  })
                                                  .catch((err) => {
                                                    return reject(err);
                                                  });
                                              });
                                            })
                                            .catch((err) => {
                                              return reject(err);
                                            });
                                        });
                                      })
                                      .catch((err) => {
                                        return reject(err);
                                      });
                                  })
                                  .catch((err) => {
                                    return reject(err);
                                  });
                              })
                              .catch((err) => {
                                return reject(err);
                              });
                          });
                        })
                        .catch((err) => {
                          return reject(err);
                        });
                    });
                  })
                  .catch((err) => {
                    return reject(err);
                  });
              })
              .catch((err) => {
                return reject(err);
              });
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
