import db from "../Db";

export function GetAllProcedureAbandon() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PROCEDUREABANDON.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetProcedureAbandonById(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PROCEDUREABANDON.get(parseInt(ID)).then((result) => {
      return resolve(result);
    });
  });
}
