import { LinearProgress, withStyles } from "@material-ui/core";

const StyleLinearProgress = withStyles({
  root: {
    height: 10,
  },
  bar1Determinate: {
    backgroundImage:
      "linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgb(255, 115, 47) 25%, rgb(255, 115, 47) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgb(255, 115, 47) 75%, rgb(255, 115, 47))",
    backgroundSize: "40px 40px",
    transition: "width 3s ease",
    animation: "progress-bar-stripes 2s linear infinite",
  },
})(LinearProgress);

export default StyleLinearProgress;
