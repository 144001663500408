import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateDefunt(headers) {
  const _defuntCount = await GetApiData("DEFUNTCount", headers);

  if (_defuntCount.Message !== undefined) {
    return _defuntCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("DEFUNT", _defuntCount, 10000, headers, (getProgress, getDefunt) => {
      if (getDefunt.Message !== undefined) {
        return res(getDefunt);
      }

      var drops = [];
      _.forEach(getDefunt, (defunt) => {
        drops.push({
          D0CLEUNIK: defunt.D0CLEUNIK,
          ETAT_CIVIL: {
            NOMDEF: defunt.NOMDEF.trim(),
            SEXE: defunt.SEXE,
            NOMJEUNE: defunt.NOMJEUNE.trim(),
            PRENOMDEF: defunt.PRENOMDEF.trim(),
            DATENAISS: defunt.DATENAISS === "1900-01-01T00:00:00" ? "" : defunt.DATENAISS,
            DATEDECES: defunt.DATEDECES === "1900-01-01T00:00:00" ? "" : defunt.DATEDECES,
            DATEINHUM: defunt.DATEINHUM === "1900-01-01T00:00:00" ? "" : defunt.DATEINHUM,
            ANNEEDECES: defunt.ANNEEDECES,
            AGE: defunt.AGE,
          },
          ENVELOPPE: {
            URNE: defunt.URNE,
            RELIQUAIRE: defunt.RELIQUAIRE,
            ENFANT: defunt.ENFANT,
            DISPERSION: defunt.DISPERSION,
            URNE_SCELLE: defunt.URNE_SCELLE,
          },
          BIERRE: {
            MEBI_MC: defunt.MEBI_MC,
            SOINS_CONSERVATIONS: defunt.SOINS_CONSERVATIONS,
            HERMETIQUE: defunt.HERMETIQUE,
            ZINC_HN: defunt.ZINC_HN,
          },
          ENFANTDC: {
            AUCUN: defunt.ENFANTDC === 1 ? 1 : 0,
            ENFANT: defunt.ENFANTDC === 2 ? 1 : 0,
            NOUVEAU_NE: defunt.ENFANTDC === 3 ? 1 : 0,
            MORT_NE: defunt.ENFANTDC === 4 ? 1 : 0,
            FOETUS: defunt.ENFANTDC === 5 ? 1 : 0,
          },
          LICLEUNIK: defunt.LICLEUNIK,
          COCLEUNIK: defunt.COCLEUNIK,
          TYPECIME: defunt.TYPECIME,
          NUMCENDRE: defunt.NUMCENDRE,
          IDMATERIAU: defunt.IDMATERIAU,
          EMPLACEMENT_LIEU: defunt.EMPLACEMENT_LIEU ?? "Provisoire",
        });
      });

      db.open()
        .then(() => {
          db.DEFUNT.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _defuntCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_defuntCount} defunts`);

    return val;
  });
}
