import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AppBar, Dialog, Paper, Slide, Toolbar, Typography, Card, CardMedia, IconButton, CardActions, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { GetPhotoconcByCocleunik } from "./../../../../Helpers/Get/Photoconc";
import { GetTemporaireForColor } from "./../../../../Helpers/Get/Temporaire";
import { IsOracle } from "./../../../../Helpers/Test/IsOracle";
import { CheckUserRight } from "./../../../../Helpers/Get/LigneAction";
import { withCookies } from "react-cookie";
import { DialogAccessRight } from "./../../../Global/DialogAccessRight";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: "16px 0",
  },
  cardcontent: {
    display: "flex",
    justifyContent: "center",
  },
  div: {
    display: "flex",
    marginBottom: 20,
  },
  typographytitle: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    justifyContent: "center",
    marginBottom: 0,
    paddingRight: 95,
  },
  photoconc_container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  photoconc_card: {
    border: "1px solid grey",
    cursor: "pointer",
    flex: 1,
    margin: 10,
    maxWidth: 160,
    minWidth: 160,
    textAlign: "center",
    width: 100,
  },
  photoconc_card_dialog: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    margin: "120px 50px 50px 50px",
  },
  photoconc_cardaction: {
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 3,
  },
  photoconc_cardmedia: {
    backgroundColor: "whitesmoke",
    backgroundSize: "contain",
    borderTop: "1px solid lightgrey",
    height: 0,
    paddingTop: "100%",
  },
  photoconc_cardmedia_dialog: {
    backgroundSize: "contain",
    height: 0,
    paddingTop: "60%",
  },
  appBar: {
    position: "relative",
  },
  typography: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CFCEPhotographie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      PHOTOCONC: [],
      REDIRECT: null,
      champ_modifie: [],
      photoDialogOpen: false,
      photoDialogPhoto: null,
      droit_util_ajout_photoconc: false,
      droit_util_ajout_modif_photoconc: false,
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    CheckUserRight(this.state.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
      if (!asRight) {
        this.setState({ droit_util_ajout_photoconc: true });
      }
    });

    let field = [];
    GetPhotoconcByCocleunik(this.props.concess.COCLEUNIK).then((photoconc) => {
      photoconc = _.filter(photoconc, (photo) => {
        return photo.PHOTO !== "";
      });

      if (photoconc.length > 0) {
        let ordered = _.orderBy(photoconc, ["PRINCIPAL"], ["desc"]);

        if (ordered[0].PRINCIPAL === 0) {
          ordered = _.orderBy(photoconc, ["DATEAJOUT"], ["desc"]);
        }

        this.setState({ PHOTOCONC: ordered });

        _.forEach(ordered, (p) => {
          const temporaire = GetTemporaireForColor("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", p.IDPHOTOCONC);

          temporaire.then((temp) => {
            if (temp.length > 0) {
              _.forEach(temp, (t) => {
                let color = "red";

                if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                  color = "blue";
                }

                if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                  color = "green";
                }

                if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                  color = "primary";
                }

                var element_temp = t.TABLE_MODIFIE_JSON;
                element_temp = _.omit(element_temp, ["id"]);

                let sub_field = {};
                for (var key in element_temp) {
                  sub_field[key] = color;

                  if (key === (this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC")) sub_field[key] = this.state.IS_ORACLE ? element_temp.IDEVOL_ETAT : element_temp.IDPHOTOCONC;
                }

                field.push(sub_field);
              });

              this.setState({ champ_modifie: field });
            }
          });
        });
      }
    });
  };

  _cree_chaine_date = (date) => {
    if (date !== "") {
      if (date.length === 4) {
        return date;
      } else {
        if (date.includes("1900-01-01")) {
          return "";
        } else {
          let c_date = date.split("T")[0].split("-");

          return new Date(c_date[0], c_date[1] - 1, c_date[2]).toLocaleDateString("fr-FR");
        }
      }
    } else {
      return date;
    }
  };

  _find_color = (modifie, idphotoconc) => {
    const temp = _.find(modifie, ["IDPHOTOCONC", idphotoconc]) || _.find(modifie, ["IDEVOL_ETAT", idphotoconc]);

    let color = [];
    for (var key in temp) {
      if (typeof temp[key] === "string") {
        if (!_.includes(color, temp[key])) color.push(temp[key]);
      }
    }

    if (_.includes(color, "red")) return "red";
    if (_.includes(color, "blue")) return "blue";
    if (_.includes(color, "green")) return "green";

    return "grey";
  };

  zoomOnImage = (photo) => (event) => {
    event.preventDefault();

    this.setState({ photoDialogOpen: true, photoDialogPhoto: photo });
  };

  render() {
    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <div style={styles.div}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              CheckUserRight(this.state.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
                if (asRight) {
                  console.log(this.props.pa);

                  if (this.props.echues) {
                    this.setState({
                      REDIRECT: `/modifier/photographies/${this.props.concess.COCLEUNIK}/c/e`,
                    });
                  } else if (this.props.pa) {
                    this.setState({
                      REDIRECT: `/modifier/photographies/${this.props.concess.COCLEUNIK}/c/pa`,
                    });
                  } else {
                    this.setState({
                      REDIRECT: `/modifier/photographies/${this.props.concess.COCLEUNIK}/c`,
                    });
                  }
                } else {
                  CheckUserRight(this.state.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
                    if (asRight) {
                      if (this.props.echues) {
                        this.setState({
                          REDIRECT: `/modifier/photographies/${this.props.concess.COCLEUNIK}/c/e`,
                        });
                      } else if (this.props.pa) {
                        this.setState({
                          REDIRECT: `/modifier/photographies/${this.props.concess.COCLEUNIK}/c/pa`,
                        });
                      } else {
                        this.setState({
                          REDIRECT: `/modifier/photographies/${this.props.concess.COCLEUNIK}/c`,
                        });
                      }
                    } else {
                      this.setState({ droit_util_ajout_modif_photoconc: true });
                    }
                  });
                }
              });
            }}
          >
            {!this.state.droit_util_ajout_photoconc ? "Ajouter" : "Modifier"}
          </Button>

          <Typography variant="body2" gutterBottom style={styles.typographytitle} className="TypographyTitle-FicheConcession">
            Liste des photographies
          </Typography>
        </div>

        {this.state.PHOTOCONC.length > 0 ? (
          <div style={styles.photoconc_container}>
            {this.state.PHOTOCONC.map((photoconc, index) =>
              photoconc.PHOTO !== "" ? (
                <Card
                  key={index}
                  style={{
                    ...styles.photoconc_card,
                    border: `1px solid ${this._find_color(this.state.champ_modifie, photoconc.IDPHOTOCONC)}`,
                  }}
                >
                  <CardActions disableSpacing style={styles.photoconc_cardaction}>
                    <Typography variant="body2">{`Prise le ${this._cree_chaine_date(photoconc.DATEAJOUT)}`}</Typography>

                    <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                      {photoconc.PRINCIPAL ? "Référente" : null}
                    </Typography>
                  </CardActions>

                  <CardMedia onClick={this.zoomOnImage(photoconc.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photoconc.PHOTO)} />
                </Card>
              ) : null
            )}
          </div>
        ) : null}

        {this.state.photoDialogPhoto !== null ? (
          <Dialog
            fullScreen
            open={this.state.photoDialogOpen}
            onClose={() => {
              this.setState({ photoDialogOpen: false });
            }}
            TransitionComponent={Transition}
          >
            <AppBar className={styles.appBar}>
              <Toolbar>
                <Typography variant="h6" style={styles.typography}></Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({ photoDialogOpen: false });
                  }}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Card style={styles.photoconc_card_dialog} elevation={0}>
              <CardMedia style={styles.photoconc_cardmedia_dialog} image={window.URL.createObjectURL(this.state.photoDialogPhoto)} />
            </Card>
          </Dialog>
        ) : null}

        <DialogAccessRight state={this.state.droit_util_ajout_modif_photoconc} onChange={(value) => this.setState({ droit_util_ajout_modif_photoconc: value })} />
      </Paper>
    );
  }
}

export default withCookies(CFCEPhotographie);
