import db from "../Db";
import { GetAllAgendaTache } from "./AgendaTache";

var _ = require("lodash");

export function GetAllLieu() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.LIEU.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetAllLieuInTask() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetAllAgendaTache().then((tache) => {
      let LICLEUNIK = [];

      _.forEach(tache, (t) => {
        if (t.LICLEUNIK > 0) {
          LICLEUNIK.push(t.LICLEUNIK);
        }
      });

      db.LIEU.where("LICLEUNIK")
        .anyOf(LICLEUNIK)
        .toArray()
        .then((result) => {
          return resolve(result);
        });
    });
  });
}

export function GetLieuById(LICLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.LIEU.where("LICLEUNIK")
      .equals(parseInt(LICLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetLieuByCimel(CIMEL, WITH_VIRTUAL) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    if (WITH_VIRTUAL) {
      db.LIEU.where("CIMEL")
        .equals(CIMEL)
        .toArray()
        .then((result) => {
          return resolve(result);
        });
    } else {
      db.LIEU.where("CIMEL")
        .equals(CIMEL)
        .and((item) => item.LIEU_MULTIPLE_PRINC_VIRTUELLE === false)
        .toArray()
        .then((result) => {
          return resolve(result);
        });
    }
  });
}

export function GetLieuByCocleunik2(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.LIEU.where("COCLEUNIK")
      .equals(parseInt(COCLEUNIK))
      .and((item) => item.LIEU_MULTIPLE_PRINC_VIRTUELLE === false)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetLieuByCocleunik(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.LIEU.where("COCLEUNIK")
      .equals(parseInt(COCLEUNIK))
      .and((item) => item.LIEU_MULTIPLE_PRINC_VIRTUELLE === false)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetAllLieuByCocleunik(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.LIEU.where("COCLEUNIK")
      .equals(parseInt(COCLEUNIK))
      .and((item) => item.LIEU_MULTIPLE_PRINC_VIRTUELLE === false)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetLieuByZocleunik(ZOCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.LIEU.where("SITUATION")
      .equals(parseInt(ZOCLEUNIK))
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
