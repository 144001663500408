import db from "../Db";

export function GetAllPhotoconc() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetPhotoconcByCocleunik(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.where("COCLEUNIK")
      .equals(parseInt(COCLEUNIK))
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetPhotoconcByI0cleunik(I0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.where("I0CLEUNIK")
      .anyOf(I0CLEUNIK === null ? "" : I0CLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetAllPhotoconcByI0cleunik(I0CLEUNIKS) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.where("I0CLEUNIK")
      .anyOf(I0CLEUNIKS)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetPhotoconcByRowId(id) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.get(parseInt(id)).then((result) => {
      return resolve(result);
    });
  });
}

export function GetPhotoconcById(IDPHOTOCONC) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.where("IDPHOTOCONC")
      .equals(parseInt(IDPHOTOCONC))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetPhotoconcByIdTablette(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.get(parseInt(ID)).then((result) => {
      return resolve(result);
    });
  });
}

export function GetPhotoconcNewId() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PHOTOCONC.toCollection()
      .last()
      .then((result) => {
        if (result === undefined) {
          return resolve(1);
        } else {
          return resolve(result.id + 1);
        }
      });
  });
}
