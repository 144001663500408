import React, { Component } from "react";
import { Paper, AppBar, Toolbar, IconButton, Typography, Dialog, DialogTitle, DialogContent, GridList, GridListTile } from "@material-ui/core";
import { ArrowBackIos, Today, ArrowForwardIos } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { startOfWeek, endOfWeek, eachDayOfInterval, format, startOfYear, eachMonthOfInterval, endOfYear } from "date-fns";
import frLocale from "date-fns/locale/fr";

import { GetAllAgendaTache } from "./../../../Helpers/Get/AgendaTache";

import CPCalendrierBulle from "./CPCalendrierBulle";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: 16,
  },
};

class CPCalendrier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentWeek: startOfWeek(Date.now(), { weekStartsOn: 1 }),
      week: [],
      displayMonthYearCard: false,
      agendaTache: [],
      tacheAffiche: [],
      displayYears: false,
      datepicker_open: false,
      expanded: null,
    };
  }

  componentDidMount = () => {
    GetAllAgendaTache().then((agendaTache) => {
      this.setState(
        {
          agendaTache: agendaTache,
          week: eachDayOfInterval({
            start: this.state.currentWeek,
            end: endOfWeek(this.state.currentWeek, { weekStartsOn: 1 }),
          }),
        },
        () => {
          let week_date = [];

          _.forEach(this.state.week, function (value) {
            week_date.push(value.toLocaleDateString("fr-FR"));
          });

          let week = eachDayOfInterval({
            start: startOfWeek(this.state.currentWeek, { weekStartsOn: 1 }),
            end: endOfWeek(startOfWeek(this.state.currentWeek, { weekStartsOn: 1 }), {
              weekStartsOn: 1,
            }),
          });

          let weeknum = [];
          _.forEach(week, (w) => {
            weeknum.push(w.valueOf());
          });

          let tache = _.filter(this.state.agendaTache, (tache) => {
            let dateTache = new Date(tache.TACHE.DATETACHE);
            dateTache.setHours(0);
            dateTache.setMinutes(0);
            let dateTacheFin = new Date(tache.TACHE.DATETACHE_FIN);
            dateTacheFin.setHours(0);
            dateTacheFin.setMinutes(0);

            if (dateTache.valueOf() === dateTacheFin.valueOf()) {
              return weeknum.includes(dateTache.valueOf());
            } else {
              if (dateTache.valueOf() > dateTacheFin.valueOf()) {
                weeknum.includes(dateTache.valueOf());
              } else {
                let tacheInterval = eachDayOfInterval({
                  start: new Date(tache.TACHE.DATETACHE),
                  end: new Date(tache.TACHE.DATETACHE_FIN),
                });

                let tacheIntervalnum = [];
                _.forEach(tacheInterval, (ti) => {
                  tacheIntervalnum.push(ti.valueOf());
                });

                const prepareBooleans = (master, keys) => {
                  const booleans = master.map((el) => {
                    return keys.includes(el);
                  });
                  return booleans;
                };

                return prepareBooleans(tacheIntervalnum, weeknum).includes(true);
              }
            }
          });

          tache = _.orderBy(tache, ["TACHE.DATETACHE"], ["asc"]);

          this.setState({ tacheAffiche: tache });
        }
      );
    });
  };

  componentDidUpdate = (prevProps) => {
    let week_date = [];

    _.forEach(this.state.week, function (value) {
      week_date.push(value.toLocaleDateString("fr-FR"));
    });

    let week = eachDayOfInterval({
      start: startOfWeek(this.state.currentWeek, { weekStartsOn: 1 }),
      end: endOfWeek(startOfWeek(this.state.currentWeek, { weekStartsOn: 1 }), {
        weekStartsOn: 1,
      }),
    });

    let weeknum = [];
    _.forEach(week, (w) => {
      weeknum.push(w.valueOf());
    });

    let tache = _.filter(this.state.agendaTache, (tache) => {
      let dateTache = new Date(tache.TACHE.DATETACHE);
      dateTache.setHours(0);
      dateTache.setMinutes(0);
      let dateTacheFin = new Date(tache.TACHE.DATETACHE_FIN);
      dateTacheFin.setHours(0);
      dateTacheFin.setMinutes(0);

      if (dateTache.valueOf() === dateTacheFin.valueOf()) {
        return weeknum.includes(dateTache.valueOf());
      } else {
        if (dateTache.valueOf() > dateTacheFin.valueOf()) {
          weeknum.includes(dateTache.valueOf());
        } else {
          let tacheInterval = eachDayOfInterval({
            start: new Date(tache.TACHE.DATETACHE),
            end: new Date(tache.TACHE.DATETACHE_FIN),
          });

          let tacheIntervalnum = [];
          _.forEach(tacheInterval, (ti) => {
            tacheIntervalnum.push(ti.valueOf());
          });

          const prepareBooleans = (master, keys) => {
            const booleans = master.map((el) => {
              return keys.includes(el);
            });
            return booleans;
          };

          return prepareBooleans(tacheIntervalnum, weeknum).includes(true);
        }
      }
    });

    let tacheAffiche = _.orderBy(tache, ["TACHE.DATETACHE"], ["asc"]);

    if (prevProps.cimetier !== this.props.cimetier || prevProps.gens !== this.props.gens) {
      if (this.props.cimetier !== 0 && this.props.gens !== 0) {
        tacheAffiche = _.filter(tacheAffiche, (agendaTache) => {
          return agendaTache.TYPECIME === this.props.cimetier && agendaTache.TACHE.IDGENS.includes(this.props.gens.toString());
        });
      } else if (this.props.cimetier !== 0 && this.props.gens === 0) {
        tacheAffiche = _.filter(tacheAffiche, ["TYPECIME", this.props.cimetier]);
      } else if (this.props.cimetier === 0 && this.props.gens !== 0) {
        tacheAffiche = _.filter(tacheAffiche, (agendaTache) => {
          return agendaTache.TACHE.IDGENS.includes(this.props.gens.toString());
        });
      }

      this.setState({ tacheAffiche: tacheAffiche });
    }
  };

  _handle_click_changeWeek = (numberOfDays) => (event) => {
    event.preventDefault();

    let weekDate = new Date(this.state.currentWeek.setDate(this.state.currentWeek.getDate() + numberOfDays));

    if (
      endOfWeek(weekDate, { weekStartsOn: 1 }) > new Date(new Date(Date.now()).getFullYear() - 1, 0, 1) &&
      startOfWeek(weekDate, { weekStartsOn: 1 }) < new Date(new Date(Date.now()).getFullYear() + 1, 0, 1)
    ) {
      let currentWeek = startOfWeek(weekDate, {
        weekStartsOn: 1,
      });

      let week = eachDayOfInterval({
        start: startOfWeek(weekDate, { weekStartsOn: 1 }),
        end: endOfWeek(startOfWeek(weekDate, { weekStartsOn: 1 }), {
          weekStartsOn: 1,
        }),
      });

      let weeknum = [];
      _.forEach(week, (w) => {
        weeknum.push(w.valueOf());
      });

      let tache = _.filter(this.state.agendaTache, (tache) => {
        let dateTache = new Date(tache.TACHE.DATETACHE);
        dateTache.setHours(0);
        dateTache.setMinutes(0);
        let dateTacheFin = new Date(tache.TACHE.DATETACHE_FIN);
        dateTacheFin.setHours(0);
        dateTacheFin.setMinutes(0);

        if (dateTache.valueOf() === dateTacheFin.valueOf()) {
          return weeknum.includes(dateTache.valueOf());
        } else {
          if (dateTache.valueOf() > dateTacheFin.valueOf()) {
            weeknum.includes(dateTache.valueOf());
          } else {
            let tacheInterval = eachDayOfInterval({
              start: new Date(tache.TACHE.DATETACHE),
              end: new Date(tache.TACHE.DATETACHE_FIN),
            });

            let tacheIntervalnum = [];
            _.forEach(tacheInterval, (ti) => {
              tacheIntervalnum.push(ti.valueOf());
            });

            const prepareBooleans = (master, keys) => {
              const booleans = master.map((el) => {
                return keys.includes(el);
              });
              return booleans;
            };

            return prepareBooleans(tacheIntervalnum, weeknum).includes(true);
          }
        }
      });

      tache = _.orderBy(tache, ["TACHE.DATETACHE"], ["asc"]);

      if (this.props.cimetier !== 0 && this.props.gens !== 0) {
        tache = _.filter(tache, (agendaTache) => {
          return agendaTache.TYPECIME === this.props.cimetier && agendaTache.TACHE.IDGENS.includes(this.props.gens.toString());
        });
      } else if (this.props.cimetier !== 0 && this.props.gens === 0) {
        tache = _.filter(tache, ["TYPECIME", this.props.cimetier]);
      } else if (this.props.cimetier === 0 && this.props.gens !== 0) {
        tache = _.filter(tache, (agendaTache) => {
          return agendaTache.TACHE.IDGENS.includes(this.props.gens.toString());
        });
      }

      this.setState({
        currentWeek: currentWeek,
        week: week,
        tacheAffiche: tache,
      });
    } else {
      weekDate = new Date(this.state.currentWeek.setDate(this.state.currentWeek.getDate() - numberOfDays));

      let currentWeek = startOfWeek(weekDate, {
        weekStartsOn: 1,
      });

      let week = eachDayOfInterval({
        start: startOfWeek(weekDate, { weekStartsOn: 1 }),
        end: endOfWeek(startOfWeek(weekDate, { weekStartsOn: 1 }), {
          weekStartsOn: 1,
        }),
      });

      let week_date = [];
      _.forEach(week, function (value) {
        week_date.push(value.toLocaleDateString("fr-FR"));
      });

      let tache = _.filter(this.state.agendaTache, (tache) => {
        let dateTache = new Date(tache.TACHE.DATETACHE).toLocaleDateString("fr-FR");

        return week_date.includes(dateTache);
      });

      tache = _.orderBy(tache, ["TACHE.DATETACHE"], ["asc"]);

      this.setState({
        currentWeek: currentWeek,
        week: week,
        tacheAffiche: tache,
      });
    }
  };

  _handle_click_displayMonthYearCard = (event) => {
    event.preventDefault();

    this.setState({
      displayMonthYearCard: true,
    });
  };

  _handle_change_date = (event) => {
    this.setState(
      {
        currentWeek: startOfWeek(event, {
          weekStartsOn: 1,
        }),

        week: eachDayOfInterval({
          start: startOfWeek(event, { weekStartsOn: 1 }),
          end: endOfWeek(startOfWeek(event, { weekStartsOn: 1 }), {
            weekStartsOn: 1,
          }),
        }),
      },
      () => {
        let week_date = [];
        _.forEach(this.state.week, function (value) {
          week_date.push(value.toLocaleDateString("fr-FR"));
        });

        let weeknum = [];
        _.forEach(this.state.week, (w) => {
          weeknum.push(w.valueOf());
        });

        let tache = _.filter(this.state.agendaTache, (tache) => {
          let dateTache = new Date(tache.TACHE.DATETACHE);
          dateTache.setHours(0);
          dateTache.setMinutes(0);
          let dateTacheFin = new Date(tache.TACHE.DATETACHE_FIN);
          dateTacheFin.setHours(0);
          dateTacheFin.setMinutes(0);

          if (dateTache.valueOf() === dateTacheFin.valueOf()) {
            return weeknum.includes(dateTache.valueOf());
          } else {
            if (dateTache.valueOf() > dateTacheFin.valueOf()) {
              weeknum.includes(dateTache.valueOf());
            } else {
              let tacheInterval = eachDayOfInterval({
                start: new Date(tache.TACHE.DATETACHE),
                end: new Date(tache.TACHE.DATETACHE_FIN),
              });

              let tacheIntervalnum = [];
              _.forEach(tacheInterval, (ti) => {
                tacheIntervalnum.push(ti.valueOf());
              });

              const prepareBooleans = (master, keys) => {
                const booleans = master.map((el) => {
                  return keys.includes(el);
                });
                return booleans;
              };

              return prepareBooleans(tacheIntervalnum, weeknum).includes(true);
            }
          }
        });

        tache = _.orderBy(tache, ["TACHE.DATETACHE"], ["asc"]);

        if (this.props.cimetier !== 0 && this.props.gens !== 0) {
          tache = _.filter(tache, (agendaTache) => {
            return agendaTache.TYPECIME === this.props.cimetier && agendaTache.TACHE.IDGENS.includes(this.props.gens.toString());
          });
        } else if (this.props.cimetier !== 0 && this.props.gens === 0) {
          tache = _.filter(tache, ["TYPECIME", this.props.cimetier]);
        } else if (this.props.cimetier === 0 && this.props.gens !== 0) {
          tache = _.filter(tache, (agendaTache) => {
            return agendaTache.TACHE.IDGENS.includes(this.props.gens.toString());
          });
        }

        this.setState({ tacheAffiche: tache, expanded: event });
      }
    );
  };

  _get_month_year = () => {
    if (this.state.displayYears) {
      let previousYear = startOfYear(new Date(new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 1)));

      let nextYear = startOfYear(new Date(new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() + 1)));

      return [previousYear, startOfYear(new Date(Date.now())), nextYear];
    } else {
      let months = eachMonthOfInterval({
        start: startOfYear(this.state.currentWeek),
        end: endOfYear(this.state.currentWeek),
      });

      return months;
    }
  };

  _handle_click_changeMonthYear = (date) => (event) => {
    event.preventDefault();

    if (this.state.displayYears) {
      date = new Date(date.setDate(date.getDate() + 7));

      this.setState({
        currentWeek: startOfWeek(date, { weekStartsOn: 1 }),
        week: eachDayOfInterval({
          start: startOfWeek(date, { weekStartsOn: 1 }),
          end: endOfWeek(startOfWeek(date, { weekStartsOn: 1 }), {
            weekStartsOn: 1,
          }),
        }),
        displayYears: false,
      });
    } else {
      this.setState(
        {
          currentWeek: startOfWeek(date, { weekStartsOn: 1 }),
          week: eachDayOfInterval({
            start: startOfWeek(date, { weekStartsOn: 1 }),
            end: endOfWeek(startOfWeek(date, { weekStartsOn: 1 }), {
              weekStartsOn: 1,
            }),
          }),
          displayMonthYearCard: false,
        },
        () => {
          let week_date = [];
          _.forEach(this.state.week, function (value) {
            week_date.push(value.toLocaleDateString("fr-FR"));
          });

          let weeknum = [];
          _.forEach(this.state.week, (w) => {
            weeknum.push(w.valueOf());
          });

          let tache = _.filter(this.state.agendaTache, (tache) => {
            let dateTache = new Date(tache.TACHE.DATETACHE);
            dateTache.setHours(0);
            dateTache.setMinutes(0);
            let dateTacheFin = new Date(tache.TACHE.DATETACHE_FIN);
            dateTacheFin.setHours(0);
            dateTacheFin.setMinutes(0);

            if (dateTache.valueOf() === dateTacheFin.valueOf()) {
              return weeknum.includes(dateTache.valueOf());
            } else {
              if (dateTache.valueOf() > dateTacheFin.valueOf()) {
                weeknum.includes(dateTache.valueOf());
              } else {
                let tacheInterval = eachDayOfInterval({
                  start: new Date(tache.TACHE.DATETACHE),
                  end: new Date(tache.TACHE.DATETACHE_FIN),
                });

                let tacheIntervalnum = [];
                _.forEach(tacheInterval, (ti) => {
                  tacheIntervalnum.push(ti.valueOf());
                });

                const prepareBooleans = (master, keys) => {
                  const booleans = master.map((el) => {
                    return keys.includes(el);
                  });
                  return booleans;
                };

                return prepareBooleans(tacheIntervalnum, weeknum).includes(true);
              }
            }
          });

          tache = _.orderBy(tache, ["TACHE.DATETACHE"], ["asc"]);

          this.setState({ tacheAffiche: tache });
        }
      );
    }
  };

  render() {
    return (
      <Paper elevation={0} style={styles.paper} className="paperplanning">
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" aria-label="previousWeek" onClick={this._handle_click_changeWeek(-7)}>
              <ArrowBackIos />
            </IconButton>

            <IconButton style={{ color: "white" }} aria-label="today" onClick={() => this.setState({ datepicker_open: true })}>
              <Today />
            </IconButton>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <DatePicker
                style={{ display: "none" }}
                open={this.state.datepicker_open}
                onOpen={() => this.setState({ datepicker_open: true })}
                onClose={() => this.setState({ datepicker_open: false })}
                value={Date.now()}
                onChange={this._handle_change_date}
                cancelLabel="annuler"
              />
            </MuiPickersUtilsProvider>

            <Typography
              variant="h6"
              style={{
                flex: 1,
                marginRight: 48,
                textAlign: "center",
                userSelect: "none",
                cursor: "pointer",
                fontSize: "1rem",
              }}
              onClick={this._handle_click_displayMonthYearCard}
            >
              {`DU ${format(this.state.currentWeek, "dd MMMM yyyy", {
                locale: frLocale,
              }).toUpperCase()}`}
              <br />
              {`AU ${format(endOfWeek(this.state.currentWeek, { weekStartsOn: 1 }), " dd MMMM yyyy", {
                locale: frLocale,
              }).toUpperCase()}`}
            </Typography>

            <IconButton edge="end" aria-label="nextWeek" onClick={this._handle_click_changeWeek(7)}>
              <ArrowForwardIos />
            </IconButton>
          </Toolbar>
        </AppBar>

        {this.state.week.length > 0 ? <CPCalendrierBulle tache={this.state.tacheAffiche} week={this.state.week} expanded={this.state.expanded} /> : null}

        {this.state.displayMonthYearCard ? (
          <Dialog onClose={() => this.setState({ displayMonthYearCard: false })} open={this.state.displayMonthYearCard} aria-label="month-year-selection">
            <DialogTitle
              id="month-year-selection"
              style={{
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: "#FF732F",
              }}
              onClick={() => this.setState({ displayYears: true })}
            >
              {format(this.state.currentWeek, "yyyy", { locale: frLocale })}
            </DialogTitle>

            <DialogContent>
              <GridList cols={3} cellHeight={50} style={{ justifyContent: "center" }}>
                {this._get_month_year().map((tile, i) => (
                  <GridListTile key={i} onClick={this._handle_click_changeMonthYear(tile)} style={{ width: 153 }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        lineHeight: "50px",
                        cursor: "pointer",
                      }}
                    >
                      {this.state.displayYears
                        ? format(tile, "yyyy", {
                            locale: frLocale,
                          }).toUpperCase()
                        : format(tile, "MMMM", {
                            locale: frLocale,
                          }).toUpperCase()}
                    </Typography>
                  </GridListTile>
                ))}
              </GridList>
            </DialogContent>
          </Dialog>
        ) : null}
      </Paper>
    );
  }
}

export default CPCalendrier;
