import db from "../Db";

var _ = require("lodash");

export function GetAllTypetb() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TYPETB.toArray().then((result) => {
      if (_.find(result, ["NOMTOMBE", "Indéterminé"]) === undefined) {
        var id = _.findLast(result).id;
        var NUMTB = _.findLast(result).NUMTB;

        result = [
          ...result,
          { NUMTB: ++NUMTB, NOMTOMBE: "Indéterminé", id: ++id },
        ];
      }

      return resolve(result);
    });
  });
}

export function GetTypetbById(NUMTB) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.TYPETB.where("NUMTB")
      .equals(parseInt(NUMTB))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
