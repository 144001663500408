import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetIntervenById } from "../Get/Interven";

var _ = require("lodash");

export async function UpdateInterven_MDF(headers) {
  const _intervenCount = await GetApiData("INTERVENCount", headers);

  if (_intervenCount.Message !== undefined) {
    return _intervenCount;
  }

  const promise = new Promise((res, rej) => {
    if (_intervenCount === 0) res("");

    addTableWithProgress("INTERVEN", _intervenCount, 2500, headers, (getProgress, getInterven) => {
      if (getInterven.Message !== undefined) {
        return res(getInterven);
      }

      _.forEach(getInterven, (interven) => {
        const interven_a_mettre_a_jour = {
          I0CLEUNIK: interven.I0CLEUNIK,
          DEMANDEUR: {
            NOM: interven.NOM,
            PRENOM: interven.PRENOM,
          },
          INTERVENANT_NOM: interven.INTERVENANT_NOM,
          DATETRAVAUX: interven.DATETRAVAUX,
          DATETRAVAUX_FIN: interven.DATETRAVAUX_FIN,
          INTERVENANT_TYPE: interven.INTERVENANT_TYPE,
          SUIVITRAV: interven.SUIVITRAV,
          IDMOUVEMENT: interven.IDMOUVEMENT,
          SOCLEUNIK: interven.SOCLEUNIK,
          LICLEUNIK: interven.LICLEUNIK,
          COCLEUNIK: interven.COCLEUNIK,
          TYPECIME: interven.TYPECIME,
          EMPL_NATURE: interven.EMPL_NATURE,
          EMPL_TYPE: interven.EMPL_TYPE,
          EMPL_DEF_OU_CONCESSION: interven.EMPL_DEF_OU_CONCESSION,
          TARVAUX: interven.TARVAUX,
        };

        GetIntervenById(interven_a_mettre_a_jour.I0CLEUNIK).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.INTERVEN.add(interven_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(interven_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.INTERVEN.put(interven_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _intervenCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_intervenCount} interventions`);

    return val;
  });
}
