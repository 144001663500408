import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import {
  AppBar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Paper,
  Typography,
  Button,
  Card,
  CardActions,
  IconButton,
  CardMedia,
  Slide,
  Toolbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { withCookies } from "react-cookie";

import { GetConcessById } from "../../Helpers/Get/Concess";
import { GetCimetierById } from "./../../Helpers/Get/Cimetier";
import { GetLieuById } from "../../Helpers/Get/Lieu";
import { GetCendreByIdLieu } from "./../../Helpers/Get/Cendre";
import { GetPhotoconcByCocleunik, GetPhotoconcById, GetPhotoconcNewId } from "./../../Helpers/Get/Photoconc";
import { StarRate, Close } from "@material-ui/icons";
import { GetClient } from "./../../Helpers/Get/Client";
import { GetTemporaire, GetTemporaireForColor, GetTemporaireNonEnvoye } from "../../Helpers/Get/Temporaire";

import { DeletePhotoconcByIdPhotoconc } from "./../../Helpers/Delete/Photoconc";
import AddTemporaire from "./../../Helpers/Add/Temporaire";
import AddPhotoconc from "./../../Helpers/Add/Photoconc";
import UpdatePhotoconc from "./../../Helpers/Update/Photoconc";
import UpdateTemporaire from "./../../Helpers/Update/Temporaire";

import { handleError, CreeTemporaire } from "../../Helpers/Function";
import { IsOracle } from "./../../Helpers/Test/IsOracle";
import DeleteTemporaire from "../../Helpers/Delete/Temporaire";
import { CheckUserRight } from "./../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "./../Global/DialogAccessRight";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 16,
  },
  photoconc_container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  photoconc_card: {
    border: "1px solid grey",
    cursor: "pointer",
    flex: 1,
    margin: 10,
    maxWidth: 160,
    minWidth: 160,
    textAlign: "center",
    width: 100,
  },
  photoconc_card_dialog: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    margin: "120px 50px 50px 50px",
  },
  photoconc_cardaction: {
    justifyContent: "space-between",
    padding: 3,
  },
  photoconc_cardmedia: {
    backgroundColor: "whitesmoke",
    backgroundSize: "contain",
    borderTop: "1px solid lightgrey",
    height: 0,
    paddingTop: "100%",
  },
  photoconc_cardmedia_dialog: {
    backgroundSize: "contain",
    height: 0,
    paddingTop: "60%",
  },
  submit_div: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 8,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  appBar: {
    position: "relative",
  },
  typography: {
    flex: 1,
  },
  loaderstyle: {
    color: "#fff",
    zIndex: 2,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MPhotographies extends Component {
  constructor(props) {
    super(props);

    this.IDCLIENT = 0;
    this.ID_TABLETTE = "";
    this.ID_UTIL = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

    this.inputFileRef = React.createRef();

    this.state = {
      IS_ORACLE: false,
      concess: undefined,
      cimetier: undefined,
      lieu: undefined,
      cendre: undefined,
      photoconc: [],
      photoconc_sauvegarde: [],
      photoconc_ajoute: [],
      changement_referente: false,
      photoconc_supprime: [],
      supprime: false,
      ajoute: false,
      misajour: false,
      champ_modifie: [],
      disabledForm: false,
      dialog_traitement: false,
      photoDialogOpen: false,
      photoDialogPhoto: null,
      dialogSupprimePhotoOpen: false,
      dialogSupprimePhotoIndex: null,
      phototemp: [],
      temporaire: [],
      droit_util_ajout_photoconc: false,
      droit_util_modif_photoconc: false,
      redirect: "",
      last_photoconc: "",
      loaderopen: true,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    if (this.props.id_concession !== undefined) {
      GetClient().then((client) => {
        this.IDCLIENT = client.IDCLIENT;
        this.ID_TABLETTE = client.ID_TABLETTE;
      });

      GetConcessById(this.props.id_concession).then((concess) => {
        GetCimetierById(concess.TYPECIME).then((cimetier) => {
          GetLieuById(concess.LICLEUNIK).then((lieu) => {
            GetCendreByIdLieu(lieu.LICLEUNIK).then((cendre) => {
              GetPhotoconcByCocleunik(concess.COCLEUNIK).then((photoconc) => {
                if (photoconc.length === 0) {
                  this.setState({
                    concess: concess,
                    cimetier: cimetier,
                    lieu: lieu,
                    cendre: cendre,
                    photoconc: photoconc,
                    photoconc_sauvegarde: _.cloneDeep(photoconc),
                    loaderopen: false,
                  });
                }

                _.forEach(photoconc, (photo, index) => {
                  GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", photo.IDPHOTOCONC).then((temporaire) => {
                    if (temporaire !== undefined && temporaire.DATE_DE_CREATION !== null) {
                      this.setState((prevState) => ({
                        phototemp: [...prevState.phototemp, temporaire.TABLE_MODIFIE_JSON.IDPHOTOCONC],
                        temporaire: [...prevState.temporaire, temporaire],
                      }));
                    }
                  });

                  GetTemporaireNonEnvoye("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", photo.IDPHOTOCONC).then((temporaire) => {
                    if (temporaire !== undefined) {
                      photo.PRINCIPAL = this.state.IS_ORACLE ? temporaire.TABLE_MODIFIE_JSON.REFERENTE : temporaire.TABLE_MODIFIE_JSON.PRINCIPAL;

                      if (index === photoconc.length - 1) {
                        this.setState({
                          concess: concess,
                          cimetier: cimetier,
                          lieu: lieu,
                          cendre: cendre,
                          photoconc: photoconc,
                          photoconc_sauvegarde: _.cloneDeep(photoconc),
                          loaderopen: false,
                        });
                      }
                    } else {
                      this.setState({
                        concess: concess,
                        cimetier: cimetier,
                        lieu: lieu,
                        cendre: cendre,
                        photoconc: photoconc,
                        photoconc_sauvegarde: _.cloneDeep(photoconc),
                        loaderopen: false,
                      });
                    }
                  });
                });
              });
            });
          });
        });
      });

      let field = [];
      GetPhotoconcByCocleunik(this.props.id_concession).then((photoconc) => {
        this.setState({ last_photoconc: _.last(photoconc) || "" });

        photoconc = _.filter(photoconc, (photo) => {
          return photo.PHOTO !== "";
        });

        if (photoconc.length > 0) {
          let ordered = _.orderBy(photoconc, ["PRINCIPAL"], ["desc"]);

          if (ordered[0].PRINCIPAL === 0) {
            ordered = _.orderBy(photoconc, ["DATEAJOUT"], ["desc"]);
          }

          this.setState({ PHOTOCONC: ordered });

          _.forEach(ordered, (p) => {
            const temporaire = GetTemporaireForColor("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", p.IDPHOTOCONC);

            temporaire.then((temp) => {
              if (temp.length > 0) {
                _.forEach(temp, (t) => {
                  let color = "red";

                  if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                    color = "blue";
                  }

                  if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                    color = "green";
                  }

                  if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                    color = "primary";
                  }

                  var element_temp = t.TABLE_MODIFIE_JSON;
                  element_temp = _.omit(element_temp, ["id"]);

                  let sub_field = {};
                  for (var key in element_temp) {
                    sub_field[key] = color;

                    if (key === (this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC")) sub_field[key] = this.state.IS_ORACLE ? element_temp.IDEVOL_ETAT : element_temp.IDPHOTOCONC;
                  }

                  field.push(sub_field);
                });

                this.setState({ champ_modifie: field });
              }
            });
          });
        }
      });
    }
  };

  _cree_chaine_emplacement = () => {
    var empl = "";

    if (this.state.cendre !== undefined) {
      empl = this.state.cendre.NOMCENDRE;
    } else {
      if (this.state.concess !== undefined) {
        empl = this.state.concess.EMPLACEMENT_LIEU;

        const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

        if (reg.test(empl)) {
          const match = empl.match(reg);

          empl = `${match[1]} ${match[3]} - ${match[2]} ${match[4]}${match[5]}`;
        }
      } else {
        empl = _.filter([this.state.lieu.EMPLACEMENT.CARRE, this.state.lieu.EMPLACEMENT.RANG, this.state.lieu.EMPLACEMENT.TOMBE]).join("-");

        const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

        if (reg.test(empl)) {
          const SECTION = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[0];
          const CARRE = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[1];

          empl = _.filter([SECTION, CARRE, this.state.lieu.EMPLACEMENT.RANG, this.state.lieu.EMPLACEMENT.TOMBE]).join("-");
        }
      }
    }

    return empl;
  };

  _change_refennte = (p1, p2, index) => {
    for (var current = 0; current < _.concat(p1, p2).length; current++) {
      if (current === index) {
        _.concat(p1, p2)[current].PRINCIPAL = _.concat(p1, p2)[current].PRINCIPAL === 1 ? 0 : 1;
      } else {
        _.concat(p1, p2)[current].PRINCIPAL = 0;
      }
    }

    this.setState({
      photoconc: p1,
      photoconc_ajoute: p2,
      changement_referente: true,
    });
  };

  _handle_click_photographies_referente = (index) => (event) => {
    event.preventDefault();

    CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
      if (asRight) {
        let PHOTOCONC = this.state.photoconc;
        let PHOTOCONC_AJOUTE = this.state.photoconc_ajoute;

        GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", _.concat(PHOTOCONC, PHOTOCONC_AJOUTE)[index].IDPHOTOCONC).then((temporaire) => {
          if (temporaire !== undefined) {
            if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
              this.setState({
                photoconc: PHOTOCONC,
                photoconc_ajoute: PHOTOCONC_AJOUTE,
                dialog_traitement: true,
              });
            } else {
              this._change_refennte(PHOTOCONC, PHOTOCONC_AJOUTE, index);
            }
          } else {
            this._change_refennte(PHOTOCONC, PHOTOCONC_AJOUTE, index);
          }
        });
      } else {
        this.setState({ droit_util_modif_photoconc: true });
      }
    });
  };

  _delete_photoconc = (photoconc, index) => {
    let PHOTOCONC_AJOUTE = [...this.state.photoconc_ajoute];

    if (index < photoconc.length) {
      let NEW_PHOTOCONC = [...photoconc];
      NEW_PHOTOCONC.splice(index, 1);

      GetTemporaireNonEnvoye("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", photoconc[index].IDPHOTOCONC).then((temp) => {
        if (temp !== undefined) {
          DeleteTemporaire(temp.id).then(() => {
            DeletePhotoconcByIdPhotoconc(photoconc[index].IDPHOTOCONC).then(() => {
              this.setState({ photoconc: NEW_PHOTOCONC, dialogSupprimePhotoOpen: false, dialogSupprimePhotoIndex: null });
            });
          });
        } else {
          GetPhotoconcById(photoconc[index].IDPHOTOCONC).then((result) => {
            if (result !== "") {
              this.setState((prevState) => ({
                photoconc_supprime: [...prevState.photoconc_supprime, photoconc[index]],
                photoconc: NEW_PHOTOCONC,
                dialogSupprimePhotoOpen: false,
                dialogSupprimePhotoIndex: null,
              }));
            } else {
              this.setState({ photoconc: NEW_PHOTOCONC, dialogSupprimePhotoOpen: false, dialogSupprimePhotoIndex: null });
            }
          });
        }
      });
    } else {
      PHOTOCONC_AJOUTE.splice(index - photoconc.length, 1);
      this.setState({ photoconc_ajoute: PHOTOCONC_AJOUTE, dialogSupprimePhotoOpen: false, dialogSupprimePhotoIndex: null });
    }
  };

  _handle_click_photographies_a_supprimer = (index) => (event) => {
    event.preventDefault();

    this.setState({ dialogSupprimePhotoOpen: true, dialogSupprimePhotoIndex: index });
  };

  _handle_click_photographies_supprimer = (index) => (event) => {
    event.preventDefault();

    let PHOTOCONC = this.state.photoconc;

    if (PHOTOCONC[index] !== undefined && PHOTOCONC[index].IDPHOTOCONC !== undefined) {
      GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", PHOTOCONC[index].IDPHOTOCONC).then((temporaire) => {
        if (temporaire !== undefined) {
          if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
            this.setState({
              photoconc: PHOTOCONC,
              dialog_traitement: true,
            });
          } else {
            this._delete_photoconc(PHOTOCONC, index);
          }
        } else {
          this._delete_photoconc(PHOTOCONC, index);
        }
      });
    } else {
      this._delete_photoconc(PHOTOCONC, index);
    }
  };

  _handle_change_input_photographie = (event) => {
    if (event.target.files) {
      let photos = event.target.files;

      GetPhotoconcNewId().then((newid) => {
        if (this.state.photoconc_ajoute.length > 0) newid = this.state.photoconc_ajoute[this.state.photoconc_ajoute.length - 1].id + 1;

        Array.from(photos).forEach((photoconc) => {
          Resizer.imageFileResizer(
            photoconc,
            2048,
            2048,
            "JPEG",
            50,
            0,
            (blob) => {
              let PHOTOCONC = {
                id: newid++,
                PHOTO: blob,
                DATEAJOUT: new Date(Date.now()).toISOString().slice(0, -5),
                PRINCIPAL: 0,
                COCLEUNIK: this.state.concess.COCLEUNIK,
              };

              this.setState(
                (prevState) => ({
                  photoconc_ajoute: [...prevState.photoconc_ajoute, PHOTOCONC],
                }),
                () => {
                  this.inputFileRef.current.value = "";
                }
              );
            },
            "blob"
          );
        });
      });
    }
  };

  _handle_submit_photographie = (event) => {
    event.preventDefault();

    if (this.state.disabledForm) return;

    this.setState({ disabledForm: true }, () => {
      let PHOTOCONC = _.cloneDeep(this.state.photoconc);
      let PHOTOCONC_AJOUTE = _.cloneDeep(this.state.photoconc_ajoute);
      let PHOTOCONC_SUPPRIME = _.cloneDeep(this.state.photoconc_supprime);

      if (PHOTOCONC_SUPPRIME.length > 0) {
        _.forEach(PHOTOCONC_SUPPRIME, (photoconc, index) => {
          const delete_photo = DeletePhotoconcByIdPhotoconc(photoconc.IDPHOTOCONC);
          delete_photo
            .then(() => {
              photoconc.PHOTO.arrayBuffer().then((buffer) => {
                photoconc.PHOTO = `data:image/jpeg;base64,${Buffer.from(new Uint8Array(buffer)).toString("base64")}`;

                if (this.state.IS_ORACLE) {
                  for (var key in photoconc) {
                    if (key === "IDPHOTOCONC") {
                      photoconc.IDEVOL_ETAT = photoconc[key];
                      delete photoconc[key];
                    }

                    if (key === "DATEAJOUT") {
                      photoconc.DATE_EVOL = photoconc[key];
                      delete photoconc[key];
                    }

                    if (key === "PRINCIPAL") {
                      photoconc.REFERENTE = photoconc[key];
                      delete photoconc[key];
                    }

                    if (key === "NOTES") {
                      photoconc.ETAT_EVOL = photoconc[key];
                      delete photoconc[key];
                    }
                  }
                } else {
                  photoconc = _.omit(photoconc, ["I0CLEUNIK"]);
                }

                const temporaire = CreeTemporaire(
                  this.IDCLIENT,
                  this.ID_TABLETTE,
                  this.ID_UTIL,
                  `Suppression de la photographie ${this.state.IS_ORACLE ? photoconc.IDEVOL_ETAT : photoconc.IDPHOTOCONC}.jpg`,
                  "PHOTOCONC",
                  3,
                  photoconc,
                  PHOTOCONC_SUPPRIME[index]
                );

                AddTemporaire(temporaire)
                  .then(() => {
                    if (index === PHOTOCONC_SUPPRIME.length - 1) this.setState({ supprime: true });
                  })
                  .catch((Err) => handleError(Err));
              });
            })
            .catch((Err) => handleError(Err));
        });
      } else {
        this.setState({ supprime: true });
      }

      if (PHOTOCONC_AJOUTE.length > 0) {
        _.forEach(PHOTOCONC_AJOUTE, (photoconc, index) => {
          _.set(photoconc, "I0CLEUNIK", 0);
          _.set(photoconc, "NOTES", "");

          if (this.state.last_photoconc !== undefined) {
            _.set(photoconc, "MONUMENT_ETAT", this.state.last_photoconc.MONUMENT_ETAT || "");
            _.set(photoconc, "SEMELLE_ETAT", this.state.last_photoconc.SEMELLE_ETAT || "");
          }

          const ajout_photo = AddPhotoconc(photoconc);
          ajout_photo
            .then((_id) => {
              _.set(photoconc, "id", _id);
              _.set(photoconc, "IDPHOTOCONC", -_id);

              const modifie_photo = UpdatePhotoconc(photoconc);
              modifie_photo
                .then(() => {
                  photoconc.PHOTO.arrayBuffer().then((buffer) => {
                    photoconc.PHOTO = `data:image/jpeg;base64,${Buffer.from(new Uint8Array(buffer)).toString("base64")}`;

                    if (this.state.IS_ORACLE) {
                      for (var key in photoconc) {
                        if (key === "IDPHOTOCONC") {
                          photoconc.IDEVOL_ETAT = photoconc[key];
                          delete photoconc[key];
                        }

                        if (key === "DATEAJOUT") {
                          photoconc.DATE_EVOL = photoconc[key];
                          delete photoconc[key];
                        }

                        if (key === "PRINCIPAL") {
                          photoconc.REFERENTE = photoconc[key];
                          delete photoconc[key];
                        }

                        if (key === "NOTES") {
                          photoconc.ETAT_EVOL = photoconc[key];
                          delete photoconc[key];
                        }
                      }
                    } else {
                      photoconc = _.omit(photoconc, ["I0CLEUNIK"]);
                    }

                    const temporaire = CreeTemporaire(
                      this.IDCLIENT,
                      this.ID_TABLETTE,
                      this.ID_UTIL,
                      `Ajout de la photographie ${this.state.IS_ORACLE ? photoconc.IDEVOL_ETAT : photoconc.IDPHOTOCONC}.jpg`,
                      "PHOTOCONC",
                      1,
                      photoconc,
                      null
                    );

                    AddTemporaire(temporaire)
                      .then(() => {
                        if (index === PHOTOCONC_AJOUTE.length - 1) this.setState({ ajoute: true });
                      })
                      .catch((Err) => handleError(Err));
                  });
                })
                .catch((Err) => handleError(Err));
            })
            .catch((Err) => handleError(Err));
        });
      } else {
        this.setState({ ajoute: true });
      }

      if (this.state.changement_referente === true) {
        _.forEach(PHOTOCONC, (photoconc, index) => {
          const modifie_photo = UpdatePhotoconc(photoconc);
          modifie_photo
            .then(() => {
              GetTemporaireNonEnvoye("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", photoconc.IDPHOTOCONC).then((temporaire) => {
                if (photoconc.PHOTO !== undefined && photoconc.PHOTO !== "") {
                  photoconc.PHOTO.arrayBuffer().then((buffer) => {
                    photoconc.PHOTO = `data:image/jpeg;base64,${Buffer.from(new Uint8Array(buffer)).toString("base64")}`;

                    if (this.state.IS_ORACLE) {
                      for (var key in photoconc) {
                        if (key === "IDPHOTOCONC") {
                          photoconc.IDEVOL_ETAT = photoconc[key];
                          delete photoconc[key];
                        }

                        if (key === "DATEAJOUT") {
                          photoconc.DATE_EVOL = photoconc[key];
                          delete photoconc[key];
                        }

                        if (key === "PRINCIPAL") {
                          photoconc.REFERENTE = photoconc[key];
                          delete photoconc[key];
                        }

                        if (key === "NOTES") {
                          photoconc.ETAT_EVOL = photoconc[key];
                          delete photoconc[key];
                        }
                      }
                    } else {
                      photoconc = _.omit(photoconc, ["I0CLEUNIK"]);
                    }

                    if (temporaire !== undefined) {
                      var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, photoconc);

                      temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;

                      UpdateTemporaire(temporaire)
                        .then(() => {
                          if (index === PHOTOCONC_SUPPRIME.length - 1) this.setState({ misajour: true });
                        })
                        .catch((Err) => handleError(Err));
                    } else {
                      const temporaire = CreeTemporaire(
                        this.IDCLIENT,
                        this.ID_TABLETTE,
                        this.ID_UTIL,
                        `Modification de la photographie ${this.state.IS_ORACLE ? photoconc.IDEVOL_ETAT : photoconc.IDPHOTOCONC}.jpg`,
                        "PHOTOCONC",
                        2,
                        photoconc,
                        _.find(this.state.photoconc_sauvegarde, ["id", photoconc.id])
                      );

                      AddTemporaire(temporaire).catch((Err) => handleError(Err));
                    }
                  });
                }
              });
            })
            .catch((Err) => handleError(Err));
        });
      } else {
        this.setState({ misajour: true });
      }
    });
  };

  _find_color = (modifie, idphotoconc) => {
    const temp = _.find(modifie, ["IDPHOTOCONC", idphotoconc]) || _.find(modifie, ["IDEVOL_ETAT", idphotoconc]);

    let color = [];
    for (var key in temp) {
      if (typeof temp[key] === "string") {
        if (!_.includes(color, temp[key])) color.push(temp[key]);
      }
    }

    if (_.includes(color, "red")) return "red";
    if (_.includes(color, "blue")) return "blue";
    if (_.includes(color, "green")) return "green";

    return "grey";
  };

  zoomOnImage = (photo) => (event) => {
    event.preventDefault();

    this.setState({ photoDialogOpen: true, photoDialogPhoto: photo });
  };

  render() {
    if ((this.state.supprime && this.state.ajoute) || this.state.misajour) {
      if (this.props.type === "c") {
        if (this.props.echues === "e") {
          return <Redirect to={`/consulter/fiche-concession/${this.state.concess.COCLEUNIK}/e`} push />;
        } else if (this.props.echues === "pa") {
          return <Redirect to={`/consulter/fiche-concession/${this.state.concess.COCLEUNIK}/PHOTOCONC/pa`} push />;
        } else {
          return <Redirect to={`/consulter/fiche-concession/${this.state.concess.COCLEUNIK}/PHOTOCONC`} push />;
        }
      } else {
        return <Redirect to={`/consulter/cartographie/photos/${this.state.concess.TYPECIME}/${this.state.concess.LICLEUNIK}`} push />;
      }
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            {this.state.concess !== undefined ? (
              <div>
                <Typography variant="h6" style={{ textAlign: "center" }}>{`Sépulture ${this.state.concess.FAMILLE}`}</Typography>

                <Typography variant="h6" style={{ textAlign: "center" }}>{`${this.state.cimetier.NOMCIME} - ${this._cree_chaine_emplacement()}`}</Typography>

                <div style={{ marginTop: 16, display: "flex", justifyContent: "space-around" }}>
                  <input type="file" ref={this.inputFileRef} accept="image/*;capture=camera" multiple style={{ display: "none" }} onChange={this._handle_change_input_photographie} />

                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => {
                      CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
                        if (asRight) {
                          this.inputFileRef.current.click();
                        } else {
                          this.setState({ droit_util_ajout_photoconc: true });
                        }
                      });
                    }}
                  >
                    Ajouter une photographie
                  </Button>
                </div>

                <div style={{ marginTop: 16 }}>
                  {this.state.photoconc.length > 0 || this.state.photoconc_ajoute.length > 0 ? (
                    <div style={styles.photoconc_container}>
                      {_.concat(this.state.photoconc, this.state.photoconc_ajoute).map((photoconc, index) =>
                        photoconc.PHOTO !== "" ? (
                          <Card
                            key={index}
                            style={{
                              ...styles.photoconc_card,
                              border: `1px solid ${this._find_color(this.state.champ_modifie, photoconc.IDPHOTOCONC)}`,
                            }}
                          >
                            <CardActions disableSpacing style={styles.photoconc_cardaction}>
                              <IconButton aria-label="Référente" color={photoconc.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(index)}>
                                <StarRate />
                              </IconButton>

                              <div
                                style={{
                                  marginLeft: "-25px",
                                  fontSize: "smaller",
                                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                  userSelect: "none",
                                  color: photoconc.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)",
                                }}
                                onClick={this._handle_click_photographies_referente(index)}
                              >{`Référente`}</div>

                              {(photoconc.IDPHOTOCONC < 0 || photoconc.IDPHOTOCONC === undefined) && !_.includes(this.state.phototemp, photoconc.IDPHOTOCONC) ? (
                                <IconButton aria-label="Supprimer" onClick={this._handle_click_photographies_a_supprimer(index)}>
                                  <Close />
                                </IconButton>
                              ) : (
                                <div style={{ minWidth: "47.47px" }}></div>
                              )}
                            </CardActions>

                            <CardMedia onClick={this.zoomOnImage(photoconc.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photoconc.PHOTO)} />
                          </Card>
                        ) : null
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div style={styles.submit_div}>
              <Button variant="contained" color="primary" onClick={this._handle_submit_photographie} disabled={this.state.disabledForm}>
                Enregistrer
              </Button>
            </div>

            <Dialog open={this.state.dialog_traitement} onClose={() => this.setState({ dialog_traitement: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle onClose={() => this.setState({ dialog_traitement: false })} id="alert-dialog-title">
                <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_traitement: false })}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Modification impossible
                  <br />
                  Photographie en cours de traitement dans Gescime
                </DialogContentText>
              </DialogContent>
            </Dialog>

            {this.state.photoDialogPhoto !== null ? (
              <Dialog
                fullScreen
                open={this.state.photoDialogOpen}
                onClose={() => {
                  this.setState({ photoDialogOpen: false });
                }}
                TransitionComponent={Transition}
              >
                <AppBar className={styles.appBar}>
                  <Toolbar>
                    <Typography variant="h6" style={styles.typography}></Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        this.setState({ photoDialogOpen: false });
                      }}
                      aria-label="close"
                    >
                      <Close />
                    </IconButton>
                  </Toolbar>
                </AppBar>

                <Card style={styles.photoconc_card_dialog} elevation={0}>
                  <CardMedia style={styles.photoconc_cardmedia_dialog} image={window.URL.createObjectURL(this.state.photoDialogPhoto)} />
                </Card>
              </Dialog>
            ) : null}

            {this.state.dialogSupprimePhotoIndex !== null ? (
              <Dialog
                open={this.state.dialogSupprimePhotoOpen}
                onClose={() => this.setState({ dialogSupprimePhotoOpen: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Attention</DialogTitle>

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">Confirmez-vous la suppression de cette photographie</DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => this.setState({ dialogSupprimePhotoOpen: false })} color="primary">
                    Non
                  </Button>
                  <Button onClick={this._handle_click_photographies_supprimer(this.state.dialogSupprimePhotoIndex)} color="primary" autoFocus>
                    Oui
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}

            <DialogAccessRight state={this.state.droit_util_ajout_photoconc} onChange={(value) => this.setState({ droit_util_ajout_photoconc: value })} />
            <DialogAccessRight state={this.state.droit_util_modif_photoconc} onChange={(value) => this.setState({ droit_util_modif_photoconc: value })} />
          </Paper>

          <Backdrop style={styles.loaderstyle} open={this.state.loaderopen}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(MPhotographies);
