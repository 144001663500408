import db from "../Db";
import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

var _ = require("lodash");

export async function CreateTypetb(headers) {
  const _typtb = await GetApiData("TYPETB", headers);

  if (_typtb.Message !== undefined) {
    return _typtb;
  }

  var drops = [];
  _.forEach(_typtb, (typetb) => {
    drops.push({
      NUMTB: typetb.NUMTB,
      NOMTOMBE: typetb.NOMTOMBE,
    });
  });

  return await db
    .open()
    .then(() => {
      return db.TYPETB.bulkAdd(drops)
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
