import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import {
  AppBar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  TextField,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  withWidth,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Card,
  CardActions,
  CardMedia,
  Slide,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { PhotoCamera, Done, Mic, Close, StarRate } from "@material-ui/icons";
import { withCookies } from "react-cookie";

import { GetClient } from "../../Helpers/Get/Client";
import { GetAllCimetier } from "../../Helpers/Get/Cimetier";
import { GetConcessById, GetConcessByIdLieu } from "../../Helpers/Get/Concess";
import { GetLieuById, GetLieuByCimel } from "../../Helpers/Get/Lieu";
import { GetAllTypetb } from "../../Helpers/Get/Typetb";
import { GetAllDuree } from "../../Helpers/Get/Duree";
import { GetTemporaire, GetTemporaireNonEnvoye, GetTemporairePhoto } from "../../Helpers/Get/Temporaire";
import { GetPaByCN } from "../../Helpers/Get/Pa";

import AddConcess from "../../Helpers/Add/Concess";
import UpdateConcess from "../../Helpers/Update/Concess";
import AddTemporaire from "../../Helpers/Add/Temporaire";
import UpdateTemporaire from "../../Helpers/Update/Temporaire";
import UpdateLieu from "../../Helpers/Update/Lieu";

import { GetSquare, GetRank, GetTomb, GetLieuFromEmpl, handleError, CreeTemporaire, Cree_iso_date, Cree_date_chaine, ResizeImage } from "../../Helpers/Function";
import { differenceObject } from "../../Helpers/Test/Difference";
import { IsOracle } from "../../Helpers/Test/IsOracle";
import { GetParam } from "../../Helpers/Get/Param";
import UpdateSvg from "../../Helpers/Update/Svg";
import { GetPhotoconcByCocleunik, GetPhotoconcByIdTablette } from "../../Helpers/Get/Photoconc";
import { AjoutePhotos } from "../../Helpers/Submit/Photoconc";
import { GetAllConcessEtatEcheance } from "../../Helpers/Get/ConcessEtatEcheance";

import b64toBlob from "b64-to-blob";
import no_photo from "../../Images/no_photo.json";
import { CheckUserRight } from "../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "../Global/DialogAccessRight";
import UpdatePhotoconc from "../../Helpers/Update/Photoconc";
import { GetTemporaireForColor } from "./../../Helpers/Get/Temporaire";
import { GetAllPhotoconc } from "./../../Helpers/Get/Photoconc";
import { Autocomplete } from "@material-ui/lab";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  menuitem: {
    minHeight: 36,
  },
  div: {
    marginBottom: 8,
    marginTop: 16,
  },
  submit_div: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },
  table: {
    marginTop: 24,
  },
  tablecells: {
    border: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  photoconc_card: {
    border: "1px solid grey",
    cursor: "pointer",
    flex: 1,
    margin: "auto",
    maxWidth: 160,
    minWidth: 160,
    textAlign: "center",
    width: 100,
    marginBottom: 5,
  },
  photoconc_card_dialog: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    margin: "120px 50px 50px 50px",
  },
  photoconc_cardaction: {
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 3,
  },
  photoconc_cardmedia: {
    backgroundColor: "whitesmoke",
    backgroundSize: "contain",
    borderTop: "1px solid lightgrey",
    height: 0,
    paddingTop: "100%",
  },
  photoconc_cardmedia_dialog: {
    backgroundSize: "contain",
    height: 0,
    paddingTop: "60%",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  appBar: {
    position: "relative",
  },
  typography: {
    flex: 1,
  },
};

const initialState = {
  CONCESS: {
    id: 0,
    COCLEUNIK: 0,
    LICLEUNIK: 0,
    DUREE: 0,
    DATEEXPIRA: null,
    MADATE: null,
    DETAIL: 0,
    FAMILLE: "",
    SUPERFICIE: "",
    TYPECIME: 0,
    DIVERS: "",
    ANCNUM: "",
    TYPECONC: 0,
    CONCESSIONTYPE: 0,
    EMPL: "",
    IDPROCEDUREABANDON: 0,
    DIVERS2: "",
    INTERLOCUTEUR_C0CLEUNIK: 0,
    A_REPRENDRE: 0,
    POSE_PLAQUE: 0,
    IDCONCESS_ETAT_ECHEANCE: "",
    EXH_ADMIN_REALISE: false,
    MONUMENT_ENLEVE: false,
    EMPLACEMENT_LIEU: "",
    NOMBRE: 0,
  },
  LIEU: {
    id: 0,
    LICLEUNIK: 0,
    EMPLACEMENT: {
      TYPECAR: "Carré",
      CARRE: "",
      TYPERG: "Rang",
      RANG: "",
      TYPEFOS: "Tombe",
      TOMBE: "",
      ETAT: 0,
      GELE: false,
      H1: 0,
      V1: 0,
      H2: 0,
      V2: 0,
      H3: 0,
      V3: 0,
      H4: 0,
      V4: 0,
    },
    COCLEUNIK: 0,
    CIMEL: 0,
    TOMBEL: 0,
    NOMBREL: 0,
    DUREEL: "",
    POSNUM: 0,
    SITUATION: 0,
    SITUATION_ALL_1: 0,
    SITUATION_ALL_2: 0,
    SITUATION_ALL_3: 0,
    SITUATION_ALL_4: 0,
    TAILLEPOLICEDULIEU: 0,
    AFFMULTILIGNE: 0,
  },
};

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = undefined;

if (SpeechRecognition !== undefined) {
  recognition = new SpeechRecognition();

  recognition.continous = false;
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;
  recognition.lang = "fr-FR";
}

const en_vigueur = (pa) => {
  return pa.PA1_VIGUEUR === 1 && pa.PA2_VIGUEUR === 1 && pa.PA3_VIGUEUR === 1;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MConcession extends Component {
  constructor(props) {
    super(props);

    this.IDCLIENT = 0;
    this.ID_TABLETTE = "";
    this.ID_UTIL = atob(props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

    this.champ_nature = React.createRef();
    this.ABANDON_1_ref = React.createRef();
    this.ABANDON_2_ref = React.createRef();
    this.ABANDON_1B_ref = React.createRef();
    this.ABANDON_2B_ref = React.createRef();
    this.famille_ref = React.createRef();

    this.concessdivers_ref_1 = React.createRef();
    this.concessdivers_ref_2 = React.createRef();
    this.concessdivers2_ref_1 = React.createRef();
    this.concessdivers2_ref_2 = React.createRef();

    this.textfield_semelle = React.createRef();
    this.textfield_monument = React.createRef();

    this.state = {
      IS_ORACLE: false,
      CONCESS_SAUVEGARDE: { ...initialState.CONCESS },
      CONCESS: { ...initialState.CONCESS },
      PA: [],
      CIMETIER: [],
      CONCESS_ETAT_ECHEANCE: [],
      LIEU_SAUVEGARDE: { ...initialState.LIEU },
      LIEU: { ...initialState.LIEU },
      PHOTOCONC_SAUVEGARDE: [],
      PHOTOCONC: [],
      LIEU_AVANT_ENVOI: [],
      TYPETB_SAUVEGARDE: [],
      TYPETB: [],
      DUREE: [],
      SQUARE: [],
      RANK: [],
      TOMB: [],
      CIMEL: 0,
      TOMBEL: 0,
      DUREEL: "",
      REDIRECT: null,
      SPEECH_LISTENING_PV1: false,
      SPEECH_LISTENING_PV2: false,
      TemporaireNonEnvoye: null,
      estTemporaireNonEnvoye: false,
      param: "",
      dialog_traitement: false,
      dialog_plus_en_vigueur: false,
      dialog_plus_en_vigueur_message: "",
      procedure: this.props.procedure === "pa",
      photoDialogOpen: false,
      photoDialogPhoto: null,
      droit_util_ajout_photoconc: false,
      droit_util_modif_photoconc: false,
      champ_modifie: [],
      photoconc_pour_combo: [],
      photoconc_pour_combo2: [],
      cas_strasbourg: false,
      last_photoconc_sauvegarde: "",
      last_photoconc: "",
      photoEnvoye: false,
    };

    if (props.id_concession !== undefined && props.id_concession !== "ajout") {
      GetConcessById(props.id_concession).then((concess) => {
        GetPhotoconcByCocleunik(concess.COCLEUNIK).then((photos) => {
          let last_photos = _.last(photos);

          if (last_photos !== undefined) {
            GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", last_photos.IDPHOTOCONC).then((temporaire) => {
              if (temporaire !== undefined) {
                if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
                  this.setState({ photoEnvoye: true });
                }
              }
            });
          }

          photos = _.filter(photos, (p) => {
            return p.ABANDON_1 || p.ABANDON_2;
          });

          this.setState({
            CONCESS_SAUVEGARDE: concess,
            PHOTOCONC_SAUVEGARDE: photos,
            last_photoconc_sauvegarde: last_photos,
          });
        });
      });
    }

    if (props.id_lieu !== undefined) {
      let CONCESS = this.state.CONCESS;
      CONCESS.LICLEUNIK = props.id_lieu;

      GetLieuById(props.id_lieu).then((lieu) => {
        CONCESS.TYPECIME = lieu.CIMEL;
        this.setState({
          CONCESS: CONCESS,
          LIEU_SAUVEGARDE: lieu,
          LIEU: lieu,
        });
      });
    }
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    GetParam().then((param) => {
      this.setState({ param: param });
    });

    GetClient().then((client) => {
      this.IDCLIENT = client.IDCLIENT;
      this.ID_TABLETTE = client.ID_TABLETTE;
    });

    const alias = atob(this.props.cookies.get(encodeURIComponent(btoa("ALIAS"))));

    if (/^strasbourg(.*)67000$/.test(alias)) {
      const photos_to_object = [
        {
          id: 1,
          MONUMENT_ETAT: "R.A.S",
        },
        {
          id: 2,
          MONUMENT_ETAT: "Dégradé",
        },
        {
          id: 3,
          MONUMENT_ETAT: "Dangereux",
        },
        {
          id: 4,
          MONUMENT_ETAT: "Problème végétation",
        },
      ];

      const photos_to_object2 = [
        {
          id: 1,
          SEMELLE_ETAT: "Affaissement",
        },
        {
          id: 2,
          SEMELLE_ETAT: "Hauteur d'arbre",
        },
        {
          id: 3,
          SEMELLE_ETAT: "Monument penché",
        },
        {
          id: 4,
          SEMELLE_ETAT: "Végétation exubérante",
        },
        {
          id: 5,
          SEMELLE_ETAT: "Stèle descellée",
        },
      ];

      this.setState({ photoconc_pour_combo: photos_to_object, photoconc_pour_combo2: photos_to_object2, cas_strasbourg: true });
    } else {
      GetAllPhotoconc().then((photos) => {
        const photos_monument = _.uniqWith(_.compact(_.map(photos, "MONUMENT_ETAT")), _.isEqual);
        const photos_semelle = _.uniqWith(_.compact(_.map(photos, "SEMELLE_ETAT")), _.isEqual);

        let photos_to_object = [];
        for (let index = 0; index < photos_monument.length; index++) {
          photos_to_object.push({
            id: index + 1,
            MONUMENT_ETAT: photos_monument[index],
          });
        }

        let photos_to_object2 = [];
        for (let index = 0; index < photos_semelle.length; index++) {
          photos_to_object2.push({
            id: index + 1,
            SEMELLE_ETAT: photos_semelle[index],
          });
        }

        this.setState({ photoconc_pour_combo: photos_to_object, photoconc_pour_combo2: photos_to_object2 });
      });
    }

    GetAllCimetier(1).then((cimetier) => {
      GetAllTypetb().then((typetb) => {
        GetAllDuree().then((duree) => {
          GetAllConcessEtatEcheance().then((concess_etat_echeance) => {
            concess_etat_echeance = _.orderBy(concess_etat_echeance, "ORDRE", "asc");

            concess_etat_echeance = _.filter(concess_etat_echeance, (concetaech) => {
              return concetaech.ARCHIVER === false || concetaech.IDCONCESS_ETAT_ECHEANCE === this.state.CONCESS.IDCONCESS_ETAT_ECHEANCE;
            });

            this.setState({ CIMETIER: cimetier, TYPETB: typetb, TYPETB_SAUVEGARDE: typetb, DUREE: duree, CONCESS_ETAT_ECHEANCE: concess_etat_echeance });
          });
        });
      });
    });

    if (this.props.id_concession !== undefined && this.props.id_concession !== "ajout") {
      GetConcessById(this.props.id_concession).then((concess) => {
        this.props.changeTitle(concess.FAMILLE);

        let field = [];
        GetPhotoconcByCocleunik(concess.COCLEUNIK).then((photos) => {
          let last_photos = _.last(photos);

          if (last_photos !== undefined) {
            GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", last_photos.IDPHOTOCONC).then((temporaire) => {
              if (temporaire !== undefined) {
                if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
                  this.setState({ photoEnvoye: true });
                }
              }
            });
          }

          photos = _.filter(photos, (p) => {
            return p.ABANDON_1 || p.ABANDON_2;
          });

          _.forEach(photos, (p) => {
            const temporaire = GetTemporaireForColor("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", p.IDPHOTOCONC);

            temporaire.then((temp) => {
              if (temp.length > 0) {
                _.forEach(temp, (t) => {
                  let color = "red";

                  if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                    color = "blue";
                  }

                  if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                    color = "green";
                  }

                  if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                    color = "primary";
                  }

                  var element_temp = t.TABLE_MODIFIE_JSON;
                  element_temp = _.omit(element_temp, ["id"]);

                  let sub_field = {};
                  for (var key in element_temp) {
                    sub_field[key] = color;

                    if (key === (this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC")) sub_field[key] = this.state.IS_ORACLE ? element_temp.IDEVOL_ETAT : element_temp.IDPHOTOCONC;
                  }

                  field.push(sub_field);
                });

                this.setState({ champ_modifie: field });
              }
            });
          });

          this.setState({
            PHOTOCONC: photos,
            last_photoconc: last_photos,
          });
        });

        GetTemporaireNonEnvoye("CONCESS", "COCLEUNIK", concess.COCLEUNIK).then((temp) => {
          if (temp !== undefined)
            this.setState({
              TemporaireNonEnvoye: temp.TYPE_D_ACTION,
              estTemporaireNonEnvoye: true,
            });
        });

        GetLieuById(concess.LICLEUNIK).then((lieu) => {
          concess.TYPECIME = lieu.CIMEL;
          concess.DETAIL = lieu.TOMBEL;

          GetLieuByCimel(lieu.CIMEL, true).then((alllieu) => {
            let square = GetSquare(alllieu);
            let rank = GetRank(alllieu, lieu.EMPLACEMENT.CARRE);
            let tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE, lieu.EMPLACEMENT.RANG);

            GetPaByCN(lieu.CIMEL, lieu.NOMBREL).then((pa) => {
              if (concess.DUREE > 0) {
                pa = _.filter(pa, function (p) {
                  return p.CONCESSIONTYPE === concess.CONCESSIONTYPE && p.CODEDURE === concess.DUREE;
                });
              } else {
                pa = _.filter(pa, function (p) {
                  return p.CONCESSIONTYPE === concess.CONCESSIONTYPE;
                });
              }

              const TYPETB = _.cloneDeep(this.state.TYPETB_SAUVEGARDE);

              const numtbs = _.map(pa, "CODETYPE");
              const typetb = _.filter(TYPETB, function (p) {
                return _.includes(numtbs, p.NUMTB);
              });

              this.setState({
                CONCESS: concess,
                PA: pa,
                LIEU: lieu,
                SQUARE: square,
                RANK: rank,
                TOMB: tomb,
                TOMBEL: lieu.TOMBEL,
                TYPETB: typetb,
              });
            });
          });
        });
      });
    }

    if (this.props.id_lieu !== undefined) {
      let CONCESS = this.state.CONCESS;
      CONCESS.LICLEUNIK = parseInt(this.props.id_lieu);

      GetLieuById(this.props.id_lieu).then((lieu) => {
        CONCESS.TYPECIME = lieu.CIMEL;
        CONCESS.DETAIL = lieu.TOMBEL;

        GetLieuByCimel(lieu.CIMEL, true).then((alllieu) => {
          let square = GetSquare(alllieu);
          let rank = GetRank(alllieu, lieu.EMPLACEMENT.CARRE);
          let tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE, lieu.EMPLACEMENT.RANG);

          GetPaByCN(lieu.CIMEL, lieu.NOMBREL).then((pa) => {
            if (!this.state.IS_ORACLE) {
              pa = _.filter(pa, function (p) {
                return p.CONCESSIONTYPE === CONCESS.CONCESSIONTYPE;
              });
            }

            const TYPETB = _.cloneDeep(this.state.TYPETB_SAUVEGARDE);

            const numtbs = _.map(pa, "CODETYPE");
            const typetb = _.filter(TYPETB, function (p) {
              return _.includes(numtbs, p.NUMTB);
            });

            this.setState({
              CONCESS: CONCESS,
              PA: pa,
              LIEU: lieu,
              SQUARE: square,
              RANK: rank,
              TOMB: tomb,
              TOMBEL: lieu.TOMBEL,
              TYPETB: typetb,
            });
          });
        });
      });
    }
  };

  _handle_change_concess = (champ, refInput) => (event) => {
    event.preventDefault();

    let cursor;
    if (event.target !== undefined) cursor = event.target.selectionStart;

    let CONCESS = this.state.CONCESS;
    let LIEU = this.state.LIEU;
    let PHOTOCONC = this.state.PHOTOCONC;

    switch (champ) {
      case "FAMILLE":
        CONCESS[champ] = event.target.value.toUpperCase();
        break;
      case "MADATE":
      case "DATEEXPIRA":
        CONCESS[champ] = new Date(event.target.value).toISOString().slice(0, -5);
        break;
      case "SUPERFICIE":
        CONCESS[champ] = event.target.value.replace(",", ".");
        break;
      case "A_REPRENDRE":
      case "POSE_PLAQUE":
      case "EXH_ADMIN_REALISE":
      case "MONUMENT_ENLEVE":
        CONCESS[champ] = event.target.checked ? 1 : 0;
        break;
      case "DUREE":
        if (CONCESS.MADATE !== null) {
          let duree = parseInt(this.state.DUREE.find((d) => d.NUMDURE === event.target.value).LIBDURE.replace(/\D/g, ""));

          if (!Number.isNaN(duree)) {
            let MADATE = new Date(CONCESS.MADATE);
            let DATEEXPIRA = new Date(
              new Date(MADATE.getFullYear() + duree, MADATE.getMonth(), MADATE.getDate(), MADATE.getHours(), MADATE.getMinutes(), MADATE.getSeconds()) - MADATE.getTimezoneOffset() * 60000
            );

            CONCESS.DATEEXPIRA = DATEEXPIRA.toISOString().slice(0, -5);
          } else {
            CONCESS.DATEEXPIRA = null;
          }
        }

        CONCESS[champ] = event.target.value;
        LIEU.DUREEL = event.target.value;
        break;
      case "DETAIL":
        CONCESS[champ] = event.target.value;
        LIEU.TOMBEL = event.target.value;

        let pa;
        if (CONCESS.DUREE > 0) {
          pa = _.filter(this.state.PA, (pa) => {
            return pa.CODEDURE === CONCESS.DUREE && pa.CODETYPE === parseInt(event.target.value);
          })[0];
        } else {
          pa = _.filter(this.state.PA, (pa) => {
            return pa.CODETYPE === parseInt(event.target.value);
          });

          pa = _.orderBy(pa, ["PA1_VIGUEUR", "PA2_VIGUEUR", "PA3_VIGUEUR"], ["asc", "asc", "asc"])[0];
        }

        if (!en_vigueur(pa)) {
          this.setState({ dialog_plus_en_vigueur: true, dialog_plus_en_vigueur_message: pa.PA1_MESSAGE || pa.PA2_MESSAGE || pa.PA3_MESSAGE });
        }

        break;
      case "DIVERS":
        PHOTOCONC = _.filter(PHOTOCONC, (p) => {
          return p.ABANDON_1 === true && p.PHOTO !== "" && p.IDPHOTOCONC !== undefined;
        });

        const value_divers = event.target.value;

        if (PHOTOCONC.length > 0) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              CONCESS[champ] = value_divers;

              this.setState({ CONCESS: CONCESS, LIEU: LIEU }, () => {
                if (refInput.current !== null) refInput.current.selectionEnd = cursor;
              });
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              CONCESS[champ] = value_divers;

              this.setState({ CONCESS: CONCESS, LIEU: LIEU }, () => {
                if (refInput.current !== null) refInput.current.selectionEnd = cursor;
              });
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        }

        break;
      case "DIVERS2":
        PHOTOCONC = _.filter(PHOTOCONC, (p) => {
          return p.ABANDON_2 === true && p.PHOTO !== "" && p.IDPHOTOCONC !== undefined;
        });

        const value_divers2 = event.target.value;

        if (PHOTOCONC.length > 0) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              CONCESS[champ] = value_divers2;

              this.setState({ CONCESS: CONCESS, LIEU: LIEU }, () => {
                if (refInput.current !== null) refInput.current.selectionEnd = cursor;
              });
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              CONCESS[champ] = value_divers2;

              this.setState({ CONCESS: CONCESS, LIEU: LIEU }, () => {
                if (refInput.current !== null) refInput.current.selectionEnd = cursor;
              });
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        }

        break;
      default:
        CONCESS[champ] = event.target.value;
        break;
    }

    this.setState({ CONCESS: CONCESS, LIEU: LIEU }, () => {
      if (refInput && refInput.current != null) refInput.current.selectionEnd = cursor;
    });
  };

  _handle_change_date_concess = (champ) => (date) => {
    let CONCESS = this.state.CONCESS;

    if (date instanceof Date && !isNaN(date)) {
      let newdate = new Date(date);

      if (newdate <= new Date(1911, 3, 11)) {
        newdate.setMinutes(9);
        newdate.setSeconds(21);
      }

      var userTimezoneOffset = newdate.getTimezoneOffset() * 60000;
      newdate = new Date(newdate.getTime() - userTimezoneOffset);

      CONCESS[champ] = newdate.toISOString().slice(0, -5);

      this.setState({ CONCESS: CONCESS });
    }
  };

  _handle_change_combo_cimetier = (event) => {
    GetLieuByCimel(event.target.value).then((lieu) => {
      let square;

      if (this.props.id_concession === undefined) {
        lieu = _.filter(lieu, (l) => {
          return l.EMPLACEMENT.ETAT !== 2;
        });

        square = GetSquare(lieu);
      } else {
        square = GetSquare(lieu);
      }

      let CONCESS = this.state.CONCESS;
      CONCESS.TYPECIME = event.target.value;

      let LIEU = this.state.LIEU;
      LIEU.EMPLACEMENT.CARRE = "";
      LIEU.EMPLACEMENT.RANG = "";
      LIEU.EMPLACEMENT.TOMBE = "";

      this.setState({
        CONCESS: CONCESS,
        LIEU: LIEU,
        SQUARE: square,
        RANK: [],
        TOMB: [],
      });
    });
  };

  _handle_change_combo_square = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.CONCESS.TYPECIME).then((alllieu) => {
      let rank;

      if (this.props.id_concession === undefined) {
        alllieu = _.filter(alllieu, (l) => {
          return l.EMPLACEMENT.ETAT !== 2;
        });

        rank = GetRank(alllieu, event.target.value.trim());
      } else {
        rank = GetRank(alllieu, event.target.value.trim());
      }

      let LIEU = this.state.LIEU;
      LIEU.EMPLACEMENT.CARRE = event.target.value;
      LIEU.EMPLACEMENT.RANG = "";
      LIEU.EMPLACEMENT.TOMBE = "";

      this.setState({
        LIEU: LIEU,
        RANK: rank,
        TOMB: [],
      });
    });
  };

  _handle_change_combo_rank = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.CONCESS.TYPECIME).then((alllieu) => {
      let tomb;

      if (this.props.id_concession === undefined) {
        alllieu = _.filter(alllieu, (l) => {
          return l.EMPLACEMENT.ETAT !== 2;
        });

        tomb = GetTomb(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), event.target.value.trim());
      } else {
        tomb = GetTomb(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), event.target.value.trim());
      }

      let LIEU = this.state.LIEU;
      LIEU.EMPLACEMENT.RANG = event.target.value;
      LIEU.EMPLACEMENT.TOMBE = "";

      this.setState({
        LIEU: LIEU,
        TOMB: tomb,
      });
    });
  };

  _handle_change_combo_tomb = (event) => {
    event.preventDefault();

    GetLieuByCimel(this.state.CONCESS.TYPECIME).then((alllieu) => {
      let LIEU;

      if (this.props.id_concession === undefined) {
        alllieu = _.filter(alllieu, (l) => {
          return l.EMPLACEMENT.ETAT !== 2;
        });

        LIEU = GetLieuFromEmpl(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), this.state.LIEU.EMPLACEMENT.RANG.trim(), event.target.value.trim());
      } else {
        LIEU = GetLieuFromEmpl(alllieu, this.state.LIEU.EMPLACEMENT.CARRE.trim(), this.state.LIEU.EMPLACEMENT.RANG.trim(), event.target.value.trim());
      }

      GetConcessByIdLieu(LIEU.LICLEUNIK).then((concess) => {
        if (concess !== undefined) {
          LIEU.COCLEUNIK = concess.COCLEUNIK;

          UpdateLieu(LIEU).catch((Err) => handleError(Err));
        }
      });

      if (LIEU.EMPLACEMENT.CARRE === "") {
        LIEU.EMPLACEMENT.CARRE = " ";
      }

      if (LIEU.EMPLACEMENT.RANG === "") {
        LIEU.EMPLACEMENT.RANG = " ";
      }

      if (LIEU.EMPLACEMENT.TOMBE === "") {
        LIEU.EMPLACEMENT.TOMBE = " ";
      }

      let CONCESS = this.state.CONCESS;
      CONCESS.LICLEUNIK = LIEU.LICLEUNIK;
      CONCESS.EMPL = this._cree_chaine_emplacement();
      CONCESS.DETAIL = LIEU.TOMBEL === 0 ? _.findLast(this.state.TYPETB).id : LIEU.TOMBEL;

      this.setState({
        CONCESS: CONCESS,
        LIEU: LIEU,
        LIEU_AVANT_ENVOI: _.cloneDeep(LIEU),
      });
    });
  };

  _cree_chaine_emplacement = () => {
    let empl = "";

    if (!this.state.LIEU.EMPLACEMENT.CARRE.includes("Provisoire") || !this.state.LIEU.EMPLACEMENT.CARRE.includes("Non affect")) {
      empl = _.filter([this.state.LIEU.EMPLACEMENT.CARRE, this.state.LIEU.EMPLACEMENT.RANG, this.state.LIEU.EMPLACEMENT.TOMBE]).join("/");
    }

    return empl;
  };

  _cree_chaine_emplacement2 = () => {
    return _.filter([
      _.filter([this.state.LIEU.EMPLACEMENT.TYPECAR, this.state.LIEU.EMPLACEMENT.CARRE]).join(" "),
      _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
      _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
    ]).join(" - ");
  };

  _cree_chaine_date = (date) => {
    if (date !== null) {
      if (date.includes("1900-01-01")) {
        return null;
      } else {
        return date.split("T")[0];
      }
    } else {
      return null;
    }
  };

  _UpdateConcessData = (CONCESS) => {
    if (CONCESS.DATEEXPIRA !== "") {
      CONCESS.DATEEXPIRA = Cree_iso_date(CONCESS.DATEEXPIRA);
    }

    if (CONCESS.MADATE !== "") {
      CONCESS.MADATE = Cree_iso_date(CONCESS.MADATE);
    }

    if (CONCESS.DETAIL === "") {
      CONCESS.DETAIL = 0;
    }

    if (CONCESS.SUPERFICIE !== "") {
      CONCESS.SUPERFICIE = parseFloat(CONCESS.SUPERFICIE);
    }

    if (CONCESS.EMPLACEMENT_LIEU === "") {
      CONCESS.EMPLACEMENT_LIEU = this._cree_chaine_emplacement2();
    }

    return CONCESS;
  };

  _AjoutTemporaire = (temporaire) => {
    AddTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: this.state.procedure ? `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}/pa` : `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourTemporaire = (temporaire) => {
    UpdateTemporaire(temporaire)
      .then(() => {
        this.setState({
          REDIRECT: this.state.procedure ? `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}/pa` : `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}`,
        });
      })
      .catch((Err) => handleError(Err));
  };

  _AjoutConcess = (CONCESS) => {
    let LIEU = this.state.LIEU;

    CONCESS = _.omit(CONCESS, ["id"]);

    const ajout_concess = AddConcess(CONCESS);
    ajout_concess
      .then((_id) => {
        _.set(CONCESS, "id", _id);
        _.set(CONCESS, "COCLEUNIK", -_id);

        const modif_cocleunik = UpdateConcess(CONCESS);
        modif_cocleunik
          .then(() => {
            if (this.state.IS_ORACLE) {
              CONCESS = _.omit(CONCESS, ["A_REPRENDRE", "POSE_PLAQUE"]);
              for (var key in CONCESS) {
                if (key === "EMPL") {
                  CONCESS.PANNEAU = CONCESS[key];
                  delete CONCESS[key];
                }
                if (key === "INTERLOCUTEUR_C0CLEUNIK") {
                  CONCESS.C0CLEUNIK = CONCESS[key];
                  delete CONCESS[key];
                }
              }
            }

            _.set(CONCESS, "DEVENU_DEGELE", LIEU.EMPLACEMENT.GELE);
            const temporaire = CreeTemporaire(this.IDCLIENT, this.ID_TABLETTE, this.ID_UTIL, `Ajout de la concession ${CONCESS.FAMILLE}`, "CONCESS", 1, CONCESS, null);
            this._AjoutTemporaire(temporaire);

            _.set(LIEU.EMPLACEMENT, "ETAT", 2);
            _.set(LIEU.EMPLACEMENT, "GELE", 0);
            _.set(LIEU, "COCLEUNIK", CONCESS.COCLEUNIK);

            UpdateLieu(LIEU)
              .then(() => {
                UpdateSvg(LIEU).catch((Err) => handleError(Err));
              })
              .catch((Err) => handleError(Err));
          })
          .catch((Err) => handleError(Err));
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourConcess = (CONCESS, CONCESS_TEMPORAIRE, MAJ_PHOTO) => {
    const modifie_concess = UpdateConcess(CONCESS);
    modifie_concess
      .then(() => {
        if (_.has(CONCESS_TEMPORAIRE, ["DETAIL"])) {
          UpdateLieu(this.state.LIEU);
        }

        if (MAJ_PHOTO) {
          this._MiseAJourPhotoconc(false);
        }

        GetTemporaireNonEnvoye("CONCESS", "COCLEUNIK", CONCESS.COCLEUNIK).then((temporaire) => {
          if (this.state.IS_ORACLE) {
            CONCESS_TEMPORAIRE = _.omit(CONCESS_TEMPORAIRE, ["A_REPRENDRE", "POSE_PLAQUE"]);
          }

          if (temporaire !== undefined) {
            var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, CONCESS_TEMPORAIRE);

            temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;
            temporaire.TITRE = temporaire.TITRE.replace(/(.*concession )(.*)/, `$1${CONCESS.FAMILLE}`);

            this._MiseAJourTemporaire(temporaire);
          } else {
            const temporaire = CreeTemporaire(
              this.IDCLIENT,
              this.ID_TABLETTE,
              this.ID_UTIL,
              `Modification de la concession ${CONCESS.FAMILLE}`,
              "CONCESS",
              2,
              CONCESS_TEMPORAIRE,
              this.state.CONCESS_SAUVEGARDE
            );

            this._AjoutTemporaire(temporaire);
          }
        });
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourPhotoconc = (withRedirect) => {
    let PHOTOCONC = _.cloneDeep(this.state.PHOTOCONC);
    let PHOTOCONC_SAUVEGARDE = _.cloneDeep(this.state.PHOTOCONC_SAUVEGARDE);

    const principal = _.filter(PHOTOCONC, "PRINCIPAL", 1)[0];

    if (principal !== undefined) {
      GetPhotoconcByCocleunik(principal.COCLEUNIK).then((allPhotos) => {
        for (let index = 0; index < allPhotos.length; index++) {
          let element = allPhotos[index];

          if (element.IDPHOTOCONC !== principal.IDPHOTOCONC) {
            element.PRINCIPAL = false;

            const modifie_photo = UpdatePhotoconc(element);

            modifie_photo.catch((Err) => handleError(Err));
          }
        }
      });
    }

    AjoutePhotos(PHOTOCONC, PHOTOCONC_SAUVEGARDE, this.state.IS_ORACLE, this.IDCLIENT, this.ID_TABLETTE, this.ID_UTIL).then(() => {
      if (withRedirect) {
        this.setState({
          REDIRECT: this.state.procedure ? `/consulter/fiche-concession/${this.state.CONCESS.COCLEUNIK}/pa` : `/consulter/fiche-concession/${this.state.CONCESS.COCLEUNIK}`,
        });
      }
    });
  };

  _handle_submit_form_concess = (event) => {
    event.preventDefault();

    let CONCESS = this.state.CONCESS;
    CONCESS = this._UpdateConcessData(CONCESS);

    let PHOTOCONC = _.cloneDeep(this.state.PHOTOCONC);
    let PHOTOCONC_SAUVEGARDE = _.cloneDeep(this.state.PHOTOCONC_SAUVEGARDE);

    if (this.state.last_photoconc !== undefined && this.state.last_photoconc !== "") {
      let photoconc = _.cloneDeep(this.state.last_photoconc);

      const modifie_photo = UpdatePhotoconc(photoconc);
      modifie_photo
        .then(() => {
          GetTemporairePhoto("PHOTOCONC", "COCLEUNIK", photoconc.COCLEUNIK).then((photoconcTemporaire) => {
            if (this.state.last_photoconc !== "") {
              _.forEach(photoconcTemporaire, (pt) => {
                if (pt.DATE_DE_CREATION === null) {
                  if (this.state.last_photoconc.MONUMENT_ETAT !== undefined) {
                    pt.TABLE_MODIFIE_JSON.MONUMENT_ETAT = this.state.last_photoconc.MONUMENT_ETAT;
                  }

                  if (this.state.last_photoconc.MONUMENT_ETAT !== undefined) {
                    pt.TABLE_MODIFIE_JSON.SEMELLE_ETAT = this.state.last_photoconc.SEMELLE_ETAT;
                  }

                  UpdateTemporaire(pt)
                    .then(() => {
                      console.log(pt.TABLE_MODIFIE_JSON);
                      GetPhotoconcByIdTablette(pt.TABLE_MODIFIE_JSON.id).then((photo) => {
                        console.log(photo);
                        if (photo.IDPHOTOCONC !== this.state.last_photoconc.IDPHOTOCONC) {
                          if (this.state.last_photoconc.MONUMENT_ETAT !== undefined) {
                            photo.MONUMENT_ETAT = this.state.last_photoconc.MONUMENT_ETAT;
                          }

                          if (this.state.last_photoconc.MONUMENT_ETAT !== undefined) {
                            photo.SEMELLE_ETAT = this.state.last_photoconc.SEMELLE_ETAT;
                          }

                          UpdatePhotoconc(photo).catch((Err) => handleError(Err));
                        }
                      });
                    })
                    .catch((Err) => handleError(Err));
                }
              });
            }
          });

          GetTemporaireNonEnvoye("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", photoconc.IDPHOTOCONC).then((temporaire) => {
            if (this.state.IS_ORACLE) {
              for (var key in photoconc) {
                if (key === "IDPHOTOCONC") {
                  photoconc.IDEVOL_ETAT = photoconc[key];
                  delete photoconc[key];
                }
              }
            } else {
              photoconc = _.omit(photoconc, ["I0CLEUNIK"]);
            }

            photoconc = _.omit(photoconc, ["ABANDON_1"]);
            photoconc = _.omit(photoconc, ["ABANDON_2"]);
            photoconc = _.omit(photoconc, ["CONSTAT_TRAVAUX"]);
            photoconc = _.omit(photoconc, ["DATEAJOUT"]);
            photoconc = _.omit(photoconc, ["NOTES"]);
            photoconc = _.omit(photoconc, ["PHOTO"]);
            photoconc = _.omit(photoconc, ["PRINCIPAL"]);

            if (photoconc.MONUMENT_ETAT === this.state.last_photoconc_sauvegarde.MONUMENT_ETAT) {
              photoconc = _.omit(photoconc, ["MONUMENT_ETAT"]);
            }

            if (photoconc.SEMELLE_ETAT === this.state.last_photoconc_sauvegarde.SEMELLE_ETAT) {
              photoconc = _.omit(photoconc, ["SEMELLE_ETAT"]);
            }

            if (temporaire !== undefined) {
              var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, photoconc);

              temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;

              UpdateTemporaire(temporaire).catch((Err) => handleError(Err));
            } else {
              const temporaire = CreeTemporaire(
                this.IDCLIENT,
                this.ID_TABLETTE,
                this.ID_UTIL,
                `Modification de la photographie ${this.state.last_photoconc.IDPHOTOCONC}.jpg`,
                "PHOTOCONC",
                2,
                photoconc,
                this.state.last_photoconc_sauvegarde
              );

              this._AjoutTemporaire(temporaire);
            }
          });
        })
        .catch((Err) => handleError(Err));
    }

    if (this.props.id_concession !== undefined) {
      let changed = !_.isEqual(CONCESS, this._UpdateConcessData(this.state.CONCESS_SAUVEGARDE));
      let changed_photoconc = !_.isEqual(PHOTOCONC, PHOTOCONC_SAUVEGARDE);

      if (changed) {
        let diff = differenceObject(CONCESS, this._UpdateConcessData(this.state.CONCESS_SAUVEGARDE));

        diff = _.assign(
          {
            id: CONCESS.id,
            COCLEUNIK: CONCESS.COCLEUNIK,
          },
          diff
        );

        this._MiseAJourConcess(CONCESS, diff, changed_photoconc);
      }

      if (!changed && changed_photoconc) {
        this._MiseAJourPhotoconc(true);
      } else {
        this.setState({
          REDIRECT: this.state.procedure ? `/consulter/fiche-concession/${this.state.CONCESS.COCLEUNIK}/pa` : `/consulter/fiche-concession/${this.state.CONCESS.COCLEUNIK}`,
        });
      }
    } else {
      if (CONCESS.CONCESSIONTYPE !== 0 && CONCESS.DETAIL === 0) {
        this.champ_nature.current.focus();
      } else {
        this._AjoutConcess(CONCESS);
      }
    }
  };

  _listen_speech_recognition = (pv, refInput) => {
    const cursor = refInput.current.selectionStart;

    if (recognition !== undefined) {
      if (this.state.speechListening) {
        recognition.start();

        recognition.onend = () => {
          recognition.stop();

          this.setState({ speechListening: false });
        };
      } else {
        recognition.stop();
      }

      let CONCESS = this.state.concess;
      let finalPhrase = pv === "PV1" ? CONCESS.DIVERS : CONCESS.DIVERS2;

      recognition.onresult = function (event) {
        var interimPhrase = "";
        var result;
        var printcount;
        var lastPhrase = null;

        for (var i = 0; i < event.results.length; ++i) {
          result = event.results[i];

          if (result.isFinal) {
            if (refInput.current !== null) {
              finalPhrase = `${finalPhrase.substr(0, cursor).trim()} ${result[0].transcript} ${finalPhrase.substr(cursor).trim()}`;
            } else {
              finalPhrase = `${finalPhrase.trim()} ${result[0].transcript}`;
            }
          } else {
            interimPhrase = `${interimPhrase.trim()} ${result[0].transcript}`;
          }
        }

        if (!lastPhrase) {
          printcount = "";
          lastPhrase = `${"#" + printcount}`;
        }

        lastPhrase = `${finalPhrase.trim()} ${interimPhrase.trim()}`;

        if (finalPhrase.trim()) {
          lastPhrase = null;
        }

        if (lastPhrase == null) {
          if (pv === "PV1") {
            CONCESS.DIVERS = finalPhrase;
          } else {
            CONCESS.DIVERS2 = finalPhrase;
          }

          this.setState({ concess: CONCESS });
        }
      };

      recognition.onerror = (event) => {
        console.log("Erreur lors de la reconaissance vocale: " + event.error);
      };
    }
  };

  _listen_speech_recognition = (pv, refInput) => {
    let PHOTOCONC = this.state.PHOTOCONC;
    const cursor = refInput.current.selectionStart;

    PHOTOCONC = _.filter(PHOTOCONC, (p) => {
      if (pv === "PV1") {
        return p.ABANDON_1 === true && p.PHOTO !== "" && p.IDPHOTOCONC !== undefined;
      } else {
        return p.ABANDON_2 === true && p.PHOTO !== "" && p.IDPHOTOCONC !== undefined;
      }
    });

    if (PHOTOCONC.length > 0) {
      CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
        if (asRight) {
          if (recognition !== undefined) {
            if (this.state.SPEECH_LISTENING_PV1) {
              recognition.start();

              recognition.onend = () => {
                recognition.stop();

                this.setState({ SPEECH_LISTENING_PV1: false });
              };
            } else {
              recognition.stop();
            }

            if (this.state.SPEECH_LISTENING_PV2) {
              recognition.start();

              recognition.onend = () => {
                recognition.stop();

                this.setState({ SPEECH_LISTENING_PV2: false });
              };
            } else {
              recognition.stop();
            }

            let CONCESS = this.state.CONCESS;
            let finalPhrase = pv === "PV1" ? CONCESS.DIVERS : CONCESS.DIVERS2;

            recognition.onresult = function (event) {
              var interimPhrase = "";
              var result;
              var printcount;
              var lastPhrase = null;

              for (var i = 0; i < event.results.length; ++i) {
                result = event.results[i];

                if (result.isFinal) {
                  if (refInput.current !== null) {
                    finalPhrase = `${finalPhrase.substr(0, cursor).trim()} ${result[0].transcript} ${finalPhrase.substr(cursor).trim()}`;
                  } else {
                    finalPhrase = `${finalPhrase.trim()} ${result[0].transcript}`;
                  }
                } else {
                  interimPhrase = `${interimPhrase.trim()} ${result[0].transcript}`;
                }
              }

              if (!lastPhrase) {
                printcount = "";
                lastPhrase = `${"#" + printcount}`;
              }

              lastPhrase = `${finalPhrase.trim()} ${interimPhrase.trim()}`;

              if (finalPhrase.trim()) {
                lastPhrase = null;
              }

              if (lastPhrase == null) {
                if (pv === "PV1") {
                  CONCESS.DIVERS = finalPhrase;
                } else {
                  CONCESS.DIVERS2 = finalPhrase;
                }

                this.setState({ concess: CONCESS });
              }
            };

            recognition.onerror = (event) => {
              console.log("Erreur lors de la reconaissance vocale: " + event.error);
            };
          }
        } else {
          if (this.state.SPEECH_LISTENING_PV1) this.setState({ SPEECH_LISTENING_PV1: false });
          if (this.state.SPEECH_LISTENING_PV2) this.setState({ SPEECH_LISTENING_PV2: false });

          this.setState({ droit_util_modif_photoconc: true });
        }
      });
    } else {
      CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
        if (asRight) {
          if (recognition !== undefined) {
            if (this.state.SPEECH_LISTENING_PV1) {
              recognition.start();

              recognition.onend = () => {
                recognition.stop();

                this.setState({ SPEECH_LISTENING_PV1: false });
              };
            } else {
              recognition.stop();
            }

            if (this.state.SPEECH_LISTENING_PV2) {
              recognition.start();

              recognition.onend = () => {
                recognition.stop();

                this.setState({ SPEECH_LISTENING_PV2: false });
              };
            } else {
              recognition.stop();
            }

            let CONCESS = this.state.CONCESS;
            let finalPhrase = pv === "PV1" ? CONCESS.DIVERS : CONCESS.DIVERS2;

            recognition.onresult = function (event) {
              var interimPhrase = "";
              var result;
              var printcount;
              var lastPhrase = null;

              for (var i = 0; i < event.results.length; ++i) {
                result = event.results[i];

                if (result.isFinal) {
                  if (refInput.current !== null) {
                    finalPhrase = `${finalPhrase.substr(0, cursor).trim()} ${result[0].transcript} ${finalPhrase.substr(cursor).trim()}`;
                  } else {
                    finalPhrase = `${finalPhrase.trim()} ${result[0].transcript}`;
                  }
                } else {
                  interimPhrase = `${interimPhrase.trim()} ${result[0].transcript}`;
                }
              }

              if (!lastPhrase) {
                printcount = "";
                lastPhrase = `${"#" + printcount}`;
              }

              lastPhrase = `${finalPhrase.trim()} ${interimPhrase.trim()}`;

              if (finalPhrase.trim()) {
                lastPhrase = null;
              }

              if (lastPhrase == null) {
                if (pv === "PV1") {
                  CONCESS.DIVERS = finalPhrase;
                } else {
                  CONCESS.DIVERS2 = finalPhrase;
                }

                this.setState({ concess: CONCESS });
              }
            };

            recognition.onerror = (event) => {
              console.log("Erreur lors de la reconaissance vocale: " + event.error);
            };
          }
        } else {
          if (this.state.SPEECH_LISTENING_PV1) this.setState({ SPEECH_LISTENING_PV1: false });
          if (this.state.SPEECH_LISTENING_PV2) this.setState({ SPEECH_LISTENING_PV2: false });

          this.setState({ droit_util_ajout_photoconc: true });
        }
      });
    }
  };

  _replace_photo = (file, type, tableauPhotoconc) => {
    ResizeImage(file).then((blob) => {
      const photoconc = {
        ABANDON_1: type === "ABANDON_1",
        ABANDON_2: type === "ABANDON_2",
        COCLEUNIK: this.state.CONCESS.COCLEUNIK,
        CONSTAT_TRAVAUX: 0,
        DATEAJOUT: new Date(Date.now()).toISOString().slice(0, -5),
        I0CLEUNIK: null,
        MONUMENT_ETAT: "",
        NOTES: "",
        PHOTO: blob,
        PRINCIPAL: 0,
        SEMELLE_ETAT: "",
        MONUMENT_GAUCHE: "",
        MONUMENT_DROITE: "",
        MONUMENT_DEVANT: "",
        MONUMENT_DERRIERE: "",
        AGENT: "",
        SIGNATURE_AGENT: null,
        SOCIETE: "",
        SIGNATURE_SOCIETE: null,
      };

      tableauPhotoconc = [...tableauPhotoconc, photoconc];

      this.setState({
        PHOTOCONC: tableauPhotoconc,
      });
    });
  };

  _handle_change_photo_constat = (type) => (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    const tableauPhotoconc = this.state.PHOTOCONC;

    this._replace_photo(file, type, tableauPhotoconc);
  };

  _handle_change_nature = (event) => {
    event.preventDefault();

    const lieu = this.state.LIEU;
    const TYPETB = _.cloneDeep(this.state.TYPETB_SAUVEGARDE);

    const pa = _.filter(this.state.PA, function (p) {
      return p.CODECIME === lieu.CIMEL && p.CONCESSIONTYPE === parseInt(event.target.value) && p.CODENOMBRE === lieu.NOMBREL;
    });

    const numtbs = _.uniq(_.map(pa, "CODETYPE"));
    const typetb = _.filter(TYPETB, function (p) {
      return _.includes(numtbs, p.NUMTB);
    });

    const CONCESS = _.cloneDeep(this.state.CONCESS);
    CONCESS.CONCESSIONTYPE = event.target.value !== "" ? parseInt(event.target.value) : 0;
    CONCESS.NOMBRE = lieu.NOMBREL;
    CONCESS.DETAIL = lieu.TOMBEL;

    this.setState({ CONCESS: CONCESS, TYPETB: typetb });
  };

  zoomOnImage = (photo) => (event) => {
    event.preventDefault();

    this.setState({ photoDialogOpen: true, photoDialogPhoto: photo });
  };

  _handle_click_photographies_referente = (photo) => (event) => {
    event.preventDefault();

    CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
      if (asRight) {
        let photos = [...this.state.PHOTOCONC];

        GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", photo.IDPHOTOCONC).then((temporaire) => {
          if (temporaire !== undefined) {
            if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
              this.setState({
                photoconc: photos,
                dialog_traitement: true,
              });
            } else {
              for (let index = 0; index < photos.length; index++) {
                const element = photos[index];

                if (photo === element) {
                  element.PRINCIPAL = element.PRINCIPAL === 1 ? 0 : 1;
                } else {
                  element.PRINCIPAL = 0;
                }
              }

              this.setState({ PHOTOCONC: photos, disabledForm: false });
            }
          } else {
            for (let index = 0; index < photos.length; index++) {
              const element = photos[index];

              if (photo === element) {
                element.PRINCIPAL = element.PRINCIPAL === 1 ? 0 : 1;
              } else {
                element.PRINCIPAL = 0;
              }
            }

            this.setState({ PHOTOCONC: photos, disabledForm: false });
          }
        });
      } else {
        this.setState({ droit_util_modif_photoconc: true });
      }
    });
  };

  _find_color = (modifie, idphotoconc) => {
    const temp = _.find(modifie, ["IDPHOTOCONC", idphotoconc]) || _.find(modifie, ["IDEVOL_ETAT", idphotoconc]);

    let color = [];
    for (var key in temp) {
      if (typeof temp[key] === "string") {
        if (!_.includes(color, temp[key])) color.push(temp[key]);
      }
    }

    if (_.includes(color, "red")) return "red";
    if (_.includes(color, "blue")) return "blue";
    if (_.includes(color, "green")) return "green";

    return "grey";
  };

  _handle_change_photoconc_etat_semelle = (event, newValue) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.last_photoconc;

      if (photoconc !== undefined) {
        if (photoconc.NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              photoconc.SEMELLE_ETAT = newValue.SEMELLE_ETAT;

              this.setState({ last_photoconc: photoconc });
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              photoconc.SEMELLE_ETAT = newValue.SEMELLE_ETAT;

              this.setState({ last_photoconc: photoconc });
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_inputchange_photoconc_etat_semelle = (event, newValue, refInput) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.last_photoconc;
      let cursor;
      if (event.target !== undefined) cursor = event.target.selectionStart;

      if (photoconc !== undefined) {
        if (photoconc.NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo2, (ppc) => {
                return ppc.SEMELLE_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc.SEMELLE_ETAT = result[0].SEMELLE_ETAT;

                this.setState({ last_photoconc: photoconc });
              } else {
                if (event !== null) photoconc.SEMELLE_ETAT = newValue;

                this.setState({ last_photoconc: photoconc }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo2, (ppc) => {
                return ppc.SEMELLE_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc.SEMELLE_ETAT = result[0].SEMELLE_ETAT;

                this.setState({ last_photoconc: photoconc });
              } else {
                if (event !== null) photoconc.SEMELLE_ETAT = newValue;

                this.setState({ last_photoconc: photoconc }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_change_photoconc_etat_semelle_strasbourg = (event) => {
    event.preventDefault();

    const photoconc = this.state.last_photoconc;
    const value = event.target.value;

    if (photoconc !== undefined) {
      if (photoconc.NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            photoconc.SEMELLE_ETAT = value;

            this.setState({ last_photoconc: photoconc });
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            photoconc.SEMELLE_ETAT = value;

            this.setState({ last_photoconc: photoconc });
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _handle_change_photoconc_etat_monument = (event, newValue) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.last_photoconc;

      if (photoconc !== undefined) {
        if (photoconc.NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              photoconc.MONUMENT_ETAT = newValue.MONUMENT_ETAT;

              this.setState({ last_photoconc: photoconc });
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              photoconc.MONUMENT_ETAT = newValue.MONUMENT_ETAT;

              this.setState({ last_photoconc: photoconc });
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_inputchange_photoconc_etat_monument = (event, newValue, refInput) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.last_photoconc;
      let cursor;
      if (event.target !== undefined) cursor = event.target.selectionStart;

      if (photoconc !== undefined) {
        if (photoconc.NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo, (ppc) => {
                return ppc.MONUMENT_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc.MONUMENT_ETAT = result[0].MONUMENT_ETAT;

                this.setState({ last_photoconc: photoconc });
              } else {
                if (event !== null) photoconc.MONUMENT_ETAT = newValue;

                this.setState({ last_photoconc: photoconc }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo, (ppc) => {
                return ppc.MONUMENT_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc.MONUMENT_ETAT = result[0].MONUMENT_ETAT;

                this.setState({ last_photoconc: photoconc });
              } else {
                if (event !== null) photoconc.MONUMENT_ETAT = newValue;

                this.setState({ last_photoconc: photoconc }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_change_photoconc_etat_monument_strasbourg = (event) => {
    event.preventDefault();

    const photoconc = this.state.last_photoconc;
    const value = event.target.value;

    if (photoconc !== undefined) {
      if (photoconc.NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            photoconc.MONUMENT_ETAT = value;

            this.setState({ last_photoconc: photoconc });
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            photoconc.MONUMENT_ETAT = value;

            this.setState({ last_photoconc: photoconc });
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  render() {
    console.log("RENDER M_CONCESSION");

    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <form onSubmit={this._handle_submit_form_concess}>
              <TextField
                inputRef={this.famille_ref}
                required
                fullWidth
                disabled={this.props.procedure !== undefined}
                margin="normal"
                label="Famille"
                value={this.state.CONCESS.FAMILLE}
                onChange={this._handle_change_concess("FAMILLE", this.famille_ref)}
              />

              {this.props.id_concession !== undefined && (this.state.TemporaireNonEnvoye > 1 || !this.state.estTemporaireNonEnvoye) ? (
                <TextField fullWidth disabled margin="normal" label="N° d'ordre" value={this.state.CONCESS.ANCNUM} onChange={this._handle_change_concess("ANCNUM")} />
              ) : null}

              <TextField
                select
                fullWidth
                disabled={this.props.id_concession !== undefined || this.props.id_lieu !== undefined}
                margin="normal"
                label="Cimetière"
                value={this.state.CONCESS.TYPECIME !== 0 ? this.state.CONCESS.TYPECIME : ""}
                onChange={this._handle_change_combo_cimetier}
              >
                {this.state.CIMETIER.map((cimetier, index) => (
                  <MenuItem key={index} value={cimetier.NUMCIME}>
                    {cimetier.NOMCIME}
                  </MenuItem>
                ))}
              </TextField>

              {this.state.LIEU.EMPLACEMENT.CARRE.includes("Provisoire") || this.state.LIEU.EMPLACEMENT.CARRE.includes("Non affect") ? (
                <TextField
                  fullWidth
                  disabled
                  margin="normal"
                  label={this.state.param.LIBELLEANCIENNUMPLAN !== "" ? this.state.param.LIBELLEANCIENNUMPLAN : "Emplacement"}
                  value={this.state.CONCESS.EMPL}
                  onChange={this._handle_change_concess("EMPL")}
                />
              ) : (
                <div>
                  <TextField
                    select
                    fullWidth
                    disabled={this.props.id_concession !== undefined || this.props.id_lieu !== undefined}
                    margin="normal"
                    label={this.state.LIEU.EMPLACEMENT.TYPECAR}
                    value={this.state.LIEU.EMPLACEMENT.CARRE !== "" ? this.state.LIEU.EMPLACEMENT.CARRE : " "}
                    onChange={this._handle_change_combo_square}
                  >
                    {this.state.SQUARE.map((square, index) => (
                      <MenuItem key={index} value={square} style={styles.menuitem}>
                        {square}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    fullWidth
                    disabled={this.props.id_concession !== undefined || this.props.id_lieu !== undefined}
                    margin="normal"
                    label={this.state.LIEU.EMPLACEMENT.TYPERG}
                    value={this.state.LIEU.EMPLACEMENT.RANG !== "" ? this.state.LIEU.EMPLACEMENT.RANG : " "}
                    onChange={this._handle_change_combo_rank}
                  >
                    {this.state.RANK.map((rank, index) => (
                      <MenuItem key={index} value={rank} style={styles.menuitem}>
                        {rank}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    fullWidth
                    disabled={this.props.id_concession !== undefined || this.props.id_lieu !== undefined}
                    margin="normal"
                    label={this.state.LIEU.EMPLACEMENT.TYPEFOS}
                    value={this.state.LIEU.EMPLACEMENT.TOMBE !== "" ? this.state.LIEU.EMPLACEMENT.TOMBE : " "}
                    onChange={this._handle_change_combo_tomb}
                  >
                    {this.state.TOMB.map((tomb, index) => (
                      <MenuItem key={index} value={tomb} style={styles.menuitem}>
                        {tomb}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}

              {this.props.id_lieu === undefined && this.props.id_concession !== undefined ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                  <KeyboardDatePicker
                    fullWidth
                    disabled={this.props.id_concession !== undefined}
                    margin="normal"
                    minDate="01.01.1800"
                    minDateMessage="La date ne peut être inférieure au 1er Janvier 1800"
                    maxDateMessage="La date n'est pas valide"
                    invalidDateMessage="Format de date invalide"
                    label="Date de prise (JJ/MM/AAAA)"
                    format="dd/MM/yyyy"
                    inputProps={{
                      pattern: "(^(?:((?:0[1-9]|1[0-9]|2[0-9])/(?:0[1-9]|1[0-2])|(?:30)/(?!02)(?:0[1-9]|1[0-2])|31/(?:0[13578]|1[02]))/(?:18|19|20)[0-9]{2})$)",
                    }}
                    value={this.state.CONCESS.MADATE !== "" ? this._cree_chaine_date(this.state.CONCESS.MADATE) : null}
                    onChange={this._handle_change_date_concess("MADATE")}
                    animateYearScrolling
                    cancelLabel="annuler"
                  />
                </MuiPickersUtilsProvider>
              ) : null}

              {this.props.id_lieu === undefined && this.props.id_concession !== undefined ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                  <KeyboardDatePicker
                    fullWidth
                    disabled={this.props.id_concession !== undefined}
                    margin="normal"
                    minDate="01.01.1800"
                    minDateMessage="La date ne peut être inférieure au 1er Janvier 1800"
                    maxDateMessage="La date n'est pas valide"
                    invalidDateMessage="Format de date invalide"
                    label="Date d'expiration (JJ/MM/AAAA)"
                    format="dd/MM/yyyy"
                    inputProps={{
                      pattern: "(^(?:((?:0[1-9]|1[0-9]|2[0-9])/(?:0[1-9]|1[0-2])|(?:30)/(?!02)(?:0[1-9]|1[0-2])|31/(?:0[13578]|1[02]))/(?:18|19|20)[0-9]{2})$)",
                    }}
                    maxDate={new Date(4100, 0, 1)}
                    value={this.state.CONCESS.DATEEXPIRA !== "" ? this._cree_chaine_date(this.state.CONCESS.DATEEXPIRA) : null}
                    onChange={this._handle_change_date_concess("DATEEXPIRA")}
                    animateYearScrolling
                    cancelLabel="annuler"
                  />
                </MuiPickersUtilsProvider>
              ) : null}

              {this.state.IS_ORACLE ? (
                <TextField
                  select
                  disabled={this.props.id_concession !== undefined}
                  inputRef={this.champ_nature}
                  fullWidth
                  margin="normal"
                  label="Nature"
                  value={this.state.CONCESS.CONCESSIONTYPE !== 0 ? this.state.CONCESS.CONCESSIONTYPE : ""}
                  onChange={this._handle_change_nature}
                >
                  <MenuItem value="" style={{ minHeight: 36 }} />
                  <MenuItem value="1">Concession de terrain</MenuItem>
                  <MenuItem value="2">Concession columbarium</MenuItem>
                  <MenuItem value="3">Terrain commun</MenuItem>
                  <MenuItem value="4">Militaire</MenuItem>
                  <MenuItem value="5">Victime civile</MenuItem>
                </TextField>
              ) : null}

              {this.state.TYPETB.length > 0 ? (
                <TextField select fullWidth margin="normal" label="Genre" value={this.state.CONCESS.DETAIL} onChange={this._handle_change_concess("DETAIL")}>
                  {this.state.TYPETB.map((typetb, index) => (
                    <MenuItem key={index} value={typetb.NUMTB}>
                      {typetb.NOMTOMBE}
                    </MenuItem>
                  ))}
                </TextField>
              ) : null}

              {this.state.DUREE.length > 0 && this.props.id_lieu === undefined && this.props.id_concession !== undefined ? (
                <TextField
                  select
                  fullWidth
                  disabled={this.props.id_concession !== undefined}
                  margin="normal"
                  label="Durée"
                  value={this.state.CONCESS.DUREE !== 0 ? this.state.CONCESS.DUREE : ""}
                  onChange={this._handle_change_concess("DUREE")}
                >
                  {this.state.DUREE.map((duree, index) => (
                    <MenuItem key={index} value={duree.NUMDURE}>
                      {duree.LIBDURE}
                    </MenuItem>
                  ))}
                </TextField>
              ) : null}

              <TextField
                fullWidth
                margin="normal"
                autoCapitalize="off"
                autoCorrect="off"
                label="Superficie (m²)"
                inputProps={{ maxLength: 5 }}
                value={this.state.CONCESS.SUPERFICIE === 0 ? "" : this.state.CONCESS.SUPERFICIE}
                onChange={this._handle_change_concess("SUPERFICIE")}
              />

              {this.state.IS_ORACLE ? (
                <TextField
                  select
                  fullWidth
                  margin="normal"
                  label="Etat d'échéance"
                  value={this.state.CONCESS.IDCONCESS_ETAT_ECHEANCE === 0 ? "" : this.state.CONCESS.IDCONCESS_ETAT_ECHEANCE}
                  onChange={this._handle_change_concess("IDCONCESS_ETAT_ECHEANCE")}
                >
                  <MenuItem value="" style={{ minHeight: 36 }} />
                  {this.state.CONCESS_ETAT_ECHEANCE.map((cee, index) => (
                    <MenuItem key={index} value={cee.IDCONCESS_ETAT_ECHEANCE}>
                      {cee.LIBELLE}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <FormControl component="fieldset" margin="normal" style={{ userSelect: "none" }}>
                  <FormGroup>
                    <FormControlLabel
                      label="A reprendre"
                      disabled={this.props.procedure !== undefined}
                      control={<Checkbox checked={this.state.CONCESS.A_REPRENDRE === 1} value={this.state.CONCESS.A_REPRENDRE === 1} onChange={this._handle_change_concess("A_REPRENDRE")} />}
                    />
                  </FormGroup>
                </FormControl>
              )}

              {!this.state.IS_ORACLE ? (
                <FormControl component="fieldset" margin="normal" style={{ userSelect: "none" }}>
                  <FormGroup>
                    <FormControlLabel
                      label="Pose plaquette"
                      disabled={this.props.procedure !== undefined}
                      control={<Checkbox checked={this.state.CONCESS.POSE_PLAQUE === 1} value={this.state.CONCESS.POSE_PLAQUE === 1} onChange={this._handle_change_concess("POSE_PLAQUE")} />}
                    />
                  </FormGroup>
                </FormControl>
              ) : null}

              {this.state.IS_ORACLE ? (
                <FormControl component="fieldset" margin="normal" style={{ userSelect: "none" }}>
                  <FormGroup>
                    <FormControlLabel
                      label="Exhumations réalisées"
                      disabled={this.props.procedure !== undefined}
                      control={
                        <Checkbox checked={this.state.CONCESS.EXH_ADMIN_REALISE === 1} value={this.state.CONCESS.EXH_ADMIN_REALISE === 1} onChange={this._handle_change_concess("EXH_ADMIN_REALISE")} />
                      }
                    />
                  </FormGroup>
                </FormControl>
              ) : null}

              {this.state.IS_ORACLE ? (
                <FormControl component="fieldset" margin="normal" style={{ userSelect: "none" }}>
                  <FormGroup>
                    <FormControlLabel
                      label="Monument enlevé"
                      disabled={this.props.procedure !== undefined}
                      control={
                        <Checkbox checked={this.state.CONCESS.MONUMENT_ENLEVE === 1} value={this.state.CONCESS.MONUMENT_ENLEVE === 1} onChange={this._handle_change_concess("MONUMENT_ENLEVE")} />
                      }
                    />
                  </FormGroup>
                </FormControl>
              ) : null}

              {this.state.IS_ORACLE ? (
                this.state.last_photoconc !== undefined && this.state.last_photoconc !== "" ? (
                  this.state.cas_strasbourg ? (
                    <TextField
                      select
                      fullWidth
                      disabled={this.state.photoEnvoye}
                      margin="normal"
                      label="Etat du monument"
                      value={this.state.last_photoconc.MONUMENT_ETAT || ""}
                      onChange={this._handle_change_photoconc_etat_monument_strasbourg}
                    >
                      <MenuItem value="" style={{ minHeight: 36 }} />
                      {this.state.photoconc_pour_combo.map((item, index) => (
                        <MenuItem key={index} value={item.MONUMENT_ETAT}>
                          {item.MONUMENT_ETAT}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Autocomplete
                      fullWidth
                      freeSolo
                      disabled={this.state.photoEnvoye}
                      disableClearable
                      openOnFocus
                      options={this.state.photoconc_pour_combo}
                      getOptionLabel={(option) => option.MONUMENT_ETAT || ""}
                      value={this.state.last_photoconc.MONUMENT_ETAT || ""}
                      inputValue={this.state.last_photoconc.MONUMENT_ETAT || ""}
                      onChange={(event, newValue) => this._handle_change_photoconc_etat_monument(event, newValue)}
                      onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_monument(event, newValue, this.textfield_monument)}
                      renderInput={(params) => <TextField {...params} ref={this.textfield_monument} margin="normal" label="Etat du monument" />}
                    />
                  )
                ) : (
                  <div>
                    <Typography variant="body2">
                      <i>{`Pour ajouter un état du monument ou un ${
                        this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE !== undefined ? this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE.toLowerCase() : "Etat de la semelle"
                      }, il est nécessaire d’ajouter une photographie`}</i>
                    </Typography>
                  </div>
                )
              ) : null}

              {this.state.IS_ORACLE ? (
                this.state.last_photoconc !== undefined && this.state.last_photoconc !== "" ? (
                  this.state.cas_strasbourg ? (
                    <TextField
                      select
                      fullWidth
                      disabled={this.state.photoEnvoye}
                      margin="normal"
                      label={this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}
                      value={this.state.last_photoconc.SEMELLE_ETAT || ""}
                      onChange={this._handle_change_photoconc_etat_semelle_strasbourg}
                    >
                      <MenuItem value="" style={{ minHeight: 36 }} />
                      {this.state.photoconc_pour_combo2.map((item, index) => (
                        <MenuItem key={index} value={item.SEMELLE_ETAT}>
                          {item.SEMELLE_ETAT}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Autocomplete
                      fullWidth
                      freeSolo
                      disabled={this.state.photoEnvoye}
                      disableClearable
                      openOnFocus
                      options={this.state.photoconc_pour_combo2}
                      getOptionLabel={(option) => option.SEMELLE_ETAT || ""}
                      value={this.state.last_photoconc.SEMELLE_ETAT || ""}
                      inputValue={this.state.last_photoconc.SEMELLE_ETAT || ""}
                      onChange={(event, newValue) => this._handle_change_photoconc_etat_semelle(event, newValue)}
                      onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_semelle(event, newValue, this.textfield_semelle)}
                      renderInput={(params) => <TextField {...params} ref={this.textfield_semelle} margin="normal" label={this.state.param.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"} />}
                    />
                  )
                ) : null
              ) : null}

              {this.state.CONCESS.IDPROCEDUREABANDON !== 0 ? (
                <div style={styles.div}>
                  <Typography variant="body1">Procédure d'abandon</Typography>

                  {width === "xs" || width === "sm" ? (
                    <div>
                      <Table style={styles.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={styles.tablecells}>
                              <Typography variant="body2">
                                1<sup>er</sup> constat d'abandon
                              </Typography>

                              <input ref={this.ABANDON_1B_ref} type="file" accept="image/*;capture=camera" style={{ display: "none" }} onChange={this._handle_change_photo_constat("ABANDON_1")} />

                              <IconButton
                                component="span"
                                onClick={() => {
                                  CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
                                    if (asRight) {
                                      this.ABANDON_1B_ref.current.click();
                                    } else {
                                      this.setState({ droit_util_ajout_photoconc: true });
                                    }
                                  });
                                }}
                              >
                                <PhotoCamera />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                ...styles.tablecells,
                                ...{ flexWrap: "wrap" },
                              }}
                            >
                              {_.filter(this.state.PHOTOCONC, (photos) => {
                                return photos.ABANDON_1;
                              }).length === 0 ? (
                                <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.ABANDON_1B_ref.current.click()}>
                                  <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                    <Typography variant="body2">&nbsp;</Typography>
                                  </CardActions>

                                  <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                                </Card>
                              ) : (
                                _.filter(this.state.PHOTOCONC, (photos) => {
                                  return photos.ABANDON_1;
                                }).map((photo, index) =>
                                  photo.PHOTO !== "" ? (
                                    <Card
                                      key={index}
                                      style={{
                                        ...styles.photoconc_card,
                                        border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                                      }}
                                    >
                                      <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                          <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                            <StarRate />
                                          </IconButton>

                                          <div
                                            style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                            onClick={this._handle_click_photographies_referente(photo)}
                                          >{`Référente`}</div>
                                        </div>

                                        <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>
                                      </CardActions>

                                      <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                                    </Card>
                                  ) : null
                                )
                              )}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell style={styles.tablecells}>
                              <TextField
                                inputRef={this.concessdivers_ref_1}
                                fullWidth
                                multiline
                                margin="normal"
                                label="Descriptif de l'état du monument"
                                rows={1}
                                rowsMax={4}
                                value={this.state.CONCESS.DIVERS}
                                onChange={this._handle_change_concess("DIVERS", this.concessdivers_ref_1)}
                                InputProps={{
                                  endAdornment:
                                    SpeechRecognition !== undefined ? (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="speech_recognition"
                                          onClick={() => {
                                            this.setState(
                                              {
                                                SPEECH_LISTENING_PV1: !this.state.SPEECH_LISTENING_PV1,
                                              },
                                              () => {
                                                this._listen_speech_recognition("PV1", this.concessdivers_ref_1);
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.SPEECH_LISTENING_PV1 ? <Done /> : <Mic />}
                                        </IconButton>
                                      </InputAdornment>
                                    ) : null,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>

                      <Table style={styles.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={styles.tablecells}>
                              <Typography variant="body2">
                                2<sup>ème</sup> constat d'abandon
                              </Typography>

                              <input ref={this.ABANDON_2B_ref} type="file" accept="image/*;capture=camera" style={{ display: "none" }} onChange={this._handle_change_photo_constat("ABANDON_2")} />

                              <IconButton
                                component="span"
                                onClick={() => {
                                  CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
                                    if (asRight) {
                                      this.ABANDON_2B_ref.current.click();
                                    } else {
                                      this.setState({ droit_util_ajout_photoconc: true });
                                    }
                                  });
                                }}
                              >
                                <PhotoCamera />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                ...styles.tablecells,
                                ...{ flexWrap: "wrap" },
                              }}
                            >
                              {_.filter(this.state.PHOTOCONC, (photos) => {
                                return photos.ABANDON_2;
                              }).length === 0 ? (
                                <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.ABANDON_2B_ref.current.click()}>
                                  <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                    <Typography variant="body2">&nbsp;</Typography>
                                  </CardActions>

                                  <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                                </Card>
                              ) : (
                                _.filter(this.state.PHOTOCONC, (photos) => {
                                  return photos.ABANDON_2;
                                }).map((photo, index) =>
                                  photo.PHOTO !== "" ? (
                                    <Card
                                      key={index}
                                      style={{
                                        ...styles.photoconc_card,
                                        border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                                      }}
                                    >
                                      <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                          <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                            <StarRate />
                                          </IconButton>

                                          <div
                                            style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                            onClick={this._handle_click_photographies_referente(photo)}
                                          >{`Référente`}</div>
                                        </div>

                                        <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>
                                      </CardActions>

                                      <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                                    </Card>
                                  ) : null
                                )
                              )}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell style={styles.tablecells}>
                              <TextField
                                inputRef={this.concessdivers2_ref_1}
                                fullWidth
                                multiline
                                margin="normal"
                                label="Descriptif de l'état du monument"
                                rows={1}
                                rowsMax={4}
                                value={this.state.CONCESS.DIVERS2}
                                onChange={this._handle_change_concess("DIVERS2", this.concessdivers2_ref_1)}
                                InputProps={{
                                  endAdornment:
                                    SpeechRecognition !== undefined ? (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="speech_recognition"
                                          onClick={() => {
                                            this.setState(
                                              {
                                                SPEECH_LISTENING_PV2: !this.state.SPEECH_LISTENING_PV2,
                                              },
                                              () => {
                                                this._listen_speech_recognition("PV2", this.concessdivers2_ref_1);
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.SPEECH_LISTENING_PV2 ? <Done /> : <Mic />}
                                        </IconButton>
                                      </InputAdornment>
                                    ) : null,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <Table style={styles.table}>
                      <TableHead>
                        <TableRow style={{ display: "flex" }}>
                          <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                            <Typography variant="body2">
                              1<sup>er</sup> constat d'abandon
                            </Typography>

                            <input ref={this.ABANDON_1_ref} type="file" accept="image/*;capture=camera" style={{ display: "none" }} onChange={this._handle_change_photo_constat("ABANDON_1")} />

                            <IconButton
                              component="span"
                              onClick={() => {
                                CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
                                  if (asRight) {
                                    this.ABANDON_1_ref.current.click();
                                  } else {
                                    this.setState({ droit_util_ajout_photoconc: true });
                                  }
                                });
                              }}
                            >
                              <PhotoCamera />
                            </IconButton>
                          </TableCell>

                          <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                            <Typography variant="body2">
                              2<sup>ème</sup> constat d'abandon
                            </Typography>

                            <input ref={this.ABANDON_2_ref} type="file" accept="image/*;capture=camera" style={{ display: "none" }} onChange={this._handle_change_photo_constat("ABANDON_2")} />

                            <IconButton
                              component="span"
                              onClick={() => {
                                CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
                                  if (asRight) {
                                    this.ABANDON_2_ref.current.click();
                                  } else {
                                    this.setState({ droit_util_ajout_photoconc: true });
                                  }
                                });
                              }}
                            >
                              <PhotoCamera />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow style={{ display: "flex" }}>
                          <TableCell
                            style={{
                              ...styles.tablecells,
                              ...{ flex: 1, flexWrap: "wrap" },
                            }}
                          >
                            {_.filter(this.state.PHOTOCONC, (photos) => {
                              return photos.ABANDON_1;
                            }).length === 0 ? (
                              <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.ABANDON_1_ref.current.click()}>
                                <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                  <Typography variant="body2">&nbsp;</Typography>
                                </CardActions>

                                <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                              </Card>
                            ) : (
                              _.filter(this.state.PHOTOCONC, (photos) => {
                                return photos.ABANDON_1;
                              }).map((photo, index) =>
                                photo.PHOTO !== "" ? (
                                  <Card
                                    key={index}
                                    style={{
                                      ...styles.photoconc_card,
                                      border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                                    }}
                                  >
                                    <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                          <StarRate />
                                        </IconButton>

                                        <div
                                          style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                          onClick={this._handle_click_photographies_referente(photo)}
                                        >{`Référente`}</div>
                                      </div>

                                      <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>
                                    </CardActions>

                                    <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                                  </Card>
                                ) : null
                              )
                            )}
                          </TableCell>

                          <TableCell
                            style={{
                              ...styles.tablecells,
                              ...{ flex: 1, flexWrap: "wrap" },
                            }}
                          >
                            {_.filter(this.state.PHOTOCONC, (photos) => {
                              return photos.ABANDON_2;
                            }).length === 0 ? (
                              <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.ABANDON_2_ref.current.click()}>
                                <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                  <Typography variant="body2">&nbsp;</Typography>
                                </CardActions>

                                <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                              </Card>
                            ) : (
                              _.filter(this.state.PHOTOCONC, (photos) => {
                                return photos.ABANDON_2;
                              }).map((photo, index) =>
                                photo.PHOTO !== "" ? (
                                  <Card
                                    key={index}
                                    style={{
                                      ...styles.photoconc_card,
                                      border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                                    }}
                                  >
                                    <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                          <StarRate />
                                        </IconButton>

                                        <div
                                          style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                          onClick={this._handle_click_photographies_referente(photo)}
                                        >{`Référente`}</div>
                                      </div>

                                      <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>
                                    </CardActions>

                                    <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                                  </Card>
                                ) : null
                              )
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow style={{ display: "flex" }}>
                          <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                            <TextField
                              inputRef={this.concessdivers_ref_2}
                              fullWidth
                              multiline
                              margin="normal"
                              label="Descriptif de l'état du monument"
                              rows={1}
                              rowsMax={4}
                              value={this.state.CONCESS.DIVERS}
                              onChange={this._handle_change_concess("DIVERS", this.concessdivers_ref_2)}
                              InputProps={{
                                endAdornment:
                                  SpeechRecognition !== undefined ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="speech_recognition"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              SPEECH_LISTENING_PV1: !this.state.SPEECH_LISTENING_PV1,
                                            },
                                            () => {
                                              this._listen_speech_recognition("PV1", this.concessdivers_ref_2);
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.SPEECH_LISTENING_PV1 ? <Done /> : <Mic />}
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null,
                              }}
                            />
                          </TableCell>

                          <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                            <TextField
                              inputRef={this.concessdivers2_ref_2}
                              fullWidth
                              multiline
                              margin="normal"
                              label="Descriptif de l'état du monument"
                              rows={1}
                              rowsMax={4}
                              value={this.state.CONCESS.DIVERS2}
                              onChange={this._handle_change_concess("DIVERS2", this.concessdivers2_ref_2)}
                              InputProps={{
                                endAdornment:
                                  SpeechRecognition !== undefined ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="speech_recognition"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              SPEECH_LISTENING_PV2: !this.state.SPEECH_LISTENING_PV2,
                                            },
                                            () => {
                                              this._listen_speech_recognition("PV2", this.concessdivers2_ref_2);
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.SPEECH_LISTENING_PV2 ? <Done /> : <Mic />}
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </div>
              ) : null}

              <div style={styles.submit_div}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Annuler
                </Button>

                <Button variant="contained" color="primary" type="submit">
                  Enregistrer
                </Button>
              </div>
            </form>

            <Dialog open={this.state.dialog_traitement} onClose={() => this.setState({ dialog_traitement: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle onClose={() => this.setState({ dialog_traitement: false })} id="alert-dialog-title">
                <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_traitement: false })}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ marginTop: 12 }} id="alert-dialog-description">
                  Modification impossible
                  <br />
                  Photographie en cours de traitement dans Gescime
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog
              open={this.state.dialog_plus_en_vigueur}
              onClose={() => this.setState({ dialog_plus_en_vigueur: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle onClose={() => this.setState({ dialog_plus_en_vigueur: false })} id="alert-dialog-title">
                <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_plus_en_vigueur: false })}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ marginTop: 12 }} id="alert-dialog-description">
                  {this.state.dialog_plus_en_vigueur_message}
                </DialogContentText>
              </DialogContent>
            </Dialog>

            {this.state.photoDialogPhoto !== null ? (
              <Dialog
                fullScreen
                open={this.state.photoDialogOpen}
                onClose={() => {
                  this.setState({ photoDialogOpen: false });
                }}
                TransitionComponent={Transition}
              >
                <AppBar className={styles.appBar}>
                  <Toolbar>
                    <Typography variant="h6" style={styles.typography}></Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        this.setState({ photoDialogOpen: false });
                      }}
                      aria-label="close"
                    >
                      <Close />
                    </IconButton>
                  </Toolbar>
                </AppBar>

                <Card style={styles.photoconc_card_dialog} elevation={0}>
                  <CardMedia style={styles.photoconc_cardmedia_dialog} image={window.URL.createObjectURL(this.state.photoDialogPhoto)} />
                </Card>
              </Dialog>
            ) : null}

            <DialogAccessRight state={this.state.droit_util_ajout_photoconc} onChange={(value) => this.setState({ droit_util_ajout_photoconc: value })} />
            <DialogAccessRight state={this.state.droit_util_modif_photoconc} onChange={(value) => this.setState({ droit_util_modif_photoconc: value })} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withWidth()(withCookies(withRouter(MConcession)));
