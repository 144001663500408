import db from "../Db";
import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

var _ = require("lodash");

export async function CreateGens(headers) {
  const _gens = await GetApiData("GENS", headers);

  if (_gens.Message !== undefined) {
    return _gens;
  }

  var drops = [];
  _.forEach(_gens, (gens) => {
    drops.push({
      IDGENS: gens.IDGENS,
      NOM: gens.NOM.trim(),
      PRENOM: gens.PRENOM.trim(),
      CLECIME: gens.CLECIME.replace(/,\s*$/, "").split(",").map(Number),
      UTCLEUNIK: gens.UTCLEUNIK,
      AGENT_TERRAIN: gens.AGENT_TERRAIN === true ? 1 : 0,
    });
  });

  return await db
    .open()
    .then(() => {
      return db.GENS.bulkAdd(drops)
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
