import { GetClient } from "./../../Helpers/Get/Client";
import Dexie from "dexie";
import jsonpackage from "../../../package.json";

export default function Opposition(cleApi) {
  if (cleApi !== undefined) {
    GetClient().then((clientTablette) => {
      if (clientTablette !== undefined) {
        const headers = {
          _cle: atob(cleApi),
          _idtablette: clientTablette.ID_TABLETTE,
          "Content-Type": "application/json",
        };

        fetch(`${jsonpackage.urlapi}OppositionTablette`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
          .then((result) => result.text())
          .then((result) => {
            result = JSON.parse(result.toLowerCase());

            if (result === true) {
              Dexie.delete("GARDIEN");

              const expire = new Date();
              expire.setFullYear(expire.getFullYear() + 10);

              window.location.reload();
            }
          });
      }
    });
  }
}
