import db from "../Db";
import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";
import DeleteUtil from "../Delete/Util_MDF";

var _ = require("lodash");

export async function UpdateUtil_MDF(headers) {
  const _util = await GetApiData("UTIL", headers);

  if (_util.Message !== undefined) {
    return _util;
  }

  return await DeleteUtil().then(() => {
    var drops = [];
    _.forEach(_util, (util) => {
      drops.push({
        UTCLEUNIK: util.UTCLEUNIK,
        LOGIN: util.LOGIN.trim(),
        PASS: util.PASS,
        ADMINISTRATEUR: util.ADMINISTRATEUR ? 1 : 0,
        NOM: util.NOM.trim(),
        PRENOM: util.PRENOM.trim(),
        AGENT_TERRAIN: util.AGENT_TERRAIN === true ? 1 : 0,
      });
    });

    return db
      .open()
      .then(() => {
        return db.UTIL.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
