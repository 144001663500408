import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetConcessById } from "../Get/Concess";

var _ = require("lodash");

export async function UpdateConcess_MDF(headers) {
  const _concessCount = await GetApiData("CONCESSCount", headers);

  if (_concessCount.Message !== undefined) {
    return _concessCount;
  }

  const promise = new Promise((res, rej) => {
    if (_concessCount === 0) res("");

    addTableWithProgress("CONCESS", _concessCount, 10000, headers, (getProgress, getConcess) => {
      if (getConcess.Message !== undefined) {
        return res(getConcess);
      }

      _.forEach(getConcess, (concess) => {
        const concess_a_mettre_a_jour = {
          COCLEUNIK: concess.COCLEUNIK,
          LICLEUNIK: concess.LICLEUNIK,
          DUREE: concess.DUREE,
          DATEEXPIRA: concess.DATEEXPIRA,
          MADATE: concess.MADATE,
          DETAIL: concess.DETAIL,
          FAMILLE: concess.FAMILLE.trim(),
          SUPERFICIE: concess.SUPERFICIE,
          TYPECIME: concess.TYPECIME,
          DIVERS: concess.DIVERS,
          ANCNUM: concess.ANCNUM,
          TYPECONC: concess.TYPECONC,
          CONCESSIONTYPE: concess.CONCESSIONTYPE,
          EMPL: concess.EMPL,
          IDPROCEDUREABANDON: concess.IDPROCEDUREABANDON,
          DIVERS2: concess.DIVERS2,
          INTERLOCUTEUR_C0CLEUNIK: concess.INTERLOCUTEUR_C0CLEUNIK,
          A_REPRENDRE: concess.A_REPRENDRE,
          POSE_PLAQUE: concess.POSE_PLAQUE,
          IDCONCESS_ETAT_ECHEANCE: concess.IDCONCESS_ETAT_ECHEANCE,
          EXH_ADMIN_REALISE: concess.EXH_ADMIN_REALISE ? 1 : 0,
          MONUMENT_ENLEVE: concess.MONUMENT_ENLEVE ? 1 : 0,
          EMPLACEMENT_LIEU: concess.EMPLACEMENT_LIEU,
          NOMBRE: concess.NOMBRE,
        };

        GetConcessById(concess_a_mettre_a_jour.COCLEUNIK).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.CONCESS.add(concess_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(concess_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.CONCESS.put(concess_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _concessCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_concessCount} concessions`);

    return val;
  });
}
