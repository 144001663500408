import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { CookiesProvider, withCookies } from "react-cookie";
import { DbExist } from "./Helpers/Test/DbExist";

import requestNotificationPermission from "./Components/Global/requestAutorisation";
import theme from "./Components/Global/CreateTheme";
import * as serviceWorker from "./serviceWorker";

import Param from "./Components/Param";
import LogIn from "./Components/LogIn";
import { DeleteDatabase } from "./Helpers/Delete/Database";
import { useWakeLock } from "react-screen-wake-lock";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newVersionAvailable: false,
      dbExist: null,
      recommencerSansPhotos: null,
    };

    requestNotificationPermission();
  }

  componentDidMount = () => {
    serviceWorker.register({
      onUpdate: () => {
        this.setNewVersionAvailable(true);
      },
    });

    DbExist().then((result) => this.setState({ dbExist: result }));
  };

  setNewVersionAvailable = (value) => {
    this.setState({ newVersionAvailable: value });
  };

  _handle_click_recommencer = (withoutPhotos) => (event) => {
    event.preventDefault();

    const deleteDb = DeleteDatabase();
    deleteDb.then((res) => {
      this.setState({ dbExist: false, recommencerSansPhotos: withoutPhotos });
    });
  };

  render() {
    return this.state.dbExist !== null ? (
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          {!this.state.dbExist ? (
            <Param newVersionAvailable={this.state.newVersionAvailable} setNewVersionAvailable={this.setNewVersionAvailable} recommencerSansPhotos={this.state.recommencerSansPhotos} />
          ) : (
            <LogIn newVersionAvailable={this.state.newVersionAvailable} setNewVersionAvailable={this.setNewVersionAvailable} handle_click_recommencer={this._handle_click_recommencer} />
          )}
        </CookiesProvider>
      </ThemeProvider>
    ) : null;
  }
}

function WrapperComponent() {
  const { isSupported, request } = useWakeLock({
    onRequest: () => console.log("Screen Wake Lock: requested!"),
    onError: () => console.log("An error happened 💥"),
    onRelease: () => console.log("Screen Wake Lock: released!"),
  });

  if (isSupported) request();

  return <App />;
}

export default withCookies(WrapperComponent);
