import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateIntervenDefunt(headers) {
  const _intervenDefuntCount = await GetApiData("INTERVEN_DEFUNTCount", headers);

  if (_intervenDefuntCount.Message !== undefined) {
    return _intervenDefuntCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("INTERVEN_DEFUNT", _intervenDefuntCount, 2500, headers, (getProgress, getIntervenDefunt) => {
      if (getIntervenDefunt.Message !== undefined) {
        return res(getIntervenDefunt);
      }

      var drops = [];
      _.forEach(getIntervenDefunt, (interven_defunt) => {
        drops.push({
          IDINTERVEN_DEFUNT: interven_defunt.IDINTERVEN_DEFUNT,
          D0CLEUNIK: interven_defunt.D0CLEUNIK,
          I0CLEUNIK: interven_defunt.I0CLEUNIK,
          IDMOUVEMENT: interven_defunt.IDMOUVEMENT,
        });
      });

      db.open()
        .then(() => {
          db.INTERVEN_DEFUNT.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _intervenDefuntCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_intervenDefuntCount} interventions lié à un défunt`);

    return val;
  });
}
