import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateNatinterv(headers) {
  const _natintervCount = await GetApiData("NATINTERVCount", headers);

  if (_natintervCount.Message !== undefined) {
    return _natintervCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("NATINTERV", _natintervCount, 2500, headers, (getProgress, getNatinterv) => {
      if (getNatinterv.Message !== undefined) {
        return res(getNatinterv);
      }

      var drops = [];
      _.forEach(getNatinterv, (natinterv) => {
        drops.push({
          IDNATINTERV: natinterv.IDNATINTERV,
          LIBINTERVENTION: natinterv.LIBINTERVENTION,
          TYPEPREST: natinterv.TYPEPREST,
          I0CLEUNIK: natinterv.I0CLEUNIK,
        });
      });

      db.open()
        .then(() => {
          db.NATINTERV.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _natintervCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_natintervCount} natures d'intervention`);

    return val;
  });
}
