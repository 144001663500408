import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateMouvement(headers) {
  const _mouvementCount = await GetApiData("MOUVEMENTCount", headers);

  if (_mouvementCount.Message !== undefined) {
    return _mouvementCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("MOUVEMENT", _mouvementCount, 10000, headers, (getProgress, getMouvement) => {
      if (getMouvement.Message !== undefined) {
        return res(getMouvement);
      }

      var drops = [];
      _.forEach(getMouvement, (mouvement) => {
        drops.push({
          IDMOUVEMENT: mouvement.IDMOUVEMENT,
          D0CLEUNIK: mouvement.D0CLEUNIK,
        });
      });

      db.open()
        .then(() => {
          db.MOUVEMENT.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _mouvementCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_mouvementCount} mouvements`);

    return val;
  });
}
