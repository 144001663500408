import Dexie from "dexie";
import db from "../Db";

var AddConcess = Dexie.async(function* (CONCESS) {
  try {
    yield db.open();
    var id = yield db.CONCESS.add(CONCESS);
    return id;
  } finally {
    db.close();
  }
});

export default AddConcess;
