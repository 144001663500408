import db from "../Db";

export function GetAllCendre() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CENDRE.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetCendreById(NUMCENDRE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CENDRE.where("NUMCENDRE")
      .equals(parseInt(NUMCENDRE))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetCendreByIdLieu(LICLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CENDRE.where("LICLEUNIK")
      .equals(LICLEUNIK)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetCendreByNumcime(NUMCIME) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CENDRE.where("TYPECIME")
      .equals(NUMCIME)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
