import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetDefuntById } from "../Get/Defunt";

var _ = require("lodash");

export async function UpdateDefunt_MDF(headers) {
  const _defuntCount = await GetApiData("DEFUNTCount", headers);

  if (_defuntCount.Message !== undefined) {
    return _defuntCount;
  }

  const promise = new Promise((res, rej) => {
    if (_defuntCount === 0) res("");

    addTableWithProgress("DEFUNT", _defuntCount, 10000, headers, (getProgress, getDefunt) => {
      if (getDefunt.Message !== undefined) {
        return res(getDefunt);
      }

      _.forEach(getDefunt, (defunt) => {
        const defunt_a_mettre_a_jour = {
          D0CLEUNIK: defunt.D0CLEUNIK,
          ETAT_CIVIL: {
            NOMDEF: defunt.NOMDEF.trim(),
            SEXE: defunt.SEXE,
            NOMJEUNE: defunt.NOMJEUNE.trim(),
            PRENOMDEF: defunt.PRENOMDEF.trim(),
            DATENAISS: defunt.DATENAISS === "1900-01-01T00:00:00" ? "" : defunt.DATENAISS,
            DATEDECES: defunt.DATEDECES === "1900-01-01T00:00:00" ? "" : defunt.DATEDECES,
            DATEINHUM: defunt.DATEINHUM === "1900-01-01T00:00:00" ? "" : defunt.DATEINHUM,
            ANNEEDECES: defunt.ANNEEDECES,
            AGE: defunt.AGE,
          },
          ENVELOPPE: {
            URNE: defunt.URNE,
            RELIQUAIRE: defunt.RELIQUAIRE,
            ENFANT: defunt.ENFANT,
            DISPERSION: defunt.DISPERSION,
            URNE_SCELLE: defunt.URNE_SCELLE,
          },
          BIERRE: {
            MEBI_MC: defunt.MEBI_MC,
            SOINS_CONSERVATIONS: defunt.SOINS_CONSERVATIONS,
            HERMETIQUE: defunt.HERMETIQUE,
            ZINC_HN: defunt.ZINC_HN,
          },
          ENFANTDC: {
            AUCUN: defunt.ENFANTDC === 1 ? 1 : 0,
            ENFANT: defunt.ENFANTDC === 2 ? 1 : 0,
            NOUVEAU_NE: defunt.ENFANTDC === 3 ? 1 : 0,
            MORT_NE: defunt.ENFANTDC === 4 ? 1 : 0,
            FOETUS: defunt.ENFANTDC === 5 ? 1 : 0,
          },
          LICLEUNIK: defunt.LICLEUNIK,
          COCLEUNIK: defunt.COCLEUNIK,
          TYPECIME: defunt.TYPECIME,
          NUMCENDRE: defunt.NUMCENDRE,
          IDMATERIAU: defunt.IDMATERIAU,
          EMPLACEMENT_LIEU: defunt.EMPLACEMENT_LIEU ?? "Provisoire",
        };

        GetDefuntById(defunt_a_mettre_a_jour.D0CLEUNIK).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.DEFUNT.add(defunt_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(defunt_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.DEFUNT.put(defunt_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _defuntCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_defuntCount} defunts`);

    return val;
  });
}
