import db from "../Db";

export function GetAllDuree() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DUREE.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetDureeById(NUMDURE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.DUREE.where("NUMDURE")
      .equals(parseInt(NUMDURE))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
