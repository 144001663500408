import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AppBar, Button, Dialog, IconButton, Paper, Slide, Table, TableBody, TableRow, TableCell, Toolbar, Typography, withWidth, TableHead, Card, CardActions, CardMedia } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { GetPhotoconcByCocleunik } from "./../../../Helpers/Get/Photoconc";

import CFCEPhotographie from "./CFCEtat/CFCEPhotographie";
import { GetTemporaireForColor } from "../../../Helpers/Get/Temporaire";
import { IsOracle } from "./../../../Helpers/Test/IsOracle";
import { Cree_date_chaine } from "../../../Helpers/Function";

import b64toBlob from "b64-to-blob";
import no_photo from "../../../Images/no_photo.json";
import { CheckUserRight } from "./../../../Helpers/Get/LigneAction";
import { withCookies } from "react-cookie";
import { DialogAccessRight } from "./../../Global/DialogAccessRight";
import CurrentTab from "./../../Global/Object/current_tab";
import { GetParam } from "./../../../Helpers/Get/Param";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: 16,
  },
  papertitle: {
    display: "flex",
    marginBottom: 20,
  },
  typographytitle: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    justifyContent: "center",
    marginBottom: 0,
    paddingRight: 95,
  },
  table: {
    marginTop: 24,
  },
  tablecells: {
    border: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  photoconc_card: {
    border: "1px solid grey",
    cursor: "pointer",
    flex: 1,
    margin: "auto",
    marginBottom: "5px",
    maxWidth: 160,
    minWidth: 160,
    textAlign: "center",
    width: 100,
  },
  photoconc_card_dialog: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    margin: "120px 50px 50px 50px",
  },
  photoconc_cardaction: {
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 3,
  },
  photoconc_cardmedia: {
    backgroundColor: "whitesmoke",
    backgroundSize: "contain",
    borderTop: "1px solid lightgrey",
    height: 0,
    paddingTop: "100%",
  },
  photoconc_cardmedia_dialog: {
    backgroundSize: "contain",
    height: 0,
    paddingTop: "60%",
  },
  appBar: {
    position: "relative",
  },
  typography: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CFCEtat extends Component {
  constructor(props) {
    super(props);

    this.MODIFIER_ref = React.createRef();

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      PHOTOCONC: [],
      champ_modifie: [],
      photoDialogOpen: false,
      photoDialogPhoto: null,
      droit_util_modif_concession: false,
      redirect: null,
      PARAM: "",
      PHOTOCONC_ETAT: "",
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    GetParam().then((param) => {
      this.setState({ PARAM: param });
    });

    let field = [];
    const temporaire = GetTemporaireForColor("CONCESS", "COCLEUNIK", this.props.concess.COCLEUNIK);
    temporaire.then((temp) => {
      _.forEach(temp, (t) => {
        let color = "red";

        if (t.DATE_D_ENVOI_A_GESCIME !== null) {
          color = "blue";
        }

        if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
          color = "green";
        }

        if (t.DATE_TRAITE_DANS_GESCIME !== null) {
          color = "primary";
        }

        var element_temp = t.TABLE_MODIFIE_JSON;
        element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

        for (var key in element_temp) {
          field[key] = color;
        }

        this.setState({ champ_modifie: field });
      });
    });

    GetPhotoconcByCocleunik(this.props.concess.COCLEUNIK).then((photoconc) => {
      this.setState({ PHOTOCONC_ETAT: _.last(photoconc) || "" }, () => {
        if (this.state.PHOTOCONC_ETAT !== "") {
          const temporaire = GetTemporaireForColor("PHOTOCONC", "COCLEUNIK", this.state.PHOTOCONC_ETAT.COCLEUNIK);
          temporaire.then((temp) => {
            let tlength = temp.length;

            if (temp[0] !== undefined) {
              let color = "red";

              if (temp[0].DATE_D_ENVOI_A_GESCIME !== null) {
                color = "blue";
              }

              if (temp[0].DATE_D_IMPORT_DANS_GESCIME !== null) {
                color = "green";
              }

              if (temp[0].DATE_TRAITE_DANS_GESCIME !== null) {
                color = "primary";
              }

              var element_temp = temp[0].TABLE_MODIFIE_JSON;
              element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

              for (var key in element_temp) {
                if ((key === "MONUMENT_ETAT" || key === "SEMELLE_ETAT") && tlength > 0) {
                  if (element_temp[key] !== "") {
                    field[key] = color;
                  }
                } else {
                  field[key] = color;
                }
              }

              this.setState({ champ_modifie: field });
            }
          });
        }
      });

      photoconc = _.filter(photoconc, (photo) => {
        return photo.PHOTO !== "" && (photo.ABANDON_1 || photo.ABANDON_2);
      });

      if (photoconc.length > 0) {
        let ordered = _.orderBy(photoconc, ["PRINCIPAL"], ["desc"]);

        if (ordered[0].PRINCIPAL === 0) {
          ordered = _.orderBy(photoconc, ["DATEAJOUT"], ["desc"]);
        }

        this.setState({ PHOTOCONC: ordered });

        _.forEach(ordered, (p) => {
          const temporaire = GetTemporaireForColor("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", p.IDPHOTOCONC);

          temporaire.then((temp) => {
            if (temp.length > 0) {
              _.forEach(temp, (t) => {
                let color = "red";

                if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                  color = "blue";
                }

                if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                  color = "green";
                }

                if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                  color = "primary";
                }

                var element_temp = t.TABLE_MODIFIE_JSON;
                element_temp = _.omit(element_temp, ["id"]);

                let sub_field = {};
                for (var key in element_temp) {
                  sub_field[key] = color;

                  if (key === (this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC")) sub_field[key] = this.state.IS_ORACLE ? element_temp.IDEVOL_ETAT : element_temp.IDPHOTOCONC;
                }

                field.push(sub_field);
              });

              this.setState({ champ_modifie: field });
            }
          });
        });
      }
    });
  };

  _find_color = (modifie, idphotoconc) => {
    const temp = _.find(modifie, ["IDPHOTOCONC", idphotoconc]) || _.find(modifie, ["IDEVOL_ETAT", idphotoconc]);

    let color = [];
    for (var key in temp) {
      if (typeof temp[key] === "string") {
        if (!_.includes(color, temp[key])) color.push(temp[key]);
      }
    }

    if (_.includes(color, "red")) return "red";
    if (_.includes(color, "blue")) return "blue";
    if (_.includes(color, "green")) return "green";

    return "grey";
  };

  zoomOnImage = (photo) => (event) => {
    event.preventDefault();

    this.setState({ photoDialogOpen: true, photoDialogPhoto: photo });
  };

  render() {
    const champ_modifie = this.props.champ_modifie;
    const { width } = this.props;

    console.log(this.state.champ_modifie);

    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <Paper elevation={0} style={styles.papertitle}>
          <Button
            variant="contained"
            color="primary"
            disabled={this.props.en_cours_de_traitement}
            ref={this.MODIFIER_ref}
            onClick={() => {
              CheckUserRight(this.state.ID_UTIL, "CONCESS", "DROITMODIF").then((asRight) => {
                if (asRight) {
                  CurrentTab.setTab(2);

                  this.setState({
                    redirect: this.props.pa ? `/modifier/concession/${this.props.concess.COCLEUNIK}/pa` : `/modifier/concession/${this.props.concess.COCLEUNIK}`,
                  });
                } else {
                  this.setState({ droit_util_modif_concession: true });
                }
              });
            }}
          >
            Modifier
          </Button>

          {this.props.en_cours_de_traitement ? (
            <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
              Modification impossible : Concession non traitée dans Gescime
            </Typography>
          ) : null}

          <Typography variant="body2" gutterBottom style={styles.typographytitle}>
            Etat
          </Typography>
        </Paper>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  color: _.has(champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    ? _.get(champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    : _.has(this.state.champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    ? _.get(this.state.champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    : "primary",
                }}
              >
                {this.state.IS_ORACLE ? `Etat d'échéance` : `A reprendre`}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    ? _.get(champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    : _.has(this.state.champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    ? _.get(this.state.champ_modifie, this.state.IS_ORACLE ? "IDCONCESS_ETAT_ECHEANCE" : "A_REPRENDRE")
                    : "primary",
                }}
              >
                {this.state.IS_ORACLE
                  ? _.find(this.props.concess_etat_echeance, ["IDCONCESS_ETAT_ECHEANCE", this.props.concess.IDCONCESS_ETAT_ECHEANCE]) !== undefined
                    ? _.find(this.props.concess_etat_echeance, ["IDCONCESS_ETAT_ECHEANCE", this.props.concess.IDCONCESS_ETAT_ECHEANCE]).LIBELLE
                    : ""
                  : this.props.concess.A_REPRENDRE === 1
                  ? `Oui`
                  : `Non`}
              </TableCell>
            </TableRow>

            {!this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(champ_modifie, "POSE_PLAQUE")
                      ? _.get(champ_modifie, "POSE_PLAQUE")
                      : _.has(this.state.champ_modifie, "POSE_PLAQUE")
                      ? _.get(this.state.champ_modifie, "POSE_PLAQUE")
                      : "primary",
                  }}
                >
                  Pose plaquette
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(champ_modifie, "POSE_PLAQUE")
                      ? _.get(champ_modifie, "POSE_PLAQUE")
                      : _.has(this.state.champ_modifie, "POSE_PLAQUE")
                      ? _.get(this.state.champ_modifie, "POSE_PLAQUE")
                      : "primary",
                  }}
                >
                  {this.props.concess.POSE_PLAQUE === 1 ? `Oui` : `Non`}
                </TableCell>
              </TableRow>
            ) : null}

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(champ_modifie, "EXH_ADMIN_REALISE")
                      ? _.get(champ_modifie, "EXH_ADMIN_REALISE")
                      : _.has(this.state.champ_modifie, "EXH_ADMIN_REALISE")
                      ? _.get(this.state.champ_modifie, "EXH_ADMIN_REALISE")
                      : "primary",
                  }}
                >
                  Exhumations réalisées
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(champ_modifie, "EXH_ADMIN_REALISE")
                      ? _.get(champ_modifie, "EXH_ADMIN_REALISE")
                      : _.has(this.state.champ_modifie, "EXH_ADMIN_REALISE")
                      ? _.get(this.state.champ_modifie, "EXH_ADMIN_REALISE")
                      : "primary",
                  }}
                >
                  {this.props.concess.EXH_ADMIN_REALISE === 1 ? `Oui` : `Non`}
                </TableCell>
              </TableRow>
            ) : null}

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(champ_modifie, "MONUMENT_ENLEVE")
                      ? _.get(champ_modifie, "MONUMENT_ENLEVE")
                      : _.has(this.state.champ_modifie, "MONUMENT_ENLEVE")
                      ? _.get(this.state.champ_modifie, "MONUMENT_ENLEVE")
                      : "primary",
                  }}
                >
                  Monument enlevé
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(champ_modifie, "MONUMENT_ENLEVE")
                      ? _.get(champ_modifie, "MONUMENT_ENLEVE")
                      : _.has(this.state.champ_modifie, "MONUMENT_ENLEVE")
                      ? _.get(this.state.champ_modifie, "MONUMENT_ENLEVE")
                      : "primary",
                  }}
                >
                  {this.props.concess.MONUMENT_ENLEVE === 1 ? `Oui` : `Non`}
                </TableCell>
              </TableRow>
            ) : null}

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(this.state.champ_modifie, "MONUMENT_ETAT") ? _.get(this.state.champ_modifie, "MONUMENT_ETAT") : "primary",
                  }}
                >
                  {"Etat du monument"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(this.state.champ_modifie, "MONUMENT_ETAT") ? _.get(this.state.champ_modifie, "MONUMENT_ETAT") : "primary",
                  }}
                >
                  {this.state.PHOTOCONC_ETAT.MONUMENT_ETAT}
                </TableCell>
              </TableRow>
            ) : null}

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(this.state.champ_modifie, "SEMELLE_ETAT") ? _.get(this.state.champ_modifie, "SEMELLE_ETAT") : "primary",
                  }}
                >
                  {this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(this.state.champ_modifie, "SEMELLE_ETAT") ? _.get(this.state.champ_modifie, "SEMELLE_ETAT") : "primary",
                  }}
                >
                  {this.state.PHOTOCONC_ETAT.SEMELLE_ETAT}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>

        <CFCEPhotographie concess={this.props.concess} echues={this.props.echues} pa={this.props.pa} />

        {this.props.concess.IDPROCEDUREABANDON !== 0 ? (
          <Paper elevation={0} style={styles.paper}>
            <Typography variant="body2">Procédure d'abandon</Typography>

            {width === "xs" || width === "sm" ? (
              <div>
                <Table style={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tablecells}>
                        <Typography variant="body2">
                          1<sup>er</sup> constat d'abandon
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          ...styles.tablecells,
                          ...{ flexWrap: "wrap" },
                        }}
                      >
                        {_.filter(this.state.PHOTOCONC, (photos) => {
                          return photos.ABANDON_1;
                        }).length === 0 ? (
                          <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.MODIFIER_ref.current.click()}>
                            <CardActions disableSpacing style={styles.photoconc_cardaction}>
                              <Typography variant="body2">&nbsp;</Typography>
                            </CardActions>

                            <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                          </Card>
                        ) : (
                          _.filter(this.state.PHOTOCONC, (photos) => {
                            return photos.ABANDON_1;
                          }).map((photo, index) =>
                            photo.PHOTO !== "" ? (
                              <Card
                                key={index}
                                style={{
                                  ...styles.photoconc_card,
                                  border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                                }}
                              >
                                <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                  <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>

                                  <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                                    {photo.PRINCIPAL ? "Référente" : null}
                                  </Typography>
                                </CardActions>

                                <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                              </Card>
                            ) : null
                          )
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          ...styles.tablecells,
                          color: _.has(champ_modifie, "DIVERS") ? _.get(champ_modifie, "DIVERS") : _.has(this.state.champ_modifie, "DIVERS") ? _.get(this.state.champ_modifie, "DIVERS") : "primary",
                        }}
                      >
                        Descriptif de l'état du monument
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          ...styles.tablecells,
                          color: _.has(champ_modifie, "DIVERS") ? _.get(champ_modifie, "DIVERS") : _.has(this.state.champ_modifie, "DIVERS") ? _.get(this.state.champ_modifie, "DIVERS") : "primary",
                        }}
                      >
                        {this.props.concess.DIVERS}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table style={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tablecells}>
                        <Typography variant="body2">
                          2<sup>ème</sup> constat d'abandon
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          ...styles.tablecells,
                          ...{ flexWrap: "wrap" },
                        }}
                      >
                        {_.filter(this.state.PHOTOCONC, (photos) => {
                          return photos.ABANDON_2;
                        }).length === 0 ? (
                          <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.MODIFIER_ref.current.click()}>
                            <CardActions disableSpacing style={styles.photoconc_cardaction}>
                              <Typography variant="body2">&nbsp;</Typography>
                            </CardActions>

                            <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                          </Card>
                        ) : (
                          _.filter(this.state.PHOTOCONC, (photos) => {
                            return photos.ABANDON_2;
                          }).map((photo, index) =>
                            photo.PHOTO !== "" ? (
                              <Card
                                key={index}
                                style={{
                                  ...styles.photoconc_card,
                                  border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                                }}
                              >
                                <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                  <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>

                                  <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                                    {photo.PRINCIPAL ? "Référente" : null}
                                  </Typography>
                                </CardActions>

                                <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                              </Card>
                            ) : null
                          )
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          ...styles.tablecells,
                          color: _.has(champ_modifie, "DIVERS") ? _.get(champ_modifie, "DIVERS") : _.has(this.state.champ_modifie, "DIVERS") ? _.get(this.state.champ_modifie, "DIVERS") : "primary",
                        }}
                      >
                        Descriptif de l'état du monument
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          ...styles.tablecells,
                          color: _.has(champ_modifie, "DIVERS2")
                            ? _.get(champ_modifie, "DIVERS2")
                            : _.has(this.state.champ_modifie, "DIVERS2")
                            ? _.get(this.state.champ_modifie, "DIVERS2")
                            : "primary",
                        }}
                      >
                        {this.props.concess.DIVERS2}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            ) : (
              <Table style={styles.table}>
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                      <Typography variant="body2">
                        1<sup>er</sup> constat d'abandon
                      </Typography>
                    </TableCell>

                    <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                      <Typography variant="body2">
                        2<sup>ème</sup> constat d'abandon
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow style={{ display: "flex" }}>
                    <TableCell
                      style={{
                        ...styles.tablecells,
                        ...{ flex: 1, flexWrap: "wrap" },
                      }}
                    >
                      {_.filter(this.state.PHOTOCONC, (photos) => {
                        return photos.ABANDON_1;
                      }).length === 0 ? (
                        <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.MODIFIER_ref.current.click()}>
                          <CardActions disableSpacing style={styles.photoconc_cardaction}>
                            <Typography variant="body2">&nbsp;</Typography>
                          </CardActions>

                          <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                        </Card>
                      ) : (
                        _.filter(this.state.PHOTOCONC, (photos) => {
                          return photos.ABANDON_1;
                        }).map((photo, index) =>
                          photo.PHOTO !== "" ? (
                            <Card
                              key={index}
                              style={{
                                ...styles.photoconc_card,
                                border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                              }}
                            >
                              <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>

                                <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                                  {photo.PRINCIPAL ? "Référente" : null}
                                </Typography>
                              </CardActions>

                              <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                            </Card>
                          ) : null
                        )
                      )}
                    </TableCell>

                    <TableCell
                      style={{
                        ...styles.tablecells,
                        ...{ flex: 1, flexWrap: "wrap" },
                      }}
                    >
                      {_.filter(this.state.PHOTOCONC, (photos) => {
                        return photos.ABANDON_2;
                      }).length === 0 ? (
                        <Card style={{ ...styles.photoconc_card, marginBottom: "auto" }} onClick={() => this.MODIFIER_ref.current.click()}>
                          <CardActions disableSpacing style={styles.photoconc_cardaction}>
                            <Typography variant="body2">&nbsp;</Typography>
                          </CardActions>

                          <CardMedia style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(b64toBlob(no_photo.image, "image/png"))} />
                        </Card>
                      ) : (
                        _.filter(this.state.PHOTOCONC, (photos) => {
                          return photos.ABANDON_2;
                        }).map((photo, index) =>
                          photo.PHOTO !== "" ? (
                            <Card
                              key={index}
                              style={{
                                ...styles.photoconc_card,
                                border: `1px solid ${this._find_color(this.state.champ_modifie, photo.IDPHOTOCONC)}`,
                              }}
                            >
                              <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                <Typography variant="body2">{`Prise le ${Cree_date_chaine(photo.DATEAJOUT)}`}</Typography>

                                <Typography variant="body2" color="textSecondary" style={{ height: 20 }}>
                                  {photo.PRINCIPAL ? "Référente" : null}
                                </Typography>
                              </CardActions>

                              <CardMedia onClick={this.zoomOnImage(photo.PHOTO)} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)} />
                            </Card>
                          ) : null
                        )
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ display: "flex" }}>
                    <TableCell
                      style={{
                        ...styles.tablecells,
                        flex: 1,
                        flexWrap: "wrap",
                        color: _.has(champ_modifie, "DIVERS") ? _.get(champ_modifie, "DIVERS") : _.has(this.state.champ_modifie, "DIVERS") ? _.get(this.state.champ_modifie, "DIVERS") : "primary",
                      }}
                    >
                      Descriptif de l'état du monument
                    </TableCell>
                    <TableCell
                      style={{
                        ...styles.tablecells,
                        flex: 1,
                        flexWrap: "wrap",
                        color: _.has(champ_modifie, "DIVERS2") ? _.get(champ_modifie, "DIVERS2") : _.has(this.state.champ_modifie, "DIVERS2") ? _.get(this.state.champ_modifie, "DIVERS2") : "primary",
                      }}
                    >
                      Descriptif de l'état du monument
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ display: "flex" }}>
                    <TableCell
                      style={{
                        ...styles.tablecells,
                        flex: 1,
                        flexWrap: "wrap",
                        color: _.has(champ_modifie, "DIVERS") ? _.get(champ_modifie, "DIVERS") : _.has(this.state.champ_modifie, "DIVERS") ? _.get(this.state.champ_modifie, "DIVERS") : "primary",
                      }}
                    >
                      {this.props.concess.DIVERS}
                    </TableCell>
                    <TableCell
                      style={{
                        ...styles.tablecells,
                        flex: 1,
                        flexWrap: "wrap",
                        color: _.has(champ_modifie, "DIVERS2") ? _.get(champ_modifie, "DIVERS2") : _.has(this.state.champ_modifie, "DIVERS2") ? _.get(this.state.champ_modifie, "DIVERS2") : "primary",
                      }}
                    >
                      {this.props.concess.DIVERS2}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            {this.state.photoDialogPhoto !== null ? (
              <Dialog
                fullScreen
                open={this.state.photoDialogOpen}
                onClose={() => {
                  this.setState({ photoDialogOpen: false });
                }}
                TransitionComponent={Transition}
              >
                <AppBar className={styles.appBar}>
                  <Toolbar>
                    <Typography variant="h6" style={styles.typography}></Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        this.setState({ photoDialogOpen: false });
                      }}
                      aria-label="close"
                    >
                      <Close />
                    </IconButton>
                  </Toolbar>
                </AppBar>

                <Card style={styles.photoconc_card_dialog} elevation={0}>
                  <CardMedia style={styles.photoconc_cardmedia_dialog} image={window.URL.createObjectURL(this.state.photoDialogPhoto)} />
                </Card>
              </Dialog>
            ) : null}
          </Paper>
        ) : null}

        <DialogAccessRight state={this.state.droit_util_modif_concession} onChange={(value) => this.setState({ droit_util_modif_concession: value })} />
      </Paper>
    );
  }
}

export default withCookies(withWidth()(CFCEtat));
