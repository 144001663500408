import db from "../Db";

export function GetAllNatinterv() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.NATINTERV.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetAllNatintervInTask(I0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.NATINTERV.where("I0CLEUNIK")
      .anyOf(I0CLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetNatintervById(IDNATINTERV) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.NATINTERV.where("IDNATINTERV")
      .equals(IDNATINTERV)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetNatintervByIdInterven(I0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.NATINTERV.where("I0CLEUNIK")
      .equals(I0CLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
