import db from "../Db";

export function GetAllGens(AGENT = null) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    if (AGENT === null) {
      db.GENS.toArray().then((gens) => {
        return resolve(gens);
      });
    } else {
      db.GENS.where("AGENT_TERRAIN")
        .equals(AGENT)
        .toArray()
        .then((gens) => {
          return resolve(gens);
        });
    }
  });
}
