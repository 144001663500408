import { CreeTemporaire, handleError } from "../../Helpers/Function";
import { GetDefuntByCocleunik } from "../../Helpers/Get/Defunt";
import { GetIntervenByCocleunik } from "../../Helpers/Get/Interven";
import { GetTemporaireNonEnvoye } from "../../Helpers/Get/Temporaire";
import UpdateConcess from "./../../Helpers/Update/Concess";
import UpdateDefunt from "./../../Helpers/Update/Defunt";
import UpdateInterven from "./../../Helpers/Update/Interven";
import UpdateLieu from "./../../Helpers/Update/Lieu";
import UpdateSvg from "./../../Helpers/Update/Svg";

var _ = require("lodash");

export function miseAJourConcessAssociation(concess, lieu, oracle, concess_temporaire, _this, concess_selectionne) {
  const modifie_concess = UpdateConcess(concess);

  modifie_concess
    .then(() => {
      _.set(concess_temporaire, "DEVENU_DEGELE", lieu.EMPLACEMENT.GELE);

      _.set(lieu, "COCLEUNIK", concess.COCLEUNIK);
      _.set(lieu.EMPLACEMENT, "ETAT", 2);
      _.set(lieu.EMPLACEMENT, "GELE", 0);

      GetDefuntByCocleunik(concess.COCLEUNIK).then((defunts) => {
        _.forEach(defunts, (d) => {
          d.LICLEUNIK = lieu.LICLEUNIK;

          UpdateDefunt(d);
        });
      });

      GetIntervenByCocleunik(concess.COCLEUNIK).then((intervens) => {
        _.forEach(intervens, (i) => {
          i.LICLEUNIK = lieu.LICLEUNIK;

          UpdateInterven(i);
        });
      });

      UpdateLieu(lieu).then(() => {
        UpdateSvg(lieu).then(() => {
          GetTemporaireNonEnvoye("CONCESS", "COCLEUNIK", concess.COCLEUNIK).then((temporaire) => {
            if (oracle) {
              concess_temporaire = _.omit(concess_temporaire, ["A_REPRENDRE", "POSE_PLAQUE"]);
            }

            if (temporaire !== undefined) {
              var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, concess_temporaire);

              temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;

              _this._MiseAJourTemporaire(temporaire);
            } else {
              const temporaire = CreeTemporaire(
                _this.IDCLIENT,
                _this.ID_TABLETTE,
                _this.ID_UTIL,
                `Modification de la concession ${concess.FAMILLE}`,
                "CONCESS",
                2,
                concess_temporaire,
                concess_selectionne
              );

              _this._AjoutTemporaire(temporaire);
            }
          });
        });
      });
    })
    .catch((Err) => handleError(Err));
}
