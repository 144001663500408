import db from "../Db";
import { handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteParam from "../Delete/Param_MDF";

export async function UpdateParam_MDF(headers) {
  const _param = await GetApiData("PARAM", headers);

  if (_param.Message !== undefined) {
    return _param;
  }

  return await DeleteParam().then(() => {
    return db
      .open()
      .then(() => {
        return db.PARAM.add({
          CARREDEFLT: _param.CARREDEFLT.trim(),
          RANGDEFLT: _param.RANGDEFLT.trim(),
          TOMBEDEFLT: _param.TOMBEDEFLT.trim(),
          LIBELLEANCIENNUMPLAN: _param.LIBELLEANCIENNUMPLAN.trim(),
          ETAT_MONUMENT_LIBELLE_SEMELLE: _param.ETAT_MONUMENT_LIBELLE_SEMELLE.trim(),
          LIBPASSEPIEDS: _param.LIBPASSEPIEDS.trim(),
        })
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
