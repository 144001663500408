import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuButton from "./MenuButton";

import "./AccueilButton.css";

function AccueilButton(props) {
  return (
    <Grid item xs className="Accueil-Button-Grid">
      <MenuButton onClick={props.onClick(props.url)}>
        <FontAwesomeIcon icon={props.icon} className="Accueil-Button-FontAwesomeIcon" />
      </MenuButton>

      <Typography variant="subtitle1" color="secondary" className="Accueil-Button-Typography">
        {props.text}
      </Typography>
    </Grid>
  );
}

export default AccueilButton;
