import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import DeleteMateriau from "../Delete/Materiau_MDF";
import { handleError } from "../Function";

var _ = require("lodash");

export async function UpdateMateriau_MDF(headers) {
  const _materiau = await GetApiData("MATERIAU", headers);

  if (_materiau.Message !== undefined) {
    return _materiau;
  }

  return await DeleteMateriau().then(() => {
    var drops = [];
    _.forEach(_materiau, (materiau) => {
      drops.push({
        IDMATERIAU: materiau.IDMATERIAU,
        LIBELLE: materiau.LIBELLE.trim(),
        ORDRE: materiau.ORDRE,
        ARCHIVER: materiau.ARCHIVER,
      });
    });

    return db
      .open()
      .then(() => {
        return db.MATERIAU.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
