const { IconButton, withStyles } = require("@material-ui/core");

const MenuButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#646464",
    borderRadius: "15%",
    color: "white",
    "&:hover": {
      backgroundColor: "#FF732F",
      color: "black",
    },
    "& span": {
      flexDirection: "column",
    },
  },
}))(IconButton);

export default MenuButton;
