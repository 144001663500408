import db from "../Db";

import { GetAllDefunt } from "./../Get/Defunt";
import { bulkAddWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateDefuntS() {
  const defunt_s = {
    A: [],
    B: [],
    C: [],
    D: [],
    E: [],
    F: [],
    G: [],
    H: [],
    I: [],
    J: [],
    K: [],
    L: [],
    M: [],
    N: [],
    O: [],
    P: [],
    Q: [],
    R: [],
    S: [],
    T: [],
    U: [],
    V: [],
    W: [],
    X: [],
    Y: [],
    Z: [],
    Autre: [],
  };

  const defunts = await GetAllDefunt();

  _.forEach(defunts, (defunt) => {
    let premier_caractere = "";
    let identite = "";

    if (defunt.ETAT_CIVIL.NOMDEF.trim() !== "") {
      premier_caractere = _.deburr(defunt.ETAT_CIVIL.NOMDEF.charAt(0));

      if (defunt.ETAT_CIVIL.PRENOMDEF.trim() !== "") {
        identite = `${defunt.ETAT_CIVIL.NOMDEF.trim()} ${defunt.ETAT_CIVIL.PRENOMDEF.trim()}`;
      } else {
        identite = `${defunt.ETAT_CIVIL.NOMDEF.trim()}`;
      }
    } else {
      if (defunt.ETAT_CIVIL.SEXE > 1 && defunt.ETAT_CIVIL.NOMJEUNE.trim() !== "") {
        premier_caractere = _.deburr(defunt.ETAT_CIVIL.NOMJEUNE.charAt(0));
        identite = `${defunt.ETAT_CIVIL.NOMJEUNE.trim()} ${defunt.ETAT_CIVIL.PRENOMDEF.trim()}`;
      } else {
        premier_caractere = _.deburr(defunt.ETAT_CIVIL.PRENOMDEF.charAt(0));
        identite = `${defunt.ETAT_CIVIL.PRENOMDEF.trim()}`;
      }
    }

    if (premier_caractere !== "" && premier_caractere !== " ") {
      if (defunt_s[premier_caractere] !== undefined) {
        defunt_s[premier_caractere].push({
          id: defunt.id,
          D0CLEUNIK: defunt.D0CLEUNIK,
          IDENTITE: identite,
        });
      } else {
        defunt_s.Autre.push({
          id: defunt.id,
          D0CLEUNIK: defunt.D0CLEUNIK,
          IDENTITE: identite,
        });
      }
    }
  });

  for (var cle in defunt_s) {
    if (defunt_s.hasOwnProperty(cle)) {
      defunt_s[cle] = _.orderBy(defunt_s[cle], "IDENTITE", "asc");
    }
  }

  var drops = [];
  for (var key in defunt_s) {
    if (defunt_s.hasOwnProperty(key)) {
      drops.push({
        id: Object.keys(defunt_s).indexOf(key) + 1,
        [key]: defunt_s[key],
      });
    }
  }

  return await db
    .open()
    .then(() => {
      return bulkAddWithProgress(db.DEFUNT_S, drops, 2500).then(() => {
        return "";
      });
    })
    .catch((err) => {
      handleError(err);
    });
}
