import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetMouvementById } from "../Get/Mouvement";

var _ = require("lodash");

export async function UpdateMouvement_MDF(headers) {
  const _mouvementCount = await GetApiData("MOUVEMENTCount", headers);

  if (_mouvementCount.Message !== undefined) {
    return _mouvementCount;
  }

  const promise = new Promise((res, rej) => {
    if (_mouvementCount === 0) res("");

    addTableWithProgress("MOUVEMENT", _mouvementCount, 10000, headers, (getProgress, getMouvement) => {
      if (getMouvement.Message !== undefined) {
        return res(getMouvement);
      }

      _.forEach(getMouvement, (mouvement) => {
        const mouvement_a_mettre_a_jour = {
          IDMOUVEMENT: mouvement.IDMOUVEMENT,
          D0CLEUNIK: mouvement.D0CLEUNIK,
        };

        GetMouvementById(mouvement_a_mettre_a_jour.IDMOUVEMENT).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.MOUVEMENT.add(mouvement_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(mouvement_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.MOUVEMENT.put(mouvement_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _mouvementCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_mouvementCount} mouvements`);

    return val;
  });
}
