import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { IsOracle } from "./../../../Helpers/Test/IsOracle";
import { GetThanatomorphoseById } from "../../../Helpers/Get/Thanatomorphose";
import { GetLieuById } from "../../../Helpers/Get/Lieu";
import { GetEtatTerrainById } from "./../../../Helpers/Get/EtatTerrain";
import { GetAccessibiliteById } from "./../../../Helpers/Get/Accessibilite";
import { GetParam } from "./../../../Helpers/Get/Param";
import { GetTemporaireForColor } from "../../../Helpers/Get/Temporaire";
import { withCookies } from "react-cookie";
import { CheckUserRight } from "./../../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "./../../Global/DialogAccessRight";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    padding: 16,
  },
  papertitle: {
    display: "flex",
    marginBottom: 20,
  },
  typographytitle: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    justifyContent: "center",
    marginBottom: 0,
    paddingRight: 95,
  },
};

class CFCLieu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      PARAM: "",
      LIEU: "",
      THANATO: "",
      ETAT_TERRAIN: "",
      ACCESSIBILITE: "",
      REDIRECT: null,
      champ_modifie: [],
      droit_util_modif_lieu: false,
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle });
    });

    GetParam().then((param) => {
      this.setState({ PARAM: param });
    });

    GetLieuById(this.props.concess.LICLEUNIK).then((lieu) => {
      GetThanatomorphoseById(lieu.IDTHANATOMORPHOSE).then((thanato) => {
        GetEtatTerrainById(lieu.IDETAT_TERRAIN).then((etat_terrain) => {
          GetAccessibiliteById(lieu.IDACCESSIBILITE).then((accessibilite) => {
            this.setState({ LIEU: lieu, THANATO: thanato || "", ETAT_TERRAIN: etat_terrain || "", ACCESSIBILITE: accessibilite || "" });
          });
        });
      });
    });

    let field = {};
    const temporaire = GetTemporaireForColor("LIEU", "LICLEUNIK", this.props.concess.LICLEUNIK);
    temporaire.then((temp) => {
      _.forEach(temp, (t) => {
        let color = "red";

        if (t.DATE_D_ENVOI_A_GESCIME !== null) {
          color = "blue";
        }

        if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
          color = "green";
        }

        if (t.DATE_TRAITE_DANS_GESCIME !== null) {
          color = "primary";
        }

        var element_temp = t.TABLE_MODIFIE_JSON;
        element_temp = _.omit(element_temp, ["id", "LICLEUNIK"]);

        for (var key in element_temp) {
          if (key === "EMPLACEMENT") {
            for (var subkey in t.TABLE_MODIFIE_JSON[key]) {
              field[subkey] = color;
            }
          } else {
            field[key] = color;
          }
        }
      });

      this.setState({
        champ_modifie: field,
      });
    });
  };

  _cree_chaine_position_stele = () => {
    switch (this.state.LIEU.EMPLACEMENT.POSITIONSTELE) {
      case 2:
        return "En haut";
      case 3:
        return "En bas";
      case 4:
        return "A gauche";
      case 5:
        return "A droite";
      case 6:
        return "Au dessus";
      default:
        return "";
    }
  };

  render() {
    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return this.state.LIEU !== undefined && this.state.LIEU.EMPLACEMENT !== undefined ? (
      <Paper elevation={0} style={styles.paper}>
        <Paper elevation={0} style={styles.papertitle}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              CheckUserRight(this.state.ID_UTIL, "LIEU", "DROITMODIF").then((asRight) => {
                if (asRight) {
                  this.setState({
                    REDIRECT: `/modifier/lieu/${this.state.LIEU.LICLEUNIK}`,
                  });
                } else {
                  this.setState({ droit_util_modif_lieu: true });
                }
              });
            }}
          >
            Modifier
          </Button>

          <Typography variant="body2" gutterBottom style={styles.typographytitle}>
            Emplacement
          </Typography>
        </Paper>

        <Table>
          <TableBody>
            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(this.state.champ_modifie, "IDTHANATOMORPHOSE") ? _.get(this.state.champ_modifie, "IDTHANATOMORPHOSE") : "primary",
                  }}
                >
                  Thanatomorphose
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(this.state.champ_modifie, "IDTHANATOMORPHOSE") ? _.get(this.state.champ_modifie, "IDTHANATOMORPHOSE") : "primary",
                  }}
                >
                  {this.state.THANATO.LIBELLE}
                </TableCell>
              </TableRow>
            ) : null}

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(this.state.champ_modifie, "IDETAT_TERRAIN") ? _.get(this.state.champ_modifie, "IDETAT_TERRAIN") : "primary",
                  }}
                >
                  Nature du sol
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(this.state.champ_modifie, "IDETAT_TERRAIN") ? _.get(this.state.champ_modifie, "IDETAT_TERRAIN") : "primary",
                  }}
                >
                  {this.state.ETAT_TERRAIN.LIBELLE}
                </TableCell>
              </TableRow>
            ) : null}

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(this.state.champ_modifie, "IDACCESSIBILITE") ? _.get(this.state.champ_modifie, "IDACCESSIBILITE") : "primary",
                  }}
                >
                  Accessibilité
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(this.state.champ_modifie, "IDACCESSIBILITE") ? _.get(this.state.champ_modifie, "IDACCESSIBILITE") : "primary",
                  }}
                >
                  {this.state.ACCESSIBILITE.LIBELLE}
                </TableCell>
              </TableRow>
            ) : null}
            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(this.state.champ_modifie, "GELE") ? _.get(this.state.champ_modifie, "GELE") : "primary",
                  }}
                >
                  Emplacement gelé
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(this.state.champ_modifie, "GELE") ? _.get(this.state.champ_modifie, "GELE") : "primary",
                  }}
                >
                  {this.state.LIEU.EMPLACEMENT.GELE === true ? "Oui" : "Non"}
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell
                style={{
                  color: _.has(this.state.champ_modifie, "POSITIONSTELE") ? _.get(this.state.champ_modifie, "POSITIONSTELE") : "primary",
                }}
              >
                Entrée caveau
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(this.state.champ_modifie, "POSITIONSTELE") ? _.get(this.state.champ_modifie, "POSITIONSTELE") : "primary",
                }}
              >
                {this._cree_chaine_position_stele()}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  color: _.has(this.state.champ_modifie, "MONUMENTPRESENT") ? _.get(this.state.champ_modifie, "MONUMENTPRESENT") : "primary",
                }}
              >
                Monument restant
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: _.has(this.state.champ_modifie, "MONUMENTPRESENT") ? _.get(this.state.champ_modifie, "MONUMENTPRESENT") : "primary",
                }}
              >
                {this.state.LIEU.EMPLACEMENT.MONUMENTPRESENT === true ? "Oui" : "Non"}
              </TableCell>
            </TableRow>

            {this.state.IS_ORACLE ? (
              <TableRow>
                <TableCell
                  style={{
                    color: _.has(this.state.champ_modifie, "PASSE_PIED") ? _.get(this.state.champ_modifie, "PASSE_PIED") : "primary",
                  }}
                >
                  {this.state.PARAM.LIBPASSEPIEDS || "Passe-pieds"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: _.has(this.state.champ_modifie, "PASSE_PIED") ? _.get(this.state.champ_modifie, "PASSE_PIED") : "primary",
                  }}
                >
                  {this.state.LIEU.EMPLACEMENT.PASSE_PIED === true ? "Oui" : "Non"}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>

        <DialogAccessRight state={this.state.droit_util_modif_lieu} onChange={(value) => this.setState({ droit_util_modif_lieu: value })} />
      </Paper>
    ) : null;
  }
}

export default withCookies(CFCLieu);
