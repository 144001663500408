import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateLieu(headers) {
  const _lieuCount = await GetApiData("LIEUCount", headers);

  if (_lieuCount.Message !== undefined) {
    return _lieuCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("LIEU", _lieuCount, 2500, headers, (getProgress, getLieu) => {
      if (getLieu.Message !== undefined) {
        return res(getLieu);
      }

      var drops = [];
      _.forEach(getLieu, (lieu) => {
        drops.push({
          LICLEUNIK: lieu.LICLEUNIK,
          EMPLACEMENT: {
            TYPECAR: lieu.TYPECAR.trim(),
            CARRE: lieu.CARRE.trim(),
            TYPERG: lieu.TYPERG.trim(),
            RANG: lieu.RANG.trim(),
            TYPEFOS: lieu.TYPEFOS.trim(),
            TOMBE: lieu.TOMBE.trim(),
            ETAT: lieu.ETAT, // 1 = libre - 2 = occupe
            GELE: lieu.GELE,
            H1: lieu.H1,
            V1: lieu.V1,
            H2: lieu.H2,
            V2: lieu.V2,
            H3: lieu.H3,
            V3: lieu.V3,
            H4: lieu.H4,
            V4: lieu.V4,
            POSITIONSTELE: lieu.POSITIONSTELE,
            MONUMENTPRESENT: lieu.MONUMENTPRESENT,
            PASSE_PIED: lieu.PASSE_PIED,
          },
          COCLEUNIK: lieu.COCLEUNIK,
          CIMEL: lieu.CIMEL,
          TOMBEL: lieu.TOMBEL,
          NOMBREL: lieu.NOMBREL,
          DUREEL: lieu.DUREEL,
          POSNUM: lieu.POSNUM,
          SITUATION: lieu.SITUATION,
          SITUATION_ALL_1: lieu.SITUATION_ALL.split("/")[0] !== undefined && lieu.SITUATION_ALL.split("/")[0] !== "" ? parseInt(lieu.SITUATION_ALL.split("/")[0]) : 0,
          SITUATION_ALL_2: lieu.SITUATION_ALL.split("/")[1] !== undefined ? parseInt(lieu.SITUATION_ALL.split("/")[1]) : 0,
          SITUATION_ALL_3: lieu.SITUATION_ALL.split("/")[2] !== undefined ? parseInt(lieu.SITUATION_ALL.split("/")[2]) : 0,
          SITUATION_ALL_4: lieu.SITUATION_ALL.split("/")[3] !== undefined ? parseInt(lieu.SITUATION_ALL.split("/")[3]) : 0,
          TAILLEPOLICEDULIEU: lieu.TAILLEPOLICEDULIEU,
          AFFMULTILIGNE: lieu.AFFMULTILIGNE,
          LIEU_MULTIPLE_PRINC_VIRTUELLE: lieu.LIEU_MULTIPLE_PRINC_VIRTUELLE,
          IDTHANATOMORPHOSE: lieu.IDTHANATOMORPHOSE,
          IDETAT_TERRAIN: lieu.IDETAT_TERRAIN,
          IDACCESSIBILITE: lieu.IDACCESSIBILITE,
        });
      });

      db.open()
        .then(() => {
          db.LIEU.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _lieuCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_lieuCount} lieux`);

    return val;
  });
}
