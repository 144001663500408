import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `#${process.env.REACT_APP_PALETTE_PRIMARY}`,
    },
    secondary: {
      main: `#${process.env.REACT_APP_PALETTE_SECONDARY}`,
    },
  },
});

export default theme;
