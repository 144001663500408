import Dexie from "dexie";
import db from "../Db";

var AddConcaire = Dexie.async(function* (CONCAIRE) {
  try {
    yield db.open();
    var id = yield db.CONCAIRE.add(CONCAIRE);
    return id;
  } finally {
    db.close();
  }
});

export default AddConcaire;
