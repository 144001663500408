import db from "../Db";
import { GetAllAgendaTache } from "./AgendaTache";

var _ = require("lodash");

export function GetAllConcess() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetAllConcessInTask() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetAllAgendaTache().then((tache) => {
      let LICLEUNIK = [];

      _.forEach(tache, (t) => {
        if (t.LICLEUNIK > 0) {
          LICLEUNIK.push(t.LICLEUNIK);
        }
      });

      db.CONCESS.where("LICLEUNIK")
        .anyOf(LICLEUNIK)
        .toArray()
        .then((result) => {
          return resolve(result);
        });
    });
  });
}

export function GetConcessByRowId(id) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.get(parseInt(id)).then((result) => {
      return resolve(result);
    });
  });
}

export function GetConcessById(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.where("COCLEUNIK")
      .equals(parseInt(COCLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetConcessNewId() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.toCollection()
      .last()
      .then((result) => {
        if (result === undefined) {
          return resolve(1);
        } else {
          return resolve(result.id + 1);
        }
      });
  });
}

export function GetConcessByIdLieu(LICLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.where("LICLEUNIK")
      .equals(LICLEUNIK)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetConcessByIdCimetier(NUMCIME) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.where("TYPECIME")
      .equals(parseInt(NUMCIME))
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetConcessNonAffecte(FAMILLE, NUMCIME) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.where("TYPECONC")
      .equals(2)
      .and((item) => _.includes(item.FAMILLE.toUpperCase(), FAMILLE) && item.TYPECIME === NUMCIME)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetConcessByIdProcedureAbandon(IDPROCEDUREABANDON) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.where("IDPROCEDUREABANDON")
      .equals(parseInt(IDPROCEDUREABANDON))
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetAllExpiredConcess(oracle) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.where("DATEEXPIRA")
      .notEqual("1900-01-01T00:00:00")
      .and((item) => {
        return new Date(item.DATEEXPIRA) <= Date.now();
      })
      .toArray()
      .then((result) => {
        if (oracle) {
          result = _.filter(result, (c) => {
            return c.CONCESSIONTYPE !== 3;
          });
        }

        return resolve(result);
      });
  });
}
