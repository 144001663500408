import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const styles = {
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

export function DialogAccessRight(props) {
  const { state, onChange } = props;

  return (
    <Dialog onClose={() => onChange(false)} open={state}>
      <DialogTitle onClose={() => onChange(false)}>
        <Typography variant="h6" component="p">
          Attention
        </Typography>

        <IconButton aria-label="close" style={styles.closeButton} onClick={() => onChange(false)}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>Vous n’avez pas les droits suffisants pour réaliser cette opération </Typography>
      </DialogContent>
    </Dialog>
  );
}
