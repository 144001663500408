import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateSociete(headers) {
  const _societeCount = await GetApiData("SOCIETECount", headers);

  if (_societeCount.Message !== undefined) {
    return _societeCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("SOCIETE", _societeCount, 2500, headers, (getProgress, getSociete) => {
      if (getSociete.Message !== undefined) {
        return res(getSociete);
      }

      var drops = [];
      _.forEach(getSociete, (societe) => {
        drops.push({
          SOCLEUNIK: societe.SOCLEUNIK,
          NOMSOCIETE: societe.NOMSOCIETE,
          ADRESSE: societe.ADRESSE,
          CODEPO: societe.CODEPO,
          VILLE: societe.VILLE,
        });
      });

      db.open()
        .then(() => {
          db.SOCIETE.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _societeCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_societeCount} sociétés`);

    return val;
  });
}
