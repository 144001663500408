import React, { Component } from "react";
import { Grid, Paper, TextField, MenuItem } from "@material-ui/core";
import { GetAllCimetier } from "../../Helpers/Get/Cimetier";

import PlanOperateur from "../PlanOperateur";
import { GetPlanById } from "./../../Helpers/Get/Plan";
import CListeEmplacementsPlan from "./CListeEmplacementsPlan";
import { GetLieuById } from "../../Helpers/Get/Lieu";
import { GetCimetierById } from "./../../Helpers/Get/Cimetier";
import Opposition from "./../Global/OppositionTablette";
import { withCookies } from "react-cookie";

var _ = require("lodash");

const styles = {
  grid: {
    flex: 1,
    overflow: "auto",
  },
  grid_item: {},
  paper: {
    flex: 1,
    padding: 16,
  },
  button_gmap: {
    float: "right",
  },
};

class CCartographie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SELECTED_CIMETIER: "",
      REAL_CIMETIER: "",
      CIMETIER: [],
      PLAN: "",
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    GetAllCimetier(0).then((cimetier) => {
      let id_lieu = this.props.id_lieu !== undefined ? this.props.id_lieu : !isNaN(parseInt(this.props.type)) ? this.props.type : undefined;

      if (id_lieu !== undefined) {
        GetLieuById(id_lieu).then((lieu) => {
          GetCimetierById(lieu.CIMEL).then((cime) => {
            GetAllCimetier(1).then((allcime) => {
              let filtered = _.filter(allcime, (ac) => {
                return ac.NUMPLAN === cime.NUMPLAN && ac.IMPRIMECOLUMB === 0;
              })[0];

              GetPlanById(cime.NUMPLAN).then((plan) => {
                this.setState({
                  SELECTED_CIMETIER: filtered.NUMCIME,
                  REAL_CIMETIER: cime.NUMCIME,
                  CIMETIER: cimetier,
                  PLAN: plan,
                });
              });
            });
          });
        });
      } else {
        let filtered = cimetier;
        if (this.props.id_cimetiere !== undefined) {
          filtered = _.filter(cimetier, (c) => {
            return c.NUMCIME === parseInt(this.props.id_cimetiere);
          });
        }

        GetPlanById(filtered[0].NUMPLAN).then((plan) => {
          this.setState({
            SELECTED_CIMETIER: filtered[0].NUMCIME,
            REAL_CIMETIER: filtered[0].NUMCIME,
            CIMETIER: cimetier,
            PLAN: plan,
          });
        });
      }
    });
  };

  _handle_change_cimetier = (event) => {
    const cime = _.find(this.state.CIMETIER, ["NUMCIME", event.target.value]);

    GetPlanById(cime.NUMPLAN).then((plan) => {
      this.setState({
        SELECTED_CIMETIER: cime.NUMCIME,
        REAL_CIMETIER: cime.NUMCIME,
        PLAN: plan,
      });
    });
  };

  render() {
    console.log("RENDER CARTOGRAPHIE");

    return (
      <Grid container alignItems="center" justify="center" style={styles.grid}>
        <Grid item xs={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <TextField select fullWidth margin="normal" label="Cimetière" value={this.state.SELECTED_CIMETIER} onChange={this._handle_change_cimetier}>
              {this.state.CIMETIER.map((cime, index) => (
                <MenuItem key={index} value={cime.NUMCIME}>
                  {cime.NOMCIME}
                </MenuItem>
              ))}
            </TextField>

            {this.state.PLAN.IMAGEPL !== "" || this.state.PLAN.IMAGESVG !== "" ? (
              this.state.SELECTED_CIMETIER !== "" ? (
                <PlanOperateur type={this.props.type} id_lieu={this.props.id_lieu} numcime={this.state.SELECTED_CIMETIER} vrainumcime={this.state.REAL_CIMETIER} />
              ) : null
            ) : (
              <CListeEmplacementsPlan type={this.props.type} numcime={this.state.SELECTED_CIMETIER} />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(CCartographie);
