import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";

var _ = require("lodash");

export async function CreateInterven(headers) {
  const _intervenCount = await GetApiData("INTERVENCount", headers);

  if (_intervenCount.Message !== undefined) {
    return _intervenCount;
  }

  const promise = new Promise((res, rej) => {
    addTableWithProgress("INTERVEN", _intervenCount, 2500, headers, (getProgress, getInterven) => {
      if (getInterven.Message !== undefined) {
        return res(getInterven);
      }

      var drops = [];
      _.forEach(getInterven, (interven) => {
        drops.push({
          I0CLEUNIK: interven.I0CLEUNIK,
          DEMANDEUR: {
            NOM: interven.NOM,
            PRENOM: interven.PRENOM,
          },
          INTERVENANT_NOM: interven.INTERVENANT_NOM,
          DATETRAVAUX: interven.DATETRAVAUX,
          DATETRAVAUX_FIN: interven.DATETRAVAUX_FIN,
          INTERVENANT_TYPE: interven.INTERVENANT_TYPE,
          SUIVITRAV: interven.SUIVITRAV,
          IDMOUVEMENT: interven.IDMOUVEMENT,
          SOCLEUNIK: interven.SOCLEUNIK,
          LICLEUNIK: interven.LICLEUNIK,
          COCLEUNIK: interven.COCLEUNIK,
          TYPECIME: interven.TYPECIME,
          EMPL_NATURE: interven.EMPL_NATURE,
          EMPL_TYPE: interven.EMPL_TYPE,
          EMPL_DEF_OU_CONCESSION: interven.EMPL_DEF_OU_CONCESSION,
          TRAVAUX: interven.TRAVAUX,
        });
      });

      db.open()
        .then(() => {
          db.INTERVEN.bulkAdd(drops)
            .then(() => {
              if (getProgress >= _intervenCount) res("");
            })
            .catch((err) => {
              rej(handleError(err));
            });
        })
        .catch((err) => {
          rej(handleError(err));
        });
    });
  });

  return await promise.then((val) => {
    console.log(`Insertion de ${_intervenCount} interventions`);

    return val;
  });
}
