import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { sha256 } from "js-sha256";
import { GetApiKey } from "../Helpers/Get/ApiKey";
import { GetApiData } from "../Helpers/Get/ApiData";
import isOnline from "is-online";
import base64 from "base64-arraybuffer";

import App from "../App";
import ParamConfig from "./Param/ParamConfig";
import ParamCimetiere from "./Param/ParamCimetiere";
import CreateTable from "../Helpers/CreateTable";
import CreateTableSansPhotos from "../Helpers/CreateTableSansPhotos";
import { GetLastestGescimeBuild } from "../Helpers/Get/LastestGescimeBuild";

import jsonpackage from "../../package.json";
import conf from "../assets/.conf.txt";

import db from "../Helpers/Db";
import { GetClient } from "./../Helpers/Get/Client";
import NewVersionAvailableSnackBar from "./../NewVersionAvailableSnackBar";
import LoaderState from "./Global/Object/LoaderState";

var _ = require("lodash");

const styles = {
  grid_container: {
    flex: 1,
  },
  paper: {
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

function generatePassword(identifiant, motdepasse, alias) {
  let buffer;

  buffer = sha256.arrayBuffer(`${motdepasse}${alias}`);
  if (identifiant === "gescime" && motdepasse === "copernic") buffer = sha256.arrayBuffer(motdepasse);

  return base64.encode(buffer);
}

function setCookies(cookieProps, cle_api, alias) {
  const expire = new Date();
  expire.setFullYear(expire.getFullYear() + 10);

  cookieProps.set(encodeURIComponent(btoa("APIKEY")), btoa(cle_api), {
    path: "/",
    expires: expire,
  });

  cookieProps.set(encodeURIComponent(btoa("ALIAS")), btoa(alias), {
    path: "/",
    expires: expire,
  });
}

class Param extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      is_online: null,
      alias: "",
      identifiant: "",
      motdepasse: "",
      cle_api: "",
      liste_cimetiere: [],
      cimetiere: "0",
      loading: false,
      liste_tables: [
        "GNUTIL",
        "PARAM",
        "DEFUNT",
        "LIEU",
        "CENDRE",
        "PLAN",
        "ZONE",
        "CONCESS",
        "CONCAIRE",
        "TYPETB",
        "DUREE",
        "PHOTOCONC",
        "INTERVEN",
        "INTERVEN_DEFUNT",
        "NATINTERV",
        "PROCEDUREABANDON",
        "SOCIETE",
        "UTIL",
        "GENS",
        "AGENDATACHE",
        "MOUVEMENT",
        "ACCESSIBILITE",
        "ETAT_TERRAIN",
        "CONCESS_ETAT_ECHEANCE",
        "THANATOMORPHOSE",
        "MATERIAU",
        "PA",
        "LIGNE_ACTION",
        "SVG",
        "DEFUNT_S",
      ],
      data_loaded: 0,
      table_loading: "",
      nb_total_photos_loading: 0,
      nb_photos_enregistre: 0,
      disabledForm: false,
      dialog_version: false,
      dialog_version_bdd: false,
      dialog_erreur: false,
      dialog_cimetier: false,
      erreurIdentifiants: false,
    };
  }

  componentDidMount = () => {
    const is_online = isOnline();

    is_online.then((status) => {
      this.setState({ is_online: status });
    });
  };

  submit_param_config = (event) => {
    event.preventDefault();

    const identifiant = this.state.identifiant;
    const alias = this.state.alias;
    const motdepasse = generatePassword(identifiant, this.state.motdepasse, alias);

    const expire = new Date();
    expire.setFullYear(expire.getFullYear() + 10);

    this.props.cookies.set(encodeURIComponent(btoa("IDENTIFIANT")), btoa(identifiant), {
      path: "/",
      expires: expire,
    });

    this.props.cookies.set(encodeURIComponent(btoa("MOT_DE_PASSE")), btoa(motdepasse), {
      path: "/",
      expires: expire,
    });

    const getApiKey = GetApiKey(alias, identifiant, motdepasse);
    getApiKey.then((result) => {
      if (result === undefined) {
        this.setState({ erreurIdentifiants: true });
      } else {
        const cle_api = result;

        console.log({
          cle_api,
          alias,
          identifiant,
          motdepasse,
        });

        fetch(`${jsonpackage.urlapi}IsBddValid`, {
          mode: "cors",
          headers: {
            _cle: cle_api,
            _identifiant: identifiant,
            _motdepasse: motdepasse,
          },
        })
          .then((r) => r.text())
          .then((text) => {
            if (text === "true") {
              const getApiData = GetApiData("CIMETIER", {
                APIKEY: cle_api,
                ALIAS: alias,
                IDENTIFIANT: identifiant,
                MOT_DE_PASSE: motdepasse,
                DATE_SYNCHRO: "1900-01-01",
              });

              getApiData.then((result) => {
                let liste_cimetiere = _.filter(result, (cimetiere) => {
                  return cimetiere.IMPRIMECOLUMB === false || cimetiere.IMPRIMECOLUMB === 0;
                });

                this.setState({
                  cle_api: cle_api,
                  liste_cimetiere: liste_cimetiere,
                  cimetiere: `0,${_.map(liste_cimetiere, (lc) => {
                    return lc.NUMCIME;
                  })}`,
                });
              });
            } else {
              this.setState({ dialog_version_bdd: true });
            }
          });
      }
    });
  };

  _change_loader_state = () => {
    this.setState({
      data_loaded: LoaderState.getIndex(),
      table_loading: LoaderState.getTable(),
      nb_total_photos_loading: LoaderState.getNombreTotalPhotos(),
      nb_photos_enregistre: LoaderState.getNombrePhotos(),
    });
  };

  submit_param_cimetiere = (event) => {
    event.preventDefault();

    if (this.state.disabledForm) return;

    this.setState({ disabledForm: true }, () => {
      const cimetiere = this.state.cimetiere;
      const cle_api = this.state.cle_api;
      const alias = this.state.alias;
      const identifiant = this.state.identifiant;
      const motdepasse = generatePassword(identifiant, this.state.motdepasse, alias);

      setCookies(this.props.cookies, cle_api, alias);

      var liste_cimetiere = this.state.liste_cimetiere;
      if (cimetiere !== "0") {
        liste_cimetiere = _.filter(liste_cimetiere, (cime) => {
          return _.includes(cimetiere, cime.NUMCIME);
        });
      }

      if (liste_cimetiere.length > 0) {
        this.setState({ loading: true }, () => {
          const headers = {
            APIKEY: cle_api,
            ALIAS: alias,
            IDENTIFIANT: identifiant,
            MOT_DE_PASSE: motdepasse,
            DATE_SYNCHRO: "1900-01-01",
          };

          const lastestGescimeBuild = GetLastestGescimeBuild(headers);
          lastestGescimeBuild.then((version) => {
            fetch(conf)
              .then((r) => r.text())
              .then((text) => {
                GetApiData("CLIENT", headers).then((client) => {
                  const oracle = client.ORACLE;

                  let json = {};

                  const keyValuePair = (kvStr) => {
                    const kvPair = kvStr.split("=").map((val) => val.trim());
                    var jsonKey = kvPair[0];

                    json[jsonKey] = kvPair[1];
                  };

                  _.forEach(text.split("\n"), (t) => {
                    keyValuePair(t);
                  });

                  if (version < (oracle === 1 ? json.VERSION_GESCIME_ORACLE : json.VERSION_GESCIME_HF)) {
                    this.setState({ dialog_version: true, disabledForm: false, loading: false });
                  } else {
                    const createTable = this.props.recommencerSansPhotos
                      ? CreateTableSansPhotos(liste_cimetiere, headers, this._change_loader_state)
                      : CreateTable(liste_cimetiere, headers, this._change_loader_state);

                    createTable
                      .then(() => {
                        LoaderState.setIndex(0);
                        LoaderState.setTable("");

                        const headers = {
                          _cle: atob(this.props.cookies.get(encodeURIComponent(btoa("APIKEY")))),
                          "Content-Type": "application/json",
                        };

                        GetClient().then((clientTablette) => {
                          const data = {
                            ID_TABLETTE: clientTablette.ID_TABLETTE,
                            ID_CLIENT: clientTablette.IDCLIENT,
                            NOM_UTIL_GESCIME: null,
                          };

                          fetch(`${jsonpackage.urlapi}EnregistrementTablette`, {
                            mode: "cors",
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(data),
                          })
                            .then((result) => result.text())
                            .then((result) => {
                              if (result === "true") {
                                this.setState({ loaded: true });
                              } else {
                                console.error("Une erreur s'est produite lors de l'enregistrement de la tablette");

                                this.setState({ dialog_erreur: true, disabledForm: false, loading: false }, () => {
                                  db.delete();
                                });
                              }
                            });
                        });
                      })
                      .catch((err) => {
                        console.error(err);

                        this.setState({ dialog_erreur: true, disabledForm: false, loading: false }, () => {
                          db.delete();
                        });
                      });
                  }
                });
              });
          });
        });
      } else {
        this.setState({ dialog_cimetier: true, disabledForm: false, loading: false });
      }
    });
  };

  change_param_config_field = (champ) => (event) => {
    event.preventDefault();

    this.setState({ [champ]: event.target.value, erreurIdentifiants: false });
  };

  click_param_cimetiere_selection = (value) => (event) => {
    event.preventDefault();

    let numcime = _.map(this.state.liste_cimetiere, (lc) => {
      return lc.NUMCIME;
    });

    let cimetiere = this.state.cimetiere;
    let arrcim = cimetiere !== "" ? cimetiere.split(",").map(Number) : [];

    if (value === 0) {
      if (_.includes(arrcim, value)) {
        this.setState({ cimetiere: "" });
      } else {
        this.setState({ cimetiere: `${value.toString()},${numcime.join(",")}` });
      }
    } else {
      if (_.includes(arrcim, value)) {
        arrcim = _.filter(arrcim, (arr) => {
          return arr !== value;
        });

        let arrcim_sans_zero = _.filter(arrcim, (arr) => {
          return arr !== 0;
        });

        if (!_.isEqual(arrcim_sans_zero, numcime)) {
          arrcim = arrcim_sans_zero;
        }

        cimetiere = arrcim.join(",");

        if (cimetiere === "0") cimetiere = "";
      } else {
        arrcim.push(value);

        arrcim = arrcim.sort((a, b) => {
          return a - b;
        });

        if (_.isEqual(arrcim, numcime)) {
          arrcim.push(0);

          arrcim = arrcim.sort((a, b) => {
            return a - b;
          });
        }

        cimetiere = arrcim.join(",");
      }

      this.setState({ cimetiere: cimetiere });
    }
  };

  click_param_cimetiere_retour = (event) => {
    event.preventDefault();

    let numcime = _.map(this.state.liste_cimetiere, (lc) => {
      return lc.NUMCIME;
    });

    this.setState({ liste_cimetiere: [], cimetiere: `0,${numcime.join(",")}` });
  };

  render() {
    if (this.state.loaded === true) {
      return <App />;
    }

    if (this.state.is_online === false) {
      return (
        <Grid container justify="center" alignItems="center" style={styles.grid_container}>
          <Grid item xs={8}>
            <Paper style={styles.paper}>
              <Typography variant="h5" gutterBottom>
                Lors de la première utilisation de l'application "Gardiens" vous devez vous connecter à internet afin de télécharger les données de l'application.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      );
    }

    if (this.state.liste_cimetiere.length === 0) {
      return (
        <div style={{ display: "contents" }}>
          <ParamConfig
            submit_param_config={this.submit_param_config}
            change_param_config_field={this.change_param_config_field}
            alias={this.state.alias}
            identifiant={this.state.identifiant}
            motdepasse={this.state.motdepasse}
            erreurIdentifiants={this.state.erreurIdentifiants}
          />

          <Dialog open={this.state.dialog_version_bdd} onClose={() => this.setState({ dialog_version_bdd: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle onClose={() => this.setState({ dialog_version_bdd: false })} id="alert-dialog-title">
              <Typography variant="h6" component="p">
                Base de données non compatible
              </Typography>

              <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_version_bdd: false })}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Le type de base de données de votre site Gescime.Net (Hyperfile) ne vous permet pas d’utiliser l’application ‘Gardiens de cimetières’.
                <br />
                Merci de contacter notre support technique
              </DialogContentText>
            </DialogContent>
          </Dialog>

          <NewVersionAvailableSnackBar newVersionAvailable={this.props.newVersionAvailable} setNewVersionAvailable={this.props.setNewVersionAvailable}>
            Une nouvelle version de l'application 'Gardiens de cimetières' est disponible. Afin de la télécharger merci de fermer et rouvrir l'application 'Gardiens de cimetières'
          </NewVersionAvailableSnackBar>
        </div>
      );
    }

    console.log((this.state.data_loaded * 100) / this.state.liste_tables.length);

    return (
      <div style={{ display: "contents" }}>
        <ParamCimetiere
          submit_param_cimetiere={this.submit_param_cimetiere}
          click_param_cimetiere_selection={this.click_param_cimetiere_selection}
          click_param_cimetiere_retour={this.click_param_cimetiere_retour}
          liste_cimetiere={this.state.liste_cimetiere}
          cimetiere={this.state.cimetiere}
          loading={this.state.loading}
          data_loaded={(this.state.data_loaded * 100) / this.state.liste_tables.length}
          table_loading={this.state.table_loading}
          nb_total_photos_loading={this.state.nb_total_photos_loading}
          nb_photos_enregistre={this.state.nb_photos_enregistre}
          disabledForm={this.state.disabledForm}
        />

        <Dialog open={this.state.dialog_version} onClose={() => this.setState({ dialog_version: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle onClose={() => this.setState({ dialog_version: false })} id="alert-dialog-title">
            <Typography variant="h6" component="p">
              Version de Gescime non compatible
            </Typography>

            <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_version: false })}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Votre version de Gescime ne vous permet pas d'utiliser l'application 'Gardiens de cimetières'
              <br />
              Veuillez mettre à jour votre logiciel Gescime afin de pouvoir utiliser l’application ‘Gardiens de cimetières’
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.dialog_erreur} onClose={() => this.setState({ dialog_erreur: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle onClose={() => this.setState({ dialog_erreur: false })} id="alert-dialog-title">
            <Typography variant="h6" component="p">
              Erreur
            </Typography>

            <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_erreur: false })}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Une erreur a été détectée lors de la mise à jour des données de l’application ‘Gardiens de cimetières’
              <br />
              Veuillez réessayer ultérieurement
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.dialog_cimetier} onClose={() => this.setState({ dialog_cimetier: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle onClose={() => this.setState({ dialog_cimetier: false })} id="alert-dialog-title">
            <Typography variant="h6" component="p">
              Attention
            </Typography>

            <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_cimetier: false })}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Veuillez sélectioner au moins un cimetière dans la liste</DialogContentText>
          </DialogContent>
        </Dialog>

        <NewVersionAvailableSnackBar newVersionAvailable={this.props.newVersionAvailable} setNewVersionAvailable={this.props.setNewVersionAvailable}>
          Une nouvelle version de l'application 'Gardiens de cimetières' est disponible. Afin de la télécharger merci de fermer et rouvrir l'application 'Gardiens de cimetières'
        </NewVersionAvailableSnackBar>
      </div>
    );
  }
}

export default withCookies(Param);
