import { GetApiData } from "./Get/ApiData";
import Resizer from "react-image-file-resizer";

var _ = require("lodash");

function OrderArray(array) {
  var StringArray = [],
    IntArray = [];

  // Pour chaque élément du tableau :
  array.forEach((element) => {
    // Si l'élément peut être converti en entier et qu'il ne commence pas par 0, On le pousse dans un tableau d'entier sinon on le pousse dans un tableau de chaine
    if (!isNaN(element) && element[0] !== "0") {
      IntArray.push(element);
    } else {
      StringArray.push(element);
    }
  });

  // On tri les deux tableau
  IntArray.sort((a, b) => a - b);
  StringArray.sort();

  // On crée deux nouveau tableau : un tableau qui contiendra les chaine commencant par 0 et un nouveau tableau d'entier qu'on initialise à la valeur du tableau d'entier précédent
  var zeroArr = [],
    IntArrayReturn = IntArray;

  // Pour chaque élément du tableau de chaine :
  _.forEach(StringArray, (sa) => {
    var index = 0;

    // Si l'élément est une chaine vide, on le place en premier dans le nouveau tableau d'entier
    if (sa === " ") {
      IntArrayReturn.splice(0, 0, sa);

      // on le supprime du tableau de chaine
      StringArray = _.filter(StringArray, (sar) => {
        return sar !== sa;
      });
    }

    // Si l'élément commence par 0 :
    if (sa[0] === "0") {
      // On l'ajoute dans le tableau des zéro
      zeroArr.push(sa);

      // On le supprime du tableau de chaine
      StringArray = _.filter(StringArray, (sar) => {
        return sar !== sa;
      });
    } else {
      // Sinon si l'élément peut être converti en entier :
      if (!Number.isNaN(parseInt(sa))) {
        // Pour chaque élément du nouveau tableau d'entier :
        _.forEach(IntArrayReturn, (iar) => {
          // On recupére l'index de l'élément ayant la valeur la plus proche ou égale à l'élément
          if (parseInt(iar) <= parseInt(sa)) {
            index = IntArrayReturn.indexOf(iar);
          } else {
            // Sinon, il n'y a pas de correspondance : on initialise index à -1 et on sort de la boucle
            if (index === 0) {
              index = -1;
              return;
            }
          }
        });

        // Si le nouveau tableau d'entier est vide : on ajoute l'élément
        if (IntArrayReturn.length === 0) {
          IntArrayReturn.push(sa);
        } else {
          // Sinon on l'insère à l'index + 1
          IntArrayReturn.splice(index + 1, 0, sa);
        }

        // On supprime l'élément du tableau de chaine
        StringArray = _.filter(StringArray, (sar) => {
          return sar !== sa;
        });
      }
    }
  });

  // Si le tableau de zéro n'est pa vide :
  if (zeroArr.length > 0) {
    var index = 0;

    // Si le tableau contient une chaine vide : On initialise l'index à 1
    if (IntArrayReturn[0] === " ") index = 1;

    // Pour chaque élément du tableau de zéro :
    _.forEach(zeroArr, (za) => {
      // On insère l'élément à l'index suivant
      IntArrayReturn.splice(index, 0, za);

      index++;
    });
  }

  // On retourne une concaténation du nouveau tableau d'entier et de ce qui reste dans le tableau de chaine.
  return IntArrayReturn.concat(StringArray);
}

function GetSquare(alllieu) {
  var square = alllieu
    .map((l) => l.EMPLACEMENT.CARRE)
    .filter((s, index, self) => {
      return self.indexOf(s) === index;
    });

  square = _.map(square, (s) => {
    return s === "" ? " " : s;
  });

  square = _.filter(square, (s) => {
    return s !== "Non affectée" && s !== "Provisoire";
  });

  return OrderArray(square);
}

function GetRank(alllieu, carre) {
  let alllieu_with_square = alllieu.filter((alws) => alws.EMPLACEMENT.CARRE === carre);

  var rank = alllieu_with_square
    .map((l) => l.EMPLACEMENT.RANG)
    .filter((r, index, self) => {
      return self.indexOf(r) === index;
    });

  rank = _.map(rank, (r) => {
    return r === "" ? " " : r;
  });

  return OrderArray(rank);
}

function GetTomb(alllieu, carre, rang, cocleuink) {
  let alllieu_with_square_and_rank = alllieu.filter((alwsar) => alwsar.EMPLACEMENT.CARRE === carre && alwsar.EMPLACEMENT.RANG === rang);

  if (cocleuink !== undefined) {
    alllieu_with_square_and_rank = alllieu_with_square_and_rank.filter((alwsar) => alwsar.COCLEUNIK === cocleuink && alwsar.LIEU_MULTIPLE_PRINC_VIRTUELLE === false);
  }

  var tomb = alllieu_with_square_and_rank
    .map((l) => l.EMPLACEMENT.TOMBE)
    .filter((t, index, self) => {
      return self.indexOf(t) === index;
    });

  tomb = _.map(tomb, (t) => {
    return t === "" ? " " : t;
  });

  return OrderArray(tomb);
}

function GetLieuFromEmpl(alllieu, carre, rang, tomb) {
  let alllieu_with_square_and_rank_and_tomb = alllieu.filter((alwsarat) => alwsarat.EMPLACEMENT.CARRE === carre && alwsarat.EMPLACEMENT.RANG === rang && alwsarat.EMPLACEMENT.TOMBE === tomb);

  return alllieu_with_square_and_rank_and_tomb;
}

async function bulkAddWithProgress(table, objects, chunkSize, pos = 0) {
  await table.bulkAdd(objects.slice(pos, pos + chunkSize));

  if (objects.length > pos + chunkSize) {
    await bulkAddWithProgress(table, objects, chunkSize, pos + chunkSize);
  }
}

function bulkAddWithProgressForSvg(table, objects, chunkSize) {
  return new Promise((resolve, reject) => {
    var arrayOfArrays = [];
    for (let index = 0; index < objects.length; index += chunkSize) {
      arrayOfArrays.push(objects.slice(index, index + chunkSize));
    }

    for (let index = 0; index < arrayOfArrays.length; index++) {
      const element = arrayOfArrays[index];

      setTimeout(() => {
        table.bulkAdd(element).then(() => {
          console.log(`1 Enregistrement en cours des donnée svg`);

          if (index === arrayOfArrays.length - 1) resolve("");
        });
      }, index * 2500);
    }
  });
}

async function addTableWithProgress(table, count, chunkSize, headers, onProgress, pos = 0) {
  const objects = await GetApiData(`${table}?start=${pos}&end=${chunkSize}`, headers);

  onProgress(pos + chunkSize, objects);
  if (count > pos + chunkSize) {
    addTableWithProgress(table, count, chunkSize, headers, onProgress, pos + chunkSize);
  }
}

async function addTableWithProgressForPhotoconc(table, count, chunkSize, headers, onProgress, pos = 0) {
  const objects = await GetApiData(`${table}?start=${pos}&end=${chunkSize}`, headers);

  setTimeout(() => {
    onProgress(pos + chunkSize, objects);
    if (count > pos + chunkSize) {
      addTableWithProgress(table, count, chunkSize, headers, onProgress, pos + chunkSize);
    }
  }, 2500);
}

function handleError(e) {
  switch (e.name) {
    case "AbortError":
      if (e.inner) {
        return handleError(e.inner);
      }
      console.error("Abort error " + e.message);
      break;
    case "QuotaExceededError":
      console.error("QuotaExceededError " + e.message);
      break;
    default:
      console.error(e);
      break;
  }
}

function CreeTemporaire(idclient, idtablette, idutil, titre, entite, action, objet, etatInitiale) {
  return {
    ID_TEMPORAIRE: null,
    ID_CLIENT: idclient,
    ID_TABLETTE: idtablette,
    ID_UTIL: parseInt(idutil),
    ID_TYPE_TEMPORAIRE: null,
    TYPE_TEMPORAIRE: 3,
    DATE_DE_CREATION: null,
    DATE_D_ENVOI_A_GESCIME: null,
    DATE_D_IMPORT_DANS_GESCIME: null,
    ID_APPLICATION_GARDIENS: null,
    ID_GESCIME: null,
    TITRE: titre,
    TYPE_D_ENTITE: entite,
    TYPE_D_ACTION: action,
    TABLE_MODIFIE_JSON: objet,
    DATE_TRAITE_DANS_GESCIME: null,
    STATUS_DE_VALIDATION: 0,
    ETAT_INITIAL: etatInitiale,
  };
}

function Cree_iso_date(date, time) {
  if (date !== "" && date !== null) {
    if (date.length === 4) {
      let new_date = new Date(Date.UTC(date, 0, 1, 0, 0, 0, 0));

      return new_date.toISOString().slice(0, -5);
    } else {
      if (date.includes("/")) {
        let split_date = date.split("/");

        let new_date = new Date(split_date[2], parseInt(split_date[1]) - 1, split_date[0]);
        if (new_date <= new Date(1911, 3, 11)) {
          new_date.setMinutes(new_date.getMinutes() + 9);
          new_date.setSeconds(new_date.getSeconds() + 21);
        }

        new_date.setHours(0 - new_date.getTimezoneOffset() / 60, new_date.getMinutes(), new_date.getSeconds());

        return new_date.toISOString().slice(0, -5);
      } else {
        if (time !== undefined) {
          return `${date.split("T")[0]}T${time.split("T")[1]}`;
        } else {
          return `${date.split("T")[0]}T00:00:00`;
        }
      }
    }
  } else {
    if (time !== undefined) {
      return `1900-01-01T${time.split("T")[1]}`;
    } else {
      return "";
    }
  }
}

function Cree_iso_date2(datetime) {
  const date = [datetime.getFullYear(), ("0" + (datetime.getMonth() + 1)).slice(-2), ("0" + datetime.getDate()).slice(-2)].join("-");
  const heure = [("0" + datetime.getHours()).slice(-2), ("0" + datetime.getMinutes()).slice(-2), ("0" + datetime.getSeconds()).slice(-2)].join(":");

  return [date, heure].join("T");
}

function Cree_date_chaine(date) {
  if (date === null || date === "") return "";
  if (_.includes(date, "1900-01-01")) return date;

  const datetime = new Date(date);
  const datetimeFormat = new Intl.DateTimeFormat("fr", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [{ value: day }, , { value: month }, , { value: year }] = datetimeFormat.formatToParts(datetime);

  return `${day}/${month}/${year}`;
}

function ResizeImage(image) {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      image,
      2048,
      2048,
      "JPEG",
      50,
      0,
      (blob) => {
        return resolve(blob);
      },
      "blob"
    );
  });
}

export {
  OrderArray,
  GetSquare,
  GetRank,
  GetTomb,
  GetLieuFromEmpl,
  bulkAddWithProgress,
  bulkAddWithProgressForSvg,
  addTableWithProgress,
  addTableWithProgressForPhotoconc,
  handleError,
  CreeTemporaire,
  Cree_iso_date,
  Cree_iso_date2,
  Cree_date_chaine,
  ResizeImage,
};
