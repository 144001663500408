import db from "../Db";
import { GetAllCimetier } from "./Cimetier";

var _ = require("lodash");

export function GetAllZone(CIM = 1, ZONE_DESSUS_TYPE = 1, planv4) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    if (ZONE_DESSUS_TYPE === 1) {
      if (planv4 === 0) {
        return resolve([]);
      } else {
        db.ZONE.where(["CIM", "ZONE_DESSUS_TYPE"])
          .equals([CIM, ZONE_DESSUS_TYPE])
          .toArray()
          .then((zone) => {
            return resolve(zone);
          });
      }
    } else {
      GetAllCimetier(1).then((cimetiere) => {
        const numplan = _.find(cimetiere, ["NUMCIME", CIM]).NUMPLAN;
        const cime = _.filter(cimetiere, (c) => {
          return c.NUMPLAN === numplan;
        });
        const numcime = _.map(cime, "NUMCIME");

        if (planv4 === 0) {
          db.ZONE.where("CIM")
            .anyOf(numcime)
            .and((item) => item.ZOCLEUNIK_PARENT === 0)
            .toArray()
            .then((zone) => {
              return resolve(zone);
            });
        } else {
          db.ZONE.where("CIM")
            .anyOf(numcime)
            .and((item) => item.ZONE_DESSUS_TYPE === ZONE_DESSUS_TYPE && item.ZOCLEUNIK_PARENT === 0)
            .toArray()
            .then((zone) => {
              return resolve(zone);
            });
        }
      });
    }
  });
}

export function GetZoneByTypezone(NUMCENDRE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.ZONE.where("TYPEZONE")
      .equals(NUMCENDRE)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetZoneById(ZOCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.ZONE.where("ZOCLEUNIK")
      .equals(parseInt(ZOCLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetFirstZone(CIM = 1, ZONE_DESSUS_TYPE = 1, oracle) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    if (oracle === 0) {
      db.ZONE.where(["CIM", "ZONE_DESSUS_TYPE"])
        .equals([CIM, ZONE_DESSUS_TYPE])
        .sortBy("id")
        .then((zone) => {
          return resolve(zone.shift());
        });
    } else {
      db.ZONE.where(["CIM", "ZONE_DESSUS_TYPE"])
        .equals([CIM, ZONE_DESSUS_TYPE])
        .toArray()
        .then((zone) => {
          zone = _.sortBy(zone, (item) => [item.COORDONNEES.X1, item.COORDONNEES.Y1], ["asc"]);

          if (zone.length === 0) {
            return resolve(undefined);
          }

          return resolve(zone[0]);
        });
    }
  });
}

export function GetZoneFromParent(ZOCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.ZONE.where("ZOCLEUNIK_PARENT")
      .equals(ZOCLEUNIK)
      .toArray()
      .then((zone) => {
        return resolve(zone);
      });
  });
}
