import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

import InputRechercheDefunt from "../InputRechercheDefunt";
import ListeDefunts from "../ListeDefunts";
import Opposition from "./../Global/OppositionTablette";
import { withCookies } from "react-cookie";

const styles = {
  grid: {
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    paddingTop: 10,
  },
  paper: {
    padding: 16,
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
    paddingTop: 16,
  },
};

class CDefunts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      REDIRECT: null,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);
  };

  _handle_click_liste_defunts = (event) => {
    event.preventDefault();

    this.setState({ REDIRECT: "/consulter/liste-defunts" });
  };

  render() {
    console.log("RENDER DEFUNTS");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return this.props.input_defunt !== undefined ? (
      <ListeDefunts input_defunt={this.props.input_defunt} />
    ) : (
      <Grid container alignItems="center" justify="center" style={styles.grid}>
        <Grid item xs={11} style={styles.grid_item}>
          <Paper elevation={0} style={styles.paper}>
            <Typography variant="h6" gutterBottom>
              Rechercher un défunt
            </Typography>

            <InputRechercheDefunt />

            <div style={styles.div}>
              <Typography variant="body2">
                <i>Vous pouvez également taper le début du nom et cliquer sur le bouton RECHERCHER (double cliquer)</i>
              </Typography>

              <Button variant="contained" color="primary" onClick={this._handle_click_liste_defunts}>
                {`Afficher tous les défunts`}
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(CDefunts);
