import db from "../Db";
import { GetAllAgendaTache } from "./AgendaTache";

var _ = require("lodash");

export function GetAllInterven() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetAllIntervenInTask(IDMOUVEMENT) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetAllAgendaTache().then((tache) => {
      let IDINTERVEN = [];

      _.forEach(tache, (t) => {
        if (t.IDINTERVEN > 0) {
          IDINTERVEN.push(t.IDINTERVEN);
        }
      });

      db.INTERVEN.where("I0CLEUNIK")
        .anyOf(IDINTERVEN)
        .toArray()
        .then((result) => {
          return resolve(result);
        });
    });
  });
}

export function GetIntervenById(I0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN.where("I0CLEUNIK")
      .equals(parseInt(I0CLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetIntervenByIdMouvement(IDMOUVEMENT) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN.where("IDMOUVEMENT")
      .equals(IDMOUVEMENT)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetIntervenByCocleunik(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.INTERVEN.where("COCLEUNIK")
      .equals(COCLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
