import React, { Component } from "react";

import { IsOracle } from "./../Helpers/Test/IsOracle";
import { GetAllLieuByCocleunik, GetLieuById } from "./../Helpers/Get/Lieu";
import { GetCendreByIdLieu } from "../Helpers/Get/Cendre";
import { GetZoneByTypezone, GetZoneById } from "../Helpers/Get/Zone";
import { CalculateCoordinatesSousPlan } from "./../Helpers/Calculate/CoordinalesSousPlan";

var _ = require("lodash");

class SousPlan extends Component {
  constructor(props) {
    super(props);

    this.ISORACLE = false;
    this.LIEU = "";
    this.LICLEUNIK = [];
    this.CENDRE = "";

    this.state = {
      ZONE: "",
      COORDINATES: [],
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.ISORACLE = oracle;
    });

    GetLieuById(this.props.id_lieu).then((lieu) => {
      this.LIEU = lieu;

      if (this.LIEU.LIEU_MULTIPLE_PRINC_VIRTUELLE) {
        GetAllLieuByCocleunik(this.LIEU.COCLEUNIK).then((lieu) => {
          this.LICLEUNIK = _.map(lieu, "LICLEUNIK");

          this.LIEU = _.minBy(lieu, function (l) {
            return l.LICLEUNIK;
          });

          GetCendreByIdLieu(this.LIEU.LICLEUNIK).then((cendre) => {
            this.CENDRE = cendre;

            if (cendre !== undefined) {
              GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                this.setState({ ZONE: zone });
              });
            } else {
              let sit = _.compact([this.LIEU.SITUATION_ALL_1, this.LIEU.SITUATION_ALL_2, this.LIEU.SITUATION_ALL_3, this.LIEU.SITUATION_ALL_4]);

              if (sit.length === 0) {
                sit.push(this.LIEU.SITUATION);
              }

              _.forEach(sit, (situation) => {
                GetZoneById(situation).then((zone) => {
                  CalculateCoordinatesSousPlan(zone, this.LIEU, this.LICLEUNIK).then((coordinates) => {
                    var emplacement = _.find(coordinates, {
                      LICLEUNIK: this.LIEU.LICLEUNIK,
                    });

                    if (emplacement.TEXT.X > 0 && emplacement.TEXT.X < 1024 && emplacement.TEXT.Y > 0 && emplacement.TEXT.Y < 768) {
                      this.setState({ ZONE: zone, COORDINATES: coordinates });
                    }
                  });
                });
              });
            }
          });
        });
      } else {
        this.LICLEUNIK = [lieu.LICLEUNIK];

        GetCendreByIdLieu(lieu.LICLEUNIK).then((cendre) => {
          this.CENDRE = cendre;

          if (cendre !== undefined) {
            GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
              this.setState({ ZONE: zone });
            });
          } else {
            let sit = _.compact([lieu.SITUATION_ALL_1, lieu.SITUATION_ALL_2, lieu.SITUATION_ALL_3, lieu.SITUATION_ALL_4]);

            if (sit.length === 0) {
              sit.push(lieu.SITUATION);
            }

            _.forEach(sit, (situation) => {
              GetZoneById(situation).then((zone) => {
                CalculateCoordinatesSousPlan(zone, lieu, this.LICLEUNIK).then((coordinates) => {
                  var emplacement = _.find(coordinates, {
                    LICLEUNIK: lieu.LICLEUNIK,
                  });

                  if (emplacement.TEXT.X > 0 && emplacement.TEXT.X < 1024 && emplacement.TEXT.Y > 0 && emplacement.TEXT.Y < 768) {
                    this.setState({ ZONE: zone, COORDINATES: coordinates });
                  }
                });
              });
            });
          }
        });
      }
    });
  };

  _create_sous_plan = () => {
    if (this.state.ZONE.IMAGESP.size > 0) {
      return (
        <g>
          <title>Position du défunt sur le sous-plan</title>
          <image xlinkHref={window.URL.createObjectURL(this.state.ZONE.IMAGESP)} x={0} y={0} width={1024} height={768} />
          <g>
            {this.state.COORDINATES.length > 0
              ? this.state.COORDINATES.map((coordinate, index) => (
                  <g key={index}>
                    <polygon
                      style={{
                        fill: coordinate.COULEUR,
                        stroke: "#646464",
                        strokeWidth: "1px",
                      }}
                      points={coordinate.POLYGON}
                    />

                    <text x={coordinate.TEXT.X} y={coordinate.TEXT.Y} textAnchor="middle" style={{ fontSize: "6px", fontFamily: "Verdana" }}>
                      {coordinate.TEXT.TOMBE}
                    </text>
                  </g>
                ))
              : null}
          </g>
        </g>
      );
    } else {
      return null;
    }
  };

  render() {
    return this.state.ZONE !== "" ? this._create_sous_plan() : null;
  }
}

export default SousPlan;
