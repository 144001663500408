import { Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Paper, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";

import { GetClient } from "./../../Helpers/Get/Client";
import { GetParam } from "./../../Helpers/Get/Param";
import { GetLieuById } from "../../Helpers/Get/Lieu";
import { GetConcessByIdLieu } from "../../Helpers/Get/Concess";
import { GetAllThanatomorphose } from "./../../Helpers/Get/Thanatomorphose";
import { GetAllEtatTerrain } from "./../../Helpers/Get/EtatTerrain";
import { GetAllAccessibilite } from "./../../Helpers/Get/Accessibilite";
import { GetTemporaireNonEnvoye } from "../../Helpers/Get/Temporaire";

import { differenceObject } from "./../../Helpers/Test/Difference";
import { CreeTemporaire, handleError } from "../../Helpers/Function";

import UpdateLieu from "./../../Helpers/Update/Lieu";
import UpdateTemporaire from "./../../Helpers/Update/Temporaire";
import AddTemporaire from "./../../Helpers/Add/Temporaire";
import { IsOracle } from "./../../Helpers/Test/IsOracle";
import UpdateSvg from "./../../Helpers/Update/Svg";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    display: "flex",
    flex: 1,
    padding: 16,
  },
  form: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  radiogroup: {
    flexDirection: "initial",
    justifyContent: "space-between",
  },
  submit_div: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },
};

class MLieu extends Component {
  constructor(props) {
    super(props);

    this.IDCLIENT = 0;
    this.ID_TABLETTE = "";
    this.EMPLACEMENT_LIEU = "";
    this.ID_UTIL = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));

    this.state = {
      PARAM: "",
      LIEU: {
        LICLEUNIK: 0,
        EMPLACEMENT: {
          TYPECAR: "",
          CARRE: "",
          TYPERG: "",
          RANG: "",
          TYPEFOS: "",
          TOMBE: "",
          ETAT: 0,
          GELE: false,
          H1: 0,
          V1: 0,
          H2: 0,
          V2: 0,
          H3: 0,
          V3: 0,
          H4: 0,
          V4: 0,
          POSITIONSTELE: 0,
          MONUMENTPRESENT: false,
          PASSE_PIED: false,
        },
        COCLEUNIK: 0,
        CIMEL: 0,
        TOMBEL: 0,
        NOMBREL: 0,
        DUREEL: 0,
        POSNUM: 0,
        SITUATION: 0,
        SITUATION_ALL_1: 0,
        SITUATION_ALL_2: 0,
        SITUATION_ALL_3: 0,
        SITUATION_ALL_4: 0,
        TAILLEPOLICEDULIEU: 0,
        AFFMULTILIGNE: 0,
        LIEU_MULTIPLE_PRINC_VIRTUELLE: false,
        IDTHANATOMORPHOSE: 0,
        IDETAT_TERRAIN: 0,
        IDACCESSIBILITE: 0,
      },
      LIEU_SAUVEGARDE: "",
      THANATO: [],
      ETAT_TERRAIN: [],
      ACCESSIBILITE: [],
      REDIRECT: null,
      disabledForm: false,
    };

    if (props.id_lieu !== undefined) {
      GetLieuById(props.id_lieu).then((lieu) => {
        this.setState({ LIEU_SAUVEGARDE: lieu });

        GetConcessByIdLieu(lieu.LICLEUNIK).then((concess) => {
          this.EMPLACEMENT_LIEU = concess.EMPLACEMENT_LIEU;

          const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

          if (reg.test(this.EMPLACEMENT_LIEU)) {
            const match = this.EMPLACEMENT_LIEU.match(reg);

            this.EMPLACEMENT_LIEU = `${match[1]} ${match[3]} - ${match[2]} ${match[4]}${match[5]}`;
          }

          props.changeTitle(this.EMPLACEMENT_LIEU);
        });
      });
    }
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle === 1 });
    });

    GetClient().then((client) => {
      this.IDCLIENT = client.IDCLIENT;
      this.ID_TABLETTE = client.ID_TABLETTE;
    });

    GetParam().then((param) => {
      this.setState({ PARAM: param });
    });

    if (this.props.id_lieu !== undefined) {
      GetLieuById(this.props.id_lieu).then((lieu) => {
        GetAllThanatomorphose().then((thanato) => {
          thanato = _.orderBy(thanato, "ORDRE", "asc");

          thanato = _.filter(thanato, (than) => {
            return than.ARCHIVER === false || than.IDTHANATOMORPHOSE === lieu.IDTHANATOMORPHOSE;
          });

          GetAllEtatTerrain().then((etat_terrain) => {
            etat_terrain = _.orderBy(etat_terrain, "ORDRE", "asc");

            etat_terrain = _.filter(etat_terrain, (etter) => {
              return etter.BL_ARCHIVE === false || etter.IDETAT_TERRAIN === lieu.IDETAT_TERRAIN;
            });

            GetAllAccessibilite().then((accessibilite) => {
              accessibilite = _.orderBy(accessibilite, "ORDRE", "asc");

              accessibilite = _.filter(accessibilite, (acces) => {
                return acces.BL_ARCHIVE === false || acces.IDACCESSIBILITE === lieu.IDACCESSIBILITE;
              });

              this.setState({ LIEU: lieu, THANATO: thanato, ETAT_TERRAIN: etat_terrain, ACCESSIBILITE: accessibilite });
            });
          });
        });
      });
    }
  };

  _MiseAJourTemporaire = (temporaire) => {
    UpdateTemporaire(temporaire)
      .then(() => {
        this.setState({ REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}` });
      })
      .catch((Err) => handleError(Err));
  };

  _AjoutTemporaire = (temporaire) => {
    AddTemporaire(temporaire)
      .then(() => {
        this.setState({ REDIRECT: `/consulter/fiche-concession/${temporaire.TABLE_MODIFIE_JSON.COCLEUNIK}` });
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourLieu = (LIEU, LIEU_TEMPORAIRE) => {
    const modifie_lieu = UpdateLieu(LIEU);
    modifie_lieu
      .then(() => {
        UpdateSvg(LIEU)
          .then(() => {
            GetTemporaireNonEnvoye("LIEU", "LICLEUNIK", LIEU.LICLEUNIK)
              .then((temporaire) => {
                if (temporaire !== undefined) {
                  var emplacement = LIEU_TEMPORAIRE.EMPLACEMENT;

                  if (LIEU_TEMPORAIRE.EMPLACEMENT !== undefined) {
                    emplacement = _.assign({}, temporaire.TABLE_MODIFIE_JSON.EMPLACEMENT, LIEU_TEMPORAIRE.EMPLACEMENT);
                  }

                  var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, LIEU_TEMPORAIRE);
                  _.set(miseajour_temporaire, "EMPLACEMENT", emplacement);

                  temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;

                  this._MiseAJourTemporaire(temporaire);
                } else {
                  const temporaire = CreeTemporaire(
                    this.IDCLIENT,
                    this.ID_TABLETTE,
                    this.ID_UTIL,
                    `Modification de l'emplacement ${this.EMPLACEMENT_LIEU}`,
                    "LIEU",
                    2,
                    LIEU_TEMPORAIRE,
                    this.state.LIEU_SAUVEGARDE
                  );

                  this._AjoutTemporaire(temporaire);
                }
              })
              .catch((Err) => handleError(Err));
          })
          .catch((Err) => handleError(Err));
      })
      .catch((Err) => handleError(Err));
  };

  _handle_submit_form_lieu = (event) => {
    event.preventDefault();

    if (this.state.disabledForm) return;

    this.setState({ disabledForm: true }, () => {
      let LIEU = this.state.LIEU;

      let changed = !_.isEqual(LIEU, this.state.LIEU_SAUVEGARDE);
      if (changed === true) {
        let diff = differenceObject(LIEU, this.state.LIEU_SAUVEGARDE);

        diff = _.assign(
          {
            id: LIEU.id,
            LICLEUNIK: LIEU.LICLEUNIK,
            COCLEUNIK: LIEU.COCLEUNIK,
          },
          diff
        );

        this._MiseAJourLieu(LIEU, diff);
      }
    });
  };

  handle_change_lieu = (field) => (event) => {
    let LIEU = this.state.LIEU;

    switch (field) {
      case "IDTHANATOMORPHOSE":
        event.target.value === "" ? (LIEU[field] = 0) : (LIEU[field] = parseInt(event.target.value));

        break;
      case "IDETAT_TERRAIN":
      case "IDACCESSIBILITE":
        LIEU[field] = parseInt(event.target.value);

        break;
      case "POSITIONSTELE":
        LIEU.EMPLACEMENT[field] = parseInt(event.target.value);
        break;
      case "GELE":
      case "MONUMENTPRESENT":
      case "PASSE_PIED":
        LIEU.EMPLACEMENT[field] = event.target.checked;
        break;
      default:
        break;
    }

    this.setState({ LIEU: LIEU, disabledForm: false });
  };

  render() {
    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <form onSubmit={this._handle_submit_form_lieu} style={styles.form}>
              <div>
                {this.state.IS_ORACLE ? (
                  <TextField
                    select
                    fullWidth
                    margin="normal"
                    label="Thanatomorphose"
                    value={this.state.LIEU.IDTHANATOMORPHOSE === 0 ? "" : this.state.LIEU.IDTHANATOMORPHOSE}
                    onChange={this.handle_change_lieu("IDTHANATOMORPHOSE")}
                  >
                    <MenuItem value="" style={{ minHeight: 36 }} />
                    {this.state.THANATO.map((thanato, index) => (
                      <MenuItem key={index} value={thanato.IDTHANATOMORPHOSE.toString()}>
                        {thanato.LIBELLE}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}

                {this.state.IS_ORACLE ? (
                  <TextField
                    select
                    fullWidth
                    margin="normal"
                    label="Nature du sol"
                    value={this.state.LIEU.IDETAT_TERRAIN === 0 ? "" : this.state.LIEU.IDETAT_TERRAIN}
                    onChange={this.handle_change_lieu("IDETAT_TERRAIN")}
                  >
                    <MenuItem value="0" style={{ minHeight: 36 }} />
                    {this.state.ETAT_TERRAIN.map((etat_terrain, index) => (
                      <MenuItem key={index} value={etat_terrain.IDETAT_TERRAIN.toString()}>
                        {etat_terrain.LIBELLE}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}

                {this.state.IS_ORACLE ? (
                  <TextField
                    select
                    fullWidth
                    margin="normal"
                    label="Accessibilité"
                    value={this.state.LIEU.IDACCESSIBILITE === 0 ? "" : this.state.LIEU.IDACCESSIBILITE}
                    onChange={this.handle_change_lieu("IDACCESSIBILITE")}
                  >
                    <MenuItem value="0" style={{ minHeight: 36 }} />
                    {this.state.ACCESSIBILITE.map((accessibilite, index) => (
                      <MenuItem key={index} value={accessibilite.IDACCESSIBILITE.toString()}>
                        {accessibilite.LIBELLE}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}

                <Typography variant="body2" style={{ fontStyle: "italic", marginBottom: 8, marginTop: 16 }}>
                  Attention, pour le champ 'Entrée caveau', la sélection fera apparaître un trait noir sur l'emplacement <u>en vue de dessus</u> (et non en vue de face)
                </Typography>

                <TextField
                  style={{ marginTop: 0 }}
                  select
                  fullWidth
                  margin="normal"
                  label="Entrée caveau"
                  value={this.state.LIEU.EMPLACEMENT.POSITIONSTELE === 0 || this.state.LIEU.EMPLACEMENT.POSITIONSTELE === 1 ? "" : this.state.LIEU.EMPLACEMENT.POSITIONSTELE}
                  onChange={this.handle_change_lieu("POSITIONSTELE")}
                >
                  <MenuItem value="0" style={{ minHeight: 36 }} />
                  <MenuItem value="2">En haut</MenuItem>
                  <MenuItem value="3">En bas</MenuItem>
                  <MenuItem value="4">A gauche</MenuItem>
                  <MenuItem value="5">A droite</MenuItem>
                  <MenuItem value="6">Au dessus</MenuItem>
                </TextField>

                <FormGroup row style={styles.radiogroup}>
                  {this.state.IS_ORACLE ? (
                    <FormControlLabel
                      style={{ userSelect: "none" }}
                      control={<Checkbox checked={this.state.LIEU.EMPLACEMENT.GELE} onChange={this.handle_change_lieu("GELE")} value={this.state.LIEU.EMPLACEMENT.GELE} />}
                      label="Emplacement gelé"
                    />
                  ) : null}

                  <FormControlLabel
                    style={{ userSelect: "none" }}
                    control={
                      <Checkbox checked={this.state.LIEU.EMPLACEMENT.MONUMENTPRESENT} onChange={this.handle_change_lieu("MONUMENTPRESENT")} value={this.state.LIEU.EMPLACEMENT.MONUMENTPRESENT} />
                    }
                    label="Monument restant"
                  />

                  {this.state.IS_ORACLE ? (
                    <FormControlLabel
                      style={{ userSelect: "none" }}
                      control={<Checkbox checked={this.state.LIEU.EMPLACEMENT.PASSE_PIED} onChange={this.handle_change_lieu("PASSE_PIED")} value={this.state.LIEU.EMPLACEMENT.PASSE_PIED} />}
                      label={this.state.PARAM.LIBPASSEPIEDS || "Passe-pieds"}
                    />
                  ) : null}
                </FormGroup>
              </div>

              <div style={styles.submit_div}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Annuler
                </Button>

                <Button variant="contained" color="primary" type="submit" disabled={this.state.disabledForm}>
                  Enregistrer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(withRouter(MLieu));
