import { handleError } from "../Function";
import { GetAllInterven } from "../Get/Interven";
import { GetAllPhotoconcByI0cleunik } from "../Get/Photoconc";
import UpdatePhotoconc from "../Update/Photoconc";
import LoaderState from "./../../Components/Global/Object/LoaderState";

var _ = require("lodash");

export function DeletePhotoForInterven(_change_loader_state) {
  return new Promise((resolve, reject) => {
    GetAllInterven().then(async (allInterven) => {
      allInterven = _.filter(allInterven, ["SUIVITRAV", 5]);

      if (allInterven.length === 0) resolve("");

      let i0cleunik = _.map(allInterven, "I0CLEUNIK");
      i0cleunik = i0cleunik.map(String);

      let photos = await GetAllPhotoconcByI0cleunik(i0cleunik);
      photos = _.uniqBy(photos, "IDPHOTOCONC");
      photos = _.filter(photos, ["PRINCIPAL", 0]);

      let photosamettreajour = [];

      if (photos.length > 0) {
        for (let index = 0; index < photos.length; index++) {
          const element = photos[index];

          if (element.PHOTO !== "" || (element.SIGNATURE_AGENT !== undefined && element.SIGNATURE_AGENT !== null) || (element.SIGNATURE_SOCIETE !== undefined && element.SIGNATURE_SOCIETE !== null)) {
            element.PHOTO = "";
            if (element.SIGNATURE_AGENT !== undefined) element.SIGNATURE_AGENT = null;
            if (element.SIGNATURE_SOCIETE !== undefined) element.SIGNATURE_SOCIETE = null;

            photosamettreajour.push(element);
          }
        }

        if (photosamettreajour.length > 0) {
          LoaderState.setNombreTotalPhotos(photosamettreajour.length);
          for (let index = 0; index < photosamettreajour.length; index++) {
            const element = photosamettreajour[index];

            UpdatePhotoconc(element)
              .then(() => {
                LoaderState.setNombrePhotos(index);
                _change_loader_state();

                if (index === photosamettreajour.length - 1) return resolve("");
              })
              .catch((Err) => handleError(Err));
          }
        } else {
          return resolve("");
        }
      } else {
        return resolve("");
      }
    });
  });
}
