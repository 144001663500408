var LoaderState = (function () {
  var index = 0;
  var table = "";
  var nombrePhotos = 0;
  var nombreTotalPhotos = 0;

  var getIndex = function () {
    return index;
  };

  var setIndex = function (currentIndex) {
    index = currentIndex;
  };

  var getTable = function () {
    return table;
  };

  var setTable = function (currentTable) {
    table = currentTable;
  };

  var getNombrePhotos = function () {
    return nombrePhotos;
  };

  var setNombrePhotos = function (currentNombrePhotos) {
    nombrePhotos = currentNombrePhotos;
  };

  var getNombreTotalPhotos = function () {
    return nombreTotalPhotos;
  };

  var setNombreTotalPhotos = function (currentNombreTotalPhotos) {
    nombreTotalPhotos = currentNombreTotalPhotos;
  };

  return {
    getIndex: getIndex,
    setIndex: setIndex,
    getTable: getTable,
    setTable: setTable,
    getNombrePhotos: getNombrePhotos,
    setNombrePhotos: setNombrePhotos,
    getNombreTotalPhotos: getNombreTotalPhotos,
    setNombreTotalPhotos: setNombreTotalPhotos,
  };
})();

export default LoaderState;
