import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import DeleteLigneAction from "../Delete/LigneAction_MDF";
import { handleError } from "../Function";

var _ = require("lodash");

export async function UpdateLigneAction_MDF(headers) {
  const _ligneAction = await GetApiData("LIGNE_ACTION", headers);

  if (_ligneAction.Message !== undefined) {
    return _ligneAction;
  }

  return await DeleteLigneAction().then(() => {
    var drops = [];
    _.forEach(_ligneAction, (ligneAction) => {
      drops.push({
        IDACTION_UTIL: ligneAction.IDACTION_UTIL,
        DROITAJOUT: ligneAction.DROITAJOUT,
        DROITLECTURE: ligneAction.DROITLECTURE,
        DROITMODIF: ligneAction.DROITMODIF,
        UTCLEUNIK: ligneAction.UTCLEUNIK,
        NUMACTION: ligneAction.NUMACTION,
      });
    });

    return db
      .open()
      .then(() => {
        return db.LIGNE_ACTION.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
