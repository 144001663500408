import db from "../Db";

export function GetAllConcaire() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCAIRE.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetConcaireById(C0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCAIRE.where("C0CLEUNIK")
      .equals(parseInt(C0CLEUNIK))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetConcaireNewId() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCAIRE.toCollection()
      .last()
      .then((result) => {
        if (result === undefined) {
          return resolve(1);
        } else {
          return resolve(result.id + 1);
        }
      });
  });
}

export function GetConcaireByCocleunik(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCAIRE.where("COCLEUNIK")
      .equals(COCLEUNIK)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}
