import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { stableSort, getComparator } from "./../Global/Gfunction";
import { Close } from "@material-ui/icons";
import { GetLieuByCimel } from "../../Helpers/Get/Lieu";
import { GetAllCendre } from "./../../Helpers/Get/Cendre";
import { GetAllConcess } from "./../../Helpers/Get/Concess";

var _ = require("lodash");

const styles = {
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
};

const headCells = [
  { id: "label", label: "Emplacement" },
  { id: "etat", label: "Etat" },
  { id: "famille", label: "Famille" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span style={styles.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage />
      </IconButton>
    </div>
  );
}

class CListeConcessionsPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LIEU: [],
      CENDRE: [],
      CONCESS: [],
      REDIRECT: null,
      LIST_EMPLACEMENT: [],
      LIST_EMPLACEMENT_AFFICHE: [],
      RECHERCHE: "",
      emplacement_libre_dialog: false,
      currentPage: 0,
      rowsPerPage: 10,
      orderBy: "FAMILLE",
      order: "asc",
    };
  }

  componentDidMount = () => {
    GetLieuByCimel(this.props.numcime).then((lieu) => {
      GetAllCendre().then((cendre) => {
        GetAllConcess().then((concess) => {
          this.setState({ LIEU: lieu, CENDRE: cendre, CONCESS: concess }, () => {
            var liste_empl = this._cree_chaine_emplacement();

            this.setState({ LIST_EMPLACEMENT: liste_empl, LIST_EMPLACEMENT_AFFICHE: liste_empl });
          });
        });
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.numcime !== this.props.numcime) {
      GetLieuByCimel(this.props.numcime).then((lieu) => {
        GetAllCendre().then((cendre) => {
          GetAllConcess().then((concess) => {
            this.setState({ LIEU: lieu, CENDRE: cendre, CONCESS: concess }, () => {
              var liste_empl = this._cree_chaine_emplacement();

              this.setState({ LIST_EMPLACEMENT: liste_empl, LIST_EMPLACEMENT_AFFICHE: liste_empl });
            });
          });
        });
      });
    }
  };

  _cree_chaine_emplacement = () => {
    let liste_empl = [];

    _.forEach(this.state.LIEU, (lieu) => {
      const concess = _.find(this.state.CONCESS, ["COCLEUNIK", lieu.COCLEUNIK]);
      const cendre = _.find(this.state.CENDRE, ["LICLEUNIK", lieu.LICLEUNIK]);

      if (cendre !== undefined && cendre !== "") {
        liste_empl.push({
          id: lieu.LICLEUNIK,
          etat: lieu.EMPLACEMENT.ETAT,
          famille: concess !== undefined ? concess.FAMILLE.trim() : null,
          label: cendre.NOMCENDRE,
        });
      } else {
        if (lieu.EMPLACEMENT.CARRE.includes("Provisoire") || lieu.EMPLACEMENT.CARRE.includes("Non affect")) {
          if (concess !== undefined && concess !== "") {
            liste_empl.push({
              id: lieu.LICLEUNIK,
              etat: lieu.EMPLACEMENT.ETAT,
              famille: concess !== undefined ? concess.FAMILLE.trim() : null,
              label: concess.EMPL,
            });
          }
        } else {
          liste_empl.push({
            id: lieu.LICLEUNIK,
            etat: lieu.EMPLACEMENT.ETAT,
            famille: concess !== undefined ? concess.FAMILLE.trim() : null,
            label: _.filter([
              _.filter([lieu.EMPLACEMENT.TYPECAR, lieu.EMPLACEMENT.CARRE]).join(" "),
              _.filter([lieu.EMPLACEMENT.TYPERG, lieu.EMPLACEMENT.RANG]).join(" "),
              _.filter([lieu.EMPLACEMENT.TYPEFOS, lieu.EMPLACEMENT.TOMBE]).join(" "),
            ]).join(" - "),
          });
        }
      }
    });

    return _.orderBy(liste_empl, "label", "asc");
  };

  _handle_click_listItem = (licleunik) => (event) => {
    event.preventDefault();

    const lieu = _.find(this.state.LIEU, ["LICLEUNIK", licleunik]);

    if (this.props.type === "photos") {
      if (lieu.EMPLACEMENT.ETAT === 2) {
        this.setState({
          REDIRECT: `/modifier/photographies/${lieu.COCLEUNIK}`,
        });
      } else {
        this.setState({ emplacement_libre_dialog: true });
      }
    } else {
      if (lieu.EMPLACEMENT.ETAT === 2) {
        this.setState({
          REDIRECT: `/consulter/fiche-concession/${lieu.COCLEUNIK}`,
        });
      } else {
        this.setState({
          REDIRECT: `/consulter/concessions/ajout/${lieu.LICLEUNIK}`,
        });
      }
    }
  };

  _handle_change_recherche = (event) => {
    event.preventDefault();

    let LIST_EMPLACEMENT = { ...this.state.LIST_EMPLACEMENT };
    LIST_EMPLACEMENT = _.filter(LIST_EMPLACEMENT, (le) => {
      let etat_recherche = null;
      if (_.includes("occupé", event.target.value.toLowerCase()) || _.includes("occupe", event.target.value.toLowerCase())) {
        etat_recherche = 2;
      }

      if (_.includes("libre", event.target.value.toLowerCase())) {
        etat_recherche = 1;
      }

      return (
        _.includes(le.label.toLowerCase(), event.target.value.toLowerCase()) ||
        (le.famille !== null && _.includes(le.famille.toLowerCase(), event.target.value.toLowerCase())) ||
        (etat_recherche !== null && le.etat === etat_recherche)
      );
    });

    this.setState({ RECHERCHE: event.target.value, LIST_EMPLACEMENT_AFFICHE: LIST_EMPLACEMENT, rowsPerPage: 10, currentPage: 0 });
  };

  textfield = () => {
    return (
      <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
        <TextField style={{ width: "15rem" }} margin="normal" label="Rechercher" value={this.state.RECHERCHE} onChange={this._handle_change_recherche} />

        <Button variant="contained" color="primary" onClick={() => this.setState({ REDIRECT: "/consulter/liste-equipement" })}>
          Equipements
        </Button>
      </div>
    );
  };

  handleRequestSort = (_, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";

    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  _handle_change_tablepage = (event, newPage) => {
    this.setState({ currentPage: newPage, EXPANDED: -1 });
  };

  _handle_change_tablerowperpage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, currentPage: 0, EXPANDED: -1 });
  };

  render() {
    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return this.state.LIST_EMPLACEMENT_AFFICHE.length > 0 ? (
      <div>
        {this.textfield()}

        <TableContainer component={Paper} style={{ marginTop: 24 }}>
          <Table>
            <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} onRequestSort={this.handleRequestSort} />

            <TableBody>
              {stableSort(this.state.LIST_EMPLACEMENT_AFFICHE, getComparator(this.state.order, this.state.orderBy))
                .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover={true} style={{ cursor: "pointer" }} onClick={this._handle_click_listItem(row.id)}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell style={{ color: row.etat === 1 ? "#9ACD32" : "#000" }}>{row.etat === 1 ? "Libre" : "Occupé"}</TableCell>
                      <TableCell>{row.famille}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Table>
          <TableBody>
            <TableRow>
              <TablePagination
                style={{ display: "flex", flexDirection: "column" }}
                colSpan={3}
                count={this.state.LIST_EMPLACEMENT_AFFICHE.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.currentPage}
                labelRowsPerPage="Nombre de lignes"
                labelDisplayedRows={({ from, to, count }) => (count > 0 ? `De ${from} à ${to === -1 ? count : to} sur ${count !== -1 ? count : to} resultats` : `Aucun résultat`)}
                onChangePage={this._handle_change_tablepage}
                onChangeRowsPerPage={this._handle_change_tablerowperpage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableBody>
        </Table>

        <Dialog onClose={() => this.setState({ emplacement_libre_dialog: false })} open={this.state.emplacement_libre_dialog}>
          <DialogTitle onClose={() => this.setState({ emplacement_libre_dialog: false })}>
            <Typography variant="h6" component="p">
              Attention
            </Typography>

            <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ emplacement_libre_dialog: false })}>
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Typography gutterBottom>Impossible d'ajouter des photographies sur un emplacement libre.</Typography>
          </DialogContent>
        </Dialog>
      </div>
    ) : (
      <div>{this.textfield()}</div>
    );
  }
}

export default CListeConcessionsPlan;
