import DeleteSocieteBySocleunik from "../Delete/Societe";
import { addTableWithProgress } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteAgendaTacheByIdAgenda from "../Delete/AgendaTache";
import { DeleteConcaireByC0cleunik } from "../Delete/Concaire";
import { DeleteConcessByCocleunik } from "../Delete/Concess";
import { DeleteDefuntByD0cleunik } from "../Delete/Defunt";
import DeleteIntervenByI0cleunik from "../Delete/Interven";
import DeleteLieuByLicleunik from "../Delete/Lieu";
import DeleteMouvementByIdMouvement from "../Delete/Mouvement";
import { DeletePhotoconcByIdPhotoconc } from "../Delete/Photoconc";
import DeleteUtilByUtcleunik from "../Delete/Util";

var _ = require("lodash");

export async function Suppression_MDF(headers) {
  const _suppressionCount = await GetApiData("SUPPRESSIONCount", headers);

  if (_suppressionCount.Message !== undefined) {
    return _suppressionCount;
  }

  const promise = new Promise((res, rej) => {
    if (_suppressionCount === 0) res("");

    addTableWithProgress("SUPPRESSION", _suppressionCount, 2500, headers, (getProgress, getSuppression) => {
      if (getSuppression.Message !== undefined) {
        return res(getSuppression);
      }

      _.forEach(getSuppression, (suppression, index) => {
        switch (suppression.TABLE_NOM) {
          case "AGENDATACHE":
            DeleteAgendaTacheByIdAgenda(suppression.IDAUTO);
            break;
          case "CONCAIRE":
            DeleteConcaireByC0cleunik(suppression.IDAUTO);
            break;
          case "CONCESS":
            DeleteConcessByCocleunik(suppression.IDAUTO);
            break;
          case "DEFUNT":
            DeleteDefuntByD0cleunik(suppression.IDAUTO);
            break;
          case "INTERVEN":
            DeleteIntervenByI0cleunik(suppression.IDAUTO);
            break;
          case "LIEU":
            DeleteLieuByLicleunik(suppression.IDAUTO);
            break;
          case "MOUVEMENT":
            DeleteMouvementByIdMouvement(suppression.IDAUTO);
            break;
          case "PHOTOCONC":
            DeletePhotoconcByIdPhotoconc(suppression.IDAUTO);
            break;
          case "SOCIETE":
            DeleteSocieteBySocleunik(suppression.IDAUTO);
            break;
          case "UTIL":
            DeleteUtilByUtcleunik(suppression.IDAUTO);
            break;
          default:
            break;
        }

        if (index === getSuppression.length - 1) res("");
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Suppression de ${_suppressionCount} éléments`);

    return val;
  });
}
