import db from "../Db";
import { handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import DeleteAccessibilite from "../Delete/Accessibilite_MDF";

var _ = require("lodash");

export async function UpdateAccessibilite_MDF(headers) {
  const _accessibilite = await GetApiData("ACCESSIBILITE", headers);

  if (_accessibilite.Message !== undefined) {
    return _accessibilite;
  }

  return await DeleteAccessibilite().then(() => {
    var drops = [];
    _.forEach(_accessibilite, (accessibilite) => {
      drops.push({
        IDACCESSIBILITE: accessibilite.IDACCESSIBILITE,
        LIBELLE: accessibilite.LIBELLE.trim(),
        BL_ARCHIVE: accessibilite.BL_ARCHIVE === 1,
        ORDRE: accessibilite.ORDRE,
      });
    });

    return db
      .open()
      .then(() => {
        return db.ACCESSIBILITE.bulkAdd(drops)
          .then(() => {
            return "";
          })
          .catch((err) => {
            return handleError(err);
          });
      })
      .catch((err) => {
        return handleError(err);
      });
  });
}
