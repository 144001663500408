import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

export async function CreateParam(headers) {
  const _param = await GetApiData("PARAM", headers);

  if (_param.Message !== undefined) {
    return _param;
  }

  return await db
    .open()
    .then(() => {
      return db.PARAM.add({
        CARREDEFLT: _param.CARREDEFLT.trim(),
        RANGDEFLT: _param.RANGDEFLT.trim(),
        TOMBEDEFLT: _param.TOMBEDEFLT.trim(),
        LIBELLEANCIENNUMPLAN: _param.LIBELLEANCIENNUMPLAN.trim(),
        ETAT_MONUMENT_LIBELLE_SEMELLE: _param.ETAT_MONUMENT_LIBELLE_SEMELLE.trim(),
        LIBPASSEPIEDS: _param.LIBPASSEPIEDS.trim(),
      })
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
