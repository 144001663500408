import React, { useState } from "react";
import { Badge, Button, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import TransitionRight from "./Components/Global/Transition/TransitionRight";

export default function NewVersionAvailableSnackBar({ newVersionAvailable, setNewVersionAvailable, children }) {
  const [click, setClick] = useState(false);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={({ right: 24, cursor: "pointer" }, click ? { display: "block" } : { display: "flex" })}
      open={newVersionAvailable}
      TransitionComponent={TransitionRight}
      onClick={() => setClick(!click)}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={() => setNewVersionAvailable(false)}>
            Fermer
          </Button>
        </React.Fragment>
      }
    >
      {!click ? (
        <Badge badgeContent="!" color="primary">
          <Alert severity="info" className="AlertSmall"></Alert>
        </Badge>
      ) : (
        <Alert severity="info">{children}</Alert>
      )}
    </Snackbar>
  );
}
