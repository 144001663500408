import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

var _ = require("lodash");

export async function CreateCendre(headers) {
  const _cendre = await GetApiData("CENDRE", headers);

  if (_cendre.Message !== undefined) {
    return _cendre;
  }

  var drops = [];
  _.forEach(_cendre, (cendre) => {
    drops.push({
      NUMCENDRE: cendre.NUMCENDRE,
      NOMCENDRE: cendre.NOMCENDRE.trim(),
      TYPECIME: cendre.TYPECIME,
      LICLEUNIK: cendre.LICLEUNIK,
      DISPERSION: cendre.DISPERSION,
    });
  });

  return await db
    .open()
    .then(() => {
      return db.CENDRE.bulkAdd(drops)
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
