import db from "../Db";
import { GetConcessById } from "../Get/Concess";

export function DeleteConcess(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCESS.delete(ID);

    return resolve(true);
  });
}

export function DeleteConcessByCocleunik(COCLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetConcessById(COCLEUNIK).then((concess) => {
      if (concess !== undefined) db.CONCESS.delete(concess.id);

      return resolve(true);
    });
  });
}
