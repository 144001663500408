import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { handleError } from "../Function";

var _ = require("lodash");

export async function CreateCimetier(liste_cimetiere, headers) {
  const _cimetier = await GetApiData("CIMETIER", headers);

  if (_cimetier.Message !== undefined) {
    return _cimetier;
  }

  const drops = [];
  _.forEach(liste_cimetiere, (cimetiere) => {
    const numplan = cimetiere.NUMPLAN;
    const cimetiere_a_ajouter = _.filter(_cimetier, { NUMPLAN: numplan });

    _.forEach(cimetiere_a_ajouter, (cimetiere_1) => {
      drops.push({
        NUMCIME: cimetiere_1.NUMCIME,
        NOMCIME: cimetiere_1.NOMCIME.trim(),
        NUMPLAN: cimetiere_1.NUMPLAN,
        IMPRIMECOLUMB: cimetiere_1.IMPRIMECOLUMB ? 1 : 0,
        COULEUR_EMPL: cimetiere_1.COULEUR_EMPL,
      });
    });
  });

  return await db
    .open()
    .then(() => {
      return db.CIMETIER.bulkAdd(drops)
        .then(() => {
          return "";
        })
        .catch((err) => {
          return handleError(err);
        });
    })
    .catch((err) => {
      return handleError(err);
    });
}
