import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Paper, TextField, MenuItem, Button } from "@material-ui/core";
import Downshift from "downshift";
import { GetAllDefuntS } from "./../Helpers/Get/DefuntS";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
  },
  form: {
    display: "flex",
    alignItems: "baseline",
  },
  downshift: {
    flex: 1,
    marginRight: 20,
  },
};

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return <TextField InputProps={{ inputRef: ref, ...InputProps }} {...other} />;
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem {...itemProps} key={index} selected={isHighlighted} component="div" style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
      {suggestion.IDENTITE}
    </MenuItem>
  );
}

class InputRechercheDefunt extends Component {
  constructor(props) {
    super(props);

    this.LIST_DEFUNTS = [];

    this.state = {
      SELECTED_DEFUNT: "",
      SELECTED_DEFUNT_ID: [],
      RESULT_LIST: [],
      ERROR: false,
    };

    this._get_all_defunt();
  }

  componentDidMount = () => {
    document.onkeydown = (event) => {
      event.stopPropagation();
    };
  };

  _get_all_defunt = () => {
    GetAllDefuntS().then((defunts) => {
      this.LIST_DEFUNTS = defunts;
    });
  };

  _get_suggestions = (inputValue) => {
    let count = 0;

    if (inputValue !== "") {
      var n = _.deburr(inputValue).toLowerCase().charCodeAt(0) - 97;

      let list;
      if (this.LIST_DEFUNTS[n] !== undefined) {
        list = this.LIST_DEFUNTS[n][_.deburr(inputValue).charAt(0).toUpperCase()];
      } else {
        list = this.LIST_DEFUNTS[26].Autre;
      }

      return _.orderBy(list, ["IDENTITIE"], ["asc"]).filter((suggestion) => {
        const keep =
          (!inputValue ||
            suggestion.IDENTITE.normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .startsWith(
                inputValue
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              )) &&
          count < 5;

        if (keep) {
          count++;
        }
        return keep;
      });
    } else {
      return [];
    }
  };

  _get_all_suggestions = (inputValue) => {
    if (this.state.SELECTED_DEFUNT_ID.length) {
      return this.state.SELECTED_DEFUNT_ID;
    } else {
      if (inputValue !== "") {
        var n = _.deburr(inputValue).toLowerCase().charCodeAt(0) - 97;

        let list;
        if (this.LIST_DEFUNTS[n] !== undefined) {
          list = this.LIST_DEFUNTS[n][_.deburr(inputValue).charAt(0).toUpperCase()];
        } else {
          list = this.LIST_DEFUNTS[26].Autre;
        }

        return _.orderBy(list, ["IDENTITIE"], ["asc"]).filter((suggestion) => {
          const keep =
            !inputValue ||
            suggestion.IDENTITE.normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .startsWith(
                inputValue
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              );

          return keep;
        });
      } else {
        return [];
      }
    }
  };

  _handle_submit_form_recherchedefunt = (event) => {
    event.preventDefault();

    this.setState({
      RESULT_LIST: this._get_all_suggestions(this.state.SELECTED_DEFUNT),
    });
  };

  render() {
    if (this.state.RESULT_LIST.length > 0) {
      if (this.state.RESULT_LIST.length === 1) {
        return <Redirect to={`/consulter/fiche-defunt/${this.state.RESULT_LIST[0].D0CLEUNIK}`} push />;
      } else {
        return <Redirect to={`/consulter/defunts/${this.state.SELECTED_DEFUNT}`} push />;
      }
    }

    return (
      <Paper elevation={0} style={styles.paper}>
        <form style={styles.form} onSubmit={this._handle_submit_form_recherchedefunt}>
          <Downshift
            inputValue={this.state.SELECTED_DEFUNT}
            onChange={(item) => {
              this.setState({ SELECTED_DEFUNT: item.IDENTITE });
            }}
            onSelect={(item) => {
              if (item !== null) {
                this.setState({ SELECTED_DEFUNT_ID: [item] });
              }
            }}
            itemToString={(item) => (item ? item.IDENTITE : "")}
          >
            {({ getInputProps, getItemProps, isOpen, inputValue, highlightedIndex, clearSelection }) => (
              <div style={styles.downshift}>
                {renderInput({
                  fullWidth: true,
                  error: this.state.ERROR,
                  helperText: this.state.ERROR ? "Selectionner un défunt" : null,
                  InputProps: getInputProps({
                    placeholder: "NOM Prénom",
                    onChange: (event) => {
                      this.setState({
                        SELECTED_DEFUNT: event.target.value,
                        ERROR: false,
                      });
                    },
                    onKeyDown: (event) => {
                      if (event.key === "Enter" && isOpen) {
                        clearSelection();
                      }
                    },
                  }),
                })}
                {isOpen ? (
                  <Paper square>
                    {this._get_suggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion,
                        }),
                        highlightedIndex,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            )}
          </Downshift>

          <Button variant="contained" color="secondary" type="submit">
            Rechercher
          </Button>
        </form>
      </Paper>
    );
  }
}

export default withRouter(InputRechercheDefunt);
