import Dexie from "dexie";

export function DbExist() {
  return new Promise(function (resolve, reject) {
    Dexie.exists("GARDIEN")
      .then(function (exists) {
        if (exists) return resolve(true);
        else return resolve(false);
      })
      .catch(function () {
        console.error(
          "Impossible de tester l'existence de la base de données."
        );
      });
  });
}
