import { GetAllZone } from "./../../../Helpers/Get/Zone";

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

var CalculeSituation = (function () {
  var getSituation = async function (lieu) {
    const situationAll = [lieu.SITUATION_ALL_1, lieu.SITUATION_ALL_2, lieu.SITUATION_ALL_3, lieu.SITUATION_ALL_4];
    const currentSituation = situationAll.filter((x) => x !== 0);

    if (currentSituation.length === 0) currentSituation.push(lieu.SITUATION);

    let x = [],
      y = [];

    const x1 = lieu.EMPLACEMENT.H1;
    const x2 = lieu.EMPLACEMENT.H2;
    const x3 = lieu.EMPLACEMENT.H3;
    const x4 = lieu.EMPLACEMENT.H4;
    const y1 = lieu.EMPLACEMENT.V1;
    const y2 = lieu.EMPLACEMENT.V2;
    const y3 = lieu.EMPLACEMENT.V3;
    const y4 = lieu.EMPLACEMENT.V4;

    const p1 = new Point(x1, y1);
    const p2 = new Point(x2, y2);
    const p3 = new Point(x3, y3);
    const p4 = new Point(x4, y4);

    let points = { p1, p2, p3, p4 };
    for (let p in points) {
      let point = points[p];

      x.push(point.x);
      y.push(point.y);
    }

    const XMin = Math.min.apply(null, x);
    const XMax = Math.max.apply(null, x);
    const YMin = Math.min.apply(null, y);
    const YMax = Math.max.apply(null, y);

    const XCentre = XMin + (XMax - XMin) / 2;
    const YCentre = YMin + (YMax - YMin) / 2;

    return await GetAllZone(lieu.CIMEL, 1, 1).then((zone) => {
      zone = zone.filter((z) => {
        return z.COORDONNEES.X1 <= XCentre && z.COORDONNEES.X2 >= XCentre && z.COORDONNEES.Y1 <= YCentre && z.COORDONNEES.Y2 >= YCentre && currentSituation.includes(zone.ZOCLEUNIK);
      });

      if (zone.length > 0) return zone[0].ZOCLEUNIK;
      return lieu.SITUATION;
    });
  };

  return {
    getSituation: getSituation,
  };
})();

export default CalculeSituation;
