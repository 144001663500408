import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetAgendaTacheById } from "../Get/AgendaTache";

var _ = require("lodash");

export async function UpdateAgendaTache_MDF(headers) {
  const _agendatacheCount = await GetApiData("AGENDATACHECount", headers);

  if (_agendatacheCount.Message !== undefined) {
    return _agendatacheCount;
  }

  const promise = new Promise((res, rej) => {
    if (_agendatacheCount === 0) res("");

    addTableWithProgress("AGENDATACHE", _agendatacheCount, 2500, headers, (getProgress, getAgendatache) => {
      if (getAgendatache.Message !== undefined) {
        return res(getAgendatache);
      }

      _.forEach(getAgendatache, (agendatache) => {
        let couleur = agendatache.COULEUR > 0 ? agendatache.COULEUR : null;
        if (couleur !== null) {
          var b = Math.floor(couleur / 65536);
          var v = Math.floor((couleur - b * 65536) / 256);
          var r = Math.floor(couleur - b * 65536 - v * 256);

          couleur = [r, v, b];
        }

        const agendatache_a_mettre_a_jour = {
          IDAGENDA: agendatache.IDAGENDA,
          TACHE: {
            DATECREATION: agendatache.DATECREATION,
            NOM_CREER_PAR: agendatache.NOM_CREER_PAR,
            LIBELLE: agendatache.LIBELLE.trim(),
            DESCRIPTION: agendatache.TITRETACHE,
            COULEUR: couleur,
            IDGENS: agendatache.GENS !== null ? agendatache.GENS.split(",") : [],
            DATETACHE: agendatache.DATETACHE,
            DATETACHE_FIN: agendatache.DATETACHE_FIN,
          },
          TYPECIME: agendatache.TYPECIME,
          LICLEUNIK: agendatache.LICLEUNIK,
          D0CLEUNIK: agendatache.D0CLEUNIK,
          IDSOCIETE: agendatache.IDSOCIETE,
          IDINTERVEN: agendatache.IDINTERVEN,
        };

        GetAgendaTacheById(agendatache_a_mettre_a_jour.IDAGENDA).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.AGENDATACHE.add(agendatache_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(agendatache_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.AGENDATACHE.put(agendatache_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _agendatacheCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_agendatacheCount} taches d'agenda`);

    return val;
  });
}
