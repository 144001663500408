import db from "../Db";

export function GetAllThanatomorphose() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.THANATOMORPHOSE.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetThanatomorphoseById(IDTHANATOMORPHOSE) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.THANATOMORPHOSE.where("IDTHANATOMORPHOSE")
      .equals(parseInt(IDTHANATOMORPHOSE))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
