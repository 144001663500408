import db from "../Db";

export function GetSvgByNumcime(NUMCIME) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.SVG.where("NUMCIME")
      .equals(NUMCIME)
      .toArray()
      .then((result) => {
        return resolve(result);
      });
  });
}

export function GetSvgByIdLieu(LICLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.SVG.where("LICLEUNIK")
      .equals(LICLEUNIK)
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
