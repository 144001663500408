import React, { Component } from "react";
import { Grid, Paper, Button, AppBar, Tabs, Tab } from "@material-ui/core";

import { IsOracle } from "./../../Helpers/Test/IsOracle";
import { CalculateCoordinatesGMap } from "./../../Helpers/Calculate/CoordinalesGMpap";

import { GetConcessById } from "../../Helpers/Get/Concess";
import { GetLieuByCocleunik2, GetLieuById } from "./../../Helpers/Get/Lieu";
import { GetCendreByIdLieu } from "../../Helpers/Get/Cendre";
import { GetZoneByTypezone, GetZoneById } from "../../Helpers/Get/Zone";
import { GetCimetierById } from "../../Helpers/Get/Cimetier";
import { GetPlanById } from "./../../Helpers/Get/Plan";
import { GetTemporaire, GetTemporaireForColor } from "./../../Helpers/Get/Temporaire";

import SousPlan from "../SousPlan";
import PlanGeneral from "../PlanGeneral";
import CFCContrat from "./CFicheConcession/CFCContrat";
import CFCInterlocuteur from "./CFicheConcession/CFCInterlocuteur";
import CFCEtat from "./CFicheConcession/CFCEtat";
import CFCDefunts from "./CFicheConcession/CFCDefunts";
import CFCTravaux from "./CFicheConcession/CFCTravaux";
import { GetAllConcessEtatEcheance } from "./../../Helpers/Get/ConcessEtatEcheance";
import CFCLieu from "./CFicheConcession/CFCLieu";
import { withCookies } from "react-cookie";

import CurrentTab from "./../Global/Object/current_tab";
import PlanOperateurFiche from "../PlanOperateurFiche";
import Opposition from "./../Global/OppositionTablette";

const isOnline = require("is-online");
var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
};

class CFicheConcession extends Component {
  constructor(props) {
    super(props);

    this.CENDRE = "";
    this.COLOR_TEXT = {};

    this.state = {
      IS_ONLINE: false,
      IS_ORACLE: false,
      CONCESS: "",
      CONCESS_ETAT_ECHEANCE: [],
      LIEU_PRINC: "",
      LIEU: "",
      ZONE: "",
      CIMETIER: "",
      PLAN: "",
      SOUS_PLAN: false,
      FROM_BUTTON: false,
      CURRENT_TAB: CurrentTab.getTab(),
      REVERSE: this.props.reverse === "r",
      PA: this.props.reverse === "pa",
      champ_modifie: [],
      en_cours_de_traitement: false,
    };
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    isOnline().then((status) => {
      this.setState({ IS_ONLINE: status });
    });

    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle === 1 });
    });

    GetConcessById(this.props.id_concession).then((concession) => {
      if (concession.FAMILLE !== undefined) {
        this.props.changeTitle(concession.FAMILLE);
      }

      GetTemporaire("CONCESS", "COCLEUNIK", concession.COCLEUNIK).then((temporaire) => {
        this.setState({
          en_cours_de_traitement: false,
        });

        if (temporaire !== undefined) {
          if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
            this.setState({
              en_cours_de_traitement: true,
            });
          }
        }
      });

      GetAllConcessEtatEcheance().then((concess_etat_echeance) => {
        GetLieuById(concession.LICLEUNIK).then((lieu) => {
          this.setState({ LIEU_PRINC: lieu }, () => {
            if (this.state.LIEU_PRINC.LIEU_MULTIPLE_PRINC_VIRTUELLE) {
              GetLieuByCocleunik2(this.state.LIEU_PRINC.COCLEUNIK).then((lieu) => {
                this.setState({ LIEU: lieu }, () => {
                  GetCendreByIdLieu(this.state.LIEU.LICLEUNIK).then((cendre) => {
                    if (cendre !== undefined) {
                      GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                        GetCimetierById(cendre.TYPECIME).then((cimetier) => {
                          GetPlanById(cimetier.NUMPLAN).then((plan) => {
                            this.CENDRE = cendre;

                            this.setState({
                              CONCESS: concession,
                              CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                              ZONE: zone,
                              CIMETIER: cimetier,
                              PLAN: plan,
                            });
                          });
                        });
                      });
                    } else {
                      GetZoneById(this.state.LIEU.SITUATION).then((zone) => {
                        GetCimetierById(this.state.LIEU.CIMEL).then((cimetier) => {
                          GetPlanById(cimetier.NUMPLAN).then((plan) => {
                            this.setState({
                              CONCESS: concession,
                              CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                              ZONE: zone,
                              CIMETIER: cimetier,
                              PLAN: plan,
                            });
                          });
                        });
                      });
                    }
                  });
                });
              });
            } else {
              GetCendreByIdLieu(this.state.LIEU_PRINC.LICLEUNIK).then((cendre) => {
                if (cendre !== undefined) {
                  GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                    GetCimetierById(cendre.TYPECIME).then((cimetier) => {
                      GetPlanById(cimetier.NUMPLAN).then((plan) => {
                        this.CENDRE = cendre;

                        this.setState({
                          CONCESS: concession,
                          CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                          ZONE: zone,
                          CIMETIER: cimetier,
                          PLAN: plan,
                          LIEU: lieu,
                        });
                      });
                    });
                  });
                } else {
                  GetZoneById(this.state.LIEU_PRINC.SITUATION).then((zone) => {
                    GetCimetierById(this.state.LIEU_PRINC.CIMEL).then((cimetier) => {
                      GetPlanById(cimetier.NUMPLAN).then((plan) => {
                        this.setState({
                          CONCESS: concession,
                          CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                          ZONE: zone,
                          CIMETIER: cimetier,
                          PLAN: plan,
                          LIEU: lieu,
                        });
                      });
                    });
                  });
                }
              });
            }
          });
        });
      });
    });

    let field = {};
    const temporaire = GetTemporaireForColor("CONCESS", "COCLEUNIK", this.props.id_concession);
    temporaire.then((temp) => {
      _.forEach(temp, (t) => {
        let color = "red";

        if (t.DATE_D_ENVOI_A_GESCIME !== null) {
          color = "blue";
        }

        if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
          color = "green";
        }

        if (t.DATE_TRAITE_DANS_GESCIME !== null) {
          color = "primary";
        }

        var element_temp = t.TABLE_MODIFIE_JSON;
        element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

        for (var key in element_temp) {
          field[key] = color;
        }
      });

      const temporaire = GetTemporaireForColor("PHOTOCONC", "COCLEUNIK", this.props.id_concession);
      temporaire.then((temp) => {
        let tlength = temp.length;

        if (temp[0] !== undefined) {
          let color = "red";
          if (temp[0].DATE_D_ENVOI_A_GESCIME !== null) {
            color = "blue";
          }

          if (temp[0].DATE_D_IMPORT_DANS_GESCIME !== null) {
            color = "green";
          }

          if (temp[0].DATE_TRAITE_DANS_GESCIME !== null) {
            color = "primary";
          }

          var element_temp = temp[0].TABLE_MODIFIE_JSON;
          element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

          for (var key in element_temp) {
            if ((key === "MONUMENT_ETAT" || key === "SEMELLE_ETAT") && tlength > 0) {
              if (element_temp[key] !== "") {
                field[key] = color;
              }
            } else {
              field[key] = color;
            }
          }
        }
      });

      this.setState({
        champ_modifie: field,
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.id_concession !== this.props.id_concession) {
      this.setState(
        {
          IS_ONLINE: false,
          IS_ORACLE: false,
          CONCESS: "",
          CONCESS_ETAT_ECHEANCE: [],
          LIEU_PRINC: "",
          LIEU: "",
          ZONE: "",
          CIMETIER: "",
          PLAN: "",
          SOUS_PLAN: false,
          FROM_BUTTON: false,
          CURRENT_TAB: CurrentTab.getTab(),
          REVERSE: this.props.reverse === "r",
          PA: this.props.reverse === "pa",
          champ_modifie: [],
          en_cours_de_traitement: false,
        },
        () => {
          if (this.props.reverse === "pa") {
            this.setState({ CURRENT_TAB: 2 });
          }

          isOnline().then((status) => {
            this.setState({ IS_ONLINE: status });
          });

          IsOracle().then((oracle) => {
            this.setState({ IS_ORACLE: oracle === 1 });
          });

          GetConcessById(this.props.id_concession).then((concession) => {
            if (concession.FAMILLE !== undefined) {
              this.props.changeTitle(concession.FAMILLE);
            }

            GetTemporaire("CONCESS", "COCLEUNIK", concession.COCLEUNIK).then((temporaire) => {
              this.setState({
                en_cours_de_traitement: false,
              });

              if (temporaire !== undefined) {
                if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
                  this.setState({
                    en_cours_de_traitement: true,
                  });
                }
              }
            });

            GetAllConcessEtatEcheance().then((concess_etat_echeance) => {
              GetLieuById(concession.LICLEUNIK).then((lieu) => {
                this.setState({ LIEU_PRINC: lieu }, () => {
                  if (this.state.LIEU_PRINC.LIEU_MULTIPLE_PRINC_VIRTUELLE) {
                    GetLieuByCocleunik2(this.state.LIEU_PRINC.COCLEUNIK).then((lieu) => {
                      this.setState({ LIEU: lieu }, () => {
                        GetCendreByIdLieu(this.state.LIEU.LICLEUNIK).then((cendre) => {
                          if (cendre !== undefined) {
                            GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                              GetCimetierById(cendre.TYPECIME).then((cimetier) => {
                                GetPlanById(cimetier.NUMPLAN).then((plan) => {
                                  this.CENDRE = cendre;

                                  this.setState({
                                    CONCESS: concession,
                                    CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                                    ZONE: zone,
                                    CIMETIER: cimetier,
                                    PLAN: plan,
                                  });
                                });
                              });
                            });
                          } else {
                            GetZoneById(this.state.LIEU.SITUATION).then((zone) => {
                              GetCimetierById(this.state.LIEU.CIMEL).then((cimetier) => {
                                GetPlanById(cimetier.NUMPLAN).then((plan) => {
                                  this.setState({
                                    CONCESS: concession,
                                    CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                                    ZONE: zone,
                                    CIMETIER: cimetier,
                                    PLAN: plan,
                                  });
                                });
                              });
                            });
                          }
                        });
                      });
                    });
                  } else {
                    GetCendreByIdLieu(this.state.LIEU_PRINC.LICLEUNIK).then((cendre) => {
                      if (cendre !== undefined) {
                        GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                          GetCimetierById(cendre.TYPECIME).then((cimetier) => {
                            GetPlanById(cimetier.NUMPLAN).then((plan) => {
                              this.CENDRE = cendre;

                              this.setState({
                                CONCESS: concession,
                                CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                                ZONE: zone,
                                CIMETIER: cimetier,
                                PLAN: plan,
                                LIEU: lieu,
                              });
                            });
                          });
                        });
                      } else {
                        GetZoneById(this.state.LIEU_PRINC.SITUATION).then((zone) => {
                          GetCimetierById(this.state.LIEU_PRINC.CIMEL).then((cimetier) => {
                            GetPlanById(cimetier.NUMPLAN).then((plan) => {
                              this.setState({
                                CONCESS: concession,
                                CONCESS_ETAT_ECHEANCE: concess_etat_echeance,
                                ZONE: zone,
                                CIMETIER: cimetier,
                                PLAN: plan,
                                LIEU: lieu,
                              });
                            });
                          });
                        });
                      }
                    });
                  }
                });
              });
            });
          });

          setTimeout(() => {
            let field = {};
            const temporaire = GetTemporaireForColor("CONCESS", "COCLEUNIK", this.props.id_concession);
            temporaire.then((temp) => {
              _.forEach(temp, (t) => {
                let color = "red";

                if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                  color = "blue";
                }

                if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                  color = "green";
                }

                if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                  color = "primary";
                }

                var element_temp = t.TABLE_MODIFIE_JSON;
                element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

                for (var key in element_temp) {
                  field[key] = color;
                }
              });

              const temporaire = GetTemporaireForColor("PHOTOCONC", "COCLEUNIK", this.props.id_concession);
              temporaire.then((temp) => {
                let tlength = temp.length;

                if (temp[0] !== undefined) {
                  let color = "red";

                  if (temp[0].DATE_D_ENVOI_A_GESCIME !== null) {
                    color = "blue";
                  }

                  if (temp[0].DATE_D_IMPORT_DANS_GESCIME !== null) {
                    color = "green";
                  }

                  if (temp[0].DATE_TRAITE_DANS_GESCIME !== null) {
                    color = "primary";
                  }

                  var element_temp = temp[0].TABLE_MODIFIE_JSON;
                  element_temp = _.omit(element_temp, ["id", "COCLEUNIK"]);

                  for (var key in element_temp) {
                    if ((key === "MONUMENT_ETAT" || key === "SEMELLE_ETAT") && tlength > 0) {
                      if (element_temp[key] !== "") {
                        field[key] = color;
                      }
                    } else {
                      field[key] = color;
                    }
                  }
                }
              });

              this.setState({
                champ_modifie: field,
              });
            });
          }, 500);
        }
      );
    }
  };

  _handle_click_gmap_button = () => {
    const lieu = this.state.LIEU_PRINC !== "" && this.state.LIEU_PRINC !== undefined ? this.state.LIEU_PRINC : this.state.LIEU;

    CalculateCoordinatesGMap(lieu, this.state.ZONE, this.state.CIMETIER.NUMPLAN, this.state.IS_ORACLE).then((latlng) => {
      if (latlng !== null) {
        window.open("https://maps.google.fr/maps?q=" + latlng + "&t=k");
      }
    });
  };

  _changePlan = (event) => {
    event.preventDefault();

    this.setState({ SOUS_PLAN: !this.state.SOUS_PLAN });
  };

  render() {
    console.log("RENDER FICHE-CONCESSION");

    return this.state.CONCESS !== "" ? (
      <Grid container alignItems="baseline" justify="center" style={styles.grid} ref={this.ficheconcess_ref}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Paper>
            {!this.state.REVERSE ? (
              this.state.LIEU !== undefined && this.state.LIEU.SITUATION > 0 && (this.state.LIEU.SITUATION < 250 || this.state.LIEU.SITUATION > 255) ? (
                this.state.PLAN !== undefined ? (
                  this.state.PLAN.PLAN_V4 === 1 ? (
                    this.state.PLAN.IMAGEPL !== "" ? (
                      <div>
                        <Paper elevation={0} style={styles.paper}>
                          <PlanOperateurFiche
                            page="concess"
                            id_lieu={this.state.CONCESS.LICLEUNIK}
                            cimetier={this.state.CIMETIER}
                            plan={this.state.PLAN}
                            sousplan={this.state.SOUS_PLAN}
                            change_plang={() => {
                              this.setState({
                                SOUS_PLAN: !this.state.SOUS_PLAN,
                              });
                            }}
                            from_button={this.state.FROM_BUTTON}
                            change_from_button={() => {
                              this.setState({
                                FROM_BUTTON: false,
                              });
                            }}
                            reverse={this.state.REVERSE}
                          />
                        </Paper>

                        <Paper
                          elevation={0}
                          style={{
                            ...styles.paper,
                            ...{
                              display: "flex",
                              justifyContent:
                                this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1
                                  ? "space-between"
                                  : "flex-end",
                            },
                          }}
                        >
                          {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                            <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                              Ouvrir dans Google Maps
                            </Button>
                          ) : null}

                          {this.state.ZONE !== undefined ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                this.setState({
                                  SOUS_PLAN: !this.state.SOUS_PLAN,
                                  FROM_BUTTON: true,
                                });
                              }}
                            >
                              {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                            </Button>
                          ) : null}
                        </Paper>
                      </div>
                    ) : null
                  ) : this.state.PLAN.IMAGEPL !== "" ? (
                    <div>
                      <Paper elevation={0} style={styles.paper}>
                        <svg
                          width="100%"
                          height="100%"
                          viewBox={this.state.SOUS_PLAN ? `0 0 1024 768` : this.state.IS_ORACLE ? `0 0 1024 617` : `0 0 1024 768`}
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          {this.state.SOUS_PLAN ? <SousPlan id_lieu={this.state.CONCESS.LICLEUNIK} /> : <PlanGeneral id_lieu={this.state.CONCESS.LICLEUNIK} afficheSousPlan={this._changePlan} />}
                        </svg>
                      </Paper>

                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{
                            display: "flex",
                            justifyContent:
                              this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1
                                ? "space-between"
                                : "flex-end",
                          },
                        }}
                      >
                        {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                          <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                            Ouvrir dans Google Maps
                          </Button>
                        ) : null}

                        {this.state.ZONE !== undefined ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.setState({
                                SOUS_PLAN: !this.state.SOUS_PLAN,
                              });
                            }}
                          >
                            {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                          </Button>
                        ) : null}
                      </Paper>
                    </div>
                  ) : this.state.ZONE !== undefined && this.state.ZONE.IMAGESP !== "" ? (
                    <div>
                      <Paper elevation={0} style={styles.paper}>
                        <svg width="100%" height="100%" viewBox="0 0 1024 768" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <SousPlan id_lieu={this.state.CONCESS.LICLEUNIK} />
                        </svg>
                      </Paper>

                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{
                            display: "flex",
                            justifyContent:
                              this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1
                                ? "space-between"
                                : "flex-end",
                          },
                        }}
                      >
                        {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                          <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                            Ouvrir dans Google Maps
                          </Button>
                        ) : null}
                      </Paper>
                    </div>
                  ) : null
                ) : null
              ) : null
            ) : null}

            <AppBar position="static">
              <Tabs
                value={this.state.CURRENT_TAB}
                onChange={(event, newValue) => {
                  CurrentTab.setTab(newValue);

                  this.setState({ CURRENT_TAB: newValue });
                }}
              >
                <Tab value={0} label="Contrat" style={{ fontWeight: "bold", minWidth: "auto", padding: 6 }} />
                <Tab value={1} label="Interlocuteurs" style={{ fontWeight: "bold", minWidth: "auto", padding: 6 }} />
                <Tab value={2} label="Etat" style={{ fontWeight: "bold", minWidth: "auto", padding: 6 }} />
                <Tab value={3} label="Lieu" style={{ fontWeight: "bold", minWidth: "auto", padding: 6 }} />
                <Tab value={4} label="Défunts" style={{ fontWeight: "bold", minWidth: "auto", padding: 6 }} />
                <Tab value={5} label="Travaux" style={{ fontWeight: "bold", minWidth: "auto", padding: 6 }} />
              </Tabs>
            </AppBar>
            <div key="tab-content">
              {this.state.CURRENT_TAB === 0 && (
                <CFCContrat
                  concess={this.state.CONCESS}
                  cimetier={this.state.CIMETIER}
                  champ_modifie={this.state.champ_modifie}
                  en_cours_de_traitement={this.state.en_cours_de_traitement}
                  pa={this.state.PA}
                />
              )}
              {this.state.CURRENT_TAB === 1 && <CFCInterlocuteur concess={this.state.CONCESS} />}
              {this.state.CURRENT_TAB === 2 && (
                <CFCEtat
                  concess={this.state.CONCESS}
                  concess_etat_echeance={this.state.CONCESS_ETAT_ECHEANCE}
                  champ_modifie={this.state.champ_modifie}
                  en_cours_de_traitement={this.state.en_cours_de_traitement}
                  pa={this.state.PA}
                  echues={this.props.reverse === "e"}
                />
              )}
              {this.state.CURRENT_TAB === 3 && <CFCLieu concess={this.state.CONCESS} />}
              {this.state.CURRENT_TAB === 4 && <CFCDefunts concess={this.state.CONCESS} />}
              {this.state.CURRENT_TAB === 5 && <CFCTravaux concess={this.state.CONCESS} />}
            </div>

            {this.state.REVERSE ? (
              this.state.LIEU !== undefined && this.state.LIEU.SITUATION > 0 && (this.state.LIEU.SITUATION < 250 || this.state.LIEU.SITUATION > 255) ? (
                this.state.PLAN !== undefined ? (
                  this.state.PLAN.PLAN_V4 === 1 ? (
                    <div>
                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{
                            display: "flex",
                            justifyContent:
                              this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1
                                ? "space-between"
                                : "flex-end",
                          },
                        }}
                      >
                        {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                          <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                            Ouvrir dans Google Maps
                          </Button>
                        ) : null}

                        {this.state.ZONE !== undefined ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.setState({
                                SOUS_PLAN: !this.state.SOUS_PLAN,
                                FROM_BUTTON: true,
                              });
                            }}
                          >
                            {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                          </Button>
                        ) : null}
                      </Paper>

                      <Paper elevation={0} style={styles.paper}>
                        <PlanOperateurFiche
                          page="concess"
                          id_lieu={this.state.CONCESS.LICLEUNIK}
                          cimetier={this.state.CIMETIER}
                          plan={this.state.PLAN}
                          sousplan={this.state.SOUS_PLAN}
                          change_plang={() => {
                            this.setState({
                              SOUS_PLAN: !this.state.SOUS_PLAN,
                            });
                          }}
                          from_button={this.state.FROM_BUTTON}
                          change_from_button={() => {
                            this.setState({
                              FROM_BUTTON: false,
                            });
                          }}
                          reverse={this.state.REVERSE}
                        />
                      </Paper>
                    </div>
                  ) : this.state.PLAN.IMAGEPL !== "" ? (
                    <div>
                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{
                            display: "flex",
                            justifyContent:
                              this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1
                                ? "space-between"
                                : "flex-end",
                          },
                        }}
                      >
                        {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                          <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                            Ouvrir dans Google Maps
                          </Button>
                        ) : null}

                        {this.state.ZONE !== undefined ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.setState({
                                SOUS_PLAN: !this.state.SOUS_PLAN,
                              });
                            }}
                          >
                            {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                          </Button>
                        ) : null}
                      </Paper>

                      <Paper elevation={0} style={styles.paper}>
                        <svg
                          width="100%"
                          height="100%"
                          viewBox={this.state.SOUS_PLAN ? `0 0 1024 768` : this.state.IS_ORACLE ? `0 0 1024 617` : `0 0 1024 768`}
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          {this.state.SOUS_PLAN ? <SousPlan id_lieu={this.state.CONCESS.LICLEUNIK} /> : <PlanGeneral id_lieu={this.state.CONCESS.LICLEUNIK} afficheSousPlan={this._changePlan} />}
                        </svg>
                      </Paper>
                    </div>
                  ) : this.state.ZONE !== undefined && this.state.ZONE.IMAGESP !== "" ? (
                    <div>
                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{
                            display: "flex",
                            justifyContent:
                              this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1
                                ? "space-between"
                                : "flex-end",
                          },
                        }}
                      >
                        {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                          <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                            Ouvrir dans Google Maps
                          </Button>
                        ) : null}
                      </Paper>

                      <Paper elevation={0} style={styles.paper}>
                        <svg width="100%" height="100%" viewBox="0 0 1024 768" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <SousPlan id_lieu={this.state.CONCESS.LICLEUNIK} />
                        </svg>
                      </Paper>
                    </div>
                  ) : null
                ) : null
              ) : null
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    ) : null;
  }
}

export default withCookies(CFicheConcession);
