import Dexie from "dexie";
import db from "../Db";

var AddDefunt = Dexie.async(function* (DEFUNT) {
  try {
    yield db.open();
    var id = yield db.DEFUNT.add(DEFUNT);
    return id;
  } finally {
    db.close();
  }
});

export default AddDefunt;
