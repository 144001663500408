import db from "../Db";

export function GetAllPlan() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PLAN.toArray().then((result) => {
      return resolve(result);
    });
  });
}

export function GetPlanById(NUMPLAN) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PLAN.where("NUMPLAN")
      .equals(parseInt(NUMPLAN))
      .first()
      .then((result) => {
        return resolve(result);
      });
  });
}
