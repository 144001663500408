import db from "../Db";

export function GetParam() {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.PARAM.toArray().then((result) => {
      return resolve(result[0]);
    });
  });
}
