import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button } from "@material-ui/core";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import withWidth from "@material-ui/core/withWidth";

import DrawerMenu from "./DrawerMenu";
import HomeButton from "./HomeButton";
import BackButton from "./BackButton";

import App from "../App";
import Accueil from "./Accueil";
import CDefunts from "./Consulter/CDefunts";
import CFicheDefunt from "./Consulter/CFicheDefunt";
import MDefunt from "./Modifier/MDefunt";
import CCartographie from "./Consulter/CCartographie";
import CListeEquipement from "./Consulter/CListeEquipement";
import CListeEquipementCendre from "./Consulter/CListeEquipementCendre";
import CConcessions from "./Consulter/CConcessions";
import CListeConcessions from "./Consulter/CListeConcessions";
import CFicheConcession from "./Consulter/CFicheConcession";
import CProcedures from "./Consulter/CProcedures";
import CProcedureAbandon from "./Consulter/CProcedureAbandon";
import CPSepultureExpirees from "./Consulter/CProcedures/CPSepultureExpirees";
import CTravaux from "./Consulter/CTravaux";
import CTFicheTravaux from "./Consulter/CTravaux/CTFicheTravaux";
import MTravaux from "./Modifier/MTravaux";
import CPlanning from "./Consulter/CPlanning";
import MConcession from "./Modifier/MConcession";
import MCInterlocuteur from "./Modifier/MConcession/MCInterlocuteur";
import MPhotos from "./Modifier/MPhotos";
import Synchronisation from "./Synchronisation";
import MPhotographies from "./Modifier/MPhotographies";
import MLieu from "./Modifier/MLieu";

import { IsOracle } from "./../Helpers/Test/IsOracle";
import Opposition from "./Global/OppositionTablette";
import Aide from "./Aide";
import { GetUtilById } from "./../Helpers/Get/Util";
import NewVersionAvailableSnackBar from "./../NewVersionAvailableSnackBar";
import CListeDefunts from "./Consulter/CListeDefunts";

const styles = {
  router: {
    div: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    appbar_typography: {
      flex: 1,
      display: "flex",
    },
  },
};

class Home extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      IS_ORACLE: false,
      LOGGED: true,
      PAGE: "Accueil",
      REDIRECT: null,
      NOM_UTIL: "",
    };
  }

  componentDidMount() {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle === 1 });
    });

    let idUtil = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));
    if (idUtil !== "0") {
      GetUtilById(idUtil).then((util) => {
        this.setState({ NOM_UTIL: util.LOGIN });
      });
    } else {
      this.setState({ NOM_UTIL: "Gescime" });
    }

    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);
  }

  _change_title = (title) => {
    this.setState({ PAGE: title });
  };

  _handle_click_logout_button = (event) => {
    event.preventDefault();

    this.props.cookies.remove(encodeURIComponent(btoa("ID_UTIL")), {
      path: "/",
    });

    var historyObj = window.history;
    historyObj.pushState(null, null, "/");

    this.setState({ LOGGED: false });
  };

  render() {
    console.log("RENDER HOME");

    const { width } = this.props;

    return this.state.LOGGED ? (
      <Router>
        <div style={styles.router.div}>
          {width === "xs" ? (
            <AppBar position="fixed" color="primary">
              <Toolbar>
                <DrawerMenu />

                <BackButton />

                <HomeButton />

                {this.state.NOM_UTIL !== "" ? (
                  <Typography variant="h6" style={{ position: "absolute", bottom: 0, right: 24, fontSize: "smaller", fontStyle: "italic" }}>
                    {`Utilisateur connecté : ${this.state.NOM_UTIL}`}
                  </Typography>
                ) : null}

                <Button style={{ fontWeight: "bold", flex: 1, justifyContent: "right" }} onClick={this._handle_click_logout_button}>
                  Se déconnecter
                </Button>
              </Toolbar>

              <Toolbar>
                <Typography variant="h6" style={styles.router.appbar_typography}>
                  {this.state.PAGE}
                </Typography>
              </Toolbar>
            </AppBar>
          ) : (
            <AppBar position="fixed" color="primary">
              <Toolbar>
                <DrawerMenu />

                <BackButton />

                <HomeButton />

                <Typography variant="h6" style={styles.router.appbar_typography}>
                  {this.state.PAGE}
                </Typography>

                {this.state.NOM_UTIL !== "" ? (
                  <Typography variant="h6" style={{ position: "absolute", bottom: 0, right: 30, fontSize: "smaller", fontStyle: "italic" }}>
                    {`Utilisateur connecté : ${this.state.NOM_UTIL}`}
                  </Typography>
                ) : null}

                <Button style={{ fontWeight: "bold" }} onClick={this._handle_click_logout_button}>
                  Se déconnecter
                </Button>
              </Toolbar>
            </AppBar>
          )}

          <Toolbar />

          {width === "xs" ? <Toolbar /> : null}

          <Switch>
            {/* Consulter */}
            <Route exact path="/" render={() => <Accueil title="Accueil" changeTitle={this._change_title} />} />

            <Route path="/synchronisation" render={() => <Synchronisation title="Synchronisation" changeTitle={this._change_title} />} />

            <Route path="/aide" render={() => <Aide title="Aide" changeTitle={this._change_title} />} />

            <Route path="/consulter/defunts/:input_defunt?" render={({ match }) => <CDefunts input_defunt={match.params.input_defunt} title="Défunts" changeTitle={this._change_title} />} />

            <Route
              path="/consulter/fiche-defunt/:id_defunt/:type_intervention?"
              render={({ match }) => <CFicheDefunt id_defunt={match.params.id_defunt} type_intervention={match.params.type_intervention} title="" changeTitle={this._change_title} />}
            />

            <Route
              path="/consulter/cartographie/:type?/:id_cimetiere?/:id_lieu?"
              render={({ match }) => (
                <CCartographie type={match.params.type} id_cimetiere={match.params.id_cimetiere} id_lieu={match.params.id_lieu} title="Cartographie" changeTitle={this._change_title} />
              )}
            />

            <Route path="/consulter/liste-equipement" render={() => <CListeEquipement title="Equipements" changeTitle={this._change_title} />} />

            <Route
              path="/consulter/list-equipement_cendre/:id_cendre"
              render={({ match }) => <CListeEquipementCendre id_cendre={match.params.id_cendre} title="" changeTitle={this._change_title} />}
            />

            <Route
              path="/consulter/concessions/:input_concession?/:id_lieu?"
              render={({ match }) => <CConcessions input_concession={match.params.input_concession} id_lieu={match.params.id_lieu} title="Concessions" changeTitle={this._change_title} />}
            />

            <Route
              path="/consulter/liste-concessions/:id_lieu?"
              render={({ match }) => <CListeConcessions id_lieu={match.params.id_lieu} title="Liste des concessions" changeTitle={this._change_title} />}
            />

            <Route path="/consulter/liste-defunts" render={({ match }) => <CListeDefunts title="Liste des défunts" changeTitle={this._change_title} />} />

            <Route
              path="/consulter/fiche-concession/:id_concession/:reverse?"
              render={({ match }) => <CFicheConcession id_concession={match.params.id_concession} reverse={match.params.reverse} title="" changeTitle={this._change_title} />}
            />

            <Route path="/consulter/procedures" render={() => <CProcedures title="Procédures" changeTitle={this._change_title} />} />
            <Route path="/consulter/procedure-abandon" render={() => <CProcedureAbandon title="Procédures d'abandon" changeTitle={this._change_title} />} />
            <Route path="/consulter/sepultures-expirees" render={() => <CPSepultureExpirees title="Concessions expirées" changeTitle={this._change_title} />} />

            <Route path="/consulter/travaux" render={() => <CTravaux title="Travaux" changeTitle={this._change_title} />} />

            <Route path="/consulter/fiche-travaux/:id_travaux" render={({ match }) => <CTFicheTravaux id_travaux={match.params.id_travaux} title="" changeTitle={this._change_title} />} />

            <Route path="/consulter/planning" render={() => <CPlanning title="Planning" changeTitle={this._change_title} />} />

            {/* Modifier */}

            <Route
              path="/modifier/defunt/:id_defunt?/:id_concess?"
              render={({ match }) => <MDefunt id_defunt={match.params.id_defunt} id_concess={match.params.id_concess} title="" changeTitle={this._change_title} />}
            />

            <Route
              path="/modifier/concession/:id_concession?/:procedure?"
              render={({ match }) => <MConcession id_concession={match.params.id_concession} procedure={match.params.procedure} title="" changeTitle={this._change_title} />}
            />

            <Route path="/modifier/ajout-concession/:id_lieu?" render={({ match }) => <MConcession id_lieu={match.params.id_lieu} title="" changeTitle={this._change_title} />} />

            <Route
              path="/modifier/interlocuteur/:id_concession/:id_interlocuteur?"
              render={({ match }) => <MCInterlocuteur id_concession={match.params.id_concession} id_interlocuteur={match.params.id_interlocuteur} title="" changeTitle={this._change_title} />}
            />

            <Route
              path="/modifier/type_photographies/:id_concession?"
              render={({ match }) => <MPhotos id_concession={match.params.id_concession} title="Photographies" changeTitle={this._change_title} />}
            />

            <Route
              path="/modifier/photographies/:id_concession/:type?/:echues?"
              render={({ match }) => (
                <MPhotographies id_concession={match.params.id_concession} type={match.params.type} echues={match.params.echues} title="Photographies" changeTitle={this._change_title} />
              )}
            />

            <Route path="/modifier/travaux/:id_travaux?" render={({ match }) => <MTravaux id_travaux={match.params.id_travaux} title="" changeTitle={this._change_title} />} />

            <Route path="/modifier/lieu/:id_lieu" render={({ match }) => <MLieu id_lieu={match.params.id_lieu} title="" changeTitle={this._change_title} />} />
          </Switch>

          <NewVersionAvailableSnackBar newVersionAvailable={this.props.newVersionAvailable} setNewVersionAvailable={this.props.setNewVersionAvailable}>
            Une nouvelle version de l'application 'Gardiens de cimetières' est disponible. Afin de la télécharger merci de fermer et rouvrir l'application 'Gardiens de cimetières'
          </NewVersionAvailableSnackBar>
        </div>
      </Router>
    ) : (
      <App />
    );
  }
}

export default withWidth()(withCookies(Home));
