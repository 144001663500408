import db from "../Db";

import { GetConcaireById } from "./../Get/Concaire";

export function DeleteConcaire(ID) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    db.CONCAIRE.delete(ID);

    return resolve(true);
  });
}

export function DeleteConcaireByC0cleunik(C0CLEUNIK) {
  return new Promise(function (resolve, reject) {
    if (!db.isOpen()) {
      db.open();
    }

    GetConcaireById(C0CLEUNIK).then((concaire) => {
      if (concaire !== undefined) db.CONCAIRE.delete(concaire.id);

      return resolve(true);
    });
  });
}
