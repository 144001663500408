import React, { Component } from "react";
import { Grid, Paper, FormLabel, TextField, MenuItem, FormControl, InputLabel, Input, InputAdornment, IconButton, Button, FormHelperText } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const styles = {
  grid_container: {
    flex: 1,
  },
  paper: {
    padding: 16,
  },
  submit_div: {
    display: "flex",
    marginTop: 16,
    justifyContent: "flex-end",
  },
};

class ParamConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motdepasse_visible: false,
    };
  }

  click_param_config_motdepasse_visible = (event) => {
    event.preventDefault();

    this.setState({ motdepasse_visible: !this.state.motdepasse_visible });
  };

  render() {
    const { submit_param_config, change_param_config_field, alias, identifiant, motdepasse } = this.props;

    return (
      <Grid container justify="center" alignItems="center" style={styles.grid_container}>
        <Grid item xs={11} sm={11} md={8}>
          <Paper style={styles.paper}>
            <form onSubmit={submit_param_config}>
              <FormLabel>
                Veuillez saisir les identifiants ci-dessous
                <br />
                <i style={{ fontSize: "0.75rem" }}>(vous les retrouverez sur le site Gescime.net &gt; Backoffice &gt; Divers &gt; Application Gardiens)</i>
              </FormLabel>

              {process.env.REACT_APP_ENVIRONMENT !== undefined && process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                <TextField required select fullWidth margin="normal" label="Indiquer l'alias du client" onChange={change_param_config_field("alias")} value={alias}>
                  <MenuItem value="demo-cimetiere-0000H">demo-cimetiere-0000H</MenuItem>
                  <MenuItem value="demo-cimetiere-0000O">demo-cimetiere-0000O</MenuItem>
                  <MenuItem value="demo-cimetiere-0000D">demo-cimetiere-0000D</MenuItem>
                  <MenuItem value="alfortville-cimetiere-94140">alfortville-cimetiere-94140</MenuItem>
                  <MenuItem value="strasbourg-cimetiere-67000">strasbourg-cimetiere-67000</MenuItem>
                  <MenuItem value="bordeaux-cimetiere-33001">bordeaux-cimetiere-33001</MenuItem>
                  <MenuItem value="grenoble-cimetiere-38000">grenoble-cimetiere-38000</MenuItem>
                  <MenuItem value="brest-cimetiere-29200">brest-cimetiere-29200</MenuItem>
                  <MenuItem value="plouarzel-cimetiere-29812">plouarzel-cimetiere-29812</MenuItem>
                  <MenuItem value="angouleme-cimetiere-16000">angouleme-cimetiere-16000</MenuItem>
                  <MenuItem value="colmar-cimetiere-68000">colmar-cimetiere-68000</MenuItem>
                  <MenuItem value="mandres-les-roses-cimetiere-94520">mandres-les-roses-cimetiere-94520</MenuItem>
                  <MenuItem value="tours-cimetiere-37000">tours-cimetiere-37000</MenuItem>
                </TextField>
              ) : (
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label="Indiquer l'alias du client"
                  autoCapitalize="off"
                  inputProps={{
                    pattern: "^[a-z\\-]+-cimetiere-([0-9]{4}([0-9]|[A-Z]){1})$",
                    autoCapitalize: "none",
                  }}
                  onChange={change_param_config_field("alias")}
                  value={alias}
                />
              )}

              <TextField
                error={this.props.erreurIdentifiants}
                helperText={this.props.erreurIdentifiants ? "Identifiant ou mot de passe incorrect" : ""}
                required
                fullWidth
                margin="normal"
                autoComplete="username"
                autoCorrect="off"
                label="Identifiant"
                onChange={change_param_config_field("identifiant")}
                value={identifiant}
              />

              <FormControl error={this.props.erreurIdentifiants} required fullWidth margin="normal">
                <InputLabel htmlFor="motdepasse">Mot de passe</InputLabel>

                <Input
                  aria-describedby="component-error-text"
                  id="motdepasse"
                  autoComplete="current-password"
                  autoCorrect="off"
                  type={this.state.motdepasse_visible ? "text" : "password"}
                  onChange={change_param_config_field("motdepasse")}
                  value={motdepasse}
                  endAdornment={
                    <InputAdornment>
                      <IconButton aria-label="Visualiser le mot de passe" onClick={this.click_param_config_motdepasse_visible}>
                        {this.state.motdepasse_visible === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="component-error-text">{this.props.erreurIdentifiants ? "Identifiant ou mot de passe incorrect" : ""}</FormHelperText>
              </FormControl>

              <div style={styles.submit_div}>
                <Button variant="contained" color="primary" type="submit">
                  Suivant
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default ParamConfig;
