import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetSocieteById } from "../Get/Societe";

var _ = require("lodash");

export async function UpdateSociete_MDF(headers) {
  const _societeCount = await GetApiData("SOCIETECount", headers);

  if (_societeCount.Message !== undefined) {
    return _societeCount;
  }

  const promise = new Promise((res, rej) => {
    if (_societeCount === 0) res("");

    addTableWithProgress("SOCIETE", _societeCount, 2500, headers, (getProgress, getSociete) => {
      if (getSociete.Message !== undefined) {
        return res(getSociete);
      }

      _.forEach(getSociete, (societe) => {
        const societe_a_mettre_a_jour = {
          SOCLEUNIK: societe.SOCLEUNIK,
          NOMSOCIETE: societe.NOMSOCIETE,
          ADRESSE: societe.ADRESSE,
          CODEPO: societe.CODEPO,
          VILLE: societe.VILLE,
        };

        GetSocieteById(societe_a_mettre_a_jour.SOCLEUNIK).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.SOCIETE.add(societe_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(societe_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.SOCIETE.put(societe_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _societeCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_societeCount} sociétés`);

    return val;
  });
}
