import Dexie from "dexie";
import db from "../Db";

var AddPhotoconc = Dexie.async(function* (PHOTOCONC) {
  yield db.open();
  var id = yield db.PHOTOCONC.add(PHOTOCONC);
  return id;
});

export default AddPhotoconc;
