import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Paper, List, ListItem, ListItemText, Divider, CircularProgress, withWidth } from "@material-ui/core";

import { GetAllDefuntS } from "../Helpers/Get/DefuntS";
import { GetAllConcess } from "./../Helpers/Get/Concess";
import { GetAllCimetier } from "./../Helpers/Get/Cimetier";
import { GetAllDefunt } from "./../Helpers/Get/Defunt";
import { GetAllLieu } from "./../Helpers/Get/Lieu";
import { GetAllCendre } from "./../Helpers/Get/Cendre";

var _ = require("lodash");

const styles = {
  paper: {
    flex: 1,
    margin: 16,
  },
};

class ListeDefunts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LISTE_DEFUNTS: [],
      DEFUNT: [],
      LIEU: [],
      CENDRE: [],
      CONCESSION: [],
      CIMETIERE: [],
      REDIRECT: null,
    };
  }

  componentDidMount = () => {
    GetAllLieu().then((lieu) => {
      GetAllCendre().then((cendre) => {
        GetAllConcess().then((concess) => {
          GetAllCimetier(1).then((cimetier) => {
            GetAllDefunt().then((def) => {
              GetAllDefuntS().then((defunts) => {
                this.setState({
                  LISTE_DEFUNTS: this._get_all_suggestions(defunts, this.props.input_defunt),
                  LIEU: lieu,
                  CENDRE: cendre,
                  CONCESSION: concess,
                  DEFUNT: def,
                  CIMETIERE: cimetier,
                });
              });
            });
          });
        });
      });
    });
  };

  _get_all_suggestions = (defunts, input_defunt) => {
    var n = _.deburr(input_defunt).toLowerCase().charCodeAt(0) - 97;

    return _.orderBy(defunts[n][_.deburr(input_defunt).charAt(0).toUpperCase()], ["IDENTITIE"], ["asc"]).filter((suggestion) => {
      const keep =
        !input_defunt ||
        suggestion.IDENTITE.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .startsWith(
            input_defunt
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          );

      return keep;
    });
  };

  render() {
    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={`/consulter/fiche-defunt/${this.state.REDIRECT}`} push />;
    }

    return (
      <Paper style={styles.paper}>
        {this.state.LISTE_DEFUNTS.length === 0 ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

        <List component="nav">
          {this.state.LISTE_DEFUNTS.map((defunt, index) => {
            var def = _.find(this.state.DEFUNT, ["D0CLEUNIK", defunt.D0CLEUNIK]);
            var lie = _.find(this.state.LIEU, ["LICLEUNIK", def.LICLEUNIK]);
            var cen = _.find(this.state.CENDRE, ["LICLEUNIK", def.LICLEUNIK]);

            return (
              <div key={index}>
                <ListItem button onClick={() => this.setState({ REDIRECT: defunt.D0CLEUNIK })} style={{ display: width === "xs" ? "grid" : "flex" }}>
                  <ListItemText primary={defunt.IDENTITE} />
                  <ListItemText
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    primary={`${
                      _.find(this.state.CIMETIERE, ["NUMCIME", cen !== undefined ? cen.TYPECIME : lie.CIMEL]) !== undefined
                        ? _.find(this.state.CIMETIERE, ["NUMCIME", cen !== undefined ? cen.TYPECIME : lie.CIMEL]).NOMCIME
                        : ""
                    } - ${def.EMPLACEMENT_LIEU}`}
                  />
                </ListItem>

                <Divider />
              </div>
            );
          })}
        </List>
      </Paper>
    );
  }
}

export default withWidth()(ListeDefunts);
