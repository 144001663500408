import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { IconButton, Drawer, List, ListItem, ListItemText, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { Menu } from "@material-ui/icons";

class DrawerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      REDIRECT: null,
      DRAWER_OPEN: false,
      DIALOG_RESET: false,
    };
  }

  componentDidMount = () => {
    document.addEventListener("keydown", (event) => {
      if (event.ctrlKey && event.key === "m") {
        this.setState({ DRAWER_OPEN: !this.state.DRAWER_OPEN });
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.REDIRECT !== null ? <Redirect to={this.state.REDIRECT} push /> : null}

        <IconButton aria-label="Accueil" onClick={() => this.setState({ DRAWER_OPEN: true, REDIRECT: null })}>
          <Menu />
        </IconButton>

        <Drawer open={this.state.DRAWER_OPEN} onClose={() => this.setState({ DRAWER_OPEN: false, REDIRECT: null })}>
          <List style={{ width: 300 }}>
            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Accueil" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/consulter/planning",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Planning" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/consulter/defunts",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Défunts" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/consulter/cartographie",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Cartographie" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/consulter/concessions",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Concessions" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/consulter/procedures",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Procédures" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/modifier/type_photographies",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Photographies" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/consulter/travaux",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Travaux" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/synchronisation",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Synchronisation" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem
              button
              onClick={() =>
                this.setState({
                  DIALOG_RESET: true,
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Réinitialisation" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                this.setState({
                  REDIRECT: "/aide",
                  DRAWER_OPEN: false,
                })
              }
            >
              <ListItemText primary="Aide" />
            </ListItem>
          </List>
        </Drawer>

        <Dialog open={this.state.DIALOG_RESET} onClose={() => this.setState({ DIALOG_RESET: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Réinitialisation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{`Afin de réinitialiser l'application 'Gardiens de cimetières', videz simplement le cache de votre navigateur internet selon la procédure suivante : Paramétrages > Applications > Google Chrome / Opéra / etc. > Stockage > Effacer les données. Fermez l'application 'Gardiens de cimetières' et ouvrez-la à nouveau`}</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ DIALOG_RESET: false })}>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(DrawerMenu);
