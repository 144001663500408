import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetNatintervById } from "../Get/Natinterv";

var _ = require("lodash");

export async function UpdateNatinterv_MDF(headers) {
  const _natintervCount = await GetApiData("NATINTERVCount", headers);

  if (_natintervCount.Message !== undefined) {
    return _natintervCount;
  }

  const promise = new Promise((res, rej) => {
    if (_natintervCount === 0) res("");

    addTableWithProgress("NATINTERV", _natintervCount, 2500, headers, (getProgress, getNatinterv) => {
      if (getNatinterv.Message !== undefined) {
        return res(getNatinterv);
      }

      _.forEach(getNatinterv, (natinterv) => {
        const natinterv_a_mettre_a_jour = {
          IDNATINTERV: natinterv.IDNATINTERV,
          LIBINTERVENTION: natinterv.LIBINTERVENTION,
          TYPEPREST: natinterv.TYPEPREST,
          I0CLEUNIK: natinterv.I0CLEUNIK,
        };

        GetNatintervById(natinterv_a_mettre_a_jour.IDNATINTERV).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.NATINTERV.add(natinterv_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(natinterv_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.NATINTERV.put(natinterv_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _natintervCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_natintervCount} natures d'intervention`);

    return val;
  });
}
