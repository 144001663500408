import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { Grid, Paper, Button, Table, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";

import { IsOracle } from "./../../Helpers/Test/IsOracle";
import { GetDefuntById } from "../../Helpers/Get/Defunt";
import { GetLieuById } from "../../Helpers/Get/Lieu";
import { GetCendreByIdLieu } from "../../Helpers/Get/Cendre";
import { GetZoneByTypezone, GetZoneById } from "../../Helpers/Get/Zone";
import { GetCimetierById } from "../../Helpers/Get/Cimetier";
import { GetPlanById } from "./../../Helpers/Get/Plan";
import { CalculateCoordinatesGMap } from "../../Helpers/Calculate/CoordinalesGMpap";
import { GetAgendaTacheByD0cleunik } from "./../../Helpers/Get/AgendaTache";
import { GetAllSociete } from "./../../Helpers/Get/Societe";
import { GetTemporaire, GetTemporaireForColor } from "./../../Helpers/Get/Temporaire";

import SousPlan from "../SousPlan";
import PlanGeneral from "../PlanGeneral";
import { GetParam } from "./../../Helpers/Get/Param";
import { GetConcessById } from "../../Helpers/Get/Concess";
import { GetAllMateriau } from "./../../Helpers/Get/Materiau";
import { CheckUserRight } from "./../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "../Global/DialogAccessRight";
import PlanOperateurFiche from "../PlanOperateurFiche";

const isOnline = require("is-online");
var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
};

const ButtonModifier = React.forwardRef((props, ref) => {
  return (
    <Button
      variant="contained"
      innerRef={ref}
      color="primary"
      disabled={props.en_cours_de_traitement}
      onClick={() => {
        CheckUserRight(props.id_util, "DEFUNT", "DROITMODIF").then((asRight) => {
          props.onChange(asRight);
        });
      }}
    >
      Modifier
    </Button>
  );
});

class CFicheDefunt extends Component {
  constructor(props) {
    super(props);

    this.CENDRE = "";

    this.state = {
      IS_ONLINE: false,
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      DEFUNT: "",
      LIEU: "",
      CONCESS: "",
      ZONE: "",
      CIMETIER: "",
      PLAN: "",
      SOUS_PLAN: false,
      REDIRECT: null,
      TYPE_INTERVENTION: null,
      champ_modifie: [],
      tache: [],
      societe: [],
      param: "",
      materiau: [],
      en_cours_de_traitement: false,
      droit_util_modif_defunt: false,
      FROM_BUTTON: false,
    };
  }

  componentDidMount = () => {
    isOnline().then((status) => {
      this.setState({ IS_ONLINE: status });
    });

    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle });
    });

    GetParam().then((param) => {
      this.setState({ param: param });
    });

    GetAllMateriau().then((materiau) => {
      materiau = _.orderBy(materiau, "ORDRE", "asc");

      this.setState({ materiau: materiau });
    });

    GetDefuntById(this.props.id_defunt).then((defunt) => {
      this.props.changeTitle(defunt.ETAT_CIVIL.NOMDEF + " " + defunt.ETAT_CIVIL.PRENOMDEF);

      GetTemporaire("DEFUNT", "D0CLEUNIK", defunt.D0CLEUNIK).then((temporaire) => {
        this.setState({
          en_cours_de_traitement: false,
        });

        if (temporaire !== undefined) {
          if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
            this.setState({
              en_cours_de_traitement: true,
            });
          }
        }
      });

      if (this.props.type_intervention !== undefined) {
        var typeInter = "";

        if (this.props.type_intervention === "1") typeInter = "Inhumation";
        if (this.props.type_intervention === "2") typeInter = "Exhumation";
        if (this.props.type_intervention === "3") typeInter = "Dispersion";

        if (typeInter !== "") {
          GetAgendaTacheByD0cleunik(defunt.D0CLEUNIK).then((tache) => {
            GetAllSociete().then((societe) => {
              this.setState({
                TYPE_INTERVENTION: typeInter,
                tache: tache,
                societe: societe,
              });
            });
          });
        }
      }

      GetLieuById(defunt.LICLEUNIK).then((lieu) => {
        GetConcessById(defunt.COCLEUNIK).then((concess) => {
          GetCendreByIdLieu(lieu.LICLEUNIK).then((cendre) => {
            if (cendre !== undefined) {
              GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                GetCimetierById(cendre.TYPECIME).then((cimetier) => {
                  GetPlanById(cimetier.NUMPLAN).then((plan) => {
                    this.CENDRE = cendre;

                    this.setState({
                      DEFUNT: defunt,
                      LIEU: lieu,
                      CONCESS: concess,
                      ZONE: zone,
                      CIMETIER: cimetier,
                      PLAN: plan,
                    });
                  });
                });
              });
            } else {
              GetZoneById(lieu.SITUATION).then((zone) => {
                GetCimetierById(lieu.CIMEL).then((cimetier) => {
                  GetPlanById(cimetier.NUMPLAN).then((plan) => {
                    this.setState({
                      DEFUNT: defunt,
                      LIEU: lieu,
                      CONCESS: concess,
                      ZONE: zone,
                      CIMETIER: cimetier,
                      PLAN: plan,
                    });
                  });
                });
              });
            }
          });
        });
      });
    });

    let field = {};
    const temporaire = GetTemporaireForColor("DEFUNT", "D0CLEUNIK", this.props.id_defunt);
    temporaire.then((temp) => {
      _.forEach(temp, (t) => {
        let color = "red";

        if (t.DATE_D_ENVOI_A_GESCIME !== null) {
          color = "blue";
        }

        if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
          color = "green";
        }

        if (t.DATE_TRAITE_DANS_GESCIME !== null) {
          color = "primary";
        }

        var element_temp = t.TABLE_MODIFIE_JSON;
        element_temp = _.omit(element_temp, ["id", "D0CLEUNIK"]);

        for (var key in element_temp) {
          field[key] = color;
        }
      });

      this.setState({
        champ_modifie: field,
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.id_defunt !== this.props.id_defunt) {
      isOnline().then((status) => {
        this.setState({ IS_ONLINE: status });
      });

      IsOracle().then((oracle) => {
        this.setState({ IS_ORACLE: oracle });
      });

      GetParam().then((param) => {
        this.setState({ param: param });
      });

      GetAllMateriau().then((materiau) => {
        materiau = _.orderBy(materiau, "ORDRE", "asc");

        this.setState({ materiau: materiau });
      });

      GetDefuntById(this.props.id_defunt).then((defunt) => {
        this.props.changeTitle(defunt.ETAT_CIVIL.NOMDEF + " " + defunt.ETAT_CIVIL.PRENOMDEF);

        GetTemporaire("DEFUNT", "D0CLEUNIK", defunt.D0CLEUNIK).then((temporaire) => {
          this.setState({
            en_cours_de_traitement: false,
          });

          if (temporaire !== undefined) {
            if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
              this.setState({
                en_cours_de_traitement: true,
              });
            }
          }
        });

        if (this.props.type_intervention !== undefined) {
          var typeInter = "";

          if (this.props.type_intervention === "1") typeInter = "Inhumation";
          if (this.props.type_intervention === "2") typeInter = "Exhumation";
          if (this.props.type_intervention === "3") typeInter = "Dispersion";

          if (typeInter !== "") {
            GetAgendaTacheByD0cleunik(defunt.D0CLEUNIK).then((tache) => {
              GetAllSociete().then((societe) => {
                this.setState({
                  TYPE_INTERVENTION: typeInter,
                  tache: tache,
                  societe: societe,
                });
              });
            });
          }
        }

        GetLieuById(defunt.LICLEUNIK).then((lieu) => {
          GetConcessById(defunt.COCLEUNIK).then((concess) => {
            GetCendreByIdLieu(lieu.LICLEUNIK).then((cendre) => {
              if (cendre !== undefined) {
                GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                  GetCimetierById(cendre.TYPECIME).then((cimetier) => {
                    GetPlanById(cimetier.NUMPLAN).then((plan) => {
                      this.CENDRE = cendre;

                      this.setState({
                        DEFUNT: defunt,
                        LIEU: lieu,
                        CONCESS: concess,
                        ZONE: zone,
                        CIMETIER: cimetier,
                        PLAN: plan,
                      });
                    });
                  });
                });
              } else {
                GetZoneById(lieu.SITUATION).then((zone) => {
                  GetCimetierById(lieu.CIMEL).then((cimetier) => {
                    GetPlanById(cimetier.NUMPLAN).then((plan) => {
                      this.setState({
                        DEFUNT: defunt,
                        LIEU: lieu,
                        CONCESS: concess,
                        ZONE: zone,
                        CIMETIER: cimetier,
                        PLAN: plan,
                      });
                    });
                  });
                });
              }
            });
          });
        });
      });

      let field = {};
      const temporaire = GetTemporaireForColor("DEFUNT", "D0CLEUNIK", this.props.id_defunt);
      temporaire.then((temp) => {
        _.forEach(temp, (t) => {
          let color = "red";

          if (t.DATE_D_ENVOI_A_GESCIME !== null) {
            color = "blue";
          }

          if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
            color = "green";
          }

          if (t.DATE_TRAITE_DANS_GESCIME !== null) {
            color = "primary";
          }

          var element_temp = t.TABLE_MODIFIE_JSON;
          element_temp = _.omit(element_temp, ["id", "D0CLEUNIK"]);

          for (var key in element_temp) {
            field[key] = color;
          }
        });

        this.setState({
          champ_modifie: field,
        });
      });
    }
  };

  _handle_click_gmap_button = () => {
    CalculateCoordinatesGMap(this.state.LIEU, this.state.ZONE, this.state.CIMETIER.NUMPLAN, this.state.IS_ORACLE).then((latlng) => {
      if (latlng !== null) {
        window.open("https://maps.google.fr/maps?q=" + latlng + "&t=k");
      }
    });
  };

  _cree_chaine_date = (date) => {
    if (date === null) {
      return "";
    }

    if (date !== "") {
      if (date.length === 4) {
        return date;
      } else {
        // HF
        if (date.includes("1900-01-01")) {
          return "";
        } else {
          let c_date = date.split("T")[0].split("-");

          return new Date(c_date[0], c_date[1] - 1, c_date[2]).toLocaleDateString("fr-FR");
        }
      }
    } else {
      return date;
    }
  };

  _cree_chaine_heure = (date) => {
    if (date === null) {
      return "";
    }

    if (date !== "") {
      if (date.length > 4) {
        // HF
        if (date.includes("00:00:01")) {
          return "";
        } else {
          let c_time = date.split("T")[1].split(":");

          return `${c_time[0]}:${c_time[1]}`;
        }
      } else {
        return "";
      }
    } else {
      return date;
    }
  };

  _calcule_age_defunt = () => {
    const defunt = this.state.DEFUNT;

    if (
      defunt.ETAT_CIVIL.DATENAISS !== "" &&
      !defunt.ETAT_CIVIL.DATENAISS.includes("1900-01-01") &&
      (defunt.ETAT_CIVIL.DATEDECES !== "" || (defunt.ETAT_CIVIL.ANNEEDECES !== "" && defunt.ETAT_CIVIL.ANNEEDECES !== 0))
    ) {
      var birthDate;
      if (defunt.ETAT_CIVIL.DATENAISS.length === 4) {
        birthDate = new Date(defunt.ETAT_CIVIL.DATENAISS, 0, 1);
      } else {
        birthDate = new Date(defunt.ETAT_CIVIL.DATENAISS);
      }

      var deathDate = defunt.ETAT_CIVIL.DATEDECES !== "" ? new Date(defunt.ETAT_CIVIL.DATEDECES) : new Date(defunt.ETAT_CIVIL.ANNEEDECES, 11, 31);

      var years = deathDate.getFullYear() - birthDate.getFullYear();

      if ((deathDate.getMonth() < birthDate.getMonth() || deathDate.getMonth() === birthDate.getMonth()) && deathDate.getDate() < birthDate.getDate()) {
        years--;
      }

      return Math.abs(years);
    }
  };

  _cree_chaine_enveloppe = () => {
    let env = "";

    if (this.state.IS_ORACLE) {
      let keyArray = [];
      for (let [key, value] of Object.entries(_.assign(this.state.DEFUNT.ENVELOPPE, this.state.DEFUNT.BIERRE))) {
        if (value === 1) {
          switch (key) {
            case "URNE":
              keyArray.push("Urne");
              break;
            case "RELIQUAIRE":
              keyArray.push("Boite à ossements");
              break;
            case "DISPERSION":
              keyArray.push("Dispersion");
              break;
            case "URNE_SCELLE":
              keyArray.push("Urne scellée");
              break;
            case "HERMETIQUE":
              keyArray.push("Cercueil hermétique");
              break;
            case "ZINC_HN":
              keyArray.push("Cercueil hors-normes");
              break;
            default:
              break;
          }
        }
      }

      if (keyArray.length === 0) keyArray.push("Cercueil");

      env = keyArray.join(" - ");
    } else {
      const defAssign = {
        ...this.state.DEFUNT.ENVELOPPE,
        ...this.state.DEFUNT.BIERRE,
      };

      let cerceuil = _.every(_.values(defAssign), (value) => value === 0);

      if (cerceuil) {
        env = "Cercueil";
      } else {
        let cle = _.findKey(defAssign, (item) => item === 1);

        switch (cle) {
          case "URNE":
            env = "Urne";
            break;
          case "RELIQUAIRE":
            env = "Reliquaire";
            break;
          case "ENFANT":
            env = "Cercueil enfant";
            break;
          case "DISPERSION":
            env = "Dispersion";
            break;
          case "URNE_SCELLE":
            env = "Urne scellée";
            break;
          case "HERMETIQUE":
            env = "Cercueil hermétique";
            break;
          case "ZINC_HN":
            env = "Cercueil zingué";
            break;
          default:
            break;
        }
      }
    }

    return env;
  };

  _cree_chaine_enfant = () => {
    let enf = "";

    switch (_.findKey(this.state.DEFUNT.ENFANTDC, (item) => item === 1)) {
      case "AUCUN":
        enf = "";
        break;
      case "ENFANT":
        enf = "Enfant";
        break;
      case "NOUVEAU_NE":
        enf = "Nouveau né";
        break;
      case "MORT_NE":
        enf = "Enfant sans vie";
        break;
      case "FOETUS":
        enf = "fœtus";
        break;
      default:
        break;
    }

    return enf;
  };

  _changePlan = (event) => {
    event.preventDefault();

    this.setState({ SOUS_PLAN: !this.state.SOUS_PLAN });
  };

  render() {
    console.log("RENDER FICHE-DEFUNT");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return this.state.DEFUNT !== "" ? (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} md={8} style={styles.grid_item}>
          <Paper>
            {this.state.TYPE_INTERVENTION !== null ? (
              <div>
                <Typography variant="h4" style={{ textAlign: "center", margin: 16 }} gutterBottom>
                  {`${this.state.TYPE_INTERVENTION} - ${this.state.DEFUNT.ETAT_CIVIL.NOMDEF + " " + this.state.DEFUNT.ETAT_CIVIL.PRENOMDEF}`}
                </Typography>

                {this.state.tache !== undefined && this.state.tache.length > 0 ? (
                  <Table>
                    <TableBody>
                      {this.state.tache.map((tache, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {`${this.state.TYPE_INTERVENTION === "Dispersion" ? this.state.TYPE_INTERVENTION : tache.TACHE.LIBELLE} le ${new Date(tache.TACHE.DATETACHE)
                              .toLocaleDateString("fr-FR", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                              .replace(",", " à")} ${
                              _.find(this.state.societe, {
                                id: tache.IDSOCIETE,
                              }) !== undefined
                                ? ` par la société : ${
                                    _.find(this.state.societe, {
                                      id: tache.IDSOCIETE,
                                    }).NOMSOCIETE
                                  }`
                                : ""
                            }`}
                            {this.state.DEFUNT.EMPLACEMENT_LIEU !== "" ? (
                              <div>
                                {this.state.LIEU.EMPLACEMENT.CARRE.includes("Provisoire") || this.state.LIEU.EMPLACEMENT.CARRE.includes("Non affect")
                                  ? this.state.param.LIBELLEANCIENNUMPLAN !== ""
                                    ? this.state.param.LIBELLEANCIENNUMPLAN
                                    : "Emplacement"
                                  : "Emplacement"}
                                {` : ${this.state.DEFUNT.EMPLACEMENT_LIEU}`}
                              </div>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : null}
              </div>
            ) : null}

            {this.state.LIEU !== undefined && (this.state.LIEU.SITUATION < 250 || this.state.LIEU.SITUATION > 255) ? (
              this.state.PLAN !== undefined && this.state.ZONE !== undefined ? (
                this.state.PLAN.PLAN_V4 === 1 ? (
                  this.state.PLAN.IMAGEPL !== "" ? (
                    <div>
                      <Paper elevation={0} style={styles.paper}>
                        <PlanOperateurFiche
                          id_lieu={this.state.DEFUNT.LICLEUNIK}
                          cimetier={this.state.CIMETIER}
                          plan={this.state.PLAN}
                          sousplan={this.state.SOUS_PLAN}
                          change_plang={() => {
                            this.setState({
                              SOUS_PLAN: !this.state.SOUS_PLAN,
                            });
                          }}
                          from_button={this.state.FROM_BUTTON}
                          change_from_button={() => {
                            this.setState({
                              FROM_BUTTON: false,
                            });
                          }}
                          reverse={this.state.REVERSE}
                        />
                      </Paper>

                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{ display: "flex", justifyContent: "space-between" },
                        }}
                      >
                        <ButtonModifier
                          en_cours_de_traitement={this.state.en_cours_de_traitement}
                          id_util={this.state.ID_UTIL}
                          onChange={(value) => {
                            if (value) {
                              this.setState({
                                REDIRECT: `/modifier/defunt/${this.state.DEFUNT.D0CLEUNIK}`,
                              });
                            } else {
                              this.setState({ droit_util_modif_defunt: true });
                            }
                          }}
                        />

                        {this.state.en_cours_de_traitement ? (
                          <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                            Modification impossible : Défunt non traité dans Gescime
                          </Typography>
                        ) : null}

                        {this.state.ZONE !== undefined ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.setState({ SOUS_PLAN: !this.state.SOUS_PLAN, FROM_BUTTON: true });
                            }}
                          >
                            {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                          </Button>
                        ) : null}
                      </Paper>

                      {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                        <Paper
                          elevation={0}
                          style={{
                            ...styles.paper,
                            ...{ display: "flex", justifyContent: "flex-end" },
                          }}
                        >
                          <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                            Ouvrir dans Google Maps
                          </Button>
                        </Paper>
                      ) : null}
                    </div>
                  ) : (
                    <Paper
                      elevation={0}
                      style={{
                        ...styles.paper,
                        ...{ display: "flex", justifyContent: "space-between" },
                      }}
                    >
                      <ButtonModifier
                        en_cours_de_traitement={this.state.en_cours_de_traitement}
                        id_util={this.state.ID_UTIL}
                        onChange={(value) => {
                          if (value) {
                            this.setState({
                              REDIRECT: `/modifier/defunt/${this.state.DEFUNT.D0CLEUNIK}`,
                            });
                          } else {
                            this.setState({ droit_util_modif_defunt: true });
                          }
                        }}
                      />

                      {this.state.en_cours_de_traitement ? (
                        <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                          Modification impossible : Défunt non traité dans Gescime
                        </Typography>
                      ) : null}
                    </Paper>
                  )
                ) : this.state.PLAN.IMAGEPL !== "" ? (
                  <div>
                    <Paper elevation={0} style={styles.paper}>
                      <svg
                        width="100%"
                        height="100%"
                        viewBox={this.state.SOUS_PLAN ? `0 0 1024 768` : this.state.IS_ORACLE ? `0 0 1024 617` : `0 0 1024 768`}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        {this.state.SOUS_PLAN ? <SousPlan id_lieu={this.state.DEFUNT.LICLEUNIK} /> : <PlanGeneral id_lieu={this.state.DEFUNT.LICLEUNIK} afficheSousPlan={this._changePlan} />}
                      </svg>
                    </Paper>

                    <Paper
                      elevation={0}
                      style={{
                        ...styles.paper,
                        ...{ display: "flex", justifyContent: "space-between" },
                      }}
                    >
                      <ButtonModifier
                        en_cours_de_traitement={this.state.en_cours_de_traitement}
                        id_util={this.state.ID_UTIL}
                        onChange={(value) => {
                          if (value) {
                            this.setState({
                              REDIRECT: `/modifier/defunt/${this.state.DEFUNT.D0CLEUNIK}`,
                            });
                          } else {
                            this.setState({ droit_util_modif_defunt: true });
                          }
                        }}
                      />

                      {this.state.en_cours_de_traitement ? (
                        <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                          Modification impossible : Défunt non traité dans Gescime
                        </Typography>
                      ) : null}

                      {this.state.ZONE !== undefined ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.setState({ SOUS_PLAN: !this.state.SOUS_PLAN });
                          }}
                        >
                          {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                        </Button>
                      ) : null}
                    </Paper>

                    {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{ display: "flex", justifyContent: "flex-end" },
                        }}
                      >
                        <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                          Ouvrir dans Google Maps
                        </Button>
                      </Paper>
                    ) : null}
                  </div>
                ) : this.state.ZONE.IMAGESP !== "" ? (
                  <div>
                    <Paper elevation={0} style={styles.paper}>
                      <svg width="100%" height="100%" viewBox="0 0 1024 768" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <SousPlan id_lieu={this.state.DEFUNT.LICLEUNIK} />
                      </svg>
                    </Paper>

                    <Paper
                      elevation={0}
                      style={{
                        ...styles.paper,
                        ...{ display: "flex", justifyContent: "space-between" },
                      }}
                    >
                      <ButtonModifier
                        en_cours_de_traitement={this.state.en_cours_de_traitement}
                        id_util={this.state.ID_UTIL}
                        onChange={(value) => {
                          if (value) {
                            this.setState({
                              REDIRECT: `/modifier/defunt/${this.state.DEFUNT.D0CLEUNIK}`,
                            });
                          } else {
                            this.setState({ droit_util_modif_defunt: true });
                          }
                        }}
                      />

                      {this.state.en_cours_de_traitement ? (
                        <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                          Modification impossible : Défunt non traité dans Gescime
                        </Typography>
                      ) : null}
                    </Paper>

                    {this.state.IS_ONLINE && this.state.PLAN.COORDONNEES.GNET_NE_LATITUDE !== 0 && this.state.PLAN.PLAN_V4 === 1 && this.state.ZONE.ZONE_DESSUS_TYPE === 1 ? (
                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{ display: "flex", justifyContent: "flex-end" },
                        }}
                      >
                        <Button variant="contained" color="primary" onClick={this._handle_click_gmap_button}>
                          Ouvrir dans Google Maps
                        </Button>
                      </Paper>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <Paper
                      elevation={0}
                      style={{
                        ...styles.paper,
                        ...{ display: "flex", justifyContent: "space-between" },
                      }}
                    >
                      <ButtonModifier
                        en_cours_de_traitement={this.state.en_cours_de_traitement}
                        id_util={this.state.ID_UTIL}
                        onChange={(value) => {
                          if (value) {
                            this.setState({
                              REDIRECT: `/modifier/defunt/${this.state.DEFUNT.D0CLEUNIK}`,
                            });
                          } else {
                            this.setState({ droit_util_modif_defunt: true });
                          }
                        }}
                      />

                      {this.state.en_cours_de_traitement ? (
                        <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                          Modification impossible : Défunt non traité dans Gescime
                        </Typography>
                      ) : null}
                    </Paper>
                  </div>
                )
              ) : (
                <div>
                  <Paper
                    elevation={0}
                    style={{
                      ...styles.paper,
                      ...{ display: "flex", justifyContent: "space-between" },
                    }}
                  >
                    <ButtonModifier
                      en_cours_de_traitement={this.state.en_cours_de_traitement}
                      id_util={this.state.ID_UTIL}
                      onChange={(value) => {
                        if (value) {
                          this.setState({
                            REDIRECT: `/modifier/defunt/${this.state.DEFUNT.D0CLEUNIK}`,
                          });
                        } else {
                          this.setState({ droit_util_modif_defunt: true });
                        }
                      }}
                    />

                    {this.state.en_cours_de_traitement ? (
                      <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                        Modification impossible : Défunt non traité dans Gescime
                      </Typography>
                    ) : null}
                  </Paper>
                </div>
              )
            ) : (
              <div>
                <Paper
                  elevation={0}
                  style={{
                    ...styles.paper,
                    ...{ display: "flex", justifyContent: "space-between" },
                  }}
                >
                  <ButtonModifier
                    en_cours_de_traitement={this.state.en_cours_de_traitement}
                    id_util={this.state.ID_UTIL}
                    onChange={(value) => {
                      if (value) {
                        this.setState({
                          REDIRECT: `/modifier/defunt/${this.state.DEFUNT.D0CLEUNIK}`,
                        });
                      } else {
                        this.setState({ droit_util_modif_defunt: true });
                      }
                    }}
                  />

                  {this.state.en_cours_de_traitement ? (
                    <Typography variant="body2" style={{ flex: 1, alignSelf: "center", padding: "0 18px" }}>
                      Modification impossible : Défunt non traité dans Gescime
                    </Typography>
                  ) : null}
                </Paper>
              </div>
            )}

            <Paper elevation={0} style={styles.paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        color:
                          _.has(this.state.champ_modifie, "SEXE") || _.has(this.state.champ_modifie, "CIVDEF")
                            ? _.get(this.state.champ_modifie, "SEXE") || _.get(this.state.champ_modifie, "CIVDEF")
                            : "primary",
                      }}
                    >
                      Civilité
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{
                        color:
                          _.has(this.state.champ_modifie, "SEXE") || _.has(this.state.champ_modifie, "CIVDEF")
                            ? _.get(this.state.champ_modifie, "SEXE") || _.get(this.state.champ_modifie, "CIVDEF")
                            : "primary",
                      }}
                    >
                      {this.state.DEFUNT.ETAT_CIVIL.SEXE === 1 ? `Monsieur` : this.state.DEFUNT.ETAT_CIVIL.SEXE === 2 ? `Madame` : this.state.DEFUNT.ETAT_CIVIL.SEXE === 3 ? `Mademoiselle` : null}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "NOMDEF") ? _.get(this.state.champ_modifie, "NOMDEF") : "primary",
                      }}
                    >
                      Nom d'usage
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "NOMDEF") ? _.get(this.state.champ_modifie, "NOMDEF") : "primary",
                      }}
                    >
                      {this.state.DEFUNT.ETAT_CIVIL.NOMDEF}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "NOMJEUNE") ? _.get(this.state.champ_modifie, "NOMJEUNE") : "primary",
                      }}
                    >
                      Nom de naissance
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "NOMJEUNE") ? _.get(this.state.champ_modifie, "NOMJEUNE") : "primary",
                      }}
                    >
                      {this.state.DEFUNT.ETAT_CIVIL.NOMJEUNE}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "PRENOMDEF") ? _.get(this.state.champ_modifie, "PRENOMDEF") : "primary",
                      }}
                    >
                      Prénom
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "PRENOMDEF") ? _.get(this.state.champ_modifie, "PRENOMDEF") : "primary",
                      }}
                    >
                      {this.state.DEFUNT.ETAT_CIVIL.PRENOMDEF}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "DATENAISS") ? _.get(this.state.champ_modifie, "DATENAISS") : "primary",
                      }}
                    >
                      Date de naissance
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "DATENAISS") ? _.get(this.state.champ_modifie, "DATENAISS") : "primary",
                      }}
                    >
                      {this.state.DEFUNT.ETAT_CIVIL.DATENAISS !== "1900-01-01T00:00:00" ? this._cree_chaine_date(this.state.DEFUNT.ETAT_CIVIL.DATENAISS) : null}
                    </TableCell>
                  </TableRow>

                  {this.state.IS_ORACLE ? (
                    this.state.DEFUNT.ETAT_CIVIL.ANNEEDECES !== "" && this.state.DEFUNT.ETAT_CIVIL.ANNEEDECES !== 0 ? (
                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(this.state.champ_modifie, "ANNEEDECES") ? _.get(this.state.champ_modifie, "ANNEEDECES") : "primary",
                          }}
                        >
                          Année de décès
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: _.has(this.state.champ_modifie, "ANNEEDECES") ? _.get(this.state.champ_modifie, "ANNEEDECES") : "primary",
                          }}
                        >
                          {this.state.DEFUNT.ETAT_CIVIL.ANNEEDECES}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell
                          style={{
                            color: _.has(this.state.champ_modifie, "DATEDECES") ? _.get(this.state.champ_modifie, "DATEDECES") : "primary",
                          }}
                        >
                          Date de décès
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: _.has(this.state.champ_modifie, "DATEDECES") ? _.get(this.state.champ_modifie, "DATEDECES") : "primary",
                          }}
                        >
                          {this.state.DEFUNT.ETAT_CIVIL.DATEDECES !== "" ? this._cree_chaine_date(this.state.DEFUNT.ETAT_CIVIL.DATEDECES) : ""}
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "DATEDECES") ? _.get(this.state.champ_modifie, "DATEDECES") : "primary",
                        }}
                      >
                        Date de décès
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "DATEDECES") ? _.get(this.state.champ_modifie, "DATEDECES") : "primary",
                        }}
                      >
                        {this.state.DEFUNT.ETAT_CIVIL.DATEDECES !== "" ? this._cree_chaine_date(this.state.DEFUNT.ETAT_CIVIL.DATEDECES) : ""}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "AGE") ? _.get(this.state.champ_modifie, "AGE") : "primary",
                      }}
                    >
                      Age
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "AGE") ? _.get(this.state.champ_modifie, "AGE") : "primary",
                      }}
                    >
                      {this._calcule_age_defunt() !== undefined && this._calcule_age_defunt() > 0
                        ? this._calcule_age_defunt()
                        : this.state.DEFUNT.ETAT_CIVIL.AGE > 0
                        ? this.state.DEFUNT.ETAT_CIVIL.AGE
                        : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "DATEINHUM") ? _.get(this.state.champ_modifie, "DATEINHUM") : "primary",
                      }}
                    >
                      Date d'inhumation
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "DATEINHUM") ? _.get(this.state.champ_modifie, "DATEINHUM") : "primary",
                      }}
                    >
                      {this.state.DEFUNT.ETAT_CIVIL.DATEINHUM !== "" ? this._cree_chaine_date(this.state.DEFUNT.ETAT_CIVIL.DATEINHUM) : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "HEUREMOUV") ? _.get(this.state.champ_modifie, "HEUREMOUV") : "primary",
                      }}
                    >
                      Heure d'inhumation
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "HEUREMOUV") ? _.get(this.state.champ_modifie, "HEUREMOUV") : "primary",
                      }}
                    >
                      {this.state.DEFUNT.ETAT_CIVIL.DATEINHUM !== "" ? this._cree_chaine_heure(this.state.DEFUNT.ETAT_CIVIL.DATEINHUM) : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "TYPECIME") ? _.get(this.state.champ_modifie, "TYPECIME") : "primary",
                      }}
                    >
                      Cimetière
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "TYPECIME") ? _.get(this.state.champ_modifie, "TYPECIME") : "primary",
                      }}
                    >
                      {this.state.CIMETIER.NOMCIME}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "LICLEUNIK")
                          ? _.get(this.state.champ_modifie, "LICLEUNIK")
                          : _.has(this.state.champ_modifie, "NUMCENDRE")
                          ? _.get(this.state.champ_modifie, "NUMCENDRE")
                          : "primary",
                      }}
                    >
                      {this.state.LIEU.EMPLACEMENT.CARRE.includes("Provisoire") || this.state.LIEU.EMPLACEMENT.CARRE.includes("Non affect")
                        ? this.state.param.LIBELLEANCIENNUMPLAN !== ""
                          ? this.state.param.LIBELLEANCIENNUMPLAN
                          : "Emplacement"
                        : "Emplacement"}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "LICLEUNIK")
                          ? _.get(this.state.champ_modifie, "LICLEUNIK")
                          : _.has(this.state.champ_modifie, "NUMCENDRE")
                          ? _.get(this.state.champ_modifie, "NUMCENDRE")
                          : "primary",
                      }}
                    >
                      {this.state.DEFUNT.EMPLACEMENT_LIEU}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        color: _.has(this.state.champ_modifie, "ENVELOPPE")
                          ? _.get(this.state.champ_modifie, "ENVELOPPE")
                          : _.has(this.state.champ_modifie, "BIERRE")
                          ? _.get(this.state.champ_modifie, "BIERRE")
                          : "primary",
                      }}
                    >
                      Enveloppe
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: _.has(this.state.champ_modifie, "ENVELOPPE")
                          ? _.get(this.state.champ_modifie, "ENVELOPPE")
                          : _.has(this.state.champ_modifie, "BIERRE")
                          ? _.get(this.state.champ_modifie, "BIERRE")
                          : "primary",
                      }}
                    >
                      {this._cree_chaine_enveloppe()}
                    </TableCell>
                  </TableRow>

                  {this.state.IS_ORACLE ? (
                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "ENFANTDC") ? _.get(this.state.champ_modifie, "ENFANTDC") : "primary",
                        }}
                      >
                        Enfant
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "ENFANTDC") ? _.get(this.state.champ_modifie, "ENFANTDC") : "primary",
                        }}
                      >
                        {this._cree_chaine_enfant()}
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {this.state.IS_ORACLE ? (
                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "IDMATERIAU") ? _.get(this.state.champ_modifie, "IDMATERIAU") : "primary",
                        }}
                      >
                        Matériau
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "IDMATERIAU") ? _.get(this.state.champ_modifie, "IDMATERIAU") : "primary",
                        }}
                      >
                        {_.find(this.state.materiau, ["IDMATERIAU", this.state.DEFUNT.IDMATERIAU]) !== undefined
                          ? _.find(this.state.materiau, ["IDMATERIAU", this.state.DEFUNT.IDMATERIAU]).LIBELLE
                          : ""}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </Paper>

            <DialogAccessRight state={this.state.droit_util_modif_defunt} onChange={(value) => this.setState({ droit_util_modif_defunt: value })} />
          </Paper>
        </Grid>
      </Grid>
    ) : null;
  }
}

export default withCookies(CFicheDefunt);
